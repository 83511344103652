/** @format */

import styled from 'styled-components';
import { theme, device } from '@constants';

export const ViewWrapper = styled.div`
  display: block;
  margin-bottom: 0px;
  outline: none;
`;

export const Wrapper = styled.div`
  display: ${props => (props.hide ? 'none' : 'block')};
  align: center;
  overflow-x: hidden;
  padding-bottom: ${props => (props.dotsEnabled ? 30 : 0)}px;

  .slick-dots {
    bottom: -5px;

    @media ${device.mediaQuery.SMALL_SCREEN} {
      bottom: -10px;
    }
  }

  .magic-dots.slick-dots li.slick-active button:before {
    color: ${theme.BASE_COLOR};
    opacity: 1;
  }

  .slick-prev {
    left: 25%;
    top: 99%;
    z-index: 12;

    @media ${device.mediaQuery.SMALL_SCREEN} {
      font-size: 18px;
      padding-top: 2px;
    }
  }

  .slick-prev:before {
    color: ${theme.BASE_COLOR};
    opacity: 1;
  }

  .slick-next {
    right: 25%;
    top: 99%;

    @media ${device.mediaQuery.SMALL_SCREEN} {
      font-size: 18px;
      padding-top: 2px;
    }
  }

  .slick-next:before {
    color: ${theme.BASE_COLOR};
    opacity: 1;
  }
`;
