/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const ProtectedRoute = ({
  component,
  isAllowed,
  path,
  exact,
  redirectTo,
  render,
  restricted,
}) => {
  const Component = component;
  const { restrictedMode } = useSelector(state => state.app);
  const isRouteAllowed = restrictedMode ? isAllowed && restricted : isAllowed;

  return isRouteAllowed ? (
    <Route path={path} exact={exact} component={Component} render={render} />
  ) : (
    <Redirect to={{ pathname: redirectTo }} />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.func,
  isAllowed: PropTypes.bool.isRequired,
  restricted: PropTypes.bool,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  redirectTo: PropTypes.string,
  render: PropTypes.func,
};

ProtectedRoute.defaultProps = {
  exact: false,
  redirectTo: '/',
  render: null,
  component: null,
  restricted: false,
};

export default ProtectedRoute;
