/** @format */

import styled from 'styled-components';
import { theme } from '@constants';

export const SheetModalWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const SheetModal = styled.div`
  background-color: ${theme.WHITE};
  width: 100%;
  overflow-y: scroll;
  max-height: 65vh;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
  border-radius: 15px 15px 0 0;
`;

export const ToggleMinimizeButton = styled.button`
  width: 28px;
  padding: 0;
  border: none;
  background-color: ${theme.TRANSPARENT};
  position: absolute;
  top: -12px;
  left: calc(50% - 14px);
  z-index: 998;

  :focus {
    outline: none;
  }
`;

export const ToggleMinimizeButtonImage = styled.img`
  width: 28px;
`;
