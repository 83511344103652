/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { tripHelpers } from '@utils';
import { generals as generalConstants } from '@constants';
import { Trip, Pagination } from '@components';
import {
  MyTrips as StyledMyTrips,
  MyTripsWrapper,
  MyTripTypeTabsRow,
  TripsCol,
  ActiveIndicator,
  ClickHandler,
  MyTripTypeCol,
  MyTripTypeText,
  Message,
  LoaderIcon,
  LoaderWrapperRow,
  CloseIcon,
  PaginationRow,
  CloseIconRow,
} from './styled';
import { tabs } from './config';

const MyTrips = ({
  activeTripsType,
  setActiveTripsType,
  trips,
  onPageChange,
  onSelect,
  loading,
  onClose,
  addToCalenderBtnCliked,
  calendarItemClicked,
}) => {
  const { t } = useTranslation();
  const activeTab = tabs.findIndex(tab => tab.type === activeTripsType);

  const onTabChange = event => {
    const type = event.currentTarget.getAttribute('data-tabtype');
    setActiveTripsType(type);
  };

  const getAddToCalendarEvent = trip => {
    const [pickup, destination] = trip.places;
    const { transitShareTripRequest } = trip;
    const startTime = transitShareTripRequest?.leaveTime || trip.leaveTime;
    const endTime = transitShareTripRequest?.arriveTime || trip.arriveTime;
    const event = {
      title: `${
        transitShareTripRequest
          ? `${trip.transitShareTripRequest.operator || 'SHARE + Transit'}`
          : 'SHARE'
      } ${t('trip.labels.organization-upcoming-trip')}`,
      description: `
        ${t('common.from')}: ${pickup.address}
        ${t('common.to')}: ${destination.address}
        ${t('trip.labels.booking-ref')}: ${trip.bookingRef}
      `,
      location: pickup.address,
      startTime,
      endTime,
      heading: `${
        transitShareTripRequest
          ? `${trip.transitShareTripRequest.operator || 'SHARE + Transit'}`
          : 'SHARE'
      }`,
    };

    return event;
  };

  const getIsPaymentFailed = (trip) => {
    const status = trip?.paymentDetail?.status;

    if (
      status === generalConstants.PAYMENT_STATUS.intentCreationFailed ||
      status === generalConstants.PAYMENT_STATUS.intentCaptureFailed
    ) {
      return true;
    }

    return false;
  };

  const renderTabContent = () => {
    const tab = tabs[activeTab];
    const tripsOfSelectedType = trips[tab.type].data;
    const { pageCount } = trips[tab.type].pagination;
    const isMyTripsLoading = loading === generalConstants.MY_TRIPS_LOADING;

    return (
      <TripsCol
        id={tab.id}
        justify="flex-start"
        align="center"
        pageCount={pageCount}
      >
        {isMyTripsLoading && (
          <LoaderWrapperRow
            id="my-trips-loader"
            justify="center"
            align="center"
            spacingV={30}
          >
            <LoaderIcon icon={faSpinner} spin />
          </LoaderWrapperRow>
        )}

        {!isMyTripsLoading &&
          tripsOfSelectedType.map((trip, i) => (
            <Trip
              key={i}
              optionName={trip.operator}
              status={
                trip.status
                  ? t(`trip.labels.statuses.${trip.status.toLowerCase().split(' ').join('-')}`)
                  : ''
              }
              isTransitShareTrip={trip.transitShareTripRequest?.type === generalConstants.TRIP_TYPES.transit_share}
              tripCost={trip.cost}
              tripTime={trip.transitShareTripRequest?.tripTime || trip.tripTime}
              leaveBy={trip.transitShareTripRequest?.leaveTime || trip.leaveTime}
              arriveBy={trip.transitShareTripRequest?.arriveTime || trip.arriveTime}
              path={tripHelpers.createSteps(trip.steps)}
              onTripSelect={() => onSelect(trip)}
              addToCalendarEvent={getAddToCalendarEvent(trip)}
              timezone={trip.places[0].timezone}
              timeFormat="MM/DD/YYYY hh:mm:ss a"
              addToCalenderBtnCliked={addToCalenderBtnCliked}
              calendarItemClicked={calendarItemClicked}
              isPaymentFailed={getIsPaymentFailed(trip)}
            />
          ))}

        {!isMyTripsLoading && tripsOfSelectedType.length === 0 && (
          <Message id="message">
            {tab.titleContent && tab.titleContent.includes('upcoming')
              ? t('my-trips.messages.no-trips.upcoming')
              : t('my-trips.messages.no-trips.past')}
          </Message>
        )}

        {pageCount > 1 && (
          <PaginationRow id="pagination-row" justify="center" align="center">
            <Pagination count={pageCount} onChange={onPageChange} />
          </PaginationRow>
        )}
      </TripsCol>
    );
  };

  return (
    <StyledMyTrips justify="flex-start" align="center">
      <MyTripsWrapper>
        <CloseIconRow justify="flex-end" align="flex-end">
          <CloseIcon id="close-my-trips" onClick={onClose} width={16} />
        </CloseIconRow>
        <MyTripTypeTabsRow justify="flex-start" align="flex-start">
          <ActiveIndicator selectedIndex={activeTab} />
          {tabs.map((tab, index) => (
            <ClickHandler
              key={index}
              data-tabtype={tab.type}
              id={`${tab.id}-button`}
              onClick={onTabChange}
            >
              <MyTripTypeCol justify="center" align="center">
                <MyTripTypeText>{t(tab.titleContent)}</MyTripTypeText>
              </MyTripTypeCol>
            </ClickHandler>
          ))}
        </MyTripTypeTabsRow>
        {renderTabContent()}
      </MyTripsWrapper>
    </StyledMyTrips>
  );
};

MyTrips.propTypes = {
  activeTripsType: PropTypes.string.isRequired,
  setActiveTripsType: PropTypes.func.isRequired,
  trips: PropTypes.shape({
    upcoming: PropTypes.shape({
      data: PropTypes.array,
      pagination: PropTypes.shape({
        pageCount: PropTypes.number,
      }),
    }),
    past: PropTypes.shape({
      data: PropTypes.array,
      pagination: PropTypes.shape({
        pageCount: PropTypes.number,
      }),
    }),
  }).isRequired,
  onPageChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  loading: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  addToCalenderBtnCliked: PropTypes.func,
  calendarItemClicked: PropTypes.func,
};

MyTrips.defaultProps = {
  loading: null,
  addToCalenderBtnCliked: null,
  calendarItemClicked: null,
};

export default MyTrips;