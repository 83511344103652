/** @format */

import React, { useCallback, useState } from 'react';
import { ConfirmModal } from '@components';
import { Portal } from 'react-portal';

const useConfirmationDialog = ({
  bodyText = null,
  onConfirmClick = null,
} = {}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalProps, setProps] = useState({
    bodyText,
    onConfirmClick,
  });

  const openConfirmationDialog = (value, updatedProps) => {
    setIsOpen(value);
    if (updatedProps) setProps({ ...modalProps, ...updatedProps });
  };

  const ConfirmationDialog = useCallback(
    () =>
      isOpen ? (
        <Portal>
          <ConfirmModal
            text={modalProps.bodyText}
            submit={modalProps.onConfirmClick}
            close={() => openConfirmationDialog(false)}
          />
        </Portal>
      ) : null,

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen, modalProps.bodyText, modalProps.onConfirmClick],
  );

  return {
    ConfirmationDialog,
    openConfirmationDialog,
  };
};
export default useConfirmationDialog;
