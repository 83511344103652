/** @format */

// 3rd party Imports
import styled from 'styled-components';

// internal Imports
import { Button } from '@components';

export const FeedBackButton = styled(Button)`
  border-radius: 6px;
  font-size: 12px;
  height: 52px;
  width: 45%;
  margin: 5px;
  line-height: 14px;
`;
