/** @format */

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Input, Button, Col, Dropdown } from '@components';
import { theme, device } from '@constants';
import { styles as styleUtils } from '@utils';

export const Form = styled.div`
  ${styleUtils.flex('center', 'center', 'column')}

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & > * {
    margin: 10px 0px;
    flex: 45%;
  }
`;

export const SInput = styled(Input)`
  span {
    color: ${theme.INPUT_LABEL_COLOR};
  }
`;

export const Wrapper = styled.div`
  ${styleUtils.flex('flex-start', 'center', 'column')}
  flex: 1;
  font-family: Bryant;

  @media ${device.mediaQuery.SMALL_SCREEN} {
    padding: 20px;
  }

  @media ${device.mediaQuery.LARGE_SCREEN} {
    padding: 30px;
  }
`;

export const FormWrapper = styled.div`
  ${styleUtils.flex('flex-start', 'center', 'column')}
  flex: 1;
`;

export const StyledButton = styled(Button)`
  border-radius: 8px;
  font-size: 18px;
  line-height: 20px;
  font-family: Bryant;
  font-weight: 700;
  height: 50px;
`;

export const BottomWrapper = styled.div`
  ${styleUtils.flex('flex-start', 'center', 'column')}
  margin-top: 15px;
`;

export const ConfirmationBottomWrapper = styled.div`
  ${styleUtils.flex('flex-start', 'center', 'column')}
  margin-top: 15px;
  width 40%;
`;

export const Title = styled.h6`
  color: ${theme.COD_GRAY_LIGHT};
  font-weight: bold;
  font-size: 26px;
  @media ${device.mediaQuery.SMALL_SCREEN} {
    margin-top: 20px;
  }
`;

export const SLink = styled(Link)`
  margin-left: auto;
`;

export const DropdownCol = styled(Col)`
  margin-bottom: 20px;
`;

export const SDropdown = styled(Dropdown)`
  width: 100%;
`;

export const ModalText = styled.p`
  text-align: center;
`;
