/** @format */

import React, { useEffect, useState, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Marker, useMap, Popup, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import { useLocation } from 'react-router';
import { useLocalStorage, useSessionStorage } from '@hooks';
import { hints } from '@constants';
import { BoldText, TextAlign, STooltip } from '../styled';
import {
  selectedStopMarkerIcon,
  selectedWorkplaceMarkerIcon,
  stopMarkerIcon,
  workplaceMarkerIcon,
  disabledStopMarkerIcon,
  disabledWorkplaceMarkerIcon,
  tripMarkersConfig,
} from '../config';
import TripPath from '../tripPath';

const getMapBoundsForFixedRoute = orgPlaces => {
  return orgPlaces.filter(p => p.type === "STOP").map(item => [item.lat, item.lng]);
};

const WorkForceMap = ({
  places,
  tripMarkers,
  tripAddresses,
  tripPath,
  resizeMapKey,
  // fetchTrips,
  selectedMarker,
  firstTimeFitBounds,
  setFirstTimeFitBounds,
  onMarkerClick,
  isUserLoggedIn,
  tripRequestsLoading,
}) => {
  const { t } = useTranslation();

  const map = useMap();
  const location = useLocation();
  const mapBounds = getMapBoundsForFixedRoute(places);
  const [selectedLatLng, setSelectedLatLng] = useState(null);
  const [showTooltip, setShowTooltip] = useState(true);
  const [seenHints, setSeenHints] = useLocalStorage('seenHints');
  const [seenSessionHints, setSeenSessionHints] = useSessionStorage('workforceMapHints');

  const hintsVisible = useMemo(() => {
    return !seenHints.mapWorkforce && isUserLoggedIn;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoggedIn]);

  const isHomeScreen = location.pathname === '/';

  const showSessionHints = !seenSessionHints && !isUserLoggedIn;

  useEffect(() => {
    if (!seenHints.mapWorkforce && isUserLoggedIn) {
      setShowTooltip(true);
      setSeenHints({ ...seenHints, mapWorkforce: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hintsVisible, isUserLoggedIn]);

  useEffect(() => {
    if (mapBounds.length > 0 && !firstTimeFitBounds) {
      mapBounds && map.fitBounds(mapBounds);
    }
    if (mapBounds.length > 0 && isUserLoggedIn && !selectedMarker) {
      mapBounds && mapBounds.length && map.fitBounds(mapBounds);
    }
    if (selectedLatLng && !tripMarkers?.pickup) {
      map.setView(selectedLatLng)
    } else if (tripMarkers?.pickup) {
      map.fitBounds([tripMarkers.pickup.location, tripMarkers.destination.location]);
    }
  }, [map, mapBounds, firstTimeFitBounds, selectedLatLng, tripMarkers, isUserLoggedIn, selectedMarker]);

  const firstStopIndex = places && places.findIndex(item => item.type === 'STOP');

  // commented code is for map zoom functionality
  useMapEvents({
    // zoomend: () => {
    //   getPlaces(places);
    // },
    // moveend: () => {
    //   getPlaces(places);
    // },
    click: () => setShowTooltip(false)
  });

  const getMarkerIcon = (stop) => {
    const isSelected = selectedMarker && selectedMarker === stop.orgPlaceId;
    return isSelected ? (
      stop.type === 'STOP' ? selectedStopMarkerIcon : selectedWorkplaceMarkerIcon
    ) : (
      !tripRequestsLoading ? (
        stop.type === 'STOP' ? stopMarkerIcon : workplaceMarkerIcon
      ) : (
        stop.type === 'STOP' ? disabledStopMarkerIcon : disabledWorkplaceMarkerIcon
      )
    );
  };

  // code for map zoom functionality
  // const getPlaces = places => {
  //   const zoomedPlaces = [];
  //   const bounds = map.getBounds();
  //   places.forEach(place => {
  //     // eslint-disable-next-line no-underscore-dangle
  //     if (place.lat > bounds._southWest.lat && place.lat < bounds._northEast.lat) {
  //       // eslint-disable-next-line no-underscore-dangle
  //       if (place.lng > bounds._southWest.lng && place.lng < bounds._northEast.lng) {
  //         zoomedPlaces.push(place);
  //       }
  //     }
  //   });
  //   if (!selectedMarker) {
  //     fetchTrips(zoomedPlaces.map(item => item.orgPlaceId));
  //   }
  // };

  useEffect(() => {
    if (!firstTimeFitBounds) {
      // const zoomedPlaces = [];
      if (places.length > 0) {
        // places.forEach(place => {
        //   zoomedPlaces.push(place);
        // });
        setFirstTimeFitBounds(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstTimeFitBounds, places, isUserLoggedIn]);

  const handleMarkerClick = (id, lat, lng) => {
    onMarkerClick(id);
    setShowTooltip(false);
    if (showSessionHints) {
      setSeenSessionHints('workforceMapHints', true);
    }

    if (!selectedMarker || selectedMarker !== id) {
      setSelectedLatLng([lat, lng]);
    }
    else {
      setSelectedLatLng(null);
    }
  }

  return (
    <>
      <TripPath tripPath={tripPath} resizeMapKey={resizeMapKey} />
      {places && isHomeScreen &&
        places.map((stop, idx) => {
          return (
            stop && (
              <Marker
                key={`marker-${idx}-${stop.id}`}
                id={
                  stop.type === 'STOP'
                    ? `work-force-marker-stop-${stop.orgPlaceId}`
                    : `work-force-marker-workplace-${stop.orgPlaceId}`
                }
                icon={getMarkerIcon(stop)}
                position={[stop.lat, stop.lng]}
                title={stop.seq}
                eventHandlers={{
                  click: () => handleMarkerClick(stop.orgPlaceId, stop.lat, stop.lng),
                  mouseover: (e) => { e.target.openPopup(); },
                  mouseout: (e) => { e.target.closePopup(); },
                  contextmenu: (e) => { L.DomEvent.preventDefault(e) },
                }}
              >
                <Popup>
                  <BoldText title="Location Name">{stop.name}</BoldText>
                  <TextAlign title="Location Name">{stop.label}</TextAlign>
                </Popup>
                {((hintsVisible || showSessionHints) && showTooltip && idx === firstStopIndex) && (
                  <STooltip direction="top" opacity={1} permanent id="marker-tooltip">{t(hints.mapWorkforceHints.marker)}</STooltip>
                )}
              </Marker>
            )
          );
        })}
      {tripMarkersConfig.map(o => {
        if (!tripMarkers[o.key]) return null;
        return (
          <Marker
            key={o.key}
            id={`${o.key}-marker`}
            icon={tripMarkers[o.key].type ? getMarkerIcon(tripMarkers[o.key]) : o.icon}
            alt={o.alt}
            position={tripMarkers[o.key].location}
            title={t(o.title)}
            eventHandlers={{
              mouseover: (e) => { e.target.openPopup(); },
              mouseout: (e) => { e.target.closePopup(); },
            }}
          >
            <Popup>
              <BoldText title="Location Name">{t(o.name)}</BoldText>
            </Popup>
          </Marker>
        );
      })}
    </>
  );
};

WorkForceMap.propTypes = {
  places: PropTypes.array.isRequired,
  tripMarkers: PropTypes.shape({
    pickup: PropTypes.array,
    destination: PropTypes.array,
  }).isRequired,
  tripPath: PropTypes.shape({
    share: PropTypes.array,
  }).isRequired,
  tripAddresses: PropTypes.object,
  resizeMapKey: PropTypes.number.isRequired,
  // fetchTrips: PropTypes.func.isRequired,
  selectedMarker: PropTypes.number,
  onMarkerClick: PropTypes.func.isRequired,
  firstTimeFitBounds: PropTypes.bool,
  setFirstTimeFitBounds: PropTypes.func.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  tripRequestsLoading: PropTypes.bool.isRequired,
};

WorkForceMap.defaultProps = {
  selectedMarker: null,
  firstTimeFitBounds: false,
  tripAddresses: null,
};

export default WorkForceMap;
