/** @format */

import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { theme, device } from '@constants';
import { Button, Col, IconButton, Row, Input, Modal } from '..';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Wrapper = styled(Col)`
  height: 100%;
  font-family: Bryant;

  button[width='20'] {
    margin-top: 0 !important;
    @media ${device.mediaQuery.SMALL_SCREEN} {
      position: absolute;
      margin: 15px 0;
      font-size: 24px;
      top: 24px !important;
      right: 20px;
    }
  }

  @media ${device.mediaQuery.SMALL_SCREEN} {
    min-height: 100%;
    padding: 20px 20px;
  }

  @media ${device.mediaQuery.LARGE_SCREEN} {
    padding: 20px 24px 20px 24px;
  }
`;

export const Title = styled.h6`
  color: ${theme.COD_GRAY_LIGHT};
  font-weight: bold;
  font-size: 26px;
  @media ${device.mediaQuery.SMALL_SCREEN} {
    margin-top: 20px;
  }
`;

export const CloseIconRow = styled(Row)`
  margin-left: 8px;
`;

export const SubTitle = styled.p`
  color: ${theme.COD_GRAY_LIGHT};
  font-size: 22px;
  margin-bottom: 10px;
  @media ${device.mediaQuery.SMALL_SCREEN} {
    font-size: 18px;
  }
`;

export const FormWrapper = styled(Col)`
  display: block;

  & > * {
    margin: 6px 0px;
  }
`;

export const TagsContainer = styled(Row)`
  flex-wrap: wrap;
  overflow-y: auto;
`;

export const InviteButton = styled(Button)`
  margin-top: 20px;
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  height: 50px;
`;

export const CloseIconStyled = styled(IconButton)`
  margin-top: 0;
  z-index: 2;

  @media ${device.mediaQuery.SMALL_SCREEN} {
    position: absolute;
    margin: 15px 0;
    font-size: 22px;
    top: 124px;
  }
`;

export const Tooltip = styled(ReactTooltip)`
  &.show {
    opacity: 1 !important;
  }
  @media ${device.mediaQuery.SMALL_SCREEN} {
    width: 88%;
    &#send-referral-tooltip {
      left: 6% !important;
    }
  }
`;

export const TextSmall = styled.small`
  color: ${theme.BASE_COLOR};
  font-weight: bold;
  font-size: 16px;
  padding-right: 10px;
`;

export const SuccessLabel = styled.small`
  color: ${theme.WEIRD_GREEN};
  font-size: 14px;
  font-weight: bold;
  padding-left: 6px;
`;

export const SInput = styled(Input)`
  floating-label: {
    margin-bottom: 0px;
  }
  label {
    pointer-events: auto;
    span {
      cursor: pointer;
    }
  }

  &.svg {
    background: red;
  }
`;

export const CopyToClipboardContainer = styled(Row)`
  margin-top: 15px;
  width: 100%;
`;

export const CopyMobileButton = styled(Button)`
  width: 60px;
  height: 30px;
  border-radius: 4px;
  svg {
    height: 15px;
    width: 15px !important;
  }
`;

export const CopyTitle = styled.p`
  color: ${theme.COD_GRAY_LIGHT};
  font-size: 22px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${device.mediaQuery.SMALL_SCREEN} {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const Popup = styled(Modal)``;

export const LoaderIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
`;
