/** @format */
import React from 'react';
import { TileLayer, ZoomControl, Circle } from 'react-leaflet';
import { PropTypes } from 'prop-types';
import { credits, defaultCenter } from './config';
import { SMap } from './styled';
import WorkForceMap from './map';

const MapWorkforce = ({
  organizationPlaces,
  currentLocation,
  tripMarkers,
  tripAddresses,
  tripPath,
  resizeMapKey,
  searchTripRequests,
  selectedMarker,
  setSelectedMarker,
  firstTimeFitBounds,
  setFirstTimeFitBounds,
  isUserLoggedIn,
  tripRequestsLoading,
  largeScreen,
 }) => {
  // code for map zoom functionality
  // const [placesInViewport, setPlacesInViewport] = useState([]);

  const center =
    tripMarkers.pickup ||
    currentLocation ||
    defaultCenter;

  // code for map zoom functionality
  // const fetchTrips = (placeIds) => {
  //   if (placeIds.length > 0) {
  //     setPlacesInViewport(placeIds);
  //     if (!selectedMarker) {
  //       searchTripRequests(placeIds);
  //     }
  //   } else {
  //     searchTripRequests(null);
  //   }
  // };

  const onMarkerClick = (placeId) => {
    if (!tripRequestsLoading) {
      if (!selectedMarker || selectedMarker !== placeId) {
        setSelectedMarker(placeId);
        searchTripRequests([placeId]);
      } else if (selectedMarker === placeId) {
        setSelectedMarker(null);
        searchTripRequests(null);
      }
    }
  };

  return (
    <SMap
      zoomControl={false}
      center={center?.location || center}
      zoom={tripMarkers.pickup ? 16 : 13}
    >
      <ZoomControl position={largeScreen ? 'bottomright' : 'topleft'} />
      <TileLayer attribution={credits.attribution} url={credits.url} />
      <WorkForceMap
        places={organizationPlaces}
        tripMarkers={tripMarkers}
        tripPath={tripPath}
        tripAddresses={tripAddresses}
        resizeMapKey={resizeMapKey}
        // code for map zoom functionality
        // fetchTrips={fetchTrips}
        selectedMarker={selectedMarker}
        onMarkerClick={onMarkerClick}
        firstTimeFitBounds={firstTimeFitBounds}
        setFirstTimeFitBounds={setFirstTimeFitBounds}
        isUserLoggedIn={isUserLoggedIn}
        tripRequestsLoading={tripRequestsLoading}
      />
      {currentLocation && (
        <Circle center={currentLocation?.location || currentLocation} />
      )}
    </SMap>
  );
};

MapWorkforce.propTypes = {
  currentLocation: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  organizationPlaces: PropTypes.array.isRequired,
  tripMarkers: PropTypes.shape({
    pickup: PropTypes.array,
    destination: PropTypes.array,
  }).isRequired,
  tripPath: PropTypes.shape({
    share: PropTypes.array,
  }).isRequired,
  largeScreen: PropTypes.bool.isRequired,
  tripAddresses: PropTypes.object,
  resizeMapKey: PropTypes.number.isRequired,
  searchTripRequests: PropTypes.func.isRequired,
  selectedMarker: PropTypes.number,
  setSelectedMarker: PropTypes.func.isRequired,
  firstTimeFitBounds: PropTypes.bool,
  setFirstTimeFitBounds: PropTypes.func.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  tripRequestsLoading: PropTypes.bool.isRequired,
};

MapWorkforce.defaultProps = {
  selectedMarker: null,
  firstTimeFitBounds: false,
  tripAddresses: null,
};

export default MapWorkforce;
