/** @format */

import React from 'react';
import { PropTypes } from 'prop-types';
import { MultiSelect as SMultiSelect } from './styled';

const MultiSelect = ({
  value,
  onChange,
  options,
  placeholder,
  isMulti,
  onOpen,
  onClose,
  height,
  ...restProps
}) => {
  return (
    <SMultiSelect
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      options={options}
      isMulti={isMulti}
      className="react-select-container"
      classNamePrefix="react-select"
      isOptionDisabled={(option) => option.disabled}
      onMenuOpen={onOpen}
      onMenuClose={onClose}
      height={height}
      {...restProps}
    />
  );
};

MultiSelect.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  isMulti: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  height: PropTypes.number,
};

MultiSelect.defaultProps = {
  isMulti: true,
  onOpen: () => {},
  onClose: () => {},
  value: null,
  height: 200,
};

export default MultiSelect;
