/** @format */

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSwipeable } from 'react-swipeable';

import { theme } from '@constants';
import arrowUp from '@assets/icons/up-arrow.svg';
import arrowDown from '@assets/icons/down-arrow.svg';
import {
  SheetModal as SSheetModal,
  SheetModalWrapper,
  ToggleMinimizeButton,
  ToggleMinimizeButtonImage,
} from './styled';

export const SheetModal = ({
  id,
  minimized,
  toggleMinimized,
  minimizeEnabled,
  children,
}) => {
  const sheetModalRef = useRef(null);

  // to prevent reload on swipe down for chrome
  useEffect(() => {
    if (minimizeEnabled) {
      document.body.style.overscrollBehavior = 'none';
    }

    return () => {
      if (minimizeEnabled) {
        document.body.style.overscrollBehavior = 'initial';
      }
    };
  }, [minimizeEnabled]);

  let swipeStartTime;

  const handleSwipe = e => {
    if (!toggleMinimized) {
      return;
    }

    const swipeEndTime = new Date().getTime();
    const swipeDuration = swipeEndTime - swipeStartTime;
    const shouldToggle =
      (e.dir === 'Up' && minimized) || (e.dir === 'Down' && !minimized);
    const isScrollVisible =
      (sheetModalRef?.current?.scrollHeight > sheetModalRef?.current?.clientHeight) || false;

    /**
     * if content of sheetModal is not scrollable => allow swipe gesture
     * else allow swipe gesture if swipeDuration < 80ms
     * swipeDuration > 80ms will be considered as scroll
     */
    if (shouldToggle && (!isScrollVisible || swipeDuration < 80)) {
      toggleMinimized();
    }
  };

  const handlers = useSwipeable({
    onSwipeStart: () => {
      if (!toggleMinimized) {
        return;
      }

      swipeStartTime = new Date().getTime();
    },
    onSwipedUp: handleSwipe,
    onSwipedDown: handleSwipe,
  });

  return (
    <SheetModalWrapper {...handlers}>
      {minimizeEnabled && (
        <ToggleMinimizeButton
          id="toggle-minimize-btn"
          onClick={toggleMinimized}
          color={theme.BASE_COLOR}
          inverted
        >
          <ToggleMinimizeButtonImage
            src={minimized ? arrowUp : arrowDown}
            alt="toggle minimize icon"
          />
        </ToggleMinimizeButton>
      )}

      <SSheetModal
        justify="flex-start"
        align="flex-start"
        id={id}
        ref={sheetModalRef}
      >
        {children}
      </SSheetModal>
    </SheetModalWrapper>
  );
};

SheetModal.propTypes = {
  id: PropTypes.string,
  minimized: PropTypes.bool,
  toggleMinimized: PropTypes.func,
  minimizeEnabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.element.isRequired),
  ]).isRequired,
};

SheetModal.defaultProps = {
  id: '',
  minimizeEnabled: false,
  minimized: false,
  toggleMinimized: null,
};

export default SheetModal;
