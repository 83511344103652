/** @format */

import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { theme } from '@constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row } from '..';

export const ClickHandler = styled.button`
  width: 100%;
  border: none;
  padding: 0;
  background-color: ${theme.WHITE};

  &:focus {
    outline: none;
  }
`;

export const PaymentMethodRow = styled(Row)`
  border-top: 1px solid ${theme.BASE_COLOR};
`;

export const ChargedPerTripValue = styled.span`
  font-size: 12px;
`;

export const ChargedPerTripTitle = styled.span`
  font-size: 12px;
  font-weight: 600;
`;

export const PaymentMethodTitle = styled.span`
  font-size: 12px;
  font-weight: 600;
`;

export const PaymentMethodTitleValue = styled.span`
  font-size: 12px;
  margin-right: 15px;
`;

export const Tooltip = styled(ReactTooltip)`
  &.show {
    opacity: 1 !important;
  }
`;

export const LoaderWrapperRow = styled(Row)`
  height: auto;
  padding: 1px 0px 0 5px;
  display: flex;
  justify-content: flex-start;
`;

export const LoaderIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
`;
