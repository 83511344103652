/** @format */

import React, { useRef } from 'react';

const useFocusNextInput = fields => {
  const inputRefs = useRef(fields.map(() => React.createRef()));

  // onKeyPress handler
  const focusNextInput = event => {
    if (event.charCode !== 13) {
      return;
    }

    const index = fields.findIndex(o => o.name === event.target.name);

    if (index + 1 === inputRefs.current.length) {
      inputRefs.current[index].current.blur();
      return;
    }

    const nextRef = inputRefs.current[index + 1].current;

    /**
     * recursively call the function if,
     * next input is not defined (for non-input fields, e.g dropdown)
     * or, next input is disabled
     */

    if (!nextRef || (nextRef && nextRef.disabled)) {
      focusNextInput(event, index + 1);
    } else {
      nextRef.focus();
    }
  };

  return [inputRefs, focusNextInput];
};

export default useFocusNextInput;
