/** @format */

import React from 'react';
import PropTypes from 'prop-types';

import { Screen as SScreen } from './styled';

const Screen = ({ children }) => <SScreen>{children}</SScreen>;

Screen.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.element.isRequired),
  ]).isRequired,
};

export default Screen;
