/** @format */

import img1 from '@assets/images/intro-slide1.png';
import img2 from '@assets/images/intro-slide2.png';
import img3 from '@assets/images/intro-slide3.png';

import aaaRoundTownImage1 from '@assets/images/aaa-roundtown-1.png';
import aaaRoundTownImage2 from '@assets/images/aaa-roundtown-2.png';
import aaaRoundTownImage3 from '@assets/images/aaa-roundtown-3.png';

import aaaRoundTownImageMobile1 from '@assets/images/aaa-roundtown-mobile-1.png';
import aaaRoundTownImageMobile2 from '@assets/images/aaa-roundtown-mobile-2.png';
import aaaRoundTownImageMobile3 from '@assets/images/aaa-roundtown-mobile-3.png';

const welcomeViews = [
  {
    img: img1,
    title: 'welcome-views.share.page-1.title',
    subtitle: 'welcome-views.share.page-1.subtitle',
  },
  {
    img: img2,
    title: 'welcome-views.share.page-2.title',
    subtitle: 'welcome-views.share.page-2.subtitle',
  },
  {
    img: img3,
    title: 'welcome-views.share.page-3.title',
    subtitle: 'welcome-views.share.page-3.subtitle',
  },
];

const aaaRoundTownWelcomeViews = [
  {
    index: 0,
    img: aaaRoundTownImage1,
    imgMobile: aaaRoundTownImageMobile1,
    title: 'welcome-views.aaa-roundtown.page-1.title',
    text: 'welcome-views.aaa-roundtown.page-1.text',
    secondaryText: 'welcome-views.aaa-roundtown.page-1.secondary-text',
    showReadyToRide: false,
  },
  {
    index: 1,
    img: aaaRoundTownImage2,
    imgMobile: aaaRoundTownImageMobile2,
    title: 'welcome-views.aaa-roundtown.page-2.title',
    text: 'welcome-views.aaa-roundtown.page-2.text',
    secondaryText: null,
    showReadyToRide: false,
  },
  {
    index: 2,
    img: aaaRoundTownImage3,
    imgMobile: aaaRoundTownImageMobile3,
    title: 'welcome-views.aaa-roundtown.page-3.title',
    text: 'welcome-views.aaa-roundtown.page-3.text',
    secondaryText: null,
    showReadyToRide: true,
  },
];

export { welcomeViews, aaaRoundTownWelcomeViews };
