/** @format */

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { FullScreen, LoginForm, Modal } from '@components';
import {
  setLoginFormState,
  login,
  resetAuthState,
  setLoginRememberMe,
} from '@slices/auth';
import { Portal } from 'react-portal';

const Login = () => {
  const {
    register,
    trigger,
    errors,
    formState: { isValid },
  } = useForm({
    mode: 'onBlur',
  });

  const history = useHistory();

  const dispatch = useDispatch();

  const state = useSelector(store => store.auth.login);
  const { largeScreen, user } = useSelector(store => store.app);

  useEffect(() => {
    return () => {
      dispatch(resetAuthState());
    };
  }, [dispatch]);

  const onCloseModal = () => {
    history.push('/');
    // TODO: disabling reset auth, its causing a signup with org problem if you close the modal.
    // dispatch(resetAuthState());
  };

  const goToSignupPage = () => {
    history.push('/signup');
  };

  const onSubmit = () => {
    if (!isValid) {
      trigger();
      return;
    }
    dispatch(login(history));
  };

  const onChangeRememberMe = value => {
    dispatch(setLoginRememberMe(value));
  };

  const onChange = async e => {
    const { name, value } = e.target;

    dispatch(
      setLoginFormState({
        name,
        value,
      }),
    );

    await trigger(name);
  };

  const onCloseFullScreen = () => {
    history.push('/');
  };

  const goToForgotPassword = () => {
    history.push('/forgot-password');
  };

  const onChangeShowPassword = value => {
    dispatch(
      setLoginFormState({
        name: 'showPassword',
        value,
      }),
    );
  };

  const loginForm = (
    <LoginForm
      state={state.formState}
      loading={state.loading}
      errors={errors}
      rememberMe={state.rememberMe}
      validate={register}
      onChange={onChange}
      onSubmit={onSubmit}
      onChangeRememberMe={onChangeRememberMe}
      largeScreen={largeScreen}
      onCloseModal={onCloseModal}
      onCloseFullScreen={onCloseFullScreen}
      onClickForgotPassword={goToForgotPassword}
      goToSignupPage={goToSignupPage}
      onChangeShowPassword={onChangeShowPassword}
    />
  );

  return largeScreen ? (
    !user && (
      <Portal>
        <Modal width="738" open toggle={onCloseModal}>
          {loginForm}
        </Modal>
      </Portal>
    )
  ) : (
    <FullScreen id="login-full-screen" headerHeight={0}>
      {loginForm}
    </FullScreen>
  );
};
export default Login;
