/** @format */

// eslint-disable-next-line
import React, { useState } from 'react';
import { resetMapAfterResize } from '@slices/app';
import { useDispatch } from 'react-redux';

const useSheetModalMinimize = () => {
  const dispatch = useDispatch();
  const [sheetModalMinimized, setSheetModalMinimized] = useState(false);

  const toggleSheetModalMinimized = () => {
    setSheetModalMinimized(!sheetModalMinimized);
    dispatch(resetMapAfterResize());
  };

  return [sheetModalMinimized, toggleSheetModalMinimized];
};

export default useSheetModalMinimize;
