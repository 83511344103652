/** @format */

import styled from 'styled-components';
import { theme, device } from '@constants';
import { styles as styleUtils } from '@utils';
import { ReactDropdown } from '@components';

const sizes = {
  xs: {
    padding: '4px 8px',
    fontSize: '13px',
    iconSize: '16px',
  },
  sm: {
    padding: '4px 8px',
    fontSize: '18px',
    iconSize: '16px',
  },
  md: {
    padding: '8px 20px',
    fontSize: '18px',
    iconSize: '22px',
  },
};

export const Dropdown = styled(ReactDropdown)`
  width: 100%;
  height: ${props => props.height};
  .rdd-control {
    ${styleUtils.flex('space-between', 'center')}
    height: 100%;
    padding: ${props => sizes[props.size].padding};
    border-radius: ${props => (props.rounded ? props.borderRadius || 8 : 0)}px;
    border: ${props =>
      props.bordered ? `1px solid ${theme.LIGHTEN_GRAY}` : 'none'};
    box-shadow: none;
    background-color: ${props => (props.inverted ? theme.WHITE : props.color)};
    position: relative;
    z-index: 13;
  }

  .rdd-placeholder {
    font-size: ${props => (props.inHeader ? '16px' : sizes[props.size].fontSize)};
    color: ${props => (props.inHeader ? theme.BASE_COLOR : props.inverted ? theme.BLACK : theme.WHITE)};
    font-family: ${props => (props.inHeader ? 'Poppins, Sans Serif' : 'BryantRegular')};
    padding: 10px;
  }

  .rdd-menu {
    width: ${props => (props.inHeader && '170px')};
    border-radius: ${props => (props.rounded ? 8 : 0)}px;
    box-shadow: none;
    background-color: ${props => (props.inverted ? theme.WHITE : props.color)};
    border: none;
    z-index: 12;
    top: ${props => (!props.inHeader && '34px')};
    border: ${props =>
      props.bordered ? `1px solid ${theme.LIGHTEN_GRAY}` : 'none'};

    > div {
      font-size: ${props => sizes[props.size].fontSize};
      font-family: BryantRegular;

      color: ${props => (props.inverted ? theme.BLACK : theme.WHITE)};

      &:first-child {
        margin-top: 10px;
      }

      &:hover {
        color: ${theme.WHITE};
        background-color: ${theme.BASE_COLOR};
      }
    }
  }

  @media ${device.mediaQuery.SMALLER_THAN_448} {
    height: 38px;

    .rdd-control {
      padding: 8px 10px;
    }

    .rdd-placeholder {
      font-size: ${props => sizes[props.size].fontSize};
      font-family: BryantRegular;
    }

    .rdd-menu {
      top: ${props => (!props.inHeader && '28px')};
      border: ${props =>
        props.bordered ? `1px solid ${theme.LIGHTEN_GRAY}` : 'none'};
      border-radius: ${props => (props.rounded ? 8 : 0)}px;
      box-shadow: ${props => (props.inHeader ? '0 0 5px 0 rgba(0, 0, 0, 0.15)' : 'none')};

      > div {
        font-size: ${props => (props.inHeader ? '13px' : '18px')};
        font-family: BryantRegular;

        &:hover {
          color: ${theme.WHITE};
          background-color: ${theme.BASE_COLOR};
        }
      }
    }
  }
`;
