/** @format */

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  FullScreen,
  ExtraFieldsAndSpecialNeeds as CExtraFieldsAndSpecialNeeds,
  Modal,
} from '@components';
import {
  setAccountSettingsFormState,
  setAccountSettingsAndDynamicFields,
  update,
} from '@slices/auth';
import { useHistory, useLocation } from 'react-router';
import { Portal } from 'react-portal';

const ExtraFieldsAndSpecialNeeds = () => {
  const {
    register,
    trigger,
    errors,
    formState: { isValid },
  } = useForm({
    mode: 'onBlur',
  });

  const state = useSelector(store => store.auth.accountSettings);
  const { largeScreen, user, selectedOrganization, dynamicTextFields } =
    useSelector(store => store.app);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { nextRoute } = location.state || {};

  useEffect(() => {
    setTimeout(() => {
      localStorage.removeItem('orgId');
    }, 2000);
    dispatch(setAccountSettingsAndDynamicFields(user));
  }, [dispatch, user]);

  const onChangeShowSpecialNeed = selected => {
    let showSpecialNeed = selected;

    if (!showSpecialNeed) {
      dispatch(
        setAccountSettingsFormState({
          name: 'specialNeeds',
          value: [],
        }),
      );
    }

    showSpecialNeed = showSpecialNeed ? 'Yes' : 'No';

    dispatch(
      setAccountSettingsFormState({
        name: 'showSpecialNeed',
        value: showSpecialNeed,
      }),
    );
  };

  const onChangeSpecialNeeds = selected => {
    if (!selected) {
      dispatch(
        setAccountSettingsFormState({
          name: 'specialNeeds',
          value: [],
        }),
      );
      return;
    }
    const needs = selected.map(need => need.value);
    dispatch(
      setAccountSettingsFormState({
        name: 'specialNeeds',
        value: needs,
      }),
    );
  };

  const onChange = async e => {
    const { name, value } = e.target;
    if (state.formState[name] === undefined) {
      dispatch(
        setAccountSettingsFormState({
          name: 'detail',
          value: { ...state.formState.detail, [name]: value },
        }),
      );
    } else {
      dispatch(
        setAccountSettingsFormState({
          name,
          value,
        }),
      );
    }

    await trigger(name);
  };

  const onSubmit = () => {
    if (!isValid) {
      trigger();
      return;
    }
    dispatch(update({ addressUpdated: false }, goBack));
  };

  const goBack = () => {
    const disableAddressesAfterSignup = process.env.REACT_APP_DISABLE_ADDRESSES_SIGNUP === 'true';
    const openInviteFriendsAfterFirstLoginOrSignup = JSON.parse(localStorage.getItem('openInviteFriendsAfterFirstLoginOrSignup'));

    if (nextRoute) {
      history.push(nextRoute, {
        nextRoute: '/',
      });
    } else if (!disableAddressesAfterSignup) {
      history.push({
        pathname: '/account-settings/addresses',
        state: { source: 'signup' },
      });
    } else if (openInviteFriendsAfterFirstLoginOrSignup && selectedOrganization !== null) {
      localStorage.removeItem('openInviteFriendsAfterFirstLoginOrSignup');
      history.push('/invite-friends');
    } else {
      history.push('/');
    }
  };

  const form = (
    <CExtraFieldsAndSpecialNeeds
      onChangeSpecialNeeds={onChangeSpecialNeeds}
      onChangeShowSpecialNeed={onChangeShowSpecialNeed}
      errors={errors}
      validate={register}
      state={state.formState}
      onChange={onChange}
      loading={state.loading}
      isValid={isValid}
      onSubmit={onSubmit}
      goBack={goBack}
      dynamicTextFields={dynamicTextFields}
    />
  );

  return largeScreen ? (
    <Portal>
      <Modal
        id="extra-fields-modal"
        height="580"
        width="400"
        open
        toggle={goBack}
      >
        {form}
      </Modal>
    </Portal>
  ) : (
    <FullScreen id="extra-fields-full-screen" headerHeight={0}>
      {form}
    </FullScreen>
  );
};

export default ExtraFieldsAndSpecialNeeds;
