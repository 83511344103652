/** @format */

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Col, Row } from '@components';
import { theme } from '@constants';
import { styles as styleUtils } from '@utils';

export const TripTypeTabsRow = styled(Row)`
  border-radius: 100px;
  margin-bottom: 15px;
  background-color: ${styleUtils.hexToRgba(theme.BASE_COLOR, 0.5)};
  position: relative;
`;

export const ActiveIndicator = styled.div`
  transition: left 0.2s ease-in-out;
  width: ${props => `${props.width}%`};
  border-radius: 100px;
  height: 100%;
  background-color: ${theme.BASE_COLOR};
  height: 34px;
  position: absolute;
  left: ${props => `${props.selectedIndex * props.width}%`};
  z-index: 5;
`;

export const ClickHandler = styled.button`
  width: 100%;
  padding: 0;
  border: none;
  background-color: ${theme.TRANSPARENT};

  :focus {
    outline: none;
  }
`;

export const TripTypeCol = styled(Col)`
  border-radius: 100px;
  height: 34px;
`;

export const TripTypeText = styled.span`
  color: ${theme.WHITE};
  position: relative;
  z-index: 6;
`;

export const TripTypeIcon = styled(FontAwesomeIcon)`
  color: ${theme.WHITE};
  position: relative;
  z-index: 6;
`;

export const TripTypeTextSmall = styled(TripTypeText)`
  font-size: 0.7em;
`;

export const MessageText = styled.span`
  color: ${theme.TOMATO};
  font-size: 16px;
  margin-bottom: 10px;
`;
