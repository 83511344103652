/** @format */

// 3rd party imports
import React from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// internal Imports
import { Row, Modal, RadioGroup } from '@components';
import { theme } from '@constants';
import {
  ButtonContainer,
  ActionButton,
  ModalContentWrapper,
  Heading,
  WorkplaceForm,
  Paragraph,
} from './styled';

const WorkplaceSelectionForm = ({
  loading,
  options,
  selectedWorkplace,
  onSelect,
  onConfirm,
  largeScreen,
  firstLoggedIn,
}) => {
  const { t } = useTranslation();

  return (
    <Modal open width={largeScreen ? '400' : null} margin={largeScreen ? '0' : '10'} overflow="visible" borderRadius="24">
      <WorkplaceForm>
        <ModalContentWrapper>
          <Heading>
            {firstLoggedIn ? t('workplace-select-form.labels.choose-workplace') : t('workplace-select-form.labels.title')}
          </Heading>
          <Paragraph marginBottom={-20}>
            {t('workplace-select-form.labels.work-at-location')}
          </Paragraph>
          <Row align="flex-start" justify="center" spacingV={14}>
            <RadioGroup
              id="workplace-options"
              onChange={(e, value) => onSelect(`${value}`)}
              name="workplaceId"
              data-cy="org-workplaces"
              options={options}
              buttonsFlow="column"
              checked={selectedWorkplace}
              showOverlay
              alignItems="center"
              marginTopInputLabel="0.5"
            />
          </Row>
          <Paragraph>{t('workplace-select-form.labels.message')}</Paragraph>
          <ButtonContainer width="95%">
            <Row justify="end" spacingV={8}>
              <ActionButton
                id="workplace-confirm-btn"
                data-cy="workplace-confirm-btn"
                disabled={!selectedWorkplace || loading}
                label={t('workplace-select-form.buttons.confirm')}
                color={theme.BASE_COLOR}
                size="md"
                onClick={onConfirm}
                icon={
                  loading
                    ? faSpinner
                    : null
                }
                iconProps={{ spin: true }}
                bordered
              />
            </Row>
          </ButtonContainer>
        </ModalContentWrapper>
      </WorkplaceForm>
    </Modal>
  );
};

WorkplaceSelectionForm.propTypes = {
  loading: propTypes.bool.isRequired,
  options: propTypes.array.isRequired,
  selectedWorkplace: propTypes.string,
  onSelect: propTypes.func.isRequired,
  onConfirm: propTypes.func.isRequired,
  largeScreen: propTypes.bool.isRequired,
  firstLoggedIn: propTypes.bool.isRequired,
};
WorkplaceSelectionForm.defaultProps = {
  selectedWorkplace: null,
};

export default WorkplaceSelectionForm;
