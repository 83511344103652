/** @format */

import { v4 as uuidv4 } from 'uuid';

const getMixpanelId = () => {
  let mixpanelId = null;
  mixpanelId = localStorage.getItem('mixpanelId');
  if (!mixpanelId) {
    mixpanelId = uuidv4();
    localStorage.setItem('mixpanelId', mixpanelId);
  }

  return mixpanelId;
};

const setMixpanelId = mixpanelId => {
  localStorage.setItem('mixpanelId', mixpanelId);
};

const destroyMixpanelId = () => {
  localStorage.removeItem('mixpanelId');
};

export default {
  getMixpanelId,
  destroyMixpanelId,
  setMixpanelId,
};
