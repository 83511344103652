/** @format */
import { createSlice } from '@reduxjs/toolkit';

import {
  helpers,
  userHelpers,
  placesTimingHelpers,
  styles as styleUtils,
  api as apiClient,
  toast,
  placeHelpers,
  browserHelpers,
} from '@utils';
import {
  api,
  generals as generalConstants,
  localStorageKeys,
  analytics,
} from '@constants';
import { leaflet } from '@services';
import { authenticateRider } from '@slices/auth';
import { setSearchQuery } from '@slices/tripRequest';
import {
  setSearchQuery as setWorkforceSearchQuery,
  // code for map zoom functionality
  // fetchTripRequests,
  setShowUnauthenticated as setShowUnauthenticatedOptions,
} from '@slices/tripRequestWorkforce';
import { getTranslatedStringFromKey } from '@i18Loader';
import moment from 'moment';

const user = JSON.parse(localStorage.getItem('user'));
const selectedOrganization =
  user?.OrganizationUsers?.length > 0
    ? userHelpers.getSelectedOrgIndex(user)
    : null;
const userAgent = JSON.parse(localStorage.getItem('userAgent'));
const welcomed = !window.location.pathname.includes('/fixed-route')
  ? JSON.parse(localStorage.getItem('welcomed'))
  : true;
const userDefaultLanguage =
  localStorage.getItem(localStorageKeys.USER_LANGUAGE) ||
  process.env.REACT_APP_LANGUAGE;
const workforceEnv = process.env.REACT_APP_WORKFORCE_RIDER_APP === 'true';
const workforceSearch = process.env.REACT_APP_WORKFORCE_APP === '1';

export const defaultSignupOrgServiceAreaState = {
  formState: {
    email: '',
    zipCode: '',
  },
  loading: false,
  dialogOpened: false,
  zipCodeFound: false,
};

export const defaultState = {
  user,
  userAgent,
  currentLocation: null,
  language: userDefaultLanguage,
  largeScreen: styleUtils.isLargeScreen(),
  workforceRiderApp: workforceEnv,
  organizations: userHelpers.getUserOrganizations(user),
  selectedOrganization,
  organizationPlaces: [],
  signupOrganizationPlaces: [],
  modals: {
    signup: false,
    login: false,
    welcome: !welcomed,
    orgWelcome: false,
    workInProgress: false,
    bookingCancelled: false,
    confirmation: false,
    forgotPassword: false,
    savedAddresses: false,
    ratingSubmitted: false,
    selectedOrgPlaceMarker: false,
  },
  tripMapMarkers: {
    pickup: null,
    destination: null,
    vehicle: null,
  },
  selectedOrganizationPlaceMarker: null,
  showOrgPlaceMarkerSlider: false,
  busStopMarkers: [],
  personalAddressesMarker: [],
  visibleMarker: [],
  riders: [],
  selectedRider: 0,
  tripPath: {
    share: [],
    transit: [],
  },
  resizeMapKey: 1,
  signupOrganizations: null,
  signupOrgServiceArea: defaultSignupOrgServiceAreaState,
  passwordlessAuthenticationType: null,
  passwordlessResourceLoading: true,
  referralCodeLoading: true,
  generateQRCodeLoading: false,
  restrictedMode: false,
  dynamicTextFields: [],
  updateWorkplaceLoading: false,
};

const AppSlice = createSlice({
  name: 'app',
  initialState: defaultState,
  reducers: {
    setUser(state, action) {
      const { payload } = action;

      return {
        ...state,
        user: payload,
      };
    },
    setUserAgent(state, action) {
      const { payload } = action;

      return {
        ...state,
        userAgent: payload,
      };
    },
    setCurrentLocation(state, action) {
      const { payload: currentLocation } = action;

      return {
        ...state,
        currentLocation,
      };
    },
    setAppLanguage(state, action) {
      const { payload: language } = action;

      return {
        ...state,
        language,
      };
    },
    setLargeScreen(state, action) {
      const { payload: largeScreen } = action;

      return {
        ...state,
        largeScreen,
      };
    },
    setOrganizationsFromUser(state, action) {
      const { payload: userObj } = action;
      const organizations = userHelpers.getUserOrganizations(userObj);

      return {
        ...state,
        organizations,
      };
    },
    setOrganizations(state, action) {
      const { payload } = action;

      return {
        ...state,
        organizations: payload,
      };
    },
    setSelectedOrganization(state, action) {
      const { payload: selectedOrganization } = action;

      if (!state.organizations?.length) {
        return {
          ...state,
          selectedOrganization: null,
        };
      }

      return {
        ...state,
        selectedOrganization,
      };
    },
    setOrganizationPlaces(state, action) {
      const { payload: organizationPlaces } = action;

      return {
        ...state,
        organizationPlaces,
      };
    },
    setOrganizationDetail(state, action) {
      const { detail } = action.payload;
      const organizations = JSON.parse(JSON.stringify(state.organizations));
      if (
        Array.isArray(organizations) &&
        state.selectedOrganization !== null &&
        state.selectedOrganization < organizations.length
      ) {
        organizations[state.selectedOrganization].detail = detail;
      }
      return {
        ...state,
        organizations,
      };
    },
    openModal(state, action) {
      const { modal, value } = action.payload;

      return {
        ...state,
        modals: {
          ...state.modals,
          [modal]: value || true,
        },
      };
    },
    closeModal(state, action) {
      const { modal } = action.payload;
      return {
        ...state,
        modals: {
          ...state.modals,
          [modal]: false,
        },
      };
    },
    setTripMapMarkers(state, action) {
      const { payload: tripMapMarkers } = action;

      return {
        ...state,
        tripMapMarkers: {
          ...state.tripMapMarkers,
          ...tripMapMarkers,
        },
      };
    },

    setSelectedOrganizationPlaceMarker(state, action) {
      let { payload } = action;

      payload = {
        ...payload,
        shouldFlyToMarker:
          typeof payload?.shouldFlyToMarker === 'boolean'
            ? payload?.shouldFlyToMarker
            : true,
        shouldScrollToCard:
          typeof payload?.shouldScrollToCard === 'boolean'
            ? payload?.shouldScrollToCard
            : true,
      };

      return {
        ...state,
        selectedOrganizationPlaceMarker: payload,
        showOrgPlaceMarkerSlider: true,
      };
    },

    setShowOrgPlaceMarkerSlider(state, action) {
      return {
        ...state,
        showOrgPlaceMarkerSlider: action.payload,
      };
    },

    setPersonalAddressesMarkers(state, action) {
      const { payload: personalAddressesMarker } = action;

      return {
        ...state,
        personalAddressesMarker,
      };
    },
    setBusStopMarkers(state, action) {
      const { payload: busStopMarkers } = action;

      return {
        ...state,
        busStopMarkers,
      };
    },
    setRiders(state, action) {
      const { payload: riders } = action;
      return {
        ...state,
        riders,
      };
    },

    setSelectedRider(state, action) {
      const { payload: selectedRider } = action;
      return {
        ...state,
        selectedRider,
      };
    },

    setVisibleMarker(state, action) {
      let { payload: visibleMarker } = action;
      if (visibleMarker == null) {
        visibleMarker = [];
      }
      return {
        ...state,
        visibleMarker,
      };
    },

    setFullScreenLoading(state, action) {
      const { payload } = action;

      return {
        ...state,
        fullScreenLoading: payload,
      };
    },

    setTripPath(state, action) {
      const {
        payload: { share, transit },
      } = action;

      return {
        ...state,
        tripPath: {
          share,
          transit,
        },
      };
    },

    clearTripPath(state) {
      return {
        ...state,
        tripPath: {
          share: [],
          transit: [],
        },
      };
    },

    resetMapAfterResize(state) {
      return {
        ...state,
        resizeMapKey: state.resizeMapKey === 1 ? 2 : 1, // 1 = collapsed, 2 = expanded
      };
    },
    setSignupOrganizations(state, action) {
      const { payload } = action;
      return {
        ...state,
        signupOrganizations: payload,
      };
    },

    setSignupOrgServiceAreaPlaces(state, action) {
      const { payload } = action;
      return {
        ...state,
        signupOrganizationPlaces: payload,
      };
    },

    setSignupOrgServiceAreaForm(state, action) {
      const { payload } = action;

      return {
        ...state,
        signupOrgServiceArea: {
          ...state.signupOrgServiceArea,
          formState: {
            ...state.signupOrgServiceArea.formState,
            [payload.name]: payload.value,
          },
        },
      };
    },

    setSignupOrgServiceAreaFormLoading(state, action) {
      const { payload } = action;

      return {
        ...state,
        signupOrgServiceArea: {
          ...state.signupOrgServiceArea,
          loading: payload,
        },
      };
    },

    updateSignupOrgServiceAreaOpenDialog(state, action) {
      const { payload } = action;

      return {
        ...state,
        signupOrgServiceArea: {
          ...state.signupOrgServiceArea,
          dialogOpened: payload,
        },
      };
    },

    signupOrgServiceAreaUpdateFormZipCodeFound(state, action) {
      const { payload } = action;

      return {
        ...state,
        signupOrgServiceArea: {
          ...state.signupOrgServiceArea,
          zipCodeFound: payload,
        },
      };
    },
    signupOrgServiceAreaResetFormState(state, action) {
      const { payload } = action;

      return {
        ...state,
        signupOrgServiceArea: {
          ...state.signupOrgServiceArea,
          formState: {
            ...state.signupOrgServiceArea.formState,
            [payload]: '',
          },
        },
      };
    },

    signupOrgServiceAreaResetSignupForm(state) {
      return {
        ...state,
        signupOrgServiceArea: defaultSignupOrgServiceAreaState,
      };
    },

    setPasswordlessAuthenticationType(state, action) {
      const { payload } = action;

      return {
        ...state,
        passwordlessAuthenticationType: payload,
      };
    },
    setPasswordlessResourceLoading(state, action) {
      const { payload } = action;
      return {
        ...state,
        passwordlessResourceLoading: payload,
      };
    },
    resetAppState() {
      return {
        ...defaultState,
      };
    },
    setReferralCodeLoading(state, action) {
      return {
        ...state,
        referralCodeLoading: action.payload,
      };
    },
    setGenerateQRCodeLoading(state, action) {
      return {
        ...state,
        generateQRCodeLoading: action.payload,
      };
    },
    setUpdateWorkplaceLoading(state, action) {
      return {
        ...state,
        updateWorkplaceLoading: action.payload,
      };
    },
    setRestrictedMode(state, action) {
      return {
        ...state,
        restrictedMode: action.payload,
      };
    },
    setDynamicTextFields(state, action) {
      return {
        ...state,
        dynamicTextFields: action.payload,
      };
    },
  },
});

const processPlace = place => {
  const daysReadyBy = [];
  const daysArriveBy = [];
  const readyByTimes = {
    mon: [],
    tue: [],
    wed: [],
    thu: [],
    fri: [],
    sat: [],
    sun: [],
  };
  const arriveByTimes = {
    mon: [],
    tue: [],
    wed: [],
    thu: [],
    fri: [],
    sat: [],
    sun: [],
  };

  const { offers } = place;

  offers.forEach(offer => {
    if (offer.type === 'Ready By') {
      generalConstants.DAYS.forEach(day => {
        if (offer[day]) {
          daysReadyBy.push(day);
          readyByTimes[generalConstants.FULL_TO_MINIMAL_DAY_MAPPING[day]].push(
            offer.time,
          );
        }
      });
    }

    if (offer.type === 'Arrive By') {
      generalConstants.DAYS.forEach(day => {
        if (offer[day]) {
          daysArriveBy.push(day);
          arriveByTimes[generalConstants.FULL_TO_MINIMAL_DAY_MAPPING[day]].push(
            offer.time,
          );
        }
      });
    }
  });

  Object.keys(readyByTimes).forEach(key => {
    readyByTimes[key] = [...new Set(readyByTimes[key])];
  });

  Object.keys(arriveByTimes).forEach(key => {
    arriveByTimes[key] = [...new Set(arriveByTimes[key])];
  });

  return {
    availableDaysReadyBy: [...new Set(daysReadyBy)],
    availableDaysArriveBy: [...new Set(daysArriveBy)],
    availableTimes: {
      readyBy: readyByTimes,
      arriveBy: arriveByTimes,
    },
  };
};

export const getFormattedOrganizationPlaces = data => {
  const formattedData = [];
  data.forEach(place => {
    if (place.place?.location && place.type !== 'GTFS_STOP') {
      if (place.offers?.length) {
        const {
          availableDaysArriveBy,
          availableDaysReadyBy: availableDays,
          availableTimes,
        } = processPlace(place);
        formattedData.push({
          organizationId: place.organizationId,
          availableDays,
          availableDaysArriveBy,
          availableTimes,
          name: place.name,
          label: place.place.address,
          lat: Number(place.place.location.lat),
          lng: Number(place.place.location.lon),
          timezone: placeHelpers.getTimezoneFromPlaceLocation(place.place),
          type: place.type,
          shifts: place.shifts,
          orgPlaceId: place.id,
        });
      }
    }
  });

  return placesTimingHelpers.sortPlacesByTime(formattedData);
};

export const fetchOrgPlaces = () => {
  return async (dispatch, getState) => {
    try {
      const { organizations, selectedOrganization } = getState().app;

      if (
        Array.isArray(organizations) &&
        selectedOrganization !== null &&
        selectedOrganization < organizations.length
      ) {
        const { organizationId } = organizations[selectedOrganization] || {};

        const { data: response } = await apiClient.get(
          api.endpoints.GET_FILTERED_ORGANIZATION_PLACES.replace(
            ':id',
            organizationId,
          ),
        );

        // eslint-disable-next-line no-underscore-dangle
        const places = response.data.map(p => p._source);
        dispatch(setOrganizationPlaces(getFormattedOrganizationPlaces(places)));

        const query = helpers.parseQueryString(window.location.search);
        if (query.pid) {
          const placeId = parseInt(query.pid, 10);

          const indexOfPlaceId = places.findIndex(
            place => place.placeId === placeId,
          );
          const place = places[indexOfPlaceId];

          if (place) {
            const { availableDaysReadyBy: availableDays, availableTimes } =
              processPlace(place);
            dispatch(
              setSearchQuery({
                key: 'destinationAddress',
                value: {
                  organizationId: place.organizationId,
                  availableDays,
                  availableTimes,
                  name: place.name,
                  label: place.place.address,
                  lat: Number(place.place.location.lat),
                  lng: Number(place.place.location.lon),
                  timezone: place.timezone || place.place.timezone,
                },
              }),
            );
          }
        }

        dispatch(
          trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
            eventName: analytics.ORGANIZATION_PLACES_LOADED,
            subJourneyName: 'Places',
            journeyName: 'Rider Experience',
            details: {
              places,
              organizationId,
            },
          }),
        );
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('FETCH_ORG_PLACES_ERROR', error);
    }
  };
};

export const fetchOrgDetails = () => {
  return async (dispatch, getState) => {
    const { organizations, selectedOrganization, user } = getState().app;

    if (
      Array.isArray(organizations) &&
      selectedOrganization !== null &&
      selectedOrganization < organizations.length
    ) {
      const { organizationId } = organizations[selectedOrganization] || {};
      const { data } = await apiClient.get(
        api.endpoints.GET_ORGANIZATION_DETAILS.replace(':id', organizationId),
      );

      const dynamicFieldsForSelectedOrg = data?.detail?.userDynamicFields;
      if (Array.isArray(dynamicFieldsForSelectedOrg)) {
        dispatch(setDynamicTextFields(dynamicFieldsForSelectedOrg));
      }

      if (data.squareUpAppId && data.squareUpLocationId) {
        data.detail = {
          ...data.detail,
          squareUpAppId: data.squareUpAppId,
          squareUpLocationId: data.squareUpLocationId,
          bookingMethods: data.bookingMethods,
        };
      }

      dispatch(setOrganizationDetail({ detail: data.detail }));
      const orgs = [...organizations];
      const selectedOrgIndex = orgs.findIndex(
        o => o.organizationId === organizationId || o.id === organizationId,
      );
      const org = { organization: { ...data }, detail: data.detail };
      orgs[selectedOrgIndex] = { ...orgs[selectedOrgIndex], ...org };
      dispatch(setOrganizations(orgs));

      const { city, state } = data.detail || {};
      let results = null;
      if (city && state) {
        const query = `${city}, ${state}`;
        results = await leaflet.provider().search({ query });
      }
      let viewbox = null;
      if (results && results.length > 0) {
        viewbox = results[0].bounds.join(',');
      }
      const { detail = {} } = data;
      localStorage.setItem('organizationLocationViewBox', viewbox);
      if (detail && detail.passwordRotationEnabled) {
        const orgDefinedDuration = detail.passwordRotationDuration || 0;
        const passwordUpdatedAt = moment(user.passwordUpdatedAt);
        if (moment.now() > passwordUpdatedAt.add(orgDefinedDuration, 'days')) {
          dispatch(setRestrictedMode(true));
        }
      }
    }
  };
};

export const getNearbyBusStops = (currentLocation, radius = 2.5) => {
  return async (dispatch, getState) => {
    const { organizations, selectedOrganization } = getState().app;
    if (
      Array.isArray(organizations) &&
      selectedOrganization !== null &&
      selectedOrganization < organizations.length
    ) {
      const { organization } = organizations[selectedOrganization] || {};
      if (
        organization.detail?.transit?.enabled &&
        (organization.detail?.tripRequestTypes?.includes('TRANSIT') ||
         organization.detail?.tripRequestTypes?.includes('TRANSIT_SHARE')) &&
        currentLocation !== null
      ) {
        const [lat, lng] = currentLocation;
        try {
          const { data: stops } = await apiClient.post(
            api.endpoints.GET_NEARBY_BUS_STOPS,
            {
              lat,
              lng,
              radius,
            },
          );
          const busStopMarkers = stops.map(stop => ({
            id: stop.id,
            lat: stop.lat,
            lng: stop.lng,
            label: stop.address,
            buses: stop.buses,
            distance: stop.distance,
            name: stop.stopName,
          }));
          dispatch(setBusStopMarkers(busStopMarkers));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('GET_NEARBY_BUS_STOPS_ERROR', error);
        }
      }
    }
  };
};

export const getRidersList = () => {
  return async (dispatch, getState) => {
    const { userAgent: userAgentObj } = getState().app;
    const authToken = localStorage.getItem('userAgentAuthToken');

    const { data } = await apiClient.get(
      api.endpoints.GET_RIDERS_LIST.replace(':id', userAgentObj.id),
      {
        headers: {
          'x-auth': authToken,
        },
      },
    );
    const [rider] = data.data;
    dispatch(setRiders(data.data));

    dispatch(authenticateRider(rider?.user?.id || rider?.User?.id));
  };
};

export const trackEvent = (eventName, properties = {}, callback) => {
  return async (dispatch, getState) => {
    const envIdentifier = process.env.REACT_APP_ENV_IDENTIFIER;
    const server = process.env.REACT_APP_SERVER;
    const isWorkForceApp = process.env.REACT_APP_WORKFORCE_RIDER_APP;
    const data = {
      eventName,
      properties: {
        ...properties,
        server,
        envIdentifier,
        appType: isWorkForceApp ? 'workforce' : 'normal',
      },
    };

    const { user, organizations, selectedOrganization } = getState().app;
    if (user) {
      data.properties.userId = user.id;
      data.properties.userName = user.name;
    }

    const selectedOrg =
      Array.isArray(organizations) &&
      organizations.length &&
      typeof selectedOrganization === 'number' &&
      organizations[selectedOrganization];
    if (selectedOrg) {
      data.properties.orgId = selectedOrg.organization.id;
      data.properties.orgName = selectedOrg.organization.name;
    }

    const { referrer } = document;
    if (referrer) {
      data.properties.referrer = referrer;
    }

    if (process.env.NODE_ENV === 'test') {
      if (callback && typeof callback === 'function') {
        callback();
      }

      return [];
    }

    const result = await apiClient.post(api.endpoints.ANALYTICS, data);
    if (callback && typeof callback === 'function') {
      callback();
    }

    return result.data;
  };
};

export const trackAbEvent = (featureName, payload, callback) => {
  return async (dispatch, getState) => {
    const { user, organizations, selectedOrganization, currentLocation } = getState().app;

    const selectedOrg =
      Array.isArray(organizations) &&
      organizations.length &&
      typeof selectedOrganization === 'number' &&
      organizations[selectedOrganization];

    const data = {
      eventName: featureName,
      properties: {
        journeyName: payload.journeyName,
        subJourneyName: payload.subJourneyName,
        role: 'User',
        isExisting: user ? helpers.isExistingUser(user) : null,
        eventName: payload.eventName,
        isWorkForceApp: !!workforceSearch,
      },
    };

    if (user) {
      data.properties.user = {
        id: user.id,
        email: user.email,
      };
    }
    if (selectedOrg) {
      data.properties.organization = [{
        id: selectedOrg.organization.id,
        name: selectedOrg.organization.name,
      }];
    }

    if (currentLocation) {
      data.properties.locationData = {
        latitude: currentLocation[0],
        longitude: currentLocation[1],
      };
    }

    data.properties.occurredAt = moment().format('YYYY-MM-DDTHH:MM:SS');
    // platform can be mobile, desktop, tablet
    data.properties.segmentationData = {
      platform: browserHelpers.checkIfOnMobile() ? 'mobile' : 'desktop',
      deviceInformation: browserHelpers.detectBrowser(),
    };

    if (payload.elementId) {
      data.properties.uiData =
        browserHelpers.getElementPropForTracking(
          payload.elementId,
          payload.isDataCy,
        ) || null;
    } else if (payload.uiData) {
      data.properties.uiData = payload.uiData;
    }
    data.properties.details = payload.details || null;
    if (process.env.NODE_ENV === 'test') {
      return [];
    }
    // if ab testing is enabled we change the header for mixpanel-id
    const result = await apiClient.post(api.endpoints.AB_ANALYTICS, data);
    if (callback && typeof callback === 'function') {
      callback();
    }

    return result.data;
  };
};

export const fetchSignupOrganizations = (id = '', referralCode = '') => {
  return async dispatch => {
    const orgData = [];
    const paramId = id && referralCode ? id : false;
    const organizationDetails = generalConstants.ORG_SIGNUP_IDS.map(orgId =>
      apiClient.get(
        api.endpoints.GET_ORGANIZATION_DETAILS.replace(
          ':id',
          !paramId ? orgId : paramId,
        ),
      ),
    );
    const responses = await Promise.all(organizationDetails);
    responses.forEach(response => {
      const { data } = response;
      orgData.push({
        id: id && referralCode ? id : data.id,
        name: data.name,
        restrictPassword: data.passwordRestrictionsEnable,
      });
    });
    dispatch(setSignupOrganizations(orgData));
    sessionStorage.setItem(
      localStorageKeys.SIGNUP_ORGANIZATION,
      JSON.stringify(orgData[0]),
    );
  };
};

export const fetchSignupOrganizationsPlaces = () => {
  return async (dispatch, getState) => {
    try {
      let placesData = [];
      const { signupOrganizations } = getState().app;

      if (signupOrganizations && signupOrganizations.length > 0) {
        const organizationPlaces = signupOrganizations.map(org =>
          apiClient.get(
            api.endpoints.GET_ORGANIZATION_PLACES_WITHOUT_AUTH.replace(
              ':id',
              org.id,
            ),
          ),
        );
        const responses = await Promise.all(organizationPlaces);
        responses.forEach(response => {
          const { data } = response;
          // eslint-disable-next-line no-underscore-dangle
          const orgPlaces = data.data.map(o => o._source);
          placesData = placesData.concat(orgPlaces);
        });
        const data = getFormattedOrganizationPlaces(placesData);
        dispatch(setSignupOrgServiceAreaPlaces(data));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('FETCH_ORG_PLACES_ERROR', error);
    }
  };
};

export const getWorkForceOrganizationsPlaces = () => {
  return async dispatch => {
    try {
      dispatch(setFullScreenLoading(true));
      const workForceOrgIds =
        process.env.REACT_APP_WORKFORCE_ORG_IDS.split(',');
      const organizationPlacesData = workForceOrgIds.map(org =>
        apiClient.get(
          api.endpoints.GET_ORGANIZATION_PLACES_WITHOUT_AUTH.replace(
            ':id',
            org,
          ),
        ),
      );
      const responses = await Promise.all(organizationPlacesData);
      let allOrganizationPlaces = [];
      let places = [];
      responses.forEach(response => {
        const { data } = response;
        // eslint-disable-next-line no-underscore-dangle
        const orgPlaces = data.data.map(o => o._source);
        places = [...places, ...orgPlaces.map(({ id }) => id)];
        allOrganizationPlaces = [
          ...allOrganizationPlaces,
          ...getFormattedOrganizationPlaces(orgPlaces),
        ];
      });

      dispatch(setOrganizationPlaces(allOrganizationPlaces));
      dispatch(setWorkforceSearchQuery({ key: 'placeIds', value: places }));
      // code for map zoom functionality
      // dispatch(fetchTripRequests());
      dispatch(setFullScreenLoading(false));
      dispatch(setShowUnauthenticatedOptions(true));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('FETCH_ORG_PLACES_ERROR', error);
    }
  };
};

export const checkZipCodeExists = () => {
  return async (dispatch, getState) => {
    try {
      const {
        signupOrganizations,
        signupOrgServiceArea: {
          formState: { zipCode },
        },
      } = getState().app;

      dispatch(setSignupOrgServiceAreaFormLoading(true));

      const zipCodes = signupOrganizations.map(org =>
        apiClient.get(
          api.endpoints.GET_ZIPCODE.replace(':orgId', org.id).replace(
            ':zipcode',
            zipCode,
          ),
        ),
      );
      const promisesResponce = await Promise.all(zipCodes);
      let isValid = false;
      promisesResponce.forEach(res => {
        if (res.data.valid) {
          isValid = true;
        }
      });

      if (isValid) {
        dispatch(updateSignupOrgServiceAreaOpenDialog(true));
        dispatch(signupOrgServiceAreaUpdateFormZipCodeFound(true));
        dispatch(setSignupOrgServiceAreaFormLoading(false));
      } else {
        dispatch(updateSignupOrgServiceAreaOpenDialog(true));
        dispatch(signupOrgServiceAreaUpdateFormZipCodeFound(false));
        dispatch(setSignupOrgServiceAreaFormLoading(false));
      }
    } catch (e) {
      dispatch(updateSignupOrgServiceAreaOpenDialog(true));
      dispatch(signupOrgServiceAreaUpdateFormZipCodeFound(false));
      dispatch(setSignupOrgServiceAreaFormLoading(false));
    }
  };
};

export const submitRequestForZipCode = () => {
  return async (dispatch, getState) => {
    try {
      const {
        signupOrganizations,
        signupOrgServiceArea: {
          formState: { email, zipCode },
        },
      } = getState().app;

      dispatch(setSignupOrgServiceAreaFormLoading(true));
      const zipCodes = await signupOrganizations.map(org =>
        apiClient.post(
          api.endpoints.SUBMIT_ZIPCODE_REQUEST.replace(':id', org.id),
          { email, zipCode },
        ),
      );

      const promisesResponce = await Promise.all(zipCodes);
      let sent = false;
      promisesResponce.forEach(res => {
        if (res.data.email) {
          sent = true;
        }
      });

      if (sent) {
        toast.show(
          'success',
          getTranslatedStringFromKey('messages.success.request-submitted'),
        );
      }

      dispatch(updateSignupOrgServiceAreaOpenDialog(false));
      dispatch(setSignupOrgServiceAreaFormLoading(false));
      dispatch(signupOrgServiceAreaResetFormState('email'));
    } catch (e) {
      dispatch(updateSignupOrgServiceAreaOpenDialog(false));
      dispatch(setSignupOrgServiceAreaFormLoading(false));
    }
  };
};

export const generateReferralCode = () => {
  return async (dispatch, getState) => {
    const { user } = getState().app;
    dispatch(setReferralCodeLoading(true));
    try {
      const {
        data: { referralCode },
      } = await apiClient.get(
        api.endpoints.GENERATE_REFERRAL_CODE.replace(':id', user.id),
      );
      dispatch(setUser({ ...user, referralCode }));
      localStorage.setItem('user', JSON.stringify({ ...user, referralCode }));
      dispatch(setReferralCodeLoading(false));
    } catch (error) {
      console.log('GENERATE_REFERRAL_CODE_ERROR', error);
      dispatch(setReferralCodeLoading(false));
    }
  };
};

export const renderQRPrint = (url) => {
  helpers.QRPrint(url);
};

export const generateQRCode = (id, channel = null) => {
  return async (dispatch, getState) => {
    const { user } = getState().app;
    dispatch(setGenerateQRCodeLoading(true));
    try {
      const {
        data: { url },
      } = await apiClient.put(
        api.endpoints.GENERATE_QR_CODE.replace(':id', id),
        { channel },
      );
      let body;
      if (channel === 'print (user)') {
        body = {
          ...user,
          detail: {
            ...user.detail,
            printQRCode: url,
          },
        };
      } else {
        body = {
          ...user,
          authQRCode: url,
        };
      }
      dispatch(setUser(body));
      localStorage.setItem('user', JSON.stringify(body));
      dispatch(setGenerateQRCodeLoading(false));
      if (channel) renderQRPrint(url);
    } catch (error) {
      console.log('GENERATE_QR_CODE_ERROR', error);
      dispatch(setGenerateQRCodeLoading(false));
    }
  };
};

export const updateSelectedWorkplace = (orgPlaceId, showSuccessToast = true) => {
  return async (dispatch, getState) => {
    try {
      const { organizations, selectedOrganization } = getState().app;

      dispatch(setUpdateWorkplaceLoading(true));
      const orgs = [...organizations];
      const selectedUserOrg = orgs[selectedOrganization];

      await apiClient.put(
        api.endpoints.UPDATE_SELECTED_WORKPLACE.replace(':id', selectedUserOrg?.id),
        { workplaceId: orgPlaceId },
      );

      const org = { ...selectedUserOrg, workplaceId: orgPlaceId };
      orgs[selectedOrganization] = { ...org };
      dispatch(setOrganizations(orgs));
      dispatch(setUpdateWorkplaceLoading(false));

      if (showSuccessToast) {
        toast.show(
          'success',
          getTranslatedStringFromKey('messages.success.update-successful'),
        );
      }
    } catch (error) {
      console.log('UPDATE_WORKPLACE_ERROR', error);
      dispatch(setUpdateWorkplaceLoading(false));
    }
  };
};

export const {
  setUser,
  setUserAgent,
  setOrganizationDetail,
  setCurrentLocation,
  setAppLanguage,
  setLargeScreen,
  setOrganizations,
  setOrganizationsFromUser,
  setSelectedOrganization,
  setOrganizationPlaces,
  openModal,
  closeModal,
  setTripMapMarkers,
  setOrganizationPlacesMarkers,
  setSelectedOrganizationPlaceMarker,
  setShowOrgPlaceMarkerSlider,
  setBusStopMarkers,
  setPersonalAddressesMarkers,
  setVisibleMarker,
  setRiders,
  setSelectedRider,
  setFullScreenLoading,
  setTripPath,
  clearTripPath,
  resetMapAfterResize,
  setSignupOrganizations,
  setSignupOrgServiceAreaPlaces,
  setSignupOrgServiceAreaForm,
  updateSignupOrgServiceAreaOpenDialog,
  setSignupOrgServiceAreaFormLoading,
  signupOrgServiceAreaUpdateFormZipCodeFound,
  signupOrgServiceAreaResetFormState,
  signupOrgServiceAreaResetSignupForm,
  setPasswordlessAuthenticationType,
  resetAppState,
  setPasswordlessResourceLoading,
  setReferralCodeLoading,
  setGenerateQRCodeLoading,
  setRestrictedMode,
  setDynamicTextFields,
  setRedeemingRidershipBonusLoading,
  setUpdateWorkplaceLoading,
} = AppSlice.actions;

export const appReducer = AppSlice.reducer;
