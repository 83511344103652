/** @format */

import React, { useCallback, useState } from 'react';
import { Portal } from 'react-portal';
import { useHistory } from 'react-router';
import {
  OrganizationWelcome as COrganizationWelcome,
  Modal,
} from '@components';

const useOrgWelcomeDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [orgDetails, setOrgDetails] = useState(null);
  const history = useHistory();

  const openOrgWelcomeDialog = (foundItem, orgDetails) => {
    let orgWelcomeArray = JSON.parse(localStorage.getItem('orgWelcomeArray'));
    orgWelcomeArray = orgWelcomeArray.map(x => {
      if (x.id === foundItem.id) {
        return {
          ...x,
          welcomed: true,
        };
      }
      return x;
    });
    localStorage.setItem('orgWelcomeArray', JSON.stringify(orgWelcomeArray));
    setOrgDetails(orgDetails);
    setIsOpen(true);
  };

  const onCloseModal = () => {
    setIsOpen(false);
    if (orgDetails?.id) {
      history.push('/signup');
    }
  };

  const OrgWelcomeDialog = useCallback(
    () =>
      isOpen ? (
        <Portal>
          <Modal width="350">
            <COrganizationWelcome
              title={orgDetails && orgDetails.title}
              subtitle={orgDetails && orgDetails.subtitle}
              logo={orgDetails && orgDetails.logo}
              onClose={onCloseModal}
            />
          </Modal>
        </Portal>
      ) : null,

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen, orgDetails],
  );

  return {
    OrgWelcomeDialog,
    openOrgWelcomeDialog,
  };
};
export default useOrgWelcomeDialog;
