/** @format */

import styled from 'styled-components';
import { theme } from '@constants';
import { Row } from '..';

export const TripDetailsTitle = styled.h4`
  color: ${theme.BASE_COLOR};
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
`;

export const NameRow = styled(Row)`
  margin-top: 10px;
`;

export const NameText = styled.span`
  color: ${theme.BASE_COLOR};
  margin-left: -10px;
`;

export const NameTextBold = styled.span`
  color: ${theme.BASE_COLOR};
  font-weight: 600;
`;
