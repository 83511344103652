/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import {
  PlanATripLink,
  PlanATripRow,
  PlanATripText,
  AngleRightIcon,
} from './styled';

const PlanATrip = ({ user, t, ...restProps }) => (
  <PlanATripLink {...restProps} to={user ? '/trip-request' : '/signup'}>
    <PlanATripRow
      flex="initial"
      justify="space-between"
      align="center"
      spacingH={15}
    >
      <PlanATripText>{t('trip-request.small-screen.heading')}</PlanATripText>
      <AngleRightIcon icon={faAngleRight} />
    </PlanATripRow>
  </PlanATripLink>
);

PlanATrip.propTypes = {
  user: PropTypes.object,
  t: PropTypes.func,
};

PlanATrip.defaultProps = {
  user: null,
  t: null,
};

export default PlanATrip;
