/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { theme, generals as generalConstants, generals } from '@constants';
import {
  Row as HeaderRow,
  Col,
  Slider,
  Row,
  FrequencyPicker,
  TripPathDetails,
  SelectedPaymentMethod,
  PromoCode,
  BookingMethods as CBookingMethods,
  FreeRidePoints,
} from '@components';
import {
  TripOverviewCol,
  Title,
  TripTimeTitle,
  TripTimeValue,
  EditBtn,
  TripDetailsRow,
  SubmitBtn,
  FrequencyPickerRow,
  MinimizableCol,
} from './styled';
import SelectExtraSeats from './selectExtraSeats';

const extraSeatsEnabled = process.env.REACT_APP_EXTRA_SEATS_ENABLED === 'true';

const getAddresses = trip => {
  const [pickup, destination] = trip.places;
  return { pickup: pickup.address, destination: destination.address };
};

const checkIfPaymentIsEnabled = (departTrip, returnTrip) => {
  return departTrip?.cost === 'BUY' || returnTrip?.cost === 'BUY';
};

const isSubmitDisabled = (
  isCreateTripRequestLoading,
  paymentEnabled,
  selectedBookingMethod,
  selectedTripsPoints,
  loading,
  selectedTripsPrice,
  paymentMethod,
  userPoints,
  freeRidePoints,
  freeRidePointsApplied,
  selectedSelfPaymentOption,
) => {
  if (loading || isCreateTripRequestLoading) {
    return true;
  }

  // If ride if free, but we have `points` method in bookingMethods.
  if (!paymentEnabled && freeRidePointsApplied && freeRidePoints === null) {
    return true;
  }

  if (!paymentEnabled) {
    return false;
  }

  // In case of BUY ride, one method must be selected
  if (!selectedBookingMethod) {
    return true;
  }

  if (
    checkIfSelfPaid(selectedBookingMethod) &&
    !selectedSelfPaymentOption
  ) {
    return true;
  }

  if (
    checkIfSelfPaid(selectedBookingMethod) &&
    !paymentMethod &&
    selectedTripsPrice?.total > 0 &&
    selectedSelfPaymentOption === generals.SELF_PAYMENT_OPTIONS.CARD
  ) {
    return true;
  }

  if (
    selectedBookingMethod === generals.BOOKING_METHODS.POINTS &&
    selectedTripsPoints?.totalPoints > userPoints
  ) {
    return true;
  }

  return false;
};

const checkIfSelfPaid = selectedBookingMethod =>
  selectedBookingMethod === generals.BOOKING_METHODS.SELF_PAID;

const TripOverview = ({
  loading,
  departTrip,
  returnTrip,
  payingOrganizations,
  isOrganizationPaidVisible,
  onEdit,
  onBookRide,
  repeat,
  onChangeFrequency,
  isValidEndDate,
  selectedTripsPrice,
  paymentMethod,
  applyPromoCode,
  isPromoCodeValid,
  promoCode,
  onPromoCodeChange,
  minimized,
  largeScreen,
  onChangeExtraSeats,
  extraSeats,
  bookingMethods,
  selectedTripsPoints,
  onChangeBookingMethod,
  selectedBookingMethod,
  selectedSelfPaymentOption,
  userPoints,
  paymentOption,
  squarePaymenMethods,
  selfPaymentOptions,
}) => {
  const { t } = useTranslation();
  const selectedDepartTrip = { ...departTrip, selectedTripType: 'depart' };
  const selectedReturnTrip = { ...returnTrip, selectedTripType: 'return' };

  const paymentEnabled = checkIfPaymentIsEnabled(departTrip, returnTrip);
  const isSelfPaid = checkIfSelfPaid(selectedBookingMethod);

  const freeRidePointsApplied =
    !paymentEnabled && bookingMethods.includes(generals.BOOKING_METHODS.POINTS);
  const freeRidePoints =
    freeRidePointsApplied && selectedTripsPoints ? selectedTripsPoints : null;

  const isCreateTripRequestLoading =
    loading === generalConstants.CREATE_TRIP_REQUEST_LOADING;

  const submitDisabled = isSubmitDisabled(
    isCreateTripRequestLoading,
    paymentEnabled,
    selectedBookingMethod,
    selectedTripsPoints,
    loading,
    selectedTripsPrice,
    paymentMethod || squarePaymenMethods,
    userPoints,
    freeRidePoints,
    freeRidePointsApplied,
    selectedSelfPaymentOption,
  );

  const addresses = getAddresses(departTrip || returnTrip);
  const tripPathDetails = [];
  if (departTrip) {
    tripPathDetails.push(
      <>
        <TripPathDetails
          type="depart"
          title={t('trip-overview.labels.departing-trip')}
          tripDetails={selectedDepartTrip}
          pickupAddress={addresses.pickup}
          destinationAddress={addresses.destination}
          payingOrganization={payingOrganizations.depart}
          isOrganizationPaidVisible={isOrganizationPaidVisible}
        />
        {freeRidePointsApplied && (
          <FreeRidePoints
            points={freeRidePoints ? freeRidePoints.departPoints : null}
          />
        )}
      </>,
    );
  }

  if (returnTrip) {
    tripPathDetails.push(
      <>
        <TripPathDetails
          type="return"
          title={t('trip-overview.labels.returning-trip')}
          tripDetails={selectedReturnTrip}
          pickupAddress={addresses.pickup}
          isDepartMissing={!departTrip}
          destinationAddress={addresses.destination}
          payingOrganization={payingOrganizations.return}
          isOrganizationPaidVisible={isOrganizationPaidVisible}
          swapForReturn={!!departTrip}
        />
        {freeRidePointsApplied && (
          <FreeRidePoints
            points={freeRidePoints ? freeRidePoints.returnPoints : null}
          />
        )}
      </>,
    );
  }

  return (
    <TripOverviewCol
      justify="space-between"
      align="center"
      spacingV={30}
      spacingH={30}
    >
      <Col justify="flex-start" align="center">
        <HeaderRow
          flex="initial"
          justify="space-between"
          align="center"
          spacingV={10}
        >
          <Title>{t('trip-overview.labels.title')}</Title>

          <Col
            flex="initial"
            justify="flex-start"
            align="flex-start"
            fullWidth={false}
          />

          <Col
            flex="initial"
            justify="flex-start"
            align="flex-start"
            fullWidth={false}
          >
            <TripTimeTitle>{t('trip-overview.labels.trip-time')}</TripTimeTitle>
            <TripTimeValue>
              {selectedDepartTrip.tripTime || selectedReturnTrip.tripTime}
            </TripTimeValue>
          </Col>
        </HeaderRow>

        <MinimizableCol
          justify="flex-start"
          align="center"
          minimized={minimized}
        >
          {(largeScreen || !minimized) && (
            <>
              <Col flex="initial" justify="flex-start" align="flex-start">
                {largeScreen &&
                  tripPathDetails.map((tripPathDetail, key) => (
                    <Col
                      flex="initial"
                      justify="flex-start"
                      align="flex-start"
                      key={key}
                    >
                      <TripDetailsRow
                        flex="initial"
                        justify="flex-start"
                        align="center"
                        spacingV={10}
                      >
                        {tripPathDetail}
                      </TripDetailsRow>
                    </Col>
                  ))}
                {!largeScreen && !minimized && (
                  <TripDetailsRow
                    flex="initial"
                    justify="flex-start"
                    align="center"
                    spacingV={10}
                  >
                    <Slider views={tripPathDetails} showArrows />
                  </TripDetailsRow>
                )}
                {paymentEnabled && (
                  <CBookingMethods
                    bookingMethods={bookingMethods}
                    points={selectedTripsPoints}
                    cost={selectedTripsPrice}
                    onChangeBookingMethod={onChangeBookingMethod}
                    userPoints={userPoints}
                    selectedBookingMethod={selectedBookingMethod}
                    selectedSelfPaymentOption={selectedSelfPaymentOption}
                    selfPaymentOptions={selfPaymentOptions}
                  />
                )}
                {paymentEnabled && isSelfPaid && (
                  <SelectedPaymentMethod
                    cost={selectedTripsPrice}
                    card={
                      paymentOption === 'SQUAREUP'
                        ? squarePaymenMethods
                        : paymentMethod
                        ? paymentMethod.card
                        : null
                    }
                    isPriceLoading={selectedTripsPrice === null || loading}
                    selectedSelfPaymentOption={selectedSelfPaymentOption}
                    clickDisabled={
                      selectedSelfPaymentOption ===
                      generals.SELF_PAYMENT_OPTIONS.CASH
                    }
                  />
                )}
              </Col>

              <FrequencyPickerRow>
                {extraSeatsEnabled && (
                  <Col flex="initial" justify="flex-start" align="flex-start">
                    <Title>{t('trip-overview.labels.extra-seats')}</Title>
                    <Col justify="flex-start" align="flex-start">
                      <SelectExtraSeats
                        extraSeats={extraSeats.ambulatory}
                        onChangeExtraSeats={({ value }) =>
                          onChangeExtraSeats('ambulatory', value)
                        }
                        label={t('trip-overview.extra-seats.ambulatory')}
                      />
                      <SelectExtraSeats
                        extraSeats={extraSeats.handicapped}
                        onChangeExtraSeats={({ value }) =>
                          onChangeExtraSeats('handicapped', value)
                        }
                        label={t('trip-overview.extra-seats.handicapped')}
                      />
                    </Col>
                  </Col>
                )}
                <FrequencyPicker
                  id="frequency-picker"
                  isValidEndDate={isValidEndDate}
                  data={repeat}
                  onChange={onChangeFrequency}
                />
              </FrequencyPickerRow>
              {paymentEnabled &&
                isSelfPaid &&
                selectedSelfPaymentOption &&
                ((selectedSelfPaymentOption ===
                  generals.SELF_PAYMENT_OPTIONS.CARD) ||
                  selectedSelfPaymentOption ===
                    generals.SELF_PAYMENT_OPTIONS.CASH) && (
                  <PromoCode
                    code={promoCode.code}
                    isValid={isPromoCodeValid}
                    onChange={onPromoCodeChange}
                    apply={applyPromoCode}
                    loading={loading}
                  />
                )}
            </>
          )}
        </MinimizableCol>
      </Col>
      <Row flex="initial" justify="flex-start" align="center">
        <EditBtn
          id="edit-btn"
          onClick={onEdit}
          label={t('trip-overview.buttons.back')}
          color={theme.BASE_COLOR}
          inverted
          bordered
          size="md"
        />
        <SubmitBtn
          onClick={onBookRide}
          id="book-btn"
          data-cy="book-this-ride"
          label={t('trip-overview.buttons.book')}
          color={theme.BASE_COLOR}
          icon={isCreateTripRequestLoading ? faSpinner : null}
          iconProps={{ spin: true }}
          disabled={submitDisabled}
        />
      </Row>
    </TripOverviewCol>
  );
};

TripOverview.propTypes = {
  loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  departTrip: PropTypes.object.isRequired,
  returnTrip: PropTypes.object,
  payingOrganizations: PropTypes.shape({
    depart: PropTypes.string,
    return: PropTypes.string,
  }).isRequired,
  isOrganizationPaidVisible: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onBookRide: PropTypes.func.isRequired,
  repeat: PropTypes.object.isRequired,
  onChangeFrequency: PropTypes.func.isRequired,
  isValidEndDate: PropTypes.func.isRequired,
  selectedTripsPrice: PropTypes.shape({
    total: PropTypes.number,
    fare: PropTypes.number,
    fees: PropTypes.number,
    discount: PropTypes.number,
    tax: PropTypes.number,
  }),
  extraSeats: PropTypes.shape({
    ambulatory: PropTypes.number.isRequired,
    handicapped: PropTypes.number.isRequired,
  }).isRequired,
  paymentMethod: PropTypes.object,
  squarePaymenMethods: PropTypes.object,
  applyPromoCode: PropTypes.func.isRequired,
  isPromoCodeValid: PropTypes.bool.isRequired,
  promoCode: PropTypes.shape({
    code: PropTypes.string,
  }).isRequired,
  onPromoCodeChange: PropTypes.func.isRequired,
  minimized: PropTypes.bool.isRequired,
  largeScreen: PropTypes.bool,
  onChangeExtraSeats: PropTypes.func.isRequired,
  bookingMethods: PropTypes.array.isRequired,
  selectedTripsPoints: PropTypes.shape({
    count: PropTypes.shape({
      depart: PropTypes.number,
      return: PropTypes.number,
    }),
    departPoints: PropTypes.number,
    returnPoints: PropTypes.number,
    totalPoints: PropTypes.number,
  }),
  onChangeBookingMethod: PropTypes.func.isRequired,
  selectedBookingMethod: PropTypes.string,
  selectedSelfPaymentOption: PropTypes.string,
  userPoints: PropTypes.number,
  paymentOption: PropTypes.string,
  selfPaymentOptions: PropTypes.array.isRequired,
};

TripOverview.defaultProps = {
  loading: null,
  returnTrip: null,
  largeScreen: false,
  paymentMethod: null,
  selectedTripsPrice: null,
  selectedTripsPoints: null,
  selectedBookingMethod: null,
  selectedSelfPaymentOption: null,
  userPoints: 0,
  paymentOption: '',
  squarePaymenMethods: null,
  departTrip: null,
  isOrganizationPaidVisible: true,
};

export default TripOverview;
