import styled from 'styled-components';
import { theme } from '@constants';
import { Row } from '@components';

export const CardWrapper = styled(Row)`
  z-index: 9000;
  bottom: 1px;
  background-color: #fff;
  overflow-x: auto;
  transition: max-height 200ms linear 0s;
  display: block;
`;

export const BackButton = styled.span`
  color: #fff;
  font-size: 12px;
  border-radius: 10px;
  position: fixed;
  top: 10px;
  left: 10px;
  padding: 2px 4px;
  z-index: 100;
  background-color: ${theme.BASE_COLOR};
`;
