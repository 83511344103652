/** @format */

import React, { useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { Row, Col, Input, Row as HeaderRow, Switch } from '@components';
import { theme, hints, generals as generalConstants } from '@constants';
import { helpers } from '@utils';
import { useLocalStorage } from '@hooks';
import {
  CloseIcon,
  HeaderTitle,
  InputWrapper,
  InputLabel,
  AddressInput,
  FindRidesButton,
  DepartWrapper,
  Tooltip,
} from './styled';

const Form = ({
  state,
  getPickupAddresses,
  getDestinationAddresses,
  clearAddresses,
  disabled,
  onChange,
  onPickupAddressClick,
  onDestinationAddressClick,
  openTripDateTimeForm,
  onSubmit,
  getValidTimes,
  onIsRoundTripChange,
  goToHome,
  onClear,
  language,
}) => {
  const { t } = useTranslation();
  const [seenHints, setSeenHints] = useLocalStorage('seenHints');
  const pickupAddressRef = useRef(null);
  const departDateTimeInputRef = useRef(null);

  const destinationAddressRef = useRef(null);

  const isReturnSwitchRef = useRef(null);
  const returnDateTimeInputRef = useRef(null);

  const submitBtnRef = useRef(null);

  const { query, loading, pickupAddresses, destinationAddresses } = state;
  const isSearchLoading =
    loading === generalConstants.SEARCH_TRIP_REQUEST_LOADING;

  const [hintsVisibleStep1, hintsVisibleStep2, hintsVisibleStep3] =
    useMemo(() => {
      return [
        !seenHints.tripRequestSmallStep1,
        !seenHints.tripRequestSmallStep2,
        !seenHints.tripRequestSmallStep3,
      ];
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  useEffect(() => {
    if (!seenHints.tripRequestSmallStep1) {
      setSeenHints({ ...seenHints, tripRequestSmallStep1: true });
    } else if (!seenHints.tripRequestSmallStep2) {
      setSeenHints({ ...seenHints, tripRequestSmallStep2: true });
    } else if (!seenHints.tripRequestSmallStep3) {
      setSeenHints({ ...seenHints, tripRequestSmallStep3: true });
    }

    if (hintsVisibleStep1) {
      Tooltip.show(pickupAddressRef.current);
    } else if (hintsVisibleStep2) {
      Tooltip.show(returnDateTimeInputRef.current);
    } else if (hintsVisibleStep3) {
      Tooltip.show(submitBtnRef.current);
    }

    return () => {
      Tooltip.hide();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hintsVisibleStep1, hintsVisibleStep2, hintsVisibleStep3]);

  const departDt = query.departDate
    ? `${query.departDate} ${query.departTime}`.trim()
    : '';

  const returnDt = query.returnDate
    ? `${query.returnDate} ${query.returnTime}`.trim()
    : '';

  const handlePickupAddressClick = i => {
    onPickupAddressClick(i);

    if (hintsVisibleStep1) {
      Tooltip.hide(pickupAddressRef.current);
      Tooltip.show(destinationAddressRef.current);
    }
  };

  const handleDestinationAddressClick = i => {
    onDestinationAddressClick(i);

    if (hintsVisibleStep1) {
      Tooltip.hide(destinationAddressRef.current);
      Tooltip.show(isReturnSwitchRef.current);
    }
  };

  const handleIsRoundTripChange = checked => {
    onIsRoundTripChange(checked);

    if (hintsVisibleStep1) {
      Tooltip.hide(isReturnSwitchRef.current);
      Tooltip.show(departDateTimeInputRef.current);
    }
  };

  const handleOpenTripDateTimeForm = e => {
    openTripDateTimeForm(e);

    if (hintsVisibleStep1) {
      Tooltip.hide(departDateTimeInputRef.current);
    }

    if (hintsVisibleStep2) {
      Tooltip.hide(returnDateTimeInputRef.current);
    }
  };

  const handleSubmit = () => {
    onSubmit();

    if (hintsVisibleStep3) {
      Tooltip.hide(submitBtnRef.current);
    }
  };

  const hintsVisible =
    hintsVisibleStep1 || hintsVisibleStep2 || hintsVisibleStep3;

  return (
    <>
      {hintsVisible && (
        <Tooltip
          id="trip-request-tooltip"
          backgroundColor={theme.WEIRD_GREEN}
          textColor={theme.WHITE}
          isCapture
        />
      )}
      <Row align="flex-end" justify="flex-end">
        <CloseIcon data-cy="close-btn" onClick={goToHome} width={16} />
      </Row>
      <HeaderRow justify="space-between" align="center" spacingV={10}>
        <HeaderTitle>{t('trip-request.small-screen.labels.title')}</HeaderTitle>

        <Switch
          data-cy="is-round-trip-switch"
          switchRef={isReturnSwitchRef}
          data-tip={t(hints.tripRequestSmallHints.isReturnToggle)}
          data-event="custom"
          data-event-off="click"
          data-for="trip-request-tooltip"
          id="is-round-trip-switch"
          checked={query.isReturn}
          onChange={handleIsRoundTripChange}
          unCheckedText={t('trip-request.small-screen.fields.switch.labels.one-way')}
          checkedText={t('trip-request.small-screen.fields.switch.labels.round-trip')}
          width={language === 'ru' ? 160 : 144}
          height={language === 'ru' ? 34 : 28}
          bgColor={theme.LIGHT_GREY}
          triggerColor={theme.BASE_COLOR}
          controlFontSize={language === 'ru'}
        />
      </HeaderRow>

      <Col flex="initial" justify="flex-start" align="center">
        <Row justify="space-between" align="center">
          <InputWrapper justify="flex-start" align="flex-start">
            <InputLabel>{t('trip-request.small-screen.fields.pickup.label')}</InputLabel>
            <AddressInput
              data-cy="pickup-address-input"
              id="pickup-address-input"
              inputProps={{
                inputRef: pickupAddressRef,
                'data-tip': t(hints.tripRequestSmallHints.pickupAddress),
                'data-event': 'custom',
                'data-event-off': 'click',
                'data-for': 'trip-request-tooltip',
                name: 'pickupAddress',
                onChange,
                onFocus: getPickupAddresses,
                value:
                  (query.pickupAddress.label &&
                    helpers.getStreetAddress(query.pickupAddress.label)) ||
                  query.pickupAddress,
                label: t('trip-request.small-screen.fields.pickup.placeholder'),
                color: theme.BASE_COLOR,
                inverted: true,
                bordered: true,
                onClear: () => {
                  onClear('pickupAddress');
                },
              }}
              suggestions={pickupAddresses}
              onSuggestionClick={handlePickupAddressClick}
              clearSuggestions={clearAddresses}
            />
          </InputWrapper>
        </Row>
        <Row justify="flex-start" align="center">
          <InputWrapper justify="flex-start" align="flex-start">
            <InputLabel>{t('trip-request.small-screen.fields.destination.label')}</InputLabel>
            <AddressInput
              data-cy="destination-address-input"
              id="destination-address-input"
              inputProps={{
                inputRef: destinationAddressRef,
                'data-tip': t(hints.tripRequestSmallHints.destinationAddress),
                'data-event': 'custom',
                'data-event-off': 'click',
                'data-for': 'trip-request-tooltip',
                name: 'destinationAddress',
                onChange,
                onFocus: getDestinationAddresses,
                value:
                  (query.destinationAddress.label &&
                    helpers.getStreetAddress(query.destinationAddress.label)) ||
                  query.destinationAddress,
                label: t('trip-request.small-screen.fields.destination.placeholder'),
                color: theme.BASE_COLOR,
                inverted: true,
                bordered: true,
                onClear: () => {
                  onClear('destinationAddress');
                },
              }}
              suggestions={destinationAddresses}
              onSuggestionClick={handleDestinationAddressClick}
              clearSuggestions={clearAddresses}
            />
          </InputWrapper>
        </Row>
        <Row justify="space-between" align="center">
          <InputWrapper
            flex={query.isReturn ? 0.48 : 1}
            justify="flex-start"
            align="flex-start"
          >
            <InputLabel>{t('trip-request.small-screen.fields.depart-date.label')}</InputLabel>
            <DepartWrapper
              align="flex-start"
              justify="flex-start"
              isReturn={query.isReturn}
            >
              <Input
                data-cy="depart-date-time-input"
                inputRef={departDateTimeInputRef}
                data-tip={t(hints.tripRequestSmallHints.departDateTime)}
                data-event="custom"
                data-event-off="click"
                data-for="trip-request-tooltip"
                name="depart"
                id="depart-date-time-input"
                value={departDt}
                label={t('trip-request.small-screen.fields.depart-date.placeholder')}
                color={theme.BASE_COLOR}
                onFocus={handleOpenTripDateTimeForm}
                inverted
                bordered
                readOnly
              />
            </DepartWrapper>
          </InputWrapper>
          {query.isReturn && (
            <InputWrapper flex={0.48} justify="flex-start" align="flex-start">
              <InputLabel>{t('trip-request.small-screen.fields.return-date.label')}</InputLabel>
              <Input
                data-cy="return-date-time-input"
                inputRef={returnDateTimeInputRef}
                data-tip={t(hints.tripRequestSmallHints.returnDateTime)}
                data-event="custom"
                data-event-off="click"
                data-for="trip-request-tooltip"
                name="return"
                id="return-date-time-input"
                value={returnDt}
                label={t('trip-request.small-screen.fields.return-date.placeholder')}
                color={theme.BASE_COLOR}
                onFocus={handleOpenTripDateTimeForm}
                inverted
                bordered
                readOnly
              />
            </InputWrapper>
          )}
        </Row>
      </Col>
      <FindRidesButton
        data-cy="find-rides-btn"
        btnRef={submitBtnRef}
        data-tip={t(hints.tripRequestSmallHints.submitBtn)}
        data-event="custom"
        data-event-off="click"
        data-for="trip-request-tooltip"
        id="trip-request-submit-btn"
        onClick={handleSubmit}
        label={t('trip-request.small-screen.buttons.find-rides.label')}
        color={theme.BASE_COLOR}
        icon={isSearchLoading ? faSpinner : null}
        iconProps={{ spin: true }}
        disabled={disabled || isSearchLoading}
      />
    </>
  );
};

Form.propTypes = {
  state: PropTypes.shape({
    query: PropTypes.shape({
      orgId: PropTypes.number,
      departDate: PropTypes.string,
      departTime: PropTypes.string,
      pickupAddress: PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.string,
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
        PropTypes.string,
      ]),
      returnDate: PropTypes.string,
      returnTime: PropTypes.string,
      destinationAddress: PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.string,
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
        PropTypes.string,
      ]),
      isReturn: PropTypes.bool,
    }).isRequired,
    pickupAddresses: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        lat: PropTypes.number,
        lng: PropTypes.number,
      }),
    ).isRequired,
    destinationAddresses: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        lat: PropTypes.number,
        lng: PropTypes.number,
      }),
    ).isRequired,
    loading: PropTypes.string,
  }).isRequired,
  getPickupAddresses: PropTypes.func.isRequired,
  getDestinationAddresses: PropTypes.func.isRequired,
  clearAddresses: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onPickupAddressClick: PropTypes.func.isRequired,
  onDestinationAddressClick: PropTypes.func.isRequired,
  openTripDateTimeForm: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  getValidTimes: PropTypes.func.isRequired,
  onIsRoundTripChange: PropTypes.func.isRequired,
  goToHome: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
};

export default Form;
