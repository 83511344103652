/** @format */

import React, { useCallback, useState } from 'react';
import { Portal } from 'react-portal';
import { Modal, MobileDatePicker } from '@components';

const useMobileDatePickerDialog = ({ onSave = null } = {}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalProps, setProps] = useState({
    onSave,
  });

  const onChange = () => {
    openMobileDatePickerDialog(false);
  };

  const openMobileDatePickerDialog = (value, updatedProps) => {
    setIsOpen(value);
    if (updatedProps) setProps({ ...modalProps, ...updatedProps });
  };

  const UseMobileDatePicker = useCallback(
    () =>
      isOpen ? (
        <Portal id="mobile-date-picker-dialog-instance">
          <Modal overflow="hidden" open>
            <>
              <MobileDatePicker onClose={onChange} {...modalProps} />
            </>
          </Modal>
        </Portal>
      ) : null,

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen, modalProps.onSave],
  );

  return {
    UseMobileDatePicker,
    openMobileDatePickerDialog,
  };
};
export default useMobileDatePickerDialog;
