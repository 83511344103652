/** @format */

import styled from 'styled-components';
import { theme } from '@constants';
import DatePicker from 'react-mobile-datepicker';
import { Button } from '..';

export const StyledMobileDatePicker = styled.div`
  font-family: Bryant;
  width: 92vw;
  margin: 0 auto !important;
  padding: 20px 20px 20px 20px;
  .image-wrapper {
    margin-top: 0 !important;
    margin-right: -5px !important;
  }

  .datepicker {
    background-color: white !important;
    position: relative;
    font-size: 24px;
  }

  .datepicker-scroll li {
    font-size: 20px !important;
    padding-top: 8px;
  }

  .datepicker-wheel {
    font-size: 24px !important;
    border-top: 1px solid ${theme.COD_GRAY_LIGHT} !important;
    border-bottom: 1px solid ${theme.COD_GRAY_LIGHT} !important;
    color: ${theme.COD_GRAY_LIGHT};
    height: 54px;
  }

  .datepicker-viewport {
    height: 120px !important;
  }

  .datepicker-content {
    padding: 0px !important;
  }
`;

export const HeaderText = styled.div`
  font-size: 20px;
  color: ${theme.COD_GRAY_LIGHT};
  font-weight: bold;
  text-align: right;
`;

export const MobileDatePickerStyled = styled(DatePicker)`
  position: relative;
`;

export const SaveBtn = styled(Button)`
  margin-top: 20px;
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  height: 50px;
`;
