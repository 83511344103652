/** @format */

import axios from 'axios';

import { api, localStorageKeys } from '@constants';
import { toast, mixpanel as mixpanelUtils, userHelpers } from '@utils';
import { getTranslatedStringFromKey, getApiErrorTranslation } from '@i18Loader';

// env variables
const envIdentifier = process.env.REACT_APP_ENV_IDENTIFIER;
const env = process.env.REACT_APP_HEADER_ENV;
const server = process.env.REACT_APP_SERVER;

const apiClient = axios.create({
  baseURL: api.BASE_URL,
});

apiClient.interceptors.request.use(config => {
  const authToken = localStorage.getItem(localStorageKeys.AUTH_TOKEN);
  const passwordlessAuthToken = sessionStorage.getItem('passwordlessAuthToken');
  const userAgentAuthToken = localStorage.getItem('userAgentAuthToken');

  const updatedConfig = {
    ...config,
  };

  // to setup user profile on mixpanel
  updatedConfig.headers[api.headerKeys.MIX_PANEL_ID] = mixpanelUtils.getMixpanelId();

  if (envIdentifier) {
    updatedConfig.headers[api.headerKeys.ENV_IDENTIFIER] = envIdentifier;
  }

  if (env) {
    updatedConfig.headers[api.headerKeys.ENV] = env;
  }

  if (server) {
    updatedConfig.headers.server = server;
  }

  if (authToken && !updatedConfig.headers[api.headerKeys.X_AUTH]) {
    updatedConfig.headers[api.headerKeys.X_AUTH] = authToken;
  }

  if (!authToken && !userAgentAuthToken && passwordlessAuthToken) {
    updatedConfig.headers['x-auth-passwordless'] = passwordlessAuthToken;
  }

  return updatedConfig;
});

const clearLocalStorage = () => {
  localStorage.removeItem(localStorageKeys.USER);
  localStorage.removeItem(localStorageKeys.USER_AGENT);
  localStorage.removeItem(localStorageKeys.AUTH_TOKEN);
  localStorage.removeItem(localStorageKeys.USER_AGENT_AUTH_TOKEN);
  localStorage.removeItem(localStorageKeys.PLACES);
  localStorage.removeItem(localStorageKeys.ORGANIZATION_LOCATION_VIEW_BOX);
  localStorage.removeItem(localStorageKeys.REFRESH_TOKEN);
};

const goToLogin = () => {
  setTimeout(() => {
    window.location = '/login';
  }, 2000);
};

apiClient.interceptors.response.use(
  response => response,
  error => {
    const {
      response,
    } = error;

    const { data, status = 500 } = response || {};

    if (data?.code === api.errors.USER_DEACTIVATED_ERROR) {
      clearLocalStorage();

      toast.show('error', getApiErrorTranslation(data));

      goToLogin();
    } else if (
      status === 401 &&
      (data?.code === api.errors.EXPIRED_TOKEN_ERROR ||
        data?.code === api.errors.INVALID_TOKEN_ERROR)
    ) {
      const { expiration, refreshToken } = userHelpers.getRefreshToken();
      const user = JSON.parse(localStorage.getItem(localStorageKeys.USER));

      if (refreshToken) {
        if (userHelpers.isTokenExpired(expiration) || !user) {
          clearStorageAndLogin();
        } else {
          refetchRefreshToken(
            error.config,
            refreshToken,
            user.email,
          );
        }
      } else {
        clearStorageAndLogin();
      }
    } else if (
      status === 401 &&
      (data?.code === api.errors.EXIPRED_REFRESH_TOKEN || data?.code === api.errors.AUTH_INFO_ERROR)
    ) {
      clearStorageAndLogin();
    } else if (status === 500) {
      toast.show('error', getTranslatedStringFromKey('messages.error.something-went-wrong'));
    } else if (data?.message === api.errors.USER_NOT_FOUND_ERROR) {
      toast.show('error', getTranslatedStringFromKey('messages.error.user-not-found'));
    } else if (data?.message === api.errors.ORG_INVITE_NOT_FOUND) {
      const signupOrg = JSON.parse(sessionStorage.getItem(localStorageKeys.SIGNUP_ORGANIZATION));
      toast.show('error', getTranslatedStringFromKey('messages.error.org-invite-not-found', {
        orgName: signupOrg && signupOrg.name,
        dispatchEmail: process.env.REACT_APP_DISPATCH_EMAIL,
      }));
    } else if (data?.code === 'INVALID_REDEEMABLE_AMOUNT_ERROR') {
      const message = getTranslatedStringFromKey(
        'ridership-bonus.redeem-modal.INVALID_REDEEMABLE_AMOUNT_ERROR',
        { dispatchEmail: process.env.REACT_APP_SUPPORT_EMAIL },
      );
      toast.show('error', message);
    } else {
      toast.show('error', getApiErrorTranslation(data));
    }

    return Promise.reject(error);
  },
);

const refetchRefreshToken = async (config, refreshToken, email) => {
  apiClient.post(
    api.endpoints.GET_NEW_TOKEN_WITH_REFRESH_TOKEN,
    {
      refreshToken,
      email,
    },
  ).then(response => {
    if (!response) {
      clearStorageAndLogin();
      return Promise.reject();
    }

    if (response?.status !== 200) {
      toast.show('error', getApiErrorTranslation(response?.data));
      clearStorageAndLogin();
      return Promise.reject();
    }

    userHelpers.storeRefreshToken(response?.data?.refreshToken);
    localStorage.setItem(
      localStorageKeys.AUTH_TOKEN,
      response?.headers[api.headerKeys.X_AUTH],
    );

    return apiClient({
      ...config,
      headers: {
        ...config.headers,
        [api.headerKeys.X_AUTH]: response?.headers[api.headerKeys.X_AUTH],
      },
    });
  }).catch(error => {
    clearStorageAndLogin();
    return Promise.reject(error);
  });
};

const clearStorageAndLogin = () => {
  clearLocalStorage();
  goToLogin();
};

export default apiClient;
