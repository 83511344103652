/** @format */

import styled from 'styled-components';

import { Row, Button } from '@components';
import { theme } from '@constants';

export const WrapperRow = styled(Row)`
  background-color: ${theme.WHITE};
  margin: 0px;
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
  margin-top: 5px;
`;

export const ImageContainer = styled.div`
  margin: 0 auto;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const SubContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 5px;
`;

export const StyledButton = styled(Button)`
  border-radius: 8px;
  font-size: 18px;
  line-height: 20px;
  font-family: Bryant;
  font-weight: 700;
  height: 50px;
  padding: 2px;
  margin-left: ${props => props.margin}px;
  width: ${props => props.width}%;
`;


export const LabelSmall = styled.p`
  color: ${theme.BLACK};
  font-family: Bryant;
  font-size: 18px;
  font-weight: bold;
  margin: ${props => props.margin};
`;