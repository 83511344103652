/** @format */

import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Modal, Row, IconButton, Col } from '@components';
import { theme } from '@constants';
import { helpers } from '@utils';
import {
  Container,
  Heading,
  SubHeading,
  ColItem,
  StyledButton,
  StyledIcon,
  SInput,
} from './styled';
import config from './config';

const useServiceAreaDialog = isLargeScreen => {
  const { t } = useTranslation();
  const history = useHistory();
  const spacing = 20;
  const [isOpen, setIsOpen] = useState(false);

  const width = isLargeScreen ? '400' : (window.innerWidth * 0.9).toString();

  const openServiceAreaDialog = value => {
    setIsOpen(value);
  };

  const getInputRef = (ref, formField, validate) => {
    if (!ref) {
      return;
    }
    validate(ref, formField.validator);
  };

  const onClose = onCloseModal => {
    onCloseModal();
    setIsOpen(false);
  };
  
  const ServiceAreaDialog = useCallback(
    ({
      email,
      loading,
      locationFound,
      valid,
      validate,
      onChange,
      onSubmitEmail,
      onCloseModal,
      errors,
    }) =>
      isOpen ? (
        locationFound ? (
          <Portal id="zip-code-found-portal-instance">
            <Modal
              width={width}
              height={isLargeScreen ? '272' : '278'}
              open
              toggle={() => onClose(onCloseModal)}
            >
              <Row
                align="flex-end"
                justify="flex-end"
                flex={0}
                spacingV={17}
                spacingH={17}
              >
                <IconButton
                  id="close-button"
                  onClick={() => onClose(onCloseModal)}
                />
              </Row>
              <ColItem justify="center" align="center" spacingH={20}>
                <StyledIcon
                  src={config.certifiedRibbon}
                  fill={theme.SECONDARY_BASE_COLOR}
                />
                <Heading>
                  {t(
                    'welcome-views.aaa-roundtown.service-area-map.response.in-area.text',
                  )}
                </Heading>
                <StyledButton
                  id="signup-button"
                  label={t(
                    'welcome-views.aaa-roundtown.service-area-map.response.in-area.buttons.signup-now',
                  )}
                  onClick={() => {
                    onCloseModal();
                    history.push('/signup');
                  }}
                  color={theme.BASE_COLOR}
                  size="lg"
                  className="submit-button"
                />
              </ColItem>
            </Modal>
          </Portal>
        ) : (
          <Portal id="zip-code-not-found-portal-instance">
            <Modal
              width={width}
              height={errors.email ? '480' : '450'}
              open
              toggle={() => onClose(onCloseModal)}
            >
              <Container align="flex-start" justify="flex-start">
                <Row
                  align="flex-end"
                  justify="flex-end"
                  flex={0}
                  spacingV={17}
                  spacingH={17}
                >
                  <IconButton
                    id="close-button"
                    onClick={() => onClose(onCloseModal)}
                  />
                </Row>
                <ColItem justify="center" align="center" spacingH={spacing}>
                  <StyledIcon
                    src={config.stopwatch}
                    fill={theme.SECONDARY_BASE_COLOR}
                  />
                  <Heading>
                    {t(
                      'welcome-views.aaa-roundtown.service-area-map.response.not-in-area.heading',
                    )}
                  </Heading>
                  <SubHeading>
                    {t(
                      'welcome-views.aaa-roundtown.service-area-map.response.not-in-area.sub-heading',
                    )}
                  </SubHeading>
                  {config.formFields.map((formField, i) => (
                    <SInput
                      key={i}
                      floating={false}
                      inverted
                      type={formField.type}
                      name={formField.name}
                      onChange={onChange}
                      value={email}
                      label={t(formField.label)}
                      color={theme.BASE_COLOR}
                      inputRef={ref => getInputRef(ref, formField, validate)}
                      error={t(
                        helpers.getObjectProperty(errors, [
                          formField.name,
                          'message',
                        ]),
                      )}
                    />
                  ))}
                </ColItem>
                <Col justify="flex-end" align="center" spacingH={spacing}>
                  <StyledButton
                    id="submit-btn"
                    label={t(
                      'welcome-views.aaa-roundtown.service-area-map.response.not-in-area.buttons.submit',
                    )}
                    onClick={onSubmitEmail}
                    color={theme.BASE_COLOR}
                    size="lg"
                    className="submit-button"
                    disabled={!valid}
                    icon={loading && valid ? faSpinner : null}
                    iconProps={{ spin: true }}
                  />
                </Col>
              </Container>
            </Modal>
          </Portal>
        )
      ) : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen],
  );

  return {
    ServiceAreaDialog,
    openServiceAreaDialog,
  };
};

useServiceAreaDialog.propTypes = {
  isLargeScreen: PropTypes.number.isRequired,
};

export default useServiceAreaDialog;
