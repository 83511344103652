/** @format */

import React from 'react';
import { PropTypes } from 'prop-types';
import { TileLayer, ZoomControl } from 'react-leaflet';

import { credits } from '../config';
import { Map as SMap } from './styled';
import FixedRoutePath from './fixedRoutePath';

const FixedRouteMap = ({
  center,
  fixedRoute,
  vehicleLocation,
  searchResult,
}) => {
  return (
    <SMap zoomControl={false} center={center} zoom={13}>
      <ZoomControl position="bottomright" />
      <TileLayer attribution={credits.attribution} url={credits.url} />
      <FixedRoutePath
        fixedRoute={fixedRoute}
        vehicleLocation={vehicleLocation}
        searchResultSteps={searchResult}
      />
    </SMap>
  );
};

FixedRouteMap.propTypes = {
  searchResult: PropTypes.array,
  fixedRoute: PropTypes.shape({
    pathColor: PropTypes.string,
    path: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        lat: PropTypes.number,
        lng: PropTypes.number,
        seq: PropTypes.number,
        pathToNextStop: PropTypes.array,
      }),
    ),
  }).isRequired,
  vehicleLocation: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
};

export default FixedRouteMap;
