/** @format */

import styled from 'styled-components';
import { DayPicker } from 'react-day-picker';
import { theme, device } from '@constants';
import 'react-day-picker/dist/style.css';

export const CalendarContainer = styled.div`
  position: relative;
`;

export const CalendarInput = styled.div`
  position: relative;
`;

export const Input = styled.input`
  width: ${props => `${props.width}%`};
  height: 42px;
  background: ${theme.WHITE};
  border: 1px solid #a9aebc;
  border-radius: 8px;
  padding: 8px 8px 8px 40px;
  outline: none;
  font-size: 13.3333px;
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 999;
  max-width: 320px;
  width: 320px;
  background: ${theme.WHITE};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: fit-content;

  @media ${device.mediaQuery.LARGE_SCREEN} and (max-height: 710px) {
    top: -180px;
  }
  
  @media ${device.mediaQuery.SMALL_SCREEN} and (max-height: 710px) {
    top: -280px;
  }

`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 6px;
`;

export const Button = styled.button`
  padding: 8px 16px;
  margin: 8px;
  border-radius: 8px;
  background-color: ${props => (props.confirm ? theme.MIDNIGHT_GREEN : theme.TRANSPARENT)};
  color: ${props => (props.confirm ? theme.WHITE : theme.BRONZE)};
  font-weight: 600;
  font-size: 14px;
  border: none;
  cursor: pointer;
`;

export const Image = styled.img`
  position: absolute;
  padding-right: ${props => (props.inputCalendarIcon ? '10px' : '')};
  border-right: ${props =>
    props.inputCalendarIcon ? `1px solid ${theme.SHADEY_GRAY}` : ''};
  left: ${props => (props.inputCalendarIcon ? '12px' : '28px')};
  top: ${props => (props.inputCalendarIcon ? '12px' : '20px')};
`;

export const DatePicker = styled(DayPicker)`
  .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background-color: unset !important;
  }

  .rdp-caption_label {
    font-size: 16px !important;
    padding-left: 30px !important;
    font-weight:normal !important;
  }

  .rdp-head_cell {
    font-size: 14px !important;
    text-transform: lowercase;
  }
  .rdp-head_cell:first-letter {
    text-transform: uppercase !important;
  }

  .rdp-nav_icon {
    height: 10px;
    width: 10px;
  }

  .rdp-button:not([disabled]) {
    width: 24px;
    height: 24px;
    font-size: 14px;
    font-weight: 400;
    align-items: unset;
    margin-left: 6px;
    outline: none;
  }

  .rdp-day_today:not(.rdp-day_outside) {
    font-weight: bold;
  }

  .rdp-day_selected {
    -webkit-text-stroke: 1px rgba(0.1, 0.1, 0.1, 0.1);
  }

  .rdp-day_selected,
  .rdp-day_selected:focus-visible,
  .rdp-day_selected:hover {
    background: ${props => props.background} !important;
  }
`;
