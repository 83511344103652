/** @format */

import axios from 'axios';

import { api } from '@constants';

const graphQLClient = axios.create({
  baseURL: api.GRAPHQL_ENDPOINT,
});

export default graphQLClient;
