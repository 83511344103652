/** @format */
import React, { useState, useEffect } from 'react';

const useLocalStorage = (key, defaultValue = '') => {
  const [state, setState] = useState(() => {
    const valueInLocalStorage = localStorage.getItem(key);

    if (valueInLocalStorage) {
      return JSON.parse(valueInLocalStorage);
    }
    return typeof defaultValue === 'function' ? defaultValue() : defaultValue;
  });

  const preRef = React.useRef(key);

  useEffect(() => {
    const prekey = preRef.current;
    if (prekey !== key) {
      window.localStorage.removeItem(prekey);
    }

    preRef.current = key;
    window.localStorage.setItem(key, JSON.stringify(state));
  }, [state, key]);

  return [state, setState];
};

export default useLocalStorage;
