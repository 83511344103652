/** @format */

import Leaflet from 'leaflet';

import { theme } from '@constants';
import busStop from '@assets/icons/bus-stop.svg';
import location from '@assets/icons/location-marker.svg';
import locationBlue from '@assets/icons/location-marker-blue.svg';
import locationGreen from '@assets/icons/location-marker-green.svg';
import locationRed from '@assets/icons/location-marker-red.svg';
import currentLocation from '@assets/icons/current-location-marker.svg';
import vehicle from '@assets/icons/vehicle.svg';
import home from '@assets/icons/home.svg';
import org from '@assets/icons/organization-marker.svg';
import gym from '@assets/icons/dumbbell.svg';
import school from '@assets/icons/graduation-hat.svg';
import grocery from '@assets/icons/trolley.svg';
import doctor from '@assets/icons/doctor.svg';
import wellness from '@assets/icons/care.svg';
import work from '@assets/icons/suitcase.svg';
import house from '@assets/icons/house.svg';

const credits = {
  attribution:
    '&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
  url:
    'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
};

const categoriesIcons = [
  {
    type: 'gym',
    icon: Leaflet.icon({
      iconUrl: gym,
      iconSize: [28, 28],
    }),
  },
  {
    type: 'school',
    icon: Leaflet.icon({
      iconUrl: school,
      iconSize: [28, 28],
    }),
  },
  {
    type: 'grocery',
    icon: Leaflet.icon({
      iconUrl: grocery,
      iconSize: [28, 28],
    }),
  },
  {
    type: 'doctor',
    icon: Leaflet.icon({
      iconUrl: doctor,
      iconSize: [28, 28],
    }),
  },
  {
    type: 'wellness',
    icon: Leaflet.icon({
      iconUrl: wellness,
      iconSize: [28, 28],
    }),
  },
  {
    type: 'work',
    icon: Leaflet.icon({
      iconUrl: work,
      iconSize: [28, 28],
    }),
  },
  {
    type: 'home',
    icon: Leaflet.icon({
      iconUrl: house,
      iconSize: [28, 28],
    }),
  },
  
];

const busStopMarkerIcon = Leaflet.icon({
  iconUrl: busStop,
  iconSize: [28, 28],
});

const orgMarkerIcon = Leaflet.icon({
  iconUrl: org,
  iconSize: [28, 28],
});

const pickupMarkerIcon = Leaflet.icon({
  iconUrl: location,
  iconSize: [28, 28],
});

const greenPin = Leaflet.icon({
  iconUrl: locationGreen,
  iconSize: [18, 18],
});

const redPin = Leaflet.icon({
  iconUrl: locationRed,
  iconSize: [18, 18],
});

const signupOrgMarkerIcon = Leaflet.icon({
  iconUrl: locationBlue,
  iconSize: [28, 28],
});

const destinationMarkerIcon = Leaflet.icon({
  iconUrl: home,
  iconSize: [28, 28],
});

const currentLocationMarkerIcon = Leaflet.icon({
  iconUrl: currentLocation,
  iconSize: [18, 18],
});

const vehicleMarkerIcon = Leaflet.icon({
  iconUrl: vehicle,
  iconSize: [28, 28],
});

const polylineOptions = {
  color: theme.BLACK,
};

const tripMarkersConfig = [
  {
    icon: greenPin,
    alt: 'Pickup Location Marker',
    key: 'pickup',
    title: 'trip-markers.stop.title',
  },
  {
    icon: redPin,
    alt: 'Destination Location Marker',
    key: 'destination',
    title: 'trip-markers.workplace.title',
  },
  {
    icon: vehicleMarkerIcon,
    alt: 'Vehicle Location Marker',
    key: 'vehicle',
    title: 'trip-markers.vehicle.title',
  },
];

const defaultCenter = [39.9831403, -83.2710199]; // Columbus, Ohio Coordinates

const tripPathOptions = {
  share: {
    color: theme.BASE_COLOR,
  },
  walk: {
    stroke: true,
    color: theme.BASE_COLOR,
    dashArray: ['4'],
  },
  transit: {
    // bus route
    color: theme.WEIRD_GREEN,
    weight: 5,
  },
  board: {
    color: theme.WEIRD_GREEN,
    weight: 5,
  },
};

export {
  credits,
  busStopMarkerIcon,
  pickupMarkerIcon,
  destinationMarkerIcon,
  currentLocationMarkerIcon,
  vehicleMarkerIcon,
  polylineOptions,
  tripMarkersConfig,
  defaultCenter,
  orgMarkerIcon,
  categoriesIcons,
  tripPathOptions,
  signupOrgMarkerIcon,
};
