/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Col as TripPathCol } from '..';
import {
  TripDetailsTitle,
  NameRow,
  NameText,
  NameTextBold,
} from './styled';
import ShareTripPath from './shareTripPath';
import TransitTripPath from './transitTripPath';
import { generals as generalConstants } from '@constants';

export const TripPathDetails = ({
  title,
  tripDetails,
  pickupAddress,
  destinationAddress,
  payingOrganization,
  isOrganizationPaidVisible,
  workforce,
  isDepartMissing,
  swapForReturn,
}) => {
  const { t } = useTranslation();
  const { type, selectedTripType } = tripDetails;
  const [pickup] = tripDetails.places;
  const getPickupAddress = () => {
    if (!selectedTripType || isDepartMissing) return pickupAddress;
    return selectedTripType === 'depart' ? pickupAddress : destinationAddress;
  };

  const getDestinationAddress = () => {
    if (!selectedTripType || isDepartMissing) return destinationAddress;

    return selectedTripType === 'depart' ? destinationAddress : pickupAddress;
  };

  const renderTransitSharePath = (tripDetails) => {
    const shareTrip = (tripDetails || []).steps.find(step => step.type === 'share');
    return (tripDetails || []).steps.map((step, index) => {
      const first = index === 0;
      const last = index === tripDetails?.steps.length - 1;
      let startAddress = first ? pickupAddress : null;
      let endAddress = last ? destinationAddress : null;
      const leaveTime = first ? tripDetails.leaveTime : step.leaveTime;
      const arriveTime = last ? tripDetails.arriveTime : step.arriveTime;
      const midAddressTransit = last ? shareTrip?.places[1].address : null;
      const midAddressShare = last ? shareTrip?.places[0].address : null;

      if (selectedTripType === 'return' && swapForReturn) {
        if (startAddress) {
          startAddress = destinationAddress;
        }

        if (endAddress) {
          endAddress = pickupAddress;
        }
      }
 
      if (step.type === 'share') {
        return (
          <ShareTripPath
            arriveDate={arriveTime}
            leaveDate={leaveTime}
            pickupAddress={startAddress || midAddressShare}
            destinationAddress={endAddress}
            leaveBy={first}
            arriveBy={last}
            timezone={pickup.timezone}
            isTransitShareTrip={tripDetails.type === generalConstants.TRIP_TYPES.transit_share}
          />
        );
      }

      return (
        <TransitTripPath
          arriveDate={arriveTime}
          leaveDate={leaveTime}
          pickupAddress={startAddress || midAddressTransit}
          destinationAddress={endAddress}
          tripDetails={step}
          leaveBy={first}
          arriveBy={last}
          timezone={pickup.timezone}
        />
      );
    });
  };

  const renderTripPath = () => {
    if (type === 'share') {
      return (
        <ShareTripPath
          arriveDate={tripDetails.arriveTime}
          leaveDate={tripDetails.leaveTime}
          pickupAddress={getPickupAddress()}
          destinationAddress={getDestinationAddress()}
          leaveBy
          arriveBy
          timezone={pickup.timezone}
          isTransitShareTrip={tripDetails.type === generalConstants.TRIP_TYPES.transit_share}
        />
      );
    }

    if (type === 'transit') {
      return (
        <TransitTripPath
          arriveDate={tripDetails.arriveTime}
          leaveDate={tripDetails.leaveTime}
          pickupAddress={getPickupAddress()}
          destinationAddress={getDestinationAddress()}
          tripDetails={tripDetails?.steps[0] || []}
          leaveBy
          arriveBy
          timezone={pickup.timezone}
        />
      );
    }

    return renderTransitSharePath(tripDetails);
  };

  return (
    <Col justify="flex-start" align="flex-start" spacingH={10}>
      {title && <TripDetailsTitle>{title}</TripDetailsTitle>}

      <TripPathCol justify="flex-start" align="flex-start">
        {renderTripPath()}
      </TripPathCol>
      {payingOrganization && !workforce && isOrganizationPaidVisible  && (
        <NameRow justify="flex-start" align="center">
          <NameText>
            <NameTextBold>{t('trip-detail.labels.paid-by')}: </NameTextBold>
            {payingOrganization}
          </NameText>
        </NameRow>
      )}
    </Col>
  );
};

TripPathDetails.propTypes = {
  title: PropTypes.string,
  tripDetails: PropTypes.object.isRequired,
  pickupAddress: PropTypes.string.isRequired,
  destinationAddress: PropTypes.string.isRequired,
  payingOrganization: PropTypes.string,
  isOrganizationPaidVisible: PropTypes.bool,
  workforce: PropTypes.bool,
  isDepartMissing: PropTypes.bool,
  swapForReturn: PropTypes.bool,
};

TripPathDetails.defaultProps = {
  title: null,
  payingOrganization: null,
  isOrganizationPaidVisible: true,
  workforce: false,
  isDepartMissing: false,
  swapForReturn: false,
};

export default TripPathDetails;
