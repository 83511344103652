import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import throttle from 'lodash.throttle';
import { analytics, socketEvents } from '@constants';
import { SheetModal, TripDetail as CTripDetail, WorkforceTripDetail} from '@components';
import { setSelectedTrip } from '@slices/tripRequest';
import {
  useConfirmationDialog,
  useSheetModalMinimize,
  usePayingOrganization,
  useMessageDialog,
} from '@hooks';
import {
  setTripDetail,
  getTripById,
  cancelTripById,
  getTripPathFromDriverLocation,
} from '@slices/tripDetail';
import {
  trackAbEvent,
  setTripPath,
  setTripMapMarkers,
  clearTripPath,
  trackEvent,
} from '@slices/app';
import { helpers, socket as socketUtils } from '@utils';
import { useTranslation } from 'react-i18next';

const getTripPath = trip => {
  if (trip.type === 'transit' || trip.type === 'transit_share') {
    let share = [];
    let transit = [];

    trip.steps.forEach(step => {
      if (step.type === 'share') {
        share = share.concat(step.path);
      } else {
        transit = transit.concat(step.stepsDetails);
      }
    });

    return {
      share,
      transit,
    };
  }

  return {
    share: trip?.path || [],
    transit: [],
  };
};

const getTripDetail = trip => {
  if (!trip) return null;

  if (trip.transitShareTripRequest) {
    return {
      ...trip,
      type: 'transit_share',
      ...trip.transitShareTripRequest,
    };
  }

  return trip;
};

const getCancelModalText = (tripDetail, t) => {
  let text = t('trip-detail.modals.cancel-ride.messages.free');

  if (tripDetail.pricing &&
      tripDetail.paymentDetail &&
      !tripDetail.paymentDetail.acceptCash &&
      ['En Route', 'Scheduled'].includes(tripDetail.status) &&
      tripDetail.cancellationAmount > 0
    ) {
    const cancellationAmount = (tripDetail.cancellationAmount || 0).toFixed(2);
    text = t('trip-detail.modals.cancel-ride.messages.paid', { cancellationAmount });
  }

  return text;
};

const TripDetail = () => {
  const { t } = useTranslation();
  const [sheetModalMinimized, toggleSheetModalMinimized] =
    useSheetModalMinimize();
  const { ConfirmationDialog, openConfirmationDialog } =
    useConfirmationDialog();

  const { MessageDialog, openMessageDialog } = useMessageDialog();

  const [getOrganizationName] = usePayingOrganization();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isOrganizationPaidVisible, setIsOrganizationPaidVisible] = useState(true);

  const {
    largeScreen,
    tripMapMarkers,
    user,
    passwordlessAuthenticationType,
    workforceRiderApp,
    organizations,
  } = useSelector(store => store.app);
  const { trip, loading } = useSelector(store => store.tripDetail);
  const { selectedTrip } = useSelector(store => store.tripRequest);

  const routeState = location.state || {};
  const query = helpers.parseQueryString(location.search);

  if (!trip && !query.tripId) {
    history.push(routeState.prevRoute || '/');
  }

  const tripDetail = getTripDetail(trip);
  const socket = socketUtils.getSocket(!!passwordlessAuthenticationType);
  const driverLocationUpdatesEnabled = tripDetail?.route?.id && tripDetail?.status === 'En Route';

  const addToCalenderBtnCliked = () => {
    dispatch(trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
      eventName: analytics.ADD_TO_CALENDER_CLICKED,
      subJourneyName: 'See Trip Details',
      journeyName: 'Rider Experience',
    }));
  };

  const calendarItemClicked = data => {
    dispatch(trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
      eventName: analytics.ADDED_TO_CALENDER,
      subJourneyName: 'See Trip Details',
      journeyName: 'Rider Experience',
      details: {
        data,
      },
    }));
  };

  const throttledLocationUpdateHandler = throttle(data => {
    const { lat, lng } = data;
    dispatch(
      setTripMapMarkers({
        vehicle: [lat, lng],
      }),
    );
  }, 5000);

  useEffect(() => {
    /**
     * Case I: directly hitting the url trip-detail?tripId=123, tripId will be
     *         defined in this case, tripDetail will be undefined,
     * Action: fetch tripDetail from api
     * Case II: tripDetail is defined after fetching from api
     * Case III: tripDetail is defined (routing from my-trips or trip-options)
     * Action: render tripDetail
     */

    if (query.tripId && !tripDetail) {
      dispatch(getTripById(query.tripId));
    } else if (tripDetail) {
      if (driverLocationUpdatesEnabled) {
        socket.on(
          socketEvents.DRIVER_LOCATION_UPDATED,
          throttledLocationUpdateHandler,
        );

        socket.emit(socketEvents.SUBSCRIBE_TO_ROUTE, {
          routeId: tripDetail.route.id,
        });
      }

      dispatch(trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: 'Navigate To Trip Details',
        subJourneyName: 'See Trip Details',
        journeyName: 'Rider Experience',
        details: {
          tripid: tripDetail.id,
          tripDetail,
        },
      }));
      const [pickup, destination] = tripDetail.places;
      dispatch(
        setTripMapMarkers({
          pickup: { location: [pickup.lat, pickup.lng], type: pickup.orgPlaceType || '' },
          destination: { location: [destination.lat, destination.lng], type: destination.orgPlaceType || ''},
        }),
      );

      dispatch(setTripPath(getTripPath(tripDetail)));
    }

    // cleanup on unmount
    return () => {
      if (!tripDetail) return;

      if (driverLocationUpdatesEnabled) {
        socket.off(socketEvents.DRIVER_LOCATION_UPDATED);
      }

      throttledLocationUpdateHandler.cancel();
      dispatch(setTripDetail(null));
      dispatch(
        setTripMapMarkers({
          pickup: null,
          vehicle: null,
          destination: null,
        }),
      );
      dispatch(clearTripPath());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trip]);

  // fetch updated trip path on vehicle location update
  const vehicleLocationStringified = JSON.stringify(tripMapMarkers.vehicle);
  useEffect(() => {
    if (tripMapMarkers.vehicle) {
      const [lat, lng] = tripMapMarkers.vehicle;
      const driverLocation = { lat, lng };
      dispatch(getTripPathFromDriverLocation(driverLocation, trip));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleLocationStringified]);

  useEffect(() => {
    if (Array.isArray(organizations) && trip && trip.organizationId) {
      let organizationAssociatedWithTrip = null;
      const organization = organizations.find(
        org => org.organizationId === trip.organizationId
      );
      if (organization && organization.organization) {
        organizationAssociatedWithTrip = organization.organization;
      }
      if (
        organizationAssociatedWithTrip &&
        organizationAssociatedWithTrip.detail &&
        typeof organizationAssociatedWithTrip.detail
          .isOrganizationPaidVisible === 'boolean'
      ) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        setIsOrganizationPaidVisible(
          organizationAssociatedWithTrip.detail.isOrganizationPaidVisible,
        );
      }
    }
  }, [organizations, trip]);

  const goBack = () => {
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.TRIP_DETAIL_BACK_BUTTON,
        subJourneyName: 'See Trip Details',
        journeyName: 'Rider Experience',
      }),
    );
    history.goBack();
  };

  const cancelTrip = () => {
    dispatch(trackEvent(analytics.CANCEL_TRIP_BUTTON, tripDetail)); // For Activity Feed
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.CANCEL_TRIP_BUTTON,
        subJourneyName: 'Cancel Trip',
        journeyName: 'Rider Experience',
        elementId: 'cancel-ride-btn',
        isDataCy: true,
        details: {
          tripId: tripDetail.id,
          tripDetail,
        },
      }),
    );
    openConfirmationDialog(false);
    dispatch(cancelTripById(tripDetail.id, openBookingCancelledModal));
  };

  const openBookingCancelledModal = () => {
    openMessageDialog(true, {
      bodyText: t('confirmation-modals.cancel.text'),
      closeButton: {
        title: t('confirmation-modals.cancel.buttons.got-it'),
        onPress: () => {
          history.goBack();
        },
      },
    });
  };

  const openConfirmCancelModal = () => {
    openConfirmationDialog(true, {
      bodyText: getCancelModalText(tripDetail, t),
      onConfirmClick: cancelTrip,
    });
  };

  const selectTransitShareTrip = () => {
    dispatch(
      setSelectedTrip({
        index: routeState.selectedTripIndex,
        key: 'transitShare',
        type: tripDetail.selectedTripType,
      }),
    );

    goBack();
  };

  const checkIfCurrentTransitShareSelected = () => {
    if (selectedTrip.transitShare[tripDetail.selectedTripType] === routeState.selectedTripIndex) {
      return true;
    }
    return false;
  };

  const payingOrganization = getOrganizationName(
    tripDetail && tripDetail.organizationId,
  );

  const tripDetailComponent = (
    <>
      {workforceRiderApp ? (
        <WorkforceTripDetail
          trip={tripDetail}
          loading={loading}
          goBack={goBack}
          openConfirmCancelModal={openConfirmCancelModal}
          payingOrganization={payingOrganization}
          isOrganizationPaidVisible={isOrganizationPaidVisible}
          minimized={sheetModalMinimized}
          user={user}
          isPasswordless={!!passwordlessAuthenticationType}
        />
      ) : (
        <CTripDetail
          trip={tripDetail}
          loading={loading}
          checkIfCurrentTransitShareSelected={checkIfCurrentTransitShareSelected}
          goBack={goBack}
          openConfirmCancelModal={openConfirmCancelModal}
          selectTransitShareTrip={selectTransitShareTrip}
          payingOrganization={payingOrganization}
          isOrganizationPaidVisible={isOrganizationPaidVisible}
          minimized={sheetModalMinimized}
          toggleMinimized={toggleSheetModalMinimized}
          largeScreen={largeScreen}
          addToCalenderBtnCliked={addToCalenderBtnCliked}
          calendarItemClicked={calendarItemClicked}
          user={user}
          isPasswordless={!!passwordlessAuthenticationType}
        />
      )}
      <ConfirmationDialog />
      <MessageDialog />
    </>
  );

  return largeScreen ? (
    <>{tripDetailComponent}</>
  ) : (
    <SheetModal
      id="trip-detail-sheet-modal"
      minimizeEnabled
      minimized={sheetModalMinimized}
      toggleMinimized={toggleSheetModalMinimized}
    >
      {tripDetailComponent}
    </SheetModal>
  );
};

export default TripDetail;
