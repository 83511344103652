/** @format */

// eslint-disable-next-line
import React from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useValidTimesWorkforce = (isReturn = false) => {
  const { t } = useTranslation();
  const { organizationPlaces } = useSelector(state => state.app);
  const { search } = useSelector(store => store.tripRequestWorkforce);
  let pickupAddressLabel = search && search.selection ? search.selection.pickupAddress.label : '';

  if (isReturn) {
    pickupAddressLabel = search && search.selection ? search.selection.destinationAddress.label : '';
  }

  let pickupAddress = [];
  organizationPlaces && organizationPlaces.forEach(orgPlace => {
    if (orgPlace.label === pickupAddressLabel) {
      pickupAddress = orgPlace;
    }
  });

  const getValidTimes = (returnDateParam = "") => {
    const { date, requestType, returnDate } = search.selection;
    const type = requestType === 'Ready By' ? "readyBy" : "arriveBy";
    const returnDateSelected = returnDateParam ? returnDateParam : returnDate;
    let day = search.selection
    ? moment(isReturn ? returnDateSelected : date, 'MM/DD/YYYY').locale('en').format('ddd').toLowerCase()
    : null;

    if (day === "invalid date") {
      day = null;
    }

    const getValidTime = time => {
      return {
        label: time,
        value: time,
      };
    };

    const getAvailableTimes = allTimes => {
      const validTimes = [];
      allTimes &&
        allTimes.forEach(time => {
          const currentUserTimeZoneUTC = moment
            .tz(moment(), pickupAddress?.timezone)
            .utc().format('YYYY-MM-DDTHH:mm:ss');

          const dateTime = `${moment(isReturn ? returnDateSelected : date).format('MM/DD/YYYY')} ${time}`;
          const departTime = moment
            .tz(dateTime, pickupAddress?.timezone)
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss');
          moment(departTime).isAfter(currentUserTimeZoneUTC) &&
            validTimes.push(time);
        });
      return validTimes;
    };
    if (pickupAddress.availableTimes) {
      return {
        placeholder: t('trip-date-time-picker.arrive-at'),
        values: day
          ? getAvailableTimes(pickupAddress.availableTimes[type][day]).map(
              x => getValidTime(x),
            )
          : [],
      };
    }
    return null;
  };

  return [getValidTimes];
};

export default useValidTimesWorkforce;
