/** @format */

const formFields = [
  {
    name: 'zipCode',
    label:
      'welcome-views.aaa-roundtown.service-area-map.fields.zip-code.placeholder',
    type: 'postal',
    focusNext: true,
    validator: {
      required:
        'welcome-views.aaa-roundtown.service-area-map.fields.zip-code.validation.required',
      pattern: {
        value: /^(?!0+$)[0-9]{1,12}$/,
        message:
          'welcome-views.aaa-roundtown.service-area-map.fields.zip-code.validation.invalid',
      },
    },
  },
];

export default {
  formFields,
};
