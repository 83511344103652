/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Col, Trip } from '@components';
import { generals as generalConstants } from '@constants';
import {
  TripTypeTabsRow,
  ActiveIndicator,
  ClickHandler,
  TripTypeCol,
  MessageText,
} from './styled';
import {
  tabs,
  getTripProps,
  checkOtherDateTrips,
  getTabContentIdAttribute,
  tabsIdentifiers,
} from './config';

const isAAARoundTown = process.env.REACT_APP_ENV_IDENTIFIER === 'aaa_roundtown';

const TripTypeTabs = ({
  tabsRef,
  tripRef,
  selected,
  onSelect,
  trips,
  selectedTrip,
  query,
  getSelectTripFunction,
  type,
  fixedRoutes,
  tabsToFilter,
  ...restProps
}) => {
  const { t } = useTranslation();
  const tabsToRender = tabs.filter(tab => tabsToFilter.includes(tabsIdentifiers[tab.id]));
  const selectedIndex = tabsToRender.findIndex(o => o.filterType === selected) || 0;

  const handleShareTabClick = e => {
    const tripTypeFilter = e.currentTarget.getAttribute('data-filtertype');

    // TODO: Not sure if I should remove commenting for now
    // const isShareTab = tripTypeFilter === 'share';
    // if (!isShareTab && fixedRoutes.length > 0) {
    //   const { pickup, dropoff, oneWayDateTime, type } =
    //   tripHelpers.getFixedRoutePrePopulateURL(trips);
    //   const [fixedRoute] = fixedRoutes;
    //   const url = `${fixedRoute.organization.mobilityApp}/fixed-route/${fixedRoute.routeKey}` +
    //   `?pickup=${pickup.address}&pickuplat=${pickup.lat}&pickuplng=${pickup.lng}
    //   &dropoff=${dropoff.address}&dropofflat=${dropoff.lat}&dropofflng=${dropoff.lng}
    //   &oneWayDateTime=${oneWayDateTime}&type=${type}`;
    //   window.open(url, '_blank');
    // }

    onSelect(tripTypeFilter, type);
  };

  const renderTrips = (tab, tripsToRender) => {
    if (tab !== selected) {
      return null;
    }

    const otherDatesExist = checkOtherDateTrips(
      query[`${type}Date`],
      tripsToRender,
    );

    return (
      <>
        {otherDatesExist && (
          <MessageText>
            {t('trip-options.messages.requested-date-not-found')}
          </MessageText>
        )}

        {tripsToRender.map((trip, index) => {
          const props = getTripProps({
            type,
            trip,
            index,
            tripRef,
            selectedTrip,
            getSelectTripFunction,
          });

          return <Trip 
            {...props} 
            isAAARoundTown={isAAARoundTown} 
            isTransitShareTrip={trip.type === generalConstants.TRIP_TYPES.transit_share}
          />;
        })}

        {tripsToRender.length === 0 && (
          <MessageText>
            {t('trip-options.messages.unavailable', tab)}
          </MessageText>
        )}
      </>
    );
  };

  const renderTabContent = () => {
    const id = getTabContentIdAttribute(type, selected);

    return (
      <Col id={id} justify="flex-start" align="flex-start">
        {/* for rendering share trips, and corresponding messages */}
        {renderTrips(
          generalConstants.TRIP_FILTER_TYPE_SHARE,
          trips.share[type] || [],
        )}

        {/* for rendering transit trips, and corresponding messages */}
        {renderTrips(
          generalConstants.TRIP_FILTER_TYPE_TRANSIT,
          trips.transit[type] || [],
        )}

        {/* for rendering transit share trips, and corresponding messages */}
        {renderTrips(
          generalConstants.TRIP_FILTER_TYPE_TRANSIT_SHARE,
          trips.transitShare[type] || [],
        )}
      </Col>
    );
  };

  return (
    <>
      <TripTypeTabsRow
        justify="flex-start"
        align="flex-start"
        rowRef={tabsRef}
        {...restProps}
      >
        <ActiveIndicator selectedIndex={selectedIndex} width={(100 / (tabsToRender.length || 1))} />
        {tabsToRender.map((tab, index) => (
          <ClickHandler
            key={index}
            data-filtertype={tab.filterType}
            id={tab.id}
            onClick={handleShareTabClick}
          >
            <TripTypeCol justify="center" align="center">
              {tab.titleContent}
            </TripTypeCol>
          </ClickHandler>
        ))}
      </TripTypeTabsRow>
      {renderTabContent()}
    </>
  );
};

TripTypeTabs.propTypes = {
  tabsRef: PropTypes.object,
  tripRef: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.string,
  trips: PropTypes.shape({
    share: PropTypes.shape({
      depart: PropTypes.array.isRequired,
      return: PropTypes.array.isRequired,
    }),
    transit: PropTypes.shape({
      depart: PropTypes.array.isRequired,
      return: PropTypes.array.isRequired,
    }),
    transitShare: PropTypes.shape({
      depart: PropTypes.array.isRequired,
      return: PropTypes.array.isRequired,
    }),
  }).isRequired,
  selectedTrip: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
  getSelectTripFunction: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['depart', 'return']).isRequired,
  fixedRoutes: PropTypes.array,
  tabsToFilter: PropTypes.array.isRequired,
};

TripTypeTabs.defaultProps = {
  tabsRef: null,
  tripRef: null,
  selected: null,
  fixedRoutes: [],
};

export default TripTypeTabs;
