/** @format */

import { api as apiConstants } from '@constants';
import socketIOClient from 'socket.io-client';

let socket = null;

const getSocket = (bypass = false) => {
  const token = localStorage.getItem('authToken');

  if (!socket) {
    const config = {
      query: '',
    };

    if (bypass) {
      config.query = `bypass=${true}`;
      config.transports = ['websocket'];
    } else {
      const authTokenHeader = {
        transports: ['websocket', 'polling'],
        extraHeaders: {
          'x-auth': token,
        },
      };

      config.transportOptions = {
        polling: authTokenHeader,
        websocket: authTokenHeader,
      };
    }

    socket = socketIOClient(apiConstants.BASE_URL, config);
  }

  return socket;
};

const destroySocket = () => {
  socket = null;
};

export default { getSocket, destroySocket };
