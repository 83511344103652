/** @format */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import phoneIcon from '@assets/images/phone.png';

import {
  PhoneIcon,
  CallBtn,
  Title,
  SubTitle,
  BoldText,
  CallUsCol,
} from './styled';
import { Row, Col, IconButton } from '..';

const CallUs = ({ toggle, phone, setCustomClose }) => {
  const { t } = useTranslation();
  useEffect(() => {
    setCustomClose(true);
    return () => {
      setCustomClose(false);
    };
  }, [setCustomClose]);

  return (
    <Col fullHeight align="flex-start" justify="flex-start">
      <Row
        align="flex-end"
        justify="flex-end"
        flex={0}
        spacingH={20}
        spacingV={20}
      >
        <IconButton id="close-icn" onClick={toggle} />
      </Row>
      <CallUsCol
        justify="center"
        align="center"
        spacingV={25}
        spacingH={25}
        flex={1}
      >
        <PhoneIcon src={phoneIcon} alt="phone-icon" />
        <Title>{t('welcome-views.aaa-roundtown.call-us.call-to-join')}</Title>
        <SubTitle>
          <Trans
            i18nKey="welcome-views.aaa-roundtown.call-us.text"
            components={{ bold: <BoldText /> }}
            values={{
              days: t('welcome-views.aaa-roundtown.call-us.default-days'),
              times: t('welcome-views.aaa-roundtown.call-us.default-timings'),
            }}
          />
        </SubTitle>
        <CallBtn href={`tel:${phone}`}>
          <Col
            justify="center"
            align="center"
            spacingV={31}
            fullHeight
            fullWidth
          >
            {phone}
          </Col>
        </CallBtn>
      </CallUsCol>
    </Col>
  );
};

CallUs.propTypes = {
  toggle: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
  setCustomClose: PropTypes.func.isRequired,
};

export default CallUs;
