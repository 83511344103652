/** @format */

import styled from 'styled-components';
import { Row } from '@components';
import { theme, styles } from '@constants';
import { hideExtraTextLine } from '@utils/helpers/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const CardContainer = styled.div`
  display: block;
  outline: none !important;
  display: block;
  justify-content: center;
  align-items: flex-end;
`;

export const HeaderRow = styled(Row)`
${props => !props.minimized &&
 ` padding-bottom: 10px;
  border-bottom: 2px solid ${theme.BASE_COLOR};`
}
`;

export const MinimizedCol = styled.div`
  display: ${props => (props.minimized ? 'none' : 'block')};
`

export const InfoBlock = styled.div`
  max-height: 480px;
  transition: height ${
    styles.ANIM_PLACE_CARD_CONTENT_HIDE_SHOW / 1000
  }s ease-in-out;
  background: ${theme.WHITE};
  width: ${props => (props.largeScreen ? '330px !important' : '100%')};
  border-radius: 10px;
  z-index: 3;
  position: relative;
  padding: 12px 15px;
  color: ${theme.BASE_COLOR};
  h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 3px 0;
    ${hideExtraTextLine(2)}
  }
  box-shadow: ${props => props.largeScreen ? '0px 0px 15px -5px rgba(0, 0, 0, 0.48)' : 'none'};
  border: 3px solid ${props => props.selected ? theme.BASE_COLOR : 'transparent'};
`;

export const Title = styled.h4`
  color: ${theme.BASE_COLOR};
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0;
`;

export const TripTimeTitle = styled.span`
  color: ${theme.BASE_COLOR};
  font-size: 10px;
  line-height: 8px;
`;

export const TripTimeValue = styled.div`
  color: ${theme.BASE_COLOR};
  font-weight: 600;
  font-size: 14px;
  padding-top: 8px;
`;

export const TripDetailsTitle = styled.h4`
  color: ${theme.BASE_COLOR};
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
`;

export const TripDescriptionRow = styled(Row)`
  position: relative;

  padding: 10px 10px 10px 30px;
  min-height: 60px;

  &:not(:last-child):before {
    content: '';
    position: absolute;
    width: 3px;
    height: 90%;
    background-color: ${theme.BASE_COLOR};
    left: 64px;
    bottom: -17.6px;
    z-index: 2;
  }
`;

export const TripIcon = styled(FontAwesomeIcon)`
  pointer-events: none;
  font-size: 18px;
  position: absolute;
  left: 57px;
  top: 8px;
  z-index: 2;
`;

export const TripTitle = styled.span`
  font-size: 10px;
  line-height: 1;
  padding-bottom: 2px;
  position absolute;
  left: 0px;
  font-weight: bold;
  max-width: 55px;
`;

export const TripTime = styled.span`
  font-size: 12px;
  line-height: 1;
  padding-bottom: 2px;
  position: absolute;
  left: 90px;
  top: 10px;
  width: 80px;
`;

export const TripAddress = styled.span`
  font-size: 12px;
`;

export const TripAddressSubLabel = styled.span`
  font-size: 12px;  
  font-weight: 600;
`;

export const TripAddressSubLabelHeading = styled.span`
  color: #fff;
  background: #000;
  font-size: 9px;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 5%;
`;

export const TripAddressWrapper = styled.div`
  position: absolute;
  left: 180px;
`;

export const TripPathItemTextSmall = styled.small`
  color: ${theme.BASE_COLOR};
  font-size: 10px;
  font-weight: bold;
`;

export const TripPathItemBadgeDescription = styled.span`
  font-size: 8px;
  font-weight: 600;
  line-height: 1;
  color: ${theme.BASE_COLOR};
  padding-bottom: 0;
  padding-left: 5px;
  margin-top: 2px;
`;
