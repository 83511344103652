/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { theme } from '@constants';
import {
  Title,
  Description,
  TotalPrice,
  Total,
  InnerWrapper,
  GotItBtn,
  NotFree,
  Free,
} from './styled';
import { Modal, Row, Col } from '..';
import { Field } from './Field';

const renderConfirmBookingDetails = (
  cost,
  points,
  total,
  fare,
  totalCredits,
  tax,
  fees,
  discount,
  extraSeats,
  departCount,
  returnCount,
  t,
) => {
  if (cost === 'FREE') {
    return (
      <Free>
        <Row justify="center" align="center" spacingV={10}>
          <Description>
            {t('confirmation-modals.booked.free.text')}
          </Description>
        </Row>
      </Free>
    );
  }

  if (points?.totalPoints >= 0) {
    return (
      <Description>
        {t('confirmation-modals.booked.points.text', points.totalPoints)}
      </Description>
    );
  }

  return (
    <NotFree>
      <Col justify="flex-start" align="center" spacingV={30}>
        <Field
          name={t('confirmation-modals.booked.paid.labels.extra-seats.ambulatory')}
          value={extraSeats.ambulatory.toString()}
          id="extra-ambulatory"
        />
        <Field
          name={t('confirmation-modals.booked.paid.labels.extra-seats.handicapped')}
          value={extraSeats.handicapped.toString()}
          id="extra-handicapped"
        />
        {departCount && (
          <Field name={t('confirmation-modals.booked.paid.labels.repeat-rides.depart')} value={departCount.toString()} />
        )}
        {returnCount && (
          <Field name={t('confirmation-modals.booked.paid.labels.repeat-rides.return')} value={returnCount.toString()} />
        )}
        <Field name={t('confirmation-modals.booked.paid.labels.total-price')} value={`$${parseFloat((total || 0) + (totalCredits || 0)).toFixed(2)}`} />
        <Field name={t('confirmation-modals.booked.paid.labels.fare')} value={`$${fare.toFixed(2)}`} />
        <Field name={t('confirmation-modals.booked.paid.labels.tax')} value={`$${tax.toFixed(2)}`} />
        <Field name={t('confirmation-modals.booked.paid.labels.service-fees')} value={`$${fees.toFixed(2)}`} />
        <Field
          name={t('confirmation-modals.booked.paid.labels.discount')}
          value={`${discount !== 0 ? `$${discount.toFixed(2)}` : '$0.00'}`}
        />
        <Field name={t('confirmation-modals.booked.paid.labels.credits-used')} value={`$${totalCredits ? parseFloat(totalCredits).toFixed(2) : '0.00'}`} />
        <Row justify="flex-start" align="center" spacingV={5}>
          <Col justify="flex-start" align="flex-start">
            <TotalPrice>{t('confirmation-modals.booked.paid.labels.charged')}</TotalPrice>
          </Col>
          <Col justify="flex-end" align="flex-end">
            <Total>${total.toFixed(2)}</Total>
          </Col>
        </Row>
      </Col>
      <Row justify="flex-start" align="center" spacingV={10}>
        <Description>
          {t('confirmation-modals.booked.paid.text')}
        </Description>
      </Row>
    </NotFree>
  );
};

const ConfirmBooking = ({ toggle, details }) => {
  const { t } = useTranslation();
  const {
    cost,
    total,
    fare,
    totalCredits,
    tax,
    fees,
    discount,
    extraSeats,
    departCount,
    returnCount,
    points,
  } = details;

  return (
    <Modal width="350" toggle={toggle}>
      <Col justify="center" align="center" spacingV={25} spacingH={25}>
        <InnerWrapper>
          <Col justify="flex-start" align="center">
            <Row justify="center" align="center">
              <Title>{t('confirmation-modals.booked.title')}</Title>
            </Row>
            {renderConfirmBookingDetails(
              cost,
              points,
              total,
              fare,
              totalCredits,
              tax,
              fees,
              discount,
              extraSeats,
              departCount,
              returnCount,
              t,
            )}
            <GotItBtn
              id="got-it-btn"
              data-cy="got-it-btn"
              onClick={toggle}
              label={t('confirmation-modals.booked.buttons.got-it')}
              color={theme.BASE_COLOR}
            />
          </Col>
        </InnerWrapper>
      </Col>
    </Modal>
  );
};

ConfirmBooking.propTypes = {
  toggle: PropTypes.func.isRequired,
  details: PropTypes.shape({
    cost: PropTypes.string.isRequired,
    total: PropTypes.number,
    tax: PropTypes.number,
    fees: PropTypes.number,
    fare: PropTypes.number,
    discount: PropTypes.number,
    tripsScheduled: PropTypes.number,
    totalCredits: PropTypes.number,
    extraSeats: PropTypes.shape({
      ambulatory: PropTypes.number.isRequired,
      handicapped: PropTypes.number.isRequired,
    }),
    departCount: PropTypes.number,
    returnCount: PropTypes.number,
    points: PropTypes.shape({
      totalRides: PropTypes.number,
      totalPoints: PropTypes.number,
      pointPerRide: PropTypes.number,
      remainingPoints: PropTypes.number,
    }),
  }).isRequired,
};

export default ConfirmBooking;
