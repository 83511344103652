/** @format */

const SUBSCRIBE_TO_ROUTE = 'subscribeToRoute';
const UNSUBSCRIBE_TO_ROUTE = 'unSubscribeToRoute';
const DRIVER_LOCATION_UPDATED = 'onDriverLocationUpdated';
const UPDATED_ORGANIZATION_DETAILS = 'onOrgDetailsUpdated';

export default {
  SUBSCRIBE_TO_ROUTE,
  UNSUBSCRIBE_TO_ROUTE,
  DRIVER_LOCATION_UPDATED,
  UPDATED_ORGANIZATION_DETAILS,
};
