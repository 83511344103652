/** @format */

import styled from 'styled-components';

import { theme, device } from '@constants';
import { styles as styleUtils } from '@utils';
import { Row } from '..';

export const Modal = styled(Row)`
  width: 100%;
  height: 100%;
  ${props => props.forDemoVideos === null ? 'position: absolute': 'position: static'};
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 2000;
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${styleUtils.hexToRgba('#212529', 0.85)};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2000;
`;

export const Content = styled.div`
  width: ${props => props.width}px;
  ${props => props.height && `height: ${props.height}px;`};
  max-height: 90vh;
  overflow-y: ${props => props.overflow || 'auto'};
  overflow-x: ${props => props.overflow || 'hidden;'};
  margin: 0;
  border-radius: ${props => props.borderRadius || '8'}px;
  position: absolute;
  background-color: ${theme.WHITE};
  z-index: 2001;
  margin: ${props => props.margin || '0'}px;;

  @media ${device.mediaQuery.MOBILE_SCREEN} {
    ${props => props.forDemoVideos !== null && 'width: 270px'};
    ${props => props.forDemoVideos !== null && 'height: 340px'};

  }
  ::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
`;
