/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import { useTranslation } from 'react-i18next';
import { Row, IconButton, Col } from '@components';
import { theme } from '@constants';
import { faSpinner, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import {
  Form,
  Wrapper,
  FormWrapper,
  StyledButton,
  BottomWrapper,
  SInput,
  Title,
  SLink,
  DropdownCol,
  SDropdown,
  ConfirmationBottomWrapper,
  ModalText,
} from './styled';
import { Modal } from '../common';

const LoadCredits = ({
  amount,
  getServiceFee,
  getFinalAmount,
  handleAmountChange,
  paymentMethods,
  selectedPaymentMethod,
  handlePaymentMethodsChange,
  credits,
  goBack,
  isDisabled,
  openConfirmationModal,
  isConfirmationModalOpen,
  handleConfirmationModal,
  loading,
  formErrors,
  maxCreditLimitForUser,
  minCreditLimitForUser,
}) => {
  const { t } = useTranslation();

  const amountErrors = {
    required: formErrors.required
      ? t('load-credits.fields.amount.validation.required')
      : null,
    maximumLimitReached: formErrors.maximumLimitReached
      ? t('load-credits.fields.amount.validation.max-limit', {
          amount: maxCreditLimitForUser,
        })
      : null,
    exceedsMinimumLimit: formErrors.exceedsMinimumLimit
      ? t('load-credits.fields.amount.validation.min-limit', {
          amount: minCreditLimitForUser,
        })
      : null,
  };

  const form = () => {
    return (
      <FormWrapper>
        <Form>
          <SInput
            id="credits-load-amount"
            icon={faDollarSign}
            name="credits-load-amount"
            data-cy="credits-load-amount"
            label={t('load-credits.fields.amount.label')}
            floating
            value={amount}
            onChange={handleAmountChange}
            color={theme.COD_GRAY_LIGHT}
            type="number"
            error={
              amountErrors.required ||
              amountErrors.maximumLimitReached ||
              amountErrors.exceedsMinimumLimit
            }
          />
          <DropdownCol justify="flex-start" align="flex-start">
            <Row flex="initial" align="flex-start" justify="flex-start">
              {paymentMethods?.length > 0 ? (
                <SDropdown
                  id="options-dropdown"
                  data-cy="options-dropdown"
                  placeholder={t('load-credits.select-payment-option')}
                  options={paymentMethods}
                  onChange={handlePaymentMethodsChange}
                  value={selectedPaymentMethod}
                  color={theme.BASE_COLOR}
                  inverted
                  bordered
                  rounded
                  size="sm"
                />
              ) : (
                <SLink
                  to={{
                    pathname: '/payment',
                    directedFrom: 'load-credits',
                  }}
                >
                  {t('load-credits.select-payment-option')}
                </SLink>
              )}
            </Row>
          </DropdownCol>
          <BottomWrapper>
            <StyledButton
              data-cy="upload-nowdate-btn"
              id="load-now-btn"
              onClick={openConfirmationModal}
              label={t('load-credits.load-now')}
              color={theme.BASE_COLOR}
              iconProps={{ spin: true }}
              disabled={isDisabled}
            />
          </BottomWrapper>
        </Form>
      </FormWrapper>
    );
  };

  return (
    <Wrapper>
      <Row flex="initial" align="flex-end" justify="flex-end">
        <IconButton id="close-button" data-cy="close-button" onClick={goBack} />
      </Row>
      <Row flex="initial" align="flex-start" justify="flex-start">
        <Title>{t('load-credits.load-credits')}</Title>
      </Row>
      <Row flex="initial" align="flex-start" justify="flex-start">
        <p>{t('load-credits.current-credits', { credits })}</p>
      </Row>
      {form()}
      <Portal>
        <Modal
          id="confirmation-modal"
          open={isConfirmationModalOpen}
          toggle={() => handleConfirmationModal(false)}
          width="350"
          overflow="visible"
        >
          <Col justify="center" align="center" spacingV={30} spacingH={30}>
            <Row flex="initial" align="flex-start" justify="center">
              <Title>{t('load-credits.confirmation')}</Title>
            </Row>
            <Row flex="initial" align="flex-start" justify="flex-start">
              <ModalText>
                {t('load-credits.confirmation-text', {
                  amount: parseFloat(amount).toFixed(2),
                  label: selectedPaymentMethod?.label,
                })}
              </ModalText>
            </Row>
            <Row>
              <ModalText>
                {t('load-credits.confirmation-text-explanation', {
                  serviceFee: parseFloat(getServiceFee(amount)).toFixed(2),
                  finalAmount: parseFloat(getFinalAmount(amount)).toFixed(2),
                })}
              </ModalText>
            </Row>
            <Row flex="initial" align="center" justify="space-between">
              <ConfirmationBottomWrapper>
                <StyledButton
                  data-cy="modal-no-btn"
                  id="modal-no-btn"
                  onClick={() => handleConfirmationModal(false)}
                  label={t('load-credits.confirmation-no')}
                  color={theme.BASE_COLOR}
                  iconProps={{ spin: true }}
                  disabled={loading}
                />
              </ConfirmationBottomWrapper>
              <ConfirmationBottomWrapper>
                <StyledButton
                  data-cy="modal-yes-btn"
                  id="modal-yes-btn"
                  onClick={() => handleConfirmationModal(true)}
                  label={t('load-credits.confirmation-yes')}
                  color={theme.BASE_COLOR}
                  icon={loading ? faSpinner : null}
                  iconProps={{ spin: true }}
                  disabled={loading}
                />
              </ConfirmationBottomWrapper>
            </Row>
          </Col>
        </Modal>
      </Portal>
    </Wrapper>
  );
};

LoadCredits.propTypes = {
  amount: PropTypes.string,
  getServiceFee: PropTypes.func.isRequired,
  getFinalAmount: PropTypes.func.isRequired,
  handleAmountChange: PropTypes.func.isRequired,
  paymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedPaymentMethod: PropTypes.string.isRequired,
  handlePaymentMethodsChange: PropTypes.func.isRequired,
  credits: PropTypes.number,
  goBack: PropTypes.func.isRequired,
  openConfirmationModal: PropTypes.func.isRequired,
  isConfirmationModalOpen: PropTypes.bool.isRequired,
  handleConfirmationModal: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  loading: PropTypes.func.isRequired,
  formErrors: PropTypes.shape({
    required: PropTypes.bool,
    maximumLimitReached: PropTypes.bool,
    exceedsMinimumLimit: PropTypes.bool,
  }).isRequired,
  maxCreditLimitForUser: PropTypes.number.isRequired,
  minCreditLimitForUser: PropTypes.number.isRequired,
};

LoadCredits.defaultProps = {
  amount: 0,
  credits: 0,
};

export default LoadCredits;
