/** @format */

import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { analytics } from '@constants';
import { trackEvent } from '@slices/app';
import { MyTripsWorkforce, FullScreen } from '@components';
import { getMyTrips, setActiveTripsType, resetMyTrips } from '@slices/myTrips';
import { setTripDetail } from '@slices/tripDetail';

const MyTripsWorkforceContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {largeScreen } = useSelector(store => store.app);
  const { activeTripsType, trips, loading } = useSelector(
    store => store.myTrips,
  );

  useEffect(() => {
    dispatch(getMyTrips());
    return () => {
      dispatch(resetMyTrips());
    };
  }, [dispatch, activeTripsType]);

  const onChangeActiveTripsType = type => {
    if (loading) {
      return;
    }

    dispatch(setActiveTripsType(type));
  };

  const onPageChange = pagination => {
    dispatch(getMyTrips(pagination.selected));
  };

  const onSelect = selectedTrip => {
    dispatch(trackEvent(analytics.MY_RIDES_TRIP_SELECT, selectedTrip));

    dispatch(setTripDetail(selectedTrip));

    history.push('/trip-detail', { prevRoute: 'my-trips' });
  };

  const onClose = () => {
    history.push('/');
  };

  const addToCalenderBtnCliked = () => {
    dispatch(trackEvent(analytics.ADD_TO_CALENDER_CLICKED));
  };

  const calendarItemClicked = data => {
    dispatch(trackEvent(analytics.ADDED_TO_CALENDER, data));
  };

  const myTrips =  (
    <MyTripsWorkforce
      activeTripsType={activeTripsType}
      setActiveTripsType={onChangeActiveTripsType}
      trips={trips}
      onPageChange={onPageChange}
      onSelect={onSelect}
      loading={loading}
      onClose={onClose}
      addToCalenderBtnCliked={addToCalenderBtnCliked}
      calendarItemClicked={calendarItemClicked}
    />
  );

  return largeScreen ? (
    myTrips
  ) : (
    <FullScreen id="my-trips">{myTrips}</FullScreen>
  );
};

export default MyTripsWorkforceContainer;
