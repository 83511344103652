/** @format */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { HomeLarge, HomeSmall } from '@components';

import {
  setPersonalAddressesMarkers,
  trackAbEvent,
} from '@slices/app';
import {
  useWelcomeDialog,
  useOrgWelcomeDialog,
  useWorkInProgress,
  useSelectedOrgPlaceMarker,
} from '@hooks';
import { userHelpers } from '@utils';
import { analytics } from '@constants';

const isAAARoundTown = process.env.REACT_APP_ENV_IDENTIFIER === 'aaa_roundtown';
let aaaRoundTownWelcome = false;

const Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentLocation, largeScreen, user, selectedOrganization,
    organizations, modals,
  } = useSelector(state => state.app);
  const organizationUsers = useSelector(
    state => state.auth.signup.OrganizationUsers,
  );
  const isReferralEnabled =
    (selectedOrganization != null &&
    Array.isArray(organizations) &&
    selectedOrganization < organizations.length)
      ? !!organizations[selectedOrganization]?.detail?.referral
      : false;
  const { selectedOrgPlaceMarker } = modals || false;
  // @TODO Remove the following comment when openWelcomeDialog is enabled
  // eslint-disable-next-line
  const { WelcomeDialog, openWelcomeDialog } = useWelcomeDialog();
  const { OrgWelcomeDialog, openOrgWelcomeDialog } = useOrgWelcomeDialog();
  const { SelectedOrgPlaceModal, openSelectedOrgPlaceModal } =
  useSelectedOrgPlaceMarker();

  const [organization] = organizationUsers;
  const { organizationId } = organization;
  const orgDetails = userHelpers.getOrgDetails(organizationId);

  useEffect(() => {
    // @TODO Uncomment line 42-44 to show openWelcomeDialog
    // if (localStorage.getItem('welcomed') !== 'true') {
    //   openWelcomeDialog(orgDetails);
    // }
    getOrgWelcomeState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgDetails]);

  useEffect(() => {
    if (!user && isAAARoundTown && !aaaRoundTownWelcome) {
      aaaRoundTownWelcome = true;
      // @TODO Uncomment line 42-44 to show openWelcomeDialog
      // openWelcomeDialog(orgDetails);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

useEffect(() => {
  if (selectedOrgPlaceMarker) {
    openSelectedOrgPlaceModal(selectedOrgPlaceMarker);
  }
}, [openSelectedOrgPlaceModal, selectedOrgPlaceMarker]);
  const getOrgWelcomeState = () => {
    if (localStorage.getItem('orgWelcomeArray')) {
      const orgWelcomeArray = JSON.parse(
        localStorage.getItem('orgWelcomeArray'),
      );
      const foundItem = orgWelcomeArray?.find(x => x?.welcomed === false);
      if (foundItem?.id) {
        const newOrgDetails = userHelpers.getOrgDetails(foundItem.id);
        if (newOrgDetails) {
          openOrgWelcomeDialog(foundItem, newOrgDetails);
          dispatch(
            trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
              eventName: analytics.ORGANIZTION_WELCOME_VIEW,
              subJourneyName: 'Explore Dashboard',
              journeyName: 'Rider Experience',
              details: {
                orgDetails,
              },
            }),
          );
        }
      }
    }
  };

  const { WorkInProgress, openWorkInProgress } = useWorkInProgress();

  const onClickGoals = () => {
    openWorkInProgress(true);
  };

  useEffect(() => {
    let personalAddresses = [];

    if (user && user.detail && user.detail.addresses) {
      personalAddresses = user.detail.addresses;
    }

    dispatch(setPersonalAddressesMarkers(personalAddresses));
  }, [dispatch, user, currentLocation]);

  const onOpenSendReferralCodeDialog = () => {
    history.push('/invite-friends');
  };

  return (
    <>
      {largeScreen ? (
        <HomeLarge />
      ) : (
        <HomeSmall
          user={user}
          onClickGoals={onClickGoals}
          isVisible // we are not showing org place cards for small screens so it can be directly set to visible
          openSendReferralCodeDialog={onOpenSendReferralCodeDialog}
          isReferralEnabled={isReferralEnabled}
        />
      )}
      <WelcomeDialog
        largeScreen={largeScreen}
        isAAARoundTown={isAAARoundTown}
      />
      <OrgWelcomeDialog />
      <WorkInProgress />
      <SelectedOrgPlaceModal />
    </>
  );
};

export default Home;
