/** @format */

/** This css hides text lines after `maxLines` number */
export const hideExtraTextLine = (maxLines = 3) => `
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${maxLines};
  -webkit-box-orient: vertical;
`;
