/** @format */

export default {
  REFRESH_TOKEN: 'refreshToken',
  REMEMBER_ME: 'rememberMe',
  AUTH_TOKEN: 'authToken',
  USER: 'user',
  USER_AGENT_AUTH_TOKEN: 'userAgentAuthToken',
  USER_AGENT: 'userAgent',
  PLACES: 'places',
  ORGANIZATION_LOCATION_VIEW_BOX: 'organizationLocationViewBox',
  USER_LANGUAGE: 'userLanguage',
  SOURCE: 'source',
  SIGNUP_ORGANIZATION: 'signupOrganization',
  RIDERSHIP_BONUS_LANDING_PAGE_SHOWN: 'ridershipBonusLandingPageShown',
  CACHE_EST_PICKUPS: 'cacheEstPickups',
};
