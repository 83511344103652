/** @format */

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { Row } from '@components';
import { theme } from '@constants';

export const PlanATripLink = styled(Link)`
  width: 80%;
  height: 44px;
  border-radius: 4px;
  border: solid 0.5px ${theme.WARM_GREY};
  background-color: ${theme.BASE_COLOR};
  position: relative;
  top: -22px;

  &:hover {
    text-decoration: none;
  }
`;

export const PlanATripRow = styled(Row)`
  height: 44px;
`;

export const PlanATripText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${theme.WHITE};
  line-height: 22px;
`;

export const AngleRightIcon = styled(FontAwesomeIcon)`
  font-size: 22px;
  color: ${theme.WHITE};
  line-height: 22px;
`;
