/** @format */
import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

const BugsnagProvider = store => {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    onError(event) {
      const { user, userAgent } = store.getState().app;
      event.addMetadata('IDENTIFIER', {
        name: process.env.REACT_APP_ENV_IDENTIFIER,
      });
      if (user) {
        event.addMetadata('user', {
          id: user.id,
          email: user.email,
        });
      }

      if (userAgent) {
        event.addMetadata('userAgent', {
          id: userAgent.id,
          email: userAgent.email,
        });
      }

      event.setUser(user ? user.id : '');
    },
    autoTrackSessions: false,
    releaseStage: process.env.REACT_APP_ENV,
    enabledReleaseStages: ['production'],
    enabledErrorTypes: {
      unhandledExceptions: true,
      unhandledRejections: true,
    },
  });
  return Bugsnag.getPlugin('react').createErrorBoundary(React);
};

export default BugsnagProvider;
