/** @format */

const SIGNUP_BUTTON = 'Signup Button';
const SIGNUP = 'Signup';
const LOGIN = 'Login';
const LOGIN_BUTTON = 'Login Button';
const MY_RIDES_VIEW = 'My Rides View';
const MY_RIDES_TRIP_SELECT = 'My Rides Button';
const TRIP_REQUEST_VIEW = 'Trip Request View';
const SEARCH_TRIP = 'Search Trip';
const TRIP_OPTIONS_VIEW = 'Trip Options View';
const TRIP_OPTIONS_SELECT_TRIP = 'Trip Options Select Trip';
const TRIP_OPTIONS_CONFIRM_BUTTON = 'Trip Options Confirm Button';
const TRIP_OVERVIEW_VIEW = 'Trip Overview View';
const TRIP_OVERVIEW_BACK_BUTTON = 'Trip Overview Back Button';
const BOOK_THIS_RIDES_BUTTON = 'Book This Ride Button';
const TRIP_DETAIL_VIEW = 'Trip Detail View';
const TRIP_DETAIL_BACK_BUTTON = 'Trip Detail Back Button';
const GOALS_BUTTON = 'Goals Button';
const LOGOUT_BUTTON = 'Logout Button';
const SELECT_RIDER = 'Select Rider';
const CANCEL_TRIP_BUTTON = 'Cancel Trip Button';
const PROFILE_VIEW = 'Profile View';
const SELECT_BUS_STOP_MARKER = 'Select Bus Stop Marker';
const UPDATE_ACCOUNT_BUTTON = 'Update Account Button';
const SPECIAL_NEEDS = 'Special Needs';
const ADDRESS_TYPE = 'Address Type';
const ADDRESSES = 'Address';
const ORGANIZTION_WELCOME_VIEW = 'Organization Welcome View';
const TRANSIT_TRIP_DETAIL_VIEW = 'Transit Trip Detail View';
const TRANSIT_TRIP_DETAIL_BACK_BUTTON = 'Transit Trip Detail Back Button';
const SAVE_PAYMENT_METHOD_BUTTON = 'Attach/Save Payment Method';
const DELETE_PAYMENT_METHOD_BUTTON = 'Detach/Delete Payment Method';
const SELECTED_PAYMENT_METHOD = 'Selected Payment Method';
const PAYMENT_METHOD_VIEW = 'Payment Method View';
const MAP_VIEW = 'Map View';
const FORGOT_PASSWORD = 'Forgot Password';
const LOGIN_FAILED = 'Login Failed';
const SIGNUP_FAILED = 'Signup Failed';
const ACCOUNT_UPDATE_FAILED = 'Account Update Failed';
const INVALID_PROMO_CODE = 'Invalid Promo Code';
const APPLIED_PROMO_CODE = 'Applied Promo Code';
const AUTO_APPLIED_PROMO_CODE = 'Auto Applied Promo Code';
const PROMO_CODE_REMOVED = 'Removed Promo Code';
const RATED_THE_RIDE = 'Rated the ride';
const SKIPPED_RATE_RIDE = 'Skipped rate the ride';
const ORGANIZATION_SELECTED = 'Organization Selected';
const ADD_TO_CALENDER_CLICKED = 'Add to Calender Clicked';
const ADDED_TO_CALENDER = 'Added trip to calender';
const SAVED_ADDRESSES = 'Saved Addresses';
const CONTACT_CLICKED = 'Contact Clicked';
const SCHEDULE_CLIKED = 'Schedule Clicked';
const GO_HERE_CLICKED = 'Go Here Clicked';
const ACCEPTED_INVITE = 'Accepted Invite';
const ACCEPT_INVITE_FAILED = 'Accept Invite Failed';
const DECLINED_INVITE = 'Declied Invite';
const PASSWORDLESS_AUTH_SUCCESS = 'User authenticated by passwordless strategy';
const PASSWORDLESS_AUTH_FAILED = 'Failed to authenticate user';
const OPEN_INVITE_FRIENDS = 'Open Invite Friends Modal/View';
const REFERRAL_LINK_COPIED = 'Referral Link Copied';
const INVITATION_SENT = 'Invitation Sent';
const INVITE_FRIENDS_BUTTON_CLICKED = 'Invite Friends Button Clicked';
const SEARCH_TRIPS_FOR_SELECTED_LOCATION = 'Search Trips For Selected Location';
const SELECTED_TRIP_DETAIL = 'Selected Trip Detail';
const ORGANIZATION_PLACE_MARKER_SELECTED = 'Organization Place Marker Selected';
const ORGANIZATION_PLACES_NOT_FOUND = 'Organization Places Not Found';
const ORGANIZATION_PLACES_LOADED = 'Organization Places Loaded';
const RESET_PASSWORD = 'RESET PASSWORD';
const USER_ORG_RESTRICTIONS = 'USER ORG RESTRICTIONS';
const LOAD_CREDIT_BUTTON_CLICKED = 'Load Credit Button Clicked';
const LOAD_CREDIT_SUBMIT_BUTTON_CLICK = 'Load Credit Submit Button Clicked';
const LOAD_CREDIT_CANCEL_BUTTON_CLICK = 'Load Credit Cancel Button Clicked';
const LOAD_CREDIT_CONFIRM_BUTTON_CLICK = 'Load Credit Confirm Button Clicked';
const LOAD_CREDIT_CLOSED = 'Load Credit Closed';
const LOAD_CREDIT_SUCCESS = 'Load Credit Success';
const LOAD_CREDIT_FAILED = 'Load Credit Failed';
const RIDERSHIP_REDEEM_BONUS_BUTTON_CLICKED = 'Ridership Redeem Bonus Button Clicked';
const RIDERSHIP_REDEEM_BONUS_CLOSED = 'Ridership Redeem Bonus Closed';
const RIDERSHIP_REDEEM_BONUS_SUCCESS = 'Riderhip Redeem Bonus Success';
const RIDERSHIP_REDEEM_BONUS_FAILED = 'Riderhip Redeem Bonus Failed';
const RIDERSHIP_BONUS_ENROLL_FAILED = 'Ridership Bonus Enroll Failed';
const RIDERSHIP_BONUS_INTRO_OPENED = 'Ridership Bonus Intro Opened';
const RIDERSHIP_BONUS_INTRO_CLOSE_WITHOUT_ENROLL = 'Ridership Bonus Intro Close Without Enroll';
const RIDERSHIP_BONUS_VIEW = 'Ridership Bonus View';
const RIDERSHIP_REDEEM_BUTTON_CLICKED = 'Ridership Redeem Button Clicked';
const RIDERSHIP_BONUS_BACK_BUTTON_CLICKED = 'Ridership Bonus Back Button Clicked';
const RIDERSHIP_BONUS_START_EARNING_CLICKED = 'Ridership Bonus Start Earning Clicked';
const RIDERSHIP_BONUS_ENROLL_SUCCESS = 'Ridership Bonus Enroll Success';
const GENERATE_QR_CODE = 'Generate QR Code';
const RECURRING_RIDE_BOOKED = 'Recurring Ride Booked';
const SELECT_WORKPLACE = 'Select Workplace';
const CONFIRM_WORKPLACE = 'Confirm Workplace';
const CHANGE_WORKPLACE = 'Change Workplace';
const PICKUP_HERE_SELECTED = 'Pickup Here';
const PICKUP_LOCATION_TILE_CLOSED = 'Pickup Location Tile Closed';
const WORKPLACE_TILE_CLOSED = 'Workplace Tile Closed';
const PICKUP_LOCATION_SELECTED_OR_TAPPED = 'Pickup Location Selected/Tapped';
const WORKPLACE_SELECTED_OR_TAPPED = 'Workplace Selected/Tapped';

const ABTESTING_FEATURES = {
  SMS_MODULE: 'SMS-MODULE',
  ORIGINAL_FLOW: 'Original Flow',
  WORKFORCE_FLOW: 'Workforce Flow',
};

export default {
  SIGNUP_BUTTON,
  LOGIN_BUTTON,
  MY_RIDES_VIEW,
  MY_RIDES_TRIP_SELECT,
  TRIP_OPTIONS_VIEW,
  TRIP_OPTIONS_SELECT_TRIP,
  TRIP_OPTIONS_CONFIRM_BUTTON,
  TRIP_OVERVIEW_VIEW,
  TRIP_OVERVIEW_BACK_BUTTON,
  BOOK_THIS_RIDES_BUTTON,
  TRIP_DETAIL_VIEW,
  TRIP_DETAIL_BACK_BUTTON,
  CANCEL_TRIP_BUTTON,
  GOALS_BUTTON,
  LOGOUT_BUTTON,
  SELECT_RIDER,
  SIGNUP,
  LOGIN,
  TRIP_REQUEST_VIEW,
  SEARCH_TRIP,
  PROFILE_VIEW,
  SELECT_BUS_STOP_MARKER,
  UPDATE_ACCOUNT_BUTTON,
  SPECIAL_NEEDS,
  ADDRESS_TYPE,
  ADDRESSES,
  ORGANIZTION_WELCOME_VIEW,
  TRANSIT_TRIP_DETAIL_VIEW,
  TRANSIT_TRIP_DETAIL_BACK_BUTTON,
  SAVE_PAYMENT_METHOD_BUTTON,
  DELETE_PAYMENT_METHOD_BUTTON,
  SELECTED_PAYMENT_METHOD,
  PAYMENT_METHOD_VIEW,
  MAP_VIEW,
  FORGOT_PASSWORD,
  LOGIN_FAILED,
  SIGNUP_FAILED,
  ACCOUNT_UPDATE_FAILED,
  INVALID_PROMO_CODE,
  APPLIED_PROMO_CODE,
  AUTO_APPLIED_PROMO_CODE,
  PROMO_CODE_REMOVED,
  RATED_THE_RIDE,
  SKIPPED_RATE_RIDE,
  ORGANIZATION_SELECTED,
  ADD_TO_CALENDER_CLICKED,
  ADDED_TO_CALENDER,
  SAVED_ADDRESSES,
  CONTACT_CLICKED,
  GO_HERE_CLICKED,
  ACCEPTED_INVITE,
  DECLINED_INVITE,
  ACCEPT_INVITE_FAILED,
  SCHEDULE_CLIKED,
  PASSWORDLESS_AUTH_FAILED,
  PASSWORDLESS_AUTH_SUCCESS,
  INVITATION_SENT,
  INVITE_FRIENDS_BUTTON_CLICKED,
  SEARCH_TRIPS_FOR_SELECTED_LOCATION,
  SELECTED_TRIP_DETAIL,
  ORGANIZATION_PLACE_MARKER_SELECTED,
  REFERRAL_LINK_COPIED,
  OPEN_INVITE_FRIENDS,
  ORGANIZATION_PLACES_NOT_FOUND,
  ORGANIZATION_PLACES_LOADED,
  RESET_PASSWORD,
  USER_ORG_RESTRICTIONS,
  LOAD_CREDIT_BUTTON_CLICKED,
  LOAD_CREDIT_SUBMIT_BUTTON_CLICK,
  LOAD_CREDIT_CANCEL_BUTTON_CLICK,
  LOAD_CREDIT_CONFIRM_BUTTON_CLICK,
  LOAD_CREDIT_CLOSED,
  LOAD_CREDIT_SUCCESS,
  LOAD_CREDIT_FAILED,
  RIDERSHIP_REDEEM_BONUS_BUTTON_CLICKED,
  RIDERSHIP_REDEEM_BONUS_CLOSED,
  RIDERSHIP_REDEEM_BONUS_SUCCESS,
  RIDERSHIP_REDEEM_BONUS_FAILED,
  RIDERSHIP_BONUS_ENROLL_FAILED,
  RIDERSHIP_BONUS_INTRO_OPENED,
  RIDERSHIP_BONUS_INTRO_CLOSE_WITHOUT_ENROLL,
  RIDERSHIP_BONUS_VIEW,
  RIDERSHIP_REDEEM_BUTTON_CLICKED,
  RIDERSHIP_BONUS_BACK_BUTTON_CLICKED,
  RIDERSHIP_BONUS_START_EARNING_CLICKED,
  RIDERSHIP_BONUS_ENROLL_SUCCESS,
  GENERATE_QR_CODE,
  RECURRING_RIDE_BOOKED,
  ABTESTING_FEATURES,
  SELECT_WORKPLACE,
  CONFIRM_WORKPLACE,
  CHANGE_WORKPLACE,
  PICKUP_HERE_SELECTED,
  PICKUP_LOCATION_TILE_CLOSED,
  WORKPLACE_TILE_CLOSED,
  PICKUP_LOCATION_SELECTED_OR_TAPPED,
  WORKPLACE_SELECTED_OR_TAPPED,
};
