/** @format */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { theme } from '@constants';
import { helpers } from '@utils';
import { Button, Col, Row } from '@components';
import { TripDateTimeColumn, Title } from './styled';

import { TripDateTimePicker } from './tripDateTimePicker';

const TripDateTimeForm = ({
  type,
  tripType,
  tripDate,
  tripTime,
  isValidDate,
  getValidTimes,
  onSet,
  onBack,
  getAddressFromType,
  tripRequestAvailableDays,
  checkArriveByPlace,
  showTripTypeSwitch,
  onSelectTripType,
}) => {
  const { t } = useTranslation();
  const [tripDateTimeData, setTripDateTimeData] = useState({
    tripDate,
    tripTime,
    tripType,
  });
  const [isCustomTime, setIsCustomTime] = useState(false);
  const disabled =
    !tripDateTimeData.tripDate ||
    !tripDateTimeData.tripTime ||
    (showTripTypeSwitch && !tripDateTimeData.tripType);

  const isValidDateWithType = current => isValidDate(current, type);
  const validTimes = getValidTimes(type, tripDateTimeData.tripDate, tripDateTimeData.tripType);

  const onChange = (key, value) => {
    if (key === 'tripType') {
      onSelectTripType(type, value);
    }

    if (key === 'tripTime') {
      const format = 'HH:mm:ss';
      let updatedTime = moment(value, format);
      const existingTime = moment(tripDateTimeData.tripTime, format);
      const address = getAddressFromType(type);
      const date = tripDateTimeData.tripDate;

      if (tripRequestAvailableDays && !address.organizationId && date) {
        const day = moment(date).locale('en').format('dddd').toLowerCase();
        const availableTime = tripRequestAvailableDays[day];
        let isSlotTime = false;
        let slotToTake = 0;

        for (let i = 0; i < availableTime.length; i += 1) {
          const availableFromTime = moment(availableTime[i].from, format);
          const availableToTime = moment(availableTime[i].to, format);
          if (
            updatedTime.isSameOrAfter(availableFromTime) &&
            updatedTime.isBefore(availableToTime)
          ) {
            isSlotTime = true;
            break;
          }

          if (existingTime.length) {
            if (updatedTime.isSameOrAfter(availableToTime)) slotToTake += 1;
          }
        }

        if (updatedTime.isBefore(existingTime)) slotToTake -= 1;
        if (slotToTake < 0) slotToTake = 0;
        if (slotToTake >= availableTime.length) slotToTake = availableTime.length - 1;
        updatedTime = isSlotTime ? updatedTime : moment(availableTime[slotToTake].from, format);
      }

      const formmatedTime = updatedTime.format(format);

      setTripDateTimeData({
        ...tripDateTimeData,
        [key]: validTimes ? value : formmatedTime,
      });

      return;
    } 
    
    setTripDateTimeData({
      ...tripDateTimeData,
      [key]: value,
      tripTime: ''
    });
  };

  const onOpenTimePicker = () => {
    const format = 'HH:mm:ss';
    const time = moment(`${moment().format('YYYY-MM-DD HH')}:00:00`);
    let updatedTime = moment(time, format);
    const address = getAddressFromType(type);
    const date = tripDateTimeData.tripDate;

    if (tripRequestAvailableDays && !address.organizationId && date) {
      const day = moment(date).locale('en').format('dddd').toLowerCase();
      const availableTime = tripRequestAvailableDays[day];
      let isSlotExists = false;

      for (let i = 0; i < availableTime.length; i += 1) {
        const availableFromTime = moment(availableTime[i].from, format);
        const availableToTime = moment(availableTime[i].to, format);
        if (
          updatedTime.isAfter(availableToTime) &&
          updatedTime.isBefore(availableFromTime)
        ) {
          isSlotExists = true;
        }
      }
      if (!isSlotExists) updatedTime = moment(availableTime[0].from, format);
    }

    const formmatedTime = updatedTime.format(format);

    setTripDateTimeData({
      ...tripDateTimeData,
      tripTime: formmatedTime,
    });
  };

  const onClick = () => {
    onSet({ ...tripDateTimeData, isCustomTime });
  };

  return (
    <TripDateTimeColumn id="trip-dt" justify="flex-start" align="flex-start">
      <Title>{`${helpers.capitalizeFirstLetter(type)} Trip`}</Title>
      <TripDateTimePicker
        type={type}
        tripDate={tripDateTimeData.tripDate}
        tripTime={tripDateTimeData.tripTime}
        tripType={tripDateTimeData.tripType}
        isValidDate={isValidDateWithType}
        validTimes={validTimes}
        onChange={onChange}
        setIsCustomTime={setIsCustomTime}
        onOpenTimePicker={onOpenTimePicker}
        checkArriveByPlace={() => checkArriveByPlace(type)}
        showTripTypeSwitch={showTripTypeSwitch}
      />
      <Row justify="space-between" align="flex-end">
        <Col justify="flex-start" align="flex-start" spacingH={5}>
          <Button
            data-cy="back-btn"
            id="back-btn"
            onClick={onBack}
            color={theme.BASE_COLOR}
            inverted
            bordered
            label={t('trip-request.small-screen.buttons.back')}
          />
        </Col>
        <Col justify="flex-start" align="flex-start" spacingH={5}>
          <Button
            data-cy="set-btn"
            id="set-btn"
            onClick={onClick}
            color={theme.BASE_COLOR}
            label={t('trip-request.small-screen.buttons.set')}
            disabled={disabled}
          />
        </Col>
      </Row>
    </TripDateTimeColumn>
  );
};

TripDateTimeForm.propTypes = {
  type: PropTypes.string.isRequired,
  tripDate: PropTypes.string.isRequired,
  tripTime: PropTypes.string.isRequired,
  tripType: PropTypes.string.isRequired,
  isValidDate: PropTypes.func.isRequired,
  getValidTimes: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onSet: PropTypes.func.isRequired,
  getAddressFromType: PropTypes.func.isRequired,
  checkArriveByPlace: PropTypes.func.isRequired,
  tripRequestAvailableDays: PropTypes.object,
  onSelectTripType: PropTypes.func.isRequired,
  showTripTypeSwitch: PropTypes.bool.isRequired,
};

export default TripDateTimeForm;
