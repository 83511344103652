/** @format */

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '..';
import { theme, device } from '@constants';
import { styles as styleUtils } from '@utils';
import { FloatingLabelInput } from '../../thirdParty';

export const FloatingInputWrapper = styled.div`
  ${styleUtils.flex('flex-start', 'flex-start', 'column')}
  position: relative;
`;

export const FloatingInput = styled(FloatingLabelInput)`
  margin-bottom: 5px;
  box-sizing: border-box;
  display: block;
  width: 100%;
  position: relative;

  input {
    width: 100%;
    border: none;
    border: 1px solid
      ${props => (props.error ? theme.TOMATO : theme.LIGHTEN_GRAY)};
    box-sizing: border-box;
    font-size: 18px;
    font-weight: normal;
    border-radius: 8px;
    line-height: 20px;
    font-family: BryantRegular;
    padding: 15px 16px 13px 16px;
    @media ${device.mediaQuery.SMALL_SCREEN} {
      padding: 18px 16px;
      padding-left: ${props => (props.name === 'phone' ? '85px' : '16px')};
    }
    padding-left: ${props => (props.name === 'phone' ? '85px' : '16px')};
    color: ${theme.COD_GRAY_LIGHT} !important;
    -webkit-appearance: none;

    &:focus {
      outline: none;
    }
  }

  label {
    box-sizing: border-box;
    font-size: 18px;
    pointer-events: none;
    margin: 0px;
    color: ${theme.COD_GRAY_LIGHT} !important;
    font-weight: bold;
    font-family: Bryant;
  }
`;

export const FloatingInputError = styled.span`
  color: ${theme.TOMATO};
  font-size: 16px;
  font-weight: 400;
  font-family: BryantRegular;
  margin-bottom: 5px;
`;

export const InputWrapper = styled.div`
  ${styleUtils.flex('flex-start', 'flex-start', 'column')}
`;

export const InputIconWrapper = styled.div`
  ${styleUtils.flex('flex-start', 'center')}
  position: relative;
`;

export const Input = styled.input`
  height: 38px;
  width: 100%;
  border-radius: ${props => (props.radius ? `${props.radius}px` : '100px')};
  font-weight: ${props => (props.bold ? '600' : 'none')};
  padding-left: ${props => (props.icon ? '50px' : `${props.paddingx}px`)};
  padding-right: ${props => `${props.paddingx}px`};
  color: ${props => {
    return props.inverted ? styleUtils.hexToRgba(props.color) : theme.WHITE;
  }};
  background-color: ${props => (props.inverted ? theme.WHITE : props.color)};
  border: ${props =>
    props.bordered
      ? `${props.borderSize}px solid ${props.borderColor || props.color}`
      : 'none'};
  -webkit-appearance: none;

  ::placeholder {
    color: ${props => {
      return props.inverted
        ? styleUtils.hexToRgba(props.color, 0.5)
        : theme.WHITE;
    }};
    font-weight: 100;
  }

  &:focus {
    outline: none;
  }
`;

export const InputIcon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 20px;
  color: ${props => props.color};
`;

export const FloatingInputIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 10px;
  z-index: 2;
  top: 53%;
  font-size: 18px;
  color: ${props => props.color};
`;

export const CustomInputIcon = styled.img`
  position: absolute;
  left: 10px;
  z-index: 2;
  width: 25px;
  height: 25px;
  top: 47%;
`;

export const CountryCode = styled.div`
  position: absolute;
  left: 44px;
  padding-right: 12px;
  border-right: 1px solid ${theme.LIGHTEN_GRAY};
  color: ${theme.COD_GRAY_LIGHT};
  z-index: 2;
  top: 50%;
`;

export const ClearIcon = styled(IconButton)`
  padding-left: 10px;
  position: absolute;
  right: 15px;
  width: 20px !important;
  z-index: 2;
  top: 36%;
  background-color: ${theme.WHITE};
`;

export const InputError = styled.span`
  color: ${theme.TOMATO};
  font-size: 10px;
  font-weight: 600;
  margin: 10px 5px;
`;
