/** @format */

import styled from 'styled-components';
import { Input } from '@components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  margin-top: 0;
  font-family: 'Inter';
`;

export const WrapperContainer = styled.div`
  width: 304px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  margin-bottom: 24px;
  gap: 16px;
`;

export const Title = styled.div`
  width: 304px;
  font-style: normal;
  font-weight: 600;
  font-size: 20.25px;
  line-height: 140%;
  align-items: center;
  color: rgba(0, 0, 0, 0.96);
  padding-bottom: 16px;
`;

export const Script = styled.div`
  width: 304px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.72);
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

export const SInput = styled(Input)`
  padding-top: 16px;
`;

export const GiftCardSelectionLabel = styled.div`
  padding-top: 16px;
  width: 304px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.72);
`;

export const GiftCardOptionsWrapper = styled.div`
  padding-top: 8px;
  display: inline-block;
`;

export const Option = styled.div`
  height: fit-content;
  float: left;
`;

export const OptionCircleWrapper = styled.div`
  float: left;
  padding-top: 2.5px;
`;

export const OptionCircle = styled.div`
  margin-left: ${props => props.index > 0 ? '36px' : '0'};
  width: 18px;
  height: 18px;
  border: 1px solid rgba(0, 0, 0, 0.72);
  border-radius: 100px;
  padding-top: 1px;
  padding-left: 1px;
  cursor: pointer;
`;

export const OptionCircleActive = styled.div`
  width: 14px;
  height: 14px;
  background: #337FFF;
  border-radius: 14px;
  cursor: pointer;
`;

export const OptionText = styled.p`
  margin-left: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.72);
  width: fit-content;
  float: left;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
`;

export const CancelButton = styled.div`
  padding: 6px 12px;
  gap: 8px;
  text-align: center;
  width: 72px;
  height: 40px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #6b5f00;
  line-height: 30px;
  display: inline-block;
  cursor: pointer;
`;

export const ConfirmButton = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  width: 125px;
  height: 40px;
  background: #005eff;
  border: 0.5px solid #337fff;
  border-radius: 4px;
  display: inline-block;
  float: right;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #ffffff;
  text-align: center;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;

export const LoaderWrapper = styled.div`
  width: fit-content;
  padding-right: 5px;
  float: left;
`;

export const LoaderIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
`;
