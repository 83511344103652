/** @format */

import styled from 'styled-components';
import { theme } from '@constants';
import { Button, Col, IconButton } from '..';

export const Wrapper = styled(Col)`
  max-height: ${({ isLargeScreen }) => (isLargeScreen ? '80vh' : '100%')} !important;
  font-family: Inter;
  p {
    margin: 0 !important;
  }
`;

export const BannerContainer = styled(Col)`
  width: 100%;
  background: ${theme.BASE_COLOR};
  padding: 16px 18.5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const BannerText = styled.p`
  color: ${theme.WHITE};
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  display: flex;
  align-items: center;
`;

export const RidershipBonusLandingImage = styled.img`
  width: 118px;
  height: 118px;
  object-fit: cover;
  position: absolute;
  right: 16px;
  top: 34px;
`;

export const RidershipLevelsTitleRow = styled.div`
  padding: 8px 16px;
  font-weight: 600;
  font-size: 14px;
  justify-content: space-between;
  display: flex;
`;

export const RidershipLevel = styled.div`
  padding: 5px 16px;
  border-radius: 0 6px 6px 0;
  background: ${props => props.background};
  width: ${props => 65 + (props.index * 5)}%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: left;
  padding-left: ${props => 16 + (props.index * 10)}px;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.05);
`;

export const RidershipLevelRow = styled.div`
  padding-right: 16px;
  align-items: center;
  justify-content: space-between;
  display: flex;
`;

export const RidershipLevelTitle = styled.p`
  font-weight: 600;
  font-size: 14px;
  font-family: 'Inter';
  font-style: normal;
  color: rgba(0, 0, 0, 0.72);
`;

export const RidershipLevelDescription = styled.p`
  font-size: 12px;
  font-weight: 400;
  font-family: 'Inter';
  font-style: normal;
  color: rgba(0, 0, 0, 0.72);
`;

export const RidershipLevelBonusText = styled.p`
  font-weight: 600;
  font-size: ${props => (28 - ((props.index < 4 ? props.index : 3) * 5) / 2).toString()}px !important;
  color: #15803d;
`;

export const RidershipBonusLandingIntroContainer = styled(Col)`
  padding: 38px 48px 0 16px;
`;

export const RidershipBonusLandingIntroTitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 22.5px;
  padding-right: 101px;
  line-height: 120%;
  padding-bottom: 8px;
`;

export const RidershipBonusLandingIntroDescription = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  width: 80%;
`;

export const StartEarningButton = styled(Button)`
  background: #7988ee;
  border-radius: 4px;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 14px;
  margin: 12px;
  width: unset !important;
`;

export const RidershipBonusLandingEndNote = styled.p`
  font-weight: 400;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.48);
  padding: 0 16px 8px 16px;
`;

export const RidershipBonusLandingLevelText = styled.p`
  ${props => props.color && `color: ${props.color};`}
`;

export const CloseIconStyled = styled(IconButton)`
  margin-top: 0;
  z-index: 2;
`;

export const SkipBtn = styled.span`
  margin-bottom: 10px;
  font-size: 10px;
  color: ${theme.DARK_BLUE};
  cursor: pointer;
  
  :hover {
    text-decoration: underline;
  }
`;

export const RidershipBonusTermsLink = styled.a`
  text-decoration: underline;
  color: inherit;
  cursor: pointer;
`;
