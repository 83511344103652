/** @format */

import styled from 'styled-components';
import { device } from '@constants';
import { Button, Col } from '@components';

export const Wrapper = styled.div`
  padding: 20px;
  height: 100%;
`;

export const ButtonWrapper = styled.div`
  @media ${device.mediaQuery.SMALL_SCREEN} {
    bottom: 0;
    padding: 20px;
  }

  @media ${device.mediaQuery.LARGE_SCREEN} {
    padding: 20px;
    bottom: 0;
  }
  width: 100%;
`;

export const SaveBtn = styled(Button)`
  border-radius: 8px;
  font-size: 18px;
  line-height: 18px;
  font-family: BryantRegular;
  font-weight: bold;
  height: 50px;
`;

export const FieldsWrapper = styled(Col)`
  height: 100%;
`; 