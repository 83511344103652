/** @format */

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { theme, device } from '@constants';
import Row from '../row';

export const ClickHandler = styled.button`
  padding: 0;
  border: none;
  background-color: ${theme.TRANSPARENT};

  :focus {
    outline: none;
  }
`;

export const Switch = styled(Row)`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  padding: 0px;
  background-color: ${props => props.bgColor};
  border-radius: 100px;
  position: relative;
  align-items: center;
  opacity: ${props => props.opacity};
`;

export const UnCheckedText = styled.span`
  font-size: ${props => (props.controlFontSize ? '9' : 12 * (props.width / 200))}px;
  color: ${props => (props.checked ? theme.BASE_COLOR : theme.WHITE)};
  position: relative;
  z-index: 2;
  margin-top: 1px;
  width: 50%!important;
  margin-left: ${props => (props.isSpanish ? '25px;' : '0px;')};
  @media ${device.mediaQuery.SMALL_SCREEN} {
    margin-left: ${props => (props.isSpanish ? '15px;' : '0px;')};
  }

`;

export const CheckedText = styled.span`
  font-size: ${props => (props.controlFontSize ? '9' : 12 * (props.width / 200))}px;
  color: ${props => (props.checked ? theme.WHITE : theme.BASE_COLOR)};
  position: relative;
  z-index: 2;
  width: 50% !important;
  margin-top: 1px;
`;

export const Trigger = styled.span`
  transition: left 0.2s ease-in-out;
  width: ${props => props.width / 2 - props.triggerSpacing}px;
  height: ${props => props.height - props.triggerSpacing}px;
  background-color: ${props => props.triggerColor};
  border-radius: 100px;
  position: absolute;
  top: ${props => props.triggerSpacing / 2};
  left: ${props => (props.checked ? `${(props.width / 2) + (props.triggerSpacing / 2)}px` : `${props.triggerSpacing / 2}px`)};
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SwitchIcon = styled(FontAwesomeIcon)`
  width: 12px;
  height: 10px;
  color: ${props => props.color};
`;
