/** @format */

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { setSignupOrganizationId, setSignupReferralCode } from '@slices/auth';
import {
  fetchSignupOrganizations
} from '@slices/app';
import { useQuery } from '@hooks';

const SignupOrg = () => {
  const { orgId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const byReferralCode = query.get('referral');

  useEffect(() => { 
    sessionStorage.setItem('orgId', orgId); // Use Session Storage Hook not working
    sessionStorage.setItem('referCode', byReferralCode);
    orgId && dispatch(setSignupOrganizationId(orgId));
    if (byReferralCode && byReferralCode.length) {
      dispatch(setSignupReferralCode(byReferralCode));
    }
    orgId && dispatch(fetchSignupOrganizations(orgId, byReferralCode));
    history.push('/signup');
  }, [dispatch, history, orgId, byReferralCode]);

  return null;
};

export default SignupOrg;
