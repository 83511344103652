/** @format */

import React from 'react';
import PropTypes from 'prop-types';

import { FullScreen as SFullScreen } from './styled';

export const FullScreen = ({ id, className, headerHeight, children }) => (
  <SFullScreen id={id} className={className} headerHeight={headerHeight}>
    {children}
  </SFullScreen>
);

FullScreen.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  headerHeight: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.element.isRequired),
  ]).isRequired,
};

FullScreen.defaultProps = {
  id: '',
  className: '',
  headerHeight: 0,
};

export default FullScreen;
