/** @format */

import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { trackAbEvent } from '@slices/app';
import { analytics } from '@constants';
import { TripOptions as CTripOptions, FullScreen } from '@components';
import { setSelectedTrip } from '@slices/tripRequest';
import { setTripDetail } from '@slices/tripDetail';
import { useHeaderHeight, useSelectedTrip, useSessionStorage } from '@hooks';

const checkIfConfirmButtonIsEnabled = (departTrip, returnTrip) => {
  let enableConfirm = false;
  if (departTrip || returnTrip) {
    enableConfirm = true;
  }
  return enableConfirm;
};

const TripOptions = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [tripTypeFilters] = useSessionStorage('tripTypeFilters');
  const [, , removeSearchData] = useSessionStorage('workforceTripRequestPayload');

  const [departTrip, returnTrip] = useSelectedTrip();
  const [headerHeight] = useHeaderHeight();

  const { selectedTrip, trips, search, cachedSearchQuery: query } = useSelector(
    store => store.tripRequest,
  );
  const { largeScreen, organizations, selectedOrganization } = useSelector(store => store.app);
  const fixedRoutes = useSelector(store => store.fixedRoute.fixedRoutes);

  // restrict direct /trip-options.
  const departShareTripsExist = trips.share.depart.length > 0;
  const returnShareTripsExist = trips.share.return.length > 0;
  const departTransitShareTripsExist = trips.transitShare.depart?.length > 0;
  const returnTransitShareTripsExist = trips.transitShare.return?.length > 0;
  const departTransitTripsExist = trips.transit.depart?.length > 0;
  const returnTransitTripsExist = trips.transit.return?.length > 0;
  const {
    departDate,
    departTime,
    destinationAddress,
    pickupAddress,
    returnDate,
    returnTime,
  } = query || {};

  const isSearchEmpty =
    !departDate &&
    !departTime &&
    !destinationAddress &&
    !pickupAddress &&
    !returnTime &&
    !returnDate;

  if (
    !departShareTripsExist &&
    !returnShareTripsExist &&
    !departTransitShareTripsExist &&
    !returnTransitShareTripsExist &&
    !departTransitTripsExist &&
    !returnTransitTripsExist &&
    isSearchEmpty
  ) {
    history.push(largeScreen ? '/' : '/trip-request');
  }

  let departTripTypeFilter = null;
  let returnTripTypeFilter = null;

  if (tripTypeFilters) {
    tripTypeFilters.forEach(tripOption => {
      if (tripOption.type === 'depart') {
        departTripTypeFilter = tripOption.filterType;
      } else if (tripOption.type === 'return') {
        returnTripTypeFilter = tripOption.filterType;
      }
    });
  }

  useEffect(() => {
    dispatch(trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
      eventName: analytics.TRIP_OPTIONS_VIEW,
      subJourneyName: 'Book A Ride',
      journeyName: 'Rider Experience',
      details: {
        trips,
      },
    }));
    // eslint-disable-next-line
  }, [search.loading]);

  const enableConfirm = useMemo(() => {
    return checkIfConfirmButtonIsEnabled(
      departTrip,
      returnTrip,
    );
    // eslint-disable-next-line
  }, [departTrip, returnTrip, selectedTrip]);

  const onSelectShareTrip = (index, type) => {
    dispatch(trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
      eventName: analytics.TRIP_OPTIONS_SELECT_TRIP,
      subJourneyName: 'Book A Ride',
      journeyName: 'Rider Experience',
      elementId: 'add-trip-btn',
      isDataCy: true,
      details: {
        'selected-trip-type': type,
        'selected-index': index,
        'selected-tab': 'share',
      },
    }));
    dispatch(
      setSelectedTrip({
        index,
        key: 'share',
        type,
      }),
    );
  };

  const onSelectTransitTrip = transitTrip => {
    dispatch(setTripDetail(transitTrip));
    history.push('/transit-trip-detail', {
      prevRoute: 'trip-options',
    });
  };

  const onSelectTransitShareTrip = (index, selectedTripType, detailClick) => {
    const transitShareTrip = trips.transitShare[selectedTripType][index];
    const trip = { ...transitShareTrip, selectedTripType };
    dispatch(trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
      eventName: 'Add A Trip',
      subJourneyName: 'Book A Ride',
      journeyName: 'Rider Experience',
      elementId: 'add-trip-btn',
      isDataCy: true,
      details: {
        'selected-trip-type': selectedTripType,
        'selected-index': index,
        'selected-tab': 'transit-share',
      },
    }));
    if (detailClick) {
      dispatch(setTripDetail(trip));
      history.push('/transit-share-trip-detail', {
        selectedTripIndex: index,
        prevRoute: 'trip-options',
      });
    } else {
      dispatch(
        setSelectedTrip({
          index,
          key: 'transitShare',
          type: selectedTripType,
        }),
      );
    }
  };

  const goToTripOverview = () => {
    dispatch(trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
      eventName: analytics.TRIP_OPTIONS_CONFIRM_BUTTON,
      subJourneyName: 'Book A Ride',
      journeyName: 'Rider Experience',
      details: {
        ...selectedTrip,
      },
    }));
    history.push('/trip-overview');
  };

  const goToHome = (backBtnPressed = false) => {
    if (backBtnPressed) {
      removeSearchData();
    }
    history.push('/');
  };

  const tripOptions = (
    <CTripOptions
      trips={trips}
      selectedTrip={selectedTrip}
      onSelectShareTrip={onSelectShareTrip}
      onSelectTransitTrip={onSelectTransitTrip}
      onSelectTransitShareTrip={onSelectTransitShareTrip}
      isReturnTrip={query.isReturn}
      enableConfirm={enableConfirm}
      onClickConfirm={goToTripOverview}
      largeScreen={largeScreen}
      tripTypeFilterDepart={departTripTypeFilter}
      tripTypeFilterReturn={returnTripTypeFilter}
      query={query}
      fixedRoutes={fixedRoutes}
      organizations={organizations || []}
      selectedOrganization={selectedOrganization}
      goToHome={goToHome}
    />
  );

  return largeScreen ? (
    tripOptions
  ) : (
    <FullScreen headerHeight={headerHeight}>{tripOptions}</FullScreen>
  );
};

export default TripOptions;
