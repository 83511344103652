/** @format */

import styled from 'styled-components';
import { theme } from '@constants';

export const HomeWrapper = styled.div`
  padding: 30px;
`;

export const WelcomeMessage = styled.div`
  color: ${theme.WHITE};
  background: ${theme.BASE_COLOR};
  border-radius: 6px;
  padding: 15px 30px;
`;

export const WelcomeHeading = styled.h6`
  font-weight: bold;
`;

export const GreetingsList = styled.ul`
  list-style-type: '-';
  list-style-position: inside;
  padding: 0;
  margin: 0;
`;

export const GreetingsListItem = styled.li`
  font-size: 14px;

  &:before {
    content: ' ';
  }
`;
