/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { NotificationCol, NotificationText } from './styled';

export const NotificationPopup = ({ message, isError }) => {
  return (
    <NotificationCol
      flex="initial"
      justify="flex-start"
      align="flex-start"
      spacingV={15}
      spacingH={30}
      fullWidth={false}
      isError={isError}
    >
      <NotificationText id="text">{message}</NotificationText>
    </NotificationCol>
  );
};

NotificationPopup.propTypes = {
  message: PropTypes.string.isRequired,
  isError: PropTypes.bool,
};

NotificationPopup.defaultProps = {
  isError: false,
};

export default NotificationPopup;
