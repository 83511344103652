/**
 * eslint-disable react/prop-types
 *
 * @format
 */

import React, { useState, useRef, useEffect } from 'react';
import calendarIcon from '@assets/icons/calender.svg';
import inputIcon from '@assets/icons/inputIcon.svg';
import { theme } from '@constants';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  CalendarContainer,
  CalendarInput,
  Input,
  DateContainer,
  ButtonContainer,
  Button,
  Image,
  DatePicker,
} from './styled';

const Calendar = ({
  onConfirm,
  dateFormat,
  disabledDays,
  selectedDayBackgroundColor,
  width,
  value,
  onOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const [showCalendar, setShowCalendar] = useState(false);
  const [selected, setSelected] = useState(value);
  const [initialSelected, setInitialSelected] = useState(value);
  const placeholder = t('workforce-trip-request.fields.calendar.placeholder');
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const checkIfClickedOutside = e => {
      // Close the menu if clicked outside
      if (ref.current && !ref.current.contains(e.target)) {
        setShowCalendar(false);
        onClose();
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  const formatDate = date => {
    if (!selected) return '';
    return moment(date).format(dateFormat);
  };

  const renderFooter = selected => {
    if (!selected) return null;
    return (
      <>
        <ButtonContainer>
          <Button
            onClick={() => {
              setSelected(initialSelected);
              setShowCalendar(false);
              onClose();
              setIsOpen(false);
            }}
          >
            {t('workforce-trip-request.fields.calendar.buttons.cancel')}
          </Button>
          <Button
            confirm
            onClick={() => {
              onConfirm(selected);
              setInitialSelected(selected);
              setShowCalendar(false);
              onClose();
              setIsOpen(false);
            }}
          >
            {t('workforce-trip-request.fields.calendar.buttons.confirm')}
          </Button>
        </ButtonContainer>
      </>
    );
  };

  return (
    <CalendarContainer
      onClick={
        isOpen
          ? () => {}
          : () => {
              setIsOpen(true);
              onOpen();
            }
      }
      ref={ref}
    >
      <CalendarInput>
        <Image src={inputIcon} inputCalendarIcon />
        <Input
          width={width}
          value={formatDate(selected)}
          onClick={() => setShowCalendar(true)}
          placeholder={placeholder}
        />
      </CalendarInput>

      {showCalendar && (
        <DateContainer>
          <Image src={calendarIcon} />

          <DatePicker
            mode="single"
            disabled={
              Array.isArray(disabledDays) && disabledDays.length
                ? disabledDays
                : { before: new Date() }
            }
            selected={selected}
            onSelect={setSelected}
            footer={renderFooter(selected)}
            background={selectedDayBackgroundColor}
          />
        </DateContainer>
      )}
    </CalendarContainer>
  );
};

Calendar.propTypes = {
  dateFormat: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  disabledDays: PropTypes.array.isRequired,
  selectedDayBackgroundColor: PropTypes.string,
  width: PropTypes.number,
  value: PropTypes.string,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

Calendar.defaultProps = {
  dateFormat: 'ddd, MMM. D',
  selectedDayBackgroundColor: theme.DEEP_SKY_BLUE,
  width: 100,
  value: null,
};

export default Calendar;
