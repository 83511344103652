/** @format */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal, RateRideForm } from '@components';
import { setTripFeedback, rateTrip } from '@slices/myTrips';
import { trackAbEvent } from '@slices/app';
import { analytics } from '@constants';
import { checkAndNavigateToRidershipBonusLandingPage } from '@slices/auth';

const RateRide = () => {
  const history = useHistory();
  const { completedTrip, loading } = useSelector(store => store.myTrips);
  const {
    modals: { ratingSubmitted },
    largeScreen,
  } = useSelector(store => store.app);

  const dispatch = useDispatch();

  if (!completedTrip || (completedTrip && !completedTrip.route)) {
    dispatch(checkAndNavigateToRidershipBonusLandingPage(history, '/'));
    return null;
  }

  const onSubmit = tripRating => {
    sessionStorage.setItem('should-not-open-rate-ride', true);
    dispatch(rateTrip(completedTrip.id, tripRating));
    dispatch(setTripFeedback(completedTrip.id));
  };
  const onCloseModal = (skippedRating = false) => {
    sessionStorage.setItem('should-not-open-rate-ride', true);
    if (skippedRating) {
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: 'Skip Rating',
          subJourneyName: 'Rate Your Trip',
          journeyName: 'Rider Experience',
          elementId: 'skip-rating-btn',
          isDataCy: true,
          details: {
            'trip-id': completedTrip.id,
            trip: completedTrip,
          },
        }),
      );
    }
    dispatch(checkAndNavigateToRidershipBonusLandingPage(history, '/'));
  };

  return (
    <Modal open width={largeScreen ? '556' : null} overflow="visible">
      <RateRideForm
        tripDetails={completedTrip.route}
        largeScreen={largeScreen}
        loading={loading}
        onCloseModal={onCloseModal}
        ratingSubmitted={ratingSubmitted}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};
export default RateRide;
