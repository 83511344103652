/** @format */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { SpecialNeedsField, Col, Input, Row, IconButton } from '@components';
import { helpers } from '@utils';
import { theme, generals } from '@constants';
import { useFocusNextInput } from '@hooks';
import { ButtonWrapper, SaveBtn, Wrapper, FieldsWrapper } from './styled';

const specialNeedsEnabled = process.env.REACT_APP_SPECIAL_NEEDS_ENABLED === 'true';

const ExtraFieldsAndSpecialNeeds = ({
  onChangeSpecialNeeds,
  state,
  onChangeShowSpecialNeed,
  onChange,
  errors,
  validate,
  onSubmit,
  loading,
  isValid,
  goBack,
  dynamicTextFields,
}) => {
  const { t } = useTranslation();
  const [formFields, setFormFields] = useState([]);
  const formFieldsForFocusNext = formFields.filter(o => o.focusNext);
  const [inputRefs, focusOnNextInput] = useFocusNextInput(
    formFieldsForFocusNext,
  );

  useEffect(() => {
    const dynamicFields = [];
    if (
      Array.isArray(dynamicTextFields) &&
      dynamicTextFields.length &&
      !formFields.length
    ) {
      dynamicTextFields.forEach(tf => {
        const { label, type } = tf;
        const isRequiredField =
          type === generals.USER_DYNAMIC_FIELD_TYPES.REQUIRED;

        const fieldFound = formFields.find(
          field => field.name === helpers.camelize(label),
        );
        if (!fieldFound) {
          dynamicFields.push({
            name: helpers.camelize(label),
            label: `${label} ${
              isRequiredField
                ? `(${t('profile.fields.dynamic-field.label.required')})`
                : ''
            }`,
            type: 'text',
            focusNext: true,
            validator: {
              required: isRequiredField
                ? t('profile.fields.dynamic-field.validation.required')
                : null,
            },
          });
          inputRefs.current.push(React.createRef());
        }
      });
      setFormFields(dynamicFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dynamicTextFields]);

  const getInputRef = (ref, formField) => {
    if (!ref) {
      return;
    }

    const index = formFieldsForFocusNext.findIndex(o => o.name === ref.name);

    inputRefs.current[index].current = ref;
    validate(ref, formField.validator);
  };

  return (
    <Wrapper>
      <Row flex="initial" align="flex-end" justify="flex-end">
        { isValid && <IconButton id="close-btn" onClick={goBack} /> }
      </Row>

      <FieldsWrapper justify="space-between" align="center">
        <Col justify="flex-start" align="center">
          {formFields.map((formField, i) => {
            return (
              <Input
                key={i}
                floating
                type={formField.type}
                name={formField.name}
                onChange={onChange}
                value={
                  state[formField.name] === undefined
                    ? state.detail[formField.name]
                    : state[formField.name]
                }
                label={formField.label}
                onKeyPress={focusOnNextInput}
                color={theme.COD_GRAY_LIGHT}
                inputRef={ref => getInputRef(ref, formField)}
                error={helpers.getObjectProperty(errors, [
                  formField.name,
                  'message',
                ])}
              />
            );
          })}

          {
            specialNeedsEnabled &&
            <SpecialNeedsField
              onChange={onChangeSpecialNeeds}
              specialNeeds={state.specialNeeds}
              onChangeShowSpecialNeed={onChangeShowSpecialNeed}
              showSpecialNeed={state.showSpecialNeed}
              height={200 - (formFields || []).length * 30}
            />
          }
        </Col>
        <ButtonWrapper>
          <SaveBtn
            data-cy="special-needs-save-btn"
            id="submit-btn"
            onClick={onSubmit}
            label={t('special-needs.buttons.save')}
            color={theme.BASE_COLOR}
            icon={loading ? faSpinner : null}
            iconProps={{ spin: true }}
            disabled={loading || !isValid}
          />
        </ButtonWrapper>
      </FieldsWrapper>
    </Wrapper>
  );
};

ExtraFieldsAndSpecialNeeds.propTypes = {
  state: PropTypes.shape({
    specialNeeds: PropTypes.array,
    showSpecialNeed: PropTypes.string,
    detail: PropTypes.object,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  isValid: PropTypes.bool.isRequired,
  validate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  onChangeSpecialNeeds: PropTypes.func.isRequired,
  onChangeShowSpecialNeed: PropTypes.func.isRequired,
  dynamicTextFields: PropTypes.array.isRequired,
};

export default ExtraFieldsAndSpecialNeeds;
