/** @format */

import styled from 'styled-components';
import { theme } from '@constants';

export const Badge = styled.span`
  background: ${theme.BASE_COLOR};
  color: ${theme.WHITE};
  font-size: 11px;
  border-radius: 3px;
  padding: 1px 6px;
`;
