/** @format */

import styled from 'styled-components';

import { Col } from '@components';

export const HomeCol = styled(Col)`
  z-index: 1;
  position: absolute;
  bottom: 0;
`;
