/** @format */

import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';
import { api, analytics, generals as generalConstants } from '@constants';
import { api as apiClient, toast, tripHelpers } from '@utils';
import { trackEvent, setFullScreenLoading } from '@slices/app';
import { trackAbEvent } from '../app';

const defaultQueryState = {
  placeIds: [],
  timestamp: new Date().toISOString(),
  organizationId: null,
};

const selectionDefaultState = {
  organizationId: null,
  date: '',
  time: '',
  requestType: '',
  pickupAddress: {
    label: '',
    lat: null,
    lng: null,
  },
  destinationAddress: {
    label: '',
    lat: null,
    lng: null,
  },
  isReturn: true,
  returnDate: '',
  returnTime: '',
};

export const defaultState = {
  trips: {
    share: {
      data: [],
      total: 0,
    },
  },
  selectedTripDetail: null,
  selectedReturnTripDetail: null,
  isDateTimeChanged: false,
  selectedMarker: null,
  firstTimeFitBounds: false,
  search: {
    query: defaultQueryState,
    selection: selectionDefaultState,
  },
  repeat: {
    count: { depart: 0, return: 0 },
    enabled: false,
    days: {
      sun: false,
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
    },
    end: null,
  },
  extraSeats: {
    ambulatory: 0,
    handicapped: 0,
  },
  loading: null,
  showUnauthenticated: false,
};

const TripRequestWorkforceSlice = createSlice({
  name: 'tripRequestWorkforce',
  initialState: defaultState,
  reducers: {
    setLoading(state, action) {
      const { payload: loading } = action;

      return {
        ...state,
        loading,
      };
    },
    setSearchQuery(state, action) {
      const { payload } = action;

      return {
        ...state,
        search: {
          ...state.search,
          query: {
            ...state.search.query,
            [payload.key]: payload.value,
          },
        },
      };
    },
    setSearchQueryPlaceIds(state, action) {
      const { payload } = action;

      return {
        ...state,
        search: {
          ...state.search,
          query: {
            ...state.search.query,
            placeIds: payload,
          },
        },
      };
    },
    setSearchQueryTime(state, action) {
      const { payload } = action;

      return {
        ...state,
        search: {
          ...state.search,
          query: {
            ...state.search.query,
            timestamp: payload,
          },
        },
      };
    },
    setSearchSelection(state, action) {
      const { payload } = action;

      return {
        ...state,
        search: {
          ...state.search,
          selection: {
            ...payload,
          },
        },
      };
    },
    setSearchSelectionState(state, action) {
      const { payload } = action;

      return {
        ...state,
        search: {
          ...state.search,
          selection: {
            ...state.search.selection,
            [payload.key]: payload.value,
          },
        },
      };
    },
    setSearchSelectionDate(state, action) {
      const { payload } = action;
      return {
        ...state,
        search: {
          ...state.search,
          selection: {
            ...state.search.selection,
            date: payload,
          },
        },
      };
    },
    setSearchSelectionTime(state, action) {
      const { payload } = action;
      return {
        ...state,
        search: {
          ...state.search,
          selection: {
            ...state.search.selection,
            time: payload,
          },
        },
      };
    },
    setTrips(state, action) {
      return {
        ...state,
        trips: action.payload,
      };
    },
    setSelectedTripDetail(state, action) {
      const data = action.payload;
      return {
        ...state,
        selectedTripDetail: data,
      };
    },

    setIsDateTimeChanged(state, action) {
      const data = action.payload;
      return {
        ...state,
        isDateTimeChanged: data,
        };
    },
    setSelectedMarker(state, action) {
      const data = action.payload;
      return {
        ...state,
        selectedMarker: data,
      };
    },
    setFirstTimeFitBounds(state, action) {
      const value = action.payload;
      return {
        ...state,
        firstTimeFitBounds: value,
      };
    },
    setSelectedReturnTripDetail(state, action) {
      const data = action.payload;
      return {
        ...state,
        selectedReturnTripDetail: data,
      };
    },
    setRepeatEnabled(state, action) {
      const { enabled, end } = action.payload;
      return {
        ...state,
        repeat: {
          ...defaultState.repeat,
          enabled,
          end,
        },
      };
    },
    setRepeatData(state, action) {
      const data = action.payload;

      return {
        ...state,
        repeat: {
          ...state.repeat,
          ...data,
        },
      };
    },
    setShowUnauthenticated(state, action) {
      return {
        ...state,
        showUnauthenticated: action.payload,
      };
    },
    resetAfterSearchTrips(state) {
      return {
        ...defaultState,
        search: state.search,
      };
    },
    resetTripRequestState() {
      return {
        ...defaultState,
      };
    },
    resetTripRequestStateOnTripOverviewUnmount(state) {
      return {
        ...state,
        selectedReturnTripDetail: null,
        repeat: defaultState.repeat,
      };
    },
  },
});

export const {
  setLoading,
  setSearchQuery,
  setSearchQueryPlaceIds,
  setSearchQueryTime,
  setSearchSelection,
  setSearchSelectionState,
  setSearchSelectionDate,
  setSearchSelectionTime,
  setTrips,
  setSelectedTrip,
  setRepeatEnabled,
  setRepeatData,
  setShowUnauthenticated,
  setSelectedTripDetail,
  setIsDateTimeChanged,
  setSelectedMarker,
  setFirstTimeFitBounds,
  setSelectedReturnTripDetail,
  resetSelectedTrip,
  resetAfterSearchTrips,
  resetTripRequestState,
  resetTripRequestStateOnTripOverviewUnmount,
} = TripRequestWorkforceSlice.actions;

const getFormattedTrips = ({ data, meta }) => {
  return {
    share: {
      data: data || [],
      total: meta.inlineCount,
    },
  };
};

// thunk for fetching trips(all on zooming map)
export const fetchTripRequests = (page = 0) => {
  return async (dispatch, getState) => {
    const loader =
      page === 0
        ? generalConstants.TRIP_OPTIONS_WORKFORCE_LOADING
        : generalConstants.WORKFORCE_TRIPS_PAGINATION_COUNT;
    dispatch(setLoading(loader));

    const workforceOrgIds = (
      process.env.REACT_APP_WORKFORCE_ORG_IDS || ''
    ).split(',');

    const limit = generalConstants.WORKFORCE_TRIPS_PAGINATION_COUNT;
    const skip = page * limit;

    try {
      const {
        app: { organizations, selectedOrganization },
        tripRequestWorkforce: {
          search: { query },
          trips: { share: shareTrips },
        },
      } = getState();

      const data = {
        placeIds: query.placeIds,
        time: query.timestamp,
      };

      if (!data.placeIds.length || data.placeIds.length > 1) {
        dispatch(setLoading(null));
        return;
      }

      let url = `${api.endpoints.WORKFORCE_TRIPS_SEARCH}?limit=${limit}&skip=${skip}`;
      if (Array.isArray(organizations)) {
        if (selectedOrganization !== null && selectedOrganization < organizations.length) {
          data.organizationId = organizations[selectedOrganization]?.organizationId;
        } else {
          data.organizationId = query.organizationId;
        }
      } else {
        data.organizationIds = workforceOrgIds;
        url = `${api.endpoints.WORKFORCE_TRIPS_OPEN_SEARCH}`;
      }

      if (!data.organizationId && !Array.isArray(data.organizationIds)) {
        return;
      }

      dispatch(trackEvent(analytics.SEARCH_TRIPS_FOR_SELECTED_LOCATION, { placeId: data.placeIds[0], organizationId: data.organizationId, selectedTime: data.time }));
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: 'Search For A Trip',
          subJourneyName: 'Book A Ride',
          journeyName: 'Rider Experience',
          elementId: 'find-rides-btn',
          isDataCy: true,
          details: {
            placeId: data.placeIds[0],
            organizationId: data.organizationId,
            selectedTime: data.time,
          },
        }),
      );
      let response = await apiClient.post(url, data);

      response = getFormattedTrips(response.data);
      // if its page 0 then set what is fetched otherwise append to already fetched records
      if (page !== 0) {
        response.share.data = [...shareTrips.data, ...response.share.data];
      }

      dispatch(setTrips(response));
      dispatch(setLoading(null));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('WORKFORCE_TRIPS_SEARCH_API_ERROR', error);
      dispatch(setLoading(null));
    }
  };
};

export const getTripDetails = (onSuccess, fetchReturn = false) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(generalConstants.FETCH_TRIP_DETAIL_LOADING));
    dispatch(setFullScreenLoading(true));
    try {
      const {
        app: { organizations, selectedOrganization },
        tripRequestWorkforce: {
          search: { selection },
        },
      } = getState();

      if (fetchReturn && (selection.returnDate === '' || selection.returnTime === '')) {
        dispatch(setLoading(null));
        dispatch(setFullScreenLoading(false));
        return;
      }

      let data = {
        pickup: selection.pickupAddress,
        dropoff: selection.destinationAddress,
        requestType: selection.requestType,
        organizationId: (organizations || [])[selectedOrganization]?.organizationId,
        dateTime: `${selection.date} ${selection.time}`,
      };

      if (fetchReturn) {
        data = {
          pickup: selection.destinationAddress,
          dropoff: selection.pickupAddress,
          requestType: selection.requestType,
          organizationId: (organizations || [])[selectedOrganization]?.organizationId,
          dateTime: `${selection.returnDate} ${selection.returnTime}`,
        };
      }

      const response = await apiClient.post(
        api.endpoints.WORKFORCE_TRIP_DETAILS,
        data,
      );

      const tripDetails = response.data;
      const dropoff = tripDetails.places.find(p => p.type === 'Drop-off');
      tripDetails.shift = tripHelpers.getShiftForArrival(tripDetails.arriveTime, dropoff);

      const pickupPlace = fetchReturn
        ? selection.destinationAddress.name
        : selection.pickupAddress.name;
      const dropoffPlace = fetchReturn
        ? selection.pickupAddress.name
        : selection.destinationAddress.name;

      tripDetails.places[0].name = pickupPlace;
      tripDetails.places[1].name = dropoffPlace;

      fetchReturn
        ? dispatch(setSelectedReturnTripDetail(tripDetails))
        : dispatch(setSelectedTripDetail(tripDetails));
      dispatch(setRepeatData({end: tripDetails.leaveTime}));
      dispatch(setLoading(null));
      dispatch(trackEvent(analytics.SELECTED_TRIP_DETAIL, tripDetails));
      dispatch(setFullScreenLoading(false));
      typeof onSuccess === 'function' && onSuccess();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('WORKFORCE_TRIPS_DETAIL_API_ERROR', error);
      if (fetchReturn) {
        toast.show('error', 'Sorry, return trip is not available for the selected date / time');
      }
      dispatch(setLoading(null));
      dispatch(setFullScreenLoading(false));
    }
  };
};

const formatDateForTripRequest = (date, timezone) =>
  moment.tz(date, 'MM/DD/YYYY HH:mm:ss', timezone).utc().format('YYYY-MM-DD HH:mm:ss');

const getArrivalTime = trip => {
  const arrivalTime =
    trip.requestType === 'Ready By' ? trip.leaveTime : trip.arriveTime;

  return formatDateForTripRequest(arrivalTime, trip.places[0].timezone);
};

const getRepeatUntilTime = (trip, repeat) => {
  const repeatUntilTime = repeat.end ? repeat.end : trip.leaveTime;

  return formatDateForTripRequest(repeatUntilTime, trip.places[0].timezone);
};

const getTripRequestData = (type, state) => {
  const {
    tripRequestWorkforce: { selectedTripDetail, selectedReturnTripDetail, repeat, extraSeats },
    app: {
      user: { id: userId },
    },
  } = state;

  const trip = type === "depart" ? selectedTripDetail : selectedReturnTripDetail;

  const arrivalTime = getArrivalTime(trip);
  const repeatUntilTime = getRepeatUntilTime(trip, repeat);
  const tripRequestData = {
    status: 'Requested',
    organizationId: trip.organizationId,
    arrival: arrivalTime,
    roundTrip: false,
    users: [
      {
        userId,
      },
    ],
    createdBy: userId,
    places: [...trip.places],
    ...repeat.days,
    recurringType: repeat.enabled ? 'Weekly' : '',
    end: repeatUntilTime,
    type: trip.requestType,
    extraSeats,
  };

  return tripRequestData;
};

const getPlacesData = data => {
  return data.places.map(async place => {
    if (
      typeof place.placeId === 'string' &&
      place.placeId.indexOf('osm') > -1
    ) {
      const placeData = {
        address: place.address,
        lat: place.lat,
        lng: place.lng,
      };
      const placesResponse = await apiClient
        .post(api.endpoints.PLACES, placeData)
        .then(res => res.data);
      return {
        type: place.type,
        placeId: placesResponse.id,
        address: placesResponse.address,
        lat: placesResponse.lat,
        lng: placesResponse.lng,
        timezone: placesResponse.timezone,
      };
    }
    return place;
  });
};

// api call for creating single trip request (depart or return)
const sendTripRequest = async (type, state) => {
  const tripRequestFormData = getTripRequestData(type, state);

  const placesData = getPlacesData(tripRequestFormData);
  tripRequestFormData.places = await Promise.all(placesData);

  let data = tripRequestFormData;

  data = {
    type,
    ...data,
  };

  const url = api.endpoints.TRIP_REQUESTS;
  const response = await apiClient.post(url, data);

  return response;
};

// thunk for creating trip requests (depart / return)
export const sendTripRequests = (confirmCallback) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(generalConstants.CREATE_TRIP_REQUEST_LOADING));

    const state = getState();
    const {
      tripRequestWorkforce: {
        search: { selection },
      },
    } = state;

    try {
      const created = [];

      const tripRequestDepartResponse = await sendTripRequest(
        'depart',
        state,
      );

      created.push(tripRequestDepartResponse.data);

      if (selection && selection.isReturn) {
        const tripRequestReturnResponse = await sendTripRequest(
          'return',
          state,
        );
        created.push(tripRequestReturnResponse.data);
      }

      dispatch(
        trackEvent(
          analytics.BOOK_THIS_RIDES_BUTTON,
          tripRequestDepartResponse.data,
        ),
      );

      dispatch(setLoading(null));

      confirmCallback(created, () => {
        dispatch(resetTripRequestState());
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('SEND_TRIP_REQUESTS_API_ERROR', error);
      dispatch(setLoading(null));
    }
  };
};
export const tripRequestWorkforceReducer = TripRequestWorkforceSlice.reducer;
