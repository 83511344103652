/** @format */

import styled from 'styled-components';

export const Row = styled.div`
  ${props => (props.fullWidth ? 'width: 100%;' : '')}
  padding: ${props => props.spacingV}px ${props => props.spacingH}px;
  flex: ${props => props.flex};
  display: flex;
  justify-content: ${props => props.justify};
  align-items: ${props => props.align};
  flex-wrap: ${props => props.wrap};
`;
