/** @format */
import React, { useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { generals as generalConstants, theme, hints } from '@constants';
import { useScrollPagination, useLocalStorage, useSessionStorage } from '@hooks';
import { WorkforceTrip } from '@components';
import {
  TripsCol,
  Message,
  LoaderIcon,
  LoaderWrapperRow,
  TripsWrapper,
  TitleRow,
  Title,
  TooltipWrapper,
  Tooltip
} from './styled';
import { useHistory } from 'react-router';

const TripOptionsWorkforce = ({ trips, loading, fetchTrips, onTripSelect, isAuthenticated }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [seenHints, setSeenHints] = useLocalStorage('seenHints');
  const [seenSessionHints, setSeenSessionHints] = useSessionStorage('tripOptionsHints');
  const tripCardRef = useRef(null);

  const shareTrips = trips?.share?.data || [];
  const configs = {
    loading,
    hasMoreData: (trips?.share?.total || 0) > shareTrips.length,
    fetchData: fetchTrips,
  };
  const { lastElemRefCb } = useScrollPagination(configs);

  const showSessionHints = !seenSessionHints && !isAuthenticated;

  const hintsVisible = useMemo(() => {
    return !seenHints.tripOptionsWorkforce && isAuthenticated;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (!seenHints.tripOptionsWorkforce && isAuthenticated && shareTrips.length > 0) {
      setSeenHints({ ...seenHints, tripOptionsWorkforce: true });
    }

    if (hintsVisible && tripCardRef.current) {
      Tooltip.show(tripCardRef.current);
    }

    return () => {
      Tooltip.hide();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hintsVisible, isAuthenticated, loading]);

  useEffect(() => {
    if (showSessionHints && tripCardRef.current) {
      Tooltip.show(tripCardRef.current);
    }

    return () => {
      Tooltip.hide();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seenSessionHints, loading]);

  if (loading && loading === generalConstants.FETCH_TRIP_DETAIL_LOADING) {
    return (
      <TripsCol>
        <LoaderWrapperRow
          id="workforce-trips-loader"
          justify="center"
          align="center"
          spacingV={30}
        >
          <LoaderIcon icon={faSpinner} spin />
        </LoaderWrapperRow>
      </TripsCol>
    );
  }

  const redirectToLogin = async() => {
    if (showSessionHints) {
      await setSeenSessionHints('tripOptionsHints', true);
    }
    history.push('/login');
  };

  return (
    <TripsCol justify="flex-start" align="center">
      {(hintsVisible || showSessionHints) && (
        <Tooltip
          id="trip-options-workforce-tooltip"
          backgroundColor={theme.WEIRD_GREEN}
          textColor={theme.WHITE}
          isCapture
        />
      )}
      <TripsWrapper>
        <TitleRow justify="center" align="center">
          <Title>
            {t('workforce-app.trip-options.labels.available-trips')}
          </Title>
        </TitleRow>
        {loading &&
          loading === generalConstants.TRIP_OPTIONS_WORKFORCE_LOADING && (
            <LoaderWrapperRow
              id="workforce-trips-loader"
              justify="center"
              align="center"
              spacingV={30}
            >
              <LoaderIcon icon={faSpinner} spin />
            </LoaderWrapperRow>
          )}
        {shareTrips.length &&
        loading !== generalConstants.TRIP_OPTIONS_WORKFORCE_LOADING
          ? shareTrips.map((trip, index) => {
              const tripProps = {
                tripTime: trip.arrival,
                pickup: trip.places[0],
                dropoff: trip.places[1],
                tripType: trip.requestType,
                routeType: trip.type,
                onTripSelect: () => isAuthenticated ? onTripSelect(index) : redirectToLogin(),
              };
              
              return (
                <>
                  {index === shareTrips.length - 1 ? (
                      <WorkforceTrip {...tripProps} tripRef={lastElemRefCb} key={index} />
                    ) : index === 0 ? (
                      <TooltipWrapper
                        id="trip-card-1"
                        ref={tripCardRef}
                        data-cy="trip-card-1"
                        data-tip={t(hints.tripOptionsWorkforceHints.card)}
                        data-event="custom"
                        data-for="trip-options-workforce-tooltip"
                        data-place="top"
                        key={index}
                      >
                        <WorkforceTrip {...tripProps} />
                      </TooltipWrapper>
                    ) : (
                      <WorkforceTrip {...tripProps} key={index} />
                    )
                  }
                </>
              )
            })
          : null}
        {loading === generalConstants.WORKFORCE_TRIPS_PAGINATION_COUNT && (
          <LoaderWrapperRow
            id="workforce-trips-loader__pagination"
            justify="center"
            align="center"
            spacingV={30}
          >
            <LoaderIcon icon={faSpinner} spin />
          </LoaderWrapperRow>
        )}
        {!loading && !shareTrips.length ? (
          <TitleRow justify="center" align="center">
            <Message id="message">
              {t('workforce-app.trip-options.messages.no-trips')}
            </Message>
          </TitleRow>
        ) : null}
      </TripsWrapper>
    </TripsCol>
  );
};

TripOptionsWorkforce.propTypes = {
  trips: PropTypes.shape({
    share: PropTypes.shape({
      data: PropTypes.array.isRequired,
      total: PropTypes.number.isRequired,
    }),
  }).isRequired,
  loading: PropTypes.string,
  fetchTrips: PropTypes.func.isRequired,
  onTripSelect: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

TripOptionsWorkforce.defaultProps = {
  loading: false,
  isAuthenticated: false
};

export default TripOptionsWorkforce;
