/** @format */

import styled from 'styled-components';

export const TimeRow = styled.p`
  margin: 5px 0 !important;
  text-transform: capitalize;
  text-align: center;
`;

export const TextAlign = styled.div`
  text-align: center;
`;

export const BoldText = styled(TextAlign)`
  font-weight: bold;
`;
