/** @format */

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Row, Col, Button } from '@components';
import { device, theme } from '@constants';

export const TripOverviewCol = styled(Col)`
  @media ${device.mediaQuery.LARGE_SCREEN} {
    min-height: 100%;
  }

  .slick-prev {
    left: 30%;
    top: 95%;
    z-index: 12;
  }

  .slick-next {
    right: 30%;
    top: 95%;
  }

  .kppgUP {
    max-height: 200vh;
  }
`;

export const Title = styled.h4`
  color: ${theme.BASE_COLOR};
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0;
`;

export const TripCostTitle = styled.span`
  color: ${theme.BASE_COLOR};
  font-size: 8px;
  line-height: 8px;
`;

export const TripCostValue = styled.span`
  color: ${theme.BASE_COLOR};
  font-weight: 600;
  font-size: 12px;
`;
export const TripTimeTitle = styled.span`
  color: ${theme.BASE_COLOR};
  font-size: 10px;
  line-height: 8px;
`;

export const TripTimeValue = styled.span`
  color: ${theme.BASE_COLOR};
  font-weight: 600;
  font-size: 14px;
`;
export const EditBtn = styled(Button)`
  margin-top: 20px;
  width: 35%;
  margin-right: 10px;
`;

export const TripDetailsRow = styled(Row)`
  border-top: 1px solid ${theme.BASE_COLOR};
  display: block;
`;

export const SubmitBtn = styled(Button)`
  margin-top: 20px;
`;

export const FrequencyPickerRow = styled.div`
  width: 100%;
  padding-top: 12px;
  border-top: 1px solid ${theme.BASE_COLOR};
`;

export const MinimizableCol = styled(Col)`
  max-height: ${props => (props.minimized ? '0px' : '100%')};
  transition: max-height 200ms linear 0s;
`;

export const LoaderWrapperRow = styled(Row)`
  height: 100%;
`;

export const LoaderIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
`;

export const TextSmall = styled.small`
  color: ${theme.BASE_COLOR};
  font-weight: bold;
  font-size: 10px;
  padding-right: 10px;
`;

export const UntillText = styled(TextSmall)``;
