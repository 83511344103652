/** @format */

import styled from 'styled-components';
import { Modal } from '@components/common/modal';

export const Popup = styled(Modal)``;

export const PopupText = styled.span`
  margin-bottom: 20px;
  text-align: center;
`;

export const PopupHeading = styled.h6`
  font-weight: bold;
  text-align: center;
`;
