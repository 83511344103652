/** @format */

import styled from 'styled-components';
import { device } from '@constants';
import { Button } from '..';

export const CloseButton = styled(Button)`
  width: 16px;
  height: 16px;
  z-index: 2;

  @media ${device.mediaQuery.SMALL_SCREEN} {
    margin: 15px 0;
    position: absolute;
  }

  @media ${device.mediaQuery.LARGE_SCREEN} {
    position: relative;
    top: -10px;
  }
`;

export const Wrapper = styled.div`
  padding: 10px;
  height: 100%;
`;
