/** @format */

import styled from 'styled-components';
import { theme } from '@constants';
import { Col } from '@components';

export const CallUsCol = styled(Col)`
  padding-bottom: 40px;
`;

export const Title = styled.span`
  font-size: 26px;
  line-height: 26px;
  font-weight: bolder;
  text-align: center;
  color: ${theme.COD_GRAY_LIGHT};
  margin-bottom: 4px;
  font-family: Bryant;
`;

export const SubTitle = styled.span`
  font-size: 22px;
  line-height: 34px;
  color: ${theme.COD_GRAY_LIGHT};
  text-align: center;
  margin-bottom: 28px;
  font-family: Bryant;
`;

export const PhoneIcon = styled.img`
  height: 58.92px;
  width: 58.92px;
  margin-bottom: 24px;
`;

export const CallBtn = styled.a`
  display: block;
  height: 55px;
  width: 100%;
  min-width: 280px;
  max-width: 340px;
  font-size: 26px;
  font-weight: bold;
  line-height: 26px;
  background-color: ${theme.COD_GRAY};
  color: ${theme.WHITE};
  text-decoration: none;
  cursor: pointer;
  border-radius: 8px;
  font-family: Bryant;

  &:hover,
  &:active,
  &:visited {
    color: ${theme.WHITE};
    text-decoration: none;
  }
`;

export const BoldText = styled.span`
  font-weight: bold;
`;
