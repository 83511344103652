/** @format */

import styled from 'styled-components';
import { Col, Button, SuggestionInput, Dropdown } from '@components';
import { theme } from '@constants';
import { styles } from '@utils';
import ReactTooltip from 'react-tooltip';

export const Tooltip = styled(ReactTooltip)`
  &.show {
    opacity: 1 !important;
  }
`;

export const DropdownStyled = styled(Dropdown)`
  z-index: 100;
  width: 100%;
  border: 2px solid ${theme.BASE_COLOR};
  border-radius: 100px;
  padding-left: 10px;

  .rdd-control {
    border-radius: inherit;
  }

  .rdd-menu {
    max-height: 100px;
    top: -100px;
    left: 0;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
`;

export const HeaderTitle = styled.h4`
  color: ${theme.BASE_COLOR};
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0;
`;

export const CloseIcon = styled(Button)`
  width: 16px;
  height: 16px;
  z-index: 2;
  margin-bottom: 15px;
`;

export const Title = styled.h4`
  color: ${theme.BASE_COLOR};
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0;
`;

export const InputWrapper = styled(Col)`
  margin-bottom: 5px;
`;

export const InputLabel = styled.span`
  font-weight: 600;
  font-size: 10px;
  margin: 10px 5px;
`;

export const AddressInput = styled(SuggestionInput)`
  width: 100%;
`;

export const FindRoutesBtn = styled(Button)`
  width: 50%;
  margin-top: 12px;
`;

export const FormCol = styled(Col)`
  overflow-y: hidden;
  max-height: ${props => (props.minimized ? '0px' : '100vh')};
  transition: max-height 200ms linear 0s;
`;

export const TextSmall = styled.small`
  color: ${theme.BASE_COLOR};
  font-weight: bold;
  font-size: 10px;
  padding-left: 5px;
  padding-bottom: 10px;
`;

export const RdtDatePickerWrapper = styled.div`
  position: absolute;
  z-index: 5;
  background: ${theme.WHITE};
  box-shadow: 0 1px 3px ${styles.hexToRgba(theme.BLACK, 0.1)};
  border: 1px solid ${theme.LIGHTER_GREY};
  min-width: 250px;
  top: -150px;
`;

export const RdtTimePickerWrapper = styled(RdtDatePickerWrapper)`
  min-width: 150px;
`;

export const DatePickerCol = styled(Col)`
  margin-right: 4px;
`;
