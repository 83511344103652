/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import {
  faCheckCircle,
  faCircle,
  faCircleNotch,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { tripHelpers } from '@utils';
import { generals as generalConstants } from '@constants';
import { PathItem } from '../pathItem';

const getPathItemText = (pathItem) => {
  if (['transit', 'walk'].includes(pathItem.action)) {
    const item = pathItem;
    return item.totalMinutes && Math.round(item.totalMinutes) > 0
      ? `${
          generalConstants.TRANIST_ACTION_TO_STEP_MAPPING[item.action]
        } ${Math.round(item.totalMinutes)} min`
      : '';
  }

  if (pathItem.action === 'board') {
    return `${
      pathItem.totalMinutes && Math.round(pathItem.totalMinutes) > 0
        ? `${'Wait'} ${Math.round(pathItem.totalMinutes)} min`
        : ''
    }`;
  }

  return '';
};

const TransitTripPath = ({
  leaveDate,
  pickupAddress,
  arriveDate,
  destinationAddress,
  tripDetails,
  leaveBy,
  arriveBy,
  timezone,
}) => {
  const { t } = useTranslation();
  if (!tripDetails.stepsDetails || !tripDetails.steps) {
    return null;
  }

  const transitPath = tripDetails.stepsDetails.slice(1, tripDetails.stepsDetails.length);
  const pickupDate = tripHelpers.convertTimeToGivenTimeZone(
    leaveDate,
    timezone,
    'MM/DD/YYYY',
    'MM/DD/YYYY HH:mm:ss',
  );

  const pickupTime = tripHelpers.convertTimeToGivenTimeZone(
    leaveDate,
    timezone,
    'hh:mm a',
    'MM/DD/YYYY HH:mm:ss',
  );

  const destinationDate = tripHelpers.convertTimeToGivenTimeZone(
    arriveDate,
    timezone,
    'MM/DD/YYYY',
    'MM/DD/YYYY HH:mm:ss',
  );

  const destinationTime = tripHelpers.convertTimeToGivenTimeZone(
    arriveDate,
    timezone,
    'hh:mm a',
    'MM/DD/YYYY HH:mm:ss',
  );

  const getStepTimeString = (stepsDetails) => {
    const step = generalConstants.TRANIST_ACTION_TO_STEP_MAPPING[stepsDetails.action];
    const time =
      stepsDetails && stepsDetails.totalMinutes
        ? Math.round(stepsDetails.totalMinutes)
        : null;
    const timeString = time && time > 0 ? `${time} min` : null;
    return step && timeString ? `${step} ${timeString}` : '';
  };

  return (
    <>
      <PathItem
        title={leaveBy && t('trip.labels.estd-time.pickup')}
        time={`${pickupDate}\n${pickupTime}`}
        address={pickupAddress}
        text={getStepTimeString(tripDetails.stepsDetails[0])}
        icon={leaveBy ? faCircleNotch : faCircle}
        iconRotation
      />

      {transitPath.map((pathItem, index) => {
        const prevPathItem = tripDetails.stepsDetails[index];
        const address =
          pathItem.action === 'board'
            ? pathItem.stopName
            : prevPathItem.stopName;
        const badge =
          pathItem.action === 'board'
            ? {
                value: pathItem.routeShortname,
                description: pathItem.tripHeadsign,
              }
            : null;

        return (
          <PathItem
            key={index}
            time={
              prevPathItem &&
              tripHelpers.convertTimeToGivenTimeZone(
                prevPathItem.formattedEnd,
                timezone,
                'hh:mm a',
                'MM/DD/YYYY HH:mm:ss',
              )
            }
            address={address}
            text={getPathItemText(pathItem)}
            icon={faCircle}
            iconRotation
            pathItemActionType={pathItem.action}
            badge={badge}
          />
        );
      })}

      {destinationAddress && (
        <PathItem
          title={arriveBy && t('trip.labels.estd-time.arrival-before')}
          time={`${destinationDate}\n${destinationTime}`}
          address={destinationAddress}
          icon={faCheckCircle}
        />
      )}
    </>
  );
};

TransitTripPath.propTypes = {
  leaveDate: PropTypes.string.isRequired,
  pickupAddress: PropTypes.string.isRequired,
  arriveDate: PropTypes.string,
  destinationAddress: PropTypes.string,
  tripDetails: PropTypes.object.isRequired,
  leaveBy: PropTypes.bool,
  arriveBy: PropTypes.bool,
  timezone: PropTypes.string.isRequired,
};

TransitTripPath.defaultProps = {
  arriveDate: null,
  destinationAddress: null,
  leaveBy: null,
  arriveBy: null,
};

export default TransitTripPath;
