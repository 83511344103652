/** @format */

import React from 'react';
import { faBus } from '@fortawesome/free-solid-svg-icons';

import { tripHelpers, placeHelpers } from '@utils';
import { hints, generals as generalConstants } from '@constants';
import { TripTypeText, TripTypeIcon, TripTypeTextSmall } from './styled';
import { getTranslatedStringFromKey } from '../../../i18Loader';

const isAAARoundTown = process.env.REACT_APP_ENV_IDENTIFIER === 'aaa_roundtown';

export const tabs = [
  {
    filterType: generalConstants.TRIP_FILTER_TYPE_SHARE,
    titleContent: <TripTypeText>{isAAARoundTown ? "AAA 'RT" : 'SHARE'}</TripTypeText>,
    id: 'share-button',
  },
  {
    filterType: generalConstants.TRIP_FILTER_TYPE_TRANSIT,
    titleContent: <TripTypeIcon icon={faBus} />,
    id: 'transit-button',
  },
  {
    filterType: generalConstants.TRIP_FILTER_TYPE_TRANSIT_SHARE,
    titleContent: (
      <TripTypeTextSmall>
        {isAAARoundTown ? "AAA 'RT" : 'SHARE'} +{' '}
        <TripTypeIcon icon={faBus} />
      </TripTypeTextSmall>
    ),
    id: 'share-transit-button',
  },
];

export const tabsIdentifiers = {
  'share-button': 'SHARE',
  'transit-button': 'TRANSIT',
  'share-transit-button': 'TRANSIT_SHARE',
};

export const checkOtherDateTrips = (date, tripsToCheck) => {
  let otherDateTripExists = false;
  tripsToCheck.forEach(trip => {
    if (
      tripHelpers.convertTripTimeToGivenTimeZone(
        trip,
        trip.leaveTime,
        'MM/DD/YYYY',
      ) !== date
    ) {
      otherDateTripExists = true;
    }
  });

  return otherDateTripExists;
};

const getTripIdAttribute = (type, tripType) => {
  if (tripType === 'share') {
    return `${type}-trip`;
  }

  if (tripType === 'transit') {
    return `transit-${type}-trip`;
  }

  if (tripType === 'transit_share') {
    return `transit-share-${type}-trip`;
  }
};

export const getTabContentIdAttribute = (type, filterType) => {
  if (filterType === generalConstants.TRIP_FILTER_TYPE_SHARE) {
    return `share-${type}-trips`;
  }

  if (filterType === generalConstants.TRIP_FILTER_TYPE_TRANSIT) {
    return `transit-${type}-trips`;
  }

  if (filterType === generalConstants.TRIP_FILTER_TYPE_TRANSIT_SHARE) {
    return `transit-share-${type}-trips`;
  }
};

const getTripHintProps = ({ type, index, tripRef }) => {
  const props = {
    'data-event': 'custom',
    'data-scroll-hide': 'true',
    'data-for': 'trip-options-tooltip',
    'data-tip': getTranslatedStringFromKey(hints.tripOptions.departingTrip),
    tripRef: index === 0 ? tripRef : null,
  };

  if (type === 'return') {
    props['data-tip'] = getTranslatedStringFromKey(hints.tripOptions.returnTrip);
    props.tripRef = index === 0 ? tripRef : null;
  }

  return props;
};

export const getTripProps = ({
  type,
  trip,
  index,
  tripRef,
  selectedTrip,
  getSelectTripFunction,
}) => {
  const props = {
    key: index,
    id: getTripIdAttribute(type, trip.type),
    optionName: isAAARoundTown ? "AAA 'RT" : trip.operator,
    tripTime: trip.tripTime,
    leaveBy: trip.leaveTime,
    arriveBy: trip.arriveTime,
    timezone: placeHelpers.getTimezoneFromPlace(trip.places[0]),
  };

  if (trip.type === 'share') {
    const hintProps = getTripHintProps({ type, index, tripRef });

    return {
      ...props,
      ...hintProps,
      onClickSelect: () => {
        getSelectTripFunction(type)(index);
      },
      selectedTrip: index === selectedTrip.share[type],
    };
  }

  if (trip.type === 'transit') {
    return {
      ...props,
      onTripSelect: () => getSelectTripFunction('transit')(trip, type),
    };
  }

  if (trip.type === 'transit_share') {
    return {
      ...props,
      onClickSelect: () => getSelectTripFunction('transit_share')(index, type),
      onTripSelect: () => getSelectTripFunction('transit_share')(index, type, true),
      selectedTrip: index === selectedTrip.transitShare[type],
    };
  }

  return null;
};
