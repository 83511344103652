/** @format */

import styled from 'styled-components';

import { theme } from '@constants';
import { Input } from '..';

export const SInput = styled(Input)`
  input {
    box-shadow: 0 0 4px 0 rgb(0 0 0 / 16%);
  }
`;

export const SuggestionInputWrapper = styled.div`
  position: relative;
`;

export const SuggestionsOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 13;
`;

export const Suggestions = styled.div`
  background-color: ${theme.WHITE};
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
  position: absolute;
  margin-bottom: 10px;
  margin-top: 2px;
  overflow-y: scroll;
  max-height: 200px;
  width: 100%;
  z-index: 14;
  top: 30px;
`;

export const ClickHandler = styled.button`
  width: 100%;
  padding: 8px 10px;
  border: none;
  background-color: ${theme.TRANSPARENT};
  text-align: left;

  :focus {
    outline: none;
  }
`;

export const SuggestionText = styled.span`
  color: ${theme.BASE_COLOR};
`;

export const SuggestionTextBold = styled.span`
  color: ${theme.BASE_COLOR};
  font-weight: 600;
`;

export const InputWrapper = styled.div`
  position: relative;
  z-index: ${props => (props.suggestionsVisible ? 15 : 13)};
`;
