/** @format */

import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { Col, Button, SuggestionInput, IconButton } from '@components';
import { theme } from '@constants';

export const HeaderTitle = styled.h4`
  color: ${theme.BASE_COLOR};
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0;
`;

export const CloseIcon = styled(IconButton)`
  z-index: 2;
  margin-bottom: 15px;
`;

export const Title = styled.h4`
  color: ${theme.BASE_COLOR};
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0;
`;

export const InputWrapper = styled(Col)`
  margin-bottom: 5px;
`;

export const DepartWrapper = styled(Col)`
  width: ${props => (props.isReturn ? '100%' : '48%')};
`;

export const InputLabel = styled.span`
  font-weight: 600;
  font-size: 10px;
  margin: 10px 5px;
`;

export const AddressInput = styled(SuggestionInput)`
  width: 100%;
`;

export const FindRidesButton = styled(Button)`
  width: 50%;
  margin-top: 12px;
`;

export const Tooltip = styled(ReactTooltip)`
  &.show {
    opacity: 1 !important;
  }
`;
