/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { faSpinner, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import { theme, generals as generalConstants } from '@constants';
import { Wrapper, PromoCodeCol, PromoCodeInput, ApplyButton } from './styled';

const PromoCode = ({ code, isValid, onChange, apply, loading }) => {
  const { t } = useTranslation();
  let buttonIcon = null;
  const applyPromoCodeLoading =
    loading === generalConstants.APPLY_PROMO_CODE_TRIP_REQUEST_LOADING;

  if (applyPromoCodeLoading) {
    buttonIcon = faSpinner;
  } else if (isValid || !apply) {
    buttonIcon = faCheckCircle;
  }

  return (
    <Wrapper>
      <PromoCodeCol
        justify="space-between"
        align="flex-end"
        spacingH={10}
        spacingV={10}
      >
        <PromoCodeInput
          data-cy="input-promo-code"
          floating
          type="text"
          name="promoCode"
          onChange={onChange}
          value={code}
          label={t('trip-detail.labels.promo-code')}
          color={theme.BASE_COLOR}
          readOnly={!onChange}
        />

        <ApplyButton
          data-cy="promo-apply-btn"
          id="apply-btn"
          onClick={apply}
          label={
            apply
              ? t('trip-overview.promo-code.buttons.apply')
              : t('trip-overview.promo-code.buttons.availed')
          }
          color={theme.BASE_COLOR}
          icon={buttonIcon}
          iconProps={{ spin: applyPromoCodeLoading }}
          disabled={applyPromoCodeLoading || !apply || !code}
        />
      </PromoCodeCol>
    </Wrapper>
  );
};

PromoCode.propTypes = {
  onChange: PropTypes.func,
  code: PropTypes.string.isRequired,
  isValid: PropTypes.bool,
  apply: PropTypes.func,
  loading: PropTypes.string,
};

PromoCode.defaultProps = {
  onChange: null,
  isValid: false,
  apply: null,
  loading: null,
};

export default PromoCode;
