/** @format */

import styled from 'styled-components';
import { theme } from '@constants';

export const NextButton = styled.span`
  color: #fff;
  font-size: 12px;
  border-radius: 10px;
  position: fixed;
  top: 10px;
  left: 10px;
  padding: 2px 4px;
  z-index: 100;
  background-color: ${theme.BASE_COLOR};
`;