/** @format */
import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect, Route, useLocation } from 'react-router-dom';

import {
  MapWorkforce,
  Login,
  Signup,
  HomeWorkforce,
  TripRequest,
  TripOverviewWorkforce,
  PaymentContainer,
  AccountSettings,
  TripDetail,
  Header,
  SignupOrg,
  ForgotPassword,
  SavedAddresses,
  OrganizationSchedule,
  RateRide,
  FixedRoutePublicMap,
  ExtraFieldsAndSpecialNeeds,
  TripOptionsWorkforce,
  MyTripsWorkforce
} from '@containers';
import { Screen, ProtectedRoute } from '@components';

const SmallWorkforce = ({ isAuthenticated }) => {
  const location = useLocation();

  const renderAccountSettings = ({ match: { url } }) => (
    <>
      <ProtectedRoute
        isAllowed={isAuthenticated}
        path={`${url}/`}
        component={AccountSettings}
        exact
        restricted
      />
      <ProtectedRoute
        isAllowed={isAuthenticated}
        path={`${url}/addresses`}
        component={SavedAddresses}
        restricted
      />
    </>
  );

  if (location.pathname.includes('fixed-route/')) {
    return (
      <Screen>
        <Switch>
          <Route
            path="/fixed-route/:routeKey"
            component={FixedRoutePublicMap}
          />
        </Switch>
      </Screen>
    );
  }

  return (
    <Screen isLarge={false}>
      <Header isAuthenticated={isAuthenticated} />
      <MapWorkforce />
      <Switch>
        <Route path="/" exact component={HomeWorkforce} />
        <ProtectedRoute
          isAllowed={!isAuthenticated}
          path="/login"
          redirectTo="/"
          component={Login}
        />
        <ProtectedRoute
          isAllowed={!isAuthenticated}
          path="/forgot-password"
          redirectTo="/"
          component={ForgotPassword}
        />
        <ProtectedRoute
          isAllowed={!isAuthenticated}
          path="/signup"
          redirectTo="/"
          component={Signup}
        />
        <ProtectedRoute
          isAllowed={isAuthenticated}
          path="/user-details"
          redirectTo="/"
          component={ExtraFieldsAndSpecialNeeds}
        />
        <Route path="/trip-request" component={TripRequest} />
        <ProtectedRoute
          isAllowed={isAuthenticated}
          path="/trip-overview"
          component={TripOverviewWorkforce}
        />
        <ProtectedRoute
          isAllowed={isAuthenticated}
          path="/trip-options"
          component={TripOptionsWorkforce}
        />
        <ProtectedRoute
          isAllowed={isAuthenticated}
          path="/my-trips"
          component={MyTripsWorkforce}
        />
        <ProtectedRoute
          isAllowed={isAuthenticated}
          path="/trip-detail"
          component={TripDetail}
        />
        <ProtectedRoute
          isAllowed={isAuthenticated}
          path="/payment"
          component={PaymentContainer}
        />
        <ProtectedRoute
          isAllowed={isAuthenticated}
          path="/schedule"
          component={OrganizationSchedule}
        />
        <ProtectedRoute
          isAllowed={isAuthenticated}
          path="/account-settings"
          render={renderAccountSettings}
          restricted
        />
        <ProtectedRoute
          isAllowed={isAuthenticated}
          path="/transit-trip-detail"
          component={TripDetail}
          redirectTo="/"
        />
        <ProtectedRoute
          isAllowed={isAuthenticated}
          path="/transit-share-trip-detail"
          component={TripDetail}
          redirectTo="/"
        />
        <ProtectedRoute
          isAllowed={!isAuthenticated}
          path="/org/:orgId"
          component={SignupOrg}
          redirectTo="/"
        />
        <ProtectedRoute
          isAllowed={isAuthenticated}
          path="/rate-ride"
          component={RateRide}
          redirectTo="/"
        />
        <Redirect to="/" />
      </Switch>
    </Screen>
  );
};

SmallWorkforce.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

export default SmallWorkforce;
