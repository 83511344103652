/** @format */

import styled from 'styled-components';

import { theme } from '@constants';
import { Row, Dropdown } from '..';

export const DOBRow = styled(Row)`
  margin-bottom: 8px;
  & > :nth-child(even) {
    margin: 0px 6px;
  }
`;

export const Label = styled.div`
  color: ${theme.COD_GRAY_LIGHT};
  padding: 0;
  font-size: ${props => props.fontSize}px;
  font-weight: bold;
`;

export const StyledDropdown = styled(Dropdown)``;

export const InputError = styled.span`
  color: ${theme.TOMATO};
  font-size: 16px;
  letter-spacing: 1px;
`;

export const DropdownWrapper = styled.div`
  width: 100%;
`;


export const DropdownMonthWrapper = styled.div`
  width: 120%;
`;
