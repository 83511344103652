/** @format */

import React from 'react';
import { Row, Col } from '@components';
import { generals as generalConstants } from '@constants';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Wrapper,
  Bar,
  BarFilled,
  Category,
  BarAmount,
  DynamicText,
  ColorFiller,
  LifeTimeStats,
} from './styled';

const renderTier = (
  tier,
  // t
) => {
  const {
    name,
    roundTripsBonus,
    ridesCovered,
    completedPercentage,
    // tierBonus,
    status,
    // stats,
    color,
    barColor,
  } = tier || {};
  const currentTier = status === 'Active';

  return (
    <Col justify="center" align="flex-start">
      {/* {!!stats && currentTier && renderLifeTimeStats(tierBonus, stats, t)} */}
      <Row justify="space-between" align="center">
        <Bar
          justify="flex-start"
          align="center"
          margin={
            generalConstants.RIDERSHIP_TIERS_CONFIG[tier.status]?.barMargin
          }
        >
          <Col justify="center" align="flex-start">
            {currentTier && (
              <BarFilled
                width={completedPercentage}
                color={barColor || color}
              />
            )}
            <Category>SHARE {name?.length ? `${name[0]}${name.slice(1).toLowerCase()}` : name}</Category>
            {currentTier && <BarAmount>${roundTripsBonus || 0}</BarAmount>}
          </Col>
        </Bar>
        {ridesCovered && (
          <DynamicText bold fontSize={18}>
            {ridesCovered}
          </DynamicText>
        )}
        {status === 'InActive' && (
          <DynamicText fontSize={14}>${roundTripsBonus || 0}</DynamicText>
        )}
      </Row>
    </Col>
  );
};

// eslint-disable-next-line
const renderLifeTimeStats = (tierBonus, stats, t) => {
  const { rides, totalMiles, CO2Saved } = stats || {};
  return (
    <LifeTimeStats
      id="lifetime-stats"
      data-cy="lifetime-stats"
      justify="flex-start"
      align="flex-start"
    >
      <Row justify="space-between" align="center">
        <DynamicText bold fontSize={16} margin="0 15px 0 0">
          {t('ridership-bonus.this-month')}
        </DynamicText>
        <DynamicText bold fontSize={12} opacity={0.8}>
          {t('ridership-bonus.lifetime')}
        </DynamicText>
        <DynamicText fontSize={11} opacity={0.8}>
          {t('ridership-bonus.rides')}
        </DynamicText>
        <DynamicText fontSize={11} opacity={0.8}>
          {t('ridership-bonus.total-miles')}
        </DynamicText>
        <DynamicText fontSize={11} opacity={0.8}>
          {t('ridership-bonus.co2-saved')}
        </DynamicText>
      </Row>
      <Row justify="space-between" align="flex-start">
        <DynamicText fontSize={12} opacity={0.8}>
          {`${t('ridership-bonus.extra-bonus')} $${tierBonus || 0}`}
        </DynamicText>
        <DynamicText bold fontSize={12} opacity={0.8}>
          {t('ridership-bonus.stats')}
        </DynamicText>
        <DynamicText fontSize={14} bold opacity={0.8}>
          {rides || 0}
        </DynamicText>
        <DynamicText fontSize={14} bold opacity={0.8}>
          {totalMiles ? `${totalMiles} mi` : 'N/A'}
        </DynamicText>
        <DynamicText fontSize={14} bold opacity={0.8}>
          {CO2Saved ? `${CO2Saved} lbs` : 'N/A'}
        </DynamicText>
      </Row>
    </LifeTimeStats>
  );
};

const RidershipTiers = ({ tiers, largeScreen }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      {tiers.map((tier, key) => (
        <ColorFiller
          data-cy={`color-filler-${key}`}
          id={`color-filler-${key}`}
          color={tier.color}
          largeScreen={largeScreen}
          opacity={
            generalConstants.RIDERSHIP_TIERS_CONFIG[tier.status]?.opacity
          }
        >
          <Wrapper
            data-cy={`tier-${key}`}
            id={`tier-${key}`}
            width={generalConstants.RIDERSHIP_TIERS_CONFIG[tier.status]?.width}
          >
            {renderTier(tier, t)}
          </Wrapper>
        </ColorFiller>
      ))}
    </Wrapper>
  );
};

RidershipTiers.propTypes = {
  tiers: PropTypes.array.isRequired,
  largeScreen: PropTypes.bool.isRequired,
};

export default RidershipTiers;
