/** @format */

import styled from 'styled-components';
import { MapContainer } from 'react-leaflet';

export const Map = styled(MapContainer)`
  width: 100%;
  height: 100%;
  z-index: 10;
`;
