/** @format */

import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { analytics } from '@constants';
import { trackAbEvent } from '@slices/app';
import { MyTrips, FullScreen } from '@components';
import { getMyTrips, setActiveTripsType, resetMyTrips } from '@slices/myTrips';
import { setTripDetail } from '@slices/tripDetail';

const MyTripsContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const largeScreen = useSelector(store => store.app.largeScreen);
  const { activeTripsType, trips, loading } = useSelector(
    store => store.myTrips,
  );

  useEffect(() => {
    dispatch(getMyTrips());
    return () => {
      dispatch(resetMyTrips());
    };
  }, [dispatch, activeTripsType]);

  const onChangeActiveTripsType = type => {
    if (loading) {
      return;
    }

    dispatch(setActiveTripsType(type));
  };

  const onPageChange = pagination => {
    dispatch(getMyTrips(pagination.selected));
  };

  const onSelect = selectedTrip => {
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: 'Select A Trip',
        subJourneyName: 'See Trip Details',
        journeyName: 'Rider Experience',
        details: {
          'trip-id': selectedTrip.id,
          'request-type': selectedTrip.requestType,
          'trip-type': selectedTrip.type,
          ...selectedTrip.pricing,
          'trip-time': selectedTrip.tripTime,
          'trip-status': selectedTrip.status,
          'trip-arrive-time': selectedTrip.arriveTime,
          'trip-estimated-miles': selectedTrip.estimatedMiles,
        },
      }),
    );
    dispatch(setTripDetail(selectedTrip));

    history.push('/trip-detail', { prevRoute: 'my-trips' });
  };

  const onClose = () => {
    history.push('/');
  };

  const addToCalenderBtnCliked = () => {
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.ADD_TO_CALENDER_CLICKED,
        subJourneyName: 'See Trip Details',
        journeyName: 'Rider Experience',
      }),
    );
  };

  const calendarItemClicked = data => {
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.ADDED_TO_CALENDER,
        subJourneyName: 'See Trip Details',
        journeyName: 'Rider Experience',
        details: {
          ...data,
        },
      }),
    );
  };

  const myTrips = (
    <MyTrips
      activeTripsType={activeTripsType}
      setActiveTripsType={onChangeActiveTripsType}
      trips={trips}
      onPageChange={onPageChange}
      onSelect={onSelect}
      loading={loading}
      onClose={onClose}
      addToCalenderBtnCliked={addToCalenderBtnCliked}
      calendarItemClicked={calendarItemClicked}
    />
  );

  return largeScreen ? (
    myTrips
  ) : (
    <FullScreen id="my-trips">{myTrips}</FullScreen>
  );
};

export default MyTripsContainer;
