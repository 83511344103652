/** @format */

import { createSlice } from '@reduxjs/toolkit';
import CryptoJS, { AES } from 'crypto-js';
import moment from 'moment';
import {
  setUser,
  setAppLanguage,
  setUserAgent,
  setOrganizationsFromUser,
  setSelectedOrganization,
  fetchOrgPlaces,
  fetchOrgDetails,
  trackAbEvent,
  trackEvent,
  setFullScreenLoading,
  setPasswordlessAuthenticationType,
  setOrganizations,
  setRestrictedMode,
  setDynamicTextFields,
  getNearbyBusStops,
} from '@slices/app';
import {
  setSearchQuery,
  resetTripRequestState,
  setTrips,
  setSelectedMarker,
  setSearchQueryPlaceIds,
  setFirstTimeFitBounds,
} from '@slices/tripRequestWorkforce';
import { getFixedRoutesByOrgId } from '@slices/fixedRoute';
import { api, generals, analytics, localStorageKeys } from '@constants';
import {
  changeLanguage,
  getTranslatedStringFromKey,
  getApiErrorTranslation,
} from '@i18Loader';
import {
  helpers,
  userHelpers,
  browserHelpers,
  api as apiClient,
  toast,
  mixpanel as mixpanelUtils,
} from '@utils';
import { leaflet as leafletService } from '@services';

const isAAARoundTown = process.env.REACT_APP_ENV_IDENTIFIER === 'aaa_roundtown';
const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
const userDefaultLanguage = process.env.REACT_APP_LANGUAGE;
const additionalTerms = process.env.REACT_APP_ADDITIONAL_TERMS;
const additionalTermsUrl = process.env.REACT_APP_ADDITIONAL_TERMS_URL;
const defaultDOB = () => {
  const max = isAAARoundTown
    ? new Date(`${new Date().getFullYear() - 64}-01-01`)
    : new Date(`${new Date().getFullYear() - 12}-01-01`);
  return max;
};
const bytes = localStorage.getItem('rememberMe')
  ? AES.decrypt(localStorage.getItem('rememberMe'), encryptionKey)
  : null;
const loginState = bytes ? JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) : null;
export const defaultState = {
  signup: {
    formState: {
      firstName: '',
      lastName: '',
      dob: process.env.REACT_APP_DISABLE_DOB_FIELD !== 'true' ? {
        date: moment(defaultDOB()).format('DD'),
        month: helpers.getMonthNameFromNumber(moment(defaultDOB()).format('M')),
        year: moment(defaultDOB()).format('YYYY'),
      } : {},
      phone: '',
      email: '',
      password: '',
      savedAddresses: [],
      showSpecialNeed: 'No',
      specialNeeds: [],
      notes: '',
      showPassword: false,
      organizationId: null,
      byReferralCode: '',
      detail: {},
      language: userDefaultLanguage,
      riderType: 'ADULT',
    },
    loading: false,
    addresses: [],
    OrganizationUsers: [
      {
        role: 'User',
      },
    ],
  },
  login: loginState || {
    formState: {
      email: '',
      password: '',
      showPassword: false,
    },
    loading: false,
    rememberMe: false,
  },
  accountSettings: {
    formState: {
      firstName: '',
      lastName: '',
      dob: {
        date: null,
        month: null,
        year: null,
      },
      phone: '',
      email: '',
      password: '',
      savedAddresses: [],
      specialNeeds: [],
      showSpecialNeed: 'No',
      notes: '',
      showPassword: false,
      selectedOrg: null,
      detail: {},
      language: null,
      riderType: null,
    },
    loading: false,
    addresses: [],
    invites: [],
    inviteLoading: false,
    selectedInvite: null,
    inviteLoaderType: null,
  },
  forgotPassword: {
    email: '',
    loading: false,
  },
  resetPassword: {
    password: '',
    token: '',
    loading: false,
  },
  sendReferralEmailsOrPhones: {
    emailsOrPhoneNumbers: [],
    inputEmailOrPhone: '',
    loading: false,
  },
};

const AuthSlice = createSlice({
  name: 'auth',
  initialState: defaultState,
  reducers: {
    setSignupLoading(state, action) {
      const { payload: loading } = action;

      return {
        ...state,
        signup: {
          ...state.signup,
          loading,
        },
      };
    },
    setSignupDOB(state, action) {
      const { key, value } = action.payload;

      return {
        ...state,
        signup: {
          ...state.signup,
          formState: {
            ...state.signup.formState,
            dob: {
              ...state.signup.formState.dob,
              [key]: value,
            },
          },
        },
      };
    },
    setSignupOrganizationId(state, action) {
      const { payload: organizationId } = action;
      return {
        ...state,
        signup: {
          ...state.signup,
          formState: { ...state.signup.formState, organizationId },
          OrganizationUsers: [
            {
              organizationId,
              role: 'User',
            },
          ],
        },
      };
    },
    setSignupReferralCode(state, action) {
      const { payload: byReferralCode } = action;
      return {
        ...state,
        signup: {
          ...state.signup,
          formState: { ...state.signup.formState, byReferralCode },
        },
      };
    },
    setSignupAddresses(state, action) {
      const { payload: addresses } = action;

      return {
        ...state,
        signup: {
          ...state.signup,
          addresses,
        },
      };
    },
    setSignupFormState(state, action) {
      const { payload } = action;

      return {
        ...state,
        signup: {
          ...state.signup,
          formState: {
            ...state.signup.formState,
            [payload.name]: payload.value,
          },
        },
      };
    },
    setLoginLoading(state, action) {
      const { payload: loading } = action;

      return {
        ...state,
        login: {
          ...state.login,
          loading,
        },
      };
    },
    setLoginRememberMe(state, action) {
      const { payload: rememberMe } = action;

      return {
        ...state,
        login: {
          ...state.login,
          rememberMe,
        },
      };
    },
    setLoginFormState(state, action) {
      const { payload } = action;

      return {
        ...state,
        login: {
          ...state.login,
          formState: {
            ...state.login.formState,
            [payload.name]: payload.value,
          },
        },
      };
    },
    resetAuthState(state) {
      return {
        ...defaultState,
        signup: {
          ...defaultState.signup,
          formState: {
            ...defaultState.signup.formState,
            language: state.signup.formState.language,
          },
        },
      };
    },
    setAccountSettings(state, action) {
      const { payload: { user, dynamicTextFields } } = action;

      const name = user.name.replace(/\s+/g, ' ');

      const [firstName, lastName] = name.trim().split(' ');
      const phone = user.smsNumber;

      let birthday = helpers.getObjectProperty(user, 'detail.birthday', {
        date: null,
        month: null,
        year: null,
      });

      if (typeof birthday === 'string') {
        const [year, month, date] = birthday.split('-');
        birthday = {
          date,
          month: generals.MONTHS_NAMES[parseInt(month, 10) - 1],
          year,
        };
      }

      const specialNeeds =
        helpers.getObjectProperty(user, 'detail.specialneeds', []) || [];

      const selectedOrg = helpers.getObjectProperty(
        user,
        'detail.selectedOrg',
        null,
      );

      const language = user?.language || userDefaultLanguage;
      const { riderType } = user;

      const detail = {};
      Array.isArray(dynamicTextFields) &&
        dynamicTextFields.forEach(tf => {
          const textField = helpers.camelize(tf.label);
          if (user.detail && user.detail[textField]) {
            detail[textField] = user.detail[textField];
          }
        });

      const formState = {
        firstName,
        lastName: lastName || '',
        dob: {
          date: birthday.date,
          month: birthday.month,
          year: birthday.year,
        },
        phone,
        email: user.email,
        password: '',
        savedAddresses: helpers.getObjectProperty(user, 'detail.addresses', []),
        specialNeeds,
        showSpecialNeed: specialNeeds.length > 0 ? 'Yes' : 'No',
        notes: helpers.getObjectProperty(user, 'detail.notes', ''),
        selectedOrg,
        detail,
        language,
        riderType,
      };

      return {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          formState,
        },
      };
    },
    setAccountSettingsLoading(state, action) {
      const { payload: loading } = action;

      return {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          loading,
        },
      };
    },
    setAccountSettingsDOB(state, action) {
      const { key, value } = action.payload;

      return {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          formState: {
            ...state.accountSettings.formState,
            dob: {
              ...state.accountSettings.formState.dob,
              [key]: value,
            },
          },
        },
      };
    },
    setAccountSettingsAddresses(state, action) {
      const { payload: addresses } = action;

      return {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          addresses,
        },
      };
    },
    setAccountSettingsFormState(state, action) {
      const { payload } = action;

      return {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          formState: {
            ...state.accountSettings.formState,
            [payload.name]: payload.value,
          },
        },
      };
    },
    setAccountSettingsInviteLoading(state, action) {
      const { payload: inviteLoading } = action;

      return {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          inviteLoading,
        },
      };
    },
    setAccountSettingsInvites(state, action) {
      const { payload: invites } = action;

      return {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          invites,
        },
      };
    },
    setAccountSettingsSelectedInvite(state, action) {
      const { payload: selectedInvite } = action;

      return {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          selectedInvite,
        },
      };
    },
    setAccountSettingsInviteLoaderType(state, action) {
      const { payload: inviteLoaderType } = action;

      return {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          inviteLoaderType,
        },
      };
    },
    resetAccountSettings(state) {
      return {
        ...state,
        accountSettings: defaultState.accountSettings,
      };
    },
    setAccountSettingsLanguage(state, action) {
      const { payload: language } = action;

      return {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          language,
        },
      };
    },
    setForgotPasswordLoading(state, action) {
      const { payload: loading } = action;
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          loading,
        },
      };
    },
    setForgotPasswordEmail(state, action) {
      const { payload: email } = action;

      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          email,
        },
      };
    },
    setResetPasswordLoading(state, action) {
      const { payload: loading } = action;
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          loading,
        },
      };
    },
    setResetPasswordNewPassword(state, action) {
      const { payload: password } = action;

      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          password,
        },
      };
    },
    setSendReferralInputEmailOrPhone(state, action) {
      const { payload: inputEmailOrPhone } = action;
      return {
        ...state,
        sendReferralEmailsOrPhones: {
          ...state.sendReferralEmailsOrPhones,
          inputEmailOrPhone,
        },
      };
    },
    setSendReferralEmailsOrPhones(state, action) {
      const { payload: emailsOrPhoneNumbers } = action;

      return {
        ...state,
        sendReferralEmailsOrPhones: {
          ...state.sendReferralEmailsOrPhones,
          emailsOrPhoneNumbers,
        },
      };
    },
    setSendReferralLoading(state, action) {
      const { payload: loading } = action;

      return {
        ...state,
        sendReferralEmailsOrPhones: {
          ...state.sendReferralEmailsOrPhones,
          loading,
        },
      };
    },
  },
});

const {
  setSignupLoading,
  setSignupFormState,
  setSignupAddresses,
  setSignupOrganizationId,
  setSignupReferralCode,
  setLoginLoading,
  setLoginFormState,
  resetAuthState,
  setAccountSettings,
  setAccountSettingsLoading,
  setAccountSettingsDOB,
  setSignupDOB,
  setAccountSettingsFormState,
  setAccountSettingsAddresses,
  setAccountSettingsInviteLoading,
  setAccountSettingsInvites,
  setAccountSettingsSelectedInvite,
  setAccountSettingsInviteLoaderType,
  resetAccountSettings,
  setForgotPasswordEmail,
  setForgotPasswordLoading,
  setResetPasswordLoading,
  setResetPasswordNewPassword,
  setLoginRememberMe,
  setAccountSettingsLanguage,
  setSendReferralEmailsOrPhones,
  setSendReferralInputEmailOrPhone,
  setSendReferralLoading,
} = AuthSlice.actions;

export const setAccountSettingsAndDynamicFields = user => {
    return async (dispatch, getState) => {
      let dynamicTextFields = null;
      try {
        const { selectedOrganization } = getState().app;
        dynamicTextFields =
          (typeof selectedOrganization === 'number' &&
            user?.OrganizationUsers[selectedOrganization]?.organization?.detail
              ?.userDynamicFields) ||
          null;
        if (dynamicTextFields) {
          dispatch(setDynamicTextFields(dynamicTextFields));
        } else {
          dispatch(setDynamicTextFields([]));
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('SET_ACCOUNT_SETTINGS_AND_DYNAMIC_FIELDS_ERROR', error);
      }
      dispatch(setAccountSettings({ user, dynamicTextFields }));
    };
  };

export const fetchAddresses = (addressQuery, action = setSignupAddresses) => {
  return async (dispatch, getState) => {
    const { organizations, selectedOrganization } = getState().app;
    let results = [];
    try {
      results = await leafletService.search(addressQuery);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(`LEAFLET_SEARCH_ERROR ${e}`);
    }
    const organization = Array.isArray(organizations) && organizations[selectedOrganization];
    const {
      state,
      state_restricted: stateRestricted,
    } = organization.detail || {};

    if (state && stateRestricted && Array.isArray(results) && results.length) {
      results = results.filter(
        o =>
          o.label.match(` ${generals.STATE_NAMES_LONG_TO_SHORT[state]} `),
      );
    }

    dispatch(action(results));
  };
};

export const setUserLanguage = language => {
  return async dispatch => {
    localStorage.setItem(localStorageKeys.USER_LANGUAGE, language);
    dispatch(
      setSignupFormState({
        name: 'language',
        value: language,
      }),
    );
    dispatch(setAppLanguage(language));
    setTimeout(() => {
      if (
        localStorage.getItem(localStorageKeys.AUTH_TOKEN) ||
        localStorage.getItem(localStorageKeys.USER_AGENT_AUTH_TOKEN)
      ) {
        dispatch(
          setAccountSettingsFormState({ name: 'language', value: language }),
        );
        dispatch(update());
      }
    }, 2000);
  };
};

export const signup = history => {
  return async (dispatch, getState) => {
    const termsAndConditions =
      additionalTerms && additionalTermsUrl
        ? { basic: true, additional: true }
        : { basic: true, additional: false };
    dispatch(setSignupLoading(true));

    const {
      firstName,
      lastName,
      dob,
      phone,
      email,
      password,
      savedAddresses,
      specialNeeds,
      detail,
      byReferralCode,
      language,
      riderType,
    } = getState().auth.signup.formState;

    const { OrganizationUsers } = getState().auth.signup;
    const [organization] = OrganizationUsers;
    const { organizationId } = organization;

    const phoneNumber = phone.split('-').join('');
    ['orgId', 'referCode'].forEach(key => {
      sessionStorage.removeItem(key);
    });

    const data = {
      name: `${firstName} ${lastName}`,
      email,
      unEncryptedEmail: email,
      password,
      phoneNumber,
      smsNumber: phoneNumber,
      detail: {
        birthday: dob,
        addresses: savedAddresses,
        specialneeds: specialNeeds,
        ...detail,
      },
      language,
      termsAndConditions,
      riderType,
    };

    if (byReferralCode && byReferralCode.length) {
      data.byReferralCode = byReferralCode;
    }

    try {
      if (organizationId) {
        data.OrganizationUsers = [...OrganizationUsers];
      }
      const uiData = browserHelpers.getElementPropForTracking('register-submit-btn', true);
      const response = await apiClient.post(api.endpoints.SIGNUP, data);
      const user = response.data;

      const selectedOrganization =
        user.OrganizationUsers?.length > 0
          ? userHelpers.getSelectedOrgIndex(user)
          : null;

      const selectedOrg = user?.OrganizationUsers[selectedOrganization]?.organization;
      const dynamicFieldsForSelectedOrg = selectedOrg?.detail?.userDynamicFields;
      if (Array.isArray(dynamicFieldsForSelectedOrg)) {
        dispatch(setDynamicTextFields(dynamicFieldsForSelectedOrg));
      }

      if (user.language) {
        changeLanguage(user.language);
        dispatch(setAppLanguage(user.language));
      }

      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.SIGNUP,
          subJourneyName: 'Signup/Signin',
          journeyName: 'Rider Experience',
          uiData,
          details: {
            user,
          },
        }),
      );
      dispatch(setSignupLoading(false));
      dispatch(setUser(user));
      dispatch(setOrganizationsFromUser(user));
      dispatch(setSelectedOrganization(selectedOrganization));

      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('authToken', response.headers['x-auth']);
      localStorage.setItem('firstLoggedIn', true);
      await dispatch(fetchOrgDetails());

      if (
        organizationId &&
        generals.ORGANIZATIONS_TO_WELCOME.indexOf(organizationId) !== -1
      ) {
        const orgs = JSON.parse(localStorage.getItem('orgWelcomeArray')) || [];
        orgs.push({ id: organizationId, welcomed: false });
        localStorage.setItem('orgWelcomeArray', JSON.stringify(orgs));
      }

      const specialNeedsEnabled = process.env.REACT_APP_SPECIAL_NEEDS_ENABLED === 'true';
      const dynamicTextFields = dynamicFieldsForSelectedOrg;
      const disableAddressesAfterSignup = process.env.REACT_APP_DISABLE_ADDRESSES_SIGNUP;

      const openInviteFriendsAfterFirstLoginOrSignup = JSON.parse(localStorage.getItem('openInviteFriendsAfterFirstLoginOrSignup'));

      const { bookingMethods, selfPaymentOptions } = selectedOrg || {};
      const isCreditPaymentEnabled =
        Array.isArray(bookingMethods) &&
        bookingMethods.includes(generals.BOOKING_METHODS.SELF_PAID) &&
        Array.isArray(selfPaymentOptions) &&
        selfPaymentOptions.includes(generals.SELF_PAYMENT_OPTIONS.CARD);

      if (specialNeedsEnabled || dynamicTextFields) {
        history.push('/user-details', {
          nextRoute: isCreditPaymentEnabled ? '/payment' : null,
        });
      } else if (isCreditPaymentEnabled) {
        history.push('/payment');
      } else if (!disableAddressesAfterSignup) {
        history.push({
          pathname: '/account-settings/addresses',
          state: { source: 'signup' },
        });
      } else if (openInviteFriendsAfterFirstLoginOrSignup && selectedOrganization !== null) {
        localStorage.removeItem('openInviteFriendsAfterFirstLoginOrSignup');
        history.push('/invite-friends');
        dispatch(fetchOrgPlaces());
        return;
      }

      dispatch(trackEvent(analytics.SIGNUP, user)); // For Activity Feed
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.SIGNUP,
          subJourneyName: 'Signup/Signin',
          journeyName: 'Rider Experience',
          uiData,
          details: {
            ...detail,
            smsNumber: data.smsNumber,
            language: data.language,
            riderType: data.riderType,
            byReferralCode: !!data.byReferralCode,
            organizationId: data.OrganizationUsers?.[0]?.organizationId,
            termsAndConditions: !!data.termsAndConditions,
            age: moment().diff(data.detail.birthday, 'years'),
            requiresSpecialNeeds: !!data.detail.specialneeds,
          },
        }),
      );
      dispatch(fetchOrgPlaces());
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('SIGNUP_API_ERROR', error);
      const message =
        error?.response?.data?.message ||
        getTranslatedStringFromKey(
          'messages.error.authentication.signup-failed',
        );
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.SIGNUP_FAILED,
          subJourneyName: 'Signup/Signin',
          journeyName: 'Rider Experience',
          details: {
            message,
            ...data,
          },
        }),
      );
      dispatch(setSignupLoading(false));
    }
  };
};

export const forgotPassword = history => {
  return async (dispatch, getState) => {
    dispatch(setForgotPasswordLoading(true));

    try {
      const { email } = getState().auth.forgotPassword;
      const data = { email };

      await apiClient.post(api.endpoints.FORGOT_PASSWORD, data);

      toast.show(
        'success',
        getTranslatedStringFromKey('messages.success.forgot-password'),
      );

      dispatch(setForgotPasswordLoading(false));
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.FORGOT_PASSWORD,
          subJourneyName: 'Signup/Signin',
          journeyName: 'Rider Experience',
          details: {
            email,
          },
        }),
      );

      history.push('/login');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('FORGOT_PASSWORD_API_ERROR', error);
      dispatch(setForgotPasswordLoading(false));
    }
  };
};

export const resetPassword = (token, history) => {
  return async (dispatch, getState) => {
    dispatch(setResetPasswordLoading(true));

    try {
      const { password } = getState().auth.resetPassword;
      const data = { token, password };

      await apiClient.post(api.endpoints.RESET_PASSWORD, data);

      toast.show(
        'success',
        getTranslatedStringFromKey('messages.success.reset-password'),
      );

      dispatch(setResetPasswordLoading(false));
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.RESET_PASSWORD,
          subJourneyName: 'Signup/Signin',
          journeyName: 'Rider Experience',
          details: {
            token,
          },
        }),
      );

      history.push('/login');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('RESET_PASSWORD_API_ERROR', error);
      dispatch(setResetPasswordLoading(false));
    }
  };
};

export const getUserOrgRestrictions = (token) => {
  // eslint-disable-next-line consistent-return
  return async (dispatch) => {
    dispatch(setResetPasswordLoading(true));

    try {
      const url = `${api.endpoints.GET_USER_ORG_RESTRICTIONS}?token=${token}`;
      const { data: restrictions } = await apiClient.get(url);

      dispatch(setResetPasswordLoading(false));
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.USER_ORG_RESTRICTIONS,
          subJourneyName: 'Signup/Signin',
          journeyName: 'Rider Experience',
          details: {
            token,
            restrictions,
          },
        }),
      );

      return restrictions;
    } catch (error) {
      // eslint-disable-next-line no-console
      dispatch(setResetPasswordLoading(false));
    }
  };
};

export const login = history => {
  return async (dispatch, getState) => {
    const uiData = browserHelpers.getElementPropForTracking('login-submit-btn', true);
    dispatch(setLoginLoading(true));
    const { login } = getState().auth;
    const { email, password } = login.formState;
    const data = {
      email,
      password,
    };
    login.rememberMe
      ? localStorage.setItem(
          'rememberMe',
          AES.encrypt(
            JSON.stringify({ ...getState().auth.login, loading: false }),
            encryptionKey,
          ).toString(),
        )
      : localStorage.removeItem('rememberMe');
    try {
      const response = await apiClient.post(api.endpoints.LOGIN, data);
      const user = response.data;
      if (user.language) {
        changeLanguage(user.language);
      }

      userHelpers.storeRefreshToken(user.refreshToken);

      mixpanelUtils.setMixpanelId(user.mixpanelId);
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.LOGIN,
          subJourneyName: 'Signup/Signin',
          journeyName: 'Rider Experience',
          details: {
            user,
          },
        }),
      );

      const isUserAgent = userHelpers.checkUserAgentRole(
        user.OrganizationUsers,
      );
      if (isUserAgent) {
        dispatch(resetTripRequestState());
        const getRidersResponse = await apiClient.get(
          api.endpoints.GET_RIDERS_LIST.replace(':id', user.id),
          {
            headers: {
              'x-auth': response.headers['x-auth'],
            },
          },
        );

        if (getRidersResponse.data.data.length === 0) {
          toast.show(
            'error',
            getTranslatedStringFromKey('messages.error.no-riders'),
          );
          dispatch(setLoginLoading(false));
        } else {
          localStorage.setItem('userAgent', JSON.stringify(user));
          localStorage.setItem(
            'userAgentAuthToken',
            response.headers['x-auth'],
          );

          dispatch(setUserAgent(user));
          dispatch(setLoginLoading(false));
          if (user.language) {
            changeLanguage(user.language);
          }
          handleTermsAndConditionsNavigation(user, history);
        }
      } else {
        dispatch(resetTripRequestState());
        const selectedOrganization =
          user.OrganizationUsers?.length > 0
            ? userHelpers.getSelectedOrgIndex(user)
            : null;
        const organizations = userHelpers.getUserOrganizations(user);

        const dynamicFieldsForSelectedOrg =
          user?.OrganizationUsers[selectedOrganization]?.organization?.detail
            ?.userDynamicFields;
        if (Array.isArray(dynamicFieldsForSelectedOrg)) {
          dispatch(setDynamicTextFields(dynamicFieldsForSelectedOrg));
        }

        dispatch(setUser(user));
        dispatch(setOrganizationsFromUser(user));
        dispatch(setSelectedOrganization(selectedOrganization));
        if (parseFloat(user.lat) && parseFloat(user.lng)) {
          dispatch(getNearbyBusStops([user.lat, user.lng]));
        }
        if (
          Array.isArray(organizations) &&
          selectedOrganization !== null &&
          selectedOrganization < organizations.length
        ) {
          dispatch(
            getFixedRoutesByOrgId(
              organizations[selectedOrganization].organizationId,
            ),
          );
          dispatch(
            setSearchQuery({
              key: 'organizationId',
              value: organizations[selectedOrganization].organizationId,
            }),
          );
        }

        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('authToken', response.headers['x-auth']);
        await dispatch(fetchOrgDetails());
        handleTermsAndConditionsNavigation(user, history);
        await dispatch(fetchOrgPlaces());
        dispatch(setFirstTimeFitBounds(false));
        // clear trips for other orgs.
        const trips = {
          share: {
            data: [],
            total: 0,
          },
        };
        dispatch(setTrips(trips));
        dispatch(
          trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
            eventName: analytics.LOGIN,
            subJourneyName: 'Signup/Signin',
              journeyName: 'Rider Experience',
            uiData,
            details: {
              selectedOrganizationId: (organizations || [])[selectedOrganization]?.organizationId,
            },
          }),
        );
        dispatch(setLoginLoading(false));
        const openInviteFriendsAfterFirstLoginOrSignup = JSON.parse(localStorage.getItem('openInviteFriendsAfterFirstLoginOrSignup'));

        if (
          openInviteFriendsAfterFirstLoginOrSignup &&
          organizations &&
          selectedOrganization !== null
        ) {
          localStorage.removeItem('openInviteFriendsAfterFirstLoginOrSignup');
          history.push('/invite-friends');
        }

        if (
          userHelpers.checkIfRequiredDynamicFieldsAreEmpty(
            dynamicFieldsForSelectedOrg,
            user,
          ) && window.location.pathname !== '/terms-and-conditions'
        ) {
          history.push('/user-details');
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('login error', e);
      const message =
        e?.response?.data?.message ||
        getTranslatedStringFromKey(
          'messages.error.authentication.login-failed',
        );

      dispatch(setLoginLoading(false));
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.LOGIN_FAILED,
          subJourneyName: 'Signup/Signin',
          journeyName: 'Rider Experience',
          details: {
            message,
            ...data,
          },
        }),
      );
    }
  };
};

const checkAndNavigateToRidershipBonusLandingPage = (history, alternatePath = '/', skipTermsCheck = false) => {
  return async (dispatch, getState) => {
    let ridershipBonusLandingPageShown = JSON.parse(
      localStorage.getItem(localStorageKeys.RIDERSHIP_BONUS_LANDING_PAGE_SHOWN),
    );
    const {
      app: { user, organizations, selectedOrganization },
    } = getState();
    let shouldNavigateToTerms =
      !user.termsAndConditions ||
      user.termsAndConditions.basic === false ||
      (user.termsAndConditions.additional === false && additionalTerms);
    if (skipTermsCheck) {
      shouldNavigateToTerms = false;
    }
    if (
      !shouldNavigateToTerms &&
      (!ridershipBonusLandingPageShown ||
        !ridershipBonusLandingPageShown[user.id]) &&
      organizations &&
      selectedOrganization !== null &&
      organizations[selectedOrganization]?.detail?.ridershipBonus?.enabled &&
      organizations[selectedOrganization]?.detail?.ridershipBonus?.tiers
        ?.length > 0
    ) {
      if (!ridershipBonusLandingPageShown) ridershipBonusLandingPageShown = {};
      ridershipBonusLandingPageShown[user.id] = true;
      localStorage.setItem(
        localStorageKeys.RIDERSHIP_BONUS_LANDING_PAGE_SHOWN,
        JSON.stringify(ridershipBonusLandingPageShown),
      );
      return;
    }
    if (!shouldNavigateToTerms) {
      history.push(alternatePath);
    } else {
      handleTermsAndConditionsNavigation(user, history);
    }
  };
};

const handleTermsAndConditionsNavigation = (user, history) => {
  if (
    !user.termsAndConditions ||
    user.termsAndConditions.basic === false ||
    (user.termsAndConditions.additional === false && additionalTerms)
  ) {
    history.push('/terms-and-conditions');
  }
};

export const authenticateRider = (riderId, loader = false) => {
  return async (dispatch, getState) => {
    const { userAgent: userAgentObj } = getState().app;
    if (
      !userAgentObj ||
      !userHelpers.checkUserAgentRole(userAgentObj.OrganizationUsers)
    ) {
      return;
    }

    if (loader) {
      dispatch(setFullScreenLoading(true));
    }

    const authToken = localStorage.getItem('userAgentAuthToken');

    const response = await apiClient.get(
      api.endpoints.AUTHENTICATE_RIDER.replace(':id', riderId),
      {
        headers: {
          'x-auth': authToken,
        },
      },
    );

    const user = response.data;

    const selectedOrganization =
      user.OrganizationUsers?.length > 0
        ? userHelpers.getSelectedOrgIndex(user)
        : null;
    const organizations = userHelpers.getUserOrganizations(user);

    dispatch(setUser(user));
    dispatch(setOrganizationsFromUser(user));
    dispatch(setSelectedOrganization(selectedOrganization));
    dispatch(getNearbyBusStops([user.lat, user.lng]));
    if (
      Array.isArray(organizations) &&
      selectedOrganization !== null &&
      selectedOrganization < organizations.length
    ) {
      dispatch(
        getFixedRoutesByOrgId(
          organizations[selectedOrganization].organizationId,
        ),
      );
    }
    localStorage.removeItem('places');
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('authToken', response.headers['x-auth']);

    dispatch(fetchOrgDetails());
    dispatch(fetchOrgPlaces());

    if (loader) {
      dispatch(setFullScreenLoading(false));
    }
  };
};

export const update = (
  updateInfo = {
    addressesUpdated: false,
    organizationUpdated: false,
    termsAndConditions: null,
  },
  callback,
) => {
  return async (dispatch, getState) => {
    dispatch(setAccountSettingsLoading(true));

    const {
      auth: {
        accountSettings: {
          formState: {
            firstName,
            lastName,
            dob,
            phone,
            email,
            password,
            savedAddresses,
            specialNeeds,
            notes,
            selectedOrg,
            detail,
            language,
            riderType,
          },
        },
      },
      app: {
        user: { id: userId, OrganizationUsers, detail: userDetails },
        organizations,
        selectedOrganization,
      },
    } = getState();
    const phoneNumber = phone ? phone.split('-').join('') : '';

    const data = {
      name: `${firstName} ${lastName}`,
      email,
      smsNumber: phoneNumber,
      detail: {
        ...userDetails,
        birthday: dob,
        addresses: savedAddresses,
        specialneeds: specialNeeds,
        notes,
        selectedOrg:
          selectedOrg ||
          (Array.isArray(OrganizationUsers) && OrganizationUsers.length
            ? OrganizationUsers[0].organizationId
            : null),
        ...detail,
      },
      OrganizationUsers,
      language,
      riderType,
    };
    let passwordUpdate = false;
    try {
      if (password) {
        data.password = password;
        passwordUpdate = true;
      }

      if (updateInfo && updateInfo.termsAndConditions) {
        data.termsAndConditions = updateInfo.termsAndConditions;
      }

      const response = await apiClient.put(
        api.endpoints.UPDATE_USER.replace(':id', userId),
        data,
      );

      const user = response.data;
      user.OrganizationUsers = Array.isArray(user?.OrganizationUsers)
        ? user.OrganizationUsers.filter(orgUser => orgUser.role === 'User')
        : [];
      if (updateInfo.addressesUpdated) {
        dispatch(
          trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
            eventName: analytics.SAVED_ADDRESSES,
            subJourneyName: 'Signup/Signin',
            journeyName: 'Rider Experience',
            details: {
              source: updateInfo.source,
              addresses: user?.detail?.addresses || [],
            },
          }),
        );
      } else {
        dispatch(
          trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
            eventName: analytics.UPDATE_ACCOUNT_BUTTON,
            subJourneyName: 'Signup/Signin',
            journeyName: 'Rider Experience',
          }),
        );
      }
      if (!user.OrganizationUsers.length) {
        dispatch(setSelectedOrganization(null));
        dispatch(setOrganizations([]));
      }

      dispatch(setAccountSettingsLoading(false));
      dispatch(setUser(user));
      dispatch(setOrganizationsFromUser(user));
      dispatch(setAccountSettingsAndDynamicFields(user));

      localStorage.setItem('user', JSON.stringify(user));

      if (updateInfo.organizationUpdated) {
        dispatch(
          getFixedRoutesByOrgId(
            (organizations || [])[selectedOrganization]?.organizationId,
          ),
        );
      }

      if (process.env.REACT_APP_WORKFORCE_RIDER_APP === 'true') {
        dispatch(setSelectedMarker(null));
        dispatch(setSearchQueryPlaceIds(null));
        dispatch(fetchOrgPlaces());
        dispatch(fetchOrgDetails());
      }

      passwordUpdate && dispatch(setRestrictedMode(false));

      toast.show(
        'success',
        getTranslatedStringFromKey('messages.success.update-successful'),
      );

      await new Promise((res) => setTimeout(() => res(), 1000));
      const dynamicTextFields = (organizations || [])[selectedOrganization]
        ?.organization?.detail?.userDynamicFields;
      if (
        userHelpers.checkIfRequiredDynamicFieldsAreEmpty(
          dynamicTextFields,
          user,
        )
      ) {
        updateInfo.history.push('/user-details', {
          nextRoute: '/account-settings',
        });
      }

      if (callback && typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        getTranslatedStringFromKey('messages.error.account.account-failed');
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.ACCOUNT_UPDATE_FAILED,
          subJourneyName: 'Signup/Signin',
          journeyName: 'Rider Experience',
          details: {
            message,
            ...data,
          },
        }),
      );
      dispatch(setAccountSettingsLoading(false));
    }
  };
};

export const getInvites = () => {
  return async dispatch => {
    const {
      data: { data: invites },
    } = await apiClient.get(api.endpoints.GET_INVITES);
    dispatch(
      setAccountSettingsInvites(
        invites.filter(
          invite =>
            invite.status === 'Pending' &&
            invite.role === 'User',
        ),
      ),
    );
  };
};

export const getUser = () => {
  return async (dispatch, getState) => {
    const { user } = getState().app;
    if (user) {
      const {
        data,
      } = await apiClient.get(api.endpoints.GET_USER.replace(':id', user.id));
      dispatch(setUser({
        ...user,
        ...data,
      }));
      dispatch(setOrganizationsFromUser({
        ...user,
        ...data,
      }));
      await dispatch(fetchOrgDetails());
    }
  };
};

export const acceptInvite = token => {
  return async (dispatch, getState) => {
    try {
      dispatch(setAccountSettingsInviteLoading(true));
      dispatch(setAccountSettingsInviteLoaderType('accept'));

      const response = await apiClient.post(
        api.endpoints.ACCEPT_INVITE.replace(':token', token),
      );
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.ACCEPTED_INVITE,
          subJourneyName: 'Invites',
          journeyName: 'Rider Experience',
          details: {
            token,
          },
        }),
      );
      const { OrganizationUsers } = response.data;

      const user = helpers.deepClone(getState().app.user);

      if (!user.OrganizationUsers || user.OrganizationUsers.length === 0) {
        user.OrganizationUsers = OrganizationUsers;
      } else {
        user.OrganizationUsers = [
          ...user.OrganizationUsers,
          ...OrganizationUsers,
        ];
      }

      dispatch(setUser(user));
      dispatch(setOrganizationsFromUser(user));
      dispatch(setSelectedOrganization(user.OrganizationUsers.length - 1));
      dispatch(update());
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('authToken', response.headers['x-auth']);

      dispatch(setAccountSettingsInvites([]));
      dispatch(setAccountSettingsInviteLoading(false));
      dispatch(setAccountSettingsSelectedInvite(null));

      dispatch(fetchOrgPlaces());
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('ACCEPT_INVITE_ERROR', error);
      const message =
        error?.response?.data?.message ||
        getTranslatedStringFromKey(
          'messages.error.invites.accept-invite-failed',
        );
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.ACCEPT_INVITE_FAILED,
          subJourneyName: 'Invites',
          journeyName: 'Rider Experience',
          details: {
            token,
            message,
          },
        }),
      );
      dispatch(setAccountSettingsInviteLoading(false));
    }
  };
};

export const declineInvite = token => {
  return async dispatch => {
    try {
      dispatch(setAccountSettingsInviteLoading(true));
      dispatch(setAccountSettingsInviteLoaderType('decline'));

      await apiClient.post(
        api.endpoints.DECLINE_INVITE.replace(':token', token),
      );
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.DECLINED_INVITE,
          subJourneyName: 'Invites',
          journeyName: 'Rider Experience',
          details: {
            token,
          },
        }),
      );
      dispatch(getInvites());
      dispatch(setAccountSettingsInviteLoading(false));
      dispatch(setAccountSettingsSelectedInvite(null));
    } catch {
      dispatch(setAccountSettingsInviteLoading(false));
    }
  };
};

export const logout = () => {
  sessionStorage.removeItem('should-not-open-rate-ride');
  return async dispatch => {
    localStorage.removeItem('user');
    localStorage.removeItem('userAgent');
    localStorage.removeItem('authToken');
    localStorage.removeItem('userAgentAuthToken');
    localStorage.removeItem('places');
    localStorage.removeItem('organizationLocationViewBox');
    dispatch(setUser(null));
    dispatch(setOrganizationsFromUser(null));
    window.location = '/';
  };
};

export const passwordlessAuth = (token, { onSuccess, onFailure }) => {
  return async dispatch => {
    try {
      dispatch(setFullScreenLoading(true));
      const response = await apiClient.post(api.endpoints.PASSWORDLESS_AUTH, {
        accessToken: token,
      });
      const { type, user } = response.data;
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.PASSWORDLESS_AUTH_SUCCESS,
          subJourneyName: 'Signup/Signin',
          journeyName: 'Rider Experience',
          details: {
            type,
            user,
          },
        }),
      );
      sessionStorage.setItem(
        'passwordlessAuthToken',
        response.headers['x-auth-passwordless'],
      );
      sessionStorage.setItem('user', JSON.stringify(user));
      dispatch(setPasswordlessAuthenticationType(type));
      dispatch(setUser(user));
      dispatch(setOrganizationsFromUser(user));
      if (typeof onSuccess === 'function') {
        onSuccess(type);
      }
    } catch (e) {
      const message =
        e?.response?.data?.message ||
        getTranslatedStringFromKey(
          'messages.error.authentication.passwordless-auth-failed',
        );
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.PASSWORDLESS_AUTH_FAILED,
          subJourneyName: 'Signup/Signin',
          journeyName: 'Rider Experience',
          details: {
            message,
          },
        }),
      );
      if (typeof onFailure === 'function') {
        onFailure();
      }
    }
    dispatch(setFullScreenLoading(false));
  };
};

export const sendReferrals = toggle => {
  return async (dispatch, getState) => {
    try {
      dispatch(setSendReferralLoading(true));
      const {
        app: { organizations, selectedOrganization },
        auth: {
          sendReferralEmailsOrPhones: { emailsOrPhoneNumbers },
        },
      } = getState();
      await apiClient.post(
        api.endpoints.SEND_REFERRALS.replace(
          ':id',
          (organizations || [])[selectedOrganization]?.organizationId,
        ),
        { toInvite: emailsOrPhoneNumbers },
      );
      toast.show(
        'success',
        getTranslatedStringFromKey('send-referrals.toast.invites-sent'),
      );
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.INVITATION_SENT,
          subJourneyName: 'Invites',
          journeyName: 'Rider Experience',
          details: {
            toInvite: emailsOrPhoneNumbers,
          },
        }),
      );
      dispatch(setSendReferralLoading(false));
      dispatch(setSendReferralEmailsOrPhones([]));
      dispatch(setSendReferralInputEmailOrPhone(''));

      toggle();
    } catch (e) {
      toast.show('error', getApiErrorTranslation(e));
      dispatch(setSendReferralLoading(false));
    }
  };
};

export {
  setSignupLoading,
  setSignupFormState,
  setSignupOrganizationId,
  setSignupReferralCode,
  setSignupAddresses,
  setLoginLoading,
  setLoginFormState,
  resetAuthState,
  setAccountSettings,
  setAccountSettingsLoading,
  setAccountSettingsDOB,
  setSignupDOB,
  setAccountSettingsFormState,
  setAccountSettingsAddresses,
  setAccountSettingsInviteLoading,
  setAccountSettingsInvites,
  setAccountSettingsSelectedInvite,
  setAccountSettingsInviteLoaderType,
  resetAccountSettings,
  setForgotPasswordEmail,
  setLoginRememberMe,
  setForgotPasswordLoading,
  setResetPasswordLoading,
  setResetPasswordNewPassword,
  setAccountSettingsLanguage,
  setSendReferralEmailsOrPhones,
  setSendReferralInputEmailOrPhone,
  setSendReferralLoading,
  checkAndNavigateToRidershipBonusLandingPage,
};
export const authReducer = AuthSlice.reducer;
