/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import img from '@assets/images/piggy-bank.svg';
import closeIcon from '@assets/icons/close-white.svg';
import { useTranslation } from 'react-i18next';
import { FullScreen, Col, Modal, Row } from '..';
import {
  Wrapper,
  BannerContainer,
  BannerText,
  RidershipBonusLandingImage,
  RidershipLevelsTitleRow,
  RidershipLevelRow,
  RidershipLevel,
  RidershipLevelTitle,
  RidershipLevelDescription,
  RidershipLevelBonusText,
  RidershipBonusLandingIntroContainer,
  RidershipBonusLandingIntroTitle,
  RidershipBonusLandingIntroDescription,
  StartEarningButton,
  RidershipBonusLandingEndNote,
  RidershipBonusLandingLevelText,
  CloseIconStyled,
  SkipBtn,
  RidershipBonusTermsLink,
} from './styled';
import { theme } from '@constants';

const RidershipBonusLanding = ({
  ridershipTiers,
  onClickStartEarning,
  loading,
  organizationName,
  onClose,
  isLargeScreen,
  onSkip,
}) => {
  const { t } = useTranslation();
  const termsURL = process.env.REACT_APP_TERMS_URL;
  const renderRidershipBonusLanding = () => {
    return (
      <FullScreen>
        <Wrapper
          id="ridership-bonus-landing-wrapper"
          data-cy="ridership-bonus-landing-wrapper"
          isLargeScreen={isLargeScreen}
        >
          <BannerContainer
            id="ridership-bonus-banner"
            data-cy="ridership-bonus-banner"
            flex="initial"
            justify="center"
          >
            <BannerText
              data-cy="ridership-bonus-banner-text"
              id="ridership-bonus-banner-text"
            >
              {t('ridership-bonus-landing.banner-text')}
            </BannerText>
            {isLargeScreen && (
              <CloseIconStyled
                icon={closeIcon}
                data-cy="close-btn"
                onClick={onClose}
                width={15}
              />
            )}
          </BannerContainer>
          <RidershipBonusLandingImage
            id="ridership-bonus-landing-image"
            data-cy="ridership-bonus-landing-image"
            src={img}
          />
          <RidershipBonusLandingIntroContainer
            id="ridership-bonus-landing-intro"
            data-cy="ridership-bonus-landing-intro"
            flex="initial"
          >
            <RidershipBonusLandingIntroTitle
              id="ridership-bonus-landing-intro-title"
              data-cy="ridership-bonus-landing-intro-title"
            >
              {t('ridership-bonus-landing.intro.title')}
            </RidershipBonusLandingIntroTitle>
            <RidershipBonusLandingIntroDescription
              id="ridership-bonus-landing-intro-description"
              data-cy="ridership-bonus-landing-intro-description"
            >
              {t('ridership-bonus-landing.intro.description')}
            </RidershipBonusLandingIntroDescription>
          </RidershipBonusLandingIntroContainer>
          <RidershipLevelsTitleRow>
            <RidershipBonusLandingLevelText>
              {t('ridership-bonus-landing.ridership-level.label')}
            </RidershipBonusLandingLevelText>
            <RidershipBonusLandingLevelText
              color={theme.RIDERSHIP_BONUS_DARK_GREEN}
            >
              {t('ridership-bonus-landing.ridership-level.bonus')}
            </RidershipBonusLandingLevelText>
          </RidershipLevelsTitleRow>
          {ridershipTiers?.map((tier, index) => (
            <RidershipLevelRow key={index}>
              <RidershipLevel
                background={tier.color}
                index={index}
                data-cy="ridership-level"
                id="ridership-bonus-level"
              >
                <div>
                  <RidershipLevelTitle>
                    {t(
                      `ridership-bonus-landing.ridership-level.title.${tier.name}`,
                    )}
                  </RidershipLevelTitle>
                  <RidershipLevelDescription>
                    {t('ridership-bonus-landing.ridership-level.description', {
                      numTrips: tier.totalRoundTripsRequired,
                    })}
                  </RidershipLevelDescription>
                </div>
              </RidershipLevel>
              <RidershipLevelBonusText index={index}>
                {`$${tier.totalTierbonus}`}
              </RidershipLevelBonusText>
            </RidershipLevelRow>
          ))}
          <Col align="center" fullHeight={false}>
            <StartEarningButton
              label={t('ridership-bonus-landing.buttons.start-earning')}
              onClick={onClickStartEarning}
              icon={loading ? faSpinner : null}
              spin={loading}
              id="ridership-bonus-start-earning-button"
              data-cy="ridership-bonus-start-earning-button"
            />
            <Row justify="center" align="flex-start" spacingH={20}>
              <SkipBtn onClick={onSkip}>{t('rate-ride.buttons.skip')}</SkipBtn>
            </Row>
            <RidershipBonusLandingEndNote>
              {t('ridership-bonus-landing.end-note')}&nbsp;
              <RidershipBonusTermsLink href={termsURL} rel="noopener noreferrer" target="_blank">
              {t('ridership-bonus-landing.terms-note')}
              </RidershipBonusTermsLink>
            </RidershipBonusLandingEndNote>
          </Col>
        </Wrapper>
      </FullScreen>
    );
  };

  if (isLargeScreen) {
    return <Modal id="ridership-landing-modal" width="375">
      {renderRidershipBonusLanding()}
    </Modal>;
  }
  else {
    return renderRidershipBonusLanding();
  }
};

RidershipBonusLanding.propTypes = {
  ridershipTiers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      color: PropTypes.string,
      totalRoundTripsRequired: PropTypes.number,
      totalTierbonus: PropTypes.number,
    }),
  ),
  onClickStartEarning: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  organizationName: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  isLargeScreen: PropTypes.bool.isRequired,
  onSkip: PropTypes.func.isRequired,
};

RidershipBonusLanding.defaultProps = {
  loading: false,
};

export default RidershipBonusLanding;
