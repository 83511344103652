/** @format */

import React from 'react';
import { PropTypes } from 'prop-types';
import { TileLayer, ZoomControl, Circle } from 'react-leaflet';
import { MapRow, SMap } from './styled';
import {
  credits,
  defaultCenter,
} from './config';
import Map from './map';

const WorkForceMap = ({
  places,
  tripMarkers,
  currentPosition,
  tripPath,
  resizeMapKey,
  onClickBusStopMarker,
  onClickWorkplaceMarker,
  whenCreatedHandler,
  largeScreen,
  isFitBound,
  setIsFitBound,
  selectedOrgPlaceMarker,
  pickupHereClicked,
}) => {
  const center =
    tripMarkers?.pickup?.location ||
    currentPosition ||
    defaultCenter;

  return (
    <>
      <MapRow justify="flex-start" align="flex-start">
        <SMap
          zoomControl={false}
          center={center}
          zoom={tripMarkers.pickup?.location ? 16 : 13}
          whenCreated={whenCreatedHandler}
        >
          <ZoomControl position={largeScreen ? 'topright' : 'topleft'} />
          <TileLayer attribution={credits.attribution} url={credits.url} />
          <Map
            places={places}
            tripMarkers={tripMarkers}
            tripPath={tripPath}
            resizeMapKey={resizeMapKey}
            onClickBusStopMarker={onClickBusStopMarker}
            onClickWorkplaceMarker={onClickWorkplaceMarker}
            isFitBound={isFitBound}
            setIsFitBound={setIsFitBound}
            selectedOrgPlaceMarker={selectedOrgPlaceMarker}
            pickupHereClicked={pickupHereClicked}
          />
          {currentPosition && (
            <Circle center={currentPosition?.location || currentPosition} />
          )}
        </SMap>
      </MapRow>
    </>
  );
};

WorkForceMap.propTypes = {
  places: PropTypes.array.isRequired,
  tripMarkers: PropTypes.shape({
    pickup: PropTypes.array,
    destination: PropTypes.array,
  }).isRequired,
  currentPosition: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  tripPath: PropTypes.shape({
    share: PropTypes.array,
    transit: PropTypes.array,
  }).isRequired,
  resizeMapKey: PropTypes.number.isRequired,
  onClickBusStopMarker: PropTypes.func.isRequired,
  onClickWorkplaceMarker: PropTypes.func.isRequired,
  whenCreatedHandler: PropTypes.func.isRequired,
  largeScreen: PropTypes.bool.isRequired,
  isFitBound: PropTypes.bool.isRequired,
  setIsFitBound: PropTypes.func.isRequired,
  selectedOrgPlaceMarker: PropTypes.bool,
  pickupHereClicked: PropTypes.bool,
};

WorkForceMap.defaultProps = {
  currentPosition: null,
  selectedOrgPlaceMarker: false,
  pickupHereClicked: false,
};

export default WorkForceMap;
