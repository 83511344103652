/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Row, Col, Dropdown } from '@components';
import { theme, generals } from '@constants';
import { WrapperRow, DropdownCol, LabelSmall } from './styled';
import config from './config';

export const LanguageSelectorDropdown = ({
  onChangeLanguage,
  userLanguage,
  inHeader,
  largeScreen,
  showFlag,
  onClick,
  isOpen,
  setIsLanguageDropdownOpen,
  isSmallScreen,
  className,
}) => {
  const { t } = useTranslation();
  let language;
  const options = generals.SUPPORTED_LANGUAGES.map(x => {
    switch (x) {
      case 'en':
        language = 'US';
        break;
      case 'sw':
        language = 'TZ';
        break;
      case 'sun':
        language = 'ID';
        break;
      case 'ne':
        language = 'np';
        break;
      case 'zh':
        language = 'cn';
        break;
      default:
        language = x;
        break;
    }

    const srcLink = `${config.flagsDirectory}/${language.toLowerCase()}.svg`;
    return {
      value: x,
      label: <>{t(`common.languages.${x}`)} <img alt="United States" src={srcLink} width="16" height="16" /></>,
      selectedLabel: <>{t(`common.languages.${x}`)} {showFlag && <img alt="United States" src={srcLink} width="16" height="16" />}</>,
    };
  });

  const onDropdownSelect = option => {
    onChangeLanguage(option.value);
  };

  const renderDropdown = () => (
    <DropdownCol justify="flex-start" align="flex-start">
      <Row
        data-cy="language-selector-dropdown"
        id="options"
        align="center"
        justify="center"
      >
        <Dropdown
          id="language-options-dropdown"
          placeholder="Select Language"
          options={options}
          onChange={onDropdownSelect}
          value={userLanguage}
          color={theme.BASE_COLOR}
          inverted
          bordered={!inHeader}
          rounded={!inHeader}
          inHeader={inHeader}
          size={largeScreen ? 'sm' : 'xs'}
          height="50px"
          onClick={onClick}
          isOpen={isOpen}
          setIsLanguageDropdownOpen={setIsLanguageDropdownOpen}
          isSmallScreen={isSmallScreen}
        />
      </Row>
    </DropdownCol>
  );

  return (
    <Col align="flex-start" justify="flex-start" zIndex="2" spacingV={0}>
      {!inHeader && <LabelSmall>{t('signup.labels.select-language')}</LabelSmall>}
      <WrapperRow className={className} justify="flex-start" align="flex-start">
        {renderDropdown()}
      </WrapperRow>
    </Col>
  );
};

LanguageSelectorDropdown.propTypes = {
  onChangeLanguage: PropTypes.func,
  userLanguage: PropTypes.string,
  inHeader: PropTypes.bool,
  largeScreen: PropTypes.bool,
  showFlag: PropTypes.bool,
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsLanguageDropdownOpen: PropTypes.func,
  isSmallScreen: PropTypes.bool,
};

LanguageSelectorDropdown.defaultProps = {
  onChangeLanguage: null,
  userLanguage: 'en',
  inHeader: false,
  largeScreen: true,
  showFlag: true,
  onClick: () => {},
  isOpen: false,
  setIsLanguageDropdownOpen: () => {},
  isSmallScreen: false,
};

export default LanguageSelectorDropdown;
