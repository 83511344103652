/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  faCircle,
  faCircleNotch,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { PathItem } from '../pathItem';

const WorkforceShareTripPath = ({
  leaveDate,
  arriveDate,
  pickupAddress,
  destinationAddress,
  leaveBy,
  arriveBy,
  pickupName,
  destinationName,
}) => {
  const { t } = useTranslation();
  const pickupDate = leaveDate && moment(leaveDate).format('MM/DD/YYYY');
  const pickupTime = leaveDate && moment(leaveDate).format('hh:mm a');
  const destinationDate = arriveDate && moment(arriveDate).format('MM/DD/YYYY');
  const destinationTime = arriveDate && moment(arriveDate).format('hh:mm a');

  return (
    <>
      <PathItem
        title={
          leaveBy
            ? leaveDate
              ? t('trip-overview.labels.estd-time.pickup')
              : t('trip-overview.labels.time.pickup')
            : null
        }
        time={pickupDate && pickupTime && `${pickupDate}\n${pickupTime}`}
        address={pickupAddress}
        name={pickupName}
        icon={leaveBy ? faCircleNotch : faCircle}
        iconRotation
      />

      {destinationAddress && (
        <PathItem
          title={
            arriveBy
              ? arriveDate
                ? t('trip-overview.labels.estd-time.arrival')
                : t('trip-overview.labels.time.dropoff')
              : null
          }
          time={
            destinationDate &&
            destinationTime &&
            `${destinationDate}\n${destinationTime}`
          }
          name={destinationName}
          address={destinationAddress}
          icon={arriveBy ? faCheckCircle : faCircle}
        />
      )}
    </>
  );
};

WorkforceShareTripPath.propTypes = {
  leaveDate: PropTypes.string,
  pickupAddress: PropTypes.string.isRequired,
  arriveDate: PropTypes.string,
  pickupName: PropTypes.string,
  destinationName: PropTypes.string,
  destinationAddress: PropTypes.string,
  leaveBy: PropTypes.bool,
  arriveBy: PropTypes.bool,
};

WorkforceShareTripPath.defaultProps = {
  leaveDate: null,
  leaveBy: null,
  arriveBy: null,
  arriveDate: null,
  destinationAddress: null,
  pickupName: null,
  destinationName: null,
};

export default WorkforceShareTripPath;
