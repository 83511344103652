/** @format */

import styled from 'styled-components';
import Datetime from 'react-datetime';
import { theme } from '@constants';
import { styles } from '@utils';

export const RdtPickerWrapper = styled.div`
  position: absolute;
  background: ${theme.WHITE};
  box-shadow: 0 1px 3px ${styles.hexToRgba(theme.BLACK, 0.1)};
  border: 1px solid ${theme.LIGHT_GREY};
  min-width: 200px;
`;

export const DateTimePicker = styled(Datetime)`
  width: 100%;

  .rdtPicker {
    padding: 0;
    box-shadow: none;
    border: none;
    z-index: 1000 !important;
  }

  .rdt input {
    border-radius: 100px;
  }

  .rdtActive {
    background-color: #0ab9ff !important;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    border-radius: 30px;
  }

  .rdtPicker td.rdtToday:before {
    border-bottom: 7px solid #0ab9ff;
  }

  .rdtSwitch {
    font-weight: bold;
    border-radius: 30px;
  }

  .rdtSwitch:hover {
    cursor: pointer;
    background: #eeeeee;
  }

  .rdtDay,
  .dow {
    font-size: 10px;
  }

  thead .rdtSwitch,
  .rdtMonth,
  .rdtYear {
    font-size: 12px;
  }

  .rdtBtn {
    color: #0a2e40;
  }

  .rdtPicker td.rdtDay:hover,
  .rdtPicker td.rdtHour:hover,
  .rdtPicker td.rdtMinute:hover,
  .rdtPicker td.rdtSecond:hover,
  .rdtPicker .rdtTimeToggle:hover {
    border-radius: 30px;
  }
`;
