/** @format */

import styled from 'styled-components';

import { Row, Col, Button } from '@components';
import { theme } from '@constants';

export const WrapperRow = styled(Row)`
  z-index: 2;
  background-color: ${theme.WHITE};
`;

export const DropdownCol = styled(Col)`
  margin-bottom: 20px;
`;

export const InviteActionsRow = styled(Row)`
  margin-top: 20px;
`;

export const InviteButton = styled(Button)`
  padding: 0px;
  border-radius: 8px;
  font-size: 18px;
  line-height: 20px;
  font-family: Bryant;
  font-weight: 700;
`;