/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { SheetModal } from '@components';
import FixedRouteSearchFormSmall from './small';
import FixedRouteSearchFormLarge from './large';
import { NextButton } from './styled';

const FixedRouteSearchForm = props => {
  const { largeScreen, toggleMinimized, state, toggleCardVisible } = props;

  return (
    <>
      {largeScreen ? (
        <FixedRouteSearchFormLarge {...props} />
      ) : (
        !state.cardVisible && (
          <SheetModal
            id="fixed-route-sheet-modal"
            minimizeEnabled
            toggleMinimized={toggleMinimized}
            minimized={state.minimized}
          >
            {state.results.length > 0 && !state.minimized && (
              <NextButton onClick={() => toggleCardVisible(true)} id="fixed-route-public-map-card-next-btn">
                Next
              </NextButton>
            )}
            {!state.cardVisible && <FixedRouteSearchFormSmall {...props} />}
          </SheetModal>
        )
      )}
    </>
  );
};

FixedRouteSearchForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  state: PropTypes.shape({
    query: PropTypes.shape({
      date: PropTypes.string,
      searchInAllFixedRoutes: PropTypes.bool,
      time: PropTypes.string,
      pickup: PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.string,
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
        PropTypes.string,
      ]),
      dropoff: PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.string,
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
        PropTypes.string,
      ]),
      type: PropTypes.string,
    }).isRequired,
    minimized: PropTypes.bool,
    pickupSuggestions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
      }),
    ).isRequired,
    dropoffSuggestions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
  onSelectDate: PropTypes.func.isRequired,
  onSelectTime: PropTypes.func.isRequired,
  onToggleSearchAll: PropTypes.func.isRequired,
  onToggleType: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  getAddresses: PropTypes.func.isRequired,
  clearAddresses: PropTypes.func.isRequired,
  onPickupSelected: PropTypes.func.isRequired,
  onDropoffSelected: PropTypes.func.isRequired,
  disableFixedRoutesDropdown: PropTypes.bool.isRequired,
  dropdownOptions: PropTypes.array.isRequired,
  dropdownValue: PropTypes.string.isRequired,
  onChangeFixedRoute: PropTypes.func.isRequired,
  goToHome: PropTypes.func.isRequired,
  toggleMinimized: PropTypes.func.isRequired,
  onOpenPicker: PropTypes.func.isRequired,
  largeScreen: PropTypes.bool.isRequired,
};

FixedRouteSearchForm.defaultProps = {
  loading: null,
};

export default FixedRouteSearchForm;
