/** @format */

import styled from 'styled-components';
import { theme } from '@constants';
import { styles as styleUtils } from '@utils';
import { Col, Input, Button } from '..';

export const Wrapper = styled.div`
  background-color: ${styleUtils.hexToRgba(theme.LIGHTEN_GRAY, 0.2)};
  border-radius: 8px;
  width: 100%;`;

export const PromoCodeCol = styled(Col)``;

export const PromoCodeInput = styled(Input)`
  label {
    font-size: 16px;
    font-family: Poppins;
    color: ${theme.BASE_COLOR};
  }

  input {
    border-radius: 100px;
    height: 40px;
    font-size: 16px;
    font-family: Poppins;
  }
`;

export const ApplyButton = styled(Button)`
  margin-left: 15px;
  opacity: ${props => (props.disabled && props.onClick ? 0.5 : 1)};
`;
