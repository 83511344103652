/** @format */

import styled from 'styled-components';

export const StyledImage = styled.img`
  width: ${props => props.width}px;
`;

export const ImgWrapper = styled.button`
  width: ${props => props.width}px;
  height: ${props => props.width}px;
  display: block;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;

  &:hover,
  &:focus {
    outline: none;
  }
`;
