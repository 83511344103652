/** @format */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { theme, generals as generalConstants } from '@constants';
import { Row, Modal, IconButton } from '@components';
import {
  CardContainer,
  AddressBlock,
  Line,
  TimingBlock,
  TimingBlockItem,
  InfoBlock,
  Btn,
  TimingBlockHeadings,
} from './styled';

const OrganizationPlaceCardsModal = ({
  selectedPlace,
  onClose,
  onPickupHereClick,
  selectedWorkPlace,
  estPickupTime,
  loading,
}) => {
  const { t } = useTranslation();
  const isStop = selectedPlace?.type === 'STOP';
  const isEstShiftTimeLoading = loading === generalConstants.EST_SHIFT_PICKUP_TIME_LOADING;
  const placeName = isStop ? `${selectedPlace?.markerKey}. ${selectedPlace?.name}` : `${selectedPlace?.name}`;
  return (
    <Modal open width="328" borderRadius="24">
      <CardContainer id="card-container" data-cy="address-card">
        <InfoBlock>
          <Row flex="initial" align="flex-end" justify="flex-end">
            <IconButton
              width={14}
              id="close-button"
              onClick={() => onClose()}
            />
          </Row>
          <h3 data-cy="address-name">{placeName}</h3>
          {!isStop ? <p className="type">{t('organization-card.workplace')}</p> : null}
          <AddressBlock data-cy="complete-address">
            <p className="address">{selectedPlace?.label}</p>
          </AddressBlock>
          {isStop ? (
            <>
              <Line />
              <TimingBlock id="timing-block">
                <TimingBlockHeadings>
                  <p>{t('organization-card.shift-time')}</p>
                  <p>{t('organization-card.est-pickup-time')}</p>
                </TimingBlockHeadings>
                {selectedWorkPlace?.shifts?.length > 0 ? (
                  selectedWorkPlace?.shifts?.map(shift => (
                    <TimingBlockItem key={shift.id} loading={loading}>
                      <p className="day">{shift.startTime} - {shift.endTime}</p>
                      <p className="time">{isEstShiftTimeLoading ? `${t('organization-card.calculating')}...` : estPickupTime?.get(shift.id)}</p>
                    </TimingBlockItem>
                  ))
                ) : (
                  <TimingBlockItem>
                    <p className="no-time">
                      {t('organization-card.no-schedule-available')}
                    </p>
                  </TimingBlockItem>
                )}
              </TimingBlock>
              <Btn
                id="pickup-here-btns"
                data-cy="pickup-here-btn"
                label={t('organization-card.pickup-here')}
                color={theme.BASE_COLOR}
                size="md"
                onClick={() => onPickupHereClick('pickup', selectedPlace)}
              />
            </>
          ) : null}
        </InfoBlock>
      </CardContainer>
    </Modal>
  );
};

OrganizationPlaceCardsModal.propTypes = {
  selectedPlace: PropTypes.object,
  onPickupHereClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedWorkPlace: PropTypes.object,
  estPickupTime: PropTypes.array,
  loading: PropTypes.bool.isRequired,
};

OrganizationPlaceCardsModal.defaultProps = {
  selectedPlace: null,
  selectedWorkPlace: null,
  estPickupTime: null,
};

export default OrganizationPlaceCardsModal;
