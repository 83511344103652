import React from 'react';
import { PropTypes } from 'prop-types';

import FixedRouteCardsLarge from './large';
import FixedRouteCardsSmall from './small';

const FixedRouteCards = ({
  fixedRouteResults,
  selectFixedRouteResult,
  largeScreen,
  toggleMinimized,
  state,
  toggleCardVisible,
}) => {
  return (
    <>
      {largeScreen ? (
        <FixedRouteCardsLarge
          fixedRouteResults={fixedRouteResults}
          selectFixedRouteResult={selectFixedRouteResult}
          largeScreen={largeScreen}
          selectedResult={state.selectedResult}
        />
      ) : (
        <FixedRouteCardsSmall
          fixedRouteResults={fixedRouteResults}
          selectFixedRouteResult={selectFixedRouteResult}
          largeScreen={largeScreen}
          toggleMinimized={toggleMinimized}
          state={state}
          toggleCardVisible={toggleCardVisible}
        />
      )}
    </>
  );
};

FixedRouteCards.propTypes = {
  fixedRouteResults: PropTypes.array.isRequired,
  selectFixedRouteResult: PropTypes.func.isRequired,
  largeScreen: PropTypes.bool.isRequired,
  state: PropTypes.shape({
    query: PropTypes.shape({
      date: PropTypes.string,
      searchInAllFixedRoutes: PropTypes.bool,
      time: PropTypes.string,
      pickup: PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.string,
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
        PropTypes.string,
      ]),
      dropoff: PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.string,
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
        PropTypes.string,
      ]),
      type: PropTypes.string,
    }).isRequired,
    minimized: PropTypes.bool,
    pickupSuggestions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
      }),
    ).isRequired,
    dropoffSuggestions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
  toggleMinimized: PropTypes.func.isRequired,
  toggleCardVisible: PropTypes.func.isRequired,
};

export default FixedRouteCards;
