/** @format */
const formFields = [
  {
    name: 'email',
    type: 'email',
    id: 'ridership-redeem-bonus-email',
    'data-cy': 'ridership-redeem-bonus-email',
    focusNext: true,
    placeholder: 'Enter your Email',
    validator: {
      required: 'ridership-bonus.redeem-modal.fields.email.validation.required',
      pattern: {
        // eslint-disable-next-line no-useless-escape
        value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
        message: 'ridership-bonus.redeem-modal.fields.email.validation.invalid',
      },
    },
  },
  {
    name: 'confirm-email',
    type: 'email',
    id: 'ridership-redeem-bonus-email-confirm',
    'data-cy': 'ridership-redeem-bonus-email-confirm',
    focusNext: true,
    placeholder: 'Confirm your Email',
    validator: {
      required: 'ridership-bonus.redeem-modal.fields.confirm-email.validation.required',
      match: {
        message: 'ridership-bonus.redeem-modal.fields.confirm-email.validation.invalid',
      },
    },
  },
];

export default {
  formFields,
};
