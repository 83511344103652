/** @format */

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { theme } from '@constants';
import { styles as styleUtils } from '@utils';
import { Row, Col } from '..';

export const TripColumn = styled(Col)`
  position: relative;
  margin-bottom: 20px;
  border: ${props => {
    return props.bordered ? `2px solid ${theme.BASE_COLOR}` : 'none';
  }};
  border-radius: 15px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);

  .react-add-to-calendar {
    position: absolute;
    right: 0;
    z-index: 2;
    text-align: right;

    .react-add-to-calendar__wrapper {
      .react-add-to-calendar__button {
        display: block;
        color: ${theme.BASE_COLOR};
        border: none;
        background-color: ${theme.TRANSPARENT};

        :hover {
          color: ${theme.BASE_COLOR};
          text-decoration: none;
        }

        .react-add-to-calendar__icon--left,
        .react-add-to-calendar__icon--right {
          padding: 0;
          color: ${theme.WHITE};
        }
      }
    }

    .react-add-to-calendar__dropdown {
      right: 0;
      left: initial;
      top: 0;
      width: 200px;
      padding: 0;
      margin-bottom: 15px;
      position: relative;
      z-index: 2;
      border-radius: 4px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
      background-color: ${theme.WHITE};
      border: none;

      ul {
        padding: 0;

        li {
          padding: 8px 10px;
          color: ${theme.BASE_COLOR};
        }
      }
    }
  }
`;

export const TripWrapper = styled.div`
  width: 100%;
  border: none;
  padding: 0;
  background-color: ${theme.TRANSPARENT};
  position: relative;
  z-index: 1;

  :focus {
    outline: none;
  }
`;

export const TripHeaderColumn = styled(Col)`
  background-color: ${theme.BASE_COLOR};
  color: ${theme.WHITE};
  border-radius: 10px;
`;

export const TripHeaderRow = styled(Row)`
  padding: 10px 15px;
`;

export const OptionName = styled.span`
  color: ${theme.WHITE};
  font-size: 14px;
  font-weight: 600;
  margin-right: 15px;
`;

export const TextValueWrapper = styled.div`
  ${styleUtils.flex('flex-start', 'flex-start', 'column')};
  flex: 1;
`;

export const Text = styled.span`
  font-size: 8px;
  line-height: 5px;
  font-weight: bold;
  color: ${props => props.color};
`;

export const Value = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: ${props => props.color};
`;

export const ButtonWrapper = styled.div`
  ${styleUtils.flex('center', 'flex-end', 'column')};
  width: 70px;
`;

export const SeeDetailsWrapper = styled.div`
  margin-top: 2px;
  cursor: pointer;
`;

export const SeeDetailsText = styled.span`
  font-size: 10px;
  margin-right: 4px;
  font-weight: bold;
`;

export const SeeDetailsIcon = styled(FontAwesomeIcon)`
  color: ${props => props.color};
  margin-top: 4px;
`;
