/** @format */

// eslint-disable-next-line
import React from 'react';
import { useSelector } from 'react-redux';

const getSelectedTripWithIndex = (selectedTrip, trips, type) => {
  const shareTrip = trips.share[type][selectedTrip.share[type]];
  const transitShareTrip =
    trips.transitShare[type][selectedTrip.transitShare[type]];

  const trip = shareTrip || transitShareTrip;

  let index;

  if (shareTrip) {
    index = selectedTrip.share[type];
  } else if (transitShareTrip) {
    index = selectedTrip.transitShare[type];
  }

  return [trip, index];
};

const useSelectedTrip = () => {
  const { selectedTrip, trips } = useSelector(store => store.tripRequest);

  const [departTrip, departIndex] = getSelectedTripWithIndex(
    selectedTrip,
    trips,
    'depart',
  );
  const [returnTrip, returnIndex] = getSelectedTripWithIndex(
    selectedTrip,
    trips,
    'return',
  );

  return [departTrip, returnTrip, departIndex, returnIndex];
};

export default useSelectedTrip;
