/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '@constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DateTimePicker, Row, Col, Switch } from '..';
import {
  DayItem,
  TextSmall,
  RdtUntilDatePickerWrapper,
  DateInput,
  UntillText,
  Tooltip,
} from './styled';

const FrequencyPicker = ({ data, onChange, isValidEndDate }) => {
  const { t } = useTranslation();
  const selectDay = day => {
    onChange('days', {
      ...data.days,
      [day]: !data.days[day],
    });
  };

  const onRepeatEnabledChange = enabled => {
    onChange('enabled', enabled);
  };

  const onChangeDateUntil = date => {
    const arrivalTime = moment(data.end).format('HH:mm:ss');
    const repeatUntilDate = date.format('MM/DD/YYYY');
    onChange('end', moment(`${arrivalTime} ${repeatUntilDate}`).format('MM/DD/YYYY HH:mm:ss'));
  };

  const UntilDatePickerInput = (inputProps, openCalendar) => {
    return (
      <DateInput
        name="depart"
        value={moment(inputProps.value).format('ddd, MMM DD')}
        label="Date"
        onFocus={openCalendar}
        color={theme.BASE_COLOR}
        inverted
        readOnly
        autoComplete="off"
      />
    );
    };

  return (
    <>
      <Row justify="space-between" spacingV={10} align="center">
        <Row justify="flex-start" align="flex-start">
          <TextSmall>{t('trip-overview.repeat.title')} </TextSmall>
          <Tooltip
            id="cost-tooltip"
            multiline
            backgroundColor={theme.WEIRD_GREEN}
            textColor={theme.WHITE}
          />
          <span
            data-tip={t('trip-overview.repeat.tooltip')}
            data-event="click"
            data-event-off="mouseout"
            data-for="cost-tooltip"
            data-place="right"
            data-effect="solid"
          >
            <FontAwesomeIcon icon={faExclamationCircle} />
          </span>
        </Row>
        <Switch
          id="repeat-switch"
          data-cy="repeat-toggle-button"
          checked={data.enabled}
          onChange={() => {
            onRepeatEnabledChange(!data.enabled);
          }}
          unCheckedText=""
          checkedText=""
          width={60}
          height={30}
          bgColor={theme.BASE_COLOR}
          triggerColor={theme.WHITE}
          showIcon={data.enabled}
          opacity={!data.enabled ? 0.2 : 1}
          triggerSpacing={4}
        />
      </Row>
      {data.enabled && (
        <Col justify="flex-start" align="flex-start">
          <Row justify="space-between" align="center"
            data-cy="repeat-days" spacingV={10}>
            {Object.keys(data.days).map(day => (
              <DayItem
                key={day}
                id={`day-item-${day}`}
                onClick={() => {
                  selectDay(day);
                }}
                selected={data.days[day]}
              >
                {t(`common.week-days.week-days-word.${day}`)}
              </DayItem>
            ))}
          </Row>
          <Row justify="space-between" align="center">
            <Row justify="center" align="center">
              <UntillText width="50%">{t('trip-overview.repeat.title-until')}</UntillText>
              <DateTimePicker
                onChange={onChangeDateUntil}
                renderView={(mode, renderDefault) => (
                  <RdtUntilDatePickerWrapper data-cy="repeat-date">
                    {renderDefault()}
                  </RdtUntilDatePickerWrapper>
                )}
                isValidDate={isValidEndDate}
                renderInput={UntilDatePickerInput}
                placeholder="Select Date"
                dateFormat="ddd, MMM DD"
                initialVal={data.end}
                value={data.end}
              />
            </Row>
          </Row>
        </Col>
      )}
    </>
  );
};

FrequencyPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.shape({
    count: PropTypes.shape({
      depart: PropTypes.number,
      return: PropTypes.number,
    }),
    enabled: PropTypes.bool,
    days: PropTypes.object,
    end: PropTypes.string,
  }).isRequired,
  isValidEndDate: PropTypes.func.isRequired,
};

export default FrequencyPicker;
