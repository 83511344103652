/** @format */

import styled from 'styled-components';
import { Row, Button, Col, IconButton, Modal } from '@components';
import { theme } from '@constants';

export const Wrapper = styled.div`
  background-color: ${theme.WHITE};
  margin: 0;
  font-family: Inter;
  ${props => (props.largeScreen ? 'padding: 20px' : '')}
`;

export const TopRow = styled(Row)`
  background-color: ${theme.BASE_COLOR};
  height: 60px;
  ${props => (props.largeScreen ? 'border-radius: 6px' : '')}
`;

export const Heading = styled.span`
  background-color: ${theme.BASE_COLOR};
  color: ${theme.WHITE};
  font-size: 18px;
  font-weight: 600;
`;

export const RedeemBtn = styled(Button)`
  width: 35%;
  border-radius: 4px;
  background-color: ${props => props.bgColor};
  font-weight: 600;
  font-size: 14px;
  opacity: 1;
`;

export const TextSmall = styled.span`
  opacity: ${props => props.opacity || 1};
  font-weight: ${props => (props.bold ? 600 : 400)};
  font-size: 12px;
  margin: ${props => props.margin || 0};
  ${props => (props.alignCenter ? 'text-align: center;' : '')}
  ${props => (props.cursor ? 'cursor: pointer;' : '')}
`;

export const LevelBonus = styled(Col)`
  width: 40%;
  border-radius: 4px;
  background-color: ${props => props.color};
  font-weight: 600;
  font-size: 14px;
`;

export const TextStats = styled.span`
  font-weight: 600;
  font-size: 14px;
  margin: ${props => props.margin || 0};
`;

export const TextRedeem = styled.span`
  opacity: 0.85;
  font-weight: 400;
  font-size: 11px;
  margin-right: 10px;
  margin-top: 5px;
`;

export const BackIcon = styled(IconButton)`
  margin: 12px 3px 0 20px;
  cursor: pointer;
`;

export const RedeemModal = styled(Modal)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 8px;
  position: absolute;
  left: 12px;
  top: 234px;
  background: #FFFFFF;
  border-radius: 24px;
`;
