/** @format */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { WorkplaceSelectionForm as CWorkplaceSelectionForm } from '@components';
import {
  updateSelectedWorkplace,
  trackAbEvent,
} from '@slices/app';
import { analytics } from '@constants';

const styles = {
  name: {
    style: 'font-size: 16px; font-family: "Inter"; font-style: normal; font-weight: 400;',
  },
  label: {
    style: 'font-size:12px; font-family: "Inter"; font-style: normal; font-weight: 400;',
  },
};

const WorkplaceSelectionForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [selectedWorkplace, setSelectedWorkplace] = useState();
  const {
    largeScreen,
    organizationPlaces,
    updateWorkplaceLoading,
    organizations,
    selectedOrganization,
  } = useSelector(store => store.app);
  const firstLoggedIn = localStorage.getItem('firstLoggedIn') === 'true';

  useEffect(() => {
    if (
      organizationPlaces &&
      Array.isArray(organizationPlaces) &&
      organizationPlaces.length
    ) {
      const workplaces = organizationPlaces.filter(place => place.type === 'WORKPLACE');
      setOptions(workplaces.map(p => {
        return {
          id: p.orgPlaceId,
          name: (
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: `<div>
                  <div style=${styles.name.style}>${p.name}</div> 
                  <div style=${styles.label.style}>${p.label}</div>
                </div>`,
              }}
            />
          ),
          label: p.label,
          value: `${p.orgPlaceId}`,
        };
      }));
    }
  }, [organizationPlaces]);

  useEffect(() => {
    if (
      organizations &&
      Array.isArray(organizations) &&
      (selectedOrganization || selectedOrganization === 0)
    ) {
      const { workplaceId } = organizations[selectedOrganization] || {};
      workplaceId && setSelectedWorkplace(`${workplaceId}`);
    }
  }, [organizations, selectedOrganization]);

  const onSelect = value => {
    if (updateWorkplaceLoading) return;
    setSelectedWorkplace(value);
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.SELECT_WORKPLACE,
        subJourneyName: 'Select Workplace Form',
        journeyName: 'Rider Experience',
        details: {
          selectedWorkplaceId: value,
        },
      }),
    );
  };

  const onConfirm = async () => {
    // eslint-disable-next-line radix
    await dispatch(updateSelectedWorkplace(parseInt(selectedWorkplace)));
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.CONFIRM_WORKPLACE,
        subJourneyName: 'Select Workplace Form',
        journeyName: 'Rider Experience',
        details: {
          selectedWorkplaceId: selectedWorkplace,
        },
      }),
    );
    if (firstLoggedIn) localStorage.setItem('firstLoggedIn', false);
    history.push('/');
  };

  return (
    <CWorkplaceSelectionForm
      largeScreen={largeScreen}
      loading={updateWorkplaceLoading}
      options={options}
      selectedWorkplace={selectedWorkplace}
      onSelect={onSelect}
      onConfirm={onConfirm}
      firstLoggedIn={firstLoggedIn}
    />
  );
};
export default WorkplaceSelectionForm;
