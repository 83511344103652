/** @format */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { QuickLinks } from '@components';
import { styles } from '@constants';
import PlanATrip from './planATrip';
import { HomeCol, TopWrapperCol, BottomWrapperCol } from './styled';

const HomeSmall = ({
  user,
  onClickGoals,
  isVisible,
  openSendReferralCodeDialog,
  isReferralEnabled,
}) => {
  const { t } = useTranslation();
  const [homeDisplay, setHomeDisplay] = useState(true);

  useEffect(() => {
    if (!isVisible) {
      setTimeout(() => {
        if (!isVisible) {
          setHomeDisplay(false);
        }
      }, styles.ANIM_PLACE_CARD_SLIDE_UP_TIME / 1000);
    } else {
      setHomeDisplay(true);
    }
  }, [isVisible]);

  return (
    <HomeCol
      justify="space-between"
      align="stretch"
      isVisible={isVisible}
      homeDisplay={homeDisplay}
    >
      <TopWrapperCol flex="initial" justify="flex-start" align="center">
        {/* <UserStats />
        <RideFilters />

        <NotificationPopup
          userName="John Doe"
          message="Quick Message Here, expand to take survey, etc"
        /> */}
      </TopWrapperCol>

      <BottomWrapperCol flex="initial" justify="flex-start" align="center">
        <PlanATrip user={user} data-cy="plan-a-trip-link" t={t} />
        <QuickLinks
          onClickGoals={onClickGoals}
          user={user}
          openSendReferralCodeDialog={openSendReferralCodeDialog}
          isReferralEnabled={isReferralEnabled}
        />
      </BottomWrapperCol>
    </HomeCol>
  );
};

HomeSmall.propTypes = {
  user: PropTypes.object,
  onClickGoals: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  openSendReferralCodeDialog: PropTypes.func.isRequired,
  isReferralEnabled: PropTypes.bool.isRequired,
};

HomeSmall.defaultProps = {
  user: null,
};

export default HomeSmall;
