/** @format */
import React, { useState, useRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Collapsible from 'react-collapsible';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import arrowLeft from '@assets/icons/arrow-left.svg';
import { theme, hints, generals as generalConstants } from '@constants';
import { Col, Row, IconButton } from '@components';
import { useLocalStorage, useSessionStorage } from '@hooks';
import {
  TripOptionsColumn,
  TripsWrapper,
  TitleRow,
  Title,
  CollapsibleIcon,
  ReturnTrips,
  ConfirmButton,
  Tooltip,
  ConfirmButtonColumn,
  BackIcon,
} from './styled';
import TripTypeTabs from './tripTypeTabs';

const TripOptions = ({
  trips,
  selectedTrip,
  onSelectShareTrip,
  onSelectTransitTrip,
  onSelectTransitShareTrip,
  isReturnTrip,
  onClickConfirm,
  enableConfirm,
  largeScreen,
  tripTypeFilterDepart,
  tripTypeFilterReturn,
  query,
  fixedRoutes,
  organizations,
  selectedOrganization,
  goToHome,
}) => {
  const { t } = useTranslation();
  let tabsToFilter = ['SHARE'];
  const { organization } = (organizations || [])[(selectedOrganization || 0)] || {};
  const { detail: orgDetails } = organization;
  const workforceSearch = process.env.REACT_APP_WORKFORCE_APP === '1';
  const { tripRequestTypes, transit } = orgDetails || {};
  if (transit && transit.enabled && Array.isArray(tripRequestTypes) && tripRequestTypes.length) {
    tabsToFilter = tripRequestTypes;
  }

  let initialTripTypeFilter = generalConstants.TRIP_FILTER_TYPE_SHARE;
  if (!tabsToFilter.includes('SHARE') && tabsToFilter.includes('TRANSIT')) {
    initialTripTypeFilter = generalConstants.TRIP_FILTER_TYPE_TRANSIT;
  } else if (!tabsToFilter.includes('SHARE') && tabsToFilter.includes('TRANSIT_SHARE')) {
    initialTripTypeFilter = generalConstants.TRIP_FILTER_TYPE_TRANSIT_SHARE;
  }

  const departShareTripsExist = trips.share.depart.length > 0;
  const returnShareTripsExist = trips.share.return.length > 0;

  const departTransitShareTripsExist = trips.transitShare.depart?.length > 0;
  const returnTransitShareTripsExist = trips.transitShare.return?.length > 0;

  const departTransitTripsExist = trips.transit.depart?.length > 0;
  const returnTransitTripsExist = trips.transit.return?.length > 0;

  const departTripsExist =
    departShareTripsExist ||
    departTransitTripsExist ||
    departTransitShareTripsExist;
  const returnTripsExist =
    returnShareTripsExist ||
    returnTransitTripsExist ||
    returnTransitShareTripsExist;

  const departExistWithoutTransit =
    departShareTripsExist || departTransitShareTripsExist;
  const returnExistWithoutTransit =
    returnShareTripsExist || returnTransitShareTripsExist;

  const checkIfAnyTripSelected = () => {
    if (departShareTripsExist && selectedTrip.share.depart !== null) {
      return true;
    }
    if (returnShareTripsExist && selectedTrip.share.return !== null) {
      return true;
    }
    if (departTransitShareTripsExist && selectedTrip.transitShare.depart !== null) {
      return true;
    }
    if (returnTransitShareTripsExist && selectedTrip.transitShare.return !== null) {
      return true;
    }
    return false;
  };

  let confirmBtnVisibility =
    tripTypeFilterDepart !== generalConstants.TRIP_FILTER_TYPE_TRANSIT ||
    checkIfAnyTripSelected();

  if (isReturnTrip && !confirmBtnVisibility) {
    confirmBtnVisibility =
      tripTypeFilterReturn !== generalConstants.TRIP_FILTER_TYPE_TRANSIT;
  }
  if (confirmBtnVisibility && !departExistWithoutTransit && !returnExistWithoutTransit) {
    confirmBtnVisibility = false;
  }

  const [seenHints, setSeenHints] = useLocalStorage('seenHints');
  const [tripTypeFilters, setTripTypeFilters] = useSessionStorage(
    'tripTypeFilters',
    [],
  );

  const returnTripsRef = useRef(null);
  const departingTripRef = useRef(null);
  const departTripTypeFilterRef = useRef(null);
  const returnTripRef = useRef(null);
  const returnTripTypeFilterRef = useRef(null);
  const confirmBtnRef = useRef(null);

  const [departTripTypeFilter, setDepartTripTypeFilter] = useState(
    tripTypeFilterDepart || initialTripTypeFilter,
  );
  const [returnTripTypeFilter, setReturnTripTypeFilter] = useState(
    tripTypeFilterReturn || initialTripTypeFilter,
  );
  const [confirmVisible, setConfirmVisible] = useState(confirmBtnVisibility);
  const isConfirmEnabled =
    !enableConfirm ||
    (departTransitTripsExist &&
      departTripTypeFilter === generalConstants.TRIP_FILTER_TYPE_TRANSIT &&
      returnTransitTripsExist &&
      returnTripTypeFilter === generalConstants.TRIP_FILTER_TYPE_TRANSIT
    );

  const hintsVisible = useMemo(() => {
    return !seenHints.tripOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!seenHints.tripOptions && departTripsExist) {
      setSeenHints({ ...seenHints, tripOptions: true });
    }

    if (hintsVisible && departTripsExist) {
      Tooltip.show(departTripTypeFilterRef.current);
    }

    return () => {
      Tooltip.hide();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectDepartTrip = index => {
    onSelectShareTrip(index, 'depart');

    if (returnTripsExist && returnTripsRef && returnTripsRef.current) {
      returnTripsRef.current.scrollIntoView &&
        returnTripsRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });

      if (hintsVisible) {
        Tooltip.hide(departingTripRef.current);

        setTimeout(() => {
          Tooltip.show(returnTripTypeFilterRef.current);
        }, 300);
      }
    } else if (hintsVisible) {
      Tooltip.hide(departingTripRef.current);
      Tooltip.show(confirmBtnRef.current);
    }
  };

  const selectReturnTrip = index => {
    onSelectShareTrip(index, 'return');
    if (hintsVisible) {
      Tooltip.hide(returnTripRef.current);
      Tooltip.show(confirmBtnRef.current);
    }
  };

  const renderTrigger = (title, disabled) => {
    return (
      <TitleRow justify="space-between" align="center">
        <Title>{title}</Title>
        {!disabled && <CollapsibleIcon icon={faAngleDown} />}
      </TitleRow>
    );
  };

  const handleClickConfirm = () => {
    onClickConfirm();

    if (hintsVisible) {
      Tooltip.hide(confirmBtnRef.current);
    }
  };

  const handleTripTypeSelect = (filterType, type) => {
    if (!type) return;

    let visible = false;
    let setTripTypeFltr = setDepartTripTypeFilter;
    let tripsExist = departTripsExist;
    let tripTypeFilterRef = departTripTypeFilterRef;
    let shareTripsExists = departShareTripsExist;
    let tripRef = departingTripRef;
    if (type === 'return') {
      setTripTypeFltr = setReturnTripTypeFilter;
      tripsExist = returnTripsExist;
      tripTypeFilterRef = returnTripTypeFilterRef;
      shareTripsExists = returnShareTripsExist;
      tripRef = returnTripRef;

      if (departExistWithoutTransit || returnExistWithoutTransit) {
        visible = !(
          filterType === generalConstants.TRIP_FILTER_TYPE_TRANSIT &&
          departTripTypeFilter === generalConstants.TRIP_FILTER_TYPE_TRANSIT
        );
      }
    } else if (departExistWithoutTransit || returnExistWithoutTransit) {
      visible =
        filterType !== generalConstants.TRIP_FILTER_TYPE_TRANSIT ||
        checkIfAnyTripSelected();
      if (isReturnTrip && !visible) {
        visible =
          returnTripTypeFilter !== generalConstants.TRIP_FILTER_TYPE_TRANSIT ||
          checkIfAnyTripSelected();
      }
    }

    setConfirmVisible(visible);
    // updating session storage for selected tab
    let tripOpts = [
      {
        previousRoute: 'trip-options',
        filterType,
        type,
      },
    ];

    if (tripTypeFilters) {
      const filteredTripOptions = tripTypeFilters.filter(
        tripOpt => tripOpt.type !== type,
      );

      tripOpts = tripOpts.concat(filteredTripOptions);
    }

    setTripTypeFltr(filterType); // for type selected
    setTripTypeFilters(tripOpts); // filters for all the types

    if (hintsVisible && tripsExist) {
      Tooltip.hide(tripTypeFilterRef.current);
    }

    if (
      hintsVisible &&
      shareTripsExists &&
      filterType === generalConstants.TRIP_FILTER_TYPE_SHARE
    ) {
      setTimeout(() => {
        Tooltip.show(tripRef.current);
      }, 300);
    }
  };

  const getSelectTripFunction = type => {
    if (type === 'depart') return selectDepartTrip;
    if (type === 'return') return selectReturnTrip;
    if (type === 'transit') return onSelectTransitTrip;
    if (type === 'transit_share') return onSelectTransitShareTrip;
    return undefined;
  };

  const renderTabs = type => {
    const isDepart = type === 'depart';

    const tabsRef = isDepart
      ? departTripTypeFilterRef
      : returnTripTypeFilterRef;
    const tripRef = isDepart ? departingTripRef : returnTripRef;
    const dataTip = t(
      isDepart
        ? hints.tripOptions.departTripTypeFilter
        : hints.tripOptions.returnTripTypeFilter,
    );
    const onSelect = handleTripTypeSelect;
    const selected = isDepart ? departTripTypeFilter : returnTripTypeFilter;

    return (
      <TripTypeTabs
        tabsRef={tabsRef}
        tripRef={tripRef}
        selected={selected}
        onSelect={onSelect}
        trips={trips}
        selectedTrip={selectedTrip}
        query={query}
        getSelectTripFunction={getSelectTripFunction}
        type={type}
        data-tip={dataTip}
        data-event="custom"
        data-scroll-hide="true"
        data-for="trip-options-tooltip"
        id={`${type}-type-filter-tabs`}
        fixedRoutes={fixedRoutes}
        tabsToFilter={tabsToFilter}
      />
    );
  };

  const renderCollapsible = type => {
    return (
      <Collapsible
        open
        trigger={renderTrigger(
          t(`trip-options.labels.${type}-trips`),
          type === 'depart' ? !departTripsExist : !returnTripsExist,
        )}
        triggerClassName="collapsible-trigger"
        triggerOpenedClassName="collapsible-trigger"
      >
        <Col justify="flex-start" align="flex-start">
          {renderTabs(type)}
        </Col>
      </Collapsible>
    );
  };

  return (
    <TripOptionsColumn justify="flex-start" align="flex-start">
      {hintsVisible && (
        <Tooltip
          id="trip-options-tooltip"
          backgroundColor={theme.WEIRD_GREEN}
          textColor={theme.WHITE}
          isCapture
        />
      )}
      <TripsWrapper>
        <Row flex="initial" align={workforceSearch ? 'center' : 'flex-end'} justify={workforceSearch ? 'space-between' : 'flex-end'}>
          {workforceSearch && (
            <BackIcon
              onClick={() => goToHome(false)}
              width={10}
              icon={arrowLeft}
            />
          )}
          <IconButton id="close-button" onClick={() => goToHome(true)} />
        </Row>
        {renderCollapsible('depart')}
        {isReturnTrip && (
          <ReturnTrips ref={returnTripsRef}>
            {renderCollapsible('return')}
          </ReturnTrips>
        )}
      </TripsWrapper>
      {confirmVisible && (
        <ConfirmButtonColumn
          flex="initial"
          spacingH={15}
          spacingV={15}
          align="flex-start"
          justify="flex-end"
          largeScreen={largeScreen ? 1 : 0}
        >
          <ConfirmButton
            btnRef={confirmBtnRef}
            data-tip={t(hints.tripOptions.confirm)}
            data-event="custom"
            data-scroll-hide="true"
            data-for="trip-options-tooltip"
            id="confirm-btn"
            data-cy="confirm-trip"
            label={t('trip-options.buttons.confirm')}
            color={theme.BASE_COLOR}
            onClick={handleClickConfirm}
            disabled={isConfirmEnabled}
            size="md"
          />
        </ConfirmButtonColumn>
      )}
    </TripOptionsColumn>
  );
};

TripOptions.propTypes = {
  onSelectShareTrip: PropTypes.func.isRequired,
  onSelectTransitShareTrip: PropTypes.func.isRequired,
  trips: PropTypes.shape({
    share: PropTypes.shape({
      depart: PropTypes.array.isRequired,
      return: PropTypes.array.isRequired,
    }),
    transit: PropTypes.shape({
      depart: PropTypes.array.isRequired,
      return: PropTypes.array.isRequired,
    }),
    transitShare: PropTypes.shape({
      depart: PropTypes.array.isRequired,
      return: PropTypes.array.isRequired,
    }),
  }).isRequired,
  isReturnTrip: PropTypes.bool.isRequired,
  selectedTrip: PropTypes.object.isRequired,
  onClickConfirm: PropTypes.func.isRequired,
  enableConfirm: PropTypes.bool.isRequired,
  onSelectTransitTrip: PropTypes.func.isRequired,
  largeScreen: PropTypes.bool.isRequired,
  tripTypeFilterDepart: PropTypes.string,
  tripTypeFilterReturn: PropTypes.string,
  query: PropTypes.object.isRequired,
  fixedRoutes: PropTypes.array,
  organizations: PropTypes.array.isRequired,
  selectedOrganization: PropTypes.number.isRequired,
  goToHome: PropTypes.func.isRequired,
};

TripOptions.defaultProps = {
  tripTypeFilterDepart: generalConstants.TRIP_FILTER_TYPE_SHARE,
  tripTypeFilterReturn: generalConstants.TRIP_FILTER_TYPE_SHARE,
  fixedRoutes: [],
};

export default TripOptions;
