/** @format */

// eslint-disable-next-line
import React from 'react';
import { useSelector } from 'react-redux';

const usePayingOrganization = () => {
  const organizations = useSelector(store => store.app.organizations);

  const getOrganizationName = orgId => {
    if (!orgId || !organizations) return null;

    const organizationUser = organizations.find(
      o => o.organization.id === orgId,
    );

    return organizationUser && organizationUser.organization.name;
  };

  return [getOrganizationName];
};

export default usePayingOrganization;
