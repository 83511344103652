/** @format */

import styled from 'styled-components';
import { theme } from '@constants';
import { styles as styleUtils } from '@utils';
import { Row, Col, Button, IconButton, MultiSelect } from '..';
import { colors } from './config';

export const Wrapper = styled.div`
  padding: 20px;
  font-family: 'Inter';
  background-color: ${theme.WHITE};

  ${props =>
    props.largeScreen &&
    `position: absolute; 
     height: 400px; 
     z-index: 100; 
     top: ${props.headerHeight}px; 
     width: 25%; 
     border-radius: 0px 0px 24px 24px;
     border-width: 0px 1px 1px 0px;`};
`;

export const TypeTabsRow = styled(Row)`
  border-radius: 100px;
  background-color: ${styleUtils.hexToRgba(theme.BASE_COLOR, 0.2)};
  position: relative;
  padding: 2px;
`;

export const ActiveTabIndicator = styled.div`
  transition: left 0.2s ease-in-out;
  width: 50%;
  border-radius: 100px;
  background-color: ${theme.BASE_COLOR};
  height: 36px;
  position: absolute;
  margin: 0 4px;
  left: ${props => `${props.selectedIndex * 48}%`};
  z-index: 0;
`;

export const SelectTab = styled.button`
  width: 100%;
  padding: 0;
  border: none;
  background-color: ${theme.TRANSPARENT};
  :focus {
    outline: none;
  }
`;

export const TabTypeCol = styled(Col)`
  border-radius: 100px;
  height: 40px;
`;

export const TabTypeText = styled.span`
  color: ${props => (props.selected ? theme.WHITE : theme.BLACK)};
  position: relative;
  z-index: 6;
`;

export const DynamicText = styled.span`
  font-size: ${props => props.fontSize || 14}px;
  font-weight: ${props => (props.bold ? 600 : 400)};
  opacity: ${props => props.opacity || 1};
  color: ${props => props.color || theme.BLACK};
  background-color: ${props => props.bgColor || theme.WHITE};
  ${props => props.width && `width: ${props.width}px`};
  ${props => props.height && `height: ${props.height}px`};
  margin: ${props => props.margin || 0};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const WorkplaceText = styled(DynamicText)`
  width: 100%;
`;

export const Btn = styled(Button)`
  border-radius: 5px;
  font-weight: 600;
  opacity: ${props => (props.disabled ? 0.3 : 1)};
`;

export const SkewCol = styled(Col)`
  background-color: ${theme.BASE_COLOR};
  overflow: hidden;
  margin-right: 10px;
  width: 150px;
  height: 45px;
`;

export const SkewRow = styled(Row)`
  max-width: 150px;
  height: 45px;
  transform: skewX(-20deg);
  background-color: ${colors.skewRowColor};
  margin-left: -30px;
`;

export const TextSmall = styled.span`
  opacity: ${props => props.opacity || 1};
  font-weight: ${props => (props.bold ? 600 : 400)};
  font-size: 12px;
  margin: ${props => props.margin || 0};
  ${props => (props.color ? `color: ${props.color};` : '')}
  ${props => (props.alignCenter ? 'text-align: center;' : '')}
  ${props => (props.cursor ? 'cursor: pointer;' : '')}
`;

export const BackIcon = styled(IconButton)`
  margin: 12px 3px 0 0;
  cursor: pointer;
`;

export const Pickup = styled(MultiSelect)`
  font-family: 'Inter';
  width: 100%;

  .react-select__control {
    font-family: 'Inter';
  }

  .react-select__single-value {
    font-size: 14px;
    width: 100%;
    height: 100%;
    font-family: 'Inter';
    ${props => props.spacingTop ? 'margin-top: 8px;' : ''}
  }

  .react-select__placeholder {
    font-family: 'Inter';
    color: ${theme.LIGHTEN_GRAY};
    font-size: 14px;
  }

  .react-select__option--is-disabled {
    opacity: 0.5;
  }
  
  .react-select__option--is-selected {
    background-color: ${theme.WHITE};
  }

  .react-select__option {
    font-family: 'Inter';
    background-color: ${theme.WHITE};
    font-size: 12px;
    color: ${theme.BLACK};
    ${props =>
      !props.isRoundTrip &&
      `&:first-child {
        border-bottom: 1px solid ${colors.dropdownBorder};
        margin-top: 10px;
    }`}

    &:hover {
      color: ${theme.BLACK};
      background-color: ${theme.LIGHTEN_GRAY};
    }
  }
`;

export const Dropoff = styled(Pickup)`
  .react-select__control {
    z-index: 8;
    font-family: 'Inter';
  }
  .react-select__menu {
    z-index: 7;
  }
`;

export const Shifts = styled(Pickup)`
  width: 100%;

  .react-select__control {
    min-height: 42px;
    border: 1px solid ${colors.lightGray};
  }
  
  .react-select__single-value {
    font-size: 14px;
    width: 100%;
    height: 100%;
    font-family: 'Inter';
    margin-top: 7px;
  }
`;
