/** @format */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CheckboxContainer,
  StyledCheckbox,
  HiddenCheckbox,
  Icon,
  CheckBoxLabel,
} from './styled';

const Checkbox = ({ label = '', largeScreen, onChange, value, inverted }) => {
  const [checked, setCheckbox] = useState(value);
  useEffect(() => {
    setCheckbox(value);
  }, [value]);
  const onChangeValue = () => {
    onChange(!checked);
    setCheckbox(!checked);
  };

  return (
    <>
      <CheckboxContainer onClick={onChangeValue}>
        <HiddenCheckbox />
        <StyledCheckbox largeScreen={largeScreen} checked={checked}>
          <Icon viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </Icon>
        </StyledCheckbox>
        <CheckBoxLabel inverted={inverted} id="checkbox-label">{label}</CheckBoxLabel>
      </CheckboxContainer>
    </>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  largeScreen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  inverted: PropTypes.bool,
};

Checkbox.defaultProps = {
  label: '',
  inverted: false,
};

export default Checkbox;
