/** @format */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from "lodash";
import CryptoJS, { AES } from 'crypto-js';

import { FullScreenLoader } from '@components';
import { getMyCompletedTrip } from '@slices/myTrips';
import { getInvites, passwordlessAuth, getUser } from '@slices/auth';
import { getPaymentMethods, getSquareUpCards } from '@slices/paymentMethods';
import { getFixedRoutesByOrgId } from '@slices/fixedRoute';
import {
  Small as ShellSmall,
  Large as ShellLarge,
  SmallWorkforce as ShellSmallWorkforce,
  LargeWorkforce as ShellLargeWorkforce,
} from '@routes';
import {
  trackAbEvent,
  fetchOrgDetails,
  fetchOrgPlaces,
  setLargeScreen,
  getRidersList,
  fetchSignupOrganizations,
  setPasswordlessResourceLoading,
  updateSelectedWorkplace,
} from '@slices/app';
import {
  styles as styleUtils,
  userHelpers,
  helpers,
  tripHelpers,
  socket as socketUtils,
} from '@utils';
import {
  useHeaderHeight,
  useQuery,
  useStateResetOnPasswordlessAuthUrlChange,
  useMessageDialog,
  useSessionStorage,
} from '@hooks';
import { generals as generalConstants, socketEvents, localStorageKeys, analytics } from '@constants';

const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
const workforceEnv = process.env.REACT_APP_WORKFORCE_APP === '1';

const appStateKeys = [
  'largeScreen',
  'user',
  'selectedOrganization',
  'organizations',
  'userAgent',
  'riders',
  'fullScreenLoading',
  'workforceRiderApp',
];
const socket = socketUtils.getSocket(true);
const useSelectorEqualityCheckForAppState = (appState, prevAppState) =>
  helpers.areObjectKeysEqual(appStateKeys, appState, prevAppState);

const Shell = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [headerHeight] = useHeaderHeight();
  const { t } = useTranslation();
  const { MessageDialog, openMessageDialog } = useMessageDialog();
  const query = useQuery();
  const location = useLocation();
  const [, , removeSearchData] = useSessionStorage('workforceTripRequestPayload');
  const isAccountSettings = location.pathname === '/account-settings';
  const queryParams = helpers.parseQueryString(window.location.search);

  useEffect(() => {
    window.addEventListener('beforeunload', removeSearchData);
    return () => {
      window.removeEventListener('beforeunload', removeSearchData);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (tripHelpers.checkExistingQueryParams(queryParams)) {
    const query = queryParams;
    query.saved = false;
    if (
      !_.isEqual(
        query,
        JSON.parse(localStorage.getItem(localStorageKeys.SOURCE)),
      )
    ) {
      localStorage.setItem(localStorageKeys.SOURCE, JSON.stringify(query));
    }
  }

  const ridershipEnrolled = localStorage.getItem('ridershipEnrolled');
  if (!ridershipEnrolled && queryParams.ridershipEnroll) {
    const email = AES.decrypt(queryParams.ridershipEnroll, encryptionKey).toString(CryptoJS.enc.Utf8);
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.RIDERSHIP_BONUS_START_EARNING_CLICKED,
        subJourneyName: 'Ridership Bonus',
        journeyName: 'Rider Experience',
        details: {
          email,
        },
      }),
    );
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.RIDERSHIP_BONUS_ENROLL_SUCCESS,
        subJourneyName: 'Ridership Bonus',
        journeyName: 'Rider Experience',
        details: {
          email,
        },
      }),
    );
    localStorage.setItem('ridershipEnrolled', 'true');
  }

  const passwordlessToken =
    query.get('access-token') ||
    sessionStorage.getItem('passwordlessAuthToken');
  const {
    largeScreen,
    user,
    selectedOrganization,
    organizations,
    userAgent,
    riders,
    fullScreenLoading,
    workforceRiderApp,
    passwordlessAuthenticationType,
    organizationPlaces,
  } = useSelector(state => state.app, useSelectorEqualityCheckForAppState);

  const { passwordlessResourceLoading, restrictedMode } = useSelector(state => state.app);

  useEffect(() => {
    !isAccountSettings &&
      openMessageDialog(true, {
        heading: t('messages.success.restriction.heading'),
        bodyText: t('messages.success.restriction.body'),
        closeButton: {
          title: t('controls.buttons.continue'),
          onPress: () => history.push('/account-settings'),
        },
        closeDisabled: true,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAccountSettings]);

  const startPasswordlessActivity = type => {
    switch (type) {
      case generalConstants.PASSWORDLESS_ACTIVITY.rateRide:
        dispatch(
          getMyCompletedTrip(history, false, () =>
            dispatch(setPasswordlessResourceLoading(false)),
          ),
        );
        break;
      default:
        dispatch(setPasswordlessResourceLoading(false));
    }
  };

  const authenticatePasswordless = () => {
    const token = query.get('access-token');
    if (token && !user && !userAgent) {
      dispatch(
        passwordlessAuth(token, {
          onSuccess: startPasswordlessActivity,
          onFailure: () => history.replace('/'),
        }),
      );
    } else {
      dispatch(setPasswordlessResourceLoading(false));
    }
  };

  useStateResetOnPasswordlessAuthUrlChange(authenticatePasswordless);

  // this hook will be called when there is a change in selected organization
  const userId = user?.id;
  const userStatus = user?.status;
  const userSelectedOrg = user?.detail?.selectedOrg;
  useEffect(() => {
    dispatch(fetchOrgPlaces());
    dispatch(fetchOrgDetails());

    socket.on(
      socketEvents.UPDATED_ORGANIZATION_DETAILS,
      (data) => {
        if (
          Array.isArray(organizations) &&
          organizations.length &&
          selectedOrganization !== null &&
          organizations[selectedOrganization] &&
          organizations[selectedOrganization].organizationId === data.id
        ) {
          dispatch(fetchOrgDetails());
        }
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedOrganization, userId, userStatus, userSelectedOrg]);

  useEffect(() => {
    const hideModal = generalConstants.MODALS_TO_SHOW_BEFORE_WORKPLACE_SELECTION.includes(location.pathname);

    if (
      workforceEnv &&
      Array.isArray(organizationPlaces) &&
      organizationPlaces.length &&
      Array.isArray(organizations) &&
      organizations.length &&
      selectedOrganization !== null &&
      organizations[selectedOrganization] &&
      !hideModal
    ) {
      const {
        organizationId,
        workplaceId: selectedWorkplace,
        organization,
      } = organizations[selectedOrganization] || {};
      let workplaces = [];
      workplaces = organizationPlaces.filter(p => p.type === 'WORKPLACE' && p.organizationId === organizationId);

      if (organization && organization.parentId) {
        const { parentId } = organization || {};
        const parentOrgWorkplaces = organizationPlaces.filter(p => p.type === 'WORKPLACE' && p.organizationId === parentId);
        if (parentOrgWorkplaces.length) workplaces = workplaces.concat(parentOrgWorkplaces);
      }

      if (
        workplaces.length > 1 &&
        (
          !selectedWorkplace ||
          !workplaces.find(w => w.orgPlaceId === selectedWorkplace)
        )
      ) {
        history.push('/select-workplace');
      } else if (workplaces.length === 1) {
        dispatch(updateSelectedWorkplace(workplaces[0].orgPlaceId, false));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, organizationPlaces, location.pathname]);

  useEffect(() => {
    if (user && !passwordlessAuthenticationType) {
      dispatch(getInvites());
      if (
        Array.isArray(organizations) &&
        selectedOrganization !== null &&
        selectedOrganization < organizations.length
      ) {
        if (
          organizations[selectedOrganization].organization.paymentOption ===
          'STRIPE'
        ) {
          dispatch(getPaymentMethods());
        } else {
          dispatch(getSquareUpCards());
        }
      }
      const shouldNotOpenRateRide = sessionStorage.getItem(
        'should-not-open-rate-ride',
      );
      const openInviteFriendsAfterFirstLoginOrSignup = JSON.parse(
        localStorage.getItem('openInviteFriendsAfterFirstLoginOrSignup'),
      );

      if (openInviteFriendsAfterFirstLoginOrSignup) {
        sessionStorage.setItem('should-not-open-rate-ride', true);
      }
      if (
        !shouldNotOpenRateRide &&
        !openInviteFriendsAfterFirstLoginOrSignup
      ) {
        dispatch(getMyCompletedTrip(history));
      }

      if (
        Array.isArray(organizations) &&
        selectedOrganization !== null &&
        selectedOrganization < organizations.length
      ) {
        dispatch(
          getFixedRoutesByOrgId(
            organizations[selectedOrganization].organizationId,
          ),
        );
      }
    } else if (!passwordlessToken && !window.location.href.includes('fixed-route') && !window.location.href.includes('reset-password')) {
      history.push('/signup');
    }

    const updateLargeScreen = () => {
      dispatch(setLargeScreen(styleUtils.isLargeScreen()));
    };

    window.addEventListener('resize', updateLargeScreen);

    return () => {
      window.removeEventListener('resize', updateLargeScreen);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, userId, userStatus, userSelectedOrg, selectedOrganization]);
  useEffect(() => {
    if (
      userAgent &&
      userHelpers.checkUserAgentRole(userAgent.OrganizationUsers) &&
      riders.length === 0
    ) {
      dispatch(getRidersList());
    }
  }, [dispatch, riders.length, userAgent]);

  useEffect(() => {
    const organizationId = sessionStorage.getItem('orgId');
    const referCode = sessionStorage.getItem('referCode');
    if (organizationId && referCode) {
      dispatch(fetchSignupOrganizations(organizationId, referCode));
    } else if (generalConstants.ORG_SIGNUP_IDS.length > 0) {
      dispatch(fetchSignupOrganizations());
    }

    return () => {
      sessionStorage.removeItem(localStorageKeys.SIGNUP_ORGANIZATION);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  if (passwordlessToken && passwordlessResourceLoading) {
    return <FullScreenLoader />;
  }

  const defaultShell = largeScreen ? (
    <ShellLarge
      isAuthenticated={!!user || !!userAgent}
      headerHeight={headerHeight}
    />
  ) : (
    <ShellSmall isAuthenticated={!!user || !!userAgent} />
  );

  const workforceForceShell = largeScreen ? (
    <ShellLargeWorkforce
      isAuthenticated={!!user || !!userAgent}
      headerHeight={headerHeight}
    />
  ) : (
    <ShellSmallWorkforce isAuthenticated={!!user || !!userAgent} />
  );

  return (
    <>
      {workforceRiderApp ? workforceForceShell : defaultShell}
      {fullScreenLoading && <FullScreenLoader />}
      {restrictedMode && <MessageDialog />}
    </>
  );
};

export default Shell;
