/** @format */

import { generals as generalConstants } from '@constants';

export const tabs = [
  {
    type: generalConstants.MY_TRIPS_TYPE.upcoming,
    titleContent: 'my-trips.switch.upcoming',
    id: 'my-upcoming-trips',
  },
  {
    type: generalConstants.MY_TRIPS_TYPE.past,
    titleContent: 'my-trips.switch.past',
    id: 'my-past-trips',
  },
];
