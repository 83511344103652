/** @format */

import { createSlice } from '@reduxjs/toolkit';
import { api, generals as generalConstants, analytics } from '@constants';
import { api as apiClient, toast } from '@utils';
import { trackAbEvent, setUser } from '@slices/app';
import { getTranslatedStringFromKey } from '@i18Loader';

export const defaultState = {
  addedPaymentMethods: [],
  addedSquareCards: null,
  deletePaymentMethodId: null,
  loading: null,
  status: null,
  selectedPaymentMethod: -1,
};

const PaymentMethodsSlice = createSlice({
  name: 'paymentMethods',
  initialState: defaultState,
  reducers: {
    setAddedPaymentMethods(state, action) {
      const { payload: addedPaymentMethods } = action;

      return {
        ...state,
        addedPaymentMethods,
      };
    },
    setAddedSquareCards(state, action) {
      const { payload: addedSquareCards } = action;
      return {
        ...state,
        addedSquareCards,
      };
    },
    setLoading(state, action) {
      const { payload: loading } = action;

      return {
        ...state,
        loading,
      };
    },
    setDeletePaymentMethodId(state, action) {
      const { payload: deletePaymentMethodId } = action;

      return {
        ...state,
        deletePaymentMethodId,
      };
    },
    setSelectedPaymentMethod(state, action) {
      const { payload: selectedPaymentMethod } = action;

      return {
        ...state,
        selectedPaymentMethod,
      };
    },
    setStatus(state, action) {
      const { payload: status } = action;

      return {
        ...state,
        status,
      };
    },
  },
});

const {
  setAddedPaymentMethods,
  setAddedSquareCards,
  setLoading,
  setDeletePaymentMethodId,
  setSelectedPaymentMethod,
  setStatus,
} = PaymentMethodsSlice.actions;

export const getPaymentMethods = () => {
  return async dispatch => {
    dispatch(setLoading(generalConstants.GET_PAYMENT_METHODS_LOADING));

    try {
      const response = await apiClient.get(api.endpoints.GET_PAYMENT_METHODS);
      dispatch(setAddedPaymentMethods(response.data));

      dispatch(setSelectedPaymentMethod(response.data.length > 0 ? 0 : -1));
      dispatch(setLoading(null));
    } catch (e) {
      dispatch(setLoading(null));
    }
  };
};

export const attachPaymentMethod = (paymentMethodId, goToTripOverview) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(generalConstants.ATTACH_PAYMENT_METHOD_LOADING));

    try {
      const data = { paymentMethodId };
      const response = await apiClient.post(
        api.endpoints.ATTACH_PAYMENT_METHOD,
        data,
      );

      const { addedPaymentMethods } = getState().paymentMethods;

      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.SAVE_PAYMENT_METHOD_BUTTON,
          subJourneyName: 'Payments',
          journeyName: 'Rider Experience',
          details: {
            data: response.data,
          },
        }),
      );
      dispatch(setAddedPaymentMethods([response.data, ...addedPaymentMethods]));
      dispatch(setSelectedPaymentMethod(0));

      dispatch(setLoading(null));

      goToTripOverview();

      toast.show('sucess', getTranslatedStringFromKey('messages.payment-method.added'));
    } catch (e) {
      dispatch(setLoading(null));
    }
  };
};

export const detachPaymentMethod = () => {
  return async (dispatch, getState) => {
    dispatch(setLoading(generalConstants.DETACH_PAYMENT_METHODS_LOADING));

    try {
      const { deletePaymentMethodId: paymentMethodId } =
        getState().paymentMethods;

      const data = { paymentMethodId };
      await apiClient.post(api.endpoints.DETACH_PAYMENT_METHOD, data);

      const { addedPaymentMethods } = getState().paymentMethods;

      const index = addedPaymentMethods.findIndex(
        o => o.id === paymentMethodId,
      );
      const array = [...addedPaymentMethods];
      array.splice(index, 1);
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.DELETE_PAYMENT_METHOD_BUTTON,
          subJourneyName: 'Payments',
          journeyName: 'Rider Experience',
          details: {
            paymentMethodId,
          },
        }),
      );
      dispatch(setAddedPaymentMethods(array));
      dispatch(setLoading(null));

      toast.show('sucess', getTranslatedStringFromKey('messages.payment-method.removed'));
    } catch (e) {
      dispatch(setLoading(null));
    }
  };
};

export const getSquareUpCards = () => {
  return async dispatch => {
    dispatch(setLoading(generalConstants.GET_SQUARE_CARDS_LIST));

    try {
      const response = await apiClient.get(api.endpoints.GET_SQUARE_CARDS);
      dispatch(setAddedSquareCards(response.data));
      dispatch(setSelectedPaymentMethod(response.data.length > 0 ? 0 : -1));
      dispatch(setLoading(null));
    } catch (e) {
      dispatch(setLoading(null));
    }
  };
};

export const createNewSquareCard = (cardInfo) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(generalConstants.ADD_SQUARE_CARD_LOADING));
    try {
      const response = await apiClient.post(api.endpoints.ADD_SQUARE_CARD, cardInfo);
      const { addedSquareCards } = getState().paymentMethods;
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.SAVE_PAYMENT_METHOD_BUTTON,
          subJourneyName: 'Payments',
          journeyName: 'Rider Experience',
          details: {
            data: response.data,
          },
        }),
      );
      dispatch(setAddedSquareCards([response.data, ...addedSquareCards]));
      dispatch(setSelectedPaymentMethod(0));
      dispatch(setLoading(null));
      toast.show('sucess', getTranslatedStringFromKey('messages.payment-method.added'));
    } catch (e) {
      dispatch(setLoading(null));
    }
  };
};

export const deleteSquareCard = () => {
  return async (dispatch, getState) => {
    const { deletePaymentMethodId: cardId } = getState().paymentMethods;
    dispatch(setLoading(generalConstants.DELETE_SQUARE_CARD_LOADING));
    try {
      await apiClient.post(api.endpoints.DELETE_SQUARE_CARD, { cardId });
      const { addedSquareCards } = getState().paymentMethods;

      const index = addedSquareCards.findIndex(
        o => o.id === cardId,
      );
      const array = [...addedSquareCards];
      array.splice(index, 1);
      dispatch(setAddedSquareCards(array));
      dispatch(setSelectedPaymentMethod(0));
      dispatch(setLoading(null));

      toast.show('sucess', getTranslatedStringFromKey('messages.payment-method.removed'));
    } catch (e) {
      dispatch(setLoading(null));
    }
  };
};

export const loadCreditViaPaymentMethod = ({
  paymentMethodId,
  amount,
  platform,
}) => {
  return async (dispatch, getState) => {
    const { user, selectedOrganization, organizations } = getState().app;
    dispatch(setLoading(generalConstants.LOADING_CREDITS_VIA_PAYMENT_METHOD));
    try {
      const response = await apiClient.post(
        api.endpoints.LOAD_CREDIT_VIA_PAYMENT_METHOD.replace(':orgId',
          (organizations || [])[selectedOrganization]?.organizationId),
        {
          paymentMethodId,
          userId: user.id,
          amount,
          tripRequestId: null,
          type: 'LOADED_VIA_PAYMENT_OPTION',
          organizationId: (organizations || [])[selectedOrganization]?.organizationId,
        },
      );
      toast.show('success', getTranslatedStringFromKey('load-credits.credit-load-success'));
      dispatch(setLoading(null));
      dispatch(setStatus(generalConstants.LOADING_CREDITS_VIA_PAYMENT_METHOD_SUCCESS));
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.LOAD_CREDIT_SUCCESS,
          subJourneyName: 'Payments',
          journeyName: 'Rider Experience',
          details: {
            data: response.data,
          },
        }),
      );
      const balance =
        parseFloat(user?.balance || 0) +
        parseFloat(
          parseFloat(amount || 0) -
            (amount
              ? amount *
                  generalConstants.GATEWAY_FEES[
                    platform || generalConstants.PLATFORMS.STRIPE
                  ].fee +
                  generalConstants.GATEWAY_FEES[
                    platform || generalConstants.PLATFORMS.STRIPE
                  ].charge || 0
              : 0),
        );
      dispatch(
        setUser({ ...user, balance: balance.toFixed(2) }),
      );
      localStorage.setItem('user', JSON.stringify({ ...user, balance: balance.toFixed(2) }));
    } catch {
      dispatch(setLoading(null));
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.LOAD_CREDIT_FAILED,
          subJourneyName: 'Payments',
          journeyName: 'Rider Experience',
        }),
      );
    }
  };
};

export {
  setAddedPaymentMethods,
  setAddedSquareCards,
  setLoading,
  setDeletePaymentMethodId,
  setSelectedPaymentMethod,
  setStatus,
};
export const paymentMethodsReducer = PaymentMethodsSlice.reducer;
