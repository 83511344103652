/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Row, IconButton } from '..';
import { Title, SubTitle, Wrapper, Image, OuterWrapper } from './styled';

const OrganizationWelcome = ({ onClose, subtitle, title, logo }) => {
  const { t } = useTranslation();
  return (
    <OuterWrapper>
      <Row
        align="flex-end"
        justify="flex-end"
        flex={0}
        spacingH={12}
        spacingV={12}
      >
        <IconButton id="close-org-welcome" width={25} onClick={onClose} />
      </Row>
      <Wrapper justify="center" align="center">
        <Row align="center" justify="center">
          <Image src={logo} />
        </Row>
        <Row justify="center" align="center">
          <Title>{title}</Title>
        </Row>

        <Row justify="center" align="center" spacingV={20} spacingH={20}>
          <SubTitle>{t(subtitle)}</SubTitle>
        </Row>
      </Wrapper>
    </OuterWrapper>
  );
};

OrganizationWelcome.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  logo: PropTypes.string,
};

OrganizationWelcome.defaultProps = {
  title: null,
  subtitle: null,
  logo: null,
};

export default OrganizationWelcome;
