/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';

import { Input } from '..';
import { RdtPickerWrapper, DateTimePicker as SDateTimePicker } from './styled';

const DateTimePicker = ({
  dateFormat,
  timeFormat,
  placeholder,
  renderInput,
  renderView,
  onChange,
  isValidDate,
  onOpen,
  initialVal,
  value,
}) => {
  return (
    <SDateTimePicker
      dateFormat={dateFormat}
      closeOnSelect
      timeFormat={timeFormat}
      renderInput={renderInput}
      renderView={renderView}
      {...(onOpen ? { onOpen } : {})}
      onChange={onChange}
      isValidDate={isValidDate}
      initialValue={moment(initialVal)}
      value={value || moment(initialVal)}
      locale={i18n.language}
      inputProps={{
        placeholder,
      }}
      timeConstraints={{
        minutes: {
          step: 5,
        },
      }}
    />
  );
};

DateTimePicker.propTypes = {
  dateFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  timeFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  renderInput: PropTypes.func,
  renderView: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isValidDate: PropTypes.func,
  onOpen: PropTypes.func,
  initialVal: PropTypes.string,
  value: PropTypes.string,
};

DateTimePicker.defaultProps = {
  onOpen: null,
  dateFormat: null,
  timeFormat: null,
  initialVal: `${moment().format('YYYY-MM-DD HH')}:00:00`,
  isValidDate: () => true,
  renderView: (mode, renderDefault) => (
    <RdtPickerWrapper>{renderDefault()}</RdtPickerWrapper>
  ),
  renderInput: (inputProps, openCalendar) => {
    return (
      <Input
        label={inputProps.placeholder}
        inverted
        value={inputProps.value}
        onFocus={openCalendar}
        readOnly
        paddingX={0}
        autoComplete="off"
      />
    );
  },
  value: null,
};

export default DateTimePicker;
