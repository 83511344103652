/** @format */

import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import throttle from 'lodash.throttle';
import debounce from 'lodash.debounce';
import { FixedRoutePublicMap as ComponentFixedRoutePublicMap } from '@components';
import { setCurrentLocation } from '@slices/app';
import {
  setSearchQuery,
  resetSearchAddresses,
  setSelectedResult,
  setSelectedFixedRoute,
  searchRoutes as searchFixedRoutes,
  getFixedRouteByRouteKey,
  fetchAddresses,
  setMinimized,
  setCardVisible,
} from '@slices/fixedRoute';
import { socketEvents } from '@constants';
import moment from 'moment';
import { socket as socketUtils, toast, placeHelpers } from '@utils';

const FixedRoutePublicMap = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { routeKey } = useParams();
  const [vehicleLocation, setVehicleLocation] = useState({
    lat: null,
    lng: null,
  });

  const socket = socketUtils.getSocket(true);

  const { currentLocation, largeScreen } = useSelector(store => store.app);
  const { search, loading, selectedFixedRoute, fixedRoutes } = useSelector(
    state => state.fixedRoute,
  );

  const throttledLocationUpdateHandler = throttle(data => {
    const { lat, lng } = data;

    setVehicleLocation({
      lat,
      lng,
    });
  }, 5000);

  useEffect(() => {
    const onError = () => {
      history.push('/');
    };

    dispatch(getFixedRouteByRouteKey(routeKey, onError));

    navigator.geolocation.getCurrentPosition(pos => {
      dispatch(setCurrentLocation([pos.coords.latitude, pos.coords.longitude]));
    });

    return () => {
      dispatch(setSelectedFixedRoute(null));
      socket.off(socketEvents.DRIVER_LOCATION_UPDATED);
    };
    // eslint-disable-next-line
  }, [dispatch, history, routeKey]);

  useEffect(() => {
    if (selectedFixedRoute) {
      socket.emit(socketEvents.SUBSCRIBE_TO_ROUTE, {
        routeId: selectedFixedRoute.id,
      });
    }
    // eslint-disable-next-line
  }, [selectedFixedRoute]);

  useEffect(() => {
    socket.on(
      socketEvents.DRIVER_LOCATION_UPDATED,
      throttledLocationUpdateHandler,
    );
    // eslint-disable-next-line
  }, []);

  const onChangeFixedRoute = ({ value }) => {
    if (selectedFixedRoute) {
      socket.emit(socketEvents.UNSUBSCRIBE_TO_ROUTE, {
        routeId: selectedFixedRoute.id,
      });
      setVehicleLocation({
        lat: null,
        lng: null,
      });
    }

    const fr = fixedRoutes.find(fr => fr.id === value);
    dispatch(setSelectedFixedRoute(fr));
    dispatch(setSelectedResult(null));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFetchAddresses = useCallback(
    debounce((name, searchQuery) => {
      dispatch(fetchAddresses(name, searchQuery));
    }, 500),
    [],
  );

  const onChange = e => {
    const { name, value } = e.target;

    dispatch(
      setSearchQuery({
        key: name,
        value,
      }),
    );

    if (['pickup', 'dropoff'].includes(name)) {
      debounceFetchAddresses(name, value);
    }
  };

  const getAddresses = name => {
    const query = search.query[name].label || search.query[name];
    debounceFetchAddresses(name, query);
  };

  const onSelectDate = date => {
    dispatch(
      setSearchQuery({
        key: 'date',
        value: date.format('MM/DD/YYYY'),
      }),
    );
  };

  const onSelectTime = time => {
    dispatch(
      setSearchQuery({
        key: 'time',
        value: time.format('hh:mm A'),
      }),
    );
  };

  const onToggleSearchAll = () => {
    dispatch(
      setSearchQuery({
        key: 'searchInAllFixedRoutes',
        value: !search.query.searchInAllFixedRoutes,
      }),
    );
  };

  const onToggleType = () => {
    let type = 'Arrive By';
    if (search.query.type === 'Arrive By') {
      type = 'Ready By';
    }

    dispatch(
      setSearchQuery({
        key: 'type',
        value: type,
      }),
    );
  };

  const handleSubmit = () => {
    dispatch(searchFixedRoutes());
  };

  const onChangeResult = selectedIndex => {
    const selectedFixedRouteResult = search.results[selectedIndex];
    if (selectedFixedRouteResult && selectedFixedRouteResult.fixedRouteId) {
      const index = fixedRoutes.findIndex(
        o => o.id === selectedFixedRouteResult.fixedRouteId,
      );
      dispatch(setSelectedResult(selectedIndex));
      dispatch(setSelectedFixedRoute(fixedRoutes[index]));
    }
  };

  const clearAddresses = () => {
    dispatch(resetSearchAddresses());
  };

  const onAddressClick = (key, selected) => {
    const address = search[`${key}Suggestions`][selected];

    const otherAddress =
      key === 'pickup' ? search.query.dropoff : search.query.pickup;

    // if other address is defined, check for same addresses
    if (otherAddress?.lat) {
      const isSame = placeHelpers.areSamePlaces(address, otherAddress);

      if (isSame) {
        toast.show(
          'error',
          'Same location selected for both pickup and dropoff',
        );
        return;
      }
    }

    dispatch(
      setSearchQuery({
        key,
        value: address,
      }),
    );

    clearAddresses();
  };

  const onPickupSelected = selected => {
    onAddressClick('pickup', selected);
  };

  const onDropoffSelected = selected => {
    onAddressClick('dropoff', selected);
  };

  const onClear = key => {
    dispatch(
      setSearchQuery({
        key,
        value: '',
      }),
    );
    clearAddresses();
  };

  const toggleMinimized = () => {
    dispatch(setMinimized(!search.minimized));
  };

  const toggleCardVisible = () => {
    dispatch(setCardVisible(!search.cardVisible));
  };

  const goToHome = () => {
    history.push('/');
  };

  const onOpenPicker = () => {
    if (search.query.time) {
      return;
    }

    const time = moment(`${moment().format('YYYY-MM-DD HH')}:00:00`);
    dispatch(
      setSearchQuery({
        key: 'time',
        value: time.format('hh:mm A'),
      }),
    );
  };

  if (!selectedFixedRoute) {
    return null;
  }

  const disabled =
    !search.query.time ||
    !search.query.date ||
    !search.query.type ||
    !search.query.pickup ||
    !search.query.dropoff ||
    !search.query.pickup.lat ||
    !search.query.dropoff.lat ||
    !selectedFixedRoute;

  return (
    <ComponentFixedRoutePublicMap
      fixedRoute={selectedFixedRoute}
      fixedRoutes={fixedRoutes}
      onChangeFixedRoute={onChangeFixedRoute}
      currentLocation={currentLocation}
      vehicleLocation={vehicleLocation}
      largeScreen={largeScreen}
      onChange={onChange}
      state={search}
      loading={loading}
      disabled={disabled}
      onSelectDate={onSelectDate}
      onSelectTime={onSelectTime}
      onToggleSearchAll={onToggleSearchAll}
      onToggleType={onToggleType}
      handleSubmit={handleSubmit}
      onChangeResult={onChangeResult}
      onClear={onClear}
      getAddresses={getAddresses}
      onPickupSelected={onPickupSelected}
      onDropoffSelected={onDropoffSelected}
      clearAddresses={clearAddresses}
      goToHome={goToHome}
      toggleMinimized={toggleMinimized}
      toggleCardVisible={toggleCardVisible}
      onOpenPicker={onOpenPicker}
    />
  );
};

export default FixedRoutePublicMap;
