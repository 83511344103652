/** @format */

import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useSessionStorage } from '@hooks';
import { theme, hints, generals as generalConstants } from '@constants';

import {
  Row,
  Col,
  Input,
  Row as HeaderRow,
  Switch,
  DateTimePicker,
  Checkbox,
} from '@components';
import {
  CloseIcon,
  HeaderTitle,
  InputWrapper,
  InputLabel,
  AddressInput,
  FindRoutesBtn,
  RdtDatePickerWrapper,
  FormCol,
  TextSmall,
  RdtTimePickerWrapper,
  DropdownStyled,
  DatePickerCol,
  Tooltip,
} from './styled';

const FixedRouteSearchFormSmall = ({
  onChange,
  loading,
  disabled,
  state,
  onSelectDate,
  onSelectTime,
  onToggleSearchAll,
  onToggleType,
  handleSubmit,
  onClear,
  clearAddresses,
  getAddresses,
  onPickupSelected,
  onDropoffSelected,
  goToHome,
  disableFixedRoutesDropdown,
  dropdownOptions,
  dropdownValue,
  onChangeFixedRoute,
  onOpenPicker,
}) => {
  const { t } = useTranslation();
  const [seenHints, setSeenHints] = useSessionStorage('fixedRouteHints');

  const pickupAddressRef = useRef(null);
  const destinationAddressRef = useRef(null);
  const dateInputRef = useRef(null);
  const checkboxRef = useRef(null);
  const dropdownRef = useRef(null);
  const timeInputRef = useRef(null);
  const typeSwitchRef = useRef(null);
  const submitBtnRef = useRef(null);

  useEffect(() => {
    if (!seenHints) {
      Tooltip.show(pickupAddressRef.current);
    }

    return () => {
      Tooltip.hide();
    };
  }, [seenHints]);

  const handlePickupAddressClick = i => {
    onPickupSelected(i);

    if (!seenHints) {
      Tooltip.hide(pickupAddressRef.current);
      Tooltip.show(destinationAddressRef.current);
    }
  };

  const handleDestinationAddressClick = i => {
    onDropoffSelected(i);

    if (!seenHints) {
      Tooltip.hide(destinationAddressRef.current);
      Tooltip.show(typeSwitchRef.current);
    }
  };

  const handleTypeChange = checked => {
    onToggleType(checked);

    if (!seenHints) {
      Tooltip.hide(typeSwitchRef.current);
      Tooltip.show(dateInputRef.current);
    }
  };

  const handleDateInputChange = value => {
    onSelectDate(value);

    Tooltip.hide(dateInputRef.current);
    Tooltip.show(timeInputRef.current);
  };

  const handleTimeInputChange = value => {
    onSelectTime(value);

    if (!seenHints) {
      Tooltip.hide(timeInputRef.current);
      Tooltip.show(dropdownRef.current);
    }
  };

  const handleDropdownChange = value => {
    onChangeFixedRoute(value);

    if (!seenHints) {
      Tooltip.hide(dropdownRef.current);
      Tooltip.show(checkboxRef.current);
    }
  };

  const handleCheckBoxChange = value => {
    onToggleSearchAll(value);

    if (!seenHints) {
      Tooltip.hide(checkboxRef.current);
      Tooltip.show(submitBtnRef.current);
    }
  };

  const onSubmit = () => {
    handleSubmit();

    if (!seenHints) {
      Tooltip.hide(submitBtnRef.current);
      setSeenHints('fixedRouteHints', true);
    }
  };

  const renderDateInput = (inputProps, openCalendar) => {
    return (
      <Input
        data-cy="fixed-route-date-input"
        id="fixed-route-date-input"
        inputRef={dateInputRef}
        data-tip={t(hints.fixedRouteSmallHints.date)}
        data-event="custom"
        data-for="fixed-route-tooltip"
        label={inputProps.placeholder}
        inverted
        bordered
        onFocus={openCalendar}
        value={state.query.date}
        readOnly
        autoComplete="off"
      />
    );
  };

  const renderTimeInput = (inputProps, openCalendar) => {
    return (
      <Input
        data-cy="fixed-route-time-input"
        id="fixed-route-time-input"
        inputRef={timeInputRef}
        data-tip={t(hints.fixedRouteSmallHints.time)}
        data-event="custom"
        data-for="fixed-route-tooltip"
        label={inputProps.placeholder}
        inverted
        bordered
        onFocus={openCalendar}
        value={state.query.time}
        readOnly
        autoComplete="off"
      />
    );
  };

  return (
    <Col justify="flex-start" align="center" spacingV={15} spacingH={30}>
      <Tooltip
        id="fixed-route-tooltip"
        backgroundColor={theme.WEIRD_GREEN}
        textColor={theme.WHITE}
        isCapture
      />
      <Row align="flex-end" justify="flex-end">
        <CloseIcon
          data-cy="fixed-route-close-btn"
          onClick={goToHome}
          size="lg"
          color={theme.BASE_COLOR}
          icon={faTimes}
          inverted
        />
      </Row>
      <HeaderRow justify="space-between" align="center" spacingV={10}>
        <HeaderTitle>{t('fixed-route.small-screen.labels.title')}</HeaderTitle>

        <Switch
          data-cy="is-arrive-by-switch"
          switchRef={typeSwitchRef}
          data-tip={t(hints.fixedRouteSmallHints.toggle)}
          data-event="custom"
          data-for="fixed-route-tooltip"
          id="is-arrive-by-switch"
          checked={state.query.type === 'Arrive By'}
          onChange={handleTypeChange}
          unCheckedText={t('fixed-route.small-screen.switch.labels.ready-by')}
          checkedText={t('fixed-route.small-screen.switch.labels.arrive-by')}
          width={144}
          height={20}
          bgColor={theme.LIGHT_GREY}
          triggerColor={theme.BASE_COLOR}
        />
      </HeaderRow>

      <FormCol
        minimized={state.minimized}
        flex="initial"
        justify="flex-start"
        align="center"
      >
        <Row justify="space-between" align="center">
          <InputWrapper justify="flex-start" align="flex-start">
            <InputLabel>
              {t('fixed-route.small-screen.fields.pickup.label')}
            </InputLabel>
            <AddressInput
              data-cy="fixed-route-pickup-input"
              id="fixed-route-pickup-input"
              inputProps={{
                inputRef: pickupAddressRef,
                'data-tip': t(hints.fixedRouteSmallHints.pickupAddress),
                'data-event': 'custom',
                'data-for': 'fixed-route-tooltip',
                name: 'pickup',
                onChange,
                onFocus: () => {
                  getAddresses('pickup');
                },
                value: state.query.pickup.label || state.query.pickup,
                label: t('fixed-route.small-screen.fields.pickup.placeholder'),
                color: theme.BASE_COLOR,
                inverted: true,
                bordered: true,
                onClear: () => {
                  onClear('pickup');
                },
              }}
              suggestions={state.pickupSuggestions}
              onSuggestionClick={handlePickupAddressClick}
              clearSuggestions={clearAddresses}
            />
          </InputWrapper>
        </Row>
        <Row justify="flex-start" align="center">
          <InputWrapper justify="flex-start" align="flex-start">
            <InputLabel>
              {t('fixed-route.small-screen.fields.destination.label')}
            </InputLabel>
            <AddressInput
              data-cy="fixed-route-dropoff-input"
              id="dropoff-input"
              inputProps={{
                inputRef: destinationAddressRef,
                'data-tip': t(hints.fixedRouteSmallHints.destinationAddress),
                'data-event': 'custom',
                'data-for': 'fixed-route-tooltip',
                name: 'dropoff',
                onChange,
                onFocus: () => {
                  getAddresses('dropoff');
                },
                value: state.query.dropoff.label || state.query.dropoff,
                label: t(
                  'fixed-route.small-screen.fields.destination.placeholder',
                ),
                color: theme.BASE_COLOR,
                inverted: true,
                bordered: true,
                onClear: () => {
                  onClear('dropoff');
                },
              }}
              suggestions={state.dropoffSuggestions}
              onSuggestionClick={handleDestinationAddressClick}
              clearSuggestions={clearAddresses}
            />
          </InputWrapper>
        </Row>
        <Row justify="space-between" align="flex-start" spacingV={10}>
          <DatePickerCol justify="flex-start" align="flex-start">
            <TextSmall>{t('trip-date-time-picker.date')}</TextSmall>
            <DateTimePicker
              placeholder={t('trip-date-time-picker.select-date')}
              dateFormat="ddd, MMM DD"
              renderView={(mode, renderDefault) => (
                <RdtDatePickerWrapper>{renderDefault()}</RdtDatePickerWrapper>
              )}
              renderInput={renderDateInput}
              onChange={handleDateInputChange}
            />
          </DatePickerCol>
          <Col justify="flex-start" align="flex-start">
            <TextSmall>{t('trip-date-time-picker.time')}</TextSmall>
            <DateTimePicker
              onChange={handleTimeInputChange}
              placeholder="Time"
              value={state.query.time}
              onOpen={onOpenPicker}
              timeFormat="hh:mm A"
              renderView={(mode, renderDefault) => (
                <RdtTimePickerWrapper>{renderDefault()}</RdtTimePickerWrapper>
              )}
              renderInput={renderTimeInput}
            />
          </Col>
        </Row>
        <Row justify="space-between" align="flex-start" spacingV={10}>
          <div
            style={{ width: '100%' }}
            ref={dropdownRef}
            data-tip={t(hints.fixedRouteSmallHints.dropdown)}
            data-event="custom"
            data-for="fixed-route-tooltip"
          >
            <DropdownStyled
              id="fixed-routes-dropdown-large"
              value={!disableFixedRoutesDropdown ? t(
                'fixed-route.large-screen.fields.search-all',
              ) : dropdownValue}
              options={dropdownOptions}
              onChange={handleDropdownChange}
              disabled={!disableFixedRoutesDropdown}
              inverted
              size="sm"
            />
          </div>
        </Row>
        <Row justify="space-between" align="flex-start" spacingV={10}>
          <div
            ref={checkboxRef}
            data-tip={t(hints.fixedRouteSmallHints.checkbox)}
            data-event="custom"
            data-for="fixed-route-tooltip"
          >
            <Checkbox
              label="Search in all Fixed Routes"
              value={state.query.searchInAllFixedRoutes}
              largeScreen={false}
              onChange={handleCheckBoxChange}
            />
          </div>
        </Row>
        <FindRoutesBtn
          data-cy="find-routes-btn"
          id="fixed-route-submit-btn"
          btnRef={submitBtnRef}
          data-tip={t(hints.fixedRouteSmallHints.submitBtn)}
          data-event="custom"
          data-for="fixed-route-tooltip"
          onClick={onSubmit}
          label={t('fixed-route.small-screen.buttons.find-routes.label')}
          color={theme.BASE_COLOR}
          icon={
            loading === generalConstants.SEARCH_FIXED_ROUTES_LOADING
              ? faSpinner
              : null
          }
          iconProps={{ spin: true }}
          disabled={
            disabled || loading === generalConstants.SEARCH_FIXED_ROUTES_LOADING
          }
        />
      </FormCol>
    </Col>
  );
};

FixedRouteSearchFormSmall.propTypes = {
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  state: PropTypes.shape({
    query: PropTypes.shape({
      date: PropTypes.string,
      time: PropTypes.string,
      searchInAllFixedRoutes: PropTypes.bool,
      pickup: PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.string,
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
        PropTypes.string,
      ]),
      dropoff: PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.string,
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
        PropTypes.string,
      ]),
      type: PropTypes.string,
    }).isRequired,
    minimized: PropTypes.bool,
    pickupSuggestions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
      }),
    ).isRequired,
    dropoffSuggestions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
  onSelectDate: PropTypes.func.isRequired,
  onSelectTime: PropTypes.func.isRequired,
  onToggleSearchAll: PropTypes.func.isRequired,
  onToggleType: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  getAddresses: PropTypes.func.isRequired,
  clearAddresses: PropTypes.func.isRequired,
  onPickupSelected: PropTypes.func.isRequired,
  onDropoffSelected: PropTypes.func.isRequired,
  disableFixedRoutesDropdown: PropTypes.bool.isRequired,
  dropdownOptions: PropTypes.array.isRequired,
  dropdownValue: PropTypes.string.isRequired,
  onChangeFixedRoute: PropTypes.func.isRequired,
  goToHome: PropTypes.func.isRequired,
  onOpenPicker: PropTypes.func.isRequired,
};

FixedRouteSearchFormSmall.defaultProps = {
  loading: null,
};

export default FixedRouteSearchFormSmall;
