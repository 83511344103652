/** @format */

import React from 'react';
import { PropTypes } from 'prop-types';
import closeIcon from '@assets/icons/close.svg';
import { TagContainer, SIconButton, Tag as STag } from './styled';

const Tag = ({ text, onRemoveTag, largeScreen }) => {
  return (
    <TagContainer>
      <STag>{text}</STag>
      <SIconButton
        data-cy="remove-email-btn"
        icon={closeIcon}
        width={largeScreen ? 15 : 12}
        onClick={onRemoveTag}
      />
    </TagContainer>
  );
};

Tag.propTypes = {
  text: PropTypes.string.isRequired,
  onRemoveTag: PropTypes.func.isRequired,
  largeScreen: PropTypes.bool.isRequired,
};

export default Tag;
