/** @format */

import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Polyline, Marker, useMap, Popup } from 'react-leaflet';
import { TimeRow, TextAlign, BoldText } from './styled';
import {
  markerIcon,
  polylineOptions,
  vehicleMarkerIcon,
  polylineOptionsForFixedRouteSearchWalkPath,
  pinMarkerIcon,
  homeMarkerIcon,
  polylineOptionsForFixedRouteSearchPath,
} from '../../config';

const getMapBoundsForFixedRoute = path =>
  path && path.map(item => [item.lat, item.lng]);

const FixedRoutePath = ({ fixedRoute, vehicleLocation, searchResultSteps }) => {
  const map = useMap();
  let polylineOptionsStyled = polylineOptions;
  const mapBounds =
    fixedRoute.path && getMapBoundsForFixedRoute(fixedRoute.path);
  useEffect(() => {
    map.fitBounds(mapBounds);
    return () => {
      if (polylineOptionsStyled) {
        polylineOptionsStyled.color = '#000';
      }
    };
  }, [map, mapBounds, polylineOptionsStyled]);

  if (fixedRoute.pathColor) {
    polylineOptionsStyled = {
      ...polylineOptionsStyled,
      color: fixedRoute.pathColor.trim(),
    };
  }

  const renderPath = () => {
    return (
      fixedRoute.path &&
      fixedRoute.path.map((stop, i) => {
        if (
          i === fixedRoute.path.length - 1 &&
          fixedRoute.edges !== 'Connected'
        ) {
          return null;
        }

        return stop.pathToNextStop?.length > 1 ? (
          <Polyline
            key={`polyline-${i}-${stop.id}`}
            id={`fixed-route-path-polyline-${stop.id}`}
            positions={stop.pathToNextStop}
            pathOptions={polylineOptionsStyled}
          />
        ) : null;
      })
    );
  };

  const renderSearchResultStepsPath = () => {
    return searchResultSteps.map((step, i) => {
      return step.path?.length > 1 && step.action === 'walk' ? (
        <Polyline
          key={`polyline-selected-${i}`}
          id={`fixed-route-search-result-walk-path-polyline-${i}`}
          positions={step.path}
          pathOptions={polylineOptionsForFixedRouteSearchWalkPath}
          pane="markerPane"
        />
      ) : null;
    });
  };

  const renderSearchResultRoute = () => {
    return searchResultSteps.map((step, i) => {
      return step.path?.length > 1 && step.action === 'board' ? (
        <Polyline
          key={`polyline-selected-${i}`}
          id={`fixed-route-search-result-path-polyline-${i}`}
          positions={step.path}
          pathOptions={polylineOptionsForFixedRouteSearchPath}
          pane="markerPane"
        />
      ) : null;
    });
  };

  const renderSearchResultMarkers = () => {
    const searchResultFirstStep = searchResultSteps[0].path[0];
    const lastSearchResultIndex = searchResultSteps.length - 1;
    const lastSearchResultPathLastIndex =
      searchResultSteps[lastSearchResultIndex].path.length - 1;
    const searchResultLastStep =
      searchResultSteps[lastSearchResultIndex].path[
        lastSearchResultPathLastIndex
      ];

    if (!searchResultFirstStep || !searchResultLastStep) return null;

    return [
      <Marker
        key="fixed-route-search-result-pickup"
        id="fixed-route-search-result-pickup"
        icon={pinMarkerIcon}
        position={searchResultFirstStep}
        pane="markerPane"
      />,
      <Marker
        key="fixed-route-search-result-dropoff"
        id="fixed-route-search-result-dropoff"
        icon={homeMarkerIcon}
        position={searchResultLastStep}
        pane="markerPane"
      />,
    ];
  };

  return (
    <>
      {fixedRoute.path && renderPath()}
      {searchResultSteps.length > 0 && renderSearchResultRoute()}
      {searchResultSteps.length > 0 && renderSearchResultStepsPath()}

      {fixedRoute.path &&
        fixedRoute.path.map((stop, idx) => {
          return (
            stop.visible && (
              <Marker
                key={`marker-${idx}-${stop.id}`}
                id={`fixed-route-marker-${stop.id}`}
                icon={markerIcon}
                position={[stop.lat, stop.lng]}
                title={stop.seq}
              >
                <Popup>
                  {(stop.nextAvailableTimes &&
                    stop.nextAvailableTimes.length && (
                      <>
                        {' '}
                        <BoldText title="Location Name">{stop.name}</BoldText>
                        <div>
                          {stop.nextAvailableTimes.map((item, index) => (
                            <TimeRow key={index}>{item.time}</TimeRow>
                          ))}
                        </div>
                      </>
                    )) || (
                    <>
                      <BoldText title="Location Name">{stop.name}</BoldText>
                      <TextAlign>No Available Times</TextAlign>
                    </>
                  )}
                </Popup>
              </Marker>
            )
          );
        })}

      {vehicleLocation.lat && vehicleLocation.lng && (
        <Marker
          id="fixed-route-vehcile"
          icon={vehicleMarkerIcon}
          position={[vehicleLocation.lat, vehicleLocation.lng]}
        />
      )}
      {searchResultSteps.length > 0 && renderSearchResultMarkers()}
    </>
  );
};

FixedRoutePath.propTypes = {
  fixedRoute: PropTypes.shape({
    pathColor: PropTypes.string,
    path: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        lat: PropTypes.number,
        lng: PropTypes.number,
        seq: PropTypes.number,
        pathToNextStop: PropTypes.array,
      }),
    ),
    edges: PropTypes.string.isRequired,
  }).isRequired,
  vehicleLocation: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
  searchResultSteps: PropTypes.array.isRequired,
};

export default FixedRoutePath;
