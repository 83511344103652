import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { TripOptionsWorkforce as CTripOptionsWorkforce, TripOptionsWorkforceSmall } from '@components';
import { fetchTripRequests, setSearchSelection, getTripDetails } from '@slices/tripRequestWorkforce';
import { trackEvent } from '@slices/app';
import { analytics } from '@constants';
import moment from 'moment';

const TripOptionsWorkforce = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const { largeScreen } = useSelector(store => store.app);
    const { trips, loading, showUnauthenticated } = useSelector(
        store => store.tripRequestWorkforce,
    );
    const { user, userAgent } = useSelector(store => store.app);
    const isAuthenticated = user || userAgent ? true : false;

    useEffect(() => {
      dispatch(trackEvent(analytics.TRIP_OPTIONS_VIEW, trips));
      // eslint-disable-next-line
    }, [loading, trips]);

    if (!isAuthenticated && !showUnauthenticated) {
        return null;
    }

    const fetchTrips = (page = 0) => {
        dispatch(fetchTripRequests(page));
    };

    const onTripSelect = (tripIndex) => {
        const data = { ...((trips?.share?.data || [])[tripIndex] || {}) };

        // converting to 24 hrs format
        const datetimeSplit = moment(data.arrival).format('MM/DD/YYYY HH:mm:ss').split(' ');
        const payload = {
            date: datetimeSplit[0],
            time: datetimeSplit[1],
            requestType: data.requestType,
            pickupAddress: {
                label: data.places[0].address,
                lat: data.places[0].lat,
                lng: data.places[0].lng,
                name: data.places[0].name,
            },
            destinationAddress: {
                label: data.places[1].address,
                lat: data.places[1].lat,
                lng: data.places[1].lng,
                name: data.places[1].name,
            },
            isReturn: true,
            returnDate: '',
            returnTime: '',
        };
        const analyticsPayload = { ...payload, selectedDate: payload.date, selectedTime: payload.time };
        delete analyticsPayload.time;
        delete analyticsPayload.date;
        dispatch(trackEvent(analytics.TRIP_OPTIONS_SELECT_TRIP, analyticsPayload));
        dispatch(setSearchSelection(payload));
        dispatch(getTripDetails(() => history.push('/trip-overview')));
    };

    return largeScreen ? (
      <CTripOptionsWorkforce
        trips={trips}
        fetchTrips={fetchTrips}
        loading={loading}
        onTripSelect={onTripSelect}
        isAuthenticated={isAuthenticated}
      />
    ) : (
      <TripOptionsWorkforceSmall
        trips={trips}
        fetchTrips={fetchTrips}
        loading={loading}
        onTripSelect={onTripSelect}
        isAuthenticated={isAuthenticated}
      />
    );
};

export default TripOptionsWorkforce;
