/** @format */
import React, { useState, useEffect } from 'react';

const useSessionStorage = (key, defaultValue = '') => {
  const [state, setState] = useState(() => {
    const valueInSessionStorage = sessionStorage.getItem(key);

    if (valueInSessionStorage) {
      return JSON.parse(valueInSessionStorage);
    }

    return typeof defaultValue === 'function' ? defaultValue() : defaultValue;
  });

  const preRef = React.useRef(key);
  const preStateRef = React.useRef(state);

  useEffect(() => {
    const prekey = preRef.current;
    const preState = preStateRef.current;

    if (preState === state && prekey === key) return;
    if (prekey !== key) {
      window.sessionStorage.removeItem(prekey);
    }

    preRef.current = key;
    window.sessionStorage.setItem(key, JSON.stringify(state));
  }, [state, key]);

  const removeField = () => {
    window.sessionStorage.removeItem(key);
  };
  return [state, setState, removeField];
};

export default useSessionStorage;
