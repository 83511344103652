/** @format */

import React from 'react';
import { PropTypes } from 'prop-types';
import { Badge as SBadge } from './styled';

const Badge = ({ children }) => {
  return <SBadge>{children}</SBadge>;
};

Badge.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Badge;
