/** @format */

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { analytics } from '@constants';
import { FullScreen, AccountSettings as CAccountSettings } from '@components';
import { helpers } from '@utils';
import {
  setSelectedOrganization,
  setOrganizationPlaces,
  getNearbyBusStops,
  generateQRCode,
  trackAbEvent,
  updateSelectedWorkplace,
  renderQRPrint,
} from '@slices/app';
import {
  setAccountSettingsAndDynamicFields,
  setAccountSettingsFormState,
  setAccountSettingsSelectedInvite,
  setAccountSettingsDOB,
  update,
  getInvites,
  acceptInvite,
  declineInvite,
  setUserLanguage,
} from '@slices/auth';
import { resetTripRequestState } from '@slices/tripRequest';
import { changeLanguage } from '@i18Loader';

const AccountSettings = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [passwordRestricted, setPasswordRestricted] = useState(false);
  const [workplaces, setWorkplaces] = useState([]);
  const [selectedWorkplace, setSelectedWorkplace] = useState(null);

  const state = useSelector(store => store.auth.accountSettings);
  const {
    restrictedMode,
    dynamicTextFields,
    currentLocation,
    generateQRCodeLoading,
  } = useSelector(store => store.app);

  const {
    user,
    organizations,
    selectedOrganization,
    largeScreen,
    workforceRiderApp,
    organizationPlaces,
    updateWorkplaceLoading,
  } = useSelector(store => store.app);

  const {
    register,
    trigger,
    errors,
    formState: { isValid },
  } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.PROFILE_VIEW,
        subJourneyName: 'Explore Dashboard',
        journeyName: 'Rider Experience',
        details: {
          user,
        },
      }),
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      organizations &&
      Array.isArray(organizations) &&
      organizations.length &&
      (selectedOrganization || selectedOrganization === 0) &&
      selectedOrganization < organizations.length
    ) {
      const { detail, workplaceId } = organizations[selectedOrganization] || {};

      if (!updateWorkplaceLoading && workplaceId) {
        setSelectedWorkplace(`${workplaceId}`);
      }

      if (detail && detail.passwordRestrictionsEnable) {
        setPasswordRestricted(true);
      } else {
        setPasswordRestricted(false);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganization, organizations]);

  useEffect(() => {
    if (
      organizationPlaces &&
      Array.isArray(organizationPlaces) &&
      organizationPlaces.length
    ) {
      setWorkplaces(organizationPlaces.filter(place => place.type === 'WORKPLACE'));
    }
  }, [organizationPlaces]);

  const userId = user?.id;
  const userStatus = user?.status;
  const userSelectedOrg = user?.detail?.selectedOrg;
  useEffect(() => {
    dispatch(getInvites());
    dispatch(setAccountSettingsAndDynamicFields(user));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userId, userStatus, userSelectedOrg]);

  const onSelectOrganization = selected => {
    dispatch(setAccountSettingsSelectedInvite(null));
    if (selected !== selectedOrganization) {
      dispatch(setSelectedOrganization(selected));
      dispatch(
        setAccountSettingsFormState({
          name: 'selectedOrg',
          value: (organizations || [])[selected]?.organizationId,
        }),
      );
      dispatch(resetTripRequestState());
      dispatch(setOrganizationPlaces([]));
      dispatch(update({ organizationUpdated: true, history }));
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.ORGANIZATION_SELECTED,
          subJourneyName: 'Explore Dashboard',
          journeyName: 'Rider Experience',
          elementId: 'organizations-dropdown',
          isDataCy: true,
          details: {
            organizationId:
              (organizations || [])[selected]?.organization?.id ||
              (organizations || [])[selected]?.id,
            organizatioName:
              (organizations || [])[selected]?.organization?.name ||
              (organizations || [])[selected]?.name,
          },
        }),
      );
      dispatch(getNearbyBusStops(currentLocation));
    }
  };

  const onSelectInvite = selected => {
    dispatch(setAccountSettingsSelectedInvite(selected));
  };

  const onInviteAccept = async () => {
    await dispatch(acceptInvite(state.selectedInvite));
    dispatch(resetTripRequestState());
    dispatch(setOrganizationPlaces([]));
  };

  const onInviteDecline = () => {
    dispatch(declineInvite(state.selectedInvite));
  };

  const onSubmit = () => {
    if (!isValid) {
      trigger();
      return;
    }
    dispatch(update());
  };

  const onChangeDay = day => {
    dispatch(
      setAccountSettingsDOB({
        key: 'date',
        value: day.value,
      }),
    );
  };

  const onChangeMonth = month => {
    dispatch(
      setAccountSettingsDOB({
        key: 'month',
        value: month.value,
      }),
    );
  };

  const onChangeYear = year => {
    dispatch(
      setAccountSettingsDOB({
        key: 'year',
        value: year.value,
      }),
    );
  };

  const onChangeSpecialNeeds = selected => {
    if (!selected) {
      dispatch(
        setAccountSettingsFormState({
          name: 'specialNeeds',
          value: [],
        }),
      );
      return;
    }
    const needs = selected.map(need => need.value);
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.SPECIAL_NEEDS,
        subJourneyName: 'Explore Dashboard',
        journeyName: 'Rider Experience',
        details: {
          needs,
        },
      }),
    );

    dispatch(
      setAccountSettingsFormState({
        name: 'specialNeeds',
        value: needs,
      }),
    );
  };

  const onChangeShowSpecialNeed = selected => {
    let showSpecialNeed = selected.value ? selected.value : selected;

    if (showSpecialNeed === 'No' || !showSpecialNeed) {
      dispatch(
        setAccountSettingsFormState({
          name: 'specialNeeds',
          value: [],
        }),
      );
    }

    if (showSpecialNeed !== 'No' && showSpecialNeed !== 'Yes') {
      showSpecialNeed = showSpecialNeed ? 'Yes' : 'No';
    }

    dispatch(
      setAccountSettingsFormState({
        name: 'showSpecialNeed',
        value: showSpecialNeed,
      }),
    );
  };

  const onChange = async e => {
    const { name, value } = e.target;

    if (state.formState[name] === undefined) {
      dispatch(
        setAccountSettingsFormState({
          name: 'detail',
          value: { ...state.formState.detail, [name]: value },
        }),
      );
    } else {
      if (name === 'phone' && value.length > 10) {
        return;
      }
      if (
        name === 'phone' &&
        state.formState.phone.length >= 10 &&
        value.length >= 11
      ) {
        return;
      }

      dispatch(
        setAccountSettingsFormState({
          name,
          value:
            name === 'phone' && value.length <= 10
              ? value.replace(/[^0-9]/g, '')
              : value,
        }),
      );
    }

    await trigger(name);
  };

  const onChangeShowPassword = value => {
    dispatch(
      setAccountSettingsFormState({
        name: 'showPassword',
        value,
      }),
    );
  };

  const getOrganizationsForUser = () => {
    if (!user.OrganizationUsers || user.OrganizationUsers.length === 0) {
      return null;
    }

    const organizationsForUsers = [];
    for (let i = 0; i < user.OrganizationUsers.length; i += 1) {
      if (
        user.OrganizationUsers[i].role === 'User' &&
        user.OrganizationUsers[i].status === 'Active'
      ) {
        organizationsForUsers.push(user.OrganizationUsers[i].organization.name);
      }
    }
    return organizationsForUsers.join(', ');
  };

  const goToHome = () => {
    history.push('/');
  };

  const goToSavedAddresses = () => {
    history.push('/account-settings/addresses');
  };

  const goToPaymentMethods = () => {
    history.push('/payment');
  };

  const goToLoadCredit = () => {
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.LOAD_CREDIT_BUTTON_CLICKED,
        subJourneyName: 'Explore Dashboard',
        journeyName: 'Rider Experience',
      }),
    );
    history.push('/load-credits');
  };

  const onChangeLanguage = language => {
    dispatch(setUserLanguage(language));
    changeLanguage(language);
  };

  const onChangeRiderType = riderType => {
    dispatch(
      setAccountSettingsFormState({
        name: 'riderType',
        value: riderType,
      }),
    );
  };

  const downloadQR = () => {
    helpers.QRDownload(user);
    dispatch(trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: 'Download Boarding Pass',
        subJourneyName: 'Get Your Boarding Pass',
        journeyName: 'Rider Experience',
        elementId: 'download-qr-button',
        isDataCy: true,
        details: {
          user: user.id,
        },
      }));
  };

  const printQR = async () => {
    const userQrCode = user.detail.printQRCode;
    if (userQrCode) {
      renderQRPrint(userQrCode);
    } else {
      generateQR('print (user)');
    }
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: 'Print Boarding Pass',
        subJourneyName: 'Get Your Boarding Pass',
        journeyName: 'Rider Experience',
        elementId: 'print-qr-button',
        isDataCy: true,
      }),
    );
  };

  const generateQR = (channel) => {
    dispatch(generateQRCode(user.id, channel));
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.GENERATE_QR_CODE,
        subJourneyName: 'Explore Dashboard',
        journeyName: 'Rider Experience',
        details: {
          user,
        },
      }),
    );
  };

  const onChangeWorkplace = id => {
    setSelectedWorkplace(id);
    // eslint-disable-next-line radix
    dispatch(updateSelectedWorkplace(parseInt(id)));
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.CHANGE_WORKPLACE,
        subJourneyName: 'Profile',
        journeyName: 'Rider Experience',
        details: {
          selectedWorkplaceId: id,
        },
      }),
    );
  };

  const accountSettings = (
    <CAccountSettings
      state={state.formState}
      addresses={state.addresses}
      loading={state.loading || updateWorkplaceLoading}
      generateQRCodeLoading={generateQRCodeLoading}
      errors={errors}
      validate={register}
      onChange={onChange}
      onSubmit={onSubmit}
      organizations={organizations || []}
      invites={state.invites}
      inviteLoading={state.inviteLoading}
      onInviteAccept={onInviteAccept}
      onInviteDecline={onInviteDecline}
      setOrganization={onSelectOrganization}
      setInvite={onSelectInvite}
      selectedOrganization={selectedOrganization}
      selectedInvite={state.selectedInvite}
      inviteLoaderType={state.inviteLoaderType}
      goToHome={goToHome}
      organizationsForUsers={getOrganizationsForUser()}
      onChangeSpecialNeeds={onChangeSpecialNeeds}
      onChangeDay={onChangeDay}
      onChangeMonth={onChangeMonth}
      onChangeYear={onChangeYear}
      goToSavedAddresses={goToSavedAddresses}
      goToPaymentMethods={goToPaymentMethods}
      balance={user.balance}
      authQRCode={user.authQRCode}
      downloadQR={downloadQR}
      printQR={printQR}
      generateQR={generateQR}
      onChangeShowPassword={onChangeShowPassword}
      onChangeShowSpecialNeed={onChangeShowSpecialNeed}
      points={user.points}
      onChangeLanguage={onChangeLanguage}
      onChangeRiderType={onChangeRiderType}
      workforceRiderApp={workforceRiderApp}
      passwordRestricted={passwordRestricted}
      goToLoadCredit={goToLoadCredit}
      restrictedMode={restrictedMode}
      dynamicTextFields={dynamicTextFields}
      workplaces={workplaces}
      selectedWorkplace={selectedWorkplace}
      onChangeWorkplace={onChangeWorkplace}
      largeScreen={largeScreen}
    />
  );

  return largeScreen ? (
    accountSettings
  ) : (
    <FullScreen id="account-settings-full-screen">{accountSettings}</FullScreen>
  );
};

export default AccountSettings;
