/** @format */

import styled from 'styled-components';
import Div100vh from 'react-div-100vh';
import { styles as styleUtils } from '@utils';

export const Screen = styled(Div100vh)`
  ${styleUtils.flex('flex-start', 'flex-start', 'column')}
  width: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
`;
