/** @format */

import styled from 'styled-components';

import { theme, styles } from '@constants';
import { hideExtraTextLine } from '@utils/helpers/styled';

export const CardContainer = styled.div`
  width: 100%;
  display: block;
  outline: none !important;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  pointer-events: all !important;
`;

export const PlaceImage = styled.img`
  ${props => `
    height: ${props.selected ? '70px' : '50px'};
    transition: height ${
      styles.ANIM_PLACE_CARD_CONTENT_HIDE_SHOW / 1000
    }s ease-in-out;
    width: 98%;
    background: ${theme.WHITE};
    margin-left: auto;
    margin-right: auto;
    border-radius: 13px 13px 0px 0px;
    z-index: 2;
    object-fit: cover;
    -webkit-box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.48);
    -moz-box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.48);
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.48);
`};
`;

export const InfoBlock = styled.div`
  ${props => `
   max-height: ${
     props.selected ? `${styles.ORG_PLACE_CARD_HEIGHT}px` : '135px'
   };
    transition: height ${
      styles.ANIM_PLACE_CARD_CONTENT_HIDE_SHOW / 1000
    }s ease-in-out;
  `}
  min-height: 135px;
  background: ${theme.WHITE};
  width: 100%;
  border-radius: 10px;
  z-index: 3;
  position: relative;
  padding: 12px 15px;
  color: ${theme.BASE_COLOR};
  h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 3px 0;
    ${hideExtraTextLine(2)}
  }
  -webkit-box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.48);
  -moz-box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.48);
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.48);
`;

export const AddressBlock = styled.div`
  display: flex;
  align-items: center;

  p {
    flex: 1;
    margin: 0;
    margin-right: 7px;
    font-size: 10px;
    ${hideExtraTextLine(1)}
  }

  button {
    height: 20px;
    width: 80px;
    border-radius: 16px;
    border: 0;
    background-color: ${theme.BASE_COLOR};
    color: ${theme.WHITE};
    font-size: 10px;
    &:focus {
      outline: none;
    }
  }
`;

export const Line = styled.div`
  background-color: ${theme.BASE_COLOR};
  height: 1px;
  width: 100%;
  margin: 5px 0;
`;

export const PlanCheckItem = styled.div`
  font-size: 10px;
  .check-icon {
    margin-right: 5px;
  }
`;

export const PlanSummaryBlock = styled.div`
  .heading {
    font-size: 12px;
    font-weight: bold;
    margin: 0 0 4px 0;
  }
`;

export const TimingBlock = styled.div`
  .heading {
    font-size: 12px;
    font-weight: bold;
    margin: 4px 0 4px 0;
  }
`;

export const TimingBlockItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 10px;
  margin-top: 4px;
  .day {
    font-weight: ${props => props.shift ? 400 : 700};
    width: 45%;
    margin: 0;
  }
  .time {
    font-weight: ${props => props.shift ? 600 : 500};
    margin: 0;
  }
  .no-time {
    width: 75%;
  }
`;
export const TimingBlockHeadings = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  font-family: 'Inter';
  font-weight: 600;
  font-style: normal;
  margin: 4px 0 -15px 0;
  `;
