/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useLocation } from 'react-router-dom';

import {
  MapWorkforce,
  HomeWorkforce,
  TripOverviewWorkforce,
  TripDetail,
  PaymentContainer,
  AccountSettings,
  Header,
  SignupOrg,
  SavedAddresses,
  OrganizationSchedule,
  RateRide,
  TermsConditions,
  FixedRoutePublicMap,
  Login,
  Signup,
  ForgotPassword,
  ExtraFieldsAndSpecialNeeds,
  TripOptionsWorkforce,
  MyTripsWorkforce
} from '@containers';
import { Screen, ProtectedRoute, Row } from '@components';
import { useSelector } from 'react-redux';
import { SwitchWrapper } from './styled';

const LargeWorkforce = ({ isAuthenticated, headerHeight }) => {
  const location = useLocation();
  const { selectedMarker } = useSelector(
    state => state.tripRequestWorkforce,
  );

  const renderAccountSettings = ({ match: { url } }) => (
    <>
      <ProtectedRoute
        isAllowed={isAuthenticated}
        path={`${url}/`}
        component={AccountSettings}
        restricted
        exact
      />
      <ProtectedRoute
        isAllowed={isAuthenticated}
        path={`${url}/addresses`}
        restricted
        component={SavedAddresses}
      />
    </>
  );

  if (location.pathname.includes('fixed-route/')) {
    return (
      <Screen>
        <Switch>
          <Route
            path="/fixed-route/:routeKey"
            component={FixedRoutePublicMap}
          />
        </Switch>
      </Screen>
    );
  }

  const isSideBarHidden = () => {
    if (
      location.pathname.includes('/org') ||
      location.pathname === '/login' ||
      location.pathname === '/signup' ||
      (location.pathname === '/' && selectedMarker === null)
    ) {
      return true;
    }

    return false;
  };

  return (
    <Screen>
      <>
        <Header isAuthenticated={isAuthenticated} />
        <Row justify="flex-start" align="stretch">
          <SwitchWrapper
            headerHeight={headerHeight}
            sidebarHidden={isSideBarHidden()}
          >
            <Switch>
              <Route path="/" exact component={HomeWorkforce} />
              <ProtectedRoute
                isAllowed={!isAuthenticated}
                path="/login"
                component={Login}
                exact
              />
              <ProtectedRoute
                isAllowed={isAuthenticated}
                path="account-settings/addresses"
                component={SavedAddresses}
                exact
                restricted
              />
              <ProtectedRoute
                isAllowed={!isAuthenticated}
                path="/forgot-password"
                redirectTo="/"
                component={ForgotPassword}
              />
              <ProtectedRoute
                isAllowed={!isAuthenticated}
                path="/signup"
                redirectTo="/"
                component={Signup}
              />
              <ProtectedRoute
                isAllowed={isAuthenticated}
                path="/schedule"
                component={OrganizationSchedule}
              />
              <ProtectedRoute
                isAllowed={isAuthenticated}
                path="/trip-options"
                component={TripOptionsWorkforce}
              />
              <ProtectedRoute
                isAllowed={isAuthenticated}
                path="/trip-overview"
                component={TripOverviewWorkforce}
              />
              <ProtectedRoute
                isAllowed={isAuthenticated}
                path="/my-trips"
                component={MyTripsWorkforce}
              />
              <ProtectedRoute
                isAllowed={isAuthenticated}
                path="/trip-detail"
                component={TripDetail}
              />
              <ProtectedRoute
                isAllowed={isAuthenticated}
                path="/payment"
                component={PaymentContainer}
              />
              <ProtectedRoute
                isAllowed={isAuthenticated}
                path="/account-settings"
                render={renderAccountSettings}
                restricted
              />
              <ProtectedRoute
                isAllowed={isAuthenticated}
                path="/transit-trip-detail"
                component={TripDetail}
                redirectTo="/"
              />
              <ProtectedRoute
                isAllowed={isAuthenticated}
                path="/transit-share-trip-detail"
                component={TripDetail}
                redirectTo="/"
              />
              <ProtectedRoute
                isAllowed={!isAuthenticated}
                path="/org/:orgId"
                component={SignupOrg}
                redirectTo="/"
              />
            </Switch>
          </SwitchWrapper>
          <Switch>
            <ProtectedRoute
              isAllowed={isAuthenticated}
              path="/rate-ride"
              component={RateRide}
              redirectTo="/"
            />
            <ProtectedRoute
              isAllowed={isAuthenticated}
              path="/terms-and-conditions"
              component={TermsConditions}
              redirectTo="/"
            />
            <ProtectedRoute
              isAllowed={isAuthenticated}
              path="/user-details"
              component={ExtraFieldsAndSpecialNeeds}
              exact
            />
          </Switch>

          <MapWorkforce />
        </Row>
      </>
    </Screen>
  );
};

LargeWorkforce.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  headerHeight: PropTypes.number.isRequired,
};

export default LargeWorkforce;
