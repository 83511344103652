/** @format */

import { createSlice } from '@reduxjs/toolkit';

import { analytics, api, generals as generalConstants } from '@constants';
import { api as apiClient, helpers } from '@utils';
import { openModal, trackAbEvent } from '@slices/app';
//import { checkAndNavigateToRidershipBonusLandingPage } from '@slices/auth';

export const defaultState = {
  selectedTrip: null,
  activeTripsType: generalConstants.MY_TRIPS_TYPE.upcoming,
  trips: {
    upcoming: {
      data: [],
      pagination: {
        pageCount: null,
      },
    },
    past: {
      data: [],
      pagination: {
        pageCount: null,
      },
    },
    completedTrip: {},
  },
  loading: null,
};

const MyTripsSlice = createSlice({
  name: 'myTrips',
  initialState: defaultState,
  reducers: {
    setLoading(state, action) {
      const { payload: loading } = action;

      return {
        ...state,
        loading,
      };
    },
    setActiveTripsType(state, action) {
      const { payload: activeTripsType } = action;

      return {
        ...state,
        activeTripsType,
      };
    },
    setTrips(state, action) {
      const { payload: { type, trips } } = action;
      return {
        ...state,
        trips: {
          ...defaultState.trips,
          [type]: trips,
          completedTrip: state.trips.completedTrip,
        },
      };
    },
    resetMyTrips(state) {
      return {
        ...state,
        trips: {
          ...defaultState.trips,
          completedTrip: state.trips.completedTrip,
        },
      };
    },
    setCompletedTrip(state, action) {
      const { payload: completedTrip } = action;
      return {
        ...state,
        completedTrip,
      };
    },
  },
});

export const {
  setLoading,
  setActiveTripsType,
  setTrips,
  resetMyTrips,
  setSelectedTrip,
  setConfirmCancelModal,
  setCompletedTrip,
} = MyTripsSlice.actions;

export const getMyTrips = (page = 0) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(generalConstants.MY_TRIPS_LOADING));
    try {
      const {
        app: { user },
        myTrips: { activeTripsType },
      } = getState();

      const top = generalConstants.MY_TRIPS_PAGINATION_COUNT;
      const skip = page * top;
      const url = api.endpoints.GET_USER_TRIPS.replace(':id', user.id);
      const { data: trips } = await apiClient.get(
        `${url}?type=${activeTripsType}&$top=${top}&$skip=${skip}&$inlinecount=allpages`,
      );

      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: 'Navigate To My Trips',
          subJourneyName: 'See Trip Details',
          journeyName: 'Rider Experience',
          details: {
            trips,
          },
        }),
      );
      dispatch(setTrips({
        type: activeTripsType,
        trips: {
          data: trips.data,
          pagination: {
            pageCount: Math.ceil(trips.meta.inlineCount / top),
          },
        },
      }));
      dispatch(setLoading(null));
    } catch (err) {
      console.log('GET_MY_TRIPS_ERROR', err);
      dispatch(setLoading(null));
    }
  };
};

export const getMyCompletedTrip = (history, redirectToRateRide = true, callback) => {
  return async (dispatch) => {
    try {
      const { data } = await apiClient.get(api.endpoints.GET_COMPLETED_TRIP);
      const { completedTrip } = data;
      if (completedTrip) {
        dispatch(setCompletedTrip(completedTrip));
        if (redirectToRateRide) {
          history.push('/rate-ride');
        }
      } else if (!window.location.pathname.includes('/ridership-bonus-promo')) {
        //dispatch(checkAndNavigateToRidershipBonusLandingPage(history, '/'));
      }

      if (typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('GET_MY_COMPLETED_TRIP_ERROR', error);
    }
  };
};

export const getTripById = tripId => {
  return async dispatch => {
    dispatch(setLoading(generalConstants.MY_TRIP_LOADING));

    try {
      const response = await apiClient.get(
        api.endpoints.GET_TRIP_BY_ID.replace(':tripId', tripId),
      );

      dispatch(setSelectedTrip(response.data));
      dispatch(setLoading(null));
    } catch {
      dispatch(setLoading(null));
    }
  };
};

export const rateTrip = (tripId, ratingData) => {
  return async dispatch => {
    try {
      dispatch(setLoading(generalConstants.RATE_RIDE_LOADING));
      const response = await apiClient.post(
        api.endpoints.RATE_THE_TRIP.replace(':id', tripId),
        {
          ...ratingData,
        },
      );

      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: 'Rate Trip',
          subJourneyName: 'Rate Your Trip',
          journeyName: 'Rider Experience',
          elementId: 'rating-save-btn',
          isDataCy: true,
          details: {
            rating: ratingData.rating,
            comment: ratingData.comment,
            'trip-id': tripId,
            data: response,
          },
        }),
      );

      dispatch(openModal({ modal: 'ratingSubmitted' }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('RATE_TRIP_ERROR', error);
    }

    dispatch(setLoading(null));
  };
};

export const setTripFeedback = (tripId, history) => {
  return async () => {
    try {
      if (history) {
        history.push('/');
      }
      await apiClient.put(api.endpoints.SET_FEEDBACK.replace(':id', tripId), {
        feedback: true,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('SET_TRIP_FEEDBACK_ERROR', err);
    }
  };
};

export const cancelTripById = (tripId, openModalCallback, cancelRepeatingRequests = false) => {
  return async dispatch => {
    dispatch(setLoading(generalConstants.CANCEL_TRIP_LOADING));

    try {
      let url = api.endpoints.CANCEL_TRIP_REQUEST.replace(':id', tripId);
      if (cancelRepeatingRequests) {
        url += '?cancelRepeatingRequests=true';
      }
      const { data: cancelled } = await apiClient.post(url);

      dispatch(setLoading(null));

      helpers.getObjectProperty(
        cancelled,
        'detail.payment',
        null,
      );
      if (openModalCallback) {
        openModalCallback();
      }
    } catch {
      dispatch(setLoading(null));
    }
  };
};

export const myTripsReducer = MyTripsSlice.reducer;
