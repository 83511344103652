/** @format */

import styled from 'styled-components';
import { theme, device } from '@constants';
import { Button, Col, IconButton, Row } from '..';

export const Wrapper = styled(Col)`
  height: 100%;
  font-family: Bryant;

  button[width='20'] {
    margin-top: 0 !important;
    @media ${device.mediaQuery.SMALL_SCREEN} {
      position: absolute;
      margin: 15px 0;
      font-size: 24px;
      top: 24px !important;
      right: 20px;
    }
  }

  @media ${device.mediaQuery.SMALL_SCREEN} {
    min-height: 100%;
    padding: 20px 20px;
  }

  @media ${device.mediaQuery.LARGE_SCREEN} {
    padding: 20px 24px 20px 24px;
  }
`;

export const Title = styled.h6`
  color: ${theme.COD_GRAY_LIGHT};
  font-weight: bold;
  font-size: 26px;
  @media ${device.mediaQuery.SMALL_SCREEN} {
    margin-top: 20px;
  }
`;

export const CloseIconRow = styled(Row)`
  margin-left: 8px;
`;

export const SubTitle = styled.p`
  color: ${theme.COD_GRAY_LIGHT};
  font-size: 22px;
  margin-bottom: 10px;
  @media ${device.mediaQuery.SMALL_SCREEN} {
    font-size: 18px;
  }
`;

export const FormWrapper = styled(Col)`
  display: block;

  & > * {
    margin: 6px 0px;
  }
`;

export const ResetBtn = styled(Button)`
  margin-top: 20px;
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  height: 50px;
`;

export const CloseIconStyled = styled(IconButton)`
  margin-top: 0;
  z-index: 2;

  @media ${device.mediaQuery.SMALL_SCREEN} {
    position: absolute;
    margin: 15px 0;
    font-size: 22px;
    top: 124px;
  }
`;
