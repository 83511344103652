/** @format */

import { toast } from 'react-toastify';

const show = (type, message) => {
  if (!toast[type]) {
    return;
  }

  toast[type](message, {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
  });
};

export default {
  show,
};
