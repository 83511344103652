/** @format */

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styles as styleUtils } from '@utils';
import { theme, device } from '@constants';
import { SuggestionInput, Dropdown, Button, Col } from '..';

export const Wrapper = styled.div`
  ${styleUtils.flex('space-between', 'center', 'column')}
  flex: 1;
  height: 100%;
  overflow-y: auto;

  @media ${device.mediaQuery.SMALL_SCREEN} {
    min-height: 100%;
    padding: 20px;
  }

  @media ${device.mediaQuery.LARGE_SCREEN} {
    padding: 30px;
  }

  font-faimily: BryantRegular;
`;

export const SavedAddressesWrapper = styled.div`
  ${styleUtils.flex('space-between', 'flex-start', 'column')}
`;

export const SavedAddressLabel = styled.span`
  font-size: 18px;
  margin: 10px 0;
  font-weight: bold;
  font-family: BryantRegular;
`;

export const SavedAddressTag = styled.span`
  width: 100px;
`;

export const SavedAddressValue = styled.span`
  flex: 1;
  color: ${theme.BLACK};
  margin: 0 0 0 15px;
  font-size: 18px;
  display: flex;
  align-items: center;
  font-family: BryantRegular;
`;

export const AddressInput = styled(SuggestionInput)`
  width: 100%;
  margin-left: 15px;

  #suggestion-menu {
    top: 40px;
    border: 1px solid #cccccc;
    border-radius: 8px;
    box-shadow: none;
  }

  #suggestion-text {
    color: ${theme.BLACK};
    font-family: BryantRegular;
  }
  
  }

  Suggestions {
    left: 20px;
  }

  input {
    box-shadow: none;
    height: 50px;
    font-size: 18px;
    font-family: BryantRegular;

    ::placeholder {
      font-family: BryantRegular;
      font-size: 18px;
      font-weight: normal;
      opacity: 0.5;
      color: ${theme.BLACK};
    }
  }
`;

export const AddressCol = styled(Col)`
  padding-right: 10px;
`;

export const BottomWrapper = styled.div`
  ${styleUtils.flex('flex-end', 'flex-end', 'column')}
  margin-top: 50px;
`;

export const AddIcon = styled(FontAwesomeIcon)`
  color: ${theme.BRIGHT_SKY_BLUE};
  font-size: 18px;
  margin-left: 5px;
  margin-bottom: -4px;
  cursor: pointer;
`;

export const AddressTypeDropDown = styled(Dropdown)`
  box-shadow: none;
  height: 50px;
  width: 35%;

  .rdd-control {
    z-index: ${props => props.zIndex + 1};
  }

  .rdd-menu {
    z-index: ${props => props.zIndex};
    top: 42px;

    & > div:first-child {
      margin-top: 0px;
    }
  }
`;

export const IconWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export const CloseButton = styled(Button)`
  width: 16px;
  height: 16px;
  z-index: 2;

  @media ${device.mediaQuery.SMALL_SCREEN} {
    margin-bottom: 10px;
    position: absolute;
    top: 0;
  }

  @media ${device.mediaQuery.LARGE_SCREEN} {
    margin-bottom: 15px;
  }
`;

export const Heading = styled.p`
  font-weight: bold;
  font-style: normal;
  font-family: BryantRegular;
  line-height: 26px;
  font-size: 20px;
`;

export const UpdateBtn = styled(Button)`
  border-radius: 8px;
  font-size: 18px;
  line-height: 20px;
  font-family: Bryant;
  font-weight: 700;
  height: 50px;
`;

export const DropdownWrapper = styled(Col)`
  margin-right: 10px;
`;
