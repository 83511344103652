/** @format */

import React, { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Portal } from 'react-portal';

import { SendReferralsForm } from '@components';
import {
  setSendReferralInputEmailOrPhone,
  setSendReferralEmailsOrPhones,
  sendReferrals,
  checkAndNavigateToRidershipBonusLandingPage,
} from '@slices/auth';
import { useTranslation } from 'react-i18next';
import { helpers, toast } from '@utils';
import { analytics } from '@constants';
import { generateReferralCode, setReferralCodeLoading, trackAbEvent } from '../../slices/app';

const SendReferralInvites = () => {
  const { t } = useTranslation();
  const {
    trigger,
    register,
    errors,
    formState: { isValid },
  } = useForm({
    mode: 'onBlur',
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const state = useSelector(store => store.auth.sendReferralEmailsOrPhones);
  const { restrictedMode } = useSelector(store => store.app);

  const {
    largeScreen,
    user,
    selectedOrganization,
    organizations,
    referralCodeLoading,
  } = useSelector(store => store.app);
  const inviteButtonEnabled =
    state.emailsOrPhoneNumbers.length > 0 &&
    state.inputEmailOrPhone.trim().length === 0;

  const close = useCallback(
    () => {
      dispatch(checkAndNavigateToRidershipBonusLandingPage(history, '/'));
    },
    [history, dispatch],
  );

  const copyToClipboard = () => {
    helpers.copyToClipboard(
      `${window.location.origin}/org/${(organizations || [])[selectedOrganization]?.organizationId}?referral=${user.referralCode}`,
    );
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.REFERRAL_LINK_COPIED,
        subJourneyName: 'Invites',
        journeyName: 'Rider Experience',
      }),
    );
  };

  const [initialized, setInitialized] = useState(false);
  
  useEffect(() => {
    if (user && !user.referralCode && !referralCodeLoading) {
      dispatch(generateReferralCode(user.id));
    }
    if (!initialized) {
      setInitialized(true);
      dispatch(setReferralCodeLoading(false));
    }
  }, [user, dispatch, initialized, referralCodeLoading]);

  const onSubmit = () => {
    dispatch(sendReferrals(close));
  };

  const onChange = async e => {
    const { name, value } = e.target;
    if (value !== ',') {
      dispatch(setSendReferralInputEmailOrPhone(value));
      await trigger(name);
    }
  };

  const onAddEmail = async e => {
    const { value } = e.target;

    if (
      (e.key === 'Enter' || e.key === ',') &&
      e.target.validity.valid &&
      isValid
    ) {
      if (state.emailsOrPhoneNumbers.length === 15) {
        toast.show('error', t('send-referrals.toast.max-invites'));
      } else if (isUniqueEmail(e.target.value)) {
        dispatch(
          setSendReferralEmailsOrPhones([
            ...state.emailsOrPhoneNumbers,
            value.toLowerCase(),
          ]),
        );
        dispatch(setSendReferralInputEmailOrPhone(''));
      } else if (state.emailsOrPhoneNumbers) {
        toast.show('error', t('send-referrals.toast.already-exists'));
      }
    }
  };

  const isUniqueEmail = email => {
    return !state.emailsOrPhoneNumbers.includes(email.toLowerCase());
  };

  const onRemoveTag = emailToBeRemoved => {
    dispatch(
      setSendReferralEmailsOrPhones(
        state.emailsOrPhoneNumbers.filter(x => x !== emailToBeRemoved),
      ),
    );
  };

  return (
    !restrictedMode && (
      <Portal>
        <SendReferralsForm
          errors={state.inputEmailOrPhone ? errors : null}
          validate={register}
          largeScreen={largeScreen}
          toggle={close}
          onChange={onChange}
          onSubmit={onSubmit}
          state={state}
          onAddEmail={onAddEmail}
          onRemoveTag={onRemoveTag}
          referralCode={user?.referralCode}
          copyToClipboard={copyToClipboard}
          inviteButtonEnabled={inviteButtonEnabled}
          referralCodeLoading={referralCodeLoading}
          isValid={isValid}
        />
      </Portal>
    )
  );
};

export default SendReferralInvites;
