/** @format */

import styled from 'styled-components';
import { Modal } from '@components/common/modal';

export const Popup = styled(Modal)``;

export const PopupText = styled.span`
  text-align: center;
  margin-bottom: 20px;
`;
