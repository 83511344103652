/** @format */

// 3rd party Imports
import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// internal Imports
import { theme, generals } from '@constants';
import { Row } from '@components';
import { FeedBackButton } from './styled';

const FeedBackMultiSelect = ({ onChangeFeedback, compliements }) => {
  const { t } = useTranslation();
  const onSelect = value => {
    onChangeFeedback('compliments', [...compliements, value]);
  };

  const onRemove = value => {
    onChangeFeedback(
      'compliments',
      compliements.filter(val => val !== value),
    );
  };

  return (
    <>
      <Row align="flex-start" justify="space-around" wrap="wrap">
        {generals.FEED_BACK_OPTIONS.map(({ label, value }, index) => (
          <FeedBackButton
            id={`feeback-${index}`}
            key={index}
            label={t(
              `rate-ride.fields.feedback.options.${label
                .toLowerCase()
                .split(' ')
                .join('-')}`,
            )}
            color={theme.BASE_COLOR}
            size="md"
            onClick={() =>
              compliements.indexOf(value) === -1
                ? onSelect(value)
                : onRemove(value)
            }
            inverted={compliements.indexOf(value) === -1}
            bordered
          />
        ))}
      </Row>
    </>
  );
};

FeedBackMultiSelect.propTypes = {
  onChangeFeedback: propTypes.func.isRequired,
  compliements: propTypes.array.isRequired,
};

export default FeedBackMultiSelect;
