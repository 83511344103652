import geoTz from 'geo-tz';
import GreatCircle from 'great-circle';
import { generals as generalConstants } from '@constants';

const isAirportOrTerminal = (otherAddress, ignoreNonOrgPlace = true) => {
  if (
    !otherAddress ||
    typeof otherAddress === 'string' ||
    (ignoreNonOrgPlace && !otherAddress.organizationId)
  ) {
    return false;
  }

  return (
    otherAddress.label.toLowerCase().indexOf('airport') !== -1 ||
    otherAddress.label.toLowerCase().indexOf('terminal') !== -1 ||
    otherAddress.label.toLowerCase().indexOf('hub') !== -1 ||
    otherAddress.label.toLowerCase().indexOf('arrive by') !== -1 ||
    (otherAddress.name &&
      (otherAddress.name.toLowerCase().indexOf('airport') !== -1 ||
        otherAddress.name.toLowerCase().indexOf('terminal') !== -1 ||
        otherAddress.name.toLowerCase().indexOf('hub') !== -1 ||
        otherAddress.name.toLowerCase().indexOf('arrive by') !== -1))
  );
};

const getTimezoneFromPlace = place => {
  if (place.timezone) {
    return place.timezone;
  }

  const { lat, lng } = place;
  let timezone = null;

  try {
    timezone = geoTz(Number(lat), Number(lng)).toString();
  } catch {
    return 'America/New_York';
  }

  if (timezone) {
    return timezone;
  }

  return 'America/New_York';
};

const getTimezoneFromPlaceLocation = place => {
  if (place.timezone) {
    return place.timezone;
  }

  const { lat, lon } = place.location;
  let timezone = null;

  try {
    timezone = geoTz(Number(lat), Number(lon)).toString();
  } catch {
    return 'America/New_York';
  }

  if (timezone) {
    return timezone;
  }

  return 'America/New_York';
};

const areSamePlaces = (place1, place2) => {
  const distance = GreatCircle.distance(place1.lat, place1.lng, place2.lat, place2.lng, 'M');

  // if distance is greater than equal to 160 meters or 0.1 miles, its a different place
  if (distance >= 160) {
    return false;
  }

  return true;
};

const getDistance = (place1, place2) => {
  if (!place1.lat || !place1.lng || !place2.lat || !place2.lng) {
    return null;
  }
  const distance = GreatCircle.distance(place1.lat, place1.lng, place2.lat, place2.lng, 'M');
  return distance;
};

const getAlphabetsForPlaces = orgPlaces => {
  if (!orgPlaces || !orgPlaces?.length) return [];

  const sortedPlaces = orgPlaces
    .map(p => p.type === 'STOP' ? p : null)
    .filter(v => v !== null)
    .sort((a, b) => a.orgPlaceId - b.orgPlaceId);
  let index = 0;
  let count = 0;

  return (sortedPlaces.map(place => {
    const markerKey = `${generalConstants.ALPHABETS[index]}${count || ''}`;
    // eslint-disable-next-line no-plusplus
    index++;
    if (index === 26) {
      index = 0;
      // eslint-disable-next-line no-plusplus
      count++;
    }
    return {
      ...place,
      markerKey,
    };
  }));
};

const resetEstPickupCache = (oldShifts, newShifts) => {
  if (!newShifts) return false;
  if (oldShifts?.length !== newShifts?.length) return true;

  let startTimeChanged = false;

  newShifts.forEach(shift => {
    const oldShift = oldShifts.find(s => s.id === shift.id);
    if (oldShift.startTime !== shift.startTime) {
      startTimeChanged = true;
      return true;
    }

    return false;
  });

  return startTimeChanged;
};

export default {
  isAirportOrTerminal,
  getTimezoneFromPlace,
  areSamePlaces,
  getTimezoneFromPlaceLocation,
  getDistance,
  getAlphabetsForPlaces,
  resetEstPickupCache,
};
