/** @format */

import React from 'react';
import { PropTypes } from 'prop-types';
import {
  StyledRadioGroup,
  RadioButton,
  RadioInput,
  LabelStyled,
  Title,
  ButtonsContainer,
  Error,
} from './styled';

const RadioGroup = ({
  label,
  name: fieldName,
  options,
  onChange,
  buttonsFlow,
  checked,
  alignItems,
  marginTopInputLabel,
  error,
}) => {
  return (
    <StyledRadioGroup>
      {label &&
        <Title block className="heading">
          {label}
        </Title>}
      <ButtonsContainer buttonsFlow={buttonsFlow} marginTop={!label ? '16' : 0}>
        {options.map(({ id, name, defaultChecked, value, method = null }, index) => {
          return (
            <RadioButton key={id} id={id} data-cy={`radio-btn-${index + 1}`} alignItems={alignItems}>
              <RadioInput
                id="ctrl"
                data-cy={`radio-btn-input-${index + 1}`}
                type="radio"
                name={fieldName}
                value={value}
                onChange={e => !method ? onChange(e, id) : onChange(e, id, method)}
                defaultChecked={defaultChecked}
                checked={value === checked}
              />
              <LabelStyled className="label" marginTopInputLabel={marginTopInputLabel}>{name}</LabelStyled>
            </RadioButton>
          );
        })}
      </ButtonsContainer>
      {error &&
        <Error block>
          {error}
        </Error>}
    </StyledRadioGroup>
  );
};

RadioGroup.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  buttonsFlow: PropTypes.string,
  checked: PropTypes.string,
  alignItems: PropTypes.string,
  marginTopInputLabel: PropTypes.string,
  error: PropTypes.string,
};

RadioGroup.defaultProps = {
  label: null,
  buttonsFlow: 'row',
  checked: null,
  alignItems: null,
  marginTopInputLabel: null,
  error: null,
};

export default RadioGroup;
