/** @format */

import React from 'react';
import {
  CardContainer,
  InfoBlock,
  Title,
  TripTimeTitle,
  TripDescriptionRow,
  TripIcon,
  TripTitle,
  TripTime,
  TripAddressWrapper,
  TripAddress,
  TripTimeValue,
  HeaderRow,
  TripAddressSubLabel,
  TripAddressSubLabelHeading,
  MinimizedCol
} from './styled';
import {
  faCircle,
  faCircleNotch,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Col } from '@components';
import { PropTypes } from 'prop-types';
import moment from 'moment-timezone'
const FixedRouteCardLayout = ({ result, minimized, largeScreen, selected }) => {
  const { t } = useTranslation();
  const formateTime = (dateTime) =>{
    return dateTime ? moment(dateTime).format('hh:mm A'): null;
  }
  return (
    <>
      <CardContainer>
        <InfoBlock largeScreen={largeScreen ? 1 : 0} selected={selected ? 1 : 0}>
          <HeaderRow flex="initial" justify="space-between" align="center" minimized={minimized}>
            <Title>{result.title}</Title>
            <TripTimeTitle>
              {t('trip-overview.labels.trip-time')}
              <TripTimeValue>{result.tripTime}</TripTimeValue>
            </TripTimeTitle>
          </HeaderRow>
          <MinimizedCol minimized={minimized}>
          {result.leaveAddress && (
            <TripDescriptionRow justify="flex-start" align="flex-start">
              <TripTitle>
                {t('trip.labels.estd-time.leave-by').toUpperCase()}
              </TripTitle>
              <TripIcon icon={faCircleNotch} rotation={180} />
              <TripTime>{result.tripLeave}</TripTime>
              <TripAddressWrapper pathItemActionType={0}>
                <Col flex="initial" justify="flex-start" align="flex-start">
                  <TripAddress>{result.leaveAddress.substring(0,75)}</TripAddress>
                  <TripAddressSubLabel>
                    Walk {result.walkTimeLeave} min{' '}
                  </TripAddressSubLabel>
                </Col>
              </TripAddressWrapper>
            </TripDescriptionRow>
          )}

          {result.pickupAddress && (
            <TripDescriptionRow justify="flex-start" align="flex-start">
              <TripTitle>
                <TripTitle>{t('trip.labels.estd-time.pickup')}</TripTitle>
              </TripTitle>
              <TripIcon icon={faCircle} rotation={0} />
              <TripTime>{result.leaveAddress ? formateTime(result.tripPickup) : result.tripPickup}</TripTime>
              <TripAddressWrapper pathItemActionType={0}>
                <Col flex="initial" justify="flex-start" align="flex-start">
                  <TripAddress>{result.pickupAddress.substring(0,75)}</TripAddress>
                  <TripAddressSubLabelHeading>
                    Fixed Route Stop
                  </TripAddressSubLabelHeading>
                </Col>
              </TripAddressWrapper>
            </TripDescriptionRow>
          )}

          {result.dropoffAddress && (
            <TripDescriptionRow justify="flex-start" align="flex-start">
              <TripTitle>
                <TripTitle>{t('trip.labels.estd-time.arrival')}</TripTitle>
              </TripTitle>
              <TripIcon icon={faCircle} rotation={0} />
              <TripTime>{result.arrivalAddress ? formateTime(result.tripDropoff) : result.tripDropoff}</TripTime>
              <TripAddressWrapper pathItemActionType={0}>
                <Col flex="initial" justify="flex-start" align="flex-start">
                  <TripAddress>{result.dropoffAddress.substring(0,75)}</TripAddress>
                  <TripAddressSubLabelHeading>
                    Fixed Route Stop
                  </TripAddressSubLabelHeading>
                </Col>
              </TripAddressWrapper>
            </TripDescriptionRow>
          )}

          {result.arrivalAddress && (
            <TripDescriptionRow justify="flex-start" align="flex-start">
              <TripTitle>
                <TripTitle>
                  {t('trip.labels.estd-time.arrive-by').toUpperCase()}
                </TripTitle>
              </TripTitle>
              <TripIcon icon={faCheckCircle} rotation={0} />
              <TripTime>{result.tripArrival}</TripTime>
              <TripAddressWrapper pathItemActionType={0}>
                <Col flex="initial" justify="flex-start" align="flex-start">
                  <TripAddress>{result.arrivalAddress.substring(0,75)}</TripAddress>
                  <TripAddressSubLabel>
                    Walk {result.walkTimeArrival} min
                  </TripAddressSubLabel>
                </Col>
              </TripAddressWrapper>
            </TripDescriptionRow>
          )}
          </MinimizedCol>
        </InfoBlock>
      </CardContainer>
    </>
  );
};

FixedRouteCardLayout.propTypes = {
  fixedRouteResults: PropTypes.array,
};

export default FixedRouteCardLayout;
