/** @format */

import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Badge } from '@components';
import { helpers } from '@utils';
import {
  TripPathItemRow,
  TripPathItemIcon,
  TripPathItemTitle,
  TripPathItemTime,
  TripPathItemAddressWrapper,
  TripPathItemAddress,
  TripPathItemTextSmall,
  TripPathItemBadgeDescription,
} from './styled';

export const PathItem = ({
  title,
  time,
  address,
  text,
  icon,
  iconRotation,
  pathItemActionType,
  badge,
}) => {
  const boundary = title ? 1 : 0;
  const busItem = pathItemActionType === 'transit';
  const shareWaitItem = pathItemActionType === 'board' && badge.value === 'SHARE';
  const streetAddress = helpers.getStreetAddress(address);

  return (
    <TripPathItemRow
      justify="flex-start"
      align="flex-start"
      boundary={1}
      busItem={busItem ? 1 : 0}
      shareWaitItem={shareWaitItem}
    >
      {title && <TripPathItemTitle boundary={1}>{title}</TripPathItemTitle>}
      <TripPathItemIcon
        icon={icon}
        rotation={iconRotation ? 180 : 0}
        boundary={boundary}
      />
      <TripPathItemTime data-cy="depart-time" boundary={boundary}>{time}</TripPathItemTime>
      <TripPathItemAddressWrapper
        pathItemActionType={pathItemActionType ? 1 : 0}
      >
        <Col flex="initial" justify="flex-start" align="flex-start">
          <TripPathItemAddress boundary={1}>
            {helpers.truncateStringWithEllipses(streetAddress, 75)}
          </TripPathItemAddress>

          {badge && (
            <Row flex="initial" justify="flex-start" align="center">
              {badge.value && <Badge>{badge.value}</Badge>}
              {badge.description && (
                <TripPathItemBadgeDescription>
                  {badge.description}
                </TripPathItemBadgeDescription>
              )}
            </Row>
          )}
          {text && <TripPathItemTextSmall>{text}</TripPathItemTextSmall>}
        </Col>
      </TripPathItemAddressWrapper>
    </TripPathItemRow>
  );
};

PathItem.propTypes = {
  title: PropTypes.string,
  time: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  text: PropTypes.string,
  icon: PropTypes.object.isRequired,
  iconRotation: PropTypes.bool,
  pathItemActionType: PropTypes.oneOf(['transit', 'walk', 'board']),
  badge: PropTypes.shape({
    value: PropTypes.string,
    description: PropTypes.string,
  }),
};

PathItem.defaultProps = {
  title: null,
  text: null,
  iconRotation: false,
  pathItemActionType: null,
  badge: null,
};

export default PathItem;