/** @format */

import styled from 'styled-components';

import { theme, device } from '@constants';
import { Col } from '..';

export const TripDateTimeColumn = styled(Col)`
  padding: 30px;
  background-color: ${theme.WHITE};

  @media ${device.mediaQuery.LARGE_SCREEN} {
    position: absolute;
    top: 0;
    z-index: 3;
  }
`;

export const Title = styled.p`
  color: ${theme.BASE_COLOR};
  font-weight: bold;
  font-size: 18px;
`;
