/** @format */

const BASE_COLOR = process.env.REACT_APP_BASE_COLOR || '#0a2e40';
const SECONDARY_BASE_COLOR =
  process.env.REACT_APP_SECONDARY_BASE_COLOR || '#FD050B';
const WHITE = '#fff';
const TRANSPARENT = 'transparent';
const BRIGHT_SKY_BLUE = '#0ab9ff';
const BLUE_GREY = '#78849e';
const GREYISH_PURPLE = '#847f9a';
const TOMATO = '#eb5734';
const WEIRD_GREEN = '#4dd89d';
const GREY = '#b2bec3';
const LIGHT_GREY = '#eceae6';
const LIGHTER_GREY = '#f9f9f9';
const BLACK = '#000';
const WARM_GREY = '#707070';
const DARK_BLUE = '#0c2e40';
const SKY_BLUE = '#62b4f4';
const GREEN = '#008000';
const STAR_YELLOW = '#FFD713';
const STAR_EMPTY_COLOR = '#0a314380';
const INPUT_LABEL_COLOR = '#78849e';
const DROPDOWN_HOVER_COLOR = '#f7fcff';
const COD_GRAY = '#111';
const COD_GRAY_LIGHT = '#222';
const EMPEROR_GRAY = '#555';
const LIGHTEN_GRAY = '#CCCCCC';
const BLUE_TRANSPARENT = '#0C2D831A';
const WHITE_DARK_GRAY = '#EFEFEF';
const WHITE_LIGHT_GRAY = '#A8A8A8';
const RIDERSHIP_BONUS_DARK_GREEN = '#15803D';
const LIGHT_PINK = '#FEE2E2';
const BRIGHT_BLUE = '#005EFF';
const REDEEM_GREY = '#C6C9D2';
const REDEEM_GREY_LIGHT = '#E2E4E9';
const MIDNIGHT_GREEN = '#004555';
const BRONZE = '#383000';
const DEEP_SKY_BLUE = '#00cfff';

export default {
  BASE_COLOR,
  SECONDARY_BASE_COLOR,
  WHITE,
  TRANSPARENT,
  BLUE_GREY,
  GREYISH_PURPLE,
  TOMATO,
  BRIGHT_SKY_BLUE,
  WEIRD_GREEN,
  LIGHT_GREY,
  BLACK,
  WARM_GREY,
  LIGHTER_GREY,
  DARK_BLUE,
  SKY_BLUE,
  GREEN,
  STAR_YELLOW,
  STAR_EMPTY_COLOR,
  INPUT_LABEL_COLOR,
  GREY,
  DROPDOWN_HOVER_COLOR,
  COD_GRAY,
  COD_GRAY_LIGHT,
  EMPEROR_GRAY,
  LIGHTEN_GRAY,
  BLUE_TRANSPARENT,
  WHITE_DARK_GRAY,
  WHITE_LIGHT_GRAY,
  RIDERSHIP_BONUS_DARK_GREEN,
  LIGHT_PINK,
  BRIGHT_BLUE,
  REDEEM_GREY,
  REDEEM_GREY_LIGHT,
  MIDNIGHT_GREEN,
  BRONZE,
  DEEP_SKY_BLUE,
};
