/** @format */

import React from 'react';
import moment from 'moment-timezone';
import arrowLeft from '@assets/icons/arrow-left.svg';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { theme, generals as generalConstants } from '@constants';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {
  tabs,
  config,
  colors,
  getCalendarDisabledDates,
  placeTypes,
} from './config';
import {
  Wrapper,
  ActiveTabIndicator,
  TypeTabsRow,
  SelectTab,
  TabTypeCol,
  TabTypeText,
  DynamicText,
  Btn,
  SkewRow,
  SkewCol,
  BackIcon,
  TextSmall,
  Pickup,
  Dropoff,
  WorkplaceText,
  Shifts,
} from './styled';
import { Row, Col, Calendar, IconButton } from '..';

const WorkforceTripRequest = ({
  largeScreen,
  headerHeight,
  page,
  setPage,
  tripTypeIndex,
  setTripTypeIndex,
  workplace,
  stops,
  pickup,
  setPickup,
  dropoff,
  setDropoff,
  onSubmit,
  day,
  setDay,
  shift,
  setShift,
  disabled,
  trDisabledDates,
  resetState,
  organization,
  goToHome,
  loading,
  trackOnOpenAndClose,
  setSearchQuery,
  searchQuery,
  goToLogin,
  isLoggedIn,
  googleAddresses,
}) => {
  const isSearchLoading =
    loading === generalConstants.SEARCH_TRIP_REQUEST_LOADING_WF;
  const { t } = useTranslation();
  const formDisabled = !workplace;
  const isRoundTrip = tripTypeIndex === 1;
  const goingToWork = dropoff?.type === placeTypes.workplace;

  const getLanguageTranslatedWorkplace = type => {
    if (type === 'pickup') {
      const label = t(config[type].label);
      return {
        ...pickup,
        label: (
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `<div style="${config.container.style}"><span style="${config.locationName.style}">${workplace?.name}</span> <span style="${config[type].style}">${label}</span></div>`,
            }}
          />
        ),
      };
    }

    const label = t(config[type].label);
    return {
      ...dropoff,
      label: (
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `<div style="${config.container.style}"><span style="${config.locationName.style}">${workplace?.name}</span> <span style="${config[type].style}">${label}</span></div>`,
          }}
        />
      ),
    };
  };

  const getShiftTimes = () => {
    if (!workplace?.shifts) return [];
    if (!day) {
      return [
        {
          value: null,
          label: `${t(
            'workforce-trip-request.fields.dropdown.labels.select-date-first',
          )} `,
          disabled: true,
        },
      ];
    }

    const shiftTimes = [];
    const shiftFormattedTimes = workplace.shifts.map((shift, index) => {
      const { id, startTime, endTime } = shift;
      let label = `${t(
        'workforce-trip-request.fields.dropdown.time.labels.ending',
      )} ${t(
        'workforce-trip-request.fields.dropdown.time.labels.at',
      ).toLowerCase()} ${endTime}`;
      shiftTimes[index] = { endTime, type: 'readyBy' };

      if (goingToWork) {
        label = `${t(
          'workforce-trip-request.fields.dropdown.time.labels.starting',
        )} ${t(
          'workforce-trip-request.fields.dropdown.time.labels.at',
        ).toLowerCase()} ${startTime}`;
        shiftTimes[index] = { startTime, type: 'arriveBy' };
      }

      if (isRoundTrip) {
        label = `${startTime} - ${endTime}`;
        shiftTimes[index] = { startTime, endTime };
      }

      return {
        value: JSON.stringify({ id, startTime, endTime }),
        label,
      };
    });

    const invalidTimeIndexes = getAvailableTimes(shiftTimes);
    invalidTimeIndexes.forEach(index => {
      shiftFormattedTimes[index] = null;
    });
    return shiftFormattedTimes.filter(e => e !== null);
  };

  const getAvailableTimes = allTimes => {
    const invalidTimeIndexes = [];
    if (isRoundTrip) {
      (allTimes || []).forEach((time, index) => {
        const { startTime, endTime } = time || {};
        const currentUserTimeZoneUTC = moment
          .tz(moment(), pickup?.timezone)
          .utc().format('YYYY-MM-DDTHH:mm:ss');

        const startDateTime = `${moment(day).format('MM/DD/YYYY')} ${startTime}`;
        const endDateTime = `${moment(day).format('MM/DD/YYYY')} ${endTime}`;
        const departTime = moment
          .tz(startDateTime, 'MM/DD/YYYY hh:mm A', pickup?.timezone);
        const selectedDay = departTime.format('dddd').toLowerCase();
        const key = selectedDay.slice(0, 3);
        const formattedReturnTime = moment
          .tz(endDateTime, 'MM/DD/YYYY hh:mm A', pickup?.timezone)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss');
        const formattedDepartTime = departTime.utc()
          .format('YYYY-MM-DDTHH:mm:ss');

        let isArriveByInvalid = false;
        let isReadyByInvalid = false;

        if (
          moment(formattedDepartTime).isBefore(currentUserTimeZoneUTC) ||
          !workplace?.availableDaysArriveBy?.includes(selectedDay) ||
          !workplace?.availableTimes?.arriveBy[key]?.includes(startTime)
        ) {
          isArriveByInvalid = true;
        }

        if (
          moment(formattedReturnTime).isBefore(currentUserTimeZoneUTC) ||
          !workplace?.availableDays?.includes(selectedDay) ||
          !workplace?.availableTimes?.readyBy[key]?.includes(endTime)
        ) {
          isReadyByInvalid = true;
        }

        if (isArriveByInvalid && isReadyByInvalid) {
          invalidTimeIndexes.push(index);
        }
      });
    } else {
      (allTimes || []).forEach((time, index) => {
        const shiftTime = time?.type === 'readyBy' ? time?.endTime : time?.startTime;
        const currentUserTimeZoneUTC = moment
          .tz(moment(), pickup?.timezone)
          .utc().format('YYYY-MM-DDTHH:mm:ss');

        const dateTime = `${moment(day).format('MM/DD/YYYY')} ${shiftTime}`;
        const departTime = moment
          .tz(dateTime, 'MM/DD/YYYY hh:mm A', pickup?.timezone);
        const selectedDay = departTime.format('dddd').toLowerCase();
        const key = selectedDay.slice(0, 3);
        const formattedDepartTime = departTime.utc()
          .format('YYYY-MM-DDTHH:mm:ss');

        if (time.type === 'readyBy') {
          (moment(formattedDepartTime).isBefore(currentUserTimeZoneUTC) ||
            !workplace?.availableDays?.includes(selectedDay) ||
            !workplace?.availableTimes[time?.type][key]?.includes(shiftTime)) &&
            invalidTimeIndexes.push(index);
        } else if (time.type === 'arriveBy') {
          (moment(formattedDepartTime).isBefore(currentUserTimeZoneUTC) ||
            !workplace?.availableDaysArriveBy?.includes(selectedDay) ||
            !workplace?.availableTimes[time?.type][key]?.includes(shiftTime)) &&
            invalidTimeIndexes.push(index);
        }
      });
    }
    return invalidTimeIndexes;
  };

  const getLanguageTranslatedShift = () => {
    if (!shift) return null;

    const { value } = shift;
    if (!value) return null;

    const parsedShift = JSON.parse(value);
    const { startTime, endTime } = parsedShift;

    let label = `${t(
      'workforce-trip-request.fields.dropdown.time.labels.ending',
    )} ${t(
      'workforce-trip-request.fields.dropdown.time.labels.at',
    ).toLowerCase()} ${endTime}`;

    if (goingToWork) {
      label = `${t(
        'workforce-trip-request.fields.dropdown.time.labels.starting',
      )} ${t(
        'workforce-trip-request.fields.dropdown.time.labels.at',
      ).toLowerCase()} ${startTime}`;
    }

    return { value, label };
  };

  const getAlphaKey = markerKey => markerKey ? `${markerKey}. ` : '';

  const getPickupWithDropdownLabel = () => {
    if (!pickup) return null;
    if (typeof pickup.label !== 'string' || !pickup.name) return pickup;

    const { lat, lng, orgPlaceId, label, name, type, label: address, timezone, markerKey } = pickup || {};
    return {
      orgPlaceId,
      name,
      lat,
      lng,
      type,
      label: (
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `${getAlphaKey(markerKey)}${name} <span style="${config.stop.style}">${label}</span>`,
          }}
        />
      ),
      value: address,
      timezone,
      markerKey,
    };
  };

  const getLocationsForDropdowns = type => {
    const locations = [];
    (stops || []).forEach(stop => {
      const { lat, lng, orgPlaceId, label, name, type, label: address, timezone, markerKey } = stop || {};

      if (searchQuery) {
        const query = searchQuery.toLowerCase();
        if (
          !name?.toLowerCase().includes(query) &&
          !address?.toLowerCase().includes(query)
        ) return null;
      }

      locations.push({
        orgPlaceId,
        name,
        lat,
        lng,
        type,
        label: (
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `${getAlphaKey(markerKey)}${name} <span style="${config.stop.style}">${label}</span>`,
            }}
          />
        ),
        value: address,
        timezone,
        markerKey,
      });

      return null;
    });

    const searchedLocations = googleAddresses?.map(address => {
      return {
        ...address,
        disabled:
          !isRoundTrip &&
          ((type === 'pickup' && (dropoff?.type === placeTypes.stop || (dropoff && !dropoff.type))) ||
            (type === 'dropoff' && (pickup?.type === placeTypes.stop || (pickup && !pickup.type)))),
        value: address.label,
      };
    });

    if (isRoundTrip) {
      return [...searchedLocations, { ...locations[0] }, ...(locations.slice(1))];
    }

    const {
      lat,
      lng,
      orgPlaceId,
      name,
      type: locType,
      label: address,
      timezone,
      shifts,
      availableDays,
      availableDaysArriveBy,
      availableTimes,
    } = workplace;
    const label = t(config[type].label);
    const workplaceLocation = {
      orgPlaceId,
      lat,
      lng,
      type: locType,
      label: (
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `<div style="${config.container.style}"><span style="${config.locationName.style}">${name}</span> <span style="${config[type].style}">${label}</span></div>`,
          }}
        />
      ),
      value: address,
      disabled:
        (type === 'pickup' && dropoff?.type === placeTypes.workplace) ||
        (type === 'dropoff' && pickup?.type === placeTypes.workplace),
      timezone,
      shifts,
      availableDays,
      availableDaysArriveBy,
      availableTimes,
    };

    const updatedLocations = locations.map(loc => {
      return {
        ...loc,
        disabled:
          !isRoundTrip &&
          ((type === 'pickup' && (dropoff?.type === placeTypes.stop || (dropoff && !dropoff.type))) ||
            (type === 'dropoff' && (pickup?.type === placeTypes.stop || (pickup && !pickup.type)))),
      };
    });

    return [workplaceLocation, ...updatedLocations, ...searchedLocations];
  };

  const pickups = formDisabled ? [] : getLocationsForDropdowns('pickup');
  const dropoffs = formDisabled ? [] : getLocationsForDropdowns('dropoff');

  const onSelectTab = index => {
    resetState(1);
    resetState(2);
    setTripTypeIndex(index);

    if (index === 1 && !formDisabled) {
      const { lat, lng, orgPlaceId, name, type, label, shifts, timezone, availableDays, availableDaysArriveBy, availableTimes } = workplace;
      setDropoff({ lat, lng, orgPlaceId, name, type, label, value: label, shifts, timezone, availableDays, availableDaysArriveBy, availableTimes }, true);
    } else {
      setDropoff(null);
    }
  };

  const renderPage1 = () => {
    return (
      <>
        <TypeTabsRow justify="center" align="center">
          <ActiveTabIndicator selectedIndex={tripTypeIndex} />
          {tabs.map((tab, index) => (
            <SelectTab
              key={index}
              id={`${tab.id}-button`}
              onClick={() => {
                onSelectTab(index);
              }}
            >
              <TabTypeCol justify="center" align="center">
                <TabTypeText selected={tripTypeIndex === index}>
                  {t(tab.titleContent)}
                </TabTypeText>
              </TabTypeCol>
            </SelectTab>
          ))}
        </TypeTabsRow>
        <Row spacingV={20} justify="flex-start" align="flex-start">
          <Col justify="center" align="flex-start">
            <DynamicText bold color={colors.addressType}>
              {t('workforce-trip-request.fields.pickup.title')}
            </DynamicText>
            <Pickup
              placeholder={t(
                'workforce-trip-request.fields.pickup.placeholder',
              )}
              isMulti={false}
              width={100}
              options={pickups}
              isOptionDisabled={(option) => option.disabled || !option.value}
              onChange={setPickup}
              value={
                pickup?.type === placeTypes.workplace
                  ? getLanguageTranslatedWorkplace('pickup')
                  : getPickupWithDropdownLabel()
              }
              isRoundTrip={isRoundTrip}
              isClearable
              isSearchable
              spacingTop={pickup?.type !== placeTypes.workplace}
              filterOption={(c, i) => {
                setSearchQuery(i);
                return c.value;
              }}
              onClose={() => {
                trackOnOpenAndClose('Pickup dropdown', 'Closed');
              }}
              onOpen={() => {
                trackOnOpenAndClose('Pickup dropdown', 'Opened');
              }}
            />
          </Col>
        </Row>
        <Row spacingV={20} justify="flex-start" align="flex-start">
          <Col justify="center" align="flex-start">
            <DynamicText bold color={colors.addressType}>
              {t('workforce-trip-request.fields.destination.title')}
            </DynamicText>
            {isRoundTrip && !formDisabled ? (
              <WorkplaceText bold margin="0 0 0 10px">
                {workplace?.name}
              </WorkplaceText>
            ) : (
              <Dropoff
                placeholder={t(
                  'workforce-trip-request.fields.destination.placeholder',
                )}
                isMulti={false}
                width={100}
                options={dropoffs}
                onChange={setDropoff}
                value={
                  dropoff?.type === placeTypes.workplace
                    ? getLanguageTranslatedWorkplace('dropoff')
                    : dropoff
                }
                isRoundTrip={isRoundTrip}
                isClearable
                isSearchable
                spacingTop={dropoff?.type !== placeTypes.workplace}
                filterOption={(c, i) => {
                  setSearchQuery(i);
                  return true;
                }}
                onClose={() => {
                  trackOnOpenAndClose('Dropoff dropdown', 'Closed');
                }}
                onOpen={() => {
                  trackOnOpenAndClose('Dropoff dropdown', 'Opened');
                }}
              />
            )}
          </Col>
        </Row>
      </>
    );
  };

  const renderPage2 = () => {
    return (
      <>
        <Row>
          <SkewRow justify="flex-start" align="center">
            <SkewCol justify="center" align="center">
              <DynamicText
                fontSize={14}
                bold
                color={theme.WHITE}
                bgColor={theme.BASE_COLOR}
                margin="0 30px"
              >
                {isRoundTrip
                  ? t('workforce-trip-request.labels.round-trip')
                  : t('workforce-trip-request.labels.one-way')}
              </DynamicText>
            </SkewCol>
          </SkewRow>
          <DynamicText bold fontSize={16} margin="10px 10px 0 10px">
            {!isRoundTrip && (
              <>
                <DynamicText
                  bold
                  color={goingToWork ? colors.green : colors.blue}
                  fontSize={16}
                >
                  {goingToWork
                    ? t('workforce-trip-request.labels.going')
                    : t('workforce-trip-request.labels.leaving')}{' '}
                </DynamicText>
                {goingToWork
                  ? t('workforce-trip-request.labels.to')
                  : t('workforce-trip-request.labels.from')}
              </>
            )}{' '}
            {workplace?.name}
          </DynamicText>
        </Row>
        <Row spacingV={10} justify="flex-start" align="flex-start">
          <BackIcon
            onClick={() => {
              setPage(1);
              resetState(2);
            }}
            width={8}
            icon={arrowLeft}
          />
          <TextSmall
            id="back-btn"
            data-cy="back-btn"
            onClick={() => {
              setPage(1);
              resetState(2);
            }}
            margin="10px 0 0 5px"
            bold
            opacity={0.9}
            cursor
            color={colors.addressType}
          >
            {t('workforce-trip-request.buttons.back')}
          </TextSmall>
        </Row>
        <Row spacingV={10} justify="flex-start" align="flex-start">
          <Col justify="center" align="flex-start">
            <DynamicText bold margin="0 0 10px 0" color={colors.addressType}>
              {!goingToWork && !isRoundTrip
                ? t('workforce-trip-request.fields.destination.title')
                : t('workforce-trip-request.fields.pickup.title')}
            </DynamicText>
            <DynamicText margin="0 0 0 10px">
              {!goingToWork && !isRoundTrip
                ? `${getAlphaKey(dropoff?.markerKey)}${dropoff?.name || dropoff?.value}`
                : `${getAlphaKey(pickup?.markerKey)}${pickup?.name || pickup?.value}`}
            </DynamicText>
          </Col>
        </Row>
        <Row spacingV={10} justify="flex-start" align="flex-start">
          <Col id="calendar" justify="center" align="flex-start">
            <DynamicText bold>
              {t('workforce-trip-request.fields.calendar.title')}
            </DynamicText>
            <Calendar
              width={95}
              value={day}
              disabledDays={getCalendarDisabledDates(
                trDisabledDates,
                organization,
              )}
              onConfirm={e => {
                setDay(e);
              }}
              onOpen={() => {
                trackOnOpenAndClose('Calendar', 'Opened');
              }}
              onClose={() => {
                trackOnOpenAndClose('Calendar', 'Closed');
              }}
            />
          </Col>
          <Col justify="center" align="flex-start">
            {isRoundTrip ? (
              <DynamicText bold>
                {t('workforce-trip-request.fields.dropdown.shift.title')}
              </DynamicText>
            ) : (
              <DynamicText bold>
                <DynamicText
                  bold
                  color={goingToWork ? colors.green : colors.blue}
                >
                  {goingToWork
                    ? t(
                        'workforce-trip-request.fields.dropdown.time.labels.starting',
                      )
                    : t(
                        'workforce-trip-request.fields.dropdown.time.labels.ending',
                      )}
                </DynamicText>{' '}
                {t('workforce-trip-request.fields.dropdown.time.labels.at')}
              </DynamicText>
            )}
            <Shifts
              id="shifts"
              placeholder={
                isRoundTrip
                  ? t(
                      'workforce-trip-request.fields.dropdown.shift.placeholder',
                    )
                  : t('workforce-trip-request.fields.dropdown.time.placeholder')
              }
              options={getShiftTimes()}
              onChange={setShift}
              value={isRoundTrip || !shift ? shift : getLanguageTranslatedShift()}
              isMulti={false}
              isRoundTrip
              onClose={() => {
                trackOnOpenAndClose('Shift dropdown', 'Closed');
              }}
              onOpen={() => {
                trackOnOpenAndClose('Shift dropdown', 'Opened');
              }}
            />
          </Col>
        </Row>
      </>
    );
  };
  return (
    <Wrapper
      id="form-wrapper"
      largeScreen={largeScreen}
      headerHeight={headerHeight}
      onClick={largeScreen && !isLoggedIn ? goToLogin : () => {}}
    >
      {!largeScreen && (
        <Row align="flex-end" justify="flex-end">
          <IconButton data-cy="close-btn" onClick={goToHome} width={20} />
        </Row>
      )}
      <Row justify="flex-start" align="flex-start">
        <DynamicText fontSize={20} bold margin="0 0 20px 0">
          {t('workforce-trip-request.labels.title')}
        </DynamicText>
      </Row>
      {page === 1 ? renderPage1() : renderPage2()}
      <Row justify="flex-start" align="flex-start">
        <Btn
          id={page === 1 ? 'next-btn' : 'find-rides-btn'}
          data-cy={page === 1 ? 'next-btn' : 'find-rides-btn'}
          label={
            page === 1
              ? t('workforce-trip-request.buttons.next')
              : t('workforce-trip-request.buttons.find-rides')
          }
          color={theme.BASE_COLOR}
          size="md"
          onClick={() => {
            page === 1 ? setPage(2) : onSubmit();
          }}
          disabled={disabled || isSearchLoading}
          icon={isSearchLoading ? faSpinner : null}
          iconProps={{ spin: true }}
        />
      </Row>
    </Wrapper>
  );
};

WorkforceTripRequest.propTypes = {
  largeScreen: PropTypes.bool.isRequired,
  headerHeight: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  tripTypeIndex: PropTypes.number.isRequired,
  setTripTypeIndex: PropTypes.func.isRequired,
  workplace: PropTypes.object.isRequired,
  stops: PropTypes.array.isRequired,
  pickup: PropTypes.object.isRequired,
  setPickup: PropTypes.func.isRequired,
  dropoff: PropTypes.object.isRequired,
  setDropoff: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  day: PropTypes.string.isRequired,
  setDay: PropTypes.func.isRequired,
  shift: PropTypes.object.isRequired,
  setShift: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  trDisabledDates: PropTypes.array.isRequired,
  resetState: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  goToHome: PropTypes.func.isRequired,
  loading: PropTypes.string.isRequired,
  searchQuery: PropTypes.string.isRequired,
  trackOnOpenAndClose: PropTypes.func.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
  googleAddresses: PropTypes.array.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  goToLogin: PropTypes.func.isRequired,
};

export default WorkforceTripRequest;
