import Leaflet from 'leaflet';
import stop from '@assets/icons/bus-stop-multi.svg';
import workplace from '@assets/icons/office-building.svg';

const credits = {
  attribution:
    '&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
  url:
    'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
};

const stopMarkerIcon = Leaflet.icon({
  iconUrl: stop,
  iconSize: [28, 28],
});

const workplaceMarkerIcon = Leaflet.icon({
  iconUrl: workplace,
  iconSize: [28, 28],
});

const disabledStopMarkerIcon = Leaflet.icon({
  iconUrl: stop,
  iconSize: [28, 28],
  className: 'disabled',
});

const disabledWorkplaceMarkerIcon = Leaflet.icon({
  iconUrl: workplace,
  iconSize: [28, 28],
  className: 'disabled',
});

const selectedStopMarkerIcon = Leaflet.divIcon({
  html: ` <img src="${stop}" class="img-display"/> <div class="gps-ring stop-icon-border"></div>`,
  iconSize: [35, 35],
  className: 'icon-div',
});

const selectedWorkplaceMarkerIcon = Leaflet.divIcon({
  html: ` <img src="${workplace}" class="img-display"/> <div class="gps-ring office-icon-border"></div>`,
  iconSize: [35, 35],
  className: 'icon-div',
});

const tripMarkersConfig = [
  {
    icon: stopMarkerIcon,
    alt: 'Pickup Location Marker',
    key: 'pickup',
    title: 'trip-markers.stop.title',
    name: 'trip-markers.stop.name',
  },
  {
    icon: workplaceMarkerIcon,
    alt: 'Drop-off Location Marker',
    key: 'destination',
    title: 'trip-markers.workplace.title',
    name: 'trip-markers.workplace.name',
  },
];

const defaultCenter = [39.9831403, -83.2710199]; // Columbus, Ohio Coordinates

export {
  credits,
  defaultCenter,
  stopMarkerIcon,
  workplaceMarkerIcon,
  tripMarkersConfig,
  selectedStopMarkerIcon,
  selectedWorkplaceMarkerIcon,
  disabledStopMarkerIcon,
  disabledWorkplaceMarkerIcon,
};
