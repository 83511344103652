/** @format */

import styled from 'styled-components';
import Select from 'react-select';
import { theme } from '@constants';

export const MultiSelect = styled(Select)`
  .react-select__control {
    min-height: 50px;
    border: 1px solid ${theme.LIGHTEN_GRAY};
    border-radius: 8px;
    box-shadow: none;
    color: ${theme.BLACK};
    z-index: 10;
    font-family: BryantRegular;

    &:hover {
      border: 1px solid ${theme.LIGHTEN_GRAY};
    }
  }

  .react-select__menu {
    margin-top: -8px;
    border-radius: 8px;
    border: 1px solid ${theme.LIGHTEN_GRAY};
    font-family: BryantRegular;
    font-size: 18px;
    z-index: 9;
    overflow: scroll;
    height: ${props => (props.height || 200)}px;
  }

  .react-select__placeholder {
    color: ${theme.BLACK};
    font-size: 18px;
  }

  .react-select__indicators {
    color: ${theme.LIGHTEN_GRAY};
  }

  .react-select__dropdown-indicator {
    color: ${theme.LIGHTEN_GRAY};
    padding: 6px;
    svg {
      stroke-width: 0.5;
      height: 16px;
      width: 16px;
      &:hover {
        color: ${theme.LIGHTEN_GRAY};
      }
    }
    &:hover {
      color: ${theme.LIGHTEN_GRAY};
    }
  }

  .react-select__clear-indicator {
    color: ${theme.LIGHTEN_GRAY};
    padding: 4px;
    svg {
      stroke-width: 0.5;
      height: 16px;
      width: 16px;
      &:hover {
        color: ${theme.LIGHTEN_GRAY};
      }
    }
    &:hover {
      color: ${theme.LIGHTEN_GRAY};
    }
  }

  .react-select__indicator-separator {
    width: 0px;
  }

  .react-select__multi-value {
    background-color: rgba(12, 45, 131, 0.1);
    color: ${theme.BASE_COLOR};
    border-radius: 8px;
    border: 1px solid ${theme.BASE_COLOR};
    font-size: 18px;
  }

  .react-select__multi-value__label {
    background-color: rgba(12, 45, 131, 0.1);
    font-size: 16px;
    color: ${theme.BASE_COLOR};
  }

  .react-select__multi-value__remove {
    background-color: rgba(12, 45, 131, 0.1);
    color: ${theme.BASE_COLOR};
  }

  .react-select__multi-value__remove:hover {
    background-color: rgba(12, 45, 131, 0.1);
    color: ${theme.BASE_COLOR};
  }

  .react-select__option:hover {
    background-color: ${theme.BASE_COLOR};
    color: ${theme.WHITE};
  }
`;
