/** @format */

import styled from 'styled-components';
import { theme, device } from '@constants';
import { IconButton } from '..';

export const TagContainer = styled.div`
  display: flex;
  background-color: ${theme.BLUE_TRANSPARENT};
  color: ${theme.BASE_COLOR};
  border-radius: 8px;
  border: 1px solid ${theme.BASE_COLOR};
  padding: 3px 3px;
  margin-right: 3px;
  margin-top: 3px;
  align-items:center;
`;

export const Tag = styled.span`
  font-size: 16px;
  color: ${theme.BASE_COLOR};
  @media ${device.mediaQuery.SMALL_SCREEN} {
    font-size: 12px;
  }
`;

export const SIconButton = styled(IconButton)`
  margin-left: 5px;
`;
