/* eslint-disable no-underscore-dangle */
/** @format */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { analytics, generals as generalConstants } from '@constants';
import { leaflet as leafletService } from '@services';
import { useTranslation } from 'react-i18next';
import {
  WorkforceTripRequest as CWorkforceTripRequest,
  SheetModal,
} from '@components';
import { useHeaderHeight, useSessionStorage } from '@hooks';
import { trackAbEvent } from '@slices/app';
import { toast, placeHelpers } from '@utils';
import {
  workforceSearchTrips,
  setSearchQueryTripDate,
  setSearchQueryTripTime,
  setSearchQuery,
  setSearchTripTypeIndex,
} from '@slices/tripRequest';
import moment from 'moment';
import { decode } from 'html-entities';
import ReactHtmlParser from 'react-html-parser';

const WorkforceTripRequest = () => {
  const [headerHeight] = useHeaderHeight();
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPageNumber] = useState(1);
  const [tripTypeIndex, setTripType] = useState(0);
  const [workplace, setWorkplace] = useState(null);
  const [workplaceId, setWorkplaceId] = useState(null);
  const [stops, setStops] = useState([]);
  const [pickup, setPickupLocation] = useState(null);
  const [dropoff, setDropoffLocation] = useState(null);
  const [day, setDaySelected] = useState(null);
  const [shift, setShiftTime] = useState(null);
  const [trDisabledDates, setTrDisabledDates] = useState(null);
  const [closeEventSent, setCloseEventSent] = useState(null);
  const [searchQuery, setQuery] = useState(null);
  const [googleAddresses, setGoogleAddresses] = useState([]);
  const { t } = useTranslation();
  const [workforceSession, setWorkplaceSession, removeSearchData] = useSessionStorage('workforceTripRequestPayload');

  const {
    largeScreen,
    organizationPlaces,
    selectedOrganization,
    organizations,
    user,
  } = useSelector(state => state.app);
  const { loading, search } = useSelector(state => state.tripRequest);

  useEffect(() => {
    const getGoogleAddresses = async (query, organization) => {
      if (!query || !organization) return [];
      let leafletAddresses = [];

      try {
        leafletAddresses = await leafletService.search(searchQuery);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(`LEAFLET_SEARCH_ERROR ${e}`);
        return null;
      }
      const { state, state_restricted: stateRestricted } =
        organization.detail || {};
      if (state && stateRestricted && Array.isArray(leafletAddresses) && leafletAddresses.length) {
        leafletAddresses = leafletAddresses.filter(o =>
          o.label.match(
            ` ${generalConstants.STATE_NAMES_LONG_TO_SHORT[state]} `,
          ),
        );
      }

      setGoogleAddresses(leafletAddresses);
      return null;
    };

    const organization = (organizations || [])[selectedOrganization || 0];

    const restrictToOrgLocations =
      organization?.organization?.restrictToOrgLocations ||
      generalConstants.RESTRICT_TO_ORG_LOCATIONS.No;

    const isRestrictToOrgLocations =
      restrictToOrgLocations === generalConstants.RESTRICT_TO_ORG_LOCATIONS.Yes;

    if (searchQuery && !isRestrictToOrgLocations) {
      getGoogleAddresses(searchQuery, organization);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    if (
      organizations &&
      Array.isArray(organizations) &&
      (selectedOrganization || selectedOrganization === 0)
    ) {
      const { organization, workplaceId: orgPlaceId } = organizations[selectedOrganization] || {};
      const { detail } = organization;
      const { tripRequestDisabledDates } = detail || {};

      setTrDisabledDates(tripRequestDisabledDates);
      setWorkplaceId(orgPlaceId || null);
    }
  }, [selectedOrganization, organizations]);

  useEffect(() => {
    if (Array.isArray(organizationPlaces)) {
      const stopsArr = [];
      organizationPlaces.forEach(place => {
        const {
          lat,
          lng,
          orgPlaceId,
          label,
          name,
          type,
          shifts,
          timezone,
          availableDays,
          availableDaysArriveBy,
          availableTimes,
        } = place;
        if (place.type === 'STOP') {
          stopsArr.push({
            orgPlaceId,
            name,
            label,
            lat,
            lng,
            type,
            timezone,
          });
        } else if (place.type === 'WORKPLACE' && place.orgPlaceId === workplaceId) {
          if (pickup && pickup.type === 'WORKPLACE' && pickup.orgPlaceId !== workplaceId) {
            setPickupLocation(null);
            dispatch(
              setSearchQuery({
                key: 'pickupAddress',
                value: null,
              }),
            );
            if (page === 2) setPageNumber(1);
          }

          if (dropoff && dropoff.type === 'WORKPLACE' && dropoff.orgPlaceId !== workplaceId) {
            setDropoffLocation(null);
            dispatch(
              setSearchQuery({
                key: 'destinationAddress',
                value: null,
              }),
            );
            if (page === 2) setPageNumber(1);
          }
          setWorkplace({
            orgPlaceId,
            name,
            label,
            shifts,
            lat,
            lng,
            type,
            timezone,
            availableDays,
            availableDaysArriveBy,
            availableTimes,
          });
        }
      });

      setStops(placeHelpers.getAlphabetsForPlaces([...stopsArr]));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationPlaces, workplaceId]);

  useEffect(() => {
    let pickupAddress = null;
    const {
      orgPlaceId,
      name,
      label,
      lat,
      lng,
      type,
      timezone,
      shifts,
      availableDays,
      availableDaysArriveBy,
      availableTimes,
      markerKey,
    } = search.query.pickupAddress || {};

    if (pickup && pickup.orgPlaceId === orgPlaceId) return;

    if (dropoff && dropoff.orgPlaceId === orgPlaceId) {
      dispatch(
        setSearchQuery({
          key: 'pickupAddress',
          value: pickup || null,
        }),
      );
      return;
    }

    if (page === 2) {
      setPageNumber(1);
    }

    if (dropoff && type === 'STOP' && dropoff.type === 'STOP') {
      if (search.query.pickupAddress === null || search.query.pickupAddress === pickup) return;
      dispatch(
        setSearchQuery({
          key: 'pickupAddress',
          value: pickup || null,
        }),
      );
      toast.show('error', t('workforce-trip-request.messages.both-stop-location-error'));
      return;
    }

    if (type === 'STOP' || type === 'WORKPLACE') {
      pickupAddress = {
        orgPlaceId,
        name,
        label,
        lat,
        lng,
        type,
        value: label,
        shifts,
        timezone,
        availableDays,
        availableDaysArriveBy,
        availableTimes,
        markerKey,
      };
    }

    setPickupLocation(pickupAddress);
    if (search.tripTypeIndex === 1) {
      const {
        orgPlaceId,
        name,
        label,
        lat,
        lng,
        type,
        timezone,
        shifts,
        availableDays,
        availableDaysArriveBy,
        availableTimes,
      } = search.query.destinationAddress || {};
      const destination = {
        orgPlaceId,
        name,
        label,
        lat,
        lng,
        type,
        timezone,
        shifts,
        availableDays,
        availableDaysArriveBy,
        availableTimes,
      };
      if (destination) {
      setTripTypeIndex(1);
      setDropoffLocation(destination);
      setPage(2);
    }
    dispatch(
      setSearchTripTypeIndex(0),
    );
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.query.pickupAddress]);

  const getPayloadForSearch = state => {
    const { pickup, dropoff, day, shift } = state;
    const parsedShift = JSON.parse(shift.value);
    let isReturn = true;
    let isDepart = true;
    const date = moment(day).format('MM/DD/YYYY');

    // for one-way trip depart.
    const oneWay = tripTypeIndex === 0;
    if (oneWay && pickup.type === 'WORKPLACE') {
      isDepart = false;
    }

    if (oneWay && dropoff.type === 'WORKPLACE') {
      isReturn = false;
    }

    return {
      pickup: {
        label: pickup.value,
        lat: pickup.lat,
        lng: pickup.lng,
        orgPlaceId: pickup.orgPlaceId,
      },
      destination: {
        label: dropoff.value || workplace.label,
        lat: dropoff.lat,
        lng: dropoff.lng,
        orgPlaceId: dropoff.orgPlaceId,
      },
      departDate: date,
      returnDate: date,
      shift: {
        ...parsedShift,
      },
      isDepart,
      isReturn,
    };
  };

  const onSubmit = () => {
    const payload = getPayloadForSearch({ pickup, dropoff, day, shift });
    const parsedPickupLabel =
      pickup.label &&
      pickup.label.props &&
      pickup.label.props.dangerouslySetInnerHTML
        ? decode(pickup.label.props.dangerouslySetInnerHTML.__html)
        : pickup.value;
    const parsedDropoffLabel =
      dropoff.label &&
      dropoff.label.props &&
      dropoff.label.props.dangerouslySetInnerHTML
        ? decode(dropoff.label.props.dangerouslySetInnerHTML.__html)
        : dropoff.value;

    const cachedData = {
      dropoff: { ...dropoff, label: parsedDropoffLabel },
      pickup: { ...pickup, label: parsedPickupLabel },
      day,
      shift,
      isDepart: payload.isDepart,
      isReturn: payload.isReturn,
    };

    setWorkplaceSession(cachedData);
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.WORKFORCE_FLOW, {
        eventName: 'Find Rides',
        subJourneyName: 'Workforce Search',
        journeyName: 'Rider Experience',
        elementId: 'find-rides-btn',
        isDataCy: true,
        details: {
          ...payload,
        },
      }),
    );

    dispatch(
      setSearchQuery({
        key: 'pickupAddress',
        value: payload.pickup,
      }),
    );

    dispatch(
      setSearchQuery({
        key: 'destinationAddress',
        value: payload.destination,
      }),
    );

    if (payload.isDepart) {
      dispatch(
        setSearchQueryTripDate({
          type: 'depart',
          data: {
            tripDate: payload.departDate,
          },
        }),
      );

      dispatch(
        setSearchQueryTripTime({
          type: 'depart',
          data: {
            tripTime: payload.shift.startTime,
          },
        }),
      );
    }

    if (payload.isReturn) {
      dispatch(
        setSearchQueryTripDate({
          type: 'return',
          data: {
            tripDate: payload.returnDate,
          },
        }),
      );

      dispatch(
        setSearchQueryTripTime({
          type: 'return',
          data: {
            tripTime: payload.shift.endTime,
          },
        }),
      );
    }

    dispatch(workforceSearchTrips(history, payload));
  };

 useEffect(() => {
   if (workforceSession) {
     const { day, dropoff, pickup, shift, isDepart, isReturn } =
       workforceSession;
     const dropoffParsedLabel = {
       ...dropoff,
       label: ReactHtmlParser(dropoff.label),
     };
     const parsedLabel = ReactHtmlParser(pickup.label);
     const label = parsedLabel.length === 1 ? parsedLabel[0] : parsedLabel;
     const pickupParsedLabel = {
       ...pickup,
       label,
     };

     const index = isDepart && isReturn ? 1 : 0;
     setTripType(index);
     setDay(day);
     setShift(shift);
     setPickupLocation(pickupParsedLabel);
     setDropoffLocation(dropoffParsedLabel);
     setPage(2);
   }
   // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

  const disabled = page === 1 ? !pickup || !dropoff : !day || !shift;

  const setTripTypeIndex = index => {
    setTripType(index);

    let type = 'one-way';
    let elementId = 'one-way-btn';
    if (index === 1) {
      type = 'round-trip';
      elementId = 'round-trip-btn';
    }

    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.WORKFORCE_FLOW, {
        eventName: 'Trip Type Toggled',
        subJourneyName: 'Workforce Search',
        journeyName: 'Rider Experience',
        elementId,
        isDataCy: true,
        details: {
          type,
        },
      }),
    );
  };

  const setPage = pageNumber => {
    setPageNumber(pageNumber);

    let eventName = 'Next Button Clicked';
    let elementId = 'next-btn';
    if (page === 2 && pageNumber === 1) {
      eventName = 'Back Button Clicked';
      elementId = 'back-btn';
    }

    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.WORKFORCE_FLOW, {
        eventName,
        subJourneyName: 'Workforce Search',
        journeyName: 'Rider Experience',
        elementId,
        isDataCy: true,
      }),
    );
  };

  const setShift = shift => {
    setShiftTime(shift);

    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.WORKFORCE_FLOW, {
        eventName: 'Shift Selected',
        subJourneyName: 'Workforce Search',
        journeyName: 'Rider Experience',
        elementId: 'shifts',
        isDataCy: true,
        details: {
          shift,
        },
      }),
    );
  };

  const setDay = day => {
    setDaySelected(day);

    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.WORKFORCE_FLOW, {
        eventName: 'Day Selected',
        subJourneyName: 'Workforce Search',
        journeyName: 'Rider Experience',
        isDataCy: true,
        details: {
          day,
        },
      }),
    );
  };

  const setPickup = pickup => {
    if (pickup && !pickup.value) return;

    setPickupLocation(pickup);
    dispatch(
      setSearchQuery({
        key: 'pickupAddress',
        value: pickup,
      }),
    );

    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.WORKFORCE_FLOW, {
        eventName: 'Pickup Location Selected',
        subJourneyName: 'Workforce Search',
        journeyName: 'Rider Experience',
        isDataCy: true,
        details: {
          pickup: {
            ...pickup,
            label: null,
          },
        },
      }),
    );
  };

  const setDropoff = (dropoff, skipEvent) => {
    if (dropoff && !dropoff.value) return;

    setDropoffLocation(dropoff);
    dispatch(
      setSearchQuery({
        key: 'destinationAddress',
        value: dropoff,
      }),
    );
    if (skipEvent) return;

    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.WORKFORCE_FLOW, {
        eventName: 'Dropoff Location Selected',
        subJourneyName: 'Workforce Search',
        journeyName: 'Rider Experience',
        isDataCy: true,
        details: {
          dropoff: {
            ...dropoff,
            label: null,
          },
        },
      }),
    );
  };

  const resetState = page => {
    if (page === 1) {
      setPickupLocation(null);
      dispatch(
        setSearchQuery({
          key: 'pickupAddress',
          value: null,
        }),
      );
      setDropoffLocation(null);
      dispatch(
        setSearchQuery({
          key: 'destinationAddress',
          value: null,
        }),
      );
      setQuery(null);
      return;
    }

    setShiftTime(null);
    setDaySelected(null);
    setQuery(null);
  };

  const goToLogin = () => {
    history.push('/login');
  };

  const goToHome = () => {
    removeSearchData();
    resetState(1);
    history.push('/');

    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.WORKFORCE_FLOW, {
        eventName: 'Booking Window Closed',
        subJourneyName: 'Workforce Search',
        journeyName: 'Rider Experience',
        isDataCy: true,
      }),
    );
  };

  const trackOnOpenAndClose = (name, type) => {
    if (!user) {
      history.push('/login');
      return;
    }

    setQuery(null);

    if (type === 'Opened') {
      setCloseEventSent(false);
    }

    if (type === 'Closed') {
      if (closeEventSent) return;

      setCloseEventSent(true);
    }

    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.WORKFORCE_FLOW, {
        eventName: `${name} ${type}`,
        subJourneyName: 'Workforce Search',
        journeyName: 'Rider Experience',
      }),
    );
  };

  const isLoggedIn = !!user;

  const props = {
    largeScreen,
    headerHeight,
    tripTypeIndex,
    setTripTypeIndex,
    page,
    setPage,
    workplace,
    stops,
    pickup,
    setPickup,
    dropoff,
    setDropoff,
    onSubmit,
    day,
    setDay,
    shift,
    setShift,
    trDisabledDates,
    disabled,
    resetState,
    goToHome,
    loading,
    trackOnOpenAndClose,
    organization: (organizations || [])[selectedOrganization || 0],
    setSearchQuery: setQuery,
    googleAddresses,
    searchQuery,
    goToLogin,
    isLoggedIn,
  };

  const tripRequestForm = largeScreen ? (
    <CWorkforceTripRequest {...props} />
  ) : (
    <SheetModal>
      <CWorkforceTripRequest {...props} />
    </SheetModal>
  );

  return tripRequestForm;
};

export default WorkforceTripRequest;
