/** @format */

import moment from 'moment-timezone';
import geoTz from 'geo-tz';

const fixedRouteSearchResult = state => {
  let fixedRouteCardResults = [];
  let searchResultSteps = [];

  if (state.results && state.results && state.results.length > 0) {
    let fixedRouteCardsArray = [];
    let searchResultStepsArray = [];
    state.results.forEach((route, indexRoute) => {
      let fixedRouteCard = {};
      fixedRouteCard.title = route.name;
      fixedRouteCard.tripTime = route.tripTime;
      const timeZone = route.places[0].timezone || geoTz(route.places[0].lat, route.places[0].lng).toString();
      if (route.stepsDetails.length > 0) {
        if (indexRoute === state.selectedResult) {
          searchResultStepsArray = route.stepsDetails;
        }
        Object.keys(route.stepsDetails).forEach(key => {
          if (key === '0' && route.stepsDetails[key].action === 'walk') {
            fixedRouteCard.leaveAddress = route.stepsDetails[key].start.label.split(',')[0];
            fixedRouteCard.tripLeave = moment.utc(
              route.stepsDetails[key].start.time,
            ).tz(timeZone).format('MM/DD/YYYY hh:mm A');
            fixedRouteCard.walkTimeLeave =
              Math.ceil(route.stepsDetails[key].durationMinutes);
          } else {
            if (key === '0') {
              fixedRouteCard.tripLeave = '';
              fixedRouteCard.leaveAddress = '';
              fixedRouteCard.walkTimeLeave = '';
            }
          }

          if (
            key === '2' ||
            (key === '1' && route.stepsDetails[key].action === 'walk')
          ) {
            fixedRouteCard.arrivalAddress = route.stepsDetails[key].end.label.split(',')[0];
            fixedRouteCard.tripArrival = moment.utc(
              route.stepsDetails[key].end.time,
            ).tz(timeZone).format('MM/DD/YYYY hh:mm A');
            fixedRouteCard.walkTimeArrival =
              Math.ceil(route.stepsDetails[key].durationMinutes);
          } else {
            if (key === '2' || key === '1') {
              fixedRouteCard.tripArrival = '';
              fixedRouteCard.arrivalAddress = '';
              fixedRouteCard.walkTimeArrival = '';
            }
          }

          if (route.stepsDetails[key].action === 'board') {
            fixedRouteCard.pickupAddress = route.stepsDetails[key].start.label.split(',')[0];
            fixedRouteCard.tripPickup = moment.utc(
              route.stepsDetails[key].start.time,
            ).tz(timeZone).format('MM/DD/YYYY hh:mm A');
            fixedRouteCard.dropoffAddress = route.stepsDetails[key].end.label.split(',')[0];
            fixedRouteCard.tripDropoff = moment.utc(
              route.stepsDetails[key].end.time,
            ).tz(timeZone).format('MM/DD/YYYY  hh:mm A');
          }
        });
      }
      fixedRouteCardsArray.push(fixedRouteCard);
    });
    searchResultSteps = searchResultStepsArray;
    fixedRouteCardResults = fixedRouteCardsArray;
  }
  return {
    fixedRouteCardResults,
    searchResultSteps,
  };
};

export default {
  fixedRouteSearchResult,
};
