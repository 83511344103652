/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from '@components';
import { Key, Value } from './styled';

export const Field = ({ name, value }) => {
  return (
    <Row justify="flex-start" align="center" spacingV={5}>
      <Col justify="flex-start" align="flex-start">
        <Key>{name}</Key>
      </Col>

      <Col justify="flex-end" align="flex-end">
        <Value>{value}</Value>
      </Col>
    </Row>
  );
};

Field.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default Field;
