/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { SheetModal, Slider } from '@components';
import { CardWrapper, BackButton } from './styled';
import FixedRouteCardLayout from '../fixedRouteCardLayout';

const FixedRouteCardsSmall = props => {
  const {
    largeScreen,
    toggleMinimized,
    state,
    fixedRouteResults,
    selectFixedRouteResult,
    toggleCardVisible,
  } = props;

  const sliderProps = {
    dots: false,
    swipe: state.minimized ? false : true,
    arrows: state.minimized ? false : true,
    swipeToSlide: true,
    afterChange: (index) => {
      selectFixedRouteResult(index);
    },
  };

  const fixedRouteCardLayoutArray = fixedRouteResults
    ? fixedRouteResults.map((data, index) => {
        return (
          <FixedRouteCardLayout
            result={data}
            minimized={state.minimized}
            id={`fixed-route-public-map-card-small-${index}`}
            largeScreen={largeScreen}
            toggleCardVisible={toggleCardVisible}
          />
        );
      })
    : [];

  return (
    <>
      {!largeScreen && state.cardVisible && (
        <SheetModal
          id="fixed-route-public-map-card-small"
          minimizeEnabled
          toggleMinimized={toggleMinimized}
          minimized={state.minimized}
        >
          <>
            {state.results.length > 0 && !state.minimized && (
            <BackButton onClick={() => toggleCardVisible(false)} id="fixed-route-public-map-card-back-btn">
              Back
            </BackButton>
            )}
            <CardWrapper minimized={state.minimized}>
              <Slider
                views={fixedRouteCardLayoutArray}
                sliderProps={sliderProps}
              />
            </CardWrapper>
          </>
        </SheetModal>
      )}
    </>
  );
};

FixedRouteCardsSmall.propTypes = {
  fixedRouteResults: PropTypes.array.isRequired,
  state: PropTypes.shape({
    query: PropTypes.shape({
      date: PropTypes.string,
      searchInAllFixedRoutes: PropTypes.bool,
      time: PropTypes.string,
      pickup: PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.string,
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
        PropTypes.string,
      ]),
      dropoff: PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.string,
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
        PropTypes.string,
      ]),
      type: PropTypes.string,
    }).isRequired,
    minimized: PropTypes.bool,
    pickupSuggestions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
      }),
    ).isRequired,
    dropoffSuggestions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
  toggleMinimized: PropTypes.func.isRequired,
  toggleCardVisible: PropTypes.func.isRequired,
  largeScreen: PropTypes.bool.isRequired,
  selectFixedRouteResult: PropTypes.func.isRequired,
};

export default FixedRouteCardsSmall;
