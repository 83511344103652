/** @format */

import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons';
import {
  DateTimePicker,
  Row,
  Row as SwitchRow,
  Switch,
  Col as SearchRouteCol,
  Checkbox,
} from '@components';
import { useSessionStorage } from '@hooks';
import { theme, hints, generals as generalConstants } from '@constants';
import {
  FormRow,
  FormCol,
  AddressInputRow,
  AddressInput,
  FindRoutesBtn,
  Title,
  Form as SForm,
  Wrapper,
  DateInput,
  TimeInput,
  DropdownStyled,
  CheckboxWrapper,
  Tooltip,
} from './styled';

const FixedRouteSearchFormLarge = ({
  onChange,
  loading,
  disabled,
  state,
  onSelectDate,
  onSelectTime,
  onToggleSearchAll,
  onToggleType,
  handleSubmit,
  onClear,
  getAddresses,
  clearAddresses,
  onPickupSelected,
  onDropoffSelected,
  disableFixedRoutesDropdown,
  dropdownOptions,
  dropdownValue,
  onChangeFixedRoute,
  onOpenPicker,
}) => {
  const { t } = useTranslation();
  const [seenHints, setSeenHints] = useSessionStorage('fixedRouteHints');

  const pickupAddressRef = useRef(null);
  const checkboxRef = useRef(null);
  const dropdownRef = useRef(null);
  const destinationAddressRef = useRef(null);
  const dateInputRef = useRef(null);
  const timeInputRef = useRef(null);
  const typeSwitchRef = useRef(null);
  const submitBtnRef = useRef(null);

  useEffect(() => {
    if (!seenHints) {
      Tooltip.show(pickupAddressRef.current);
    }

    return () => {
      Tooltip.hide();
    };
  }, [seenHints]);

  const handlePickupAddressClick = i => {
    onPickupSelected(i);

    if (!seenHints) {
      Tooltip.hide(pickupAddressRef.current);
      Tooltip.show(destinationAddressRef.current);
    }
  };

  const handleDestinationAddressClick = i => {
    onDropoffSelected(i);

    if (!seenHints) {
      Tooltip.hide(destinationAddressRef.current);
      Tooltip.show(typeSwitchRef.current);
    }
  };

  const handleTypeChange = checked => {
    onToggleType(checked);

    if (!seenHints) {
      Tooltip.hide(typeSwitchRef.current);
      Tooltip.show(dateInputRef.current);
    }
  };

  const handleDateInputChange = value => {
    onSelectDate(value);

    Tooltip.hide(dateInputRef.current);
    Tooltip.show(timeInputRef.current);
  };

  const handleTimeInputChange = value => {
    onSelectTime(value);

    if (!seenHints) {
      Tooltip.hide(timeInputRef.current);
      Tooltip.show(dropdownRef.current);
    }
  };

  const handleDropdownChange = value => {
    onChangeFixedRoute(value);

    if (!seenHints) {
      Tooltip.hide(dropdownRef.current);
      Tooltip.show(checkboxRef.current);
    }
  };

  const handleCheckBoxChange = value => {
    onToggleSearchAll(value);

    if (!seenHints) {
      Tooltip.hide(checkboxRef.current);
      Tooltip.show(submitBtnRef.current);
    }
  };

  const onSubmit = () => {
    handleSubmit();

    if (!seenHints) {
      Tooltip.hide(submitBtnRef.current);
      setSeenHints('fixedRouteHints', true);
    }
  };

  const getFixedRouteName = name => {
    if (name.length > 10 && window.innerWidth <= 1270) {
      return `${name.slice(0, 8)} ...`;
    }
    return name;
  };

  const renderDateInput = (_inputProps, openCalender) => {
    return (
      <DateInput
        data-cy="fixed-route-date-input"
        id="fixed-route-date-input"
        inputRef={dateInputRef}
        data-tip={t(hints.fixedRouteLargeHints.date)}
        data-event="custom"
        data-for="fixed-route-tooltip"
        name="date"
        value={state.query.date}
        label={t('fixed-route.large-screen.fields.date.placeholder')}
        onFocus={openCalender}
        color={theme.BASE_COLOR}
        inverted
        readOnly
        autoComplete="off"
      />
    );
  };

  const renderTimeInput = (_inputProps, openCalender) => {
    return (
      <TimeInput
        data-cy="fixed-route-time-input"
        id="fixed-route-time-input"
        inputRef={timeInputRef}
        data-tip={t(hints.fixedRouteLargeHints.time)}
        data-event="custom"
        data-for="fixed-route-tooltip"
        name="time"
        value={state.query.time}
        label={
          _inputProps?.placeholder ||
          t('fixed-route.large-screen.fields.time.placeholder')
        }
        color={theme.BASE_COLOR}
        inverted
        readOnly
        onFocus={openCalender}
        autoComplete="off"
      />
    );
  };

  return (
    <SForm justify="flex-start" align="flex-start">
      <Wrapper>
        <SearchRouteCol justify="flex-start" align="flex-start">
          <Title>{t('fixed-route.large-screen.labels.title')}</Title>
          <FormRow
            data-cy="fixed-route-form-large"
            justify="flex-start"
            align="flex-start"
          >
            <Tooltip
              id="fixed-route-tooltip"
              backgroundColor={theme.WEIRD_GREEN}
              textColor={theme.WHITE}
              isCapture
            />
            <FormCol justify="space-between" align="flex-start">
              <AddressInputRow
                flex="initial"
                justify="flex-start"
                align="flex-start"
              >
                <AddressInput
                  data-cy="pickup-address-input"
                  id="pickup-address-input"
                  inputProps={{
                    inputRef: pickupAddressRef,
                    'data-tip': t(hints.fixedRouteLargeHints.pickupAddress),
                    'data-event': 'custom',
                    'data-for': 'fixed-route-tooltip',
                    name: 'pickup',
                    onChange,
                    onFocus: () => {
                      getAddresses('pickup');
                    },
                    value: state.query.pickup.label || state.query.pickup,
                    label: t(
                      'fixed-route.large-screen.fields.pickup.placeholder',
                    ),
                    color: theme.BASE_COLOR,
                    inverted: true,
                    icon: faSearch,
                    onClear: () => {
                      onClear('pickup');
                    },
                  }}
                  suggestions={state.pickupSuggestions}
                  onSuggestionClick={handlePickupAddressClick}
                  clearSuggestions={clearAddresses}
                />
              </AddressInputRow>

              <Row
                id="time-row"
                flex="initial"
                spacingV={10}
                justify="flex-start"
                align="flex-start"
              >
                <DateTimePicker
                  placeholder="Date"
                  onChange={handleDateInputChange}
                  dateFormat="ddd, MMM DD"
                  renderInput={renderDateInput}
                />

                <DateTimePicker
                  placeholder={t(
                    'fixed-route.large-screen.fields.time.placeholder',
                  )}
                  onChange={handleTimeInputChange}
                  timeFormat="hh:mm A"
                  renderInput={renderTimeInput}
                  value={state.query.time}
                  onOpen={onOpenPicker}
                />
              </Row>
            </FormCol>
            <FormCol justify="space-between" align="flex-start">
              <AddressInputRow
                flex="initial"
                justify="flex-start"
                align="flex-start"
              >
                <AddressInput
                  data-cy="destination-address-input"
                  id="destination-address-input"
                  inputProps={{
                    inputRef: destinationAddressRef,
                    'data-tip': t(hints.fixedRouteLargeHints.destinationAddress),
                    'data-event': 'custom',
                    'data-for': 'fixed-route-tooltip',
                    name: 'dropoff',
                    onChange,
                    onFocus: () => {
                      getAddresses('dropoff');
                    },
                    value: state.query.dropoff.label || state.query.dropoff,
                    label: t(
                      'fixed-route.large-screen.fields.destination.placeholder',
                    ),
                    color: theme.BASE_COLOR,
                    inverted: true,
                    icon: faSearch,
                    onClear: () => {
                      onClear('dropoff');
                    },
                  }}
                  suggestions={state.dropoffSuggestions}
                  onSuggestionClick={handleDestinationAddressClick}
                  clearSuggestions={clearAddresses}
                />
              </AddressInputRow>
              <Row justify="space-between" align="center" spacingV={10}>
                <div
                  style={{ width: '50%' }}
                  ref={dropdownRef}
                  data-tip={t(hints.fixedRouteSmallHints.dropdown)}
                  data-event="custom"
                  data-for="fixed-route-tooltip"
                >
                  <DropdownStyled
                    id="fixed-routes-dropdown-large"
                    value={
                      !disableFixedRoutesDropdown
                        ? t('fixed-route.large-screen.fields.search-all')
                        : getFixedRouteName(dropdownValue)
                    }
                    options={dropdownOptions}
                    onChange={handleDropdownChange}
                    inverted
                    disabled={!disableFixedRoutesDropdown}
                    size="sm"
                  />
                </div>

                <CheckboxWrapper>
                  <div
                    ref={checkboxRef}
                    data-tip={t(hints.fixedRouteSmallHints.checkbox)}
                    data-event="custom"
                    data-for="fixed-route-tooltip"
                  >
                    <Checkbox
                      label={t(
                        'fixed-route.large-screen.fields.search-all',
                      )}
                      value={state.query.searchInAllFixedRoutes}
                      largeScreen
                      inverted
                      onChange={handleCheckBoxChange}
                    />
                  </div>
                </CheckboxWrapper>
              </Row>
            </FormCol>
            <FormCol justify="space-between" align="flex-start">
              <SwitchRow flex="initial" justify="flex-start" align="flex-start">
                <Switch
                  data-cy="is-arrive-by-switch"
                  switchRef={typeSwitchRef}
                  data-tip={t(hints.fixedRouteLargeHints.toggle)}
                  data-event="custom"
                  data-for="fixed-route-tooltip"
                  id="is-arrive-by-switch"
                  checked={state.query.type === 'Arrive By'}
                  onChange={handleTypeChange}
                  unCheckedText={t(
                    'fixed-route.large-screen.fields.switch.labels.ready-by',
                  )}
                  checkedText={t(
                    'fixed-route.large-screen.fields.switch.labels.arrive-by',
                  )}
                  width={180}
                  height={38}
                  bgColor={theme.LIGHT_GREY}
                  triggerColor={theme.WEIRD_GREEN}
                />
              </SwitchRow>
              <Row
                spacingV={10}
                flex="initial"
                justify="flex-start"
                align="flex-start"
              >
                <FindRoutesBtn
                  data-cy="find-routes-btn"
                  btnRef={submitBtnRef}
                  data-tip={t(hints.fixedRouteLargeHints.submitBtn)}
                  data-event="custom"
                  data-for="fixed-route-tooltip"
                  id="fixed-route-submit-btn"
                  onClick={onSubmit}
                  label={t('fixed-route.large-screen.buttons.find-rides.label')}
                  color={theme.WEIRD_GREEN}
                  icon={
                    loading === generalConstants.SEARCH_FIXED_ROUTES_LOADING
                      ? faSpinner
                      : null
                  }
                  iconProps={{ spin: true }}
                  disabled={
                    disabled ||
                    loading === generalConstants.SEARCH_FIXED_ROUTES_LOADING
                  }
                />
              </Row>
            </FormCol>
          </FormRow>
        </SearchRouteCol>
      </Wrapper>
    </SForm>
  );
};

FixedRouteSearchFormLarge.propTypes = {
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  state: PropTypes.shape({
    query: PropTypes.shape({
      date: PropTypes.string,
      time: PropTypes.string,
      searchInAllFixedRoutes: PropTypes.bool,
      pickup: PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.string,
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
        PropTypes.string,
      ]),
      dropoff: PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.string,
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
        PropTypes.string,
      ]),
      type: PropTypes.string,
    }).isRequired,
    pickupSuggestions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
      }),
    ).isRequired,
    dropoffSuggestions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
  onSelectDate: PropTypes.func.isRequired,
  onSelectTime: PropTypes.func.isRequired,
  onToggleSearchAll: PropTypes.func.isRequired,
  onToggleType: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  getAddresses: PropTypes.func.isRequired,
  clearAddresses: PropTypes.func.isRequired,
  onPickupSelected: PropTypes.func.isRequired,
  onDropoffSelected: PropTypes.func.isRequired,
  disableFixedRoutesDropdown: PropTypes.bool.isRequired,
  dropdownOptions: PropTypes.array.isRequired,
  dropdownValue: PropTypes.string.isRequired,
  onChangeFixedRoute: PropTypes.func.isRequired,
  onOpenPicker: PropTypes.func.isRequired,
};

FixedRouteSearchFormLarge.defaultProps = {
  loading: null,
};

export default FixedRouteSearchFormLarge;
