/** @format */

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styles as styleUtils } from '@utils';
import { theme, device } from '@constants';

import {
  SuggestionInput,
  Dropdown,
  DateOfBirthPicker,
  IconButton,
  Input,
  Row,
  LanguageSelectorDropdown,
} from '..';

export const Wrapper = styled.div`
  ${styleUtils.flex('space-between', 'center', 'column')}
  flex: 1;
  font-family: Bryant;

  button[width='20'] {
    margin-top: 0 !important;
    @media ${device.mediaQuery.SMALL_SCREEN} {
      position: absolute;
      margin: 15px 0;
      font-size: 24px;
      top: 24px !important;
      right: 20px;
    }
  }

  @media ${device.mediaQuery.SMALL_SCREEN} {
    padding: 20px;
  }

  @media ${device.mediaQuery.LARGE_SCREEN} {
    padding: 20px 24px 20px 24px;
    height: 100%;
    overflow-y: auto;
  }
`;

export const DOBPicker = styled(DateOfBirthPicker)`
  margin-bottom: 10px;
`;

export const TitleWrapper = styled.div`
  ${styleUtils.flex('center', 'flex-start', 'column')};
`;

export const Title = styled.h6`
  color: ${theme.COD_GRAY_LIGHT};
  font-weight: 600;
  font-size: 26px;
  margin: 0px;
`;

export const SubTitle = styled.p`
  color: ${theme.COD_GRAY_LIGHT};
  font-size: 22px;
  margin-bottom: 10px;
  @media ${device.mediaQuery.SMALL_SCREEN} {
    font-size: 16px;
  }
`;

export const CareGiverText = styled.span`
  font-size: 22px;
  a {
    font-size: 22px !important;
  }
  @media ${device.mediaQuery.SMALL_SCREEN} {
    font-size: 16px;
    a {
      font-size: 16px !important;
    }
  }
`;

export const FormWrapper = styled.div`
  ${styleUtils.flex('center', 'center', 'column')}
`;

export const CloseIconRow = styled(Row)`
  margin-left: 8px;
`;

export const Form = styled.div`
  ${props =>
    props.largeScreen
      ? styleUtils.flex('center', 'flex-start', 'row')
      : styleUtils.flex('center', 'flex-start', 'column')};
  flex-wrap: wrap;
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & > * {
    margin: 8px 0px;
    flex: 45%;
  }

  & > :nth-child(even) {
    margin-left: ${props => (props.largeScreen ? '20px' : '0px')};
  }
`;

export const SavedAddressesWrapper = styled.div`
  ${styleUtils.flex('flex-start', 'flex-start', 'column')}
`;

export const SavedAddressLabel = styled.span`
  font-size: 8px;
  letter-spacing: 1px;
  margin: 10px 0;
  color: ${theme.BLUE_GREY};
`;

export const SavedAddress = styled.div`
  ${styleUtils.flex('space-between', 'center')}
`;

export const SavedAddressTag = styled.span`
  width: 85px;
`;

export const SavedAddressValue = styled.span`
  flex: 1;
  color: ${theme.BASE_COLOR};
  margin: 6px 0 0 10px;
`;

export const AddressInput = styled(SuggestionInput)`
  margin-top: 3px;
  width: 100%;

  Suggestions {
    left: 20px;
  }
`;

export const BottomWrapper = styled.div`
  ${styleUtils.flex('flex-start', 'center', 'column')}
  margin-top: 25px;

  button {
    border-radius: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    height: 50px;
  }
`;

export const DOB = styled.div`
  ${styleUtils.flex('flex-start', 'flex-start', 'row')}
  width: 100%;
`;

export const LoginText = styled.div`
  font-size: 22px;
  align-self: ${props => (props.largeScreen ? 'center' : 'start')};
  margin-bottom: 15px;

  @media ${device.mediaQuery.SMALL_SCREEN} {
    width: 90%;
    font-size: 16px;
  }
`;

export const LoginLink = styled.a`
  color: ${props => props.color};
  text-decoration: none;
  font-weight: bold;
  font-size: 22px;
  cursor: pointer;
  @media ${device.mediaQuery.SMALL_SCREEN} {
    font-size: 16px;
  }
`;

export const AddIcon = styled(FontAwesomeIcon)`
  color: ${theme.BRIGHT_SKY_BLUE};
  font-size: 16px;
  margin-top: 10px;
  cursor: pointer;
`;

export const AddressTypeDropDown = styled(Dropdown)`
  box-shadow: none;

  .rdd-menu {
    z-index: 6;
  }
`;

export const CloseIconStyled = styled(IconButton)`
  margin-top: 0px;
  z-index: 2;

  @media ${device.mediaQuery.SMALL_SCREEN} {
    position: absolute;
    margin: 15px 0;
    top: 24px;
  }
`;

export const DeleteIcon = styled(FontAwesomeIcon)`
  color: ${theme.TOMATO};
  font-size: 18px;
  margin-left: 5px;
  cursor: pointer;
`;

export const IconWrapper = styled.span`
  margin-top: 5px;
`;

export const TermsAndConditionsText = styled.span`
  margin-top: 8px;
  font-size: 22px;

  @media ${device.mediaQuery.SMALL_SCREEN} {
    font-size: 18px;
  }
`;

export const TermsAndConditionsLink = styled.a`
  color: ${theme.BASE_COLOR};
  font-weight: bold;
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const SInput = styled(Input)`
  span {
    color: ${theme.INPUT_LABEL_COLOR};
  }
`;

export const SLanguageSelectorDropdown = styled(LanguageSelectorDropdown)`
  .rdd-menu {
    max-height: 150px;
  }
`;
