/** @format */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { analytics } from '@constants';
import { Map as CMap } from '@components';
import { setSearchQuery } from '@slices/tripRequest';
import {
  setCurrentLocation,
  setVisibleMarker,
  trackAbEvent,
  setSelectedOrganizationPlaceMarker,
  getNearbyBusStops,
  setBusStopMarkers,
} from '@slices/app';
import { LatLng } from 'leaflet';
import { helpers, placesTimingHelpers } from '@utils';
import { useLocation } from 'react-router';

const mapMarkerZoomConstant = 17;

const appStateKeys = [
  'currentLocation',
  'largeScreen',
  'tripMapMarkers',
  'busStopMarkers',
  'organizationPlaces',
  'personalAddressesMarker',
  'visibleMarker',
  'tripPath',
  'resizeMapKey',
  'selectedOrganizationPlaceMarker',
  'showOrgPlaceMarkerSlider',
];

const useSelectorEqualityCheckForAppState = (appState, prevAppState) =>
  helpers.areObjectKeysEqual(appStateKeys, appState, prevAppState);

const Map = ({ isServiceAreaMap }) => {
  const [organizationPlacesMapMarkers, setOrganizationPlacesMapMarkers] =
    useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const mapRef = useRef(null);
  const orgPlacesRef = useRef(null);
  let timeout = null;

  const {
    currentLocation,
    largeScreen,
    tripMapMarkers,
    busStopMarkers,
    organizationPlaces,
    personalAddressesMarker,
    visibleMarker,
    tripPath,
    resizeMapKey,
    selectedOrganizationPlaceMarker,
    showOrgPlaceMarkerSlider,
    signupOrganizationPlaces,
  } = useSelector(state => state.app, useSelectorEqualityCheckForAppState);

  const serviceAreaLocation = { lat: 41.65839, lng: -83.559601 };

  useEffect(() => {
    const validPaths = (location.pathname === '/' ||
      location.pathname.includes('/org') ||
      location.pathname.includes('/trip-request'));
    if (
      validPaths &&
      !helpers.deepCompareArraysofObjects(orgPlacesRef.current, organizationPlaces, 'orgPlaceId')
    ) {
      orgPlacesRef.current = organizationPlaces;
      setOrganizationPlacesMapMarkers(
        placesTimingHelpers.calculateAvailableTimings(organizationPlaces),
      );
    } else if (!validPaths) {
      orgPlacesRef.current = null;
      setOrganizationPlacesMapMarkers([]);
    }
  }, [organizationPlaces, location]);

  useEffect(() => {
    if (
      selectedOrganizationPlaceMarker?.lat &&
      selectedOrganizationPlaceMarker?.lng &&
      selectedOrganizationPlaceMarker?.shouldFlyToMarker &&
      mapRef?.current?.flyTo
    ) {
      flyToPosition(
        selectedOrganizationPlaceMarker?.lat,
        selectedOrganizationPlaceMarker?.lng,
        mapMarkerZoomConstant,
      );
    }
    // eslint-disable-next-line
  }, [
    selectedOrganizationPlaceMarker?.name, // eslint-disable-line
    selectedOrganizationPlaceMarker?.shouldFlyToMarker, // eslint-disable-line
  ]);

  useEffect(() => {
    // This one is just to do forcefull rendering
    setTimeout(() => {
      if (
        isHome() &&
        mapRef?.current?.setZoom &&
        helpers.browserName()?.toLowerCase() !== 'chrome'
      ) {
        mapRef.current.setZoom(mapRef.current.getZoom() - 1);
      }
    }, 1000);
    // eslint-disable-next-line
  }, [showOrgPlaceMarkerSlider, location.pathname]);

  const isHome = () => {
    return location.pathname === '/' || location.pathname.includes('/org');
  };

  const onAddressClick = (key, selected) => {
    dispatch(
      setSearchQuery({
        key: `${key}Address`,
        value: selected,
      }),
    );
  };
  const onChangeVisibleMarker = value => {
    dispatch(setVisibleMarker(value));
  };

  const onOrganziationPlacesMapMarkerSelect = place => {
    if (timeout === null) {
      timeout = window.setTimeout(() => {
        timeout = null;
        dispatch(setSelectedOrganizationPlaceMarker(place));
      }, 300);
    }
  };

  const onBusStopsMarkerSelect = busStop => {
    if (timeout === null) {
      timeout = window.setTimeout(() => {
        timeout = null;
        onAddressClick('pickup', busStop);
        dispatch(setSelectedOrganizationPlaceMarker(busStop));
        dispatch(
          trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
            eventName: analytics.SELECT_BUS_STOP_MARKER,
            subJourneyName: 'Explore Dashboard',
            journeyName: 'Rider Experience',
            details: {
              busStop,
              currentLocation,
              route: location.pathname,
            },
          }),
        );
      }, 300);
    }
  };

  useEffect(() => {
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.MAP_VIEW,
        subJourneyName: 'Explore Dashboard',
        journeyName: 'Rider Experience',
        details: {
          currentLocation,
          tripMapMarkers,
          busStopMarkers,
          organizationPlacesMapMarkers,
          personalAddressesMarker,
        },
      }),
    );
    getCurrentLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const getCurrentLocation = (flyToCurrentLocation = false) => {
    navigator.geolocation.getCurrentPosition(pos => {
      const {
        coords: { latitude, longitude },
      } = pos;
      dispatch(setCurrentLocation([latitude, longitude]));
      if (flyToCurrentLocation) {
        flyToPosition(latitude, longitude, mapMarkerZoomConstant);
      }
    });
  };
  const whenCreatedHandler = mapInstance => {
    mapRef.current = mapInstance;
  };

  const handleClusterClick = cluster => {
    const placeMarker = {
      ...cluster?.layer?.getAllChildMarkers()?.[0]?.options?.markerData,
      shouldFlyToMarker: false,
    };
    const bounds = cluster?.layer?.getBounds();
    const latLng = cluster?.latlng;
    // eslint-disable-next-line no-underscore-dangle
    const currentZoom = (cluster?.target?._zoom || 0);
    // eslint-disable-next-line no-underscore-dangle
    const maxZoom = (cluster?.target?._maxZoom || 0);
    if (currentZoom !== maxZoom) {
      if (bounds) {
        const boundOptions = {
          padding: [100, 100],
        };
        mapRef.current.flyToBounds(bounds, boundOptions);
      } else if (latLng?.lat && latLng?.lng) {
        flyToPosition(latLng?.lat, latLng?.lng, currentZoom + 2);
      }
    }

    if (placeMarker) {
      dispatch(setSelectedOrganizationPlaceMarker(placeMarker));
    }
  };

  const flyToPosition = (lat, lng, zoomConstant = mapMarkerZoomConstant) => {
    mapRef.current.flyTo(new LatLng(lat, lng), zoomConstant);
  };

  useEffect(() => {
    if (currentLocation?.length && isHome()) {
      dispatch(getNearbyBusStops(currentLocation));
    } else {
      dispatch(setBusStopMarkers([]));
    }
    return () => {
      dispatch(setBusStopMarkers([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentLocation, location.pathname]);

  return (
    <CMap
      largeScreen={largeScreen}
      tripMarkers={tripMapMarkers}
      busStopMarkers={busStopMarkers}
      currentPosition={isServiceAreaMap ? serviceAreaLocation : currentLocation}
      organizationPlacesMapMarkers={organizationPlacesMapMarkers}
      onAddressClick={onAddressClick}
      personalAddresses={personalAddressesMarker}
      visibleMarker={visibleMarker}
      onChangeVisibleMarker={onChangeVisibleMarker}
      tripPath={tripPath}
      resizeMapKey={resizeMapKey}
      onOrganziationPlacesMapMarkerSelect={onOrganziationPlacesMapMarkerSelect}
      whenCreatedHandler={whenCreatedHandler}
      handleClusterClick={handleClusterClick}
      getCurrentLocation={getCurrentLocation}
      signupOrganizationPlaces={signupOrganizationPlaces}
      isServiceAreaMap={isServiceAreaMap}
      onBusStopsMarkerSelect={onBusStopsMarkerSelect}
    />
  );
};

export default Map;
