/** @format */

import styled from 'styled-components';

import { theme } from '@constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row } from '..';

export const Container = styled.div`
  border-top: 1px solid ${theme.BASE_COLOR};
  padding: 10px 10px;
  width: 100%;

  .label, .heading {
    color: ${theme.BASE_COLOR};
  }

`;

export const Label = styled.label`
  font-size: 16px;
  color: ${theme.BASE_COLOR};
  letter-spacing: 1px;
  flex: 1;
`;
export const Heading = styled.div`
  font-size: 16px;
  color: ${theme.BASE_COLOR};
  letter-spacing: 1px;
  font-weight: bold;
  flex: 1;
`;

export const LoaderWrapperRow = styled(Row)`
  height: auto;
  padding: 1px 0px 0 5px;
  display: flex;
  justify-content: flex-start;
`;

export const LoaderIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
`;
