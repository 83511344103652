/** @format */

// 3rd party Imports
import styled from 'styled-components';

// Internal Imports
import { Button } from '@components';
import { theme, device } from '@constants';

export const WorkplaceForm = styled.div`
  position: relative;
  border-radius: inherit;
  background: ${theme.WHITE};
`;

export const ModalContentWrapper = styled.div`
  overflow: auto;
  background-color: ${theme.WHITE};
  padding: 20px;
  max-height: 75vh;
  border-radius: inherit;
  @media ${device.mediaQuery.SMALLER_THAN_448} {
    width: 100%;
    max-height: 75vh;
    margin: auto;
  }
`;

export const Heading = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24.25px;
  text-align: left;
  color: rgba(0, 0, 0, 0.96);
`;

export const Paragraph = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  color: rgba(0, 0, 0, 0.96);
  margin-bottom: ${props => props.marginBottom || 0}px;
`;

export const ButtonContainer = styled.div`
  width: ${props => props.width};
  margin: auto;
  text-align: center;
  @media ${device.mediaQuery.SMALLER_THAN_448} {
    width: 96%;
    margin: auto;
  }
`;

export const ActionButton = styled(Button)`
  width: 128px;
  height: 53px;
  font-size: 13px;
  border-radius: 5px;
`;
