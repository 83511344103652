/** @format */

// 3rd party imports
import React, { useState } from 'react';
import StarRatings from 'react-star-ratings';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import propTypes from 'prop-types';
import moment from 'moment';
import { useTranslation, Trans } from 'react-i18next';

// internal Imports
import { Row, Col } from '@components';
import { theme, generals as generalConstants } from '@constants';
import {
  CloseIcon,
  Avatar,
  UserImage,
  RideDescription,
  ContentContainer,
  Comment,
  ActionButton,
  ModalContentWrapper,
  FeedbackHeading,
  RideForm,
  UserNameImage,
} from './styled';

// eslint-disable-next-line import/no-cycle
import FeedBackMultiSelect from './feedbackMultiselect';

// props will be addeed in Setup section //dummy values will change according to apis response
const RateRideForm = ({
  tripDetails: {
    driver: { name, photo },
    start,
  },
  loading,
  onSubmit,
  onCloseModal,
  ratingSubmitted,
}) => {
  const { t } = useTranslation();
  const [rideFeedback, setRideFeedback] = useState({
    compliments: [],
    rating: 0,
    comment: '',
  });
  const disableSaveBtn =
    !rideFeedback.rating || loading === generalConstants.RATE_RIDE_LOADING;
  const disableSkipBtn = loading === generalConstants.RATE_RIDE_LOADING;
  const onChange = (key, value) => {
    setRideFeedback({ ...rideFeedback, [key]: value });
  };

  const driverNameAvatar = name[0].toUpperCase();

  let modalContent = (
    <>
      {/* Avatar With negetive Margin */}
      <Avatar justify="center" spacingV={12}>
        {photo ? (
          <UserImage src={photo} />
        ) : (
          <UserNameImage justify="center" align="center">
            {driverNameAvatar}
          </UserNameImage>
        )}
      </Avatar>
      {/* Content Section */}
      <ModalContentWrapper>
        <Row align="flex-start" justify="center" spacingV={12}>
          <FeedbackHeading>{t('rate-ride.labels.title')}</FeedbackHeading>
        </Row>
        {/* Values will replace in setup section */}
        <Row align="flex-start" justify="center">
          <RideDescription>
            <Trans
              i18nKey="rate-ride.labels.rate-ride"
              components={{ span: <span /> }}
              values={{
                driver: name.split(' ')[0].toUpperCase(),
                date: moment(start).format('L'),
              }}
            />
          </RideDescription>
        </Row>
        <Row align="flex-start" justify="center" spacingV={14}>
          <StarRatings
            starRatedColor={theme.STAR_YELLOW}
            changeRating={val => onChange('rating', val)}
            rating={rideFeedback.rating}
            starHoverColor={theme.STAR_YELLOW}
            isAggregateRating
            feedback={rideFeedback}
            starDimension="40px"
            isSelectable
            starEmptyColor={theme.LIGHT_GREY}
            numberOfStars={5}
            name="rating"
          />
        </Row>
        <Row align="flex-start" justify="center" spacingV={4}>
          <ContentContainer width="86%">
            <p>
              {t(
                'rate-ride.fields.feedback.label',
                name.split(' ')[0].toUpperCase(),
              )}
            </p>
            <FeedBackMultiSelect
              onChangeFeedback={onChange}
              compliements={rideFeedback.compliments}
            />
          </ContentContainer>
        </Row>
        <Col justify="center" align="flex-start" spacingV={10}>
          <ContentContainer width="85%">
            <p>{t('rate-ride.fields.comments.label')}</p>
            <Comment
              name="comment"
              value={rideFeedback.comment}
              placeholder={t('rate-ride.fields.comments.placeholder')}
              rows="5"
              onChange={({ target: { name, value } }) => onChange(name, value)}
            />
          </ContentContainer>
        </Col>
        <ContentContainer width="85%">
          <Row align="flex-start" justify="space-between" spacingV={8}>
            <ActionButton
              disabled={disableSkipBtn}
              label={t('rate-ride.buttons.skip')}
              color={theme.BASE_COLOR}
              size="md"
              inverted
              onClick={() => onCloseModal(true)}
              bordered
              data-cy="rating-skip-btn"
            />
            <ActionButton
              disabled={disableSaveBtn}
              label={t('rate-ride.buttons.save')}
              color={theme.BASE_COLOR}
              size="md"
              onClick={() => onSubmit(rideFeedback)}
              icon={
                loading === generalConstants.RATE_RIDE_LOADING
                  ? faSpinner
                  : null
              }
              iconProps={{ spin: true }}
              bordered
              data-cy="rating-save-btn"
            />
          </Row>
        </ContentContainer>
      </ModalContentWrapper>
    </>
  );

  if (ratingSubmitted) {
    modalContent = (
      <ModalContentWrapper>
        <Row align="flex-start" justify="center" spacingV={12}>
          <FeedbackHeading>
            {t('rate-ride.labels.thank-you')}
          </FeedbackHeading>
        </Row>
      </ModalContentWrapper>
    );
  }

  return (
    <RideForm>
      <Row align="flex-end" justify="flex-end">
        <CloseIcon
          width={20}
          onClick={() => onCloseModal(false)}
        />
      </Row>
      {modalContent}
    </RideForm>
  );
};

RateRideForm.propTypes = {
  tripDetails: propTypes.object,
  onSubmit: propTypes.func.isRequired,
  onCloseModal: propTypes.func.isRequired,
  loading: propTypes.string,
  ratingSubmitted: propTypes.string,
};
RateRideForm.defaultProps = {
  tripDetails: null,
  loading: null,
  ratingSubmitted: null,
};

export default RateRideForm;
