/** @format */

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { device, theme } from '@constants';
import { Row, Col, Button, Input } from '..';

export const PaymentMethodsCol = styled(Col)`
  min-height: 100%;

  @media ${device.mediaQuery.SMALL_SCREEN} {
    padding: 10px 15px;
  }

  @media ${device.mediaQuery.LARGE_SCREEN} {
    padding: 30px;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const LoaderWrapperRow = styled(Row)`
  height: 100%;
`;

export const LoaderIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
`;

export const PaymentMethodsTop = styled.div`
  width: 100%;
`;

export const PaymentMethodsBottomRow = styled(Row)`
  min-height: 38px;
`;

export const AddCardButton = styled.button`
  color: ${theme.BASE_COLOR};
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  background: none;
  border: none;
  padding: 0;
  &:hover {
    color: ${theme.BASE_COLOR};
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
`;

export const Title = styled.h6`
  color: ${theme.BASE_COLOR};
  font-weight: 600;

  @media ${device.mediaQuery.SMALL_SCREEN} {
    margin-top: 20px;
  }
`;

export const AddedPaymentMethodsCol = styled(Col)`
  margin-bottom: ${props => (props.canAddPaymentMethods ? 15 : 0)}px;
`;

export const AddedPaymentMethod = styled.button`
  display: block;
  width: 100%;
  height: 45px;
  margin-bottom: 15px;
  border: none;
  background-color: ${theme.TRANSPARENT};
  text-align: left;

  :focus {
    outline: none;
  }
`;

export const AddedPaymentMethodLabel = styled.div`
  font-size: 10px;
  letter-spacing: 1px;
  color: ${theme.BLUE_GREY};
  margin-bottom: 4px;
`;

export const AddedPaymentMethodText = styled.div`
  color: ${theme.BASE_COLOR};
  line-height: 20px;
`;

export const NoPaymentMethodsText = styled.span`
  color: ${theme.TOMATO};
`;

export const AddCardCol = styled(Col)``;

export const CardName = styled.div`
  width: 100%;
  height: 45px;
  margin-bottom: 15px;
  border-bottom: 1px solid ${theme.BASE_COLOR};
`;

export const CardNameInput = styled(Input)`
  input {
    padding: 0;
    height: 20px;
    color: ${theme.BASE_COLOR};

    ::placeholder {
      color: ${theme.GREY};
    }
  }
`;

export const CardNumber = styled.div`
  width: 100%;
  height: 45px;
  margin-bottom: 15px;

  .card-number {
    border-bottom: 1px solid ${theme.BASE_COLOR};
    padding-bottom: 8px;
  }
`;

export const CardInputLabel = styled.div`
  font-size: 10px;
  letter-spacing: 1px;
  color: ${theme.BLUE_GREY};
  margin-bottom: 4px;
`;

export const CardDetailsRow = styled(Row)`
  border-bottom: 1px solid ${theme.BASE_COLOR};
  margin-bottom: 15px;

  .card-expiry,
  .card-cvc {
    padding-bottom: 8px;
  }
`;

export const CardDetailItem = styled.div`
  width: 33.33%;
  height: 45px;
`;

export const ZipCodeInput = styled(Input)`
  input {
    padding: 0;
    height: 20px;
    color: ${theme.BASE_COLOR};

    ::placeholder {
      color: ${theme.GREY};
    }
  }
`;

export const CloseButton = styled(Button)`
  width: 35%;
  font-weight: 600;
  margin-right: 10px;
`;

export const SaveButton = styled(Button)`
  width: 65%;
`;

export const DeleteIcon = styled(FontAwesomeIcon)`
  color: ${theme.TOMATO};
  font-size: 18px;
  margin-left: 5px;
  cursor: pointer;
`;
