/** @format */

import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { theme, generals as generalConstants, hints } from '@constants';
import { useLocalStorage } from '@hooks';
import {
  Row as HeaderRow,
  Col,
  Slider,
  Row,
  FrequencyPickerWorkforce,
  WorkforceTripPathDetails,
} from '@components';
import {
  TripOverviewWorkforceCol,
  Title,
  EditBtn,
  TripDetailsRow,
  SubmitBtn,
  FrequencyPickerRow,
  MinimizableCol,
  TooltipWrapper,
  Tooltip
} from './styled';
import TripOverviewDateTime from './tripOverviewDateTime';
import SearchReturnTrip from './searchReturnTrip';

const getAddresses = trip => {
  const [pickup, dropoff] = trip.places;
  return {
    pickup: {
      address: pickup.address,
      name: pickup.name,
    },
    destination: {
      address: dropoff.address,
      name: dropoff.name,
    },
  };
};

const TripOverview = ({
  state,
  isValidDate,
  getValidTimes,
  loading,
  selectedTripDetail,
  onEdit,
  onBookRide,
  repeat,
  onChangeFrequency,
  minimized,
  largeScreen,
  onToggleDateTime,
  isDateTimeChanged,
  setChangedDate,
  setChangedTime,
  changedDate,
  changedTime,
  isValidEndDate,
  getValidTimesReturn,
  onToggleIsReturn,
  setReturnDate,
  setReturnTime,
  selectedReturnTripDetail,
  isReturnAvailable,
  isReturnTripValid,
  isAuthenticated
}) => {
  const { t } = useTranslation();
  const [tooltipsShown, setTooltipsShown] = useState({
    heading: false,
    return: false,
    depart: false,
    repeat: false,
    submit: false
  });
  const [offset, setOffset] = useState(0);
  const [blockScroll, setBlockScroll] = useState(false);
  const [seenHints, setSeenHints] = useLocalStorage('seenHints');
  const headingRef = useRef(null);
  const returnDatetimeRef = useRef(null);
  const departDatetimeRef = useRef(null);
  const repeatRef = useRef(null);
  const bookRideRef = useRef(null);

  const hintsVisible = useMemo(() => {
    return !seenHints.tripOverviewWorkforce && isAuthenticated;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (!seenHints.tripOverviewWorkforce && isAuthenticated) {
      setSeenHints({ ...seenHints, tripOverviewWorkforce: true });
    }

    if (hintsVisible) {
      Tooltip.show(headingRef.current);
      setTooltipsShown({...tooltipsShown, heading: true});
    }

    return () => {
      Tooltip.hide();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hintsVisible, isAuthenticated, returnDatetimeRef.current]);

  const selectedDepartTrip = {
    selectedTripType: 'depart',
    ...selectedTripDetail,
    workforce: true,
  };
  const selectedReturnTrip = {
    selectedTripType: 'return',
    ...selectedReturnTripDetail,
    workforce: true,
  };

  const isCreateTripRequestLoading =
    loading === generalConstants.CREATE_TRIP_REQUEST_LOADING;

  const submitDisabled = !!(
    loading ||
    isCreateTripRequestLoading ||
    (state.selection.isReturn &&
      isReturnAvailable &&
      (!state.selection.returnDate ||
        !state.selection.returnTime ||
        !isReturnTripValid))
  );

  const onTooltipHide = useCallback(() => {
    if (!blockScroll) {
      setBlockScroll(true);
      setTimeout(() => updateTooltipOnScroll(), 1000);
      setTimeout(() => setBlockScroll(false), 1500);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockScroll, isReturnAvailable]);

  const updateTooltipOnScroll = () => {
    switch(offset) {
      case 0:
        handleHeadingTooltip();
        break;
      case 1:
        handleReturnTooltip();
        break;
      case 2:
        handleDepartTooltip();
        break;
      case 3:
        handleRepeatTooltip();
        break;
      case 4:
        handleSubmitTooltip();
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    const node = document.querySelector('#trip-overview-wrapper')?.parentNode;
    if(!node) return;
    node.addEventListener('scroll', onTooltipHide);

    return () => {
      node.removeEventListener('scroll', onTooltipHide);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onTooltipHide]);

  const handleHeadingTooltip = () => {
    if (isReturnAvailable) {
      if (hintsVisible && !tooltipsShown.return) {
        Tooltip.hide(headingRef.current);
        setTooltipsShown({...tooltipsShown, return: true});
        Tooltip.show(returnDatetimeRef.current);
        setOffset(1);
      }
    } else {
      if (hintsVisible && !tooltipsShown.depart) {
        Tooltip.hide(headingRef.current);
        setTooltipsShown({...tooltipsShown, depart: true, return: true});
        Tooltip.show(departDatetimeRef.current);
        setOffset(2);
      }
    }
  }

  const handleReturnTooltip = () => {
    if (hintsVisible && !tooltipsShown.depart) {
      Tooltip.hide(returnDatetimeRef.current);
      setTooltipsShown({...tooltipsShown, depart: true});
      Tooltip.show(departDatetimeRef.current);
      setOffset(2);
    }
  }

  const handleDepartTooltip = () => {
    if (hintsVisible && !tooltipsShown.repeat) {
      Tooltip.hide(departDatetimeRef.current);
      setTooltipsShown({...tooltipsShown, repeat: true});
      Tooltip.show(repeatRef.current);
      setOffset(3);
    }
  }

  const handleRepeatTooltip = () => {
    if (hintsVisible && !tooltipsShown.submit) {
      Tooltip.hide(repeatRef.current);
      setTooltipsShown({...tooltipsShown, submit: true});
      Tooltip.show(bookRideRef.current);
      setOffset(4);
    }
  }

  const handleSubmitTooltip = () => {
    if (hintsVisible) {
      Tooltip.hide(bookRideRef.current);
      setOffset(5);
    }
  }

  const addresses = () => getAddresses(selectedTripDetail);
  const tripPathDetails = [];
  tripPathDetails.push(
    <WorkforceTripPathDetails
      type="depart"
      title={t('trip-overview.labels.departing-trip')}
      tripDetails={selectedDepartTrip}
      pickupAddress={addresses().pickup}
      destinationAddress={addresses().destination}
    />,
  );

  if (state.selection.isReturn && selectedReturnTripDetail) {
    const returnAddresses = getAddresses(selectedReturnTripDetail);
    tripPathDetails.push(
      <WorkforceTripPathDetails
        type="return"
        title={t('trip-overview.labels.returning-trip')}
        tripDetails={selectedReturnTrip}
        pickupAddress={returnAddresses.destination}
        destinationAddress={returnAddresses.pickup}
      />,
    );
  }

  return (
    <TripOverviewWorkforceCol
      justify="space-between"
      align="center"
      spacingV={30}
      spacingH={30}
      id="trip-overview-wrapper"
      onClick={onTooltipHide}
    >
      {hintsVisible && (
        <Tooltip
          id="trip-overview-workforce-tooltip"
          backgroundColor={theme.WEIRD_GREEN}
          textColor={theme.WHITE}
          isCapture
        />
      )}
      <Col justify="flex-start" align="center">
        <HeaderRow
          flex="initial"
          justify="space-between"
          align="center"
          spacingV={10}
        >
          <HeaderRow justify="flex-start" align="flex-start" fullWidth={false}>
            <EditBtn
              id="edit-btn"
              onClick={onEdit}
              icon={faArrowLeft}
              size="lg"
            />
            <TooltipWrapper
              id="trip-heading"
              ref={headingRef}
              data-cy="trip-heading"
              data-tip={t(hints.tripOverviewWorkforceHints.heading)}
              data-event="custom"
              data-for="trip-overview-workforce-tooltip"
              heading={true}
            >
              <Title>
                {t('trip-overview.labels.title')}
              </Title>
            </TooltipWrapper>
          </HeaderRow>
        </HeaderRow>

        <MinimizableCol
          justify="flex-start"
          align="center"
          minimized={minimized}
        >
          {(largeScreen || !minimized) && (
            <>
              <Col flex="initial" justify="flex-start" align="flex-start">
                {largeScreen &&
                  tripPathDetails.map((tripPathDetail, key) => (
                    <Col
                      flex="initial"
                      justify="flex-start"
                      align="flex-start"
                      key={key}
                    >
                      <TripDetailsRow
                        flex="initial"
                        justify="flex-start"
                        align="center"
                        spacingV={10}
                      >
                        {tripPathDetail}
                      </TripDetailsRow>
                    </Col>
                  ))}
                {!largeScreen && !minimized && (
                  <TripDetailsRow
                    flex="initial"
                    justify="flex-start"
                    align="center"
                    spacingV={10}
                  >
                    <Slider views={tripPathDetails} showArrows />
                  </TripDetailsRow>
                )}
              </Col>

              <FrequencyPickerRow>
                {isReturnAvailable && (
                  <TooltipWrapper
                    ref={returnDatetimeRef}
                    id="trip-return-datetime"
                    data-cy="trip-return-datetime"
                    data-tip={t(hints.tripOverviewWorkforceHints.returnDatetime)}
                    data-event="custom"
                    data-for="trip-overview-workforce-tooltip"
                  >
                    <SearchReturnTrip
                      onToggleIsReturn={onToggleIsReturn}
                      isReturn={state.selection.isReturn}
                      isValidDate={isValidDate}
                      getValidTimes={getValidTimesReturn}
                      setReturnDate={setReturnDate}
                      setReturnTime={setReturnTime}
                      returnDate={state.selection.returnDate}
                      returnTime={state.selection.returnTime}
                    />
                  </TooltipWrapper>
                )}
                <TooltipWrapper
                  id="trip-depart-datetime"
                  ref={departDatetimeRef}
                  data-cy="trip-depart-datetime"
                  data-tip={t(hints.tripOverviewWorkforceHints.departDatetime)}
                  data-event="custom"
                  data-for="trip-overview-workforce-tooltip"
                >
                  <TripOverviewDateTime
                    onToggleDateTime={onToggleDateTime}
                    isDateTimeChanged={isDateTimeChanged}
                    state={state}
                    isValidDate={isValidDate}
                    getValidTimes={getValidTimes}
                    setChangedDate={setChangedDate}
                    setChangedTime={setChangedTime}
                    changedDate={changedDate}
                    changedTime={changedTime}
                  />
                </TooltipWrapper>
                <TooltipWrapper
                  id="frequency-picker-tooltip"
                  ref={repeatRef}
                  data-cy="frequency-picker-tooltip"
                  data-tip={t(hints.tripOverviewWorkforceHints.repeat)}
                  data-event="custom"
                  data-for="trip-overview-workforce-tooltip"
                >
                  <FrequencyPickerWorkforce
                    id="frequency-picker"
                    isValidEndDate={isValidEndDate}
                    data={repeat}
                    hintsVisible={hintsVisible && offset < 5}
                    onChange={onChangeFrequency}
                  />
                </TooltipWrapper>
              </FrequencyPickerRow>
            </>
          )}
        </MinimizableCol>
      </Col>
      <TooltipWrapper 
        id="submit-wrapper"
        data-cy="submit-wrapper"
        ref={bookRideRef}
        data-tip={t(hints.tripOverviewWorkforceHints.bookRideBtn)}
        data-event="custom"
        data-for="trip-overview-workforce-tooltip"
      >
        <Row flex="initial" justify="flex-start" align="center">
          <SubmitBtn
            onClick={onBookRide}
            id="book-btn"
            label={t('trip-overview.buttons.book')}
            color={theme.BASE_COLOR}
            icon={isCreateTripRequestLoading ? faSpinner : null}
            iconProps={{ spin: true }}
            disabled={submitDisabled}
          />
        </Row>
      </TooltipWrapper>
    </TripOverviewWorkforceCol>
  );
};

TripOverview.propTypes = {
  loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  selectedTripDetail: PropTypes.object.isRequired,
  selectedReturnTripDetail: PropTypes.object,
  onEdit: PropTypes.func.isRequired,
  onBookRide: PropTypes.func.isRequired,
  repeat: PropTypes.object.isRequired,
  onChangeFrequency: PropTypes.func.isRequired,
  isValidEndDate: PropTypes.func.isRequired,
  minimized: PropTypes.bool.isRequired,
  largeScreen: PropTypes.bool,
  isDateTimeChanged: PropTypes.bool.isRequired,
  changedDate: PropTypes.string.isRequired,
  changedTime: PropTypes.string.isRequired,
  onToggleDateTime: PropTypes.func.isRequired,
  state: PropTypes.shape({
    selection: PropTypes.shape({
      returnDate: PropTypes.string,
      returnTime: PropTypes.string,
      isReturn: PropTypes.bool,
    }).isRequired,
  }).isRequired,
  isValidDate: PropTypes.func.isRequired,
  setChangedTime: PropTypes.func.isRequired,
  setChangedDate: PropTypes.func.isRequired,
  getValidTimes: PropTypes.func.isRequired,
  getValidTimesReturn: PropTypes.func.isRequired,
  onToggleIsReturn: PropTypes.func.isRequired,
  setReturnDate: PropTypes.func.isRequired,
  setReturnTime: PropTypes.func.isRequired,
  isReturnAvailable: PropTypes.bool.isRequired,
  isReturnTripValid: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
};

TripOverview.defaultProps = {
  loading: null,
  largeScreen: false,
  selectedReturnTripDetail: null,
};

export default TripOverview;
