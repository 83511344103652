/** @format */

// eslint-disable-next-line
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { styles as styleConstants } from '@constants';
import { userHelpers } from '@utils';

const defaultHeaderHeight = Number(process.env.REACT_APP_HEADER_HEIGHT || 40);

const useHeaderHeight = () => {
  const user = useSelector(state => state.app.user);

  const [headerHeight, setHeaderHeight] = useState(
    !user || userHelpers.getUserOrganizations(user)
      ? defaultHeaderHeight
      : defaultHeaderHeight + styleConstants.NO_ORG_BANNER_HEIGHT,
  );

  return [headerHeight, setHeaderHeight];
};

export default useHeaderHeight;
