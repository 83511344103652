import styled from 'styled-components';
import { Input, Dropdown } from '@components';
import { theme } from '@constants';

export const TextSmall = styled.small`
  color: ${theme.BASE_COLOR};
  font-weight: bold;
  font-size: 16px;
  width: 100%;
`;

export const DateInput = styled(Input)`
  min-width: 145px;
  box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.75);
`;

export const ValidTimesDropdownWrapper = styled.div`
  width: 100%;
  .rdd-placeholder {
    font-size: 16px;
    font-family: Poppins;
  }

  .rdd-menu {
    top: 40px;
    font-family: Poppins;

    > div {
      font-size: 16px;
      font-family: Poppins;
    }
  }
`;

export const ValidTimesDropdown = styled(Dropdown)`
  height: 38px;
  min-width: 150px;
  box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.75) !important;
  .rdd-menu {
    box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.75) !important;
    max-height: 125px !important;
  }
`;
