/** @format */
import React from 'react';
import { useSelector } from 'react-redux';
import { HomeWorkforceLarge, HomeWorkforceSmall } from '@components';
import { TripOptionsWorkforce } from '@containers';

const HomeWorkforce = () => {
  const { largeScreen } = useSelector(store => store.app);
  const { selectedMarker } = useSelector(
    state => state.tripRequestWorkforce,
  );

  const home = largeScreen ?
    <HomeWorkforceLarge
      id="home-workforce-large"
    >
      <TripOptionsWorkforce />
    </HomeWorkforceLarge> :
    <HomeWorkforceSmall
      id="home-workforce-small"
    >
      {selectedMarker !== null && <TripOptionsWorkforce /> }
    </HomeWorkforceSmall>;

  return home;
};

export default HomeWorkforce;
