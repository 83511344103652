/** @format */

import styled from 'styled-components';

import { Row, Col } from '@components';
import { styles, theme } from '@constants';

export const TripRequestForm = styled(Row)`
  padding: 30px;
  background-color: ${theme.BASE_COLOR};
  position: relative;
  max-height: ${styles.TRIP_REQUEST_LG_HEIGHT};
  z-index: 11;
`;

export const ViewDestinationsCol = styled(Col)`
  padding-left: 45px;
`;

export const Title = styled.h4`
  font-size: 22px;
  font-weight: 600;
  color: ${theme.WHITE};
  margin-bottom: 20px;
`;

export const ViewDestinationsText = styled.span`
  color: ${theme.WHITE};
  margin-bottom: 20px;
  line-height: 26px;
`;

export const PlanTripWrapper = styled.div`
  width: 100%;
`;
