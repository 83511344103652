/** @format */

import styled from 'styled-components';

import { theme, device } from '@constants';
import { Col, Button } from '..';

export const Wrapper = styled(Col)`
  background-color: ${theme.WHITE};
  padding-bottom: 10px;
  height: 100%;
  display: block;
  z-index: 20;
  margin-top: -30px;
  @media ${device.mediaQuery.LARGE_SCREEN} {
    margin-top: -40px;
  }
`;

export const Title = styled.span`
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: ${theme.BASE_COLOR};
  @media ${device.mediaQuery.LARGE_SCREEN} {
    font-size: 14px;
  }
`;

export const SubTitle = styled.span`
  font-size: 12px;
  color: ${theme.BASE_COLOR};
  text-align: center;
  @media ${device.mediaQuery.LARGE_SCREEN} {
    font-size: 14px;
  }
`;

export const Image = styled.img`
  height: ${props => (props.isLastPage ? 'auto' : '240px')};
  width: ${props => (props.isLastPage ? '170px' : '240px')};
  padding-top: 20px;
`;

export const SponsorImage = styled.img`
  height: 80px;
  width: 80px;
  margin-top: 10px;
`;

export const CloseIcon = styled(Button)`
  width: 16px;
  height: 16px;
  margin: 15px 0;
  z-index: 2;
`;

export const OutterWrapper = styled.div`
  display: block;
`;