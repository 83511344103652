/** @format */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { theme } from '@constants';
import { Row, Col, IconButton } from '..';
import {
  StyledMobileDatePicker,
  SaveBtn,
  MobileDatePickerStyled,
  HeaderText,
} from './styled';
import { dateConfig } from './config';

const MobileDatePicker = ({ onClose, onSave, value, max, min }) => {
  const [date, setDate] = useState(value);

  const onChange = value => {
    setDate(value);
  };
  const onSubmit = () => {
    onSave(date);
    onClose();
  };

  const CustomHeader = () => (
    <Row justify="space-around" align="center">
      <HeaderText>Day</HeaderText>
      <HeaderText>Month</HeaderText>
      <HeaderText>Year</HeaderText>
    </Row>
  );
  return (
    <StyledMobileDatePicker>
      <Row align="flex-end" justify="flex-end">
        <IconButton id="close-mobile-date-picker" onClick={onClose} />
      </Row>
      <MobileDatePickerStyled
        isOpen
        showFooter={false}
        dateConfig={dateConfig}
        isPopup={false}
        onChange={onChange}
        value={date}
        min={min}
        max={max}
        customHeader={<CustomHeader />}
      />
      <Col justify="center" align="center">
        <SaveBtn
          id="submit-btn"
          onClick={onSubmit}
          label="Save"
          color={theme.BASE_COLOR}
        />
      </Col>
    </StyledMobileDatePicker>
  );
};

MobileDatePicker.propTypes = {
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  value: PropTypes.instanceOf(Date),
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

MobileDatePicker.defaultProps = {
  max: null,
  min: null,
  value: '',
  onSave: null,
  onClose: null,
};

export default MobileDatePicker;
