/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { Marker, useMap, useMapEvents } from 'react-leaflet';
import TripPath from '../../map/tripPath';
import { TooltipText, STooltip } from '../styled';
import {
    tripMarkersConfig,
    stopMarkerIcon,
    workplaceMarkerIcon,
  } from '../config';

const getMapBounds = orgPlaces => {
  if (!Array.isArray(orgPlaces) || !orgPlaces?.length) return [];
  return (orgPlaces || []).map(item => [item?.lat, item?.lng]).filter(x => x[0] && x[1]);
};

const Map = ({
    places,
    tripMarkers,
    tripPath,
    resizeMapKey,
    onClickBusStopMarker,
    onClickWorkplaceMarker,
    isFitBound,
    setIsFitBound,
    selectedOrgPlaceMarker,
    largeScreen,
    pickupHereClicked,
}) => {
    const { t } = useTranslation();
    const map = useMap();
    const mapBounds = getMapBounds(places);

    useEffect(() => {
      if (mapBounds && mapBounds.length > 0 && isFitBound && map) {
        if (!largeScreen) {
          setTimeout(() => {
            if (!selectedOrgPlaceMarker && !pickupHereClicked) {
              map.fitBounds(mapBounds, {
                padding: [100, 100],
              });
            }
            setIsFitBound(false);
          }, 800);
        } else {
          map.fitBounds(mapBounds, {
            padding: [100, 100],
          });
          setIsFitBound(false);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapBounds, isFitBound]);

    useMapEvents({
      zoomend: () => {
        if (!map) return;
        map.getZoom() > 6
          ? map.eachLayer((l) => {
            if (l.getTooltip) {
              const toolTip = l.getTooltip();
              if (
                toolTip?._latlng?.lat &&
                toolTip?._latlng?.lng
              ) {
                map.openTooltip(toolTip);
              }
            }
          })
          : map.eachLayer((l) => {
            if (l.getTooltip) {
              const toolTip = l.getTooltip();
              if (
                toolTip?._latlng?.lat &&
                toolTip?._latlng?.lng
              ) {
                map.closeTooltip(toolTip);
              }
            }
          });
      },
    });

    const onMarkerClick = (stop) => {
    const { type } = stop || {};
        if (type === 'STOP') {
          onClickBusStopMarker(stop);
        }
        if (type === 'WORKPLACE') {
          onClickWorkplaceMarker(stop);
        }
    };

    const getMarkerIcon = (stop) => {
      return stop.type === 'STOP' ? stopMarkerIcon : workplaceMarkerIcon;
    };

    return (
      <>
        <TripPath tripPath={tripPath} resizeMapKey={resizeMapKey} />

        {Array.isArray(places) && places.length &&
          places.map((stop, i) => {
            return (
              stop && (
                <Marker
                  key={
                    stop.type === 'STOP'
                      ? `marker-${i}-${stop.orgPlaceId}`
                      : `marker-workplace-${stop.orgPlaceId}`
                  }
                  id={
                    stop.type === 'STOP'
                      ? `workforce-marker-stop-${stop.orgPlaceId}`
                      : `workforce-marker-workplace-${stop.orgPlaceId}`
                  }
                  data-cy={
                    stop.type === 'STOP'
                      ? `workforce-marker-stop-${stop.orgPlaceId}`
                      : `workforce-marker-workplace-${stop.orgPlaceId}`
                  }
                  icon={getMarkerIcon(stop)}
                  position={[stop.lat, stop.lng]}
                  title={stop.name}
                  eventHandlers={{
                    click: () => onMarkerClick(stop),
                    mouseout: (e) => {
                      map.getZoom() > 6 ? e.target.openTooltip() : e.target.closeTooltip();
                    },
                  }}
                >
                  {stop.type === 'STOP' && (
                    <STooltip
                      direction="top"
                      opacity={1}
                      id={`marker-tooltip-${stop.markerKey}`}
                    >
                      <TooltipText title="Location Unique Key">
                        {`${t('workforce-trip-request.fields.pickup.title')} ${
                          stop.markerKey
                        }`}
                      </TooltipText>
                    </STooltip>
                  )}
                </Marker>
              )
            );
          })}
        {tripMarkersConfig.map(o => {
          if (!tripMarkers[o.key]) return null;
          return (
            <Marker
              key={o.key}
              id={`${o.key}-marker`}
              icon={
                tripMarkers[o.key].type
                  ? getMarkerIcon(tripMarkers[o.key])
                  : o.icon
              }
              alt={o.alt}
              position={tripMarkers[o.key].location}
              title={t(o.title)}
            />
          );
        })}
      </>
    );
};

Map.propTypes = {
  places: PropTypes.array.isRequired,
  tripMarkers: PropTypes.shape({
    pickup: PropTypes.array,
    destination: PropTypes.array,
  }).isRequired,
  tripPath: PropTypes.shape({
    share: PropTypes.array,
    transit: PropTypes.array,
  }).isRequired,
  resizeMapKey: PropTypes.number.isRequired,
  onClickBusStopMarker: PropTypes.func.isRequired,
  onClickWorkplaceMarker: PropTypes.func.isRequired,
  isFitBound: PropTypes.bool.isRequired,
  setIsFitBound: PropTypes.func.isRequired,
  selectedOrgPlaceMarker: PropTypes.bool,
  largeScreen: PropTypes.bool.isRequired,
  pickupHereClicked: PropTypes.bool,
};

Map.defaultProps = {
  selectedOrgPlaceMarker: false,
  pickupHereClicked: false,
};

export default Map;
