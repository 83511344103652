/** @format */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { NameRow, NameText, NameTextBold } from './styled';

const FreeRidePoints = ({ points }) => {
  const { t } = useTranslation();
  return (
    <NameRow justify="flex-start" align="center" id="free-ride-points-container">
      <NameText id="name-text">
        <NameTextBold>{t('trip-detail.labels.points-applied')}: </NameTextBold>
        {points !== null ? points : <FontAwesomeIcon id="icon" icon={faSpinner} spin />}
      </NameText>
    </NameRow>
  );
};

FreeRidePoints.propTypes = {
  points: PropTypes.number,
};

FreeRidePoints.defaultProps = {
  points: null,
};

export default FreeRidePoints;
