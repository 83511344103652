/** @format */

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { theme } from '@constants';
import { Row } from '..';

export const QuickLinkItemLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export const QuickLinkItem = styled.a`
  &:hover {
    text-decoration: none;
  }
`;

export const QuickLinkItemIconRow = styled(Row)`
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: ${theme.BASE_COLOR};
`;

export const QuickLinkItemIcon = styled(FontAwesomeIcon)`
  font-size: 28px;
  line-height: 1;
  color: ${theme.WHITE};
`;

export const QuickLinkItemTitle = styled.span`
  font-size: 12px;
  color: ${theme.BASE_COLOR};
  margin-top: 10px;
  &.new-feature {
    font-weight: bold;
  }
`;
