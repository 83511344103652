/** @format */

const googleTag = id => {
  window.dataLayer = window.dataLayer || [];
  function gtag(...args) {
    window.dataLayer.push(...args);
  }
  gtag('js', new Date());
  gtag('config', id);
};

export default googleTag;
