/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { helpers, userHelpers, tripHelpers } from '@utils';
import { theme, generals as generalConstants } from '@constants';
import { Row, Col, WorkforceTripPathDetails } from '..';
import {
  TripDetailCol,
  Title,
  TripTimeTitle,
  TripTimeValue,
  TripDetailsRow,
  StyledButton,
  TextSmall,
  ImageRounded,
  ImageCol,
  RouteInfoRow,
  TripNotFoundMessage,
  LoaderIcon,
  LoaderWrapperRow,
  MinimizableCol,
  AdditionalInfoTitle,
  AdditionalInfoValue,
  EditBtn,
} from './styled';

export const WorkforceTripDetail = ({
  trip,
  loading,
  goBack,
  openConfirmCancelModal,
  minimized,
  user,
  isPasswordless,
}) => {
  const { t } = useTranslation();

  if (!trip) {
    return (
      <TripDetailCol
        justify="flex-start"
        align="flex-start"
        spacingV={30}
        spacingH={30}
      >
        {loading === generalConstants.MY_TRIP_LOADING ? (
          <LoaderWrapperRow
            id="trip-detail-loader"
            justify="center"
            align="center"
            spacingV={30}
          >
            <LoaderIcon icon={faSpinner} spin />
          </LoaderWrapperRow>
        ) : (
          <TripNotFoundMessage id="trip-not-found-message">
            {t('trip-detail.labels.trip-not-found')}
          </TripNotFoundMessage>
        )}
      </TripDetailCol>
    );
  }

  const showCancelButton = ['Requested', 'Scheduled'].includes(trip.status);

  const actionButtons = (
    <Row flex="initial" justify="space-between" align="center">
      {showCancelButton && (
        <StyledButton
          id="cancel-trip-btn"
          data-cy="cancel-ride-btn"
          onClick={openConfirmCancelModal}
          label={t('trip-detail.buttons.cancel')}
          color={theme.BASE_COLOR}
          icon={
            loading === generalConstants.CANCEL_TRIP_LOADING ? faSpinner : null
          }
          iconProps={{ spin: true }}
        />
      )}
    </Row>
  );

  if (!trip || !trip.places) {
    return null;
  }

  const [pickup, destination] = trip.places;
  const timezone = trip?.places[0]?.timezone || 'America/New_York';

  let leaveTime = moment
    .tz(trip.leaveTime, timezone)
    .format('MM/DD/YYYY hh:mm A');
  let arriveTime = moment
    .tz(trip.arriveTime, timezone)
    .format('MM/DD/YYYY hh:mm A');
  const { destination: dest } = trip;

  let shift = null;
  if (
    dest &&
    dest.length &&
    (trip.status === 'Requested' || trip.status === 'Scheduled')
  ) {
    const dropoff = dest[0];
    shift = tripHelpers.getShiftForArrival(arriveTime, dropoff);
  }

  const driverContact = userHelpers.getDriverContact(user, trip);
  let tripTimeTitle = t('trip-detail.labels.trip-time');
  let tripTimeValue = trip.tripTime;

  if (trip.route) {
    const pickupPlace = trip.places[0];
    const dropoffPlace = trip.places[1];
    const routePickupLocation = (trip.route?.locations || []).filter(
      location => location.placeId === pickupPlace.placeId,
    );
    const routeDropoffLocation = (trip.route?.locations || []).filter(
      location => location.placeId === dropoffPlace.placeId,
    );

    if (
      routePickupLocation[0]?.estimatedArrival &&
      routeDropoffLocation[0]?.estimatedArrival
    ) {
      leaveTime = moment
        .tz(routePickupLocation[0].estimatedArrival, pickupPlace.timezone)
        .format('YYYY-MM-DD HH:mm');
      arriveTime = moment
        .tz(routeDropoffLocation[0].estimatedArrival, dropoffPlace.timezone)
        .format('YYYY-MM-DD HH:mm');
      tripTimeValue =
        moment(arriveTime).diff(moment(leaveTime), 'minutes') + ' min';
    }

    trip = {
      ...trip,
      leaveTime,
      arriveTime,
    };
  } else if (trip.status === 'Requested') {
    const dateTimeSplit = (
      trip.requestType === 'Arrive By' ? arriveTime : leaveTime
    ).split(' ');
    tripTimeTitle = dateTimeSplit[0];
    tripTimeValue = `${dateTimeSplit[1]}  ${dateTimeSplit[2]}`;
  }

  return (
    <TripDetailCol
      justify="space-between"
      align="center"
      spacingV={30}
      spacingH={30}
    >
      <Col justify="flex-start" align="center">
        <Row
          flex="initial"
          justify="space-between"
          align="center"
          spacingV={10}
        >
          <Row justify="flex-start" align="flex-start" fullWidth={false}>
            <EditBtn
              id="edit-btn"
              onClick={goBack}
              icon={faArrowLeft}
              size="lg"
            />
            <Title>{t('trip-detail.labels.trip-details')}</Title>
          </Row>
          <Col
            flex="initial"
            justify="flex-start"
            align="flex-start"
            fullWidth={false}
          >
            <TripTimeTitle>{tripTimeTitle}</TripTimeTitle>
            <TripTimeValue>
              {tripTimeValue}{' '}
              {trip.status === 'Requested' &&
                (trip.requestType === 'Ready By'
                  ? '(' + t('trip.labels.pickUp') + ')'
                  : '(' + t('trip.labels.dropOff') + ')')}
            </TripTimeValue>
          </Col>
        </Row>

        <MinimizableCol
          justify="flex-start"
          align="center"
          minimized={minimized ? 1 : 0}
        >
          {trip.route && (
            <RouteInfoRow
              flex="initial"
              justify="flex-start"
              align="flex-start"
              spacingV={15}
            >
              {(trip.route.driver || driverContact) && (
                <Col justify="flex-start" align="flex-start">
                  <Title>{t('trip-detail.labels.driver')}</Title>
                  <Row
                    flex="initial"
                    justify="flex-start"
                    align="center"
                    spacingV={10}
                  >
                    {trip.route.driver.photo && (
                      <ImageCol justify="flex-start" align="flex-start">
                        <ImageRounded src={trip.route.driver.photo} />
                      </ImageCol>
                    )}
                    <Col justify="flex-start" align="flex-start" spacingH={8}>
                      {trip.route.driver.name && (
                        <TextSmall>{trip.route.driver.name}</TextSmall>
                      )}
                      {driverContact && (
                        <TextSmall>
                          <a href={`tel:${driverContact}`}>{driverContact}</a>
                        </TextSmall>
                      )}
                    </Col>
                  </Row>
                </Col>
              )}
              {trip.route.vehicle && trip.route.vehicle.detail && (
                <Col justify="flex-start" align="flex-start">
                  <Title>{t('trip-detail.labels.vehicle')}</Title>
                  <Row
                    flex="initial"
                    justify="flex-start"
                    align="center"
                    spacingV={10}
                  >
                    {trip.route.vehicle.photo && (
                      <ImageCol justify="flex-start" align="flex-start">
                        <ImageRounded src={trip.route.vehicle.photo} />
                      </ImageCol>
                    )}
                    {trip.route.vehicle.detail && (
                      <Col justify="flex-start" align="flex-start" spacingH={8}>
                        <TextSmall>
                          {trip.route.vehicle.detail.make &&
                            `${trip.route.vehicle.detail.make} `}
                          {trip.route.vehicle.detail.model &&
                            ` ${trip.route.vehicle.detail.model}`}
                        </TextSmall>
                        <TextSmall weight="normal">
                          {trip.route.vehicle.detail.color &&
                            `${trip.route.vehicle.detail.color}`}
                          {trip.route.vehicle.detail.name &&
                            ` - ${trip.route.vehicle.detail.name}`}
                        </TextSmall>
                      </Col>
                    )}
                  </Row>
                </Col>
              )}
            </RouteInfoRow>
          )}
          <TripDetailsRow
            flex="initial"
            justify="flex-start"
            align="center"
            spacingV={10}
          >
            <WorkforceTripPathDetails
              tripDetails={shift ? { ...trip, shift } : trip}
              pickupAddress={{
                name: pickup.name,
                address: helpers.getStreetAddress(pickup.address),
              }}
              destinationAddress={{
                name: destination.name,
                address: helpers.getStreetAddress(destination.address),
              }}
              showHeaderDate={false}
            />
          </TripDetailsRow>
          {trip.specialNeeds && trip.specialNeeds.length ? (
            <Row flex="initial" align="center" spacingV={10}>
              <AdditionalInfoTitle>
                {t('trip-detail.labels.special-needs')}:{' '}
              </AdditionalInfoTitle>
              &nbsp;
              <AdditionalInfoValue>
                {trip.specialNeeds
                  .map(x =>
                    t(
                      `special-needs.options.${x
                        .toLowerCase()
                        .split(' ')
                        .join('-')}`,
                    ),
                  )
                  .join(', ')}
              </AdditionalInfoValue>
            </Row>
          ) : null}
        </MinimizableCol>
      </Col>

      {!isPasswordless && actionButtons}
    </TripDetailCol>
  );
};

WorkforceTripDetail.propTypes = {
  goBack: PropTypes.func.isRequired,
  trip: PropTypes.object,
  loading: PropTypes.string,
  openConfirmCancelModal: PropTypes.func.isRequired,
  minimized: PropTypes.bool.isRequired,
  user: PropTypes.object,
  isPasswordless: PropTypes.bool,
};

WorkforceTripDetail.defaultProps = {
  trip: null,
  loading: null,
  user: {},
  isPasswordless: false,
};

export default WorkforceTripDetail;
