/** @format */

import styled from 'styled-components';
import { theme } from '@constants';

export const FullScreen = styled.div`
  width: 100%;
  background-color: ${theme.WHITE};
  position: relative;
  overflow-y: auto;
  height: calc(100% - ${props => props.headerHeight}px);
`;
