/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import {
  faArrowRight,
  faAngleRight,
  faArrowCircleRight,
  faArrowCircleLeft,
} from '@fortawesome/free-solid-svg-icons';
import { theme } from '@constants';
import { Col, Row, ReactAddToCalendar } from '..';
import { helpers } from '@utils';
import {
  TripColumn,
  ClickHandler,
  TripHeaderColumn,
  TripHeaderRow,
  TextMedium,
  Text,
  Value,
  TextValueWrapper,
  DirectionIcon,
  RouteIcon,
  SeeDetailsWrapper,
  SeeDetailsText,
  SeeDetailsIcon,
} from './styled';
import { useTranslation } from 'react-i18next';

export const WorkforceTrip = ({
  tripTime,
  pickup,
  dropoff,
  onTripSelect,
  smallScreen,
  tripRef,
  tripType,
  routeType,
  isBooked,
  status,
  addToCalendarEvent,
  addToCalenderBtnCliked,
  calendarItemClicked,
  leaveBy,
  arriveBy,
}) => {
  const clickHandlerWidth = smallScreen ? '330px' : '100%';
  const tripColWidth = smallScreen ? 'auto' : '100%';
  const dateTime = (tripTime || '').split(' ');
  const { t } = useTranslation();

  const leaveBySplit = (leaveBy || '').split(' ');
  const arrivebySplit = (arriveBy || '').split(' ');

  const leaveByDate = leaveBySplit[0];
  const leaveByTime = leaveBySplit[1] + ' ' + leaveBySplit[2];

  const arriveByDate = arrivebySplit[0];
  const arriveByTime = arrivebySplit[1] + ' ' + arrivebySplit[2];

  return (
    <TripColumn
      colRef={tripRef}
      width={tripColWidth}
      id="tripcol"
      flex="initial"
      justify="flex-start"
      align="flex-start"
    >
      {addToCalendarEvent && (
        <ReactAddToCalendar
          event={addToCalendarEvent}
          buttonTemplate={{
            'calendar-plus-o': 'left',
          }}
          buttonLabel=""
          buttonClicked={addToCalenderBtnCliked}
          calendarItemClicked={calendarItemClicked}
        />
      )}
      <ClickHandler onClick={onTripSelect} id="trip" width={clickHandlerWidth}>
        {isBooked ? (
          <>
            <TripHeaderColumn justify="center" align="flex-start">
              <TripHeaderRow justify="space-around" align="center">
                <Col justify="flex-start" align="flex-start">
                  <TextValueWrapper>
                    <Value color={theme.WHITE}>
                      {(tripType === ''
                        ? ''
                        : tripType === 'Ready By'
                        ? t('trip.labels.pickUp')
                        : t('trip.labels.dropOff')
                      ).toUpperCase()}
                    </Value>
                  </TextValueWrapper>
                </Col>
                <Col justify="flex-start" align="flex-start">
                  <TextValueWrapper>
                    <Value color={theme.WHITE} align="center">
                      {routeType === 'RETURN' && (
                        <RouteIcon id="return-icon" icon={faArrowCircleLeft} />
                      )}
                      &nbsp;
                      {t(`trip-request.small-screen.fields.${routeType?.toLowerCase()}-date.label`)}&nbsp;
                      {routeType === 'DEPART' && (
                        <RouteIcon id="depart-icon" icon={faArrowCircleRight} />
                      )}
                    </Value>
                  </TextValueWrapper>
                </Col>
                {status && (
                  <Col justify="flex-start" align="flex-start">
                    <TextValueWrapper>
                      <Text color={theme.WHITE}>{t('trip.labels.status')}</Text>
                      <Value
                        color={theme.WHITE}
                        data-cy={`status${helpers.camelToKebabCase(status)}`}
                      >
                        {status ? 
                          t(`trip.labels.statuses.${status?.toLowerCase().split(' ').join('-')}`)
                          : ''
                        }
                      </Value>
                    </TextValueWrapper>
                  </Col>
                )}
              </TripHeaderRow>
            </TripHeaderColumn>
            {status === 'Requested' ? (
              <Row
                justify="flex-start"
                align="flex-start"
                spacingH={15}
                spacingV={10}
              >
                <Col justify="flex-start" align="flex-start">
                  <TextValueWrapper>
                    <Text color={theme.BASE_COLOR}>
                      {t('trip.labels.trip-date')}
                    </Text>
                    <Value color={theme.BASE_COLOR}>
                      {tripType === 'Ready By' ? leaveByDate : arriveByDate}
                    </Value>
                  </TextValueWrapper>
                </Col>
                <Col justify="flex-start" align="flex-start">
                  <TextValueWrapper>
                    <Text color={theme.BASE_COLOR}>
                      {t('trip.labels.trip-time')}
                    </Text>
                    <Value color={theme.BASE_COLOR}>
                      {tripType === 'Ready By' ? leaveByTime : arriveByTime}
                    </Value>
                  </TextValueWrapper>
                </Col>
                {onTripSelect && (
                  <Col justify="flex-end" align="flex-end">
                    <SeeDetailsWrapper>
                      <Row
                        justify="flex-end"
                        align="flex-end"
                        data-cy="ride-details"
                      >
                        <SeeDetailsText>
                          {t('trip.labels.details')}
                        </SeeDetailsText>
                        <SeeDetailsIcon
                          icon={faAngleRight}
                          size="1x"
                          color={theme.BASE_COLOR}
                        />
                      </Row>
                    </SeeDetailsWrapper>
                  </Col>
                )}
              </Row>
            ) : (
              <Row
                justify="space-around"
                align="flex-start"
                spacingH={15}
                spacingV={10}
              >
                <Col justify="flex-start" align="flex-start">
                  <TextValueWrapper>
                    <Text color={theme.BASE_COLOR}>
                      {t('trip.labels.trip-date')}
                    </Text>
                    <Value color={theme.BASE_COLOR}>
                      {tripType === 'Ready By' ? leaveByDate : arriveByDate}
                    </Value>
                  </TextValueWrapper>
                </Col>
                <Col justify="flex-start" align="flex-start">
                  <TextValueWrapper>
                    <Text color={theme.BASE_COLOR}>
                      {t('trip.labels.estd-time.pickup')}
                    </Text>
                    <Value color={theme.BASE_COLOR}>{leaveByTime}</Value>
                  </TextValueWrapper>
                </Col>
                <Row justify="space-between">
                  <Col justify="flex-start" align="flex-start">
                    <TextValueWrapper>
                      <Text color={theme.BASE_COLOR} marginLeft="-10px">
                        {t('trip.labels.estd-time.arrival')}
                      </Text>
                      <Value color={theme.BASE_COLOR} marginLeft="-10px">{arriveByTime}</Value>
                    </TextValueWrapper>
                  </Col>
                  {onTripSelect && (
                    <Col justify="flex-end" align="flex-end">
                      <SeeDetailsWrapper>
                        <Row
                          justify="flex-end"
                          align="flex-end"
                          data-cy="ride-details"
                        >
                          <SeeDetailsText>
                            {t('trip.labels.details')}
                          </SeeDetailsText>
                          <SeeDetailsIcon
                            icon={faAngleRight}
                            size="1x"
                            color={theme.BASE_COLOR}
                          />
                        </Row>
                      </SeeDetailsWrapper>
                    </Col>
                  )}
                </Row>
              </Row>
            )}
          </>
        ) : (
          <>
            <TripHeaderColumn justify="center" align="flex-start">
              <TripHeaderRow justify="space-around" align="center">
                <Col justify="center" align="center">
                  <Value color={theme.WHITE} align="center">
                    {routeType === 'return' && (
                      <RouteIcon id="return-icon" icon={faArrowCircleLeft} />
                    )}
                    &nbsp;
                    {t(`trip-request.small-screen.fields.${routeType}-date.label`)}&nbsp;
                    {routeType === 'depart' && (
                      <RouteIcon id="depart-icon" icon={faArrowCircleRight} />
                    )}
                  </Value>
                </Col>
                <Col justify="center" align="center">
                  <Value align="center" color={theme.WHITE}>
                    {dateTime[0]} <br />
                  </Value>
                </Col>
                <Col justify="center" align="center">
                  <TextValueWrapper>
                    <Value color={theme.WHITE} align="center">
                      {t(`fixed-route.large-screen.fields.switch.labels.${tripType?.toLowerCase().split(' ').join('-')}`)} <br />
                      {dateTime[1] + ' ' + dateTime[2]} <br />
                    </Value>
                  </TextValueWrapper>
                </Col>
              </TripHeaderRow>
            </TripHeaderColumn>
            <Col
              justify="flex-start"
              align="flex-start"
              spacingH={15}
              spacingV={10}
            >
              <Row justify="flex-start" align="flex-start">
                <Col justify="flex-start" align="flex-start">
                  <TextValueWrapper>
                    <Value color={theme.BASE_COLOR}>{pickup.name}</Value>
                    <TextMedium>{pickup.address}</TextMedium>
                  </TextValueWrapper>
                </Col>
                <Col justify="flex-start" align="center" fullWidth={false}>
                  <DirectionIcon icon={faArrowRight} />
                </Col>
                <Col justify="flex-start" align="flex-start">
                  <Value color={theme.BASE_COLOR}>{dropoff.name}</Value>
                  <TextMedium>{dropoff.address}</TextMedium>
                </Col>
              </Row>
            </Col>
          </>
        )}
      </ClickHandler>
    </TripColumn>
  );
};

WorkforceTrip.propTypes = {
  tripTime: PropTypes.string.isRequired,
  routeType: PropTypes.string.isRequired,
  isBooked: PropTypes.bool,
  leaveBy: PropTypes.string,
  arriveBy: PropTypes.string,
  pickup: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
  }),
  dropoff: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
  }),
  tripRef: PropTypes.object,
  smallScreen: PropTypes.bool,
  tripType: PropTypes.string.isRequired,
  onTripSelect: PropTypes.func.isRequired,
  addToCalendarEvent: PropTypes.func,
  addToCalenderBtnCliked: PropTypes.func,
  calendarItemClicked: PropTypes.func,
  status: PropTypes.string,
};

WorkforceTrip.defaultProps = {
  tripRef: null,
  smallScreen: false,
  pickup: null,
  dropoff: null,
  isBooked: false,
  status: null,
  addToCalendarEvent: null,
  addToCalenderBtnCliked: null,
  calendarItemClicked: null,
  leaveBy: null,
  arriveBy: null,
};

export default WorkforceTrip;
