/** @format */

import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Polyline, useMap } from 'react-leaflet';
import { tripPathOptions } from '@components/map/config';

const TripPath = ({ tripPath, resizeMapKey }) => {
  const map = useMap();
  const mapBounds = tripPath.share;

  useEffect(() => {
    setTimeout(() => {
      map.invalidateSize();
    }, 400);
  }, [map, resizeMapKey]);

  useEffect(() => {
    if (mapBounds.length) {
      map.fitBounds(mapBounds);
    }
  }, [map, mapBounds]);

  return (
    <>
      {tripPath?.share?.length > 0 && (
        <Polyline
          id="trip-share-path-polyline"
          positions={tripPath.share}
          pathOptions={tripPathOptions.share}
        />
      )}
    </>
  );
};

export default TripPath;

TripPath.propTypes = {
  tripPath: PropTypes.shape({
    share: PropTypes.array,
  }).isRequired,
  resizeMapKey: PropTypes.number.isRequired,
};
