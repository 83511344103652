/** @format */

import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useHeaderHeight } from '@hooks';
import {
  OrganizationSchedule as COrganizationSchedule,
  FullScreen,
} from '@components';

const OrganizationSchedule = () => {
  const largeScreen = useSelector(state => state.app.largeScreen);

  const [headerHeight] = useHeaderHeight();
  const location = useLocation();
  const history = useHistory();

  const goToHome = () => {
    history.push('/');
  };

  if (!location.state) {
    goToHome();
    return null;
  }

  const orgDetails = location.state.details;

  return largeScreen ? (
    <COrganizationSchedule orgDetails={orgDetails} goToHome={goToHome} />
  ) : (
    <FullScreen id="org-schedule-full-screen" headerHeight={headerHeight}>
      <COrganizationSchedule orgDetails={orgDetails} goToHome={goToHome} />
    </FullScreen>
  );
};

export default OrganizationSchedule;
