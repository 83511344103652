/** @format */

import React from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { theme } from '@constants';

import { FullScreenLoaderCol, LoaderIcon } from './styled';

export const FullScrenLoader = () => {
  return (
    <FullScreenLoaderCol justify="center" align="center">
      <LoaderIcon size="lg" color={theme.WHITE} icon={faSpinner} spin />
    </FullScreenLoaderCol>
  );
};

export default FullScrenLoader;
