/** @format */

const formFields = [
  {
    name: 'email',
    label: 'login.fields.email.label',
    type: 'email',
    focusNext: true,
    validator: {
      required: 'login.fields.email.validation.required',
      pattern: {
        value: /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z\-0-9]+.)+[a-zA-Z]{2,}))$/i,
        message: 'login.fields.email.validation.invalid',
      },
    },
  },
  {
    name: 'password',
    label: 'login.fields.password.label',
    type: 'password',
    focusNext: true,
    validator: {
      required: 'login.fields.password.validation.required',
    },
  },
];

export default {
  formFields,
};
