/** @format */
const formFields = [
  {
    name: 'firstName',
    label: 'signup.fields.first-name.label',
    type: 'text',
    focusNext: true,
    validator: {
      required: 'signup.fields.first-name.validation.required',
      pattern: {
        value: /^[a-z ,.'-]+$/i,
        message: 'signup.fields.first-name.validation.invalid',
      },
    },
  },
  {
    name: 'lastName',
    label: 'signup.fields.last-name.label',
    type: 'text',
    focusNext: true,
    validator: {
      required: 'signup.fields.last-name.validation.required',
      pattern: {
        value: /^[a-z ,.'-]+$/i,
        message: 'signup.fields.last-name.validation.invalid',
      },
    },
  },
  {
    name: 'email',
    label: 'signup.fields.email.label',
    type: 'email',
    focusNext: true,
    validator: {
      required: 'signup.fields.email.validation.required',
      pattern: {
        // eslint-disable-next-line no-useless-escape
        value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
        message: 'signup.fields.email.validation.invalid',
      },
    },
  },
  {
    name: 'password',
    label: 'signup.fields.password.label',
    type: 'password',
    focusNext: true,
    validator: {
      required: 'signup.fields.password.validation.required',
      minLength: {
        value: 4,
        message: 'signup.fields.password.validation.min-len',
      },
    },
  },
  {
    name: 'phone',
    label: 'signup.fields.phone.label',
    type: 'text',
    focusNext: true,
    validator: {
      required: 'signup.fields.phone.validation.required',
      pattern: {
        value: /^\d{10}$/,
        message: 'signup.fields.phone.validation.invalid',
      },
    },
  },
  {
    name: 'dob',
    label: 'signup.fields.dob.label',
    focusNext: false,
  },
  {
    name: 'language',
    label: 'signup.fields.language.label',
    focusNext: false,
  },
];

const passwordValidator = {
  required: 'signup.fields.password.validation.invalid',
  minLength: {
    value: 8,
    message: 'signup.fields.password.validation.invalid',
  },
  pattern: {
    // eslint-disable-next-line no-useless-escape
    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d`~!@#$%^&*()+=,<.>/?\[\];:'"{}\|-]{8,}$/,
    message: 'signup.fields.password.validation.invalid',
  },
};

export default {
  formFields,
  passwordValidator,
};