/** @format */

import React, { useCallback, useState } from 'react';
import { ConfirmBooking } from '@components';
import { Portal } from 'react-portal';

import { helpers } from '@utils';
import { useHistory } from 'react-router';

const useBookingConfirmDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [detailProps, setDetailProps] = useState(null);
  const [resetCallback, setResetCallback] = useState(null);
  const history = useHistory();

  const openBookingConfirmDialog = (
    value,
    { created, selectedTripsPrice, extraSeats, resetCallback },
  ) => {
    const [departTrip, returnTrip] = created;
    const [departTripRequest] = departTrip.tripRequests;
    const [returnTripRequest] = returnTrip ? returnTrip.tripRequests : [];

    const departSelfPaid = helpers.getObjectProperty(
      departTripRequest,
      'detail.payment',
      false,
    );

    const points = helpers.getObjectProperty(
      departTripRequest,
      'detail.points',
      false,
    );

    const returnSelfPaid = returnTripRequest
      ? helpers.getObjectProperty(returnTripRequest, 'detail.payment', false)
      : false;

    const details = {
      cost: departSelfPaid || returnSelfPaid ? 'BUY' : 'FREE',
      tripsScheduled: created.length,
    };

    if (points && points.totalPoints !== null) {
      details.cost = "BUY";
    }

    if (departSelfPaid || returnSelfPaid) {
      details.total = selectedTripsPrice.total;
      details.fare = selectedTripsPrice.fare;
      details.fees = selectedTripsPrice.fees;
      details.discount = selectedTripsPrice.discount;
      details.tax = selectedTripsPrice.tax;
      details.totalCredits = selectedTripsPrice.credits;
      details.extraSeats = extraSeats;
      details.departCount =
        (departTrip && departTrip.tripRequests.length) || null; // didn't add 0 because 0 will be rendered there
      details.returnCount =
        (returnTrip && returnTrip.tripRequests.length) || null;
    } else if (points) {
      details.points = points;
    }

    setResetCallback({ ref: resetCallback });
    setDetailProps(details);
    setIsOpen(value);
  };

  const close = () => {
    setIsOpen(false);
    history.push('/my-trips');
    if (resetCallback?.ref !== undefined) {
      resetCallback.ref();
    }
  };

  const BookingConfirmDialog = useCallback(
    () =>
      isOpen ? (
        <Portal id="booking-confirm-portal-instance">
          <ConfirmBooking details={detailProps} toggle={close} />
        </Portal>
      ) : null,

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen, detailProps],
  );

  return {
    BookingConfirmDialog,
    openBookingConfirmDialog,
  };
};
export default useBookingConfirmDialog;
