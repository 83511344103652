/** @format */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { Row, Col, Row as LoaderWrapperRow, Dropdown } from '@components';
import { theme } from '@constants';
import {
  WrapperRow,
  DropdownCol,
  InviteActionsRow,
  InviteButton,
} from './styled';

const getDropdownOptions = (organizations, invites) => {
  const organizationOptions = organizations.map((org, index) => ({
    value: JSON.stringify({ type: 'organization', value: index }),
    label: org.organization.name,
  }));

  const inviteOptions = invites.map(invite => ({
    value: JSON.stringify({ type: 'invite', value: invite.token }),
    label: invite.organization.name,
  }));

  return [...organizationOptions, ...inviteOptions];
};

const getSeledtedOption = (selectedOrganization, selectedInvite) => {
  if (selectedInvite) {
    return JSON.stringify({ type: 'invite', value: selectedInvite });
  }

  if (selectedOrganization !== null) {
    return JSON.stringify({
      type: 'organization',
      value: selectedOrganization,
    });
  }

  return null;
};

export const OrganizationsDropdown = ({
  organizations,
  invites,
  setOrganization,
  setInvite,
  selectedOrganization,
  selectedInvite,
  inviteLoaderType,
  accept,
  decline,
  loading,
  ...restProps
}) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    setSelectedOption(getSeledtedOption(selectedOrganization, selectedInvite));
  }, [selectedOrganization, selectedInvite]);

  const options = getDropdownOptions(organizations, invites);

  const onDropdownSelect = option => {
    const selected = JSON.parse(option.value);

    if (selected.type === 'organization') {
      setOrganization(selected.value);
    } else {
      setInvite(selected.value);
    }
  };

  const renderInviteActions = () => {
    if (!selectedInvite) {
      return null;
    }

    return (
      <InviteActionsRow id="invite-action-row" align="center" justify="center">
        <Col align="center" justify="center" spacingH={10}>
          <InviteButton
            id="accept-btn"
            onClick={accept}
            label={t('profile.fields.invites.accept')}
            color={theme.BASE_COLOR}
            icon={loading && inviteLoaderType === 'accept' ? faSpinner : null}
            iconProps={{ spin: true }}
            disabled={!selectedInvite || loading}
          />
        </Col>
        <Col align="center" justify="center" spacingH={10}>
          <InviteButton
            id="decline-btn"
            onClick={decline}
            label={t('profile.fields.invites.decline')}
            color={theme.BASE_COLOR}
            icon={loading && inviteLoaderType === 'decline' ? faSpinner : null}
            iconProps={{ spin: true }}
            disabled={!selectedInvite || loading}
          />
        </Col>
      </InviteActionsRow>
    );
  };

  const renderDropdown = () => (
    <DropdownCol justify="flex-start" align="flex-start">
      <Row
        data-cy="organizations-dropdown"
        id="options"
        align="center"
        justify="center"
      >
        <Dropdown
          id="options-dropdown"
          placeholder={t('profile.fields.invites.select-organization')}
          options={options}
          onChange={onDropdownSelect}
          value={selectedOption}
          color={theme.BASE_COLOR}
          inverted
          bordered
          rounded
          size="sm"
          {...restProps}
        />
      </Row>

      {renderInviteActions()}
    </DropdownCol>
  );

  return (
    <WrapperRow flex="initial" justify="flex-start" align="flex-start">
      {loading && options.length === 0 && (
        <LoaderWrapperRow
          id="invites-loader"
          justify="center"
          align="center"
          spacingV={30}
        >
          <FontAwesomeIcon id="font-awesome-icon" icon={faSpinner} spin />
        </LoaderWrapperRow>
      )}

      {options.length > 0 && renderDropdown()}
    </WrapperRow>
  );
};

OrganizationsDropdown.propTypes = {
  organizations: PropTypes.array.isRequired,
  invites: PropTypes.array.isRequired,
  selectedOrganization: PropTypes.number,
  selectedInvite: PropTypes.string,
  setOrganization: PropTypes.func.isRequired,
  setInvite: PropTypes.func.isRequired,
  inviteLoaderType: PropTypes.string,
  accept: PropTypes.func.isRequired,
  decline: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

OrganizationsDropdown.defaultProps = {
  selectedOrganization: null,
  selectedInvite: null,
  inviteLoaderType: null,
};

export default OrganizationsDropdown;
