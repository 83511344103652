/** @format */

const formFields = [
  {
    name: 'email',
    label: 'forgot-password.fields.email.label',
    type: 'email',
    focusNext: false,
    validator: {
      required: 'forgot-password.fields.email.validation.required',
      pattern: {
        value: /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z\-0-9]+.)+[a-zA-Z]{2,}))$/i,
        message: 'forgot-password.fields.email.validation.invalid',
      },
    },
  },
];

export default {
  formFields,
};
