/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '@constants';
import { useTranslation } from 'react-i18next';
import { Row, Switch, DateTimePicker } from '@components';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  TextSmall,
  DateInput,
  ValidTimesDropdownWrapper,
  ValidTimesDropdown,
} from './styled';

const TripOverviewDateTime = ({
  isDateTimeChanged,
  onToggleDateTime,
  isValidDate,
  getValidTimes,
  setChangedDate,
  setChangedTime,
  changedTime,
  changedDate,
  state,
}) => {
  const { t } = useTranslation();

  const isValidDepartDate = current => isValidDate(current);
  const { organizationPlaces } = useSelector(stateData => stateData.app);
  
  let validDepartTimes = [];

  if (isDateTimeChanged) {
    validDepartTimes = getValidTimes();
  }

  const renderDepartDateInput = (_inputProps, openCalender) => {
    return (
      <DateInput
        data-cy="depart-date-input"
        id="trip-overvie-work-force-date-input"
        data-event="custom"
        data-for="trip-request-form-tooltip"
        name="depart"
        value={changedDate ?
          moment(changedDate).format('MM/DD/YYYY') :
          moment(state.selection.date).format('MM/DD/YYYY')}
        label={t('trip-request.large-screen.fields.depart-date.placeholder')}
        onFocus={openCalender}
        color={theme.BASE_COLOR}
        inverted
        readOnly
        autoComplete="off"
      />
    );
  };

  const getAvailableTimes = (allTimes, pickupAddress, date) => {
    const validTimes = [];
    allTimes &&
      allTimes.forEach(time => {
        const currentUserTimeZoneUTC = moment
          .tz(moment(), pickupAddress?.timezone)
          .utc().format('YYYY-MM-DDTHH:mm:ss');

        const dateTime = `${moment(date).format('MM/DD/YYYY')} ${time}`;
        const departTime = moment
          .tz(dateTime, pickupAddress?.timezone)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss');
        moment(departTime).isAfter(currentUserTimeZoneUTC) &&
          validTimes.push(time);
      });
    return validTimes;
  };

  const onDateChange = (e) => {
    const pickupAddressLabel = state && state.selection ? state.selection.pickupAddress.label : '';
    let pickupAddress = [];
    organizationPlaces && organizationPlaces.forEach(orgPlace => {
      if (orgPlace.label === pickupAddressLabel) {
        pickupAddress = orgPlace;
      }
    });
    const day = moment(e, 'MM/DD/YYYY').locale('en').format('ddd').toLowerCase();
    const allTimes = getAvailableTimes(pickupAddress.availableTimes.readyBy[day], pickupAddress, e)
    .map(x => x);
    let isTimeInclude = false;
    let currentTime = '';
    allTimes.forEach(x => {
      if (moment(x, ['h:mm A']).format('HH:mm:ss') === state.selection.time) {
        isTimeInclude = true;
        currentTime = x;
      }
    });
    if (isTimeInclude) {
      setChangedTime({ value: currentTime, label: currentTime });
    } else {
      setChangedTime('');
    }
    setChangedDate(e);
  };

  return (
    <>
      <Row justify="space-between" spacingV={10} align="center">
        <TextSmall>{t('trip-overview.labels.change-date-time')} </TextSmall>
        <Switch
          id="repeat-switch"
          checked={isDateTimeChanged}
          onChange={() => {
            onToggleDateTime(!isDateTimeChanged);
          }}
          unCheckedText=""
          checkedText=""
          width={60}
          height={30}
          bgColor={theme.BASE_COLOR}
          triggerColor={theme.WHITE}
          showIcon={isDateTimeChanged}
          opacity={!isDateTimeChanged ? 0.2 : 1}
          triggerSpacing={4}
        />
      </Row>

      {isDateTimeChanged && (
        <Row
          id="depart-time-row"
          flex="initial"
          spacingV={10}
          justify="flex-start"
          align="flex-start"
        >
          <DateTimePicker
            placeholder="Date"
            onChange={onDateChange}
            dateFormat="ddd, MMM DD"
            isValidDate={isValidDepartDate}
            renderInput={renderDepartDateInput}
          />
          <ValidTimesDropdownWrapper
            data-event="custom"
            data-for="trip-request-form-tooltip"
          >
            <ValidTimesDropdown
              data-cy="depart-time-dropdown"
              key={validDepartTimes?.placeholder || ''}
              className="valid-depart-times"
              options={validDepartTimes?.values || []}
              onChange={setChangedTime}
              placeholder={t(
                'trip-request.large-screen.fields.depart-time.pick-up',
              )}
              color={theme.BASE_COLOR}
              value={
                moment(changedDate).format('MM/DD/YYYY') !==
                moment(state.selection.date).format('MM/DD/YYYY') ?
                moment(state.selection.time, ['HH:mm:ss']).format('hh:mm A') : changedTime
              }
              inverted
              rounded
              borderRadius={100}
            />
          </ValidTimesDropdownWrapper>
        </Row>
      )}
    </>
  );
};

TripOverviewDateTime.propTypes = {
  isDateTimeChanged: PropTypes.bool.isRequired,
  changedDate: PropTypes.string.isRequired,
  changedTime: PropTypes.string.isRequired,
  onToggleDateTime: PropTypes.func.isRequired,
  isValidDate: PropTypes.func.isRequired,
  getValidTimes: PropTypes.func.isRequired,
  setChangedTime: PropTypes.func.isRequired,
  setChangedDate: PropTypes.func.isRequired,
  state: PropTypes.shape({
    query: PropTypes.shape({
      orgId: PropTypes.number,
      departDate: PropTypes.string,
      departTime: PropTypes.string,
      returnDate: PropTypes.string,
      returnTime: PropTypes.string,
    }).isRequired,
    selection: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        label: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default TripOverviewDateTime;
