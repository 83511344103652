/** @format */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import { useTranslation, Trans } from 'react-i18next';

import { theme } from '@constants';
import {
  Slider,
  Row,
  Modal,
  Button,
  FullScreen,
  IconButton,
} from '@components';
import { Title, SubTitle, Wrapper, Image, SponsorImage } from './styled';
import { welcomeViews } from './config';
import AAARoundTownSlider from './aaaRoundTownSlider';

const WelcomeScreen = ({
  largeScreen,
  isAAARoundTown,
  onCloseModal,
  orgDetails,
  onClickCreateAccount,
}) => {
  const { t } = useTranslation();
  const [modalHeight, setModalHeight] = useState(false);
  const [modalWidth, setModalWidth] = useState(false);
  const [customClose, setCustomClose] = useState(false);
  const pages = [];

  const setModalHeightWidth = (heightWidth = { height: null, width: null }) => {
    const { height, width } = heightWidth;
    setModalHeight(height);
    setModalWidth(width);
  };

  welcomeViews.map((welcomeView, i) => {
    pages.push(
      <Wrapper justify="center" align="center">
        <Row data-cy="welcome-slider-image" align="center" justify="center">
          <Image
            isLastPage={i === welcomeViews.length - 1}
            src={welcomeView.img}
          />
        </Row>
        <Row
          data-cy="welcome-slider-title"
          justify="center"
          align="center"
          spacingH={20}
        >
          <Title>{t(welcomeView.title)}</Title>
        </Row>

        <Row justify="center" align="center" spacingV={10} spacingH={20}>
          {orgDetails && i === 0 ? (
            <>
              <SubTitle data-cy="welcome-slider-sub-title">
                <Trans
                  i18nKey="welcome-views.share.others.discover-rides"
                  components={{ bold: <b /> }}
                />
              </SubTitle>
              <SponsorImage
                data-cy="welcome-slider-sponsor-image"
                src={orgDetails.logo}
              />
            </>
          ) : (
            <SubTitle data-cy="welcome-slider-sub-title">
              {t(welcomeView.subtitle)}
            </SubTitle>
          )}
        </Row>
        {i === 2 && (
          <Row justify="center" align="center" spacingH={30}>
            <Button
              data-cy="create-account-btn"
              id="create-account-button"
              label={t('signup.buttons.create-account')}
              onClick={onClickCreateAccount}
              color={theme.BASE_COLOR}
              size="md"
            />
          </Row>
        )}
      </Wrapper>,
    );
    return null;
  });

  const isModal = !isAAARoundTown || (isAAARoundTown && largeScreen);

  const renderAAARoundTownWelcome = () => (
    /*
      there are few internal modals in AAA RoundTown Welcome popups which have
      their own heights and widths so we have to update the modal's size accordingly
     */
    <AAARoundTownSlider
      largeScreen={largeScreen}
      onClickCreateAccount={onClickCreateAccount}
      setModalHeightWidth={setModalHeightWidth}
      setCustomClose={isCustomClose => setCustomClose(isCustomClose)}
    />
  );

  const renderWelcome = () => {
    return (
      <>
        {isModal && !customClose && (
          <Row
            align="flex-end"
            justify="flex-end"
            flex={0}
            spacingH={22}
            spacingV={22}
          >
            <IconButton
              data-cy="close-btn"
              id="close-button"
              width={25}
              onClick={onCloseModal}
            />
          </Row>
        )}
        {isAAARoundTown ? (
          renderAAARoundTownWelcome()
        ) : (
          <Slider
            id="welcome-screen-slider"
            views={pages}
            sliderProps={{ numberOfDotsToShow: 3, arrows: true }}
          />
        )}
      </>
    );
  };

  if (isModal) {
    let width = '350';
    let height = null;

    if (isAAARoundTown) {
      // we can't check !modal becaue width can have null in it
      if (modalWidth !== false) {
        width = modalWidth && modalWidth.toString();
      } else {
        width = '820';
      }

      // we can't check !modal becaue height can have null in it
      if (modalHeight !== false) {
        height = modalHeight && modalHeight.toString();
      } else {
        height = (window.innerHeight - 124).toString();
      }
    }

    return (
      <Portal id="welcome-screen-portal">
        <Modal width={width} height={height} open toggle={onCloseModal}>
          {renderWelcome()}
        </Modal>
      </Portal>
    );
  }

  return <FullScreen id="full-screen-welcome">{renderWelcome()}</FullScreen>;
};

WelcomeScreen.propTypes = {
  largeScreen: PropTypes.bool,
  isAAARoundTown: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired,
  onClickCreateAccount: PropTypes.func.isRequired,
  orgDetails: PropTypes.object,
};

WelcomeScreen.defaultProps = {
  largeScreen: false,
  orgDetails: null,
  isAAARoundTown: false,
};

export default WelcomeScreen;
