/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { theme } from '@constants';
import { helpers } from '@utils';
import { Input, Col } from '..';
import config from './config';
import {
  FormWrapper,
  Wrapper,
  Title,
  SubTitle,
  ResetBtn,
  CloseIconStyled,
  CloseIconRow,
} from './styled';

const ResetPasswordForm = ({
  largeScreen,
  onCloseModal,
  onCloseFullScreen,
  isValid,
  validate,
  errors,
  onChange,
  onSubmit,
  state,
  passwordRestriction,
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper justify="space-between" align="center">
      <FormWrapper justify="center" align="center">
        <CloseIconRow align="flex-end" justify="flex-end">
          <CloseIconStyled
            data-cy="close-btn"
            onClick={largeScreen ? onCloseModal : onCloseFullScreen}
            width={20}
          />
        </CloseIconRow>
        <Col justify="flex-start" align="flex-start">
          <Title>{t('reset-password.labels.question')}</Title>
          <SubTitle>{t('reset-password.labels.reset-password')}</SubTitle>
        </Col>
        <Col justify="center" align="center">
          {config.formFields.map((formField, i) => (
            <Input
              key={i}
              data-cy={helpers.camelToKebabCase(formField.name)}
              floating
              type={formField.type}
              name={formField.name}
              onChange={onChange}
              value={state.password}
              label={t(formField.label)}
              color={theme.BASE_COLOR}
              inputRef={validate(!passwordRestriction ? config.passwordValidator : formField.validator)}
              error={t(helpers.getObjectProperty(errors, [
                formField.name,
                'message',
              ]))}
            />
          ))}
        </Col>
      </FormWrapper>
      <ResetBtn
        data-cy="submit-btn"
        id="reset-password-btn"
        label={t('reset-password.buttons.reset-password')}
        color={theme.BASE_COLOR}
        icon={state.loading ? faSpinner : null}
        iconProps={{ spin: true }}
        disabled={!isValid || state.loading}
        onClick={onSubmit}
      />
    </Wrapper>
  );
};

ResetPasswordForm.propTypes = {
  errors: PropTypes.object.isRequired,
  isValid: PropTypes.bool.isRequired,
  validate: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onCloseFullScreen: PropTypes.func.isRequired,
  largeScreen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  state: PropTypes.shape({
    password: PropTypes.string,
    loading: PropTypes.bool,
  }).isRequired,
  passwordRestriction: PropTypes.bool.isRequired,
};

export default ResetPasswordForm;
