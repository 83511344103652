/** @format */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { theme, generals as generalConstants } from '@constants';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import propTypes from 'prop-types';
import {
  CloseIcon,
  TermsAndConditionsText,
  TermsAndConditionsLink,
  ModalContentWrapper,
  ContentContainer,
  TermsConditionsForm,
  ActionButton,
} from './styled';
import { Row } from '..';

const termsUrl = process.env.REACT_APP_TERMS_URL;
const privacyPolicyUrl = process.env.REACT_APP_PRIVACY_POLICY_URL;
const additionalTermsUrl = process.env.REACT_APP_ADDITIONAL_TERMS_URL;
const additionalTerms = process.env.REACT_APP_ADDITIONAL_TERMS;

const TermsAndConditions = ({ loading, onSubmit, onCloseModal }) => {
  const { t } = useTranslation();
  const termsAndConditions = additionalTerms
    ? { basic: true, additionalTerms: true }
    : { basic: true, additionalTerms: false };

  return (
    <div>
      <ModalContentWrapper>
        <TermsConditionsForm id="terms-and-conditions-form">
          <Row align="flex-end" justify="flex-end">
            <CloseIcon
              id="close-btn"
              onClick={() => onCloseModal(false)}
              width={16}
            />
          </Row>
          <Row
            data-cy="terms-and-conditions"
            justify="flex-start"
            align="center"
          >
            <TermsAndConditionsText>
              {t('signup.labels.pledge')}&nbsp;
              <TermsAndConditionsLink href={termsUrl} target="_blank">
                {t('signup.labels.terms-conditions')}
              </TermsAndConditionsLink>
              {additionalTerms &&
                additionalTerms.length &&
                additionalTermsUrl &&
                additionalTermsUrl.length && (
                  <>
                    <TermsAndConditionsText>
                      ,&nbsp;
                    </TermsAndConditionsText>
                    <TermsAndConditionsLink href={additionalTermsUrl} target="_blank">
                      {additionalTerms || t('signup.labels.additional-terms-conditions')}
                    </TermsAndConditionsLink>
                  </>
              )}
              &nbsp;{t('signup.labels.and')}&nbsp;
              <TermsAndConditionsLink href={privacyPolicyUrl} target="_blank">
                {t('signup.labels.privacy-policy')}
              </TermsAndConditionsLink>
              .
            </TermsAndConditionsText>
          </Row>

          <ContentContainer width="100%">
            <Row align="flex-start" justify="space-between" spacingV={8}>
              <ActionButton
                label={t('terms-conditions.buttons.decline')}
                color={theme.BASE_COLOR}
                size="md"
                inverted
                onClick={() => onCloseModal(true)}
                bordered
              />
              <ActionButton
                label={t('terms-conditions.buttons.accept')}
                id="accept-btn"
                color={theme.BASE_COLOR}
                size="md"
                onClick={() => onSubmit(termsAndConditions)}
                icon={
                  loading === generalConstants.RATE_RIDE_LOADING
                    ? faSpinner
                    : null
                }
                iconProps={{ spin: true }}
                bordered
              />
            </Row>
          </ContentContainer>
        </TermsConditionsForm>
      </ModalContentWrapper>
    </div>
  );
};

TermsAndConditions.propTypes = {
  onSubmit: propTypes.func.isRequired,
  onCloseModal: propTypes.func.isRequired,
  loading: propTypes.string,
};
TermsAndConditions.defaultProps = {
  loading: null,
};

export default TermsAndConditions;
