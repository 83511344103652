/** @format */

const formFields = [
  {
    name: 'email-phone',
    label: 'send-referrals.fields.email-phone.label',
    subLabel: 'send-referrals.fields.email-phone.sub-label',
    type: 'text',
    focusNext: false,
    tooltip: 'send-referrals.fields.email-phone.tooltip',
    validator: {
      required: 'send-referrals.fields.email-phone.validation.required',
      pattern: {
        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|^\d{10}$/,
        message: 'send-referrals.fields.email-phone.validation.invalid',
      },
    },
  },
];

export default {
  formFields,
};
