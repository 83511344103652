/** @format */

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import momentTz from 'moment-timezone';
import { FullScreen, RidershipBonus as CRidershipBonus } from '@components';
import { trackAbEvent } from '@slices/app';
import {
  getRidershipTiers,
  // getUserLifetimeStats,
  setRedeemFormState,
  redeemReward,
} from '@slices/ridershipBonus';
import { ridershipBonusHelpers } from '@utils';
import { theme, analytics, generals } from '@constants';

const RidershipBonus = () => {
  const isRidershipDisabled = true;
  const dispatch = useDispatch();
  const history = useHistory();
  const [tiers, setTiers] = useState([]);
  const [levelBonusColor, setLevelBonusColor] = useState(theme.LIGHT_PINK);
  const [isRedeemEnabled, setIsRedeemEnabled] = useState(false);
  const { user, largeScreen, organizations, selectedOrganization, loading } =
    useSelector(store => store.app);
  const {
    ridershipTiers,
    bonus,
    redeemableAmount,
    // userLifetimeStats,
    redeemFormState: { giftCardType, emailAddress, confirmEmailAddress, isRedeemModalOpen },
  } = useSelector(store => store.ridershipBonus);
  const { register, trigger, errors, getValues } = useForm({
    mode: 'onBlur',
  });
  const ridershipBonusEnded = true;

  useEffect(() => {
    if (
      organizations &&
      selectedOrganization !== null &&
      !!organizations[selectedOrganization].detail
    ) {
      const { detail } = organizations[selectedOrganization] || {};
      const { ridershipBonus } = detail || {};
      if (!ridershipBonus || !ridershipBonus.enabled || ridershipBonusEnded) {
        history.push('/');
      } else {
        dispatch(getRidershipTiers());
        // dispatch(getUserLifetimeStats());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganization, organizations]);

  useEffect(() => {
    dispatch(setRedeemFormState({ name: 'emailAddress', value: user.email }));
    dispatch(
      setRedeemFormState({
        name: 'giftCardType',
        value: generals.RIDERSHIP_GIFT_CARD_OPTIONS[0],
      }),
    );
    dispatch(
      setRedeemFormState({
        name: 'confirmEmailAddress',
        value: '',
      }),
    );
  }, [dispatch, user.email]);

  useEffect(() => {
    if (
      ridershipTiers
      // && userLifetimeStats
    ) {
      const tiers = ridershipBonusHelpers.getFormattedTiers(
        ridershipTiers,
        {},
      );
      const levelColor =
        ridershipBonusHelpers.getLevelBonusColor(ridershipTiers);
      setTiers(tiers);
      setLevelBonusColor(levelColor);
      setIsRedeemEnabled(redeemableAmount >= tiers[0].roundTripsBonus);

      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.RIDERSHIP_BONUS_VIEW,
          subJourneyName: 'Ridership Bonus',
          journeyName: 'Rider Experience',
          details: {
            tiers,
          },
        }),
      );
    }
  }, [dispatch, redeemableAmount, ridershipTiers]);

  const handleEmailChange = async event => {
    if (!event.target) return;
    dispatch(
      setRedeemFormState({ name: 'emailAddress', value: event.target.value }),
    );
    await trigger('email');
    await trigger('confirm-email');
  };

  const handleConfirmEmailChange = event => {
    dispatch(
      setRedeemFormState({
        name: 'confirmEmailAddress',
        value: event.target.value,
      }),
    );
  };

  const handleGiftCardTypeClick = option => {
    dispatch(setRedeemFormState({ name: 'giftCardType', value: option }));
  };

  const handleConfirmClick = () => {
    if (Object.keys(errors).length) {
      return;
    }
    dispatch(redeemReward(emailAddress, giftCardType));
  };

  const handleRedeemModalToggle = () => {
    dispatch(setRedeemFormState({ name: 'isRedeemModalOpen', value: !isRedeemModalOpen }));
    if (!isRedeemModalOpen) {
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.RIDERSHIP_REDEEM_BONUS_BUTTON_CLICKED,
          subJourneyName: 'Ridership Bonus',
          journeyName: 'Rider Experience',
        }),
      );
    } else {
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.RIDERSHIP_REDEEM_BONUS_CLOSED,
          subJourneyName: 'Ridership Bonus',
          journeyName: 'Rider Experience',
        }),
      );
    }
  };

  const endOfCurrentMonth = momentTz
    .tz('America/New_York')
    .endOf('month')
    .format('MMMM Do');

  const goBack = () => {
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.RIDERSHIP_BONUS_BACK_BUTTON_CLICKED,
        subJourneyName: 'Ridership Bonus',
        journeyName: 'Rider Experience',
      }),
    );

    history.push('/');
  };

  const matchEmail = value => {
    return getValues('email') === value;
  };

  const component = (
    <CRidershipBonus
      tiers={tiers}
      redeemAmount={redeemableAmount}
      redeemColor={isRedeemEnabled ? theme.BRIGHT_BLUE : theme.REDEEM_GREY}
      redeemTextColor={isRedeemEnabled ? theme.WHITE : theme.REDEEM_GREY_LIGHT}
      levelBonus={bonus}
      levelBonusColor={levelBonusColor}
      goBack={goBack}
      onRedeem={handleRedeemModalToggle}
      redeemDateText={endOfCurrentMonth}
      isRedeemEnabled={isRedeemEnabled}
      largeScreen={largeScreen}
      isRedeemModalOpen={isRedeemModalOpen}
      giftCardType={giftCardType}
      handleGiftCardTypeClick={handleGiftCardTypeClick}
      emailAddress={emailAddress}
      toggleRedeemModal={handleRedeemModalToggle}
      handleEmailChange={handleEmailChange}
      confirmEmailAddress={confirmEmailAddress}
      handleConfirmEmailChange={handleConfirmEmailChange}
      handleConfirmClick={handleConfirmClick}
      handleCancelClick={handleRedeemModalToggle}
      redeemingRidershipBonusLoading={loading}
      validate={register}
      errors={errors}
      matchEmail={matchEmail}
      isRidershipDisabled={isRidershipDisabled}
    />
  );

  return largeScreen ? (
    component
  ) : (
    <FullScreen id="signup-full-screen">{component}</FullScreen>
  );
};

export default RidershipBonus;
