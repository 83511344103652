/** @format */

import { theme } from '@constants';

const cardStyles = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: theme.BASE_COLOR,
      fontFamily: 'Poppins, Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: theme.BASE_COLOR },
      '::placeholder': { color: theme.GREY },
    },
    invalid: {
      iconColor: theme.TOMATO,
      color: theme.TOMATO,
    },
  },
};

export { cardStyles };
