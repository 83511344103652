/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { theme } from '@constants';
import { tripHelpers, helpers } from '@utils';
import { ReactAddToCalendar, Button, Col, Row } from '..';
import {
  TripColumn,
  TripWrapper,
  TripHeaderColumn,
  TripHeaderRow,
  ButtonWrapper,
  TextValueWrapper,
  Text,
  Value,
  OptionName,
  SeeDetailsWrapper,
  SeeDetailsText,
  SeeDetailsIcon,
} from './styled';

export const Trip = ({
  optionName,
  tripTime,
  leaveBy,
  arriveBy,
  onClickSelect,
  tripDate,
  selectedTrip,
  onTripSelect,
  status,
  isTransitShareTrip,
  addToCalendarEvent,
  tripRef,
  timezone,
  timeFormat,
  addToCalenderBtnCliked,
  isAAARoundTown,
  calendarItemClicked,
  isPaymentFailed,
  ...restProps
}) => {
  const { t } = useTranslation();
  const leaveByDate = tripHelpers.convertTimeToGivenTimeZone(
    leaveBy,
    timezone,
    'MM/DD/YYYY',
    timeFormat,
  );

  const leaveByTime = tripHelpers.convertTimeToGivenTimeZone(
    leaveBy,
    timezone,
    'hh:mm a',
    timeFormat,
  );

  const arriveByTime = tripHelpers.convertTimeToGivenTimeZone(
    arriveBy,
    timezone,
    'hh:mm a',
    timeFormat,
  );

  const getOptionName = oName => {
    if (oName.length > 35) {
      return `${oName.slice(0, 30)} ...`;
    }

    return oName;
  };

  return (
    <TripColumn
      colRef={tripRef}
      id="tripcol"
      flex="initial"
      justify="flex-start"
      align="flex-start"
      bordered={selectedTrip}
      {...restProps}
    >
      {addToCalendarEvent && (
        <ReactAddToCalendar
          event={addToCalendarEvent}
          buttonTemplate={{
            'calendar-plus-o': 'left',
          }}
          buttonLabel=""
          buttonClicked={addToCalenderBtnCliked}
          calendarItemClicked={calendarItemClicked}
        />
      )}
      <TripWrapper id="trip">
        <TripHeaderColumn justify="center" align="flex-start">
          <TripHeaderRow justify="space-around" align="center">
            <Col justify="flex-start" align="flex-start">
              <OptionName>
                {addToCalendarEvent
                  ? getOptionName(addToCalendarEvent.heading)
                  : t(optionName)}
              </OptionName>
            </Col>
            <Col justify="flex-start" align="flex-start">
              <TextValueWrapper>
                <Text color={theme.WHITE}>{t('trip.labels.trip-date')}</Text>
                <Value color={theme.WHITE}>{leaveByDate}</Value>
              </TextValueWrapper>
            </Col>
            {status && (
              <Col justify="flex-start" align="flex-start">
                <TextValueWrapper>
                  <Text color={theme.WHITE}>{t('trip.labels.status')}</Text>
                  <Value color={theme.WHITE} data-cy={`status${helpers.camelToKebabCase(status)}`}>{status}</Value>
                  {
                    isPaymentFailed && <Text color={theme.WHITE}>{t('trip.labels.payment-failed')}</Text>
                  }
                </TextValueWrapper>
              </Col>
            )}

            {onClickSelect && (
              <Col justify="flex-end" align="flex-end">
                <ButtonWrapper>
                  <Button
                    id="select-trip"
                    onClick={onClickSelect}
                    label={t(selectedTrip ? 'trip.buttons.added' : 'trip.buttons.add-trip')}
                    color={theme.BASE_COLOR}
                    bordered
                    inverted
                    size="sm"
                    data-cy="add-trip-btn"
                  />
                </ButtonWrapper>
              </Col>
            )}
          </TripHeaderRow>
        </TripHeaderColumn>
        <Row
          justify="flex-start"
          align="flex-start"
          spacingH={15}
          spacingV={10}
        >
          <Col justify="flex-start" align="flex-start">
            <Text color={theme.BASE_COLOR}>{t('trip.labels.trip-time')}</Text>
            <Value color={theme.BASE_COLOR}>{tripTime}</Value>
          </Col>
          <Col justify="flex-start" align="flex-start">
            <Text color={theme.BASE_COLOR}>{t('trip.labels.estd-time.pickup')}</Text>
            <Value color={theme.BASE_COLOR}>{leaveByTime}</Value>
          </Col>
          <Col justify="flex-start" align="flex-start">
          {isTransitShareTrip ? 
            <Text color={theme.BASE_COLOR} id="label-arrival-before">{t('trip.labels.estd-time.arrival-before')}</Text> :
            <Text color={theme.BASE_COLOR}>{t('trip.labels.estd-time.arrival')}</Text>  
          }
          <Value color={theme.BASE_COLOR}>{arriveByTime}</Value>
          </Col>

          {onTripSelect && (
            <Col id="details-btn" onClick={onTripSelect} justify="flex-end" align="flex-end">
              <SeeDetailsWrapper>
                <Row justify="flex-end" align="flex-end" data-cy="ride-details">
                  <SeeDetailsText data-cy="trip-details">{t('trip.labels.details')}</SeeDetailsText>
                  <SeeDetailsIcon
                    icon={faAngleRight}
                    size="1x"
                    color={theme.BASE_COLOR}
                  />
                </Row>
              </SeeDetailsWrapper>
            </Col>
          )}
        </Row>
      </TripWrapper>
    </TripColumn>
  );
};

Trip.propTypes = {
  optionName: PropTypes.string.isRequired,
  tripTime: PropTypes.string.isRequired,
  leaveBy: PropTypes.string.isRequired,
  arriveBy: PropTypes.string.isRequired,
  onClickSelect: PropTypes.func,
  onTripSelect: PropTypes.func,
  addToCalenderBtnCliked: PropTypes.func,
  calendarItemClicked: PropTypes.func,
  tripDate: PropTypes.string,
  selectedTrip: PropTypes.bool,
  status: PropTypes.string,
  addToCalendarEvent: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
  }),
  tripRef: PropTypes.object,
  timeFormat: PropTypes.string,
  timezone: PropTypes.string,
  isPaymentFailed: PropTypes.bool,
};

Trip.defaultProps = {
  onClickSelect: null,
  onTripSelect: null,
  addToCalenderBtnCliked: null,
  calendarItemClicked: null,
  selectedTrip: false,
  status: null,
  addToCalendarEvent: null,
  tripRef: null,
  tripDate: null,
  timezone: null,
  timeFormat: 'MM/DD/YYYY HH:mm:ss',
  isPaymentFailed: false,
};

export default Trip;
