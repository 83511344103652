/** @format */

import styled from 'styled-components';
import { theme } from '@constants';

export const CheckboxContainer = styled.div`
  display: flex;
  vertical-align: middle;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  overflow: hidden;
  vertical-align: baseline;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  background: ${props => (props.checked ? theme.BASE_COLOR : theme.WHITE)};
  border-radius: 4px;
  border: 1px solid ${theme.LIGHTEN_GRAY};
  transition: all 150ms;

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;

export const CheckBoxLabel = styled.label`
  font-size: ${props => (props.largeScreen ? '20px' : '18px')};
  padding-left: 10px;
  margin-bottom:0;
  color: ${props => (props.inverted ? theme.WHITE : theme.BASE_COLOR)};
  font-weight: bold;
`;
