/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import binIcon from '@assets/icons/bin.svg';

import { helpers } from '@utils';
import { theme, generals } from '@constants';
import { Col, Row, IconButton } from '@components';
import {
  SavedAddressesWrapper,
  SavedAddressLabel,
  SavedAddressValue,
  AddressInput,
  AddressTypeDropDown,
  IconWrapper,
  Wrapper,
  BottomWrapper,
  Heading,
  UpdateBtn,
  AddressCol,
} from './styled';
import { useTranslation } from 'react-i18next';

const SavedAddresses = ({
  addresses,
  addressQuery,
  getAddresses,
  clearAddresses,
  onAddressQueryChange,
  onAddressClick,
  onChangeAddressType,
  onDeleteAddress,
  savedAddresses,
  onSubmit,
  loading,
  goBack,
  source,
}) => {
  const { t } = useTranslation();
  const addressTypes = generals.ADDRESS_TYPES.map(x => {
    return {
      ...x,
      label: t(x.label),
    };
  });
  const zIndexes = savedAddresses.map((address, i) => (
    (i + 1) * (savedAddresses.length + 1) 
  ));

  zIndexes.reverse();
  
  return (
    <>
      <Wrapper>
        <Row flex="initial" align="flex-end" justify="flex-end">
          <IconButton id="close-icon" onClick={goBack} />
        </Row>

        <Col align="center" justify="space-between">
          <SavedAddressesWrapper>
            <Heading>{t('saved-addresses.labels.title')}</Heading>
            <SavedAddressLabel>
              {t('saved-addresses.labels.save-address')}
            </SavedAddressLabel>

            {savedAddresses.map((savedAddress, i) => (
              <Row
                justify="space-between"
                align="center"
                spacingV={6}
                id="saved-address"
                key={i}
              >
                <div style={{ width: '40%' }}>
                  <AddressTypeDropDown
                    options={addressTypes}
                    color={theme.BASE_COLOR}
                    data-cy="address-dropdown"
                    inverted
                    size="sm"
                    value={savedAddress.type}
                    zIndex={zIndexes[i]}
                    bordered
                    rounded
                    onChange={e => {
                      onChangeAddressType(e, i);
                    }}
                  />
                </div>
                <Row justify="space-between" align="center">
                  <SavedAddressValue>
                    {helpers.getStreetAddress(savedAddress.label)}
                  </SavedAddressValue>
                  <IconWrapper
                    id="delete-icon"
                    onClick={() => {
                      onDeleteAddress(i);
                    }}
                  >
                    <IconButton onClick={() => {}} icon={binIcon} />
                  </IconWrapper>
                </Row>
              </Row>
            ))}
            <Row justify="space-between" align="center" spacingV={6}>
              <div style={{ width: '40%' }}>
                <AddressTypeDropDown
                  id="address-type"
                  data-cy="selected-address-type"
                  placeholder="Address Type"
                  options={addressTypes}
                  color={theme.BASE_COLOR}
                  value={t('saved-addresses.fields.address.options.home')}
                  inverted
                  rounded
                  zIndex={0}
                  size="sm"
                  bordered
                  onChange={onChangeAddressType}
                />
              </div>
              <AddressCol justify="flex-start" align="center">
                <AddressInput
                  data-cy="address-field"
                  id="type-address"
                  inputProps={{
                    name: 'address',
                    onChange: onAddressQueryChange,
                    onFocus: getAddresses,
                    value: addressQuery,
                    label: t('saved-addresses.fields.address.placeholder'),
                    inverted: true,
                    color: theme.BASE_COLOR,
                    radius: 8,
                    bordered: true,
                    borderSize: 1,
                    borderColor: theme.LIGHTEN_GRAY,
                  }}
                  suggestions={addresses}
                  savedAddressesExist={savedAddresses.length > 0}
                  onSuggestionClick={onAddressClick}
                  clearSuggestions={clearAddresses}
                />
              </AddressCol>
            </Row>
          </SavedAddressesWrapper>
          <BottomWrapper>
            <UpdateBtn
              id="update-btn"
              data-cy="update-btn"
              onClick={onSubmit}
              label={
                source === 'signup'
                  ? t('saved-addresses.buttons.save-address')
                  : t('saved-addresses.buttons.update-address')
              }
              color={theme.BASE_COLOR}
              icon={loading ? faSpinner : null}
              iconProps={{ spin: true }}
              disabled={loading}
            />
          </BottomWrapper>
        </Col>
      </Wrapper>
    </>
  );
};

SavedAddresses.propTypes = {
  onAddressQueryChange: PropTypes.func.isRequired,
  onAddressClick: PropTypes.func.isRequired,
  onChangeAddressType: PropTypes.func.isRequired,
  onDeleteAddress: PropTypes.func.isRequired,
  addresses: PropTypes.array.isRequired,
  addressQuery: PropTypes.string.isRequired,
  getAddresses: PropTypes.func.isRequired,
  clearAddresses: PropTypes.func.isRequired,
  savedAddresses: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  goBack: PropTypes.func.isRequired,
  source: PropTypes.string,
};

SavedAddresses.defaultProps = {
  source: null,
};

export default SavedAddresses;
