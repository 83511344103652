/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { RadioGroup } from '@components';
import { generals } from '@constants';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { LoaderIcon, LoaderWrapperRow, Container } from './styled';

const getRadioGroupOptions = (
  bookingMethods,
  points,
  userPoints,
  selfPaymentOptions,
  t,
) => {
  const bookingMethodsRadioArray = [];
  bookingMethods &&
    bookingMethods.forEach(x => {
      if (
        bookingMethods.length > 1 &&
        x === generals.BOOKING_METHODS.POINTS &&
        points?.totalPoints > userPoints
      ) {
        return;
      }

      let name = null;
      if (x === generals.BOOKING_METHODS.POINTS) {
        name = `${t('trip-detail.labels.use-points')}: ${points?.totalPoints}`;
        bookingMethodsRadioArray.push({
          id: x,
          name,
          value: x,
          method: x,
        });
      } else {
       Array.isArray(selfPaymentOptions) && selfPaymentOptions.forEach(sp => {
          if (sp === generals.SELF_PAYMENT_OPTIONS.CASH) {
            name = t('trip-detail.labels.self-payment-methods.pay-by-cash');
          } else {
            name = t('trip-detail.labels.self-payment-methods.credit-card');
          }
          bookingMethodsRadioArray.push({
            id: sp,
            name,
            value: sp,
            method: x,
          });
        });
      }
    });

  return bookingMethodsRadioArray;
};

const BookingMethods = ({
  bookingMethods,
  points,
  onChangeBookingMethod,
  userPoints,
  selectedBookingMethod,
  selectedSelfPaymentOption,
  selfPaymentOptions,
}) => {
  const { t } = useTranslation();
  const isLoading =
    bookingMethods.includes(generals.BOOKING_METHODS.POINTS) && points === null;

  const bookingMethodsRadioArray = getRadioGroupOptions(
    bookingMethods,
    points,
    userPoints,
    selfPaymentOptions,
    t,
  );

  const onChangeBookingMethodLocal = (e, value, method) => {
    onChangeBookingMethod(value, method);
  };

  return (
    <Container id="booking-methods-container">
      {isLoading ? (
        <LoaderWrapperRow
          id="trip-amount-loader"
          justify="center"
          align="center"
          spacingV={30}
        >
          <LoaderIcon icon={faSpinner} spin />
        </LoaderWrapperRow>
      ) : (
        <RadioGroup
          label={t('trip-overview.payment-methods.title')}
          onChange={onChangeBookingMethodLocal}
          name="organizationId"
          data-cy="credit-card-button"
          options={bookingMethodsRadioArray}
          buttonsFlow="column"
          checked={selectedBookingMethod === generals.BOOKING_METHODS.SELF_PAID ?
            selectedSelfPaymentOption :
            selectedBookingMethod}
        />
      )}
    </Container>
  );
};

BookingMethods.propTypes = {
  bookingMethods: PropTypes.array.isRequired,
  points: PropTypes.object,
  onChangeBookingMethod: PropTypes.func.isRequired,
  userPoints: PropTypes.number,
  selectedBookingMethod: PropTypes.string,
  selectedSelfPaymentOption: PropTypes.string,
  selfPaymentOptions: PropTypes.array.isRequired,
};

BookingMethods.defaultProps = {
  points: null,
  userPoints: null,
  selectedBookingMethod: null,
  selectedSelfPaymentOption: null,
};

export default BookingMethods;
