/** @format */

import { authReducer } from './auth';
import { appReducer } from './app';
import { myTripsReducer } from './myTrips';
import { paymentMethodsReducer } from './paymentMethods';
import { tripRequestReducer } from './tripRequest';
import { tripDetailReducer } from './tripDetail';
import { fixedRouteReducer } from './fixedRoute';
import { tripRequestWorkforceReducer } from './tripRequestWorkforce';
import { ridershipBonusReducer } from './ridershipBonus';

const reducers = {
  auth: authReducer,
  app: appReducer,
  myTrips: myTripsReducer,
  paymentMethods: paymentMethodsReducer,
  tripRequest: tripRequestReducer,
  tripDetail: tripDetailReducer,
  fixedRoute: fixedRouteReducer,
  tripRequestWorkforce: tripRequestWorkforceReducer,
  ridershipBonus: ridershipBonusReducer,
};

export default reducers;
