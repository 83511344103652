/** @format */
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';

import { theme, device } from '@constants';
import { Col, Row, Button, IconButton } from '..';

export const TripOptionsColumn = styled(Col)`
  height: 100%;

  .collapsible-trigger {
    &.is-open {
      .fa-angle-down {
        transform: rotateZ(180deg);
      }
    }
  }
`;

export const TripsWrapper = styled.div`
  background-color: ${theme.WHITE};
  width: 100%;
  padding: 30px 30px 55px 30px;

  @media ${device.mediaQuery.SMALL_SCREEN} {
    padding: 15px 15px 55px 15px;
  }
`;
export const ConfirmWrapper = styled.div`
  background-color: ${theme.WHITE};
  width: 100%;
  padding: 30px 30px 30px 30px;

  @media ${device.mediaQuery.SMALL_SCREEN} {
    padding: 15px 15px 30px 15px;
  }
`;
export const TitleRow = styled(Row)`
  margin-bottom: 10px;
`;

export const Title = styled.h4`
  color: ${theme.BASE_COLOR};
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0;
`;

export const CollapsibleIcon = styled(FontAwesomeIcon)`
  font-size: 22px;
`;

export const ReturnTrips = styled.div`
  margin-top: 10px;
`;

export const ConfirmButtonColumn = styled(Col)`
  background-color: ${theme.WHITE};
  position: fixed;
  bottom: 0px;
  z-index: 100;
  ${props => (props.largeScreen ? 'width: 30%;' : '')};
`;
 export const BackIcon = styled(IconButton)`
   margin-bottom: 15px;
   cursor: pointer;
 `;
export const ConfirmButton = styled(Button)`
  background-color: ${theme.BASE_COLOR};
  width: 100%;
`;

export const Tooltip = styled(ReactTooltip)`
  &.show {
    opacity: 1 !important;
  }
`;
