/** @format */

import React from 'react';
import PropTypes from 'prop-types';

import { helpers } from '@utils';
import { Modal as SModal, Overlay, Content } from './styled';

// added Modal Position and overflow property to handle position of Modal
export const Modal = ({ children, open, width, toggle, height, overflow, showOverlay, forDemoVideos, borderRadius, margin }) => {
  if (!open) return null;

  const browser = helpers.browserName()?.toLowerCase();
  return (
    <SModal forDemoVideos={forDemoVideos} justify="center" align="center">
     {showOverlay &&
        <Overlay id="modal-overlay" onClick={toggle} />
     }
      <Content
        height={height}
        width={width}
        overflow={overflow}
        browser={browser}
        forDemoVideos={forDemoVideos}
        borderRadius={borderRadius}
        margin={margin}
      >
        {children}
      </Content>
    </SModal>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.element.isRequired),
  ]).isRequired,
  open: PropTypes.bool,
  width: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  height: PropTypes.string,
  overflow: PropTypes.string,
  showOverlay: PropTypes.bool,
  forDemoVideos: PropTypes.bool,
  borderRadius: PropTypes.string,
};

Modal.defaultProps = {
  open: true,
  height: null,
  overflow: null,
  showOverlay: true,
  forDemoVideos: null,
  borderRadius: null,
};

export default Modal;
