/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Row as Suggestion } from '@components';
import {
  SuggestionInputWrapper as SSuggestionInputWrapper,
  SuggestionsOverlay,
  Suggestions,
  ClickHandler,
  SuggestionText,
  SuggestionTextBold,
  InputWrapper,
  SInput,
} from './styled';

const SuggestionInput = ({
  id,
  className,
  inputProps,
  suggestions,
  onSuggestionClick,
  clearSuggestions,
  ...restProps
}) => {
  const { t } = useTranslation();
  return (
    <SSuggestionInputWrapper {...restProps} id={id} className={className}>
      <InputWrapper suggestionsVisible={suggestions.length > 0 ? 1 : 0}>
        <SInput autoComplete="off" {...inputProps} />
      </InputWrapper>

      {suggestions.length > 0 && (
        <>
          <SuggestionsOverlay
            id="suggestion-overlay"
            onClick={clearSuggestions}
          />
          <Suggestions id="suggestion-menu">
            {suggestions.map((suggestion, i) => {
              const type = suggestion.type
                ? t(
                    `saved-addresses.fields.address.options.${suggestion.type.toLowerCase()}`,
                  )
                : null;
              const tag = suggestion.name || type;
              return (
                <Suggestion
                  key={i}
                  id="suggestion"
                  justify="flex-start"
                  align="center"
                >
                  <ClickHandler
                    id="click-handler"
                    onClick={() => onSuggestionClick(i)}
                  >
                    <SuggestionText id="suggestion-text">
                      {tag && (
                        <>
                          <SuggestionTextBold>
                            {tag.toUpperCase()}
                          </SuggestionTextBold>
                          <br />
                        </>
                      )}
                      {suggestion.label}
                    </SuggestionText>
                  </ClickHandler>
                </Suggestion>
              );
            })}
          </Suggestions>
        </>
      )}
    </SSuggestionInputWrapper>
  );
};

SuggestionInput.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  inputProps: PropTypes.shape({
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string,
    icon: PropTypes.object,
    disabled: PropTypes.bool,
    floating: PropTypes.bool,
    color: PropTypes.string,
    inverted: PropTypes.bool,
    bordered: PropTypes.bool,
  }).isRequired,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.label,
    }),
  ).isRequired,
  onSuggestionClick: PropTypes.func.isRequired,
  clearSuggestions: PropTypes.func.isRequired,
};

SuggestionInput.defaultProps = {
  id: '',
  className: '',
};

export default SuggestionInput;
