/** @format */
import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect, Route, useLocation } from 'react-router-dom';
import {
  Map,
  Login,
  Signup,
  Home,
  TripRequest,
  WorkforceTripRequest,
  TripOverview,
  PaymentContainer,
  TripOptions,
  MyTrips,
  LoadCredits,
  AccountSettings,
  TripDetail,
  Header,
  SignupOrg,
  ForgotPassword,
  ResetPassword,
  SavedAddresses,
  SendReferralInvites,
  OrganizationSchedule,
  OrganizationPlaceCards,
  RateRide,
  FixedRoutePublicMap,
  ExtraFieldsAndSpecialNeeds,
  RidershipBonus,
  TermsConditions,
  WorkplaceSelectionForm,
  WorkforceMap,
} from '@containers';
import { Screen, ProtectedRoute } from '@components';

const workforceEnv = process.env.REACT_APP_WORKFORCE_APP === '1';

const Small = ({ isAuthenticated }) => {
  const location = useLocation();

  const renderAccountSettings = ({ match: { url } }) => (
    <>
      <ProtectedRoute
        isAllowed={isAuthenticated}
        path={`${url}/`}
        component={AccountSettings}
        exact
        restricted
      />
      <ProtectedRoute
        isAllowed={isAuthenticated}
        path={`${url}/addresses`}
        component={SavedAddresses}
        restricted
      />
    </>
  );

  if (location.pathname.includes('fixed-route/')) {
    return (
      <Screen>
        <Switch>
          <Route
            path="/fixed-route/:routeKey"
            component={FixedRoutePublicMap}
          />
        </Switch>
      </Screen>
    );
  }

  return (
    <Screen isLarge={false}>
      <Header isAuthenticated={isAuthenticated} />
      {workforceEnv ? <WorkforceMap /> : <Map />}
      <OrganizationPlaceCards isSmall />
      <Switch>
        <Route path="/" exact component={Home} />
        <ProtectedRoute
          isAllowed={!isAuthenticated}
          path="/login"
          redirectTo="/"
          component={Login}
        />
        <ProtectedRoute
          isAllowed={!isAuthenticated}
          path="/forgot-password"
          redirectTo="/"
          component={ForgotPassword}
        />
        <ProtectedRoute
          isAllowed={!isAuthenticated}
          path="/reset-password"
          redirectTo="/"
          component={ResetPassword}
        />
        <ProtectedRoute
          isAllowed={!isAuthenticated}
          path="/signup"
          redirectTo="/"
          component={Signup}
        />
        <ProtectedRoute
          isAllowed
          path="/invite-friends"
          component={SendReferralInvites}
          restricted
          exact
        />
        <ProtectedRoute
          isAllowed={isAuthenticated}
          path="/user-details"
          redirectTo="/"
          component={ExtraFieldsAndSpecialNeeds}
        />
        <Route path="/trip-request" component={workforceEnv ? WorkforceTripRequest : TripRequest} />
        <ProtectedRoute
          isAllowed={isAuthenticated}
          path="/trip-options"
          component={TripOptions}
        />
        <ProtectedRoute
          isAllowed={isAuthenticated}
          path="/trip-overview"
          component={TripOverview}
        />
        <ProtectedRoute
          isAllowed={isAuthenticated}
          path="/my-trips"
          component={MyTrips}
        />
        <ProtectedRoute
          isAllowed={isAuthenticated}
          path="/trip-detail"
          component={TripDetail}
        />
        <ProtectedRoute
          isAllowed={isAuthenticated}
          path="/payment"
          component={PaymentContainer}
        />
        <ProtectedRoute
          isAllowed={isAuthenticated}
          path="/schedule"
          component={OrganizationSchedule}
        />
        <ProtectedRoute
          isAllowed={isAuthenticated}
          path="/account-settings"
          render={renderAccountSettings}
          restricted
        />
        <ProtectedRoute
          isAllowed={isAuthenticated}
          path="/load-credits"
          component={LoadCredits}
          restricted
        />
        <ProtectedRoute
          isAllowed={isAuthenticated}
          path="/transit-trip-detail"
          component={TripDetail}
          redirectTo="/"
        />
        <ProtectedRoute
          isAllowed={isAuthenticated}
          path="/transit-share-trip-detail"
          component={TripDetail}
          redirectTo="/"
        />
        <ProtectedRoute
          isAllowed={!isAuthenticated}
          path="/org/:orgId"
          component={SignupOrg}
          redirectTo="/"
        />
        <ProtectedRoute
          isAllowed={isAuthenticated}
          path="/terms-and-conditions"
          component={TermsConditions}
          redirectTo="/"
        />
        <ProtectedRoute
          isAllowed={isAuthenticated}
          path="/rate-ride"
          component={RateRide}
          redirectTo="/"
        />
        <ProtectedRoute
          isAllowed={isAuthenticated}
          path="/riderbonus"
          component={RidershipBonus}
        />
        <ProtectedRoute
          isAllowed={isAuthenticated}
          path="/select-workplace"
          component={WorkplaceSelectionForm}
          redirectTo="/"
          exact
        />
        <Redirect to="/" />
      </Switch>
    </Screen>
  );
};

Small.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

export default Small;
