/** @format */

// eslint-disable-next-line
import React from 'react';
import { useSelector } from 'react-redux';

const useGetTripPath = () => {
  const { selectedTrip, trips } = useSelector(store => store.tripRequest);
  const selectedTransitShareDepartTrip =
    trips.transitShare.depart[selectedTrip.transitShare.depart];
  const selectedTransitShareReturnTrip =
    trips.transitShare.return[selectedTrip.transitShare.return];

  const selectedTransitShareDepartShareTrip =
    selectedTransitShareDepartTrip && selectedTransitShareDepartTrip.steps.find(t => t.type === 'share');
  const selectedTransitShareReturnShareTrip =
    selectedTransitShareReturnTrip && selectedTransitShareReturnTrip.steps.find(t => t.type === 'share');

  const selectedTransitShareDepartTransitTrip =
    selectedTransitShareDepartTrip && selectedTransitShareDepartTrip.steps.find(t => t.type === 'transit');
  const selectedTransitShareReturnTransitTrip =
    selectedTransitShareReturnTrip && selectedTransitShareReturnTrip.steps.find(t => t.type === 'transit');

  const selectedShareTrip =
    trips.share.depart[selectedTrip.share.depart] ||
    trips.share.return[selectedTrip.share.return] ||
    selectedTransitShareDepartShareTrip ||
    selectedTransitShareReturnShareTrip;

  const selectedTransitTrip =
    trips.transit[selectedTrip.transit] ||
    selectedTransitShareDepartTransitTrip ||
    selectedTransitShareReturnTransitTrip;

  const share = selectedShareTrip?.path || [];
  const transit = selectedTransitTrip ? selectedTransitTrip.stepsDetails : [];

  const tripPath = { share, transit };

  return [tripPath];
};

export default useGetTripPath;
