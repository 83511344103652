/** @format */

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Row } from '../..';
import { theme } from '@constants';

export const TripPathItemRow = styled(Row)`
  position: relative;
  padding: ${props => (props.boundary ? 10 : 20)}px 10px 0px 30px;
  min-height: ${props => (props.busItem ? '100px' : props.lastRow ? '50px' : '70px')};
  &:not(:last-child):before {
    content: '';
    position: absolute;
    width: ${props => (props.busItem ? '10px' : '2px')};
    height: ${props => (props.busItem ? '100%' : 'calc(100% - 6px)')};
    background-color: ${props =>
      props.busItem ? theme.WEIRD_GREEN : theme.BASE_COLOR};
    left: ${props => (props.busItem ? '76px' : '80px')};
    bottom: ${props => (props.busItem ? '-25px' : '-18px')};
    z-index: ${props => (props.busItem ? '2' : '3')};
  }
`;

export const TripPathItemIcon = styled(FontAwesomeIcon)`
  pointer-events: none;
  font-size: ${props => (props.boundary ? 18 : 10)}px;
  position: absolute;
  left: ${props => (props.boundary ? 72 : 76)}px;
  top: ${props => (props.boundary ? 8 : 18)}px;
  z-index: 2;
`;

export const TripPathItemTitle = styled.span`
  font-size: 10px;
  line-height: 1;
  padding-bottom: 2px;
  position absolute;
  left: 0px;
  font-weight: bold;
  max-width: 70px;
  overflow-wrap: break-word;
`;

export const TripPathItemTime = styled.span`
  font-size: ${props => (props.boundary ? 14 : 14)}px;
  line-height: 1;
  padding-bottom: 2px;
  position: absolute;
  left: 100px;
  top: ${props => (props.boundary ? 10 : 15)}px;
  white-space: pre-wrap;
`;

export const TripPathItemAddress = styled.span`
  font-size: ${props => (props.boundary ? 12 : 8)}px;
  line-height: 1;
`;

export const TripPathItemName = styled.span`
  font-size: ${props => (props.boundary ? 12 : 8)}px;
  line-height: 1.2;
  font-weight: bold;
  margin-bottom: 2px;
`;

export const TripPathItemAddressWrapper = styled.div`
  position: absolute;
  left:  ${props => (props.noDatetime ? 110 : 190)}px;
  top: ${props => (props.pathItemActionType ? 15 : 10)}px;
`;

export const TripPathItemTextSmall = styled.small`
  color: ${theme.BASE_COLOR};
  font-size: 10px;
  font-weight: bold;
`;

export const TripPathItemBadgeDescription = styled.span`
  font-size: 8px;
  font-weight: 600;
  line-height: 1;
  color: ${theme.BASE_COLOR};
  padding-bottom: 0;
  padding-left: 5px;
  margin-top: 2px;
`;