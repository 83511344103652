/** @format */
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { theme, device } from '@constants';
import { styles as styleUtils } from '@utils';
import { Row, Col, IconButton } from '..';

export const MyTrips = styled(Col)`
  height: 100%;
`;

export const MyTripsWrapper = styled.div`
  background-color: ${theme.WHITE};
  width: 100%;
  height: 100%;
  padding: 30px 30px 0 30px;
  @media ${device.mediaQuery.SMALL_SCREEN} {
    padding: 30px 15px 0 15px;
  }
`;

export const CloseIconRow = styled(Row)`
  @media ${device.mediaQuery.SMALL_SCREEN} {
    margin-bottom: 10px;
  }
`;

export const MyTripTypeTabsRow = styled(Row)`
  border-radius: 100px;
  margin-bottom: 15px;
  background-color: ${styleUtils.hexToRgba(theme.BASE_COLOR, 0.5)};
  position: relative;
`;

export const TripsCol = styled(Col)`
  position: relative;
  padding-bottom: ${props => (props.pageCount > 1 ? 64 : 0)}px;
  min-height: calc(100% - 80px);
  @media ${device.mediaQuery.SMALL_SCREEN} {
    min-height: calc(100% - 49px);
  }
`;

export const ActiveIndicator = styled.div`
  transition: left 0.2s ease-in-out;
  width: 50%;
  border-radius: 100px;
  height: 100%;
  background-color: ${theme.BASE_COLOR};
  height: 34px;
  position: absolute;
  left: ${props => `${props.selectedIndex * 50}%`};
  z-index: 0;
`;

export const ClickHandler = styled.button`
  width: 100%;
  padding: 0;
  border: none;
  background-color: ${theme.TRANSPARENT};
  :focus {
    outline: none;
  }
`;

export const MyTripTypeCol = styled(Col)`
  border-radius: 100px;
  height: 34px;
`;

export const MyTripTypeText = styled.span`
  color: ${theme.WHITE};
  position: relative;
  z-index: 6;
`;

export const Message = styled.span`
  color: ${theme.BASE_COLOR};
  text-align: center;
  margin-bottom: 15px;
`;

export const LoaderWrapperRow = styled(Row)`
  height: 100%;
`;

export const LoaderIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
`;

export const PaginationRow = styled(Row)`
  position: fixed;
  bottom: 0;
  background-color: ${theme.WHITE};
  z-index: 1000;
  @media ${device.mediaQuery.LARGE_SCREEN} {
    width: 30vw;
  }
`;

export const CloseIcon = styled(IconButton)`
  z-index: 0;
  @media ${device.mediaQuery.SMALL_SCREEN} {
    margin-bottom: 10px;
    position: absolute;
    top: 10px;
  }
  @media ${device.mediaQuery.LARGE_SCREEN} {
    margin-bottom: 15px;
  }
`;