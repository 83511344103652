/** @format */

import React from 'react';
import {
  HomeWrapper,
  WelcomeMessage,
  WelcomeHeading,
  GreetingsListItem,
  GreetingsList,
} from './styled';

const HomeLarge = () => {
  return (
    <HomeWrapper>
      <WelcomeMessage>
        <WelcomeHeading>Welcome to Share Mobility!</WelcomeHeading>
        <GreetingsList>
          <GreetingsListItem>Find transportation options</GreetingsListItem>
          <GreetingsListItem>Plan your trips</GreetingsListItem>
          <GreetingsListItem>
            Set goals to save $, time, and CO2
          </GreetingsListItem>
        </GreetingsList>
      </WelcomeMessage>
    </HomeWrapper>
  );
};

export default HomeLarge;
