/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '@constants';
import { useTranslation } from 'react-i18next';
import { Row, Switch, DateTimePicker } from '@components';
import moment from 'moment';
import {
  TextSmall,
  DateInput,
  ValidTimesDropdownWrapper,
  ValidTimesDropdown,
} from './styled';

const SearchReturnTrip = ({
  isReturn,
  onToggleIsReturn,
  isValidDate,
  getValidTimes,
  setReturnDate,
  setReturnTime,
  returnTime,
  returnDate,
}) => {
  const { t } = useTranslation();

  const isValidTripDate = (current, isReturn = true) => isValidDate(current, isReturn);

  let validDepartTimes = [];

  if (isReturn) {
    validDepartTimes = getValidTimes();
  }

  const renderDepartDateInput = (_inputProps, openCalender) => {
    return (
      <DateInput
        data-cy="depart-date-input"
        id="trip-overvie-work-force-date-input"
        data-event="custom"
        data-for="trip-request-form-tooltip"
        name="depart"
        value={returnDate && moment.utc(returnDate).format('MM/DD/YYYY')}
        label={t('trip-request.large-screen.fields.depart-date.placeholder')}
        onFocus={openCalender}
        color={theme.BASE_COLOR}
        inverted
        readOnly
        autoComplete="off"
      />
    );
  };

  return (
    <>
      <Row justify="space-between" spacingV={10} align="center">
        <TextSmall>{t('trip-overview.labels.search-return-trip')} </TextSmall>
        <Switch
          id="repeat-switch"
          checked={isReturn}
          onChange={() => {
            onToggleIsReturn(!isReturn);
          }}
          unCheckedText=""
          checkedText=""
          width={60}
          height={30}
          bgColor={theme.BASE_COLOR}
          triggerColor={theme.WHITE}
          showIcon={isReturn}
          opacity={!isReturn ? 0.2 : 1}
          triggerSpacing={4}
        />
      </Row>

      {isReturn && (
        <Row
          id="depart-time-row"
          flex="initial"
          spacingV={10}
          justify="flex-start"
          align="flex-start"
        >
          <DateTimePicker
            placeholder="Date"
            onChange={setReturnDate}
            dateFormat="ddd, MMM DD"
            isValidDate={isValidTripDate}
            renderInput={renderDepartDateInput}
          />
          <ValidTimesDropdownWrapper
            data-event="custom"
            data-for="trip-request-form-tooltip"
          >
            <ValidTimesDropdown
              data-cy="depart-time-dropdown"
              key={validDepartTimes?.placeholder || ''}
              className="valid-depart-times"
              options={validDepartTimes?.values || []}
              onChange={setReturnTime}
              placeholder={t(
                'trip-request.large-screen.fields.depart-time.pick-up',
              )}
              color={theme.BASE_COLOR}
              value={returnTime}
              inverted
              rounded
              borderRadius={100}
            />
          </ValidTimesDropdownWrapper>
        </Row>
      )}
    </>
  );
};

SearchReturnTrip.propTypes = {
  isReturn: PropTypes.bool.isRequired,
  returnDate: PropTypes.string.isRequired,
  returnTime: PropTypes.string.isRequired,
  onToggleIsReturn: PropTypes.func.isRequired,
  isValidDate: PropTypes.func.isRequired,
  getValidTimes: PropTypes.func.isRequired,
  setReturnTime: PropTypes.func.isRequired,
  setReturnDate: PropTypes.func.isRequired,
};

export default SearchReturnTrip;
