/** @format */

import styled from 'styled-components';
import { MapContainer, Tooltip } from 'react-leaflet';
import { theme } from '@constants';

export const SMap = styled(MapContainer)`
    width: 100%;
    height: 100%;
    z-index: 1;
`;

export const TimeRow = styled.p`
  margin: 5px 0 !important;
  text-transform: capitalize;
  text-align: center;
`;

export const TextAlign = styled.div`
  text-align: center;
`;

export const BoldText = styled(TextAlign)`
  font-weight: bold;
`;

export const STooltip = styled(Tooltip)`
  background-color: ${theme.WEIRD_GREEN};
  border: 1px solid transparent;
  border-radius: 3px;
  color: ${theme.WHITE};
  padding: 8px 21px;
  font-size: 13px;
  font-family: Poppins;

  &.leaflet-tooltip-top:before {
    border-top-color: ${theme.WEIRD_GREEN};
  }
`;