/** @format */
import React, { useEffect } from 'react';
import { generals as generalConstants, analytics } from '@constants';
import { MapWorkforce as CMapWorkforce } from '@components';
import {
  fetchOrgDetails,
  fetchOrgPlaces,
  setCurrentLocation,
  getWorkForceOrganizationsPlaces,
  trackEvent,
} from '@slices/app';
import { fetchTripRequests, setSearchQueryPlaceIds, setSearchQueryTime, setTrips, setSelectedMarker, setFirstTimeFitBounds } from '@slices/tripRequestWorkforce';
import { useDispatch, useSelector } from 'react-redux';

const getTripAddresses = (places) => {
  const [pickup, destination] = places;
  return {pickup: pickup.address, destination: destination.address};
}

const MapWorkforce = () => {
  const dispatch = useDispatch();
  const {
    user,
    userAgent,
    currentLocation,
    selectedOrganization,
    organizationPlaces,
    tripMapMarkers,
    tripPath,
    resizeMapKey,
    largeScreen,
  } = useSelector(state => state.app);
  const { selectedMarker, firstTimeFitBounds, loading, selectedTripDetail } = useSelector(
    state => state.tripRequestWorkforce,
  );

  const tripAddresses = selectedTripDetail ? getTripAddresses(selectedTripDetail.places) : null;

  const tripRequestsLoading =
    loading ===
    (generalConstants.TRIP_OPTIONS_WORKFORCE_LOADING ||
      generalConstants.WORKFORCE_TRIPS_PAGINATION_COUNT);

  useEffect(() => {
    if (!user && process.env.REACT_APP_WORKFORCE_ORG_IDS) {
      const workForceOrgIds =
        process.env.REACT_APP_WORKFORCE_ORG_IDS.split(',');
      if (workForceOrgIds.length > 0) {
        dispatch(getWorkForceOrganizationsPlaces());
      }
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (user) {
      dispatch(fetchOrgPlaces());
      dispatch(fetchOrgDetails());
    }
    navigator.geolocation.getCurrentPosition(pos => {
      const {
        coords: { latitude, longitude },
      } = pos;
      dispatch(setCurrentLocation([latitude, longitude]));
    });
    dispatch(setFirstTimeFitBounds(false));
  }, [dispatch, selectedOrganization, user]);

  const searchTripRequests = (orgPlaceIds) => {
    if (orgPlaceIds) {
      dispatch(setSearchQueryPlaceIds(orgPlaceIds));
      dispatch(setSearchQueryTime(new Date().toISOString()));
      dispatch(fetchTripRequests());
    } else {
      const trips = {
        share: {
            data: [],
            total: 0,
        },
      };
      dispatch(setTrips(trips));
      dispatch(setSearchQueryPlaceIds(null));
    }
  };

  const onSetSelectedMarker = (value) => {
    const selectedMarker = organizationPlaces.find(place => place.orgPlaceId === value);
    dispatch(setSelectedMarker(value));
    if (!selectedMarker) {
      return;
    }
    dispatch(
      trackEvent(analytics.ORGANIZATION_PLACE_MARKER_SELECTED, {
        name: selectedMarker.name,
        address: selectedMarker.label,
        id: value,
      }),
    );
  };

  const onSetFirstTimeFitBounds = (value) => {
    dispatch(setFirstTimeFitBounds(value));
  };

  return <CMapWorkforce
    organizationPlaces={organizationPlaces}
    currentLocation={currentLocation}
    tripMarkers={tripMapMarkers}
    tripAddresses={tripAddresses}
    tripPath={tripPath}
    resizeMapKey={resizeMapKey}
    searchTripRequests={searchTripRequests}
    selectedMarker={selectedMarker}
    setSelectedMarker={onSetSelectedMarker}
    firstTimeFitBounds={firstTimeFitBounds}
    setFirstTimeFitBounds={onSetFirstTimeFitBounds}
    isUserLoggedIn={user || userAgent ? true : false}
    tripRequestsLoading={tripRequestsLoading}
    largeScreen={largeScreen}
  />;
};

export default MapWorkforce;
