/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { faCalendarAlt, faUser, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { Row as QuickLinksRow, Col as QuickLinkItemCol } from '..';
import {
  QuickLinkItemLink,
  QuickLinkItemIconRow,
  QuickLinkItemIcon,
  QuickLinkItemTitle,
} from './styled';

export const QuickLinks = ({ onClickGoals, user, openSendReferralCodeDialog, isReferralEnabled }) => {
  const { t } = useTranslation();

  const sendReferralLinkProps = user
    ? { onClick: openSendReferralCodeDialog }
    : { to: 'login' };

  return (
    <QuickLinksRow
      justify="space-evenly"
      align="center"
      spacingV={10}
      spacingH={30}
      id="quick-links-container"
    >
      {/* <QuickLinkItem onClick={onClickGoals}>
        <QuickLinkItemCol
          flex="initial"
          justify="flex-start"
          align="center"
          fullWidth={false}
        >
          <QuickLinkItemIconRow flex="initial" justify="center" align="center">
            <QuickLinkItemIcon icon={faFlagCheckered} />
          </QuickLinkItemIconRow>
          <QuickLinkItemTitle>Goals</QuickLinkItemTitle>
        </QuickLinkItemCol>
      </QuickLinkItem> */}

      <QuickLinkItemLink id="my-rides-link" to={user ? '/my-trips' : '/login'}>
        <QuickLinkItemCol
          flex="initial"
          justify="flex-start"
          align="center"
          fullWidth={false}
        >
          <QuickLinkItemIconRow flex="initial" justify="center" align="center">
            <QuickLinkItemIcon icon={faCalendarAlt} />
          </QuickLinkItemIconRow>
          <QuickLinkItemTitle>{t('header.buttons.rides')}</QuickLinkItemTitle>
        </QuickLinkItemCol>
      </QuickLinkItemLink>

      {!!user?.OrganizationUsers?.length && isReferralEnabled && (
        <QuickLinkItemLink {...sendReferralLinkProps} id="send-referral-link">
          <QuickLinkItemCol
            flex="initial"
            justify="flex-start"
            align="center"
            fullWidth={false}
          >
            <QuickLinkItemIconRow
              flex="initial"
              justify="center"
              align="center"
            >
              <QuickLinkItemIcon icon={faDollarSign} />
            </QuickLinkItemIconRow>
            <QuickLinkItemTitle className="new-feature">
              {t('send-referrals.labels.title')}
            </QuickLinkItemTitle>
          </QuickLinkItemCol>
        </QuickLinkItemLink>
      )}

      <QuickLinkItemLink
        id="profile-link"
        to={user ? '/account-settings' : '/login'}
      >
        <QuickLinkItemCol
          flex="initial"
          justify="flex-start"
          align="center"
          fullWidth={false}
        >
          <QuickLinkItemIconRow flex="initial" justify="center" align="center">
            <QuickLinkItemIcon icon={faUser} />
          </QuickLinkItemIconRow>
          <QuickLinkItemTitle>{t('header.buttons.profile')}</QuickLinkItemTitle>
        </QuickLinkItemCol>
      </QuickLinkItemLink>
    </QuickLinksRow>
  );
};

QuickLinks.propTypes = {
  user: PropTypes.object,
  onClickGoals: PropTypes.func,
  openSendReferralCodeDialog: PropTypes.func.isRequired,
  isReferralEnabled: PropTypes.bool.isRequired,
};

QuickLinks.defaultProps = {
  user: null,
  onClickGoals: () => {},
};

export default QuickLinks;
