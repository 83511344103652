/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '@constants';
import {
  InputWrapper,
  Input as SInput,
  InputIconWrapper,
  InputIcon,
  InputError,
  FloatingInputWrapper,
  FloatingInput,
  FloatingInputError,
  FloatingInputIcon,
  ClearIcon,
  CountryCode,
  CustomInputIcon,
} from './styled';

const Input = ({
  inputRef,
  id,
  className,
  onChange,
  onBlur,
  onFocus,
  value,
  label,
  name,
  icon,
  disabled,
  type,
  floating,
  color,
  inverted,
  bordered,
  paddingX,
  error,
  readOnly,
  bold,
  iconProps,
  autoComplete,
  onClear,
  radius,
  borderSize,
  borderColor,
  placeholderInsideInput,
  ...restProps
}) => {
  const turnOffNegativeNumbers = inputType => {
    return inputType === 'number' ? { min: '0' } : {};
  };

  return floating ? (
    <FloatingInputWrapper id={id}>
      <InputIconWrapper>
        {icon && name === 'phone' && <CustomInputIcon src={icon} />}
        {icon && name !== 'phone' && (
          <FloatingInputIcon
            id="floating-input-icon"
            icon={icon}
            color={color}
            {...iconProps}
          />
        )}
        {name === 'phone' && <CountryCode>+1</CountryCode>}
        <FloatingInput
          inputRef={inputRef}
          className={className}
          name={name}
          placeholder={label}
          disabled={disabled}
          type={type}
          value={value}
          onChange={onChange}
          color={color}
          error={error}
          readOnly={readOnly}
          onBlur={onBlur}
          onFocus={onFocus}
          autoComplete={autoComplete}
          placeholderInsideInput={placeholderInsideInput}
          {...turnOffNegativeNumbers(type)}
          {...restProps}
        />
      </InputIconWrapper>
      {error && (
        <FloatingInputError data-cy="input-error" id="error">
          {error}
        </FloatingInputError>
      )}
    </FloatingInputWrapper>
  ) : (
    <InputWrapper id={id} className={className}>
      <InputIconWrapper>
        {icon && <InputIcon icon={icon} color={color} {...iconProps} />}

        <SInput
          ref={inputRef}
          name={name}
          placeholder={label}
          icon={icon}
          disabled={disabled}
          type={type}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          color={color}
          inverted={inverted ? 1 : 0}
          bordered={bordered ? 1 : 0}
          paddingx={paddingX}
          readOnly={readOnly}
          bold={bold}
          radius={radius}
          borderSize={borderSize}
          borderColor={borderColor}
          autoComplete={autoComplete}
          {...restProps}
        />
        {onClear && (
          <ClearIcon onClick={onClear} width={12} />
        )}
      </InputIconWrapper>

      {error && (
        <InputError data-cy="input-error" id="error">
          {error}
        </InputError>
      )}
    </InputWrapper>
  );
};

Input.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  id: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  name: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  iconProps: PropTypes.object,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  floating: PropTypes.bool,
  color: PropTypes.string,
  inverted: PropTypes.bool,
  bordered: PropTypes.bool,
  paddingX: PropTypes.number,
  readOnly: PropTypes.bool,
  error: PropTypes.string,
  bold: PropTypes.bool,
  radius: PropTypes.number,
  borderSize: PropTypes.number,
  borderColor: PropTypes.string,
  placeholderInsideInput: PropTypes.string,
  autoComplete: PropTypes.oneOf(['on', 'off']),
};

Input.defaultProps = {
  inputRef: null,
  id: '',
  className: '',
  onChange: null,
  onClear: null,
  onBlur: null,
  onFocus: null,
  name: null,
  icon: null,
  iconProps: {},
  disabled: false,
  type: 'text',
  floating: false,
  color: theme.BASE_COLOR,
  inverted: false,
  bordered: false,
  paddingX: 20,
  readOnly: false,
  error: null,
  bold: false,
  autoComplete: 'on',
  value: '',
  radius: null,
  borderSize: 2,
  borderColor: null,
  placeholderInsideInput: '',
};

export default Input;
