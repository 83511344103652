/** @format */

import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import {
  SuggestionInput,
  Dropdown,
  DateOfBirthPicker,
  Input,
  Button,
} from '@components';
import { theme, device } from '@constants';
import { styles as styleUtils } from '@utils';

export const Wrapper = styled.div`
  ${styleUtils.flex('flex-start', 'center', 'column')}
  flex: 1;
  font-family: Bryant;

  @media ${device.mediaQuery.SMALL_SCREEN} {
    padding: 20px;
  }

  @media ${device.mediaQuery.LARGE_SCREEN} {
    padding: 30px;
  }
`;

export const DOBPicker = styled(DateOfBirthPicker)`
  margin-bottom: 10px;
`;

export const NotInvitedText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${theme.TOMATO};
  margin-bottom: 15px;
`;

export const FormWrapper = styled.div`
  ${styleUtils.flex('flex-start', 'center', 'column')}
  flex: 1;
`;

export const Form = styled.div`
  ${styleUtils.flex('center', 'center', 'column')}

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & > * {
    margin: 10px 0px;
    flex: 45%;
  }
`;

export const SavedAddressesWrapper = styled.div`
  ${styleUtils.flex('flex-start', 'flex-start', 'column')}
`;

export const SavedAddressLabel = styled.span`
  font-size: 10px;
  letter-spacing: 1px;
  margin: 10px 0;
  color: ${theme.BLUE_GREY};
`;

export const SavedAddress = styled.div`
  ${styleUtils.flex('space-between', 'center')}
`;

export const SavedAddressTag = styled.span`
  width: 85px;
`;

export const SavedAddressValue = styled.span`
  flex: 1;
  color: ${theme.BASE_COLOR};
  margin: 6px 0 0 10px;
`;

export const AddressInput = styled(SuggestionInput)`
  margin-top: 3px;
  width: 100%;

  Suggestions {
    left: 20px;
  }
`;

export const BottomWrapper = styled.div`
  ${styleUtils.flex('flex-start', 'center', 'column')}
  margin-top: 15px;
`;

export const DOB = styled.div`
  ${styleUtils.flex('flex-start', 'flex-start', 'column')}
  width: 100%;
`;

export const AddIcon = styled(FontAwesomeIcon)`
  color: ${theme.BRIGHT_SKY_BLUE};
  font-size: 18px;
  margin-left: 5px;
  margin-bottom: -4px;
  cursor: pointer;
`;

export const DeleteIcon = styled(FontAwesomeIcon)`
  color: ${theme.TOMATO};
  font-size: 18px;
  margin-left: 5px;
  cursor: pointer;
`;

export const AddressTypeDropDown = styled(Dropdown)`
  box-shadow: none;

  .rdd-menu {
    z-index: 6;
  }
`;

export const Organizations = styled.span`
  margin-bottom: 10px;
  color: ${theme.BASE_COLOR};
`;

export const OrganizationsTitle = styled.span`
  font-weight: 600;
`;

export const Balance = styled.span`
  font-weight: 600;
  margin-bottom: 15px;
`;

export const BalanceText = styled.span`
  margin-bottom: 10px;
  color: ${theme.BASE_COLOR};
`;

export const CreditWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
`;

export const IconWrapper = styled.span`
  margin-top: 5px;
`;

export const SInput = styled(Input)`
  span {
    color: ${theme.INPUT_LABEL_COLOR};
  }
`;

export const StyledButton = styled(Button)`
  border-radius: 8px;
  font-size: 18px;
  line-height: 20px;
  font-family: Bryant;
  font-weight: 700;
  height: 50px;
`;

export const SLink = styled(Link)`
  margin-left: auto;
`;

export const ButtonIcon = styled(FontAwesomeIcon)`
  margin-right: 0;
  color: ${theme.BASE_COLOR};
`;

export const Tooltip = styled(ReactTooltip)`
  &.show {
    opacity: 1 !important;
  }
`;
