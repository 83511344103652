/** @format */

import React, { useCallback, useState } from 'react';

import { useHistory } from 'react-router';
import { WelcomeScreen as CWelcomeScreen } from '@components';

const useWelcomeDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [orgDetails, setOrgDetails] = useState(null);
  const history = useHistory();

  const openWelcomeDialog = orgDetails => {
    localStorage.setItem('welcomed', true);
    setOrgDetails(orgDetails);
    setIsOpen(true);
  };

  const onClickCreateAccount = () => {
    onCloseModal();
    history.push('/signup');
  };

  const onCloseModal = () => {
    setIsOpen(false);
  };

  const WelcomeDialog = useCallback(
    ({ largeScreen, isAAARoundTown }) => {
      if (!isOpen) {
        return null;
      }

      return (
        <CWelcomeScreen
          id="welcome-screen"
          largeScreen={largeScreen}
          isAAARoundTown={isAAARoundTown}
          onCloseModal={onCloseModal}
          onClickCreateAccount={onClickCreateAccount}
          orgDetails={orgDetails && orgDetails}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen, orgDetails],
  );

  return {
    WelcomeDialog,
    openWelcomeDialog,
  };
};
export default useWelcomeDialog;
