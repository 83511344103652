/** @format */

import styled from 'styled-components';
import { device } from '@constants';

export const Key = styled.span`
  @media ${device.mediaQuery.SMALLEST_SCREEN} {
    font-size: 12px;
  }
`;

export const Value = styled.span`
  font-weight: 600;

  @media ${device.mediaQuery.SMALLEST_SCREEN} {
    font-size: 12px;
  }
`;
