/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  faCircle,
  faCircleNotch,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';

import { tripHelpers } from '@utils';
import { PathItem } from '../pathItem';

const isAAARoundTown = process.env.REACT_APP_ENV_IDENTIFIER === 'aaa_roundtown';

const ShareTripPath = ({
  leaveDate,
  arriveDate,
  pickupAddress,
  destinationAddress,
  leaveBy,
  arriveBy,
  timezone,
  isTransitShareTrip,
}) => {
  const { t } = useTranslation();
  const pickupDate = tripHelpers.convertTimeToGivenTimeZone(
    leaveDate,
    timezone,
    'MM/DD/YYYY',
    'MM/DD/YYYY HH:mm:ss a',
  );

  const pickupTime = tripHelpers.convertTimeToGivenTimeZone(
    leaveDate,
    timezone,
    'hh:mm a',
    'MM/DD/YYYY HH:mm:ss a',
  );

  const destinationDate = tripHelpers.convertTimeToGivenTimeZone(
    arriveDate,
    timezone,
    'MM/DD/YYYY',
    'MM/DD/YYYY HH:mm:ss a',
  );

  const destinationTime = tripHelpers.convertTimeToGivenTimeZone(
    arriveDate,
    timezone,
    'hh:mm a',
    'MM/DD/YYYY HH:mm:ss a',
  );

  return (
    <>
      <PathItem
        title={leaveBy && t('trip-overview.labels.estd-time.pickup')}
        time={`${pickupDate}\n${pickupTime}`}
        address={pickupAddress}
        text={isAAARoundTown ? "AAA 'RT" : 'SHARE'}
        icon={leaveBy ? faCircleNotch : faCircle}
        iconRotation
      />

      {destinationAddress && (
        <PathItem
          title={
            arriveBy &&
            (isTransitShareTrip
              ? t('trip.labels.estd-time.arrival-before')
              : t('trip.labels.estd-time.arrival'))
          }
          time={`${destinationDate}\n${destinationTime}`}
          address={destinationAddress}
          icon={arriveBy ? faCheckCircle : faCircle}
        />
      )}
    </>
  );
};

ShareTripPath.propTypes = {
  leaveDate: PropTypes.string.isRequired,
  pickupAddress: PropTypes.string.isRequired,
  arriveDate: PropTypes.string,
  destinationAddress: PropTypes.string,
  leaveBy: PropTypes.bool,
  arriveBy: PropTypes.bool,
  timezone: PropTypes.string.isRequired,
};

ShareTripPath.defaultProps = {
  leaveBy: null,
  arriveBy: null,
  arriveDate: null,
  destinationAddress: null,
};

export default ShareTripPath;
