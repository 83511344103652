/** @format */

import React, { useCallback, useState } from 'react';
import { Portal } from 'react-portal';
import { WorkInProgress as CWorkInProgress, Modal } from '@components';

const useWorkInProgress = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openWorkInProgress = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  const WorkInProgress = useCallback(
    () =>
      isOpen ? (
        <Portal id="work-in-progress-portal-instance">
          <Modal width="300" open toggle={close}>
            <CWorkInProgress componentName="Goals" onClose={close} />
          </Modal>
        </Portal>
      ) : null,

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen],
  );

  return {
    WorkInProgress,
    openWorkInProgress,
  };
};
export default useWorkInProgress;
