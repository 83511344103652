/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { theme, generals as generalConstants } from '@constants';
import { WorkforceTrip, Pagination } from '@components';
import {
  MyTrips as StyledMyTrips,
  MyTripsWrapper,
  MyTripTypeTabsRow,
  TripsCol,
  ActiveIndicator,
  ClickHandler,
  MyTripTypeCol,
  MyTripTypeText,
  Message,
  LoaderIcon,
  LoaderWrapperRow,
  CloseIcon,
  PaginationRow,
  CloseIconRow,
} from './styled';
import { tabs } from './config';
import moment from 'moment';

const getTripDateTimes =  trip => {
  const pickupPlace = trip.places[0];
  const dropoffPlace = trip.places[1];

  let leaveTime = trip.leaveTime; 
  let arriveTime = trip.arriveTime;

  if (trip.route) {
    const routePickupLocation = (trip.route?.locations || []).filter(location => location.placeId === pickupPlace.placeId);
    const routeDropoffLocation = (trip.route?.locations || []).filter(location => location.placeId === dropoffPlace.placeId);

    if (routePickupLocation[0]?.estimatedArrival && routeDropoffLocation[0]?.estimatedArrival) {
      leaveTime = routePickupLocation[0].estimatedArrival;
      arriveTime = routeDropoffLocation[0].estimatedArrival;
    }
  }
  leaveTime = moment.tz(leaveTime, pickupPlace.timezone).format('MM/DD/YYYY hh:mm A'); 
  arriveTime = moment.tz(arriveTime, pickupPlace.timezone).format('MM/DD/YYYY hh:mm A');

  return {
    leaveTime,
    arriveTime,
    duration: moment(arriveTime).diff(moment(leaveTime), 'minutes') + ' min'
  }

}

const MyTripsWorkforce = ({
  activeTripsType,
  setActiveTripsType,
  trips,
  onPageChange,
  onSelect,
  loading,
  onClose,
  addToCalenderBtnCliked,
  calendarItemClicked,
}) => {
  const { t } = useTranslation();
  const activeTab = tabs.findIndex(tab => tab.type === activeTripsType);

  const onTabChange = event => {
    const type = event.currentTarget.getAttribute('data-tabtype');
    setActiveTripsType(type);
  };

  const getAddToCalendarEvent = trip => {
    const [pickup, destination] = trip.places;
    const event = {
      title: t('trip.labels.organization-upcoming-trip', 'Share Mobility'),
      description: `
        ${t('common.from')}: ${pickup.address}
        ${t('common.to')}: ${destination.address}
        ${t('trip.labels.booking-ref')}: ${trip.bookingRef}
      `,
      location: pickup.address,
      startTime: trip.leaveTime,
      endTime: trip.arriveTime,
    };

    return event;
  };

  const renderTabContent = () => {
    const tab = tabs[activeTab];
    const tripsOfSelectedType = trips[tab.type].data;
    const { pageCount } = trips[tab.type].pagination;
    const isMyTripsLoading = loading === generalConstants.MY_TRIPS_LOADING;

    return (
      <TripsCol
        id={tab.id}
        justify="flex-start"
        align="center"
        pageCount={pageCount}
      >
        {isMyTripsLoading && (
          <LoaderWrapperRow
            id="my-trips-loader"
            justify="center"
            align="center"
            spacingV={30}
          >
            <LoaderIcon icon={faSpinner} spin />
          </LoaderWrapperRow>
        )}

        {!isMyTripsLoading &&
          tripsOfSelectedType.map((trip, i) => {
            const dateTimes = getTripDateTimes(trip);
            return (
              <WorkforceTrip
                key={i}
                status={trip.status}
                tripTime={dateTimes.duration}
                tripType={trip.requestType}
                routeType={
                  trip.destination &&
                  trip.destination[0] &&
                  trip.destination[0].orgPlaceType === 'WORKPLACE'
                    ? 'DEPART'
                    : 'RETURN'
                }
                leaveBy={dateTimes.leaveTime}
                arriveBy={dateTimes.arriveTime}
                onTripSelect={() => onSelect(trip)}
                addToCalendarEvent={
                  tabs[activeTab].type ===
                    generalConstants.MY_TRIPS_TYPE.upcoming &&
                  getAddToCalendarEvent(trip)
                }
                addToCalenderBtnCliked={addToCalenderBtnCliked}
                calendarItemClicked={calendarItemClicked}
                isBooked={true}
              />
            );
          })}

        {!isMyTripsLoading && tripsOfSelectedType.length === 0 && (
          <Message id="message">
            {tab.titleContent && tab.titleContent.includes('upcoming')
              ? t('my-trips.messages.no-trips.upcoming')
              : t('my-trips.messages.no-trips.past')}
          </Message>
        )}

        {pageCount > 1 && (
          <PaginationRow id="pagination-row" justify="center" align="center">
            <Pagination count={pageCount} onChange={onPageChange} />
          </PaginationRow>
        )}
      </TripsCol>
    );
  };

  return (
    <StyledMyTrips justify="flex-start" align="center">
      <MyTripsWrapper>
        <CloseIconRow justify="flex-end" align="flex-end">
          <CloseIcon
            id="close-my-trips"
            onClick={onClose}
            size="lg"
            color={theme.BASE_COLOR}
            icon={faTimes}
            inverted
          />
        </CloseIconRow>
        <MyTripTypeTabsRow justify="flex-start" align="flex-start">
          <ActiveIndicator selectedIndex={activeTab} />
          {tabs.map((tab, index) => (
            <ClickHandler
              key={index}
              data-tabtype={tab.type}
              id={`${tab.id}-button`}
              onClick={onTabChange}
            >
              <MyTripTypeCol justify="center" align="center">
                <MyTripTypeText>{t(tab.titleContent)}</MyTripTypeText>
              </MyTripTypeCol>
            </ClickHandler>
          ))}
        </MyTripTypeTabsRow>
        {renderTabContent()}
      </MyTripsWrapper>
    </StyledMyTrips>
  );
};

MyTripsWorkforce.propTypes = {
  activeTripsType: PropTypes.string.isRequired,
  setActiveTripsType: PropTypes.func.isRequired,
  trips: PropTypes.shape({
    upcoming: PropTypes.shape({
      data: PropTypes.array,
      pagination: PropTypes.shape({
        pageCount: PropTypes.number,
      }),
    }),
    past: PropTypes.shape({
      data: PropTypes.array,
      pagination: PropTypes.shape({
        pageCount: PropTypes.number,
      }),
    }),
  }).isRequired,
  onPageChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  loading: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  addToCalenderBtnCliked: PropTypes.func,
  calendarItemClicked: PropTypes.func,
};

MyTripsWorkforce.defaultProps = {
  loading: null,
  addToCalenderBtnCliked: null,
  calendarItemClicked: null,
};

export default MyTripsWorkforce;
