/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import {
  faSpinner,
  faEyeSlash,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import { useFocusNextInput } from '@hooks';
import { helpers } from '@utils';
import { theme } from '@constants';
import { useTranslation } from 'react-i18next';

import {
  Form,
  FormWrapper,
  Wrapper,
  TitleWrapper,
  Title,
  SubTitle,
  RememberMeTextWrapper,
  LoginBtn,
  ForgotPasswordText,
  SignupText,
  SignupLink,
  LoginBtnWrapper,
  CloseIconStyled,
  CloseIconRow,
  SInput,
} from './styled';
import config from './config';
import { Row, Checkbox } from '..';

const LoginForm = ({
  largeScreen,
  onCloseModal,
  onCloseFullScreen,
  state,
  loading,
  validate,
  errors,
  onChange,
  onSubmit,
  onClickForgotPassword,
  goToSignupPage,
  rememberMe,
  onChangeRememberMe,
  onChangeShowPassword,
}) => {
  const { t } = useTranslation();

  const formFieldsForFocusNext = config.formFields.filter(o => o.focusNext);
  const [inputRefs, focusOnNextInput] = useFocusNextInput(
    formFieldsForFocusNext,
  );

  const togglePassword = () => {
    onChangeShowPassword(!state.showPassword);
  };

  const getInputIcon = formField => {
    return formField.name === 'password'
      ? { icon: state.showPassword ? faEyeSlash : faEye }
      : {};
  };

  const getInputIconProps = formField => {
    return formField.name === 'password'
      ? {
          iconProps: {
            onClick: togglePassword,
          },
        }
      : {};
  };

  const getInputRef = (ref, formField) => {
    if (!ref) {
      return;
    }

    const index = formFieldsForFocusNext.findIndex(o => o.name === ref.name);

    inputRefs.current[index].current = ref;
    validate(ref, formField.validator);
  };
  return (
    <Wrapper>
      <FormWrapper>
        <CloseIconRow align="flex-end" justify="flex-end">
          <CloseIconStyled
            data-cy="close-btn"
            onClick={largeScreen ? onCloseModal : onCloseFullScreen}
            width={20}
          />
        </CloseIconRow>
        <TitleWrapper>
          <SignupText largeScreen={largeScreen}>
            {t('login.labels.no-account')}{' '}
            <SignupLink
              data-cy="signup-link"
              color={theme.SECONDARY_BASE_COLOR}
              onClick={goToSignupPage}
            >
              {t('header.buttons.signup')}
            </SignupLink>
          </SignupText>

          <Title>{t('login.labels.ready-to-ride')}</Title>
          <SubTitle>{t('login.labels.login-to-book')}</SubTitle>
        </TitleWrapper>
        <Form largeScreen={largeScreen}>
          {config.formFields.map((formField, i) => (
            <SInput
              key={i}
              data-cy={helpers.camelToKebabCase(formField.name)}
              floating
              type={
                formField.name === 'password' && state.showPassword
                  ? 'text'
                  : formField.type
              }
              name={formField.name}
              onChange={onChange}
              onKeyPress={focusOnNextInput}
              value={state[formField.name]}
              label={t(formField.label)}
              color={theme.COD_GRAY_LIGHT}
              inputRef={ref => getInputRef(ref, formField)}
              {...getInputIcon(formField)}
              {...getInputIconProps(formField)}
              error={t(helpers.getObjectProperty(errors, [
                formField.name,
                'message',
              ]))}
            />
          ))}
        </Form>
        <Row justify="space-between" align="center">
          <RememberMeTextWrapper justify="flex-start" align="center">
            <Checkbox
              id="remember-me"
              largeScreen={largeScreen}
              value={rememberMe}
              onChange={onChangeRememberMe}
              label={t('login.labels.remember-me')}
            />
          </RememberMeTextWrapper>
          <ForgotPasswordText
            data-cy="forgot-password-link"
            largeScreen={largeScreen}
            color={theme.COD_GRAY_LIGHT}
            onClick={onClickForgotPassword}
          >
            {t('login.labels.forgot-password')}
          </ForgotPasswordText>
        </Row>
      </FormWrapper>

      <LoginBtnWrapper>
        <LoginBtn
          data-cy="login-submit-btn"
          id="submit-btn"
          onClick={onSubmit}
          label={t('login.buttons.login')}
          color={theme.BASE_COLOR}
          icon={loading ? faSpinner : null}
          iconProps={{ spin: true }}
          disabled={loading}
        />
      </LoginBtnWrapper>
    </Wrapper>
  );
};

LoginForm.propTypes = {
  state: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
    showPassword: PropTypes.bool,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  validate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  rememberMe: PropTypes.bool.isRequired,
  onChangeRememberMe: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onCloseFullScreen: PropTypes.func.isRequired,
  largeScreen: PropTypes.bool.isRequired,
  onClickForgotPassword: PropTypes.func.isRequired,
  goToSignupPage: PropTypes.func.isRequired,
  onChangeShowPassword: PropTypes.func.isRequired,
};

export default LoginForm;
