/** @format */

import { createSlice } from '@reduxjs/toolkit';

import { api, analytics, generals } from '@constants';
import { api as apiClient, toast } from '@utils';
import { trackAbEvent, setUser } from '@slices/app';
import { getTranslatedStringFromKey } from '@i18Loader';

export const defaultState = {
  loading: null,
  ridershipTiers: null,
  bonus: null,
  redeemableAmount: null,
  userLifetimeStats: null,
  redeemFormState: {
    isRedeemModalOpen: null,
    giftCardType: null,
    emailAddress: null,
    confirmEmailAddress: null,
  },
};

const RidershipBonus = createSlice({
  name: 'ridershipBonus',
  initialState: defaultState,
  reducers: {
    setLoading(state, action) {
      const { payload: loading } = action;
      return {
        ...state,
        loading,
      };
    },
    setRidershipTiers(state, action) {
      const { payload: ridershipTiers } = action;

      return {
        ...state,
        ridershipTiers,
      };
    },
    setBonus(state, action) {
      const { payload: bonus } = action;

      return {
        ...state,
        bonus,
      };
    },
    setRedeemableAmount(state, action) {
      const { payload: redeemableAmount } = action;

      return {
        ...state,
        redeemableAmount,
      };
    },
    setUserLifetimeStats(state, action) {
      const { payload: userLifetimeStats } = action;

      return {
        ...state,
        userLifetimeStats,
      };
    },
    setRedeemFormState(state, action) {
      const { payload } = action;

      return {
        ...state,
        redeemFormState: {
          ...state.redeemFormState,
          [payload.name]: payload.value,
        },
      };
    },
  },
});

export const {
  setLoading,
  setRidershipTiers,
  setBonus,
  setRedeemableAmount,
  setUserLifetimeStats,
  setRedeemFormState,
} = RidershipBonus.actions;

export const saveEnrollToRidershipBonus = (orgId, history) => {
  return async dispatch => {
    dispatch(setLoading(true));
    try {
      await apiClient.post(
        api.endpoints.SAVE_ENROLL_TO_RIDERSHIP_BONUS.replace(
          ':organizationId',
          orgId,
        ),
      );
      history.push('/riderbonus');
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.RIDERSHIP_BONUS_ENROLL_SUCCESS,
          subJourneyName: 'Ridership Bonus',
          journeyName: 'Rider Experience',
        }),
      );
      dispatch(setLoading(false));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('SAVE_ENROLL_TO_RIDERSHIP_BONUS', error);
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.RIDERSHIP_BONUS_ENROLL_FAILED,
          subJourneyName: 'Ridership Bonus',
          journeyName: 'Rider Experience',
          details: {
            message: error?.response?.data?.message,
          },
        }),
      );
      dispatch(setLoading(false));
    }
  };
};

export const getRidershipTiers = () => {
  return async (dispatch, getState) => {
    dispatch(setLoading(generals.RIDERSHIP_TIERS_LOADING));
    try {
      const { organizations, selectedOrganization } = getState().app;
      const { organizationId } = (organizations || [])[selectedOrganization] || {};
      const { data } = await apiClient.get(
        api.endpoints.GET_RIDERSHIP_TIERS.replace(':id', organizationId),
      );
      const { tiers, bonus, redeemableAmount } = data;
      dispatch(setRidershipTiers(tiers));
      dispatch(setBonus(bonus));
      dispatch(setRedeemableAmount(redeemableAmount));
      dispatch(setLoading(null));
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.RIDERSHIP_REDEEM_BUTTON_CLICKED,
          subJourneyName: 'Ridership Bonus',
          journeyName: 'Rider Experience',
          details: {
            redeemableAmount,
          },
        }),
      );
    } catch (err) {
      dispatch(setLoading(null));
    }
  };
};

export const getUserLifetimeStats = () => {
  return async (dispatch, getState) => {
    dispatch(setLoading(generals.USER_LIFETIME_STATS_LOADING));
    try {
      const { organizations, selectedOrganization } = getState().app;
      const { organizationId } = (organizations || [])[selectedOrganization] || {};
      const { data } = await apiClient.get(
        api.endpoints.GET_USER_LIFETIME_STATS.replace(':id', organizationId),
      );
      dispatch(setUserLifetimeStats(data));
      dispatch(setLoading(null));
    } catch (err) {
      dispatch(setLoading(null));
    }
  };
};

export const redeemReward = (emailForGiftCard, giftCardType) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
      const { organizations, selectedOrganization, user } = getState().app;
      if (
        Array.isArray(organizations) &&
        selectedOrganization !== null &&
        selectedOrganization < organizations.length
      ) {
        const { organization } = organizations[selectedOrganization];
        await apiClient.post(
          api.endpoints.RIDERSHIP_REDEEM_REQUEST.replace(
            ':organizationId',
            organization.id,
          ),
          { emailForGiftCard, giftCardType },
        );
        toast.show('success', getTranslatedStringFromKey('ridership-bonus.redeem-modal.redeem-request-sent-successful'));

        // updating the state of RidershipBonus Page manually
        const userPayload = {
          ...user,
          detail: {
            ...user.detail,
            ridershipBonus: {
              ...user.detail.ridershipBonus,
              tiers: [],
              redeemableAmount: 0,
            },
          },
        };
        dispatch(setUser(userPayload));
        localStorage.setItem('user', JSON.stringify(userPayload));
        dispatch(setRedeemableAmount(0));

        dispatch(
          trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
            eventName: analytics.RIDERSHIP_REDEEM_BONUS_SUCCESS,
            subJourneyName: 'Ridership Bonus',
            journeyName: 'Rider Experience',
            details: {
              emailForGiftCard,
              giftCardType,
            },
          }),
        );
        dispatch(setRedeemFormState({ name: 'isRedeemModalOpen', value: false }));
      }
    } catch (error) {
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.RIDERSHIP_REDEEM_BONUS_FAILED,
          subJourneyName: 'Ridership Bonus',
          journeyName: 'Rider Experience',
          details: {
            emailForGiftCard,
            giftCardType,
            message: error?.response?.data?.message,
          },
        }),
      );
    }
    dispatch(setLoading(false));
  };
};

export const ridershipBonusReducer = RidershipBonus.reducer;
