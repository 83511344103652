/** @format */

import { createSlice } from '@reduxjs/toolkit';
import { api, generals as generalConstants } from '@constants';
import { api as apiClient, toast, helpers } from '@utils';
import { leaflet as leafletService } from '@services';
import {
  setFullScreenLoading,
} from '@slices/app';
import moment from 'moment';

const getQueryParam = () => {
  const query = helpers.parseQueryString(window.location.search);
 
  if (query.pickup && query.dropoff && query.oneWayDateTime) {
   return {
     pickup: {
       label: query.pickup,
       lat: parseFloat(query.pickuplat),
       lng: parseFloat(query.pickuplng),
     },
     dropoff: {
       label: query.dropoff,
       lat: parseFloat(query.dropofflat),
       lng: parseFloat(query.dropofflng),
     },
     date: moment(query.oneWayDateTime).format('MM/DD/YYYY'),
     time: moment(query.oneWayDateTime).format('hh:mm A'),
     type: query.type,
     searchInAllFixedRoutes: true,
     };
   }
   return {
     pickup: '',
     dropoff: '',
     date: '',
     time: '',
     type: 'Ready By',
     searchInAllFixedRoutes: false,
   };
 };

export const defaultState = {
  search: {
    query: getQueryParam(),
    pickupSuggestions: [],
    dropoffSuggestions: [],
    results: [],
    selectedResult: null,
    minimized: false,
    cardVisible: false,
  },
  selectedFixedRoute: null,
  fixedRoutes: [],
  loading: null,
  fixedRoutesFetch: null,
};

const FixedRouteSlice = createSlice({
  name: 'fixedRoute',
  initialState: defaultState,
  reducers: {
    setLoading(state, action) {
      const { payload: loading } = action;

      return {
        ...state,
        loading,
      };
    },
    setFixedRoutesFetch(state, action) {
      const { payload: fixedRoutesFetch } = action;

      return {
        ...state,
        fixedRoutesFetch,
      };
    },
    setSearchQuery(state, action) {
      const { payload } = action;

      return {
        ...state,
        search: {
          ...state.search,
          query: {
            ...state.search.query,
            [payload.key]: payload.value,
          },
        },
      };
    },
    setSearch(state, action) {
      const { payload } = action;

      return {
        ...state,
        search: {
          ...state.search,
          [payload.key]: payload.value,
        },
      };
    },
    setSelectedResult(state, action) {
      const { payload } = action;

      return {
        ...state,
        search: {
          ...state.search,
          selectedResult: payload,
        },
      };
    },
    setSelectedFixedRoute(state, action) {
      const { payload } = action;

      return {
        ...state,
        selectedFixedRoute: payload,
      };
    },
    setResults(state, action) {
      const { payload } = action;
      return {
        ...state,
        search: {
          ...state.search,
          results: payload,
        },
      };
    },
    resetSearchAddresses(state) {
      return {
        ...state,
        search: {
          ...state.search,
          pickupSuggestions: [],
          dropoffSuggestions: [],
        },
      };
    },
    setFixedRoutes(state, action) {
      const { payload } = action;

      return {
        ...state,
        fixedRoutes: payload,
      };
    },
    setMinimized(state, action) {
      const { payload } = action;

      return {
        ...state,
        search: {
          ...state.search,
          minimized: payload,
        },
      };
    },
    setCardVisible(state, action) {
      const { payload } = action;

      return {
        ...state,
        search: {
          ...state.search,
          cardVisible: payload,
        },
      };
    },
  },
});

export const {
  setLoading,
  setSearchQuery,
  setSelectedResult,
  setSelectedFixedRoute,
  setResults,
  setSearch,
  resetSearchAddresses,
  setFixedRoutes,
  setMinimized,
  setCardVisible,
  setFixedRoutesFetch,
} = FixedRouteSlice.actions;

const validateFixedRouteSearchRequest = (data) => {
  const { organizationId, type, pickup, dropoff, dateTime } = data || {};

  return !(!organizationId || !type || !pickup || !dropoff || !dateTime.trim());
}

export const searchRoutes = () => {
  return async (dispatch, getState) => {
    dispatch(setLoading(generalConstants.SEARCH_FIXED_ROUTES_LOADING));
    try {
      const {
        fixedRoute: {
          search: { query },
          selectedFixedRoute,
          fixedRoutes,
        },
      } = getState();

      if (!selectedFixedRoute) {
        return;
      }

      const { pickup, dropoff, type, searchInAllFixedRoutes, date, time } =
        query;
      const { id: fixedRouteId, organizationId } = selectedFixedRoute;
      const data = {
        organizationId,
        fixedRouteId,
        type,
        pickup,
        dropoff,
        dateTime: `${date} ${time}`,
      };

      if (searchInAllFixedRoutes) {
        delete data.fixedRouteId;
      }

      if (!validateFixedRouteSearchRequest(data)) {
        return;
      }

      const response = await apiClient.post(
        api.endpoints.SEARCH_FIXED_ROUTES,
        data,
      );

      dispatch(setResults(response.data));
      dispatch(setLoading(null));
      if (response.data.length < 1) {
        toast.show('error', 'No Results Found');
        return;
      }
      const frId = response.data[0].fixedRouteId;
      const index = fixedRoutes.findIndex(o => o.id === frId);
      dispatch(setSelectedResult(0));
      dispatch(setSelectedFixedRoute(fixedRoutes[index]));
      dispatch(setCardVisible(true));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('SEARCH_FIXED_ROUTE_API_ERROR', error);
      dispatch(setLoading(null));
    }
  };
};

export const fetchAddresses = (name, query) => {
  return async dispatch => {
    let leafletAddresses = [];
    try {
      leafletAddresses = await leafletService.search(query);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(`LEAFLET_SEARCH_ERROR ${e}`);
    }
    dispatch(
      setSearch({
        key: `${name}Suggestions`,
        value: leafletAddresses,
      }),
    );
  };
};

export const getFixedRoutesByOrgId = (orgId, errorCallback) => {
  return async (dispatch, getState) => {
    try {
      getQueryParam();
      const { fixedRoutesFetch } = getState();
      dispatch(setFullScreenLoading(true));
      const start = `${moment.utc(new Date()).format('YYYY-MM-DD')}T00:00:00Z`;
      const url = api.endpoints.GET_FIXED_ROUTES.replace(':orgId', orgId);
      const { data: fixedRoutes } = await apiClient.get(
        `${url}?start=${start}&count=3&fetchAllFixedRoutesOfParent=true`,
      );
      dispatch(setFixedRoutes(fixedRoutes.data));
      dispatch(setFullScreenLoading(false));
      // If query params are not there api must not be triggered
      if (fixedRoutesFetch !== undefined) {
        dispatch(setFixedRoutesFetch(true));
        dispatch(searchRoutes());
      }
    } catch (error) {
      typeof errorCallback === "function" && errorCallback();
      dispatch(setFullScreenLoading(false));
      // eslint-disable-next-line no-console
      console.log('GET_FIXED_ROUTES_BY_ORG_ID_ERROR', error);
    }
  };
};

export const getFixedRouteByRouteKey = (routeKey, errorCallback) => {
  return async dispatch => {
    try {
      const { data: fixedRoute } = await apiClient.get(
        api.endpoints.GET_FIXED_ROUTE.replace(':routeKey', routeKey),
      );

      dispatch(setSelectedFixedRoute(fixedRoute));
      dispatch(getFixedRoutesByOrgId(fixedRoute.organizationId, errorCallback));
    } catch (error) {
      typeof errorCallback === "function" && errorCallback();
      // eslint-disable-next-line no-console
      console.log('GET_FIXED_ROUTE_BY_ROUTE_KEY_API_ERROR', error);
    }
  };
};

export const fixedRouteReducer = FixedRouteSlice.reducer;
