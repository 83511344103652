/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Row, Col as PlanTripCol } from '@components';
import Form from './form';
// import RideFilters from './rideFilters';
import {
  TripRequestForm as STripRequestForm,
  // ViewDestinationsCol,
  // ViewDestinationsText,
  Title,
  PlanTripWrapper,
} from './styled';

const TripRequestFormLarge = props => {
  const { t } = useTranslation();
  return (
    <STripRequestForm justify="flex-start" align="flex-start">
      <Row justify="flex-start" align="flex-start">
        <PlanTripWrapper>
          <PlanTripCol justify="flex-start" align="flex-start">
            <Title>{t('trip-request.large-screen.labels.title')}</Title>
            <Form {...props} />
          </PlanTripCol>
        </PlanTripWrapper>
        {/* <ViewDestinationsCol
          flex="initial"
          justify="flex-start"
          align="stretch"
        >
          <ViewDestinationsText>View Destinations</ViewDestinationsText>
          <RideFilters />
        </ViewDestinationsCol> */}
      </Row>
    </STripRequestForm>
  );
};

TripRequestFormLarge.propTypes = {
  state: PropTypes.shape({
    query: PropTypes.shape({
      orgId: PropTypes.number,
      departDate: PropTypes.string,
      departTime: PropTypes.string,
      pickupAddress: PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.string,
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
        PropTypes.string,
      ]),
      returnDate: PropTypes.string,
      returnTime: PropTypes.string,
      destinationAddress: PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.string,
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
        PropTypes.string,
      ]),
      isReturn: PropTypes.bool,
    }).isRequired,
    pickupAddresses: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.label,
      }),
    ).isRequired,
    destinationAddresses: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.label,
      }),
    ).isRequired,
    loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }).isRequired,
  getPickupAddresses: PropTypes.func.isRequired,
  getDestinationAddresses: PropTypes.func.isRequired,
  clearAddresses: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onIsRoundTripChange: PropTypes.func.isRequired,
  onPickupAddressClick: PropTypes.func.isRequired,
  onDestinationAddressClick: PropTypes.func.isRequired,
  isValidDate: PropTypes.func.isRequired,
  onSelectTripDate: PropTypes.func.isRequired,
  onSelectTripTime: PropTypes.func.isRequired,
  onSelectTripType: PropTypes.func.isRequired,
  showTripTypeSwitch: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  getValidTimes: PropTypes.func.isRequired,
  checkArriveByPlace: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
};

export default TripRequestFormLarge;
