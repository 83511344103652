/** @format */

import React from 'react';
import PropTypes from 'prop-types';

import { theme } from '@constants';
import { Button as SButton, ButtonIcon } from './styled';

export const Button = ({
  className,
  onClick,
  label,
  icon,
  iconProps,
  disabled,
  color,
  bordered,
  inverted,
  size,
  id,
  btnRef,
  ...restProps
}) => (
  <SButton
    ref={btnRef}
    id={id}
    className={className}
    onClick={onClick}
    disabled={disabled}
    color={color}
    bordered={bordered ? 1 : 0}
    inverted={inverted ? 1 : 0}
    size={size}
    {...restProps}
  >
    {icon && <ButtonIcon icon={icon} label={label} {...iconProps} />}
    {label && <span data-cy="add-trip" className="label">{label}</span>}
  </SButton>
);

Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string,
  icon: PropTypes.object,
  iconProps: PropTypes.object,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  bordered: PropTypes.bool,
  inverted: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  id: PropTypes.string,
  btnRef: PropTypes.object,
};

Button.defaultProps = {
  className: '',
  onClick: null,
  label: '',
  icon: null,
  iconProps: {},
  disabled: false,
  color: theme.blue,
  bordered: false,
  inverted: false,
  size: 'md',
  id: null,
  btnRef: null,
};

export default Button;
