/** @format */

import styled from 'styled-components';
import { styles, theme } from '@constants';
import { styles as stylesUtil } from '@utils';

export const SwitchWrapper = styled.div`
  width: 30%;
  position: relative;
  height: calc(
    100vh - ${props => props.headerHeight}px - ${props => props.fullMap ? '0px' : styles.TRIP_REQUEST_LG_HEIGHT}
  );
  z-index: 1;
  background: ${theme.WHITE};
  box-shadow: 0 0 4px 0 ${stylesUtil.hexToRgba(theme.BLACK, 0.16)};
  display: ${props => (props.sidebarHidden ? 'none' : 'block')};
  overflow-y: auto;
`;
