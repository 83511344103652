/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col, Dropdown } from '@components';
import { theme, generals } from '@constants';
import { WrapperRow, DropdownCol, LabelSmall } from './styled';

export const RiderTypeDropdown = ({
  onChangeRiderType,
  userRiderType,
}) => {
  const { t } = useTranslation();
  const options = generals.RIDER_TYPE.map(x => {
    return {
      value: x.toUpperCase(),
      label: t(`common.riderType.${x}`).toUpperCase(),
    };
  });

  const onDropdownSelect = option => {
    onChangeRiderType(option.value);
  };

  const renderDropdown = () => (
    <DropdownCol justify="flex-start" align="flex-start">
      <Row
        data-cy="rider-type-dropdown-row"
        id="options"
        align="center"
        justify="center"
      >
        <Dropdown
          data-cy="rider-type-dropdown"
          id="options-dropdown"
          placeholder={t('signup.fields.riderType.placeholder')}
          options={options}
          onChange={onDropdownSelect}
          value={userRiderType}
          color={theme.BASE_COLOR}
          inverted
          bordered
          rounded
          size="sm"
          height="50px"
        />
      </Row>
    </DropdownCol>
  );

  return (
    <Col align="flex-start" justify="flex-start" zIndex="1" spacingV={0}>
      <LabelSmall>{t('signup.labels.age-groups')}</LabelSmall>
      <WrapperRow justify="flex-start" align="flex-start">
        {renderDropdown()}
      </WrapperRow>
    </Col>
  );
};

RiderTypeDropdown.propTypes = {
  onChangeRiderType: PropTypes.func,
  userRiderType: PropTypes.string,
};

RiderTypeDropdown.defaultProps = {
  onChangeRiderType: null,
  userRiderType: 'ADULT',
};

export default RiderTypeDropdown;
