/** @format */

import React, { useCallback, useState } from 'react';
import { Portal } from 'react-portal';
import { MessageModal } from '@components';

const useMessageDialog = () => {

  const defaultModalProps = {
    heading: '',
    bodyText: '',
    closeButton: null,
    rightButton: null,
    closeDisabled: false,
  };
  const [isOpen, setIsOpen] = useState(false);
  const [modalProps, setProps] = useState(defaultModalProps);

  const openMessageDialog = (value, updatedProps) => {
    updatePropsAndOverloadOnPress(updatedProps);
    setIsOpen(value);
  };

  /**
   * We are keeping left button as default
   * So if onPress for left button is passed through props,
   * we will overload it with additional close modal function
   */
  const updatePropsAndOverloadOnPress = updatedProps => {
    if (updatedProps.closeButton?.onPress) {
      const prevOnPress = updatedProps.closeButton.onPress;
      updatedProps.closeButton.onPress = () => {
        setIsOpen(false);
        prevOnPress();
      };
    } else {
      updatedProps = {
        ...updatedProps,
        closeButton: {
          title: 'Ok',
          onPress: () => {
            setIsOpen(false);
          }
        }
      };
    }

    if (updatedProps?.rightButton?.onPress) {
      const prevOnPress = updatedProps.rightButton.onPress;
      updatedProps.rightButton.onPress = () => {
        setIsOpen(false);
        prevOnPress();
      };
    }
    
    setProps({ ...modalProps, ...updatedProps });
  };

  const close = () => {
    if (modalProps.closeDisabled) {
      return;
    }

    setIsOpen(false)
  }

  const MessageDialog = useCallback(
    () =>
      isOpen ? (
        <Portal id="message-dialog-portal-instance">
          <MessageModal
            heading={modalProps.heading}
            text={modalProps.bodyText}
            closeButton={modalProps.closeButton}
            rightButton={modalProps.rightButton}
            toggle={close}
          />
        </Portal>
      ) : null,

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isOpen,
      modalProps.bodyText,
      modalProps.closeButton,
      modalProps.rightButton,
    ],
  );

  return {
    MessageDialog,
    openMessageDialog,
  };
};

export default useMessageDialog;