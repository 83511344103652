/** @format */

import styled from 'styled-components';

import { Dropdown, Row } from '@components';
import { theme } from '@constants';

export const StyledDropdown = styled(Dropdown)`
  height: 34px;
  border-radius: 100px;
  position: absolute;
  .rdd-control {
    z-index: 17;
    border-radius: 100px;
  }

  .rdd-menu {
    z-index: 18;
    max-height: 140px;
  }
  margin-left: 10px;
`;

export const DropdownWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-right: 10px;
  height: 30px;
`;

export const TextSmall = styled.small`
  color: ${theme.BASE_COLOR};
  font-weight: bold;
  font-size: 16px;
  width: 140px;
`;

export const Wrapper = styled(Row)`
  margin: 8px 0;
`;
