/** @format */

import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { theme } from '@constants';
import { TextSmall, DropdownWrapper, StyledDropdown, Wrapper } from './styled';
import { extraSeatsOptions } from './config';

const SelectExtraSeats = ({ extraSeats, onChangeExtraSeats, label }) => {
  const { t } = useTranslation();

  const extraSeatsOptionsTranslated = extraSeatsOptions.map(x => {
    if (x.label === 'None') {
      return {
        ...x,
        label: t('common.none'),
      };
    }
    return x;
  });

  return (
    <Wrapper justify="flex-start" align="center">
      <TextSmall>{label}: </TextSmall>
      <DropdownWrapper>
        <StyledDropdown
          id={`${label.toLowerCase().split(' ').join('-')}-extra-seats`}
          value={extraSeats.toString()}
          name="extra-seats"
          onChange={onChangeExtraSeats}
          options={extraSeatsOptionsTranslated}
          color={theme.BASE_COLOR}
          inverted
          bordered
          rounded
          placeholder={label}
          size="sm"
        />
      </DropdownWrapper>
    </Wrapper>
  );
};

SelectExtraSeats.propTypes = {
  extraSeats: PropTypes.number.isRequired,
  onChangeExtraSeats: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default SelectExtraSeats;
