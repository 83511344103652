/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col } from '@components';
import { theme } from '@constants';
import { Popup, PopupText, PopupHeading } from './styled';

export const MessageModal = ({ heading, text, closeButton, rightButton, toggle }) => {
  return (
    <Popup id="message-modal-container" width="350" toggle={toggle}>
      <Col justify="flex-start" align="flex-start" spacingV={20} spacingH={20}>
        <Row justify="center" align="center">
          {heading && <PopupHeading id="popup-heading">{heading}</PopupHeading>}
        </Row>
        <Row justify="center" align="center">
          <PopupText>{text}</PopupText>
        </Row>
        <Row justify="center" align="center">
          <Col justify="center" align="center" spacingH={10}>
            <Button
              label={closeButton.title}
              size="md"
              id="close-button"
              data-cy="close-modal-btn"
              color={closeButton.color || theme.BASE_COLOR}
              onClick={closeButton.onPress}
            />
          </Col>

          {rightButton && (
            <Col justify="center" align="center" spacingH={10}>
              <Button
                label={rightButton.title}
                size="md"
                id="right-button"
                color={rightButton.color || theme.BASE_COLOR}
                onClick={rightButton.onPress}
              />
            </Col>
          )}
        </Row>
      </Col>
    </Popup>
  );
};

MessageModal.propTypes = {
  text: PropTypes.string.isRequired,
  heading: PropTypes.string,
  toggle: PropTypes.func.isRequired,
  closeButton: PropTypes.shape({
    title: PropTypes.string.isRequired,
    onPress: PropTypes.func.isRequired,
    color: PropTypes.string,
  }).isRequired,
  rightButton: PropTypes.shape({
    title: PropTypes.string.isRequired,
    onPress: PropTypes.func.isRequired,
    color: PropTypes.string,
  }),
};

MessageModal.defaultProps = {
  rightButton: null,
  heading: null,
};

export default MessageModal;
