/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Row, Col, Col as TripPathCol } from '..';
import {
  TripDetailsTitle,
  TripTimeTitle,
  TripTimeValue,
  NameRow,
  NameText,
  NameTextBold,
} from './styled';
import WorkforceShareTripPath from './workforceShareTripPath';

export const WorkforceTripPathDetails = ({
  title,
  tripDetails,
  pickupAddress,
  destinationAddress,
  showHeaderDate
}) => {
  const { type, selectedTripType } = tripDetails;
  const showDatetime = tripDetails.route;
  const tripDate = moment(tripDetails.leaveTime).format('MM/DD/YYYY');
  const tripTime = moment(tripDetails.leaveTime).format('hh:mm a');
  const { t } = useTranslation();

  const getPickupAddress = () => {
    if (!selectedTripType) return pickupAddress;

    return selectedTripType === 'depart' ? pickupAddress : destinationAddress;
  };

  const getDestinationAddress = () => {
    if (!selectedTripType) return destinationAddress;

    return selectedTripType === 'depart' ? destinationAddress : pickupAddress;
  };

  const renderTripPath = () => {
    const pickup = getPickupAddress();
    const destination = getDestinationAddress();
    if (type === 'share') {
      return (
        <WorkforceShareTripPath
          arriveDate={showDatetime ? tripDetails.arriveTime : null}
          leaveDate={showDatetime ? tripDetails.leaveTime : null}
          pickupName={pickup.name}
          destinationName={destination.name}
          pickupAddress={pickup.address}
          destinationAddress={destination.address}
          leaveBy
          arriveBy
        />
      );
    }
  };

  return (
    <Col justify="flex-start" align="flex-start" spacingH={10}>
      <Row flex="initial" justify="space-between" align="center" spacingV={10}>
        {title && <TripDetailsTitle>{title}</TripDetailsTitle>}
        {showHeaderDate && (
          <>
            <Col
              flex="initial"
              justify="flex-start"
              align="flex-start"
              fullWidth={false}
            />
            <Col
              flex="initial"
              justify="flex-start"
              align="flex-start"
              fullWidth={false}
            >
              <TripTimeTitle>{tripDate}</TripTimeTitle>
              <TripTimeValue>
                {tripTime} ({t(`fixed-route.large-screen.fields.switch.labels.${tripDetails?.requestType?.toLowerCase().split(' ').join('-')}`)})
              </TripTimeValue>
            </Col>
          </>
        )}
      </Row>
      <TripPathCol justify="flex-start" align="flex-start">
        {renderTripPath()}
      </TripPathCol>
      {tripDetails.shift && (
        <NameRow justify="flex-start" align="center">
          <NameText>
            <NameTextBold>{t('trip-overview.labels.shift')}: </NameTextBold>
            {tripDetails.shift}
          </NameText>
        </NameRow>
      )}
    </Col>
  );
};

WorkforceTripPathDetails.propTypes = {
  title: PropTypes.string,
  tripDetails: PropTypes.object.isRequired,
  pickupAddress: PropTypes.string.isRequired,
  destinationAddress: PropTypes.string.isRequired,
  showHeaderDate: PropTypes.bool
};

WorkforceTripPathDetails.defaultProps = {
  title: null,
  showHeaderDate: true
};

export default WorkforceTripPathDetails;