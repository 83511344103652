/** @format */

// 3rd party Imports
import styled from 'styled-components';

// Internal Imports
import { Button } from '@components';
import { theme, device } from '@constants';
import { IconButton } from '..';

export const TermsConditionsForm = styled.div`
  position: relative;
  border-radius: inherit;
  background: ${theme.WHITE};
  padding-left: 30px;
  padding-right: 30px;
`;

export const ActionButton = styled(Button)`
  width: 128px;
  height: 53px;
  font-size: 13px;
  border-radius: 5px;
`;

export const CloseIcon = styled(IconButton)`
  margin: 16px 0px;
`;

export const ContentContainer = styled.div`
  width: ${props => props.width};
  margin: auto;
  text-align: center;
  padding-top: 20px;
  @media ${device.mediaQuery.SMALLER_THAN_448} {
    width: 96%;
    margin: auto;
  }
`;

export const ModalContentWrapper = styled.div`
  overflow: auto;
  background-color: ${theme.WHITE};
  padding-bottom: 20px;
  max-height: 75vh;
  border-radius: inherit;
  @media ${device.mediaQuery.SMALLER_THAN_448} {
    width: 96%;
    max-height: 75vh;
    margin: auto;
  }
`;

export const TermsAndConditionsLink = styled.a`
  color: ${theme.BASE_COLOR};
  font-weight: bold;
`;

export const TermsAndConditionsText = styled.span`
  margin-top: 8px;
  font-size: 22px;

  @media ${device.mediaQuery.SMALL_SCREEN} {
    font-size: 18px;
  }
`;
