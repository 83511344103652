/** @format */

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Portal } from 'react-portal';
import { FullScreen, ResetPasswordForm, Modal } from '@components';
import { useQuery } from '@hooks';
import {
  resetPassword,
  setResetPasswordNewPassword,
  resetAuthState,
  getUserOrgRestrictions,
} from '@slices/auth';

const ResetPassword = () => {
  const {
    trigger,
    register,
    errors,
    formState: { isValid },
  } = useForm({
    mode: 'onBlur',
  });

  const [passwordRestriction, setPasswordRestriction] = useState(false);
  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();

  const state = useSelector(store => store.auth.resetPassword);
  const { largeScreen, user } = useSelector(store => store.app);

  const token = query.get('token');

  useEffect(() => {
    if (!token) {
      history.push('/');
    }

    return () => {
      dispatch(resetAuthState());
    };
  }, [dispatch, token, history]);

  useEffect(() => {
    if (token) {
      fetchRestrictions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchRestrictions = async() => {
    const restrictions = await dispatch(getUserOrgRestrictions(token));
    if (restrictions) {
      const { passwordRestrictionsEnable } = restrictions;
      setPasswordRestriction(passwordRestrictionsEnable);
    }
  }

  const onCloseModal = () => {
    history.push('/');
    dispatch(resetAuthState());
  };

  const onCloseFullScreen = () => {
    history.push('/');
  };

  const onSubmit = () => {
    dispatch(resetPassword(token, history));
  };

  const onChange = async e => {
    const { name, value } = e.target;
    dispatch(setResetPasswordNewPassword(value));

    await trigger(name);
  };

  const resetPasswordForm = (
    <ResetPasswordForm
      errors={errors}
      isValid={isValid}
      validate={register}
      largeScreen={largeScreen}
      onCloseModal={onCloseModal}
      onCloseFullScreen={onCloseFullScreen}
      onChange={onChange}
      onSubmit={onSubmit}
      state={state}
      passwordRestriction={passwordRestriction}
    />
  );

  return largeScreen ? (
    !user && (
      <Portal>
        <Modal width="738" open toggle={onCloseModal}>
          {resetPasswordForm}
        </Modal>
      </Portal>
    )
  ) : (
    <FullScreen id="reset-password-full-screen" headerHeight={0}>
      {resetPasswordForm}
    </FullScreen>
  );
};

export default ResetPassword;
