/** @format */

import { createSlice } from '@reduxjs/toolkit';

import { setTripPath, setUser, setOrganizationsFromUser } from '@slices/app';
import { api, generals as generalConstants } from '@constants';
import { api as apiClient } from '@utils';

export const defaultState = {
  trip: null,
  loading: null,
};

const TripDetailSlice = createSlice({
  name: 'tripDetail',
  initialState: defaultState,
  reducers: {
    setLoading(state, action) {
      const { payload: loading } = action;

      return {
        ...state,
        loading,
      };
    },
    setTripDetail(state, action) {
      const { payload: trip } = action;

      return {
        ...state,
        trip,
      };
    },
    resetTripDetail() {
      return {
        ...defaultState,
      };
    },
  },
});

export const { setLoading, setTripDetail, resetTripDetail } = TripDetailSlice.actions;

export const getTripPathFromDriverLocation = (driverLocation, trip) => {
  return async dispatch => {
    const [, destination] = trip.places;
    const url = `${api.endpoints.GET_PATH}?lat1=${driverLocation.lat}&lng1=${driverLocation.lng}&lat2=${destination.lat}&lng2=${destination.lng}&address=${destination.address}`;
    const { data } = await apiClient.get(url);

    dispatch(
      setTripPath({
        share: data.path,
        transit: [],
      }),
    );
  };
};

export const getTripById = tripId => {
  return async dispatch => {
    dispatch(setLoading(generalConstants.MY_TRIP_LOADING));

    try {
      const response = await apiClient.get(
        api.endpoints.GET_TRIP_BY_ID.replace(':tripId', tripId),
      );

      dispatch(setTripDetail(response.data));
      dispatch(setLoading(null));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('GET_TRIP_BY_ID_ERROR', error);
      dispatch(setLoading(null));
    }
  };
};

export const cancelTripById = (tripId, openModalCallback) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(generalConstants.CANCEL_TRIP_LOADING));
    const state = getState();
    try {
      const { data: cancelledTr } = await apiClient.post(
        api.endpoints.CANCEL_TRIP_REQUEST.replace(':id', tripId),
      );

      if (cancelledTr && cancelledTr.detail) {
        const { points, payment } = cancelledTr.detail;
        if (points?.pointPerRide) {
          const user = {
            ...state.app.user,
            points:
              parseInt(state.app.user?.points || 0, 10) +
              parseInt(points.pointPerRide, 10),
          };
          dispatch(
            setUser(user),
          );
          dispatch(setOrganizationsFromUser(user));
          localStorage.setItem('user', JSON.stringify(user));
        } else if (payment?.pricing?.credits) {
          const user = {
            ...state.app.user,
            balance: (
              parseFloat(state.app.user?.balance || 0) +
              parseFloat(payment.pricing.credits)
            ).toFixed(2),
          };
          dispatch(
            setUser(user),
          );
          dispatch(setOrganizationsFromUser(user));
          localStorage.setItem('user', JSON.stringify(user));
        }
      }

      dispatch(setLoading(null));
      openModalCallback();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('CANCEL_TRIP_ERROR', error);
      dispatch(setLoading(null));
    }
  };
};

export const tripDetailReducer = TripDetailSlice.reducer;
