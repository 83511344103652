import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { resetAuthState } from '@slices/auth';
import { resetMyTrips } from '@slices/myTrips';
import { resetAppState } from '@slices/app';
import { resetTripDetail } from '@slices/tripDetail';
import { resetTripRequestState } from '@slices/tripRequest';

const useStateResetOnPasswordlessAuthUrlChange = (callback) => {
  const [customPath, setCustomPath] = useState('/');
  const urlChangesCountRef = useRef(0);
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    passwordlessAuthenticationType,
  } = useSelector(state => state.app);


  useEffect(() => {
    if (urlChangesCountRef.current === 1) {
      // reset the state and remove the token and passwordless type
      if (passwordlessAuthenticationType) {
        sessionStorage.removeItem('passwordlessAuthToken');
        dispatch(resetAppState());
        dispatch(resetAuthState());
        dispatch(resetMyTrips());
        dispatch(resetTripDetail());
        dispatch(resetTripRequestState());
      }
    } else {
      urlChangesCountRef.current += 1;
    }
    
    if (typeof callback === "function") {
      callback();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname,customPath]);

  // optional 
  return {
    changePath: (path) => setCustomPath(path),
  }
}

export default useStateResetOnPasswordlessAuthUrlChange;