/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faExclamationCircle,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { theme, generals } from '@constants';
import { Col } from '..';
import {
  ClickHandler,
  PaymentMethodRow,
  ChargedPerTripValue,
  ChargedPerTripTitle,
  PaymentMethodTitle,
  PaymentMethodTitleValue,
  Tooltip,
  LoaderIcon,
  LoaderWrapperRow,
} from './styled';

const getTotalCost = (cost) => {
  const { total, credits } = cost || {};
  return parseFloat((total || 0) + (credits || 0)).toFixed(2);
};

const getTripPricing = (cost, t) => {
  const { fare, tax, fees, discount, credits, total } = cost || {};
  return `${t(
    'trip-overview.payment-methods.credit-card.pricing.total',
  )}: $${getTotalCost(cost)} <br /> ${t(
    'trip-overview.payment-methods.credit-card.pricing.fare',
  )}: $${fare.toFixed(2)} <br /> ${t(
    'trip-overview.payment-methods.credit-card.pricing.tax',
  )}: $${tax.toFixed(2)} <br /> ${t(
    'trip-overview.payment-methods.credit-card.pricing.service-fees',
  )}: $${fees.toFixed(2)} <br /> ${t(
    'trip-overview.payment-methods.credit-card.pricing.discount',
  )}: $${discount.toFixed(2)} <br /> ${t(
    'trip-overview.payment-methods.credit-card.pricing.credits',
  )}: $${credits.toFixed(2)} 
  <br /> ${t(
    'trip-overview.payment-methods.credit-card.pricing.charged',
  )}: $${total.toFixed(2)}`;
};

const SelectedPaymentMethod = ({
  cost,
  card,
  clickDisabled,
  isPriceLoading,
  selectedSelfPaymentOption,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const cardBrand = card ? card.cardBrand ? card.cardBrand.toUpperCase() : card.brand.toUpperCase() : '';
  const cardLast4 = card ? card.last4 : '';

  const handleClick = () => {
    history.push('/trip-overview/payment', {
      isPreviousScreenTripRequest: true,
    });
  };

  return (
    <ClickHandler
      onClick={!clickDisabled && cost && cost.total > 0 ? handleClick : null}
    >
      <PaymentMethodRow
        id="selected-payment-method"
        flex="initial"
        justify="flex-start"
        align="center"
        spacingV={10}
      >
        <Col justify="flex-start" align="flex-start" fullWidth={false}>
          <ChargedPerTripTitle>{t('trip-overview.payment-methods.credit-card.total-amount')}</ChargedPerTripTitle>
          {isPriceLoading ? (
            <LoaderWrapperRow
              id="trip-amount-loader"
              justify="center"
              align="center"
              spacingV={30}
            >
              <LoaderIcon icon={faSpinner} spin />
            </LoaderWrapperRow>
          ) : (
            <ChargedPerTripValue>
              ${`${cost.total.toFixed(2)} `}
              <Tooltip
                id="cost-tooltip"
                multiline
                backgroundColor={theme.WEIRD_GREEN}
                textColor={theme.WHITE}
              />
              <span
                data-tip={getTripPricing(cost, t)}
                data-event="click"
                data-event-off="mouseout"
                data-for="cost-tooltip"
                data-place="right"
                data-effect="solid"
              >
                <FontAwesomeIcon icon={faExclamationCircle} />
              </span>
            </ChargedPerTripValue>
          )}
        </Col>

        {cost && cost.total > 0 && selectedSelfPaymentOption && (
          <Col justify="flex-start" align="flex-start" fullWidth={false}>
            {(selectedSelfPaymentOption === generals.SELF_PAYMENT_OPTIONS.CASH ||
              card) &&
              <PaymentMethodTitle>{t('trip-detail.labels.payment.payment')}</PaymentMethodTitle>
            }

            <PaymentMethodTitleValue id="payment-method-title">
              {selectedSelfPaymentOption === generals.SELF_PAYMENT_OPTIONS.CASH ?
                t('trip-detail.labels.self-payment-methods.pay-by-cash') :
                (card ?
                  `${cardBrand} ${cardLast4}` :
                  t('trip-detail.labels.payment.select-payment-method')
                )
              }
            </PaymentMethodTitleValue>
          </Col>
        )}
        {!clickDisabled && cost && cost.total > 0 && selectedSelfPaymentOption && (
          <Col
            flex="initial"
            justify="flex-start"
            align="flex-start"
            fullWidth={false}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </Col>
        )}
      </PaymentMethodRow>
    </ClickHandler>
  );
};

SelectedPaymentMethod.propTypes = {
  cost: PropTypes.shape({
    fare: PropTypes.number,
    tax: PropTypes.number,
    fees: PropTypes.number,
    discount: PropTypes.number,
    credits: PropTypes.number,
    total: PropTypes.number,
  }).isRequired,
  card: PropTypes.object.isRequired,
  clickDisabled: PropTypes.bool,
  isPriceLoading: PropTypes.bool,
  selectedSelfPaymentOption: PropTypes.string.isRequired,
};

SelectedPaymentMethod.defaultProps = {
  clickDisabled: false,
  isPriceLoading: false,
};

export default SelectedPaymentMethod;
