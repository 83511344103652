/* eslint-disable react/self-closing-comp */
/**
 * /* eslint-disable react/self-closing-comp
 *
 * @format
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from '@utils';

export default function SquareUpCard(props) {
  const { onCardInitialization, selectedOrganization } = props;
  const { squareUpAppId, squareUpLocationId } = selectedOrganization || {};

  useEffect(() => {
    if (squareUpAppId && squareUpLocationId) {
      injectSquareCard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const injectSquareCard = async () => {
    if (!window.Square) {
      toast.show('error', 'Square.js failed to load properly');
    }

    async function initializeCard(payments) {
      const container = document.getElementById('square-card-container');
      if (container) {
        const card = await payments.card();
        await card.attach('#square-card-container');
        onCardInitialization(card);
      }
    }

    try {
      const payments = await window.Square.payments(
        squareUpAppId,
        squareUpLocationId,
      );
      initializeCard(payments);
    } catch (e) {
      toast.show('error', 'Square.js failed: Application/Location Id is invalid!');
    }
  };

  return (
    <>
      <form id="payment-form">
        <div id="square-card-container"></div>
      </form>
      <div id="payment-status-container"></div>
    </>
  );
}

SquareUpCard.propTypes = {
  selectedOrganization: PropTypes.object.isRequired,
  onCardInitialization: PropTypes.func.isRequired,
};
