/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import 'react-dropdown/style.css';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import arrowDownIcon from '@assets/icons/arrow-down.svg';
import { IconButton } from '@components';
import { theme } from '@constants';
import { Dropdown as SDropdown } from './styled';

const Dropdown = ({
  id,
  className,
  options,
  onChange,
  value,
  placeholder,
  disabled,
  color,
  height = '44px',
  inverted,
  bordered,
  rounded,
  borderRadius,
  size,
  inHeader,
  onClick,
  isOpen,
  setIsLanguageDropdownOpen,
  isSmallScreen,
  ...restProps
}) => {
  const { t } = useTranslation();
  const arrowDown = (
    <IconButton icon={arrowDownIcon} onClick={() => {}} width={12} />
  );
  const arrowUp = (
    <IconButton icon={arrowDownIcon} onClick={() => {}} width={12} />
  );

  return (
    <SDropdown
      id={id}
      className={className}
      controlClassName="rdd-control"
      placeholderClassName="rdd-placeholder"
      menuClassName="rdd-menu"
      options={options}
      onChange={onChange}
      value={value}
      height={height}
      placeholder={placeholder}
      disabled={disabled}
      arrowClosed={arrowDown}
      arrowOpen={arrowUp}
      color={color}
      borderRadius={borderRadius}
      inverted={inverted ? 1 : 0}
      bordered={bordered ? 1 : 0}
      rounded={rounded ? 1 : 0}
      size={size}
      noOptionsPlaceholder={t('trip-date-time-picker.no-options')}
      key={i18next.language}
      inHeader={inHeader}
      onClick={onClick}
      isOpen={isOpen}
      setIsLanguageDropdownOpen={setIsLanguageDropdownOpen}
      isSmallScreen={isSmallScreen}
      {...restProps}
    />
  );
};

Dropdown.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  inverted: PropTypes.bool,
  bordered: PropTypes.bool,
  rounded: PropTypes.bool,
  height: PropTypes.string,
  borderRadius: PropTypes.number,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  inHeader: PropTypes.bool,
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsLanguageDropdownOpen: PropTypes.func,
  isSmallScreen: PropTypes.bool,
};

Dropdown.defaultProps = {
  id: '',
  className: '',
  value: null,
  disabled: false,
  color: theme.BASE_COLOR,
  inverted: false,
  bordered: false,
  rounded: false,
  height: '44px',
  size: 'md',
  placeholder: '',
  borderRadius: null,
  inHeader: false,
  onClick: () => {},
  isOpen: false,
  setIsLanguageDropdownOpen: () => {},
  isSmallScreen: false
};

export default Dropdown;
