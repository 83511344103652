/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { HomeWrapper } from './styled';

const HomeWorkforceLarge = ({ children }) => {
  return (
    <HomeWrapper>
      {children}
    </HomeWrapper>
  );
};

HomeWorkforceLarge.propTypes = {
  children: PropTypes.any,
};

HomeWorkforceLarge.defaultProps = {
  children: null,
};

export default HomeWorkforceLarge;
