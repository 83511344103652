/** @format */

import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import debounce from 'lodash.debounce';
import {
  fetchAddresses,
  getInvites,
  setAccountSettingsAndDynamicFields,
  setAccountSettingsAddresses,
  setAccountSettingsFormState,
  update,
} from '@slices/auth';
import {
  FullScreen,
  Modal,
  SavedAddresses as CSavedAddresses,
} from '@components';
import { Portal } from 'react-portal';

const SavedAddresses = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [addressQuery, setAddressQuery] = useState('');
  const [addressType, setAddressType] = useState('home');

  const state = useSelector(store => store.auth.accountSettings);
  const { user, largeScreen, selectedOrganization } = useSelector(store => store.app);

  const source = location.state && location.state.source;

  const debounceFetchAddresses = useCallback(
    debounce((query, action) => {
      dispatch(fetchAddresses(query, action));
    }, 500),
    [],
  );

  const getAddresses = () => {
    if (!addressQuery || addressQuery.length < 6) return;
    debounceFetchAddresses(addressQuery, setAccountSettingsAddresses);
  };

  useEffect(() => {
    dispatch(getInvites());
    dispatch(setAccountSettingsAndDynamicFields(user));
  }, [dispatch, user]);

  useEffect(getAddresses, [dispatch, addressQuery]);

  const onAddressQueryChange = e => {
    const { value } = e.target;

    setAddressQuery(value);
  };

  const clearAddresses = () => {
    dispatch(setAccountSettingsAddresses([]));
  };

  const onAddressClick = selected => {
    const address = state.addresses[selected];
    setAddressQuery(address.label);
    clearAddresses();

    const addressWithType = { ...address, type: addressType };
    const savedAddress = [...state.formState.savedAddresses];
    savedAddress.push(addressWithType);
    dispatch(
      setAccountSettingsFormState({
        name: 'savedAddresses',
        value: savedAddress,
      }),
    );
    setAddressQuery('');
  };

  const onDeleteAddress = index => {
    const savedAddress = [...state.formState.savedAddresses];
    savedAddress.splice(index, 1);

    dispatch(
      setAccountSettingsFormState({
        name: 'savedAddresses',
        value: savedAddress,
      }),
    );
  };

  const onChangeAddressType = ({ value }, address = null) => {
    if (address === null) {
      setAddressType(value);
      return;
    }
    const addresses = state.formState.savedAddresses.map(savedAddress => {
      return { ...savedAddress };
    });
    addresses[address].type = value;
    dispatch(
      setAccountSettingsFormState({
        name: 'savedAddresses',
        value: addresses,
      }),
    );
  };

  const goBack = () => {
    const openInviteFriendsAfterFirstLoginOrSignup = JSON.parse(localStorage.getItem('openInviteFriendsAfterFirstLoginOrSignup'));

    if (source === 'signup' && !openInviteFriendsAfterFirstLoginOrSignup) {
      history.push('/');
    } else if (source === 'signup' && openInviteFriendsAfterFirstLoginOrSignup && selectedOrganization !== null) {
      localStorage.removeItem('openInviteFriendsAfterFirstLoginOrSignup');
      history.push('/invite-friends');
    } else {
      history.push('/account-settings');
    }
  };

  const onSubmit = async () => {
    await dispatch(update({ addressesUpdated: true, source }));
    goBack();
  };

  const savedAddresses = (
    <CSavedAddresses
      onAddressQueryChange={onAddressQueryChange}
      onAddressClick={onAddressClick}
      onChangeAddressType={onChangeAddressType}
      onDeleteAddress={onDeleteAddress}
      addresses={state.addresses}
      addressQuery={addressQuery}
      getAddresses={getAddresses}
      clearAddresses={clearAddresses}
      savedAddresses={state.formState.savedAddresses}
      onSubmit={onSubmit}
      loading={state.loading}
      goBack={goBack}
      source={source}
    />
  );

  const renderSavedAddressesModal = () => {
    return source === 'signup' ? (
      <Portal>
        <Modal width="600" open toggle={goBack}>
          {savedAddresses}
        </Modal>
      </Portal>
    ) : (
      savedAddresses
    );
  };

  return largeScreen ? (
    renderSavedAddressesModal()
  ) : (
    <FullScreen id="saved-addresses-full-screen" headerHeight={0}>
      {savedAddresses}
    </FullScreen>
  );
};

export default SavedAddresses;
