/** @format */

import styled from 'styled-components';
import {
  Row,
  Col,
  Input,
  SuggestionInput,
  Button,
  Dropdown,
} from '@components';
import { styles, theme } from '@constants';
import ReactTooltip from 'react-tooltip';

export const Tooltip = styled(ReactTooltip)`
  &.show {
    opacity: 1 !important;
  }
`;

export const DropdownStyled = styled(Dropdown)`
  z-index: 1;
  width: 50%;
  height: 38px;
  border-radius: 100px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  .rdd-control {
    border-radius: inherit;
    padding: 10px 20px;
  }
`;

export const Form = styled(Row)`
  padding: 30px;
  background-color: ${theme.BASE_COLOR};
  position: relative;
  max-height: ${styles.TRIP_REQUEST_LG_HEIGHT};
  z-index: 20;
`;

export const ViewDestinationsCol = styled(Col)`
  padding-left: 45px;
`;

export const CheckboxWrapper = styled.div`
  margin-right: 20px;
`;

export const Title = styled.h4`
  font-size: 22px;
  font-weight: 600;
  color: ${theme.WHITE};
  margin-bottom: 20px;
`;

export const ViewDestinationsText = styled.span`
  color: ${theme.WHITE};
  margin-bottom: 20px;
  line-height: 26px;
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const FormRow = styled(Row)`
  height: 90px;
`;

export const FormCol = styled(Col)`
  height: 100%;
`;

export const AddressInput = styled(SuggestionInput)`
  min-width: 250px;
  width: 100%;
`;

export const AddressInputRow = styled(Row)`
  padding-right: 15px;
`;

export const DateInput = styled(Input)`
  padding-right: 15px;
  min-width: 140px;
`;

export const TimeInput = styled(Input)`
  padding-right: 15px;
  min-width: 125px;
`;

export const FindRoutesBtn = styled(Button)`
  width: 180px;
`;

export const ValidTimesDropdownWrapper = styled.div`
  margin-right: 15px;

  .rdd-placeholder {
    font-size: 16px;
    font-family: Poppins;
  }

  .rdd-menu {
    top: 40px;
    font-family: Poppins;

    > div {
      font-size: 16px;
      font-family: Poppins;
    }
  }
`;

export const ValidTimesDropdown = styled(Dropdown)`
  height: 38px;
  min-width: 150px;
`;
