/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import MagicSliderDots from 'react-magic-slider-dots';
import 'slick-carousel/slick/slick-theme.css';
import { ViewWrapper, Wrapper } from './styled';

import 'react-magic-slider-dots/dist/magic-dots.css';

const SliderDots = ({ sliderRef, views, sliderProps, hide, id }) => {
  const { numberOfDotsToShow } = sliderProps;

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: dots => {
      return (
        <MagicSliderDots
          dots={dots}
          numDotsToShow={numberOfDotsToShow}
          dotWidth={30}
        />
      );
    },
    ...sliderProps,
  };

  return (
    <Wrapper
      data-cy="slider"
      id={id}
      dotsEnabled={settings.dots ? 1 : 0}
      hide={hide ? 1 : 0}
    >
      <Slider ref={sliderRef} {...settings}>
        {views.map((view, key) => (
          <ViewWrapper id="slider-view-wrapper" key={key}>{view}</ViewWrapper>
        ))}
      </Slider>
    </Wrapper>
  );
};

SliderDots.propTypes = {
  id: PropTypes.string,
  sliderRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  views: PropTypes.arrayOf(PropTypes.element).isRequired,
  sliderProps: PropTypes.shape({
    numberOfDotsToShow: PropTypes.number,
    arrows: PropTypes.bool,
  }),
  hide: PropTypes.bool,
};

SliderDots.defaultProps = {
  id: '',
  sliderRef: null,
  sliderProps: {
    numberOfDotsToShow: 2,
    arrows: false,
  },
  hide: false,
};

export default SliderDots;
