/** @format */

import { GoogleProvider } from 'leaflet-geosearch';
import GreatCircle from 'great-circle';

const apiKey = process.env.REACT_APP_GOOGLE_PLACE_API_KEY;

const provider = () => {
  const viewbox = localStorage.getItem('organizationLocationViewBox');
  const viewboxArray = viewbox && viewbox.split(',');

  const params = {
    key: apiKey,
    region: 'us,ca',
  };

  if (viewboxArray) {
    params.bounds = `${viewboxArray[0]},${viewboxArray[1]}|${viewboxArray[2]},${viewboxArray[3]}`;
  }

  const googleProvider = new GoogleProvider({
    params,
  });

  return googleProvider;
};

const isInBounds = (point, center, radiusInMiles) => {
  const distance = GreatCircle.distance(center.lat, center.lng, point.lat, point.lng, 'MI');

  if (distance > radiusInMiles) {
    return false;
  }

  return true;
};

const search = async query => {
  const results = query ? await provider().search({ query }) : [];

  return results.map(o => {
    return {
      label: o.raw.formatted_address,
      lat: o.raw.geometry.location.lat,
      lng: o.raw.geometry.location.lng,
    };
  });
};

export default {
  search,
  provider,
  isInBounds,
};
