/** @format */

import React from 'react';
import PropTypes from 'prop-types';

import img from '@assets/images/construction.png';
import { Row, IconButton } from '..';
import { Title, SubTitle, Wrapper, Image, OuterWrapper } from './styled';

const WorkInProgress = ({ componentName, onClose }) => {
  return (
    <OuterWrapper>
      <Row
        align="flex-end"
        justify="flex-end"
        flex={0}
        spacingH={12}
        spacingV={12}
      >
        <IconButton id="close-wip" width={25} onClick={onClose} />
      </Row>
      <Wrapper>
        <Row align="center" justify="center">
          <Image src={img} />
        </Row>
        <Row justify="center" align="center">
          <Title>{componentName} are coming soon!</Title>
        </Row>
        <Row justify="center" align="center" spacingV={20} spacingH={20}>
          <SubTitle>
            {componentName} will be available with the next version.
          </SubTitle>
        </Row>
      </Wrapper>
    </OuterWrapper>
  );
};

WorkInProgress.propTypes = {
  componentName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default WorkInProgress;
