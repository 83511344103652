/** @format */

import styled from 'styled-components';
import { theme, device } from '@constants';

export const VideoWraper = styled.div`
  position: absolute;
  z-index:9999;
  right:1%;
  top:7%;
  height: 0;
  padding: 4px;
  border: 0;

  iframe {
    border: 0;

    @media ${device.mediaQuery.MOBILE_SCREEN} {
      width: 250px;
    }
  }

  svg {
    color: ${theme.WHITE};
  }
  .icon {
    display: none;
    position: relative;
    top: -130px;
    z-index: 99999;
    left: 35px;
    color: ${theme.WHITE};
    cursor: pointer;
  }

  .cross {
    left: 292px;
    @media ${device.mediaQuery.MOBILE_SCREEN} {
      left: 235px;
    }
  }
  &:hover {
    .icon {
      display: initial;
    }
  }
`;

export const DragContainer = styled.div`
  height: '95vh';
  position: 'absolute';
  visibility: 'hidden';
`;

