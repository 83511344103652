/** @format */

import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { Row, Col, Button } from '@components';
import { device, theme } from '@constants';

export const TripOverviewWorkforceCol = styled(Col)`
  @media ${device.mediaQuery.LARGE_SCREEN} {
    min-height: 100%;
    overflow-y: auto;
  }

  .slick-prev {
    left: 30%;
    top: 95%;
    z-index: 12;
  }

  .slick-next {
    right: 30%;
    top: 95%;
  }

  .kppgUP {
    max-height: 200vh;
  }
`;

export const Title = styled.h4`
  color: ${theme.BASE_COLOR};
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0;
  padding-top: 11px;
`;

export const EditBtn = styled(Button)`
  width: 7%;
  background: transparent;
  color: ${theme.BASE_COLOR};
  font-size: 18px;
  margin-right: 15px;
`;

export const TripDetailsRow = styled(Row)`
  border-top: 1px solid ${theme.BASE_COLOR};
  display: block;
`;

export const SubmitBtn = styled(Button)`
  margin-top: 20px;
`;

export const FrequencyPickerRow = styled.div`
  width: 100%;
  padding-top: 12px;
  border-top: 1px solid ${theme.BASE_COLOR};
`;

export const MinimizableCol = styled(Col)`
  max-height: ${props => (props.minimized ? '0px' : '100%')};
  transition: max-height 200ms linear 0s;
`;

export const Tooltip = styled(ReactTooltip)`
  &.show {
    opacity: 1 !important;
    width: 200px;
  }

  &.type-custom.place-top {
    margin-top: 10px;
  }
`;

export const TooltipWrapper = styled.div`
  padding: 0;
  margin: 0;
  width: ${props => (!props.heading && '100%' )};
`;