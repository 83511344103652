/** @format */

// eslint-disable-next-line
import React from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { generals } from '@constants';
import { placeHelpers, tripHelpers } from '@utils';

const useValidTimes = () => {
  const { t } = useTranslation();
  const search = useSelector(store => store.tripRequest.search);
  const { selectedOrganization, organizations } = useSelector(
    state => state.app,
  );
  const { bookingDelay } =
    ((organizations || [])[selectedOrganization] || {}).detail || {};

  let tripTypeSwitchEnabled = tripHelpers.isOfferTypeSwitchEnabled(
    organizations,
    selectedOrganization,
  );

  const getValidTimes = (type, date, offerType) => {
    const key = generals.DATE_TO_ADDRESS_MAPPING[type];
    const { pickupAddress, destinationAddress } = search.query;
    const day = date
      ? moment(date, 'MM/DD/YYYY').locale('en').format('ddd').toLowerCase()
      : null;

    const getValidTime = time => {
      return {
        label: time,
        value: time,
      };
    };

    let { departType, returnType } = search.query;
    departType = tripTypeSwitchEnabled ? offerType || departType : null;
    returnType = tripTypeSwitchEnabled ? offerType || returnType : null;

    const getAvailableTimes = allTimes => {
      const validTimes = [];
      allTimes &&
        allTimes.forEach(time => {
          const currentUserTimeZoneUTC = moment
            .tz(moment(), pickupAddress?.timezone)
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss');

          const dateTime = `${moment(date).format('MM/DD/YYYY')} ${time}`;
          const departTime = moment
            .tz(dateTime, 'MM/DD/YYYY hh:mm A', pickupAddress?.timezone)
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss');
          moment(departTime).isAfter(currentUserTimeZoneUTC) &&
            (!bookingDelay ||
              moment(departTime).isAfter(
                moment(currentUserTimeZoneUTC).add(bookingDelay, 'seconds'),
              )) &&
            validTimes.push(time);
        });
      return validTimes;
    };

    const isPickupAirportOrTerminal = returnType
      ? false
      : placeHelpers.isAirportOrTerminal(pickupAddress);
    const isDestinationAirportOrTerminal = departType
      ? false
      : placeHelpers.isAirportOrTerminal(destinationAddress);

    if (
      key === 'pickup' &&
      destinationAddress &&
      destinationAddress.availableTimes &&
      (isDestinationAirportOrTerminal || departType === 'Arrive By')
    ) {
      return {
        placeholder: t('trip-date-time-picker.arrive-at'),
        values: day
          ? getAvailableTimes(
              destinationAddress.availableTimes.arriveBy[day],
            ).map(x => getValidTime(x))
          : [],
      };
    }

    if (
      key === 'destination' &&
      pickupAddress &&
      pickupAddress.availableTimes &&
      (isPickupAirportOrTerminal || returnType === 'Arrive By')
    ) {
      return {
        placeholder: t('trip-date-time-picker.arrive-at'),
        values: day
          ? getAvailableTimes(pickupAddress.availableTimes.arriveBy[day]).map(
              x => getValidTime(x),
            )
          : [],
      };
    }

    const isDepart = generals.DATE_TO_ADDRESS_MAPPING.depart === key;
    const isDepartReadyBy = departType === 'Ready By' || departType === null;
    const isReturnReadyBy = returnType === 'Ready By' || returnType === null;
    const isPickupOrgLoc = pickupAddress && pickupAddress.organizationId;
    const isDestOrgLoc =
      destinationAddress && destinationAddress.organizationId;

    const getValidTimesFuncDir = {
      departReadyBy: () => {
        return getAvailableTimes(pickupAddress.availableTimes.readyBy[day]).map(
          x => getValidTime(x),
        );
      },
      departArriveBy: () => {
        return getAvailableTimes(
          destinationAddress.availableTimes.arriveBy[day],
        ).map(x => getValidTime(x));
      },
      returnReadyBy: () => {
        return getAvailableTimes(
          destinationAddress.availableTimes.readyBy[day],
        ).map(x => getValidTime(x));
      },
      returnArriveBy: () => {
        return getAvailableTimes(
          pickupAddress.availableTimes.arriveBy[day],
        ).map(x => getValidTime(x));
      },
    };

    const getValidTimes = key => {
      return day ? getValidTimesFuncDir[key]() : [];
    };

    const validTimes = {
      placeholder: t('trip-date-time-picker.pickup-at'),
      values: null,
    };

    if (isDepart) {
      if (isDepartReadyBy && isPickupOrgLoc) {
        validTimes.values = getValidTimes('departReadyBy');
      }
      if (!isDepartReadyBy && isDestOrgLoc) {
        validTimes.values = getValidTimes('departArriveBy');
      }
    } else {
      if (isReturnReadyBy && isDestOrgLoc) {
        validTimes.values = getValidTimes('returnReadyBy');
      }
      if (!isReturnReadyBy && isPickupOrgLoc) {
        validTimes.values = getValidTimes('returnArriveBy');
      }
    }

    return validTimes?.values ? validTimes : null;
  };

  return [getValidTimes];
};

export default useValidTimes;
