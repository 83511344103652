/** @format */

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { FullScreen, ForgotPasswordForm, Modal } from '@components';
import {
  forgotPassword,
  setForgotPasswordEmail,
  resetAuthState,
} from '@slices/auth';
import { Portal } from 'react-portal';

const ForgotPassword = () => {
  const {
    trigger,
    register,
    errors,
    formState: { isValid },
  } = useForm({
    mode: 'onBlur',
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const state = useSelector(store => store.auth.forgotPassword);
  const { largeScreen, user } = useSelector(store => store.app);

  useEffect(() => {
    return () => {
      dispatch(resetAuthState());
    };
  }, [dispatch]);

  const onCloseModal = () => {
    history.push('/');
    dispatch(resetAuthState());
  };

  const onCloseFullScreen = () => {
    history.push('/');
  };

  const onSubmit = () => {
    dispatch(forgotPassword(history));
  };

  const onChange = async e => {
    const { name, value } = e.target;
    dispatch(setForgotPasswordEmail(value));

    await trigger(name);
  };

  const forgotPasswordForm = (
    <ForgotPasswordForm
      errors={errors}
      isValid={isValid}
      validate={register}
      largeScreen={largeScreen}
      onCloseModal={onCloseModal}
      onCloseFullScreen={onCloseFullScreen}
      onChange={onChange}
      onSubmit={onSubmit}
      state={state}
    />
  );

  return largeScreen ? (
    !user && (
      <Portal>
        <Modal width="738" open toggle={onCloseModal}>
          {forgotPasswordForm}
        </Modal>
      </Portal>
    )
  ) : (
    <FullScreen id="forgot-password-full-screen" headerHeight={0}>
      {forgotPasswordForm}
    </FullScreen>
  );
};

export default ForgotPassword;
