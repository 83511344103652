/** @format */

import styled from 'styled-components';
import { MapContainer, Tooltip } from 'react-leaflet';
import { theme } from '@constants';
import { Row } from '..';

export const MapRow = styled(Row)`
  width: 100%;
  position: relative;
  z-index: 0;

  .marker-cluster {
    background-color: ${theme.BASE_COLOR} !important;
    div {
      background-color: ${theme.BASE_COLOR} !important;
      span {
        color: ${theme.WHITE};
      }
    }
  }
`;

export const SMap = styled(MapContainer)`
    width: 100%;
    height: 100%;
    z-index: 1;
`;

export const TimeRow = styled.p`
  margin: 5px 0 !important;
  text-transform: capitalize;
  text-align: center;
`;

export const TextAlign = styled.div`
  text-align: center;
`;

export const TooltipText = styled(TextAlign)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
`;

export const STooltip = styled(Tooltip)`
  background-color: ${theme.WHITE};
  border: 1px solid transparent;
  padding: 6px 16px;
  font-size: 14px;
  font-family: Inter;
  border-radius: 40px 50px 50px 0px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: -8px;
  margin-left: 64px;

  &.leaflet-tooltip-top:before {
    border-top-color: transparent;
  }
`;
