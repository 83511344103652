/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import {
  faCopy,
  faExclamationCircle,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { theme } from '@constants';
import { helpers } from '@utils';
import { Col, Row, Tag } from '..';
import config from './config';
import {
  FormWrapper,
  Wrapper,
  Title,
  SubTitle,
  InviteButton,
  CloseIconStyled,
  CloseIconRow,
  TagsContainer,
  Tooltip,
  TextSmall,
  SuccessLabel,
  SInput,
  CopyToClipboardContainer,
  Popup,
  CopyTitle,
  CopyMobileButton,
  LoaderIcon,
} from './styled';

const renderLabel = (t, formField) => {
  return formField.tooltip ? (
    <Row id="#tooltip" justify="flex-start" align="flex-start">
      <TextSmall id="tooltip-text">{t(formField.label)} </TextSmall>
      <Tooltip
        id="send-referral-tooltip"
        multiline
        backgroundColor={theme.WEIRD_GREEN}
        textColor={theme.WHITE}
        place="top"
      />
      <span
        data-tip={t(formField.tooltip)}
        data-cy="hint-icon"
        data-event="mouseover"
        data-event-off="mouseout"
        data-for="send-referral-tooltip"
        data-place="top"
        data-effect="solid"
        id="tooltip-span-item"
      >
        <FontAwesomeIcon icon={faExclamationCircle} />
      </span>
    </Row>
  ) : (
    <TextSmall>{t(formField.label)} </TextSmall>
  );
};

const SendReferralInvites = ({
  toggle,
  largeScreen,
  copyToClipboard,
  errors,
  onChange,
  state,
  validate,
  onAddEmail,
  onRemoveTag,
  referralCode,
  inviteButtonEnabled,
  onSubmit,
  referralCodeLoading,
  isValid,
}) => {
  const { t } = useTranslation();
  const width = largeScreen ? '720' : (window.innerWidth * 0.9).toString();
  return (
    <Popup width={width} toggle={toggle}>
      <Wrapper justify="space-between" align="center">
        <FormWrapper justify="center" align="center">
          <CloseIconRow align="flex-end" justify="flex-end">
            <CloseIconStyled
              data-cyx="close-btn"
              data-cy="close-modal-btn"
              id="close-button"
              className="close-button"
              onClick={toggle}
              width={20}
            />
          </CloseIconRow>
          <Col justify="flex-start" align="flex-start">
            <Title>{t('send-referrals.labels.title')}</Title>
            <SubTitle>{t('send-referrals.labels.sub-title')}</SubTitle>
          </Col>
          <Col justify="center" align="center">
            {config.formFields.map((formField, i) => (
              <Col>
                <SInput
                  key={i}
                  data-cy={helpers.camelToKebabCase(formField.name)}
                  floating
                  type={formField.type}
                  name={formField.name}
                  onChange={onChange}
                  value={state.inputEmailOrPhone}
                  label={renderLabel(t, formField, largeScreen)}
                  color={theme.BASE_COLOR}
                  inputRef={validate(formField.validator)}
                  error={t(
                    helpers.getObjectProperty(errors, [
                      formField.name,
                      'message',
                    ]),
                  )}
                  onKeyDown={onAddEmail}
                  disabled={!!formField.disabled}
                />
                {isValid && state.inputEmailOrPhone ? (
                  <SuccessLabel>{t(formField.subLabel)} </SuccessLabel>
                ) : null}
              </Col>
            ))}
          </Col>
        </FormWrapper>

        <TagsContainer
          justify="flex-start"
          align="center"
          data-cy="tags-container"
          id="tags-container"
        >
          {state.emailsOrPhoneNumbers.map((x, i) => (
            <Tag
              largeScreen={largeScreen}
              key={i}
              text={x}
              onRemoveTag={() => onRemoveTag(x)}
            />
          ))}
        </TagsContainer>
        <InviteButton
          data-cy="submit-btn"
          id="send-referrals-invite-btn"
          label={t('send-referrals.buttons.invite')}
          color={theme.BASE_COLOR}
          icon={state.loading ? faSpinner : null}
          iconProps={{ spin: true }}
          disabled={!inviteButtonEnabled || state.loading}
          onClick={onSubmit}
        />
        <CopyToClipboardContainer align="center" justify="space-between">
          <CopyTitle id="copy-referral-code-title">
            {t('send-referrals.labels.copy-referral-code')} {referralCode}
            {referralCodeLoading && <LoaderIcon icon={faSpinner} spin />}
          </CopyTitle>
          <CopyMobileButton
            icon={faCopy}
            data-cy="saved-addresses"
            id="copy-referral-code-btn"
            onClick={copyToClipboard}
            color={theme.BASE_COLOR}
          />
        </CopyToClipboardContainer>
      </Wrapper>
    </Popup>
  );
};

SendReferralInvites.propTypes = {
  toggle: PropTypes.func.isRequired,
  largeScreen: PropTypes.bool.isRequired,
  copyToClipboard: PropTypes.func.isRequired,
  errors: PropTypes.object,
  validate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  state: PropTypes.shape({
    emailsOrPhoneNumbers: PropTypes.array,
    inputEmailOrPhone: PropTypes.string,
    loading: PropTypes.bool,
  }).isRequired,
  onAddEmail: PropTypes.func.isRequired,
  onRemoveTag: PropTypes.func.isRequired,
  referralCode: PropTypes.string,
  inviteButtonEnabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  referralCodeLoading: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
};

SendReferralInvites.defaultProps = {
  errors: null,
};

export default SendReferralInvites;
