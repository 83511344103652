/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useLocation } from 'react-router-dom';
import {
  Map,
  Home,
  TripRequest,
  WorkforceTripRequest,
  TripOverview,
  MyTrips,
  TripDetail,
  TripOptions,
  PaymentContainer,
  LoadCredits,
  AccountSettings,
  Header,
  SignupOrg,
  SavedAddresses,
  SendReferralInvites,
  OrganizationSchedule,
  OrganizationPlaceCards,
  RateRide,
  TermsConditions,
  FixedRoutePublicMap,
  Login,
  Signup,
  ForgotPassword,
  ResetPassword,
  ExtraFieldsAndSpecialNeeds,
  RidershipBonus,
  WorkplaceSelectionForm,
  WorkforceMap,
} from '@containers';

import { Screen, ProtectedRoute, Row } from '@components';
import { SwitchWrapper } from './styled';

const workforceEnv = process.env.REACT_APP_WORKFORCE_APP === '1';

const Large = ({ isAuthenticated, headerHeight }) => {
  const location = useLocation();
  const renderAccountSettings = ({ match: { url } }) => (
    <>
      <ProtectedRoute
        isAllowed={isAuthenticated}
        path={`${url}/`}
        component={AccountSettings}
        restricted
        exact
      />
      <ProtectedRoute
        isAllowed={isAuthenticated}
        path={`${url}/addresses`}
        component={SavedAddresses}
        restricted
      />
    </>
  );

  if (location.pathname.includes('fixed-route/')) {
    return (
      <Screen>
        <Switch>
          <Route
            path="/fixed-route/:routeKey"
            component={FixedRoutePublicMap}
          />
        </Switch>
      </Screen>
    );
  }

  const isSideBarHidden = () => {
    if (
      location.pathname === '/' ||
      location.pathname.includes('/org') ||
      location.pathname === '/login' ||
      location.pathname === '/signup' ||
      location.pathname === '/ridership-bonus-promo' ||
      (location.pathname === '/trip-request' && workforceEnv)
    ) {
      return true;
    }

    return false;
  };

  const showWorkforceSearch = () => {
    return location.pathname === '/trip-request' || location.pathname === '/';
  };

  return (
    <Screen>
      <>
        <Header isAuthenticated={isAuthenticated} />
        {workforceEnv ? (
          showWorkforceSearch() ? (
            <WorkforceTripRequest />
          ) : null
        ) : (
          <TripRequest largeScreen />
        )}

        <Row justify="flex-start" align="stretch">
          <SwitchWrapper
            headerHeight={headerHeight}
            sidebarHidden={isSideBarHidden()}
            fullMap={workforceEnv}
          >
            <Switch>
              <Route path="/" exact component={Home} />
              <ProtectedRoute
                isAllowed={!isAuthenticated}
                path="/login"
                component={Login}
                restricted
                exact
              />
              <ProtectedRoute
                isAllowed={isAuthenticated}
                path="account-settings/addresses"
                component={SavedAddresses}
                restricted
                exact
              />
              <ProtectedRoute
                isAllowed={!isAuthenticated}
                path="/forgot-password"
                redirectTo="/"
                component={ForgotPassword}
                restricted
              />
              <ProtectedRoute
                isAllowed={!isAuthenticated}
                path="/reset-password"
                redirectTo="/"
                component={ResetPassword}
                restricted
              />
              <ProtectedRoute
                isAllowed={!isAuthenticated}
                path="/signup"
                redirectTo="/"
                component={Signup}
                restricted
              />
              <ProtectedRoute
                isAllowed
                path="/invite-friends"
                component={SendReferralInvites}
                restricted
                exact
              />
              <ProtectedRoute
                isAllowed={isAuthenticated}
                path="/account-settings"
                render={renderAccountSettings}
                restricted
              />
              <ProtectedRoute
                isAllowed={isAuthenticated}
                path="/load-credits"
                component={LoadCredits}
                restricted
              />
              <ProtectedRoute
                isAllowed={isAuthenticated}
                path="/trip-options"
                component={TripOptions}
              />
              <ProtectedRoute
                isAllowed={isAuthenticated}
                path="/schedule"
                component={OrganizationSchedule}
              />
              <ProtectedRoute
                isAllowed={isAuthenticated}
                path="/trip-overview"
                component={TripOverview}
              />
              <ProtectedRoute
                isAllowed={isAuthenticated}
                path="/my-trips"
                component={MyTrips}
              />
              <ProtectedRoute
                isAllowed={isAuthenticated}
                path="/trip-detail"
                component={TripDetail}
              />
              <ProtectedRoute
                isAllowed={isAuthenticated}
                path="/payment"
                component={PaymentContainer}
              />
              <ProtectedRoute
                isAllowed={isAuthenticated}
                path="/transit-trip-detail"
                component={TripDetail}
                redirectTo="/"
              />
              <ProtectedRoute
                isAllowed={isAuthenticated}
                path="/transit-share-trip-detail"
                component={TripDetail}
                redirectTo="/"
              />
              <ProtectedRoute
                isAllowed={!isAuthenticated}
                path="/org/:orgId"
                component={SignupOrg}
                redirectTo="/"
              />
              <ProtectedRoute
                isAllowed={isAuthenticated}
                path="/riderbonus"
                component={RidershipBonus}
              />
            </Switch>
          </SwitchWrapper>
          <Switch>
            <ProtectedRoute
              isAllowed={isAuthenticated}
              path="/rate-ride"
              component={RateRide}
              redirectTo="/"
            />
            <ProtectedRoute
              isAllowed={isAuthenticated}
              path="/terms-and-conditions"
              component={TermsConditions}
              redirectTo="/"
            />
            <ProtectedRoute
              isAllowed={isAuthenticated}
              path="/user-details"
              component={ExtraFieldsAndSpecialNeeds}
              exact
            />
            <ProtectedRoute
              isAllowed={isAuthenticated}
              path="/select-workplace"
              component={WorkplaceSelectionForm}
              redirectTo="/"
              exact
            />
          </Switch>

          {workforceEnv ? <WorkforceMap /> : <Map />}
          <OrganizationPlaceCards
            isSmall={false}
            isHome={
              location.pathname === '/' || location.pathname.includes('/org')
            }
          />
        </Row>
      </>
    </Screen>
  );
};

Large.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  headerHeight: PropTypes.number.isRequired,
};

export default Large;
