/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { HomeCol } from './styled';

const HomeWorkforceSmall = ({ children }) => {
  return (
    <HomeCol>
      {children}
    </HomeCol>
  );
};

HomeWorkforceSmall.propTypes = {
  children: PropTypes.any,
};

HomeWorkforceSmall.defaultProps = {
  children: null,
};

export default HomeWorkforceSmall;
