/** @format */

import React from 'react';
import PropTypes from 'prop-types';

import { Row as SRow } from './styled';

const Row = ({
  id,
  children,
  className,
  spacingH,
  spacingV,
  flex,
  justify,
  align,
  wrap,
  fullWidth,
  rowRef,
  ...restProps
}) => (
  <SRow
    ref={rowRef}
    id={id}
    className={className}
    spacingH={spacingH}
    spacingV={spacingV}
    flex={flex}
    justify={justify}
    align={align}
    wrap={wrap}
    fullWidth={fullWidth}
    {...restProps}
  >
    {children}
  </SRow>
);

Row.propTypes = {
  children: PropTypes.any.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  spacingH: PropTypes.number,
  spacingV: PropTypes.number,
  flex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  justify: PropTypes.oneOf([
    'flex-start',
    'flex-end',
    'center',
    'space-between',
    'space-around',
    'space-evenly'
  ]).isRequired,
  align: PropTypes.oneOf([
    'stretch',
    'center',
    'flex-start',
    'flex-end',
    'baseline',
  ]).isRequired,
  wrap: PropTypes.oneOf(['nowrap', 'wrap', 'wrap-reverse']),
  fullWidth: PropTypes.bool,
  rowRef: PropTypes.object,
};

Row.defaultProps = {
  id: '',
  className: '',
  spacingH: 0,
  spacingV: 0,
  flex: 1,
  wrap: 'nowrap',
  fullWidth: true,
  rowRef: null,
};

export default Row;
