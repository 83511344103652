/** @format */

// 3rd party Imports
import styled from 'styled-components';

// Internal Imports
import { Row, Button } from '@components';
import { theme, device } from '@constants';
import { IconButton } from '..';

export const RideForm = styled.div`
  position: relative;
  border-radius: inherit;
  background: ${theme.WHITE};
`;

export const ActionButton = styled(Button)`
  width: 128px;
  height: 53px;
  font-size: 13px;
  border-radius: 5px;
`;

export const CloseIcon = styled(IconButton)`
  margin: 13px 13px;
`;

export const Avatar = styled(Row)`
  background: linear-gradient(180deg, transparent 50%, white 50%);
`;

export const UserImage = styled.img`
  position: absolute;
  top: -54px;
  background-position: center;
  object-fit: cover;
  z-index: 3000;
  border-radius: 100%;
  height: 134px;
  width: 134px;
  @media ${device.mediaQuery.SMALLER_THAN_448} {
    top: -44px;
    height: 100px;
    width: 100px;
  }
`;

export const UserNameImage = styled(Row)`
  position: absolute;
  top: -54px;
  z-index: 3000;
  text-align: center;
  background-color: ${theme.DARK_BLUE};
  color: ${theme.WHITE};
  border-radius: 100%;
  font-size: 52px;
  height: 134px;
  width: 134px;
  @media ${device.mediaQuery.SMALLER_THAN_448} {
    top: -44px;
    height: 100px;
    width: 100px;
    font-size: 38px;
  }
`;

export const FeedbackHeading = styled.div`
  text-align: center;
  font-size: 26px;
  color: ${theme.BASE_COLOR};
  font-weight: bold;
`;

export const Comment = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid ${theme.BASE_COLOR};
`;

// Rider description section
export const RideDescription = styled.span`
  text-align: center;
  margin: 0;
  span {
    font-weight: bold;
  }
`;

export const ContentContainer = styled.div`
  width: ${props => props.width};
  margin: auto;
  text-align: center;
  @media ${device.mediaQuery.SMALLER_THAN_448} {
    width: 96%;
    margin: auto;
  }
`;

export const ModalContentWrapper = styled.div`
  overflow: auto;
  background-color: ${theme.WHITE};
  padding-bottom: 20px;
  max-height: 75vh;
  border-radius: inherit;
  @media ${device.mediaQuery.SMALLER_THAN_448} {
    width: 96%;
    max-height: 75vh;
    margin: auto;
  }
`;
