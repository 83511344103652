/** @format */

import styled from 'styled-components';

import { Col } from '@components';
import { theme } from '@constants';

export const ColorFiller = styled.div`
  margin: 0;
  width: 100%;
  opacity: ${props => props.opacity || 1};
  background-color: ${props => props.color};
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
  font-family: Inter;
  ${props => props.largeScreen ? 'border-radius: 6px' : ''}
`;

export const Wrapper = styled.div`
  width: ${props => `${props.width || 100}%`};
`;

export const Bar = styled(Col)`
  border-radius: 100px;
  border: 1px solid rgba(54, 58, 69, 0.3);
  background-color: rgba(54, 58, 69, 0.3);
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
  position: relative;
  height: 44px;
  width: 100%;
  margin: ${props => (props.margin || 0)};
`;

export const BarFilled = styled.div`
  width: ${props => `${props.width}%`};
  border-radius: 100px;
  height: 44px;
  background-color: ${props => props.color};
  position: absolute;
  left: 0;
  z-index: 5;
`;

export const Category = styled.span`
  position: absolute;
  font-size: 14px;
  z-index: 10;
  left: 20px;
  font-weight: 600;
`;

export const BarAmount = styled.span`
  font-weight: 600;
  font-size: 22px;
  position: absolute;
  right: 20px;
`;

export const DynamicText = styled.span`
  font-size: ${props => props.fontSize || 14}px;
  font-weight: ${props => (props.bold ? 600 : 400)};
  opacity: ${props => props.opacity || 1};
  color: ${props => props.color || theme.BLACK};
  ${props => props.width && `width: ${props.width}px`};
  margin: ${props => props.margin || 0};
  ${props => (props.alignRight ? 'text-align: right;' : '')};
`;

export const LifeTimeStats = styled(Col)`
  margin-bottom: 5px;
`;
