/** @format */

import styled from 'styled-components';

import { theme, device } from '@constants';
import { Col, Button, Input } from '@components';

export const Title = styled.h1`
  font-family: Bryant;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 26px;
  color: ${theme.COD_GRAY};
  margin: 0px 0 20px 0;
  @media ${device.mediaQuery.SMALL_SCREEN} {
    font-size: 28px;
  }

`;

export const ColItem = styled(Col)`

  min-height: ${window.innerHeight * 0.8}px;
  position: relative;
  @media ${device.mediaQuery.SMALL_SCREEN} {
    min-height: 100vh;
  }

`;

export const BottomContainer = styled.div`
  border-radius: 5px 5px 0px 0px;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  border-radius: 8px;
  margin-top: 15px;
  font-family: Bryant;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  height: 52px;
  @media ${device.mediaQuery.SMALL_SCREEN} {
    font-size: 20px
  }
`;

export const SInput = styled(Input)`
  color: ${theme.WHITE};
  input {
    border: 1px ${theme.LIGHTEN_GRAY} solid;
    border-radius: 8px;
    font-family: Bryant;
    font-size: 18px;
    height: 60px;
  }
  input::placeholder {
    color: ${theme.COD_GRAY_LIGHT};
    font-family: Bryant;
    opacity: 0.5;
  }
  #error {
    font-size: 16px;
    font-weight: 400;
    font-family: Bryant;
    margin-top: 5px;
    margin-bottom: 5px;
    color: ${theme.SECONDARY_BASE_COLOR};
  }
`;
