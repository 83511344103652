import Leaflet from 'leaflet';

import location from '@assets/icons/black-circle.svg';
import pin from '@assets/icons/location-marker-blue.svg';
import home from '@assets/icons/home.svg';
import vehicle from '@assets/images/bus.png';

import { theme } from '@constants';

const credits = {
  attribution:
    '&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
  url:
    'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
};

const markerIcon = Leaflet.icon({
  iconUrl: location,
  iconSize: [18, 18],
});

const vehicleMarkerIcon = Leaflet.icon({
  iconUrl: vehicle,
  iconSize: [28, 28],
});

const pinMarkerIcon = Leaflet.icon({
  iconUrl: pin,
  iconSize: [28, 28],
});

const homeMarkerIcon = Leaflet.icon({
  iconUrl: home,
  iconSize: [28, 28],
});

const polylineOptions = {
  color: theme.WEIRD_GREEN,
  weight: 5,
};

const polylineOptionsForFixedRouteSearchPath = {
  color: theme.DARK_BLUE,
  weight: 5,
};

const polylineOptionsForFixedRouteSearchWalkPath = {
  color: theme.EMPEROR_GRAY,
  weight: 5,
  dashArray: '10, 10', dashOffset: '0',
};

const defaultCenter = [39.9831403, -83.2710199]; // Columbus, Ohio Coordinates

export {
  credits,
  markerIcon,
  pinMarkerIcon,
  vehicleMarkerIcon,
  polylineOptions,
  defaultCenter,
  polylineOptionsForFixedRouteSearchWalkPath,
  homeMarkerIcon,
  polylineOptionsForFixedRouteSearchPath
};