/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';

import { PaymentMethods, FullScreen } from '@components';
import {
  attachPaymentMethod,
  detachPaymentMethod,
  getPaymentMethods,
  setDeletePaymentMethodId,
  setSelectedPaymentMethod,
  setLoading,
} from '@slices/paymentMethods';
import { trackAbEvent } from '@slices/app';
import { helpers, toast } from '@utils';
import { analytics } from '@constants';
import { useHeaderHeight, useConfirmationDialog } from '@hooks';

const defaultBillingDetails = {
  name: '',
  zipCode: '',
};

const StripePaymentMethod = () => {
  const { t } = useTranslation();
  const [billingDetails, setBillingDetails] = useState(defaultBillingDetails);
  const { ConfirmationDialog, openConfirmationDialog } =
    useConfirmationDialog();
  const dispatch = useDispatch();
  const location = useLocation();
  const { nextRoute } = location.state || {};
  const history = useHistory();
  const [headerHeight] = useHeaderHeight();

  const { user, largeScreen } = useSelector(store => store.app);
  const { loading, addedPaymentMethods, deletePaymentMethodId } = useSelector(
    store => store.paymentMethods,
  );

  const stripe = useStripe();
  const elements = useElements();
  const isPreviousScreenTripOverview = useMemo(
    () =>
      helpers.getObjectProperty(
        location,
        'state.isPreviousScreenTripRequest',
        false,
      ),
    [location],
  );

  useEffect(() => {
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.PAYMENT_METHOD_VIEW,
        subJourneyName: 'Payments',
        journeyName: 'Rider Experience',
      }),
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isPreviousScreenTripOverview) {
      dispatch(getPaymentMethods());
    } else {
      dispatch(setLoading(null));
    }
  }, [dispatch, isPreviousScreenTripOverview]);

  useEffect(() => {
    setBillingDetails(defaultBillingDetails);
  }, [addedPaymentMethods.length]);

  const goToTripOverview = () => {
    if (isPreviousScreenTripOverview) {
      history.push('/trip-overview');
    }
  };

  const addPaymentMethod = async () => {
    const { name, zipCode } = billingDetails;

    const card = elements.getElement(CardNumberElement);
    const expiry = elements.getElement(CardExpiryElement);
    const cvc = elements.getElement(CardCvcElement);

    if (
      (!card._complete && !card._invalid) ||
      (!expiry._complete && !expiry._invalid) ||
      (!cvc._complete && !cvc._invalid)
    ) {
      return toast.show('error', t('payments.messages.card-details-missing'));
    }
    if (card._invalid || expiry._invalid || cvc._invalid) {
      return toast.show('error', t('payments.messages.card-details-invalid'));
    }

    try {
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card,
        billing_details: {
          email: user.email,
          name,
          address: {
            postal_code: zipCode,
          },
        },
      });
      dispatch(attachPaymentMethod(paymentMethod.id, goToTripOverview));
      if (location.directedFrom === 'load-credits') {
        history.push('load-credits');
      }
    } catch {
      return toast.show(
        'error',
        t('messages.error.something-went-wrong'),
      );
    }
  };

  const setSelected = selectedPaymentMethod => {
    const { id: paymentMethodId } = addedPaymentMethods[selectedPaymentMethod];
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.SELECTED_PAYMENT_METHOD,
        subJourneyName: 'Payments',
        journeyName: 'Rider Experience',
        details: {
          paymentMethodId,
        },
      }),
    );
    dispatch(setSelectedPaymentMethod(selectedPaymentMethod));
    goToTripOverview();
  };

  const onBillingDetailsChange = e => {
    const { name, value } = e.target;
    if (
      (name === 'zipCode' && value.length < 13) ||
      (name === 'name' && value.length < 35)
    ) {
      setBillingDetails({
        ...billingDetails,
        [name]: value,
      });
    }
  };

  const onDeletePaymentMethod = () => {
    openConfirmationDialog(false);
    dispatch(detachPaymentMethod());
  };

  const openConfirmDeleteModal = () => {
    openConfirmationDialog(true, { onConfirmClick: onDeletePaymentMethod });
  };

  const onChangeDeletePaymentMethodId = value => {
    dispatch(setDeletePaymentMethodId(value));
  };

  const goBack = () => {
    nextRoute ? history.push(nextRoute) : history.goBack();
  };

  const paymentMethods = (
    <>
      <PaymentMethods
        addedPaymentMethods={addedPaymentMethods}
        addPaymentMethod={addPaymentMethod}
        loading={loading}
        billingDetails={billingDetails}
        onBillingDetailsChange={onBillingDetailsChange}
        openConfirmDeleteModal={openConfirmDeleteModal}
        deletePaymentMethodId={deletePaymentMethodId}
        onChangeDeletePaymentMethodId={onChangeDeletePaymentMethodId}
        setSelected={setSelected}
        goBack={goBack}
      />
    </>
  );

  return largeScreen ? (
    <>
      {paymentMethods}
      <ConfirmationDialog />
    </>
  ) : (
    <FullScreen id="payment-methods-full-screen" headerHeight={headerHeight}>
      <>
        {paymentMethods}
        <ConfirmationDialog />
      </>
    </FullScreen>
  );
};

export default StripePaymentMethod;
