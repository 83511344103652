/** @format */

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Col } from '..';
import { theme } from '@constants';
import { styles as styleUtils } from '@utils';

export const FullScreenLoaderCol = styled(Col)`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2000;
  background-color: ${styleUtils.hexToRgba(theme.BASE_COLOR, 0.85)};
`;

export const LoaderIcon = styled(FontAwesomeIcon)`
  position: relative;
  z-index: 2001;
`;
