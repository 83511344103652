/** @format */

import styled from 'styled-components';
import { theme } from '@constants';
import { Row } from '..';

export const NameRow = styled(Row)`
  margin: 10px 0;
  flex:0;
`;

export const NameText = styled.span`
  color: ${theme.BASE_COLOR};
`;

export const NameTextBold = styled.span`
  color: ${theme.BASE_COLOR};
  font-weight: 600;
`;
