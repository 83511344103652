/** @format */

const tabs = [
  {
    titleContent: 'workforce-trip-request.fields.switch.labels.one-way',
    id: 'one-way',
  },
  {
    titleContent: 'workforce-trip-request.fields.switch.labels.round-trip',
    id: 'round-trip',
  },
];

const colors = {
  blue: '#044DC8',
  green: '#15803D',
  skewRowColor: '#00cfff',
  dropdownBorder: '#a9aebc',
  addressType: 'rgba(0, 0, 0, 0.72)',
  lightGray: '#a9aebc',
};

const config = {
  pickup: {
    style: `color: ${colors.blue}; font-size: 14px; font-weight: 600;`,
    label: 'workforce-trip-request.fields.dropdown.labels.leaving-work',
  },
  dropoff: {
    style: `color: ${colors.green}; font-size: 14px; font-weight: 600;`,
    label: 'workforce-trip-request.fields.dropdown.labels.dropoff-work',
  },
  stop: {
    style: 'font-size: 10px; font-weight: 600;',
  },
  container: {
    style: 'padding: 5px; display: flex; flex-direction: row; justify-content: space-between;',
  },
  locationName: {
    style: 'overflow: hidden; text-overflow: ellipsis; margin-right: 10px;',
  },
};

const getCalendarDisabledDates = (trDisabledDates, org) => {
  const { detail } = org || {};
  const { bookingDelay } = detail || {};
  const disableBefore = new Date();
  if (bookingDelay && bookingDelay > 0) {
    const bookingDelayDays = Math.trunc(bookingDelay / 86400);
    disableBefore.setDate(disableBefore.getDate() + bookingDelayDays);
  }

  const disabledDays = [{ before: disableBefore }];

  if (Array.isArray(trDisabledDates) && trDisabledDates) {
    trDisabledDates.forEach(date => {
      disabledDays.push(new Date(date));
    });
  }

  return disabledDays;
};

const placeTypes = {
  workplace: 'WORKPLACE',
  stop: 'STOP',
};

export { tabs, config, colors, getCalendarDisabledDates, placeTypes };
