/** @format */

import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash'
import { PaymentContainer } from '@containers';
import { SheetModal, TripOverview as CTripOverview } from '@components';
import {
  sendTripRequests,
  setRepeatData,
  setRepeatEnabled,
  setPromoCode,
  resetTripRequestStateOnTripOverviewUnmount,
  getSelectedTripsPicingAndPoints,
  setLoading,
  setExtraSeats,
  setSelectedBookingMethod,
  setSelectedSelfPaymentOption,
} from '@slices/tripRequest';
import {
  setTripMapMarkers,
  setTripPath,
  clearTripPath,
  trackAbEvent,
} from '@slices/app';
import { analytics, generals as generalConstants } from '@constants';
import {
  useSheetModalMinimize,
  usePayingOrganization,
  useSelectedTrip,
  useGetTripPath,
  useBookingConfirmDialog,
  useMessageDialog,
} from '@hooks';

const getPayingOrganizations = (
  departTrip,
  returnTrip,
  getOrganizationName,
) => {
  const payingOrganizations = {
    depart: null,
    return: null,
  };

  if (departTrip) {
    const shareTrip = departTrip.share ? departTrip.share : departTrip;

    payingOrganizations.depart =
      shareTrip.cost === 'FREE'
        ? getOrganizationName(shareTrip.organizationId)
        : null;
  }
  if (returnTrip) {
    const shareTrip = returnTrip.share ? returnTrip.share : returnTrip;

    payingOrganizations.return =
      shareTrip.cost === 'FREE'
        ? getOrganizationName(shareTrip.organizationId)
        : null;
  }

  return payingOrganizations;
};

const getAvailableBookingMethods = (
  bookingMethods,
  isPaymentEnabled,
  userPoints,
  selectedTripsPoints,
) => {
  let availableBookingMethods =
    bookingMethods?.filter(
      x => x !== generalConstants.BOOKING_METHODS.ORGANIZATION_PAID,
    ) || [];

  if (isPaymentEnabled) {
    if (
      availableBookingMethods.length === 2 &&
      availableBookingMethods.includes(
        generalConstants.BOOKING_METHODS.POINTS,
      ) &&
      userPoints < selectedTripsPoints?.totalPoints
    ) {
      availableBookingMethods = availableBookingMethods.filter(
        x => x !== generalConstants.BOOKING_METHODS.POINTS,
      );
    }
  }

  if (availableBookingMethods.length === 0) {
    availableBookingMethods.push(generalConstants.BOOKING_METHODS.SELF_PAID);
  }

  return availableBookingMethods;
};

const TripOverview = () => {
  const { t } = useTranslation();
  const [sheetModalMinimized, toggleSheetModalMinimized] =
    useSheetModalMinimize();
  const { BookingConfirmDialog, openBookingConfirmDialog } =
    useBookingConfirmDialog();
  const { MessageDialog, openMessageDialog } = useMessageDialog();

  const [getOrganizationName] = usePayingOrganization();
  const [departTrip, returnTrip] = useSelectedTrip();
  const [tripPath] = useGetTripPath();

  const history = useHistory();
  const dispatch = useDispatch();
  const [isOrganizationPaidVisible, setIsOrganizationPaidVisible] = useState(true);

  const {
    selectedTrip,
    trips,
    loading,
    extraSeats,
    repeat,
    promoCode,
    selectedTripsPrice,
    selectedTripsPoints,
    selectedBookingMethod,
    selectedSelfPaymentOption,
    hideTripOptions,
  } = useSelector(store => store.tripRequest);
  const { selectedPaymentMethod, addedPaymentMethods, addedSquareCards } = useSelector(
    store => store.paymentMethods,
  );
  const {
    largeScreen,
    organizations,
    selectedOrganization,
    user: { points: userPoints },
  } = useSelector(store => store.app);

  const paymentMethod = addedPaymentMethods[selectedPaymentMethod];
  const squarePaymenMethods = selectedPaymentMethod !== -1 ?
  addedSquareCards && addedSquareCards[selectedPaymentMethod] :
  addedSquareCards && addedSquareCards[0];
  const isSelected = departTrip || returnTrip;

  const isPaymentEnabled =
    (departTrip && departTrip.cost === 'BUY') ||
    (returnTrip && returnTrip.cost === 'BUY');

  const bookingMethods = getAvailableBookingMethods(
    (organizations || [])[selectedOrganization]?.organization?.bookingMethods,
    isPaymentEnabled,
    userPoints,
    selectedTripsPoints,
  );

  const tripRequestSuccess = useCallback(
    (created, selectedTripsPrice, extraSeats, resetCallback) => {
      openBookingConfirmDialog(true, {
        created,
        selectedTripsPrice,
        extraSeats,
        resetCallback,
      });
    },
    [openBookingConfirmDialog],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getTripPricingAndCount = useCallback(debounce((promo) => {
    dispatch(getSelectedTripsPicingAndPoints(promo));
  }, 1000), [dispatch]);

  useEffect(() => {
    if (isSelected) {
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.TRIP_OVERVIEW_VIEW,
          subJourneyName: 'Trip Overview',
          journeyName: 'Rider Experience',
          details: {
            selectedTrip: trips.share.depart
              ? trips.share.depart[selectedTrip.share.depart]
              : trips.share.return[selectedTrip.share.return],
          },
        }),
      );

      const [pickup, destination] = departTrip
        ? departTrip.places
        : returnTrip.places;

      dispatch(
        setTripMapMarkers({
          pickup: {
            location: [pickup.lat, pickup.lng],
            type: pickup.orgPlaceType || '',
          },
          destination: {
            location: [destination.lat, destination.lng],
            type: destination.orgPlaceType || '',
          },
        }),
      );

      dispatch(setTripPath(tripPath));
      if (isPaymentEnabled) {
        const selectedOrg = organizations[selectedOrganization] || {};
        const { code, organizationId, classification } = selectedOrg.promoCode || {};
        const { ON_DEMAND, BOTH } = generalConstants.PROMO_CODE_TYPE;

        if (
          code &&
          selectedOrg.organizationId === organizationId &&
          [ON_DEMAND, BOTH].includes(classification)
        ) {
          dispatch(setPromoCode({ code, applied: false }));
          dispatch(
            setLoading(generalConstants.APPLY_PROMO_CODE_TRIP_REQUEST_LOADING),
          );

          dispatch(
            trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
              eventName: analytics.AUTO_APPLIED_PROMO_CODE,
              subJourneyName: 'Trip Overview',
              journeyName: 'Rider Experience',
              details: {
                promo: code,
              },
            }),
          );

          dispatch(
            getSelectedTripsPicingAndPoints(code, () => {
              getTripPricingAndCount();
            }),
          );
        } else getTripPricingAndCount();
      }
    }

    return () => {
      dispatch(resetTripRequestStateOnTripOverviewUnmount(true));
      dispatch(
        setTripMapMarkers({
          pickup: null,
          destination: null,
        }),
      );
      dispatch(clearTripPath());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isSelected]);

  useEffect(() => {
    if (isPaymentEnabled && bookingMethods.length === 1) {
      const [bookingMethod] = bookingMethods;
      if (bookingMethod === generalConstants.BOOKING_METHODS.POINTS) {
        if (userPoints >= selectedTripsPoints?.totalPoints) {
          dispatch(setSelectedBookingMethod(bookingMethod));
        } else {
          dispatch(setSelectedBookingMethod(null));
        }
      } else {
        const paymentOption =
          Array.isArray(organizations) &&
          organizations[selectedOrganization]?.organization &&
          (
            organizations[selectedOrganization].organization
              .selfPaymentOptions || []
          ).find(el => el === generalConstants.SELF_PAYMENT_OPTIONS.CARD);
        dispatch(setSelectedBookingMethod(bookingMethod));
        dispatch(setSelectedSelfPaymentOption(selectedSelfPaymentOption || paymentOption));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTripsPoints, bookingMethods?.length, isPaymentEnabled]);

  useEffect(() => {
    if (Array.isArray(organizations) && organizations.length > selectedOrganization) {
      const organization = organizations[selectedOrganization];
      if (organization && organization.detail && (typeof organization.detail.isOrganizationPaidVisible === "boolean")) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        setIsOrganizationPaidVisible(
          organization.detail.isOrganizationPaidVisible,
        );
      }
    }
  }, [organizations, selectedOrganization]);

  if (!isSelected) {
    history.push(largeScreen ? '/' : '/trip-request');
    return null;
  }

  const payingOrganizations = getPayingOrganizations(
    departTrip,
    returnTrip,
    getOrganizationName,
  );

  const onChangeFrequency = (name, value) => {
    if (name === 'enabled') {
      const depart =
        trips.share.depart[selectedTrip.share.depart] ||
        trips.transitShare.depart[selectedTrip.transitShare.depart]?.steps.find(step => step.type === 'share');
      const returnTrip =
        trips.share.return[selectedTrip.share.return] ||
        trips.transitShare.return[selectedTrip.transitShare.return]?.steps.find(
          step => step.type === 'share',
        );
      const time = depart ? depart.leaveTime : returnTrip.leaveTime;
      dispatch(
        setRepeatEnabled({
          enabled: value,
          end: value ? moment(time).add(3, 'weeks').format('MM/DD/YYYY HH:mm:ss') : null,
        }),
      );
      getTripPricingAndCount();
    } else {
      dispatch(
        setRepeatData({
          [name]: value,
        }),
      );
      getTripPricingAndCount();
    }
  };

  const onChangeExtraSeats = (key, value) => {
    const { chargeExtraSeats } = (
      organizations[selectedOrganization].organization || {}
    );
    dispatch(setExtraSeats({ key, value }));
    if (chargeExtraSeats) getTripPricingAndCount();
  };

  const onEdit = () => {
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.TRIP_OVERVIEW_BACK_BUTTON,
        subJourneyName: 'Trip Overview',
        journeyName: 'Rider Experience',
      }),
    );
    if (hideTripOptions) {
      history.push('/');
    } else {
      history.push('/trip-options');
    }
  };

  const bookRide = () => {
    dispatch(
      sendTripRequests(
           selectedTripsPrice &&
          selectedTripsPrice.tripData
          ? selectedTripsPrice.tripData
          : undefined,
        tripRequestSuccess,
      ),
    );
  };

  const applyPromoCode = () => {
    dispatch(
      setLoading(generalConstants.APPLY_PROMO_CODE_TRIP_REQUEST_LOADING),
    );

    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.APPLIED_PROMO_CODE,
        subJourneyName: 'Trip Overview',
        journeyName: 'Rider Experience',
        details: {
          promo: promoCode.code,
        },
      }),
    );

    getTripPricingAndCount(promoCode.code);
  };

  const onPromoCodeChange = e => {
    const { value } = e.target;
    dispatch(setPromoCode({ code: value, applied: false }));
    if (promoCode.applied) {
      //  if the code is applied already
      // and may be its valid or may be not but we have to fetch the updated price first
      getTripPricingAndCount();
      // promo code is applied but now user changed/removing it
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.PROMO_CODE_REMOVED,
          subJourneyName: 'Trip Overview',
          journeyName: 'Rider Experience',
          details: {
            promo: promoCode.code,
          },
        }),
      );
    }
  };

  const isValidDate = current => {
    const trip = departTrip || returnTrip;
    const disabled = moment(trip.leaveTime).subtract(1, 'days');

    // at max it can only be trip.leaveTime + 2 months
    return current.isAfter(disabled) && current.isSameOrBefore(moment(trip.leaveTime).add(2, 'months'));
  };

  const onChangeBookingMethod = (selectedSelfPaymentOption, selectedBookingMethod) => {
    if (
      selectedBookingMethod === generalConstants.BOOKING_METHODS.POINTS &&
      selectedTripsPoints?.totalPoints > userPoints
    ) {
      openMessageDialog(true, {
        bodyText: t('trip-overview.payment-methods.points.not-enough-points'),
      });
    } else {
      dispatch(setSelectedBookingMethod(selectedBookingMethod));
      dispatch(setSelectedSelfPaymentOption(selectedSelfPaymentOption));
      getTripPricingAndCount();
    }
  };

  const tripOverview = (
    <>
      <CTripOverview
        loading={loading}
        departTrip={departTrip}
        returnTrip={returnTrip}
        payingOrganizations={payingOrganizations}
        isOrganizationPaidVisible={isOrganizationPaidVisible}
        onEdit={onEdit}
        onBookRide={bookRide}
        repeat={repeat}
        onChangeFrequency={onChangeFrequency}
        isValidEndDate={isValidDate}
        selectedTripsPrice={selectedTripsPrice}
        paymentMethod={paymentMethod}
        applyPromoCode={!promoCode.applied ? applyPromoCode : null}
        promoCode={promoCode}
        isPromoCodeValid={!!promoCode.id}
        onPromoCodeChange={onPromoCodeChange}
        minimized={sheetModalMinimized}
        largeScreen={largeScreen}
        onChangeExtraSeats={onChangeExtraSeats}
        extraSeats={extraSeats}
        bookingMethods={bookingMethods}
        selectedTripsPoints={selectedTripsPoints}
        onChangeBookingMethod={onChangeBookingMethod}
        selectedBookingMethod={selectedBookingMethod}
        selectedSelfPaymentOption={selectedSelfPaymentOption}
        userPoints={userPoints}
        paymentOption={(organizations || [])[selectedOrganization]?.organization?.paymentOption}
        squarePaymenMethods={squarePaymenMethods}
        selfPaymentOptions={(organizations || [])[selectedOrganization]?.organization?.selfPaymentOptions}
      />
      <BookingConfirmDialog />
      <MessageDialog />
    </>
  );

  const tripOverviewRoute = largeScreen ? (
    tripOverview
  ) : (
    <SheetModal
      id="trip-detail-sheet-modal"
      minimizeEnabled
      minimized={sheetModalMinimized}
      toggleMinimized={toggleSheetModalMinimized}
    >
      {tripOverview}
    </SheetModal>
  );

  return (
    <Switch>
      <Route path="/trip-overview/payment" component={PaymentContainer} />
      <Route path="/trip-overview" render={() => tripOverviewRoute} />
      <Redirect to="/trip-overview" />
    </Switch>
  );
};

export default TripOverview;
