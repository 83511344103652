/** @format */

const BASE_URL = process.env.REACT_APP_BASE_URL;

// const BASE_URL = 'https://api.sharemobility.com';

const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT;

const endpoints = {
  SIGNUP: '/users/sign-up',
  LOGIN: '/users/app-login',
  GET_ORGANIZATION_DETAILS: '/organization-details/:id',
  PLACES: '/places',
  GET_FILTERED_ORGANIZATION_PLACES:
    '/organizations/:id/es-places?excludeUnavailable=true&withParent=true',
  GET_ORGANIZATION_PLACES_WITHOUT_AUTH:
    '/organizations/:id/es-places-without-auth?excludeUnavailable=true&withParent=true',
  SEARCH_TRIPS: '/trip-requests/search',
  SEARCH_FIXED_ROUTES: '/routing/fixed/search',
  GET_VEHICLE_LOCATION: '/vehicles/:id/location',
  TRIP_REQUESTS: '/trip-requests',
  GET_USER_TRIPS: '/users/:id/trips',
  GET_TRIP_BY_ID: '/trip-requests/details/:tripId',
  UPDATE_USER: '/users/:id',
  GET_INVITES: '/invites',
  ACCEPT_INVITE: '/invites/:token/accept',
  DECLINE_INVITE: '/invites/:token/decline',
  ATTACH_PAYMENT_METHOD: '/users/payment-methods/attach',
  DETACH_PAYMENT_METHOD: '/users/payment-methods/detach',
  GET_PAYMENT_METHODS: '/users/payment-methods/get',
  GET_RIDERS_LIST: '/users/:id/riders',
  CANCEL_TRIP_REQUEST: '/trip-requests/:id/cancel',
  PAY_TO_BOOK: '/users/payments/pay-to-book',
  POINTS_TO_BOOK: '/trip-request/points-to-book',
  ANALYTICS: '/analytics/track?type=rider-app',
  FORGOT_PASSWORD: '/users/forget-password',
  RESET_PASSWORD: '/users/reset-password',
  GET_USER_ORG_RESTRICTIONS: '/user/org-restrictions',
  AB_ANALYTICS: '/analytics/track?type=ab-testing',
  VERIFY_PROMO_CODE: 'organizations/:id/promo-code/is-valid',
  AUTHENTICATE_RIDER: '/users/:id/rider/authenticate',
  GET_USER: '/users/:id',
  SET_FEEDBACK: '/trip-request/:id/feedback',
  GET_COMPLETED_TRIP: '/user-completed-trip',
  GET_PATH: '/trip-requests/path',
  RATE_THE_TRIP: '/trip-requests/:id/rating',
  GET_TRIP_REQUEST_PRICING: '/trip-requests/get-pricing-and-count',
  GET_FIXED_ROUTE: '/routing/fixed/get-by-route-key/:routeKey',
  GET_FIXED_ROUTES: '/routing/fixed/routes/:orgId',
  GET_TRIP_POINTS: '/trip-request/points',
  GET_ZIPCODE: '/organizations/:orgId/trip-requests/service-area/:zipcode',
  SUBMIT_ZIPCODE_REQUEST: '/organizations/:id/zipcode-request',
  PASSWORDLESS_AUTH: '/users/passwordless-authentication',
  GET_NEW_TOKEN_WITH_REFRESH_TOKEN: '/users/login-with-refresh-token',
  SEND_REFERRALS: '/organizations/:id/send-referral-invites',
  GENERATE_REFERRAL_CODE: '/users/:id/generate-referral-code',
  GENERATE_QR_CODE: 'user/:id/build-qrcode',
  GET_SQUARE_CARDS: '/users/square/list-cards',
  ADD_SQUARE_CARD: '/users/square/add-card',
  DELETE_SQUARE_CARD: '/users/square/disable-card',
  LOAD_CREDIT_VIA_PAYMENT_METHOD: 'organizations/:orgId/users/load-credits',
  WORKFORCE_TRIPS_SEARCH: '/trip-requests/search-workforce',
  WORKFORCE_TRIPS_OPEN_SEARCH: '/trip-requests/open-search',
  WORKFORCE_TRIP_DETAILS: '/trip-requests/workforce-trip-details',
  GET_NEARBY_BUS_STOPS: '/routes/locations/nearby-bus-stops',
  RIDERSHIP_REDEEM_REQUEST: '/organization/:organizationId/users/ridership-redeem-request',
  SAVE_ENROLL_TO_RIDERSHIP_BONUS: '/organizations/:organizationId/users/ridership-enroll',
  GET_RIDERSHIP_TIERS: '/organization/:id/users/ridership-tier-details',
  GET_USER_LIFETIME_STATS: '/organization/:id/users/lifetime-stats',
  UPDATE_SELECTED_WORKPLACE: '/org-user/:id',
  GET_SHIFTS_EST_PICKUP_TIME: '/org-place/shift-est-pickup',
};

const status = {
  CREATED: 201,
  SUCCESS: 200,
};

const errors = {
  EXPIRED_TOKEN_ERROR: 'EXPIRED_TOKEN_ERROR',
  USER_DEACTIVATED_ERROR: 'USER_DEACTIVATED_ERROR',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  INVALID_DATA_ERROR: 'INVALID_DATA_ERROR',
  EXPIRED_REFRESH_TOKEN_ERROR: 'EXPIRED_REFRESH_TOKEN_ERROR',
  AUTH_INFO_ERROR: 'AUTH_INFO_ERROR',
  USER_NOT_FOUND_ERROR: `The 'User' was not found.`,
  ORG_INVITE_NOT_FOUND: 'There is no pending invite for the provided email and organization',
  INVALID_TOKEN_ERROR: 'INVALID_TOKEN_ERROR',
};

const headerKeys = {
  X_AUTH: 'x-auth',
  ENV_IDENTIFIER: 'env-identifier',
  MIX_PANEL_ID: 'mixpanel-id',
  ENV: 'env',
};

export default {
  BASE_URL,
  GRAPHQL_ENDPOINT,
  endpoints,
  status,
  errors,
  headerKeys,
};
