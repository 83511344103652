/** @format */
import shareLogo from '@assets/images/site-logo-new.png';
import shareFavIcon from '@assets/images/sharefav.png';
import dublinLogo from '@assets/images/dublin.png';
import hillardLogo from '@assets/images/hillard.png';
import { theme } from '..';

const SHARE_WINDOW_TITLE = 'SHARE Mobility Rider App';
const SHARE_LOGO = shareLogo;
const SHARE_FAV_ICON = shareFavIcon;

const DATE_TO_ADDRESS_MAPPING = {
  depart: 'pickup',
  return: 'destination',
};

const RESTRICT_TO_ORG_LOCATIONS = {
  Yes: 'Yes',
  No: 'No',
};

const DAYS = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

const MINIMAL_TO_FULL_DAY_MAPPING = {
  sun: 'sunday',
  mon: 'monday',
  tue: 'tuesday',
  wed: 'wednesday',
  thu: 'thursday',
  fri: 'friday',
  sat: 'saturday',
};

const FULL_TO_MINIMAL_DAY_MAPPING = {
  sunday: 'sun',
  monday: 'mon',
  tuesday: 'tue',
  wednesday: 'wed',
  thursday: 'thu',
  friday: 'fri',
  saturday: 'sat',
};

const WEEK_DAYS_LABELS = {
  sun: 'S',
  mon: 'M',
  tue: 'T',
  wed: 'W',
  thu: 'Th',
  fri: 'F',
  sat: 'S',
};

const FREQUENCY_REPEAT_EVERY_OPTIONS = [
  {
    value: 'Weekly',
    text: 'Week',
  },
];

const ADDRESS_TYPES = [
  {
    value: 'home',
    label: 'saved-addresses.fields.address.options.home',
  },
  {
    value: 'work',
    label: 'saved-addresses.fields.address.options.work',
  },
  {
    value: 'gym',
    label: 'saved-addresses.fields.address.options.gym',
  },
  {
    value: 'grocery',
    label: 'saved-addresses.fields.address.options.grocery',
  },
  {
    value: 'school',
    label: 'saved-addresses.fields.address.options.school',
  },
  {
    value: 'wellness',
    label: 'saved-addresses.fields.address.options.wellness',
  },
  {
    value: 'doctor',
    label: 'saved-addresses.fields.address.options.doctor',
  },
];

const TRIP_OPTION_STEPS = {
  SHARE: { text: 'SHARE', color: theme.BRIGHT_SKY_BLUE, icon: true },
  Bus: { text: 'Bus', color: theme.GREYISH_PURPLE, icon: true },
  Walk: { text: 'Walk', color: theme.BASE_COLOR, icon: true },
  Destination: { text: 'Destination', color: theme.TOMATO, icon: false },
  Home: { text: 'Home', color: theme.TOMATO, icon: false },
  Work: { text: 'Work', color: theme.TOMATO, icon: false },
  Gym: { text: 'Gym', color: theme.TOMATO, icon: false },
  Grocery: { text: 'Grocery', color: theme.TOMATO, icon: false },
  School: { text: 'School', color: theme.TOMATO, icon: false },
  Wellness: { text: 'Wellness', color: theme.TOMATO, icon: false },
  Doctor: { text: 'Doctor', color: theme.TOMATO, icon: false },
};

const ORGANIZATIONS_TO_WELCOME =
  process.env.REACT_APP_ORGANIZATIONS_TO_WELCOME.split(',');

const ORGANIZATION_WELCOME_DETAILS = [
  {
    logo: hillardLogo,
    title: 'Hillard Express',
    subtitle: 'welcome-views.organization-welcome.hillard.subtitle',
    schedule:
      'https://sharemobility.com/hilliard-express-availability-extended/',
    notificationText: 'HILLARD EXPRESS View Schedule & Availibility',
    color: theme.BRIGHT_SKY_BLUE,
    airtable:
      'https://airtable.com/embed/shrIbND6H9FN7DUmL?backgroundColor=blue&layout=card&viewControls=on%22',
    showSchedule: true,
  },
  {
    logo: dublinLogo,
    title: 'Dublin Connector',
    subtitle: 'welcome-views.organization-welcome.dublin.workforce.subtitle',
    schedule: 'http://sharemobility.com/dublin-availability-senior',
    notificationText: 'DUBLIN WORKFORCE View Schedule & Availibility',
    color: theme.GREEN,
    airtable:
      'https://airtable.com/embed/shriabT6y8Gf8vXHP?backgroundColor=red&layout=card&viewControls=on%22',
    showSchedule: false,
  },
  {
    logo: dublinLogo,
    title: 'Dublin Connector',
    subtitle:
      'welcome-views.organization-welcome.dublin.connector-senior.subtitle',
    schedule: 'http://sharemobility.com/dublin-availability-senior',
    notificationText: 'DUBLIN CONNECTOR View Schedule & Availibility',
    color: theme.GREEN,
    airtable:
      'https://airtable.com/embed/shrHSucgUwVQdLN1T?backgroundColor=green&layout=card&viewControls=on%22',
    showSchedule: true,
  },
  {
    logo: dublinLogo,
    title: 'Dublin Connector',
    subtitle: 'welcome-views.organization-welcome.dublin.connector.subtitle',
    schedule: 'http://sharemobility.com/dublin-availability-senior',
    notificationText: 'DUBLIN CONNECTOR View Schedule & Availibility',
    color: theme.GREEN,
    airtable:
      'https://airtable.com/embed/shrHSucgUwVQdLN1T?backgroundColor=green&layout=card&viewControls=on%22',
    showSchedule: false,
  },
];

const MONTHS_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const OPTIONS_FOR_MAP_FILTER = [
  { value: 'org', label: 'Organizations' },
  { value: 'bus', label: 'Bus Stops' },
  { value: 'personal', label: 'Personal' },
];

const ATTACH_PAYMENT_METHOD_LOADING = 'ATTACH_PAYMENT_METHOD_LOADING';
const GET_PAYMENT_METHODS_LOADING = 'GET_PAYMENT_METHODS_LOADING';
const DETACH_PAYMENT_METHODS_LOADING = 'DETACH_PAYMENT_METHODS_LOADING';
const GET_SQUARE_CARDS_LIST = 'GET_SQUARE_CARDS_LIST';
const ADD_SQUARE_CARD_LOADING = 'ADD_SQUARE_CARD_LOADING';
const DELETE_SQUARE_CARD_LOADING = 'DELETE_SQUARE_CARD_LOADING';
const LOADING_CREDITS_VIA_PAYMENT_METHOD = 'LOADING_CREDITS_VIA_PAYMENT_METHOD';
const LOADING_CREDITS_VIA_PAYMENT_METHOD_SUCCESS = 'LOADING_CREDITS_VIA_PAYMENT_METHOD_SUCCESS';
const RATE_RIDE_LOADING = 'RATE_RIDE_LOADING';
const SEARCH_TRIP_REQUEST_LOADING = 'SEARCH_TRIP_REQUEST_LOADING';
const SEARCH_TRIP_REQUEST_LOADING_WF = 'SEARCH_TRIP_REQUEST_LOADING_WF';
const TRIP_DETAILS_WORKFORCE_LOADING = 'TRIP_DETAILS_WORKFORCE_LOADING';
const SEARCH_FIXED_ROUTES_LOADING = 'SEARCH_FIXED_ROUTES_LOADING';
const CREATE_TRIP_REQUEST_LOADING = 'CREATE_TRIP_REQUEST_LOADING';
const MY_TRIP_LOADING = 'MY_TRIP_LOADING';
const CANCEL_TRIP_LOADING = 'CANCEL_TRIP_LOADING';
const MY_TRIPS_LOADING = 'MY_TRIPS_LOADING';
const APPLY_PROMO_CODE_TRIP_REQUEST_LOADING = 'APPLY_PROMO_CODE_TRIP_REQUEST_LOADING';
const TRIP_OPTIONS_WORKFORCE_LOADING = 'TRIP_OPTIONS_WORKFORCE_LOADING';
const TRIP_OPTIONS_NEXT_PAGE_LOADING = 'TRIP_OPTIONS_NEXT_PAGE_LOADING';
const FETCH_TRIP_DETAIL_LOADING = 'FETCH_TRIP_DETAIL_LOADING';
const RIDERSHIP_TIERS_LOADING = 'RIDERSHIP_TIERS_LOADING';
const USER_LIFETIME_STATS_LOADING = 'USER_LIFETIME_STATS_LOADING';
const EST_SHIFT_PICKUP_TIME_LOADING = 'EST_SHIFT_PICKUP_TIME_LOADING';
const PAYMENT_OPTIONS_LOADING = 'PAYMENT_OPTIONS_LOADING';

const TRIP_FILTER_TYPE_SHARE = 'share';
const TRIP_FILTER_TYPE_TRANSIT = 'transit';
const TRIP_FILTER_TYPE_TRANSIT_SHARE = 'transit connecting';

const TRANIST_ACTION_TO_STEP_MAPPING = {
  walk: 'Walk',
  transit: 'Bus',
  board: 'Wait',
};

const SPECIAL_NEEDS = [
  { label: 'special-needs.options.wheelchair', value: 'Wheelchair' },
  { label: 'special-needs.options.walker', value: 'Walker' },
  { label: 'special-needs.options.blind', value: 'Blind' },
  { label: 'special-needs.options.deaf', value: 'Deaf' },
  { label: 'special-needs.options.service-animal', value: 'Service Animal' },
  { label: 'special-needs.options.other', value: 'Other' },
];
const FEED_BACK_OPTIONS = [
  { label: 'SAFE DRIVING', value: 'Safe Driving' },
  { label: 'FRIENDLY', value: 'Friendly' },
  { label: 'CLEAN VEHICLE', value: 'Clean Vehicle' },
  { label: 'GOOD NAVIGATION', value: 'Good Navigation' },
];

const ORG_SIGNUP_IDS = process.env.REACT_APP_ORG_SIGNUP_IDS
  ? process.env.REACT_APP_ORG_SIGNUP_IDS.split(',')
  : [];

const FIRST_INDEX = 0;

const BOOKING_METHODS = {
  ORGANIZATION_PAID: 'organizationPaid',
  SELF_PAID: 'selfPaid',
  POINTS: 'points',
};

const SELF_PAYMENT_OPTIONS = {
  CARD: 'card',
  CASH: 'cash',
};

const STATE_NAMES_LONG_TO_SHORT = {
  Arizona: 'AZ',
  Alabama: 'AL',
  Alaska: 'AK',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};

const MY_TRIPS_TYPE = {
  upcoming: 'upcoming',
  past: 'past',
};

const MY_TRIPS_PAGINATION_COUNT = 10;
const WORKFORCE_TRIPS_PAGINATION_COUNT = 10;

const PASSWORDLESS_ACTIVITY = {
  rateRide: 'RATE_RIDE',
  tripDetail: 'TRIP_DETAIL',
};

const SUPPORTED_LANGUAGES = ['en', 'es', 'bs', 'rw', 'sw', 'fr', 'ne', 'pl', 'so', 'sun', 'vi', 'zh', 'ru', 'ht'];

const RIDER_TYPE = ['SENIOR', 'CHILD', 'ADULT'];
const PAYMENT_STATUS = {
  notCreated: 'Not Created',
  inProgress: 'In Progress',
  intentCreated: 'Intent Created',
  intentCreationFailed: 'Intent Creation Failed',
  intentCaptureFailed: 'Intent Capture Failed',
  intentCapture: 'Intent Captured',
  intentCancelled: 'Intent Cancelled',
};

const RIDERSHIP_TIERS_CONFIG = {
  Completed: {
    opacity: 0.5,
    width: 100,
  },
  Active: {
    opacity: 1,
    width: 100,
    barMargin: '0 20px 0 0',
  },
  InActive: {
    opacity: 0.8,
    width: 80,
    barMargin: '0 10px 0 0',
  },
};

export const RIDERSHIP_GIFT_CARD_OPTIONS = [
  'Walmart',
  'Visa',
  'Amazon',
];

export const TRIP_TYPES = {
  transit_share: 'transit_share',
  share: 'share',
};

export const MODALS_TO_SHOW_BEFORE_WORKPLACE_SELECTION = [
  '/rate-ride',
  '/terms-and-conditions',
  '/user-details',
  '/account-settings/addresses',
  '/ridership-bonus-promo',
  '/invite-friends',
];

const ALPHABETS = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

const PROMO_CODE_TYPE = {
  FIXED_ROUTES: 'FOR_FIXED_ROUTES',
  ON_DEMAND: 'FOR_ON_DEMAND',
  BOTH: 'FOR_BOTH',
};

const USER_DYNAMIC_FIELD_TYPES = {
  OPTIONAL: 'optional',
  REQUIRED: 'required',
};

const PLATFORMS = {
  STRIPE: 'STRIPE',
  SQUAREUP: 'SQUAREUP',
};

const GATEWAY_FEES = {
  [PLATFORMS.STRIPE]: {
    fee: 0.029,
    charge: 0.3,
  },
  [PLATFORMS.SQUAREUP]: {
    fee: 0.029,
    charge: 0.3,
  },
};

export default {
  FIRST_INDEX,
  SHARE_LOGO,
  SHARE_FAV_ICON,
  SHARE_WINDOW_TITLE,
  DATE_TO_ADDRESS_MAPPING,
  DAYS,
  WEEK_DAYS_LABELS,
  FREQUENCY_REPEAT_EVERY_OPTIONS,
  TRIP_OPTION_STEPS,
  ADDRESS_TYPES,
  MONTHS_NAMES,
  ORGANIZATION_WELCOME_DETAILS,
  ORGANIZATIONS_TO_WELCOME,
  MINIMAL_TO_FULL_DAY_MAPPING,
  OPTIONS_FOR_MAP_FILTER,
  ATTACH_PAYMENT_METHOD_LOADING,
  GET_PAYMENT_METHODS_LOADING,
  DETACH_PAYMENT_METHODS_LOADING,
  GET_SQUARE_CARDS_LIST,
  ADD_SQUARE_CARD_LOADING,
  DELETE_SQUARE_CARD_LOADING,
  LOADING_CREDITS_VIA_PAYMENT_METHOD,
  LOADING_CREDITS_VIA_PAYMENT_METHOD_SUCCESS,
  RATE_RIDE_LOADING,
  MY_TRIPS_LOADING,
  CANCEL_TRIP_LOADING,
  MY_TRIP_LOADING,
  SEARCH_TRIP_REQUEST_LOADING,
  TRIP_DETAILS_WORKFORCE_LOADING,
  SEARCH_FIXED_ROUTES_LOADING,
  CREATE_TRIP_REQUEST_LOADING,
  APPLY_PROMO_CODE_TRIP_REQUEST_LOADING,
  TRIP_FILTER_TYPE_SHARE,
  TRIP_FILTER_TYPE_TRANSIT,
  TRIP_FILTER_TYPE_TRANSIT_SHARE,
  TRANIST_ACTION_TO_STEP_MAPPING,
  SPECIAL_NEEDS,
  FEED_BACK_OPTIONS,
  ORG_SIGNUP_IDS,
  RESTRICT_TO_ORG_LOCATIONS,
  BOOKING_METHODS,
  SELF_PAYMENT_OPTIONS,
  STATE_NAMES_LONG_TO_SHORT,
  MY_TRIPS_TYPE,
  MY_TRIPS_PAGINATION_COUNT,
  PASSWORDLESS_ACTIVITY,
  SUPPORTED_LANGUAGES,
  PAYMENT_STATUS,
  RIDER_TYPE,
  TRIP_OPTIONS_WORKFORCE_LOADING,
  TRIP_OPTIONS_NEXT_PAGE_LOADING,
  FETCH_TRIP_DETAIL_LOADING,
  FULL_TO_MINIMAL_DAY_MAPPING,
  WORKFORCE_TRIPS_PAGINATION_COUNT,
  RIDERSHIP_TIERS_CONFIG,
  RIDERSHIP_TIERS_LOADING,
  USER_LIFETIME_STATS_LOADING,
  RIDERSHIP_GIFT_CARD_OPTIONS,
  TRIP_TYPES,
  SEARCH_TRIP_REQUEST_LOADING_WF,
  MODALS_TO_SHOW_BEFORE_WORKPLACE_SELECTION,
  ALPHABETS,
  EST_SHIFT_PICKUP_TIME_LOADING,
  PAYMENT_OPTIONS_LOADING,
  PROMO_CODE_TYPE,
  USER_DYNAMIC_FIELD_TYPES,
  PLATFORMS,
  GATEWAY_FEES,
};
