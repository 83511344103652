/** @format */

const formFields = [
  {
    name: 'password',
    label: 'reset-password.fields.password.label',
    type: 'password',
    focusNext: false,
    validator: {
      required: 'reset-password.fields.password.validation.required',
      minLength: {
        value: 4,
        message: 'reset-password.fields.password.validation.invalid',
      },
    },
  },
];

const passwordValidator = {
  required: 'reset-password.fields.password.validation.required',
  minLength: {
    value: 8,
    message: 'signup.fields.password.validation.invalid',
  },
  pattern: {
    // eslint-disable-next-line no-useless-escape
    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d`~!@#$%^&*()+=,<.>/?\[\];:'"{}\|-]{8,}$/,
    required: 'reset-password.fields.password.validation.required',
  },
};

export default {
  formFields,
  passwordValidator,
};
