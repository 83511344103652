/** @format */

import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import Slider from 'react-slick';

import { styles, theme } from '@constants';

export const Wrapper = styled.div`
  pointer-events: none;
  ${props =>
    props.sliderVisible
      ? `
      display:block;    
      animation: 1s ease-out 0s 1 slideInFromBottom forwards;      
    `
      : props.isSmall
      ? `animation: .7s ease-out 0s 1 slideDownToBottom forwards;`
      : `display: none;`}
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: ${props => props.isSmall ? '900' : '10'};
  ${props => (props.wrapperDisplay ? `` : `display: none;`)}

  @keyframes slideInFromBottom {
    from {
      transform: translateY(${styles.ORG_PLACE_CARD_HEIGHT + 20}px);
    }
    to {
      transform: translateY(0px);
    }
  }

  @keyframes slideDownToBottom {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(${styles.ORG_PLACE_CARD_HEIGHT + 20}px);
    }
  }
  }
`;

export const ButtonWrapper = styled.div`
  .btn {
    line-height: 0;
    position: absolute;
    top: 87%;
    display: block;
    z-index: 99;
    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    color: ${theme.BASE_COLOR};
    ${props => `${props?.isSmall ? 'display: none' : ``}; `}
  }

  .btn:focus {
    box-shadow: none;
  }

  .prev-btn {
    pointer-events: all;
    left: 1%;
    z-index: 11;
  }

  .next-btn {
    pointer-events: all;
    right: 1%;
    z-index: 11;
  }

`;
export const SliderComponent = styled(Slider)`
  z-index: 1;
  margin-bottom: 10px;

  .slick-list {
    padding-top: 5px;
    padding-bottom: 5px;
    
    ${props => `${props?.isSmall ? 'padding-left: 60px' : ``}; `}
    margin: ${props => (props?.isSmall ? `0;` : `0 50px 0 20px;`)}
  }

  .slick-track {
    display: flex;
    align-items: end;
  }

  .slick-slide {
    width: ${styles.ORG_PLACE_CARD_WIDTH}px;
    padding: 0 20px;
  }
`;

export const Backdrop = styled.div`
  height: 200px;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 0;
  display: none;
`;
