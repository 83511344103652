/** @format */

import React from 'react';
import PropTypes from 'prop-types';

import { Col as SCol } from './styled';

export const Col = ({
  children,
  id,
  className,
  spacingH,
  spacingV,
  flex,
  justify,
  align,
  wrap,
  fullWidth,
  fullHeight,
  colRef,
  ...restProps
}) => (
  <SCol
    ref={colRef}
    id={id}
    className={className}
    spacingH={spacingH}
    spacingV={spacingV}
    flex={flex}
    justify={justify}
    align={align}
    wrap={wrap}
    fullWidth={fullWidth}
    fullHeight={fullHeight}
    {...restProps}
  >
    {children}
  </SCol>
);

Col.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  spacingH: PropTypes.number,
  spacingV: PropTypes.number,
  flex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  justify: PropTypes.oneOf([
    'flex-start',
    'flex-end',
    'center',
    'space-between',
    'space-around',
  ]).isRequired,
  align: PropTypes.oneOf([
    'stretch',
    'center',
    'flex-start',
    'flex-end',
    'baseline',
  ]).isRequired,
  wrap: PropTypes.oneOf(['nowrap', 'wrap', 'wrap-reverse']),
  fullWidth: PropTypes.bool,
  fullHeight: PropTypes.bool,
  colRef: PropTypes.object,
};

Col.defaultProps = {
  id: '',
  className: '',
  spacingH: 0,
  spacingV: 0,
  flex: 1,
  wrap: 'nowrap',
  fullWidth: true,
  fullHeight: false,
  colRef: null,
  children: null,
};

export default Col;
