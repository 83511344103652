/** @format */

import styled from 'styled-components';

export const CardsPositionOnLarge = styled.div`
  position: absolute;
  width: 370px;
  overflow-y: auto;
  z-index: 10;
  top: 200px;
  height: calc(100vh - 200px);
`;

export const CardWrapper = styled.div`
  margin: 15px;
  width: 370px;
  border-radius: 10px;
`;
