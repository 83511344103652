/** @format */

import styled from 'styled-components';

import { Col } from '@components';
import { device } from '@constants';

export const TripRequestFormCol = styled(Col)`
  min-height: 370px;

  @media ${device.mediaQuery.SMALL_SCREEN} {
    padding-top: 30px;
  }
`;
