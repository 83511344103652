/** @format */

import styled from 'styled-components';

import { Row } from '@components';
import { device } from '@constants';

export const MapContainer = styled(Row)`
  height: 100%;
  @media ${device.mediaQuery.SMALL_SCREEN} {
    width: 100%;
  }

  @media ${device.mediaQuery.LARGE_SCREEN} {
    margin-top: 5px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`;
