/** @format */

import styled from 'styled-components';
import { theme, device } from '@constants';
import { Col } from '..';

export const Wrapper = styled(Col)`
  background-color: ${theme.WHITE};
  height: 100%;
  display: block;
  z-index: 20;
  @media ${device.mediaQuery.LARGE_SCREEN} {
    margin-top: -10px;
  }
`;

export const Title = styled.span`
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: ${theme.BASE_COLOR};
  @media ${device.mediaQuery.LARGE_SCREEN} {
    font-size: 22px;
  }
`;

export const SubTitle = styled.span`
  font-size: 18px;
  color: ${theme.BASE_COLOR};
  font-weight: bold;
  text-align: center;
  @media ${device.mediaQuery.LARGE_SCREEN} {
    font-size: 18px;
  }
`;

export const Image = styled.img`
  width: 180px;
  height: 180px;
`;

export const OuterImage = styled.img`
  width: 200px;
  height: 200px;
  z-index: 10;
  position: relative;
  left: 0;
  top: 0;
`;

export const ImageWrapper = styled.div`
  width: 200px;
  height: 200px;
  position: relative;
  left: 0;
  top: 0;
`;

export const OuterWrapper = styled.div`
  display: block;
  padding: 10px;
`;
