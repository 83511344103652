/** @format */

import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { theme } from '@constants';
import { Row } from '..';

export const PaginationRow = styled(Row)`
  .pagination {
    margin: 16px 0;

    li:not(.break-me) {
      width: 32px;
      height: 32px;
      border-radius: 100px;
      border: 1px solid ${theme.BASE_COLOR};
      margin: 0 2px;
      color: ${theme.BASE_COLOR};

      a {
        width: 30px;
        height: 30px;
        line-height: 16px;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover,
        &:active {
          outline: none;
        }
      }

      &.active {
        background-color: ${theme.BASE_COLOR};
        color: ${theme.WHITE};
      }

      &.disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }

    li.break-me {
      margin: 0 2px;
      pointer-events: none;
    }
  }
`;

export const PrevNextIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
`;
