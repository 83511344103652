/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
import React, { useRef, useState } from 'react';
import { faSpinner, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { theme, generals as generalConstants } from '@constants';
import {
  AddCardButton,
  PaymentMethodsCol,
  LoaderWrapperRow,
  LoaderIcon,
  PaymentMethodsTop,
  PaymentMethodsBottomRow,
  Title,
  AddedPaymentMethodsCol,
  AddedPaymentMethod,
  AddedPaymentMethodLabel,
  AddedPaymentMethodText,
  NoPaymentMethodsText,
  CardName,
  CardNameInput,
  CardInputLabel,
  AddCardCol,
  SaveButton,
  DeleteIcon,
  CloseButton,
} from './styled';
import SquareUpCard from '../squareUpCard';
import { Row } from '..';

export default function SquarePaymentForm(props) {
  const [cardholderName, setCardHolderName] = useState('');
  const [card, setCard] = useState(null);
  const cardHolderRef = useRef('');
  cardHolderRef.current = cardholderName;

  const {
    selectedOrganization,
    addedPaymentMethods,
    loading,
    deletePaymentMethodId,
    onChangeDeletePaymentMethodId,
    openConfirmationDialog,
    goBack,
    createSquareCard,
  } = props;

  const paymentMethodsExist =
    addedPaymentMethods && addedPaymentMethods.length > 0;
  const canAddPaymentMethods = Array.isArray(addedPaymentMethods)
    ? addedPaymentMethods.length < 10
    : true;
  const { t } = useTranslation();

  const createPaymentMethodSquare = async () => {
    const { token } = await card.tokenize();
    createSquareCard({ sourceId: token, cardholderName });
    setCardHolderName('');
  };

  return (
    <PaymentMethodsCol id="payment-method-column" justify="space-between" align="center">
      {loading === generalConstants.GET_SQUARE_CARDS_LIST || loading === generalConstants.PAYMENT_OPTIONS_LOADING ? (
        <PaymentMethodsCol justify="space-between" align="center">
          <LoaderWrapperRow
            id="get-payment-methods-loader"
            justify="center"
            align="center"
          >
            <LoaderIcon id="get-payment-loader" icon={faSpinner} spin />
          </LoaderWrapperRow>
        </PaymentMethodsCol>
      ) : (
        <>
          <PaymentMethodsTop>
            <Title>{t('trip-overview.payment-methods.title')}</Title>

            <AddedPaymentMethodsCol
              id="added-payment-methods"
              justify="flex-start"
              align="flex-start"
              canAddPaymentMethods={canAddPaymentMethods ? 1 : 0}
            >
              {addedPaymentMethods.map((addedPaymentMethod, i) => (
                <Row
                  align="center"
                  justify="space-between"
                  key={addedPaymentMethod.id}
                  onClick={() => props.setSelected(i)}
                >
                  <AddedPaymentMethod
                    className="added-payment-method"
                    key={addedPaymentMethod.id}
                  >
                    <AddedPaymentMethodLabel>
                      {addedPaymentMethod.cardBrand.toUpperCase()}
                    </AddedPaymentMethodLabel>
                    <AddedPaymentMethodText>
                      {t('payments.labels.ending-in')}{' '}
                      {addedPaymentMethod.last4}
                    </AddedPaymentMethodText>
                  </AddedPaymentMethod>
                  <AddedPaymentMethod>
                    <AddedPaymentMethodLabel>
                      {t('payments.labels.expiry')}
                    </AddedPaymentMethodLabel>
                    <AddedPaymentMethodText>
                      {addedPaymentMethod.expMonth}/{addedPaymentMethod.expYear}
                    </AddedPaymentMethodText>
                  </AddedPaymentMethod>

                  {loading === generalConstants.DELETE_SQUARE_CARD_LOADING &&
                  deletePaymentMethodId === addedPaymentMethod.id ? (
                    <LoaderIcon icon={faSpinner} spin />
                  ) : (
                    <DeleteIcon
                      id="delete-payment"
                      onClick={() => {
                        onChangeDeletePaymentMethodId(addedPaymentMethod.id);
                        openConfirmationDialog();
                      }}
                      icon={faMinusCircle}
                    />
                  )}
                </Row>
              ))}

              {!paymentMethodsExist && (
                <NoPaymentMethodsText>
                  {t('payments.messages.not-found')}
                </NoPaymentMethodsText>
              )}
            </AddedPaymentMethodsCol>

            {canAddPaymentMethods ? (
              <AddCardCol
                justify="flex-start"
                align="flex-start"
                key={addedPaymentMethods.length}
              >
                <div style={{ width: '100%' }}>
                  <div id="form-container">
                    <div id="sq-ccbox">
                      <p>
                        <AddCardButton>
                          {t('payments.buttons.add-new-card')}
                        </AddCardButton>
                      </p>
                      <CardName>
                        <CardInputLabel>
                          {t('payments.labels.name-on-card')}
                        </CardInputLabel>
                        <CardNameInput
                          id="card-name"
                          data-cy="credit-card-detail"
                          label="John Doe"
                          name="name"
                          value={cardholderName}
                          onChange={event => {
                            setCardHolderName(event.target.value);
                          }}
                          color={theme.BASE_COLOR}
                          floating={false}
                          inverted
                        />
                      </CardName>
                      <SquareUpCard
                        selectedOrganization={selectedOrganization}
                        onCardInitialization={setCard}
                        key={`${addedPaymentMethods}`}
                      />
                    </div>
                  </div>
                </div>
              </AddCardCol>
            ) : null}
          </PaymentMethodsTop>
          <PaymentMethodsBottomRow justify="space-between" align="flex-end">
            <CloseButton
              id="back-btn"
              onClick={goBack}
              color={theme.BASE_COLOR}
              inverted
              bordered
              label={t('payments.buttons.back')}
            />
            <SaveButton
              id="save-btn"
              data-cy="credit-card-detail"
              label={t('payments.buttons.save')}
              color={theme.BASE_COLOR}
              onClick={createPaymentMethodSquare}
              bordered
              size="md"
              icon={loading ? faSpinner : null}
              iconProps={{ spin: true }}
              disabled={
                (!canAddPaymentMethods ||
                cardholderName.length <= 2 ||
                loading === generalConstants.ADD_SQUARE_CARD_LOADING)
                && card !== null
              }
            />
          </PaymentMethodsBottomRow>
        </>
      )}
    </PaymentMethodsCol>
  );
}
