/** @format */
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { theme, device } from '@constants';
import ReactTooltip from 'react-tooltip';
import { Row, Col} from '..';

export const TripsCol = styled(Col)`
  position: relative;
  min-height: calc(100% - 80px);

  @media ${device.mediaQuery.SMALL_SCREEN} {
    min-height: calc(100% - 49px);
  }
`;

export const TitleRow = styled(Row)`
  margin-bottom: 14px;
`;

export const Title = styled.h4`
  color: ${theme.BASE_COLOR};
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 5px;
`;

export const TripsWrapper = styled.div`
  background-color: ${theme.WHITE};
  width: 100%;
  padding: 30px 30px 55px 30px;

  @media ${device.mediaQuery.SMALL_SCREEN} {
    padding: 15px 15px 55px 15px;
  }
`;

export const Message = styled.span`
  color: ${theme.BASE_COLOR};
  text-align: center;
  margin-bottom: 15px;
`;

export const LoaderWrapperRow = styled(Row)`
  height: 100%;
`;

export const LoaderIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
`;

export const Tooltip = styled(ReactTooltip)`
  &.show {
    opacity: 1 !important;
    width: 250px;
  }
`;

export const TooltipWrapper = styled.div`
  padding: 0;
  margin: 0;
  width: 100%;
`;