/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col } from '@components';
import { theme } from '@constants';
import { Popup, PopupText } from './styled';

export const ConfirmModal = ({ close, submit, text }) => {
  const { t } = useTranslation();
  const textContent = typeof text === 'string' ? text : null;

  return (
    <Popup width="350" toggle={close}>
      <Col justify="center" align="center" spacingV={20} spacingH={20}>
        <Row justify="center" align="center">
          <PopupText id="popup-text">{textContent || t('common.sure')}</PopupText>
        </Row>
        <Row justify="center" align="center">
          <Col justify="center" align="center" spacingH={10}>
            <Button
              data-cy="confirm-no"
              id="confirm-no-btn"
              label={t('common.no')}
              size="md"
              color={theme.BASE_COLOR}
              onClick={close}
            />
          </Col>
          <Col justify="center" align="center" spacingH={10}>
            <Button
              data-cy="confirm-yes"
              id="confirm-delete"
              label={t('common.yes')}
              size="md"
              color={theme.BASE_COLOR}
              onClick={submit}
            />
          </Col>
        </Row>
      </Col>
    </Popup>
  );
};

ConfirmModal.propTypes = {
  close: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  text: PropTypes.string,
};

ConfirmModal.defaultProps = {
  text: null,
};

export default ConfirmModal;
