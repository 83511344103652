/** @format */

import styled from 'styled-components';

import { Col } from '@components';
import { theme, styles } from '@constants';

export const HomeCol = styled(Col)`
  z-index: 1;
  position: absolute;
  bottom: 0;

  ${props =>
    props.isVisible
      ? `
      animation: ${
        styles.ANIM_PLACE_CARD_SLIDE_UP_TIME / 1000
      }s ease-out 0s 1 slideInFromBottom forwards;      
    `
      : `
      animation: ${
        styles.ANIM_PLACE_CARD_SLIDE_UP_TIME / 1000
      }s ease-out 0s 1 slideDownToBottom forwards;
    `}

  ${props => (props?.homeDisplay ? `` : `display: none;`)}  

  @keyframes slideInFromBottom {
    from {
      transform: translateY(${styles.ORG_PLACE_CARD_HEIGHT}px);
    }
    to {
      transform: translateY(0px);
    }
  }

  @keyframes slideDownToBottom {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(${styles.ORG_PLACE_CARD_HEIGHT}px);
    }
  }
`;

export const TopWrapperCol = styled(Col)`
  background-color: ${theme.WHITE};
`;

export const BottomWrapperCol = styled(Col)`
  height: 160px;
  background-color: ${theme.WHITE};
`;
