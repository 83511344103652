/** @format */

import certifiedRibbon from '@assets/images/certified-ribbon.png';
import stopwatch from '@assets/images/stopwatch.png';

const formFields = [
  {
    name: 'email',
    label: 'welcome-views.aaa-roundtown.service-area-map.response.not-in-area.fields.email.placeholder',
    type: 'email',
    focusNext: false,
    validator: {
      required: 'welcome-views.aaa-roundtown.service-area-map.response.not-in-area.fields.email.validation.required',
      pattern: {
        value:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'welcome-views.aaa-roundtown.service-area-map.response.not-in-area.fields.email.validation.invalid',
      },
    },
  },
];

export default {
  formFields,
  certifiedRibbon,
  stopwatch,
};
