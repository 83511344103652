/** @format */

import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Badge } from '@components';
import { helpers } from '@utils';
import {
  TripPathItemRow,
  TripPathItemIcon,
  TripPathItemTitle,
  TripPathItemTime,
  TripPathItemAddressWrapper,
  TripPathItemName,
  TripPathItemAddress,
  TripPathItemTextSmall,
  TripPathItemBadgeDescription,
} from './styled';

export const PathItem = ({
  title,
  time,
  name,
  address,
  text,
  icon,
  iconRotation,
  pathItemActionType,
  badge,
}) => {
  const boundary = title ? 1 : 0;
  const busItem = pathItemActionType === 'depart';
  const streetAddress = helpers.getStreetAddress(address);
  const lastRow = icon.iconName === 'check-circle';
  return (
    <TripPathItemRow
      justify="flex-start"
      align="flex-start"
      boundary={1}
      busItem={busItem ? 1 : 0}
      lastRow={lastRow}
    >
      {title && <TripPathItemTitle boundary={1}>{title}</TripPathItemTitle>}
      <TripPathItemIcon
        icon={icon}
        rotation={iconRotation ? 180 : 0}
        boundary={boundary}
      />
      {time && <TripPathItemTime boundary={boundary}>{time}</TripPathItemTime>}
      <TripPathItemAddressWrapper
        pathItemActionType={pathItemActionType ? 1 : 0}
        noDatetime={!time}
      >
        <Col flex="initial" justify="flex-start" align="flex-start">
          {name && <TripPathItemName boundary={1}>{name}</TripPathItemName>}
          <TripPathItemAddress boundary={1}>
            {helpers.truncateStringWithEllipses(streetAddress, 75)}
          </TripPathItemAddress>

          {badge && (
            <Row flex="initial" justify="flex-start" align="center">
              <Badge>{badge.value}</Badge>
              <TripPathItemBadgeDescription>
                {badge.description}
              </TripPathItemBadgeDescription>
            </Row>
          )}
          {text && <TripPathItemTextSmall>{text}</TripPathItemTextSmall>}
        </Col>
      </TripPathItemAddressWrapper>
    </TripPathItemRow>
  );
};

PathItem.propTypes = {
  title: PropTypes.string,
  time: PropTypes.string,
  name: PropTypes.string,
  address: PropTypes.string.isRequired,
  text: PropTypes.string,
  icon: PropTypes.object.isRequired,
  iconRotation: PropTypes.bool,
  pathItemActionType: PropTypes.oneOf(['depart', 'walk', 'board']),
  badge: PropTypes.shape({
    value: PropTypes.string,
    description: PropTypes.string,
  }),
};

PathItem.defaultProps = {
  title: null,
  time: null,
  text: null,
  name: null,
  iconRotation: false,
  pathItemActionType: null,
  badge: null,
};

export default PathItem;