/** @format */

import styled from 'styled-components';
import { theme } from '@constants';
import ReactTooltip from 'react-tooltip';
import { styles } from '@utils';
import { Input } from '..';

export const PickerWrapper = styled.div`
  border-radius: 5px;
  box-shadow: 0 0 4px 0 ${styles.hexToRgba('#000', 0.16)};
  background-color: ${theme.WHITE};
  padding: 20px;
  width: 100%;
  margin: 10px 0;
`;

export const DayItem = styled.li`
  list-style-type: none;
  cursor: pointer;
  text-align: center;
  padding: 0 6px;
  display: block;

  ${props => 
    props.selected && `
      background: ${theme.BASE_COLOR};
      color: ${theme.WHITE};
    `
  }

  ${props =>
    props.disabled && `
      color: ${theme.LIGHT_GREY};
    `
  }
`;

export const TextSmall = styled.small`
  color: ${theme.BASE_COLOR};
  font-weight: bold;
  font-size: 16px;
  padding-right: 10px;
`;

export const UntillText = styled(TextSmall)`
  width: 40%;
`;

export const Select = styled.select`
  background: ${theme.BASE_COLOR};
  font-size: 12px;
  color: ${theme.WHITE};
`;

export const RdtUntilDatePickerWrapper = styled.div`
  position: absolute;
  background: ${theme.WHITE};
  box-shadow: 0 1px 3px ${styles.hexToRgba('#000', 0.1)};
  border: 1px solid #f9f9f9;
  min-width: 250px;
  top: -250px;
`;

export const DateInput = styled(Input)`
  min-height: 25px;
`;

export const Tooltip = styled(ReactTooltip)`
  &.show {
    opacity: 1 !important;
  }
  width: 200px;
`;
