/** @format */

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { theme } from '@constants';
import { styles as styleUtils } from '@utils';

const size = {
  sm: {
    fontSize: 10,
    height: 22,
    borderWidth: 1,
  },
  md: {
    fontSize: 12,
    height: 38,
    borderWidth: 2,
  },
  lg: {
    fontSize: 16,
    height: 44,
    borderWidth: 2,
  },
};

export const Button = styled.button`
  ${styleUtils.flex('center', 'center')}
  height: ${props => size[props.size].height}px;
  width: 100%;
  font-size: ${props => size[props.size].fontSize}px;
  line-height: ${props => size[props.size].fontSize - 2}px;
  border-radius: 100px;
  color: ${props => (props.inverted ? props.color : theme.WHITE)};
  background-color: ${props => (props.inverted ? theme.WHITE : props.color)};
  border: ${props => {
    return props.bordered
      ? `${size[props.size].borderWidth}px solid ${props.color}`
      : 'none';
  }};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  padding: 0;

  &:focus {
    outline: none;
  }
`;

export const ButtonIcon = styled(FontAwesomeIcon)`
  margin-right: ${props => (props.label ? 10 : 0)}px;
`;
