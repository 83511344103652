/** @format */

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { device, theme } from '@constants';
import { Row, Col, Button } from '..';

export const TripDetailCol = styled(Col)`
  @media ${device.mediaQuery.LARGE_SCREEN} {
    min-height: 100%;
  }

  .react-add-to-calendar {
    width: 100%;
  }

  .react-add-to-calendar__button {
    display: block;
    color: ${theme.WHITE};
    border: none;
    background-color: ${theme.BASE_COLOR};
    border-radius: 20px;
    font-size: 14px;
    padding: 10px 0px;
    white-space: nowrap;
    position: relative;
    z-index: 6;
    text-align: center;

    :hover {
      text-decoration: none;
    }
  }

  .react-add-to-calendar__button {
    font-size: 12px;
  }

  .react-add-to-calendar__dropdown {
    padding: 0;
    margin: 0 10px 15px 12px;
    left: initial;
    top: initial;
    z-index: 5;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
    background-color: ${theme.WHITE};
    border: none;
    top: -196px;
    width: 90%;

    ul {
      padding: 0;
      li {
        padding: 8px 10px;
        color: ${theme.BASE_COLOR};
      }
    }
  }
`;

export const Title = styled.span`
  color: ${theme.BASE_COLOR};
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0;
  margin-left: 5px;
  margin-top: 10px;
`;

export const TripTimeTitle = styled.span`
  color: ${theme.BASE_COLOR};
  font-size: 10px;
  line-height: 8px;
`;

export const TripTimeValue = styled.span`
  color: ${theme.BASE_COLOR};
  font-weight: 600;
  font-size: 14px;
`;

export const TripDetailsRow = styled(Row)`
  border-top: 1px solid ${theme.BASE_COLOR};
`;

export const BackButton = styled(Button)`
  margin-top: 20px;
  width: ${props => (props.fullWidth ? '100%' : '100px')};
  margin-right: ${props => (props.fullWidth ? 0 : 10)}px;
`;

export const StyledButton = styled(Button)`
  margin-top: 20px;
`;

export const TextSmall = styled.span`
  font-size: 12px;
  color: ${theme.BASE_COLOR};
  margin: 0;
  text-transform: uppercase;
  font-weight: ${props => (props.weight ? props.weight : 'bold')};
`;

export const EditBtn = styled(Button)`
  width: 7%;
  background: transparent;
  color: ${theme.BASE_COLOR};
  font-size: 18px;
  margin-right: 15px;
`;

export const ImageCol = styled(Col)`
  max-width: 50px;
`;

export const ImageRounded = styled.img`
  max-width: 50px;
  max-height: 50px;
  border-radius: 50px;
  margin-left: 5px;
`;

export const RouteInfoRow = styled(Row)`
  border-top: 1px solid ${theme.BASE_COLOR};
`;

export const TripNotFoundMessage = styled.span`
  color: ${theme.TOMATO};
  font-size: 16px;
  margin-bottom: 10px;
`;

export const LoaderWrapperRow = styled(Row)`
  height: 100%;
`;

export const LoaderIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
`;

export const CalenderCol = styled(Col)`
  margin-top: 20px;
  width: calc(100% - 100px);
`;

export const MinimizableCol = styled(Col)`
  overflow-y: hidden;
  max-height: ${props => (props.minimized ? '0px' : '100vh')};
  transition: max-height 200ms linear 0s;
`;

export const AdditionalInfoTitle = styled.span`
  color: ${props => props.color || theme.BASE_COLOR};
  font-weight: 600;
`;

export const AdditionalInfoValue = styled.span`
  color: ${props => props.color || theme.BASE_COLOR};
`;
