/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col } from '@components';
import { theme } from '@constants';
import { WrapperRow, DropdownCol, LabelSmall, WorkplaceSelectDropdown } from './styled';

export const WorkplaceDropdownWorkforce = ({
  workplaces,
  onChangeWorkplace,
  selectedWorkplace,
}) => {
  const { t } = useTranslation();
  const options = workplaces.map(x => {
    return {
      value: `${x.orgPlaceId}`,
      label: x.name,
    };
  });

  const onDropdownSelect = option => {
    onChangeWorkplace(option.value);
  };

  const renderDropdown = () => (
    <DropdownCol justify="flex-start" align="flex-start">
      <Row
        data-cy="workplace-options-dropdown-row"
        id="options"
        align="center"
        justify="center"
      >
        <WorkplaceSelectDropdown
          data-cy="workplace-options-dropdown"
          id="workplace-options-dropdown"
          placeholder={t('profile.fields.workplace.placeholder')}
          options={options}
          onChange={onDropdownSelect}
          value={
            options.length &&
            selectedWorkplace &&
            options.find(o => o.value === selectedWorkplace)
            ? selectedWorkplace
            : null
          }
          color={theme.BASE_COLOR}
          inverted
          bordered
          rounded
          size="sm"
          height="50px"
        />
      </Row>
    </DropdownCol>
  );

  return (
    <Col align="flex-start" justify="flex-start" zIndex="1" spacingV={0}>
      <LabelSmall>{t('profile.labels.default-workplace')}</LabelSmall>
      <WrapperRow justify="flex-start" align="flex-start">
        {renderDropdown()}
      </WrapperRow>
    </Col>
  );
};

WorkplaceDropdownWorkforce.propTypes = {
  workplaces: PropTypes.array.isRequired,
  onChangeWorkplace: PropTypes.func.isRequired,
  selectedWorkplace: PropTypes.string.isRequired,
};

export default WorkplaceDropdownWorkforce;
