/** @format */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ServiceAreaMap as CServiceAreaMap } from '@components';
import {
  checkZipCodeExists,
  fetchSignupOrganizationsPlaces,
  submitRequestForZipCode,
  setSignupOrgServiceAreaForm,
  signupOrgServiceAreaResetSignupForm,
  signupOrgServiceAreaResetFormState,
  updateSignupOrgServiceAreaOpenDialog,
} from '@slices/app';
import { useServiceAreaDialog } from '@hooks';
import { Map } from '@containers';
import { MapContainer } from './styled';

const ServiceAreaMap = ({ isLargeScreen, toggle, setCustomClose }) => {
  const dispatch = useDispatch();

  const state = useSelector(store => store.app.signupOrgServiceArea);

  const { ServiceAreaDialog, openServiceAreaDialog } =
    useServiceAreaDialog(isLargeScreen);

  const {
    register: registerZipCode,
    trigger: triggerZipCode,
    errors: errorsZipCode,
    formState: { isValid: isValidZipCode },
  } = useForm({
    mode: 'onBlur',
  });

  const {
    register: registerEmail,
    trigger: triggerEmail,
    errors: errorsEmail,
    formState: { isValid: isValidEmail },
  } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    dispatch(fetchSignupOrganizationsPlaces());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleServiceAreaDialog();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.dialogOpened]);

  useEffect(() => {
    setCustomClose(true);
    return () => {
      setCustomClose(false);
    };
  }, [setCustomClose]);

  const onSubmitZipCode = () => {
    dispatch(checkZipCodeExists());
  };

  const onChangeZipCode = async e => {
    const { name, value } = e.target;

    dispatch(
      setSignupOrgServiceAreaForm({
        name,
        value,
      }),
    );

    await triggerZipCode(name);
  };

  const onChangeEmail = async e => {
    const { name, value } = e.target;

    dispatch(
      setSignupOrgServiceAreaForm({
        name,
        value,
      }),
    );

    await triggerEmail(name);
  };

  const onSubmitRequestedEmail = () => {
    if (isValidEmail) {
      dispatch(submitRequestForZipCode());
    }
  };

  const onCloseModal = () => {
    dispatch(signupOrgServiceAreaResetFormState('email'));
    dispatch(updateSignupOrgServiceAreaOpenDialog(false));
  };

  const onCloseMainDialog = () => {
    dispatch(signupOrgServiceAreaResetSignupForm());
    toggle();
  };

  const handleServiceAreaDialog = () => {
    if (state.dialogOpened && state.zipCodeFound) {
      openServiceAreaDialog(state.dialogOpened);
    } else if (state.dialogOpened && !state.zipCodeFound) {
      openServiceAreaDialog(state.dialogOpened);
    } else {
      openServiceAreaDialog(state.dialogOpened);
    }
  };

  const renderServiceAreaMap = () => {
    return (
      <MapContainer
        justify="flex-start"
        align="stretch"
      >
        <Map isServiceAreaMap />
      </MapContainer>
    );
  };

  return (
    <>
      <CServiceAreaMap
        onSubmitZipCode={onSubmitZipCode}
        onChange={onChangeZipCode}
        zipCode={state.formState.zipCode}
        errors={errorsZipCode}
        validate={registerZipCode}
        toggle={onCloseMainDialog}
        loading={state.loading}
        valid={isValidZipCode}
        renderServiceAreaMap={renderServiceAreaMap}
      />
      <ServiceAreaDialog
        email={state.formState.email}
        loading={state.loading}
        locationFound={state.zipCodeFound}
        valid={isValidEmail}
        validate={registerEmail}
        onChange={onChangeEmail}
        onSubmitEmail={onSubmitRequestedEmail}
        onCloseModal={onCloseModal}
        errors={errorsEmail}
      />
    </>
  );
};

ServiceAreaMap.propTypes = {
  isLargeScreen: PropTypes.number,
  toggle: PropTypes.func.isRequired,
  setCustomClose: PropTypes.func.isRequired
};

export default ServiceAreaMap;
