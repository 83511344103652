/** @format */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal, TermsAndConditions } from '@components';
import {
  logout,
  update,
  setAccountSettingsAndDynamicFields,
  checkAndNavigateToRidershipBonusLandingPage,
} from '@slices/auth';

const TermsConditions = () => {
  const history = useHistory();
  const { user } = useSelector(store => store.app);
  const [isOpen, setIsOpen] = useState(true);

  const { loading } = useSelector(store => store.myTrips);
  const { largeScreen } = useSelector(store => store.app);

  const dispatch = useDispatch();

  const onSubmit = termsAndConditions => {
    dispatch(update({ termsAndConditions }, history));
    setIsOpen(false);
    dispatch(checkAndNavigateToRidershipBonusLandingPage(history, '/', true));
  };

  const onCloseModal = () => {
    dispatch(logout());
  };

  useEffect(() => {
    if (user) {
      dispatch(setAccountSettingsAndDynamicFields(user));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user]);

  return isOpen ? (
    <Modal
      open
      width={largeScreen ? '556' : null}
      overflow="visible"
    >
      <TermsAndConditions
        loading={loading}
        onCloseModal={onCloseModal}
        onSubmit={termsAndConditions => onSubmit(termsAndConditions)}
      />
    </Modal>
  ) : null;
};

export default TermsConditions;
