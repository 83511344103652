/** @format */

import React from 'react';
import { PropTypes } from 'prop-types';

import { CardWrapper, CardsPositionOnLarge } from './styled';
import FixedRouteCardLayout from '../fixedRouteCardLayout';

const FixedRouteCardsLarge = ({ fixedRouteResults, selectFixedRouteResult, largeScreen, selectedResult }) => {
  return (
    <CardsPositionOnLarge id="fixed-route-public-map-card-large">
      {fixedRouteResults.map((data, index) => {
        return (
          <CardWrapper
            key={index}
            id={`fixed-route-public-map-card-large-${index}`}
            onClick={() => selectFixedRouteResult(index)}
          >
            <FixedRouteCardLayout result={data} largeScreen={largeScreen} selected={index === selectedResult} />
          </CardWrapper>
          );
      })}
    </CardsPositionOnLarge>
  );
};

FixedRouteCardsLarge.propTypes = {
  fixedRouteResults: PropTypes.array.isRequired,
  selectFixedRouteResult: PropTypes.func.isRequired,
  largeScreen: PropTypes.bool.isRequired,
};

export default FixedRouteCardsLarge;
