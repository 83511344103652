/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import closeIcon from '@assets/icons/close.svg';

import { Row } from '@components';
import { StyledImage, ImgWrapper } from './styled';

const IconButton = ({ width, onClick, icon, ...restProps }) => {
  return (
    <ImgWrapper id="icon-button" width={width} onClick={onClick} {...restProps}>
      <Row justify="center" align="center">
        <StyledImage className="styled-icon" src={icon} width={width} />
      </Row>
    </ImgWrapper>
  );
};

IconButton.propTypes = {
  width: PropTypes.number,
  onClick: PropTypes.func,
  icon: PropTypes.string,
};

IconButton.defaultProps = {
  width: 20,
  icon: closeIcon,
  onClick: null,
};

export default IconButton;
