/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { theme } from '@constants';
import { Row } from '..';
import { CloseButton, Wrapper } from './styled';

const OrganizationSchedule = ({ orgDetails, goToHome }) => {
  return (
    <>
      <Row flex="initial" align="flex-end" justify="flex-end" spacingH={10}>
        <CloseButton
          id="close-schedule"
          onClick={goToHome}
          icon={faTimes}
          color={theme.BASE_COLOR}
          size="lg"
          inverted
          label=""
        />
      </Row>
      <Wrapper>
        <iframe
          title={orgDetails.title}
          className="airtable-embed"
          src={orgDetails.airtable}
          frameBorder="0"
          width="100%"
          height="100%"
        />
      </Wrapper>
    </>
  );
};

OrganizationSchedule.propTypes = {
  orgDetails: PropTypes.object.isRequired,
  goToHome: PropTypes.func.isRequired,
};

export default OrganizationSchedule;
