/** @format */

import styled from 'styled-components';

import { theme } from '@constants';
import { Col } from '..';

export const NotificationCol = styled(Col)`
  width: calc(100% - 30px);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  background-color: ${props => (props.isError ? 'theme.TOMATO' : theme.GREEN)};
  color: ${theme.WHITE};
  border-radius: 40px;
  z-index: 9;
`;

export const NotificationTitle = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: ${theme.WHITE};
  margin-bottom: 5px;
`;

export const NotificationText = styled.span`
  font-size: 10px;
  color: ${theme.WHITE};
  text-align: center;
`;
