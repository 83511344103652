/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col } from '@components';
import { theme } from '@constants';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { WrapperRow, Image, LabelSmall, StyledButton, Container, SubContainer, ImageContainer } from './styled';

export const QRCode = ({ authQRCode, downloadQR, printQR, generateQR, loading, largeScreen }) => {
  const { t } = useTranslation();

  const renderQR = () => (
    <Container>
      <ImageContainer id="qr-code-img">
        <Image src={authQRCode} data-cy="qr-code-image" alt="QR Code" />
      </ImageContainer>
      <SubContainer>
        <StyledButton
          color={theme.BASE_COLOR}
          label={t('profile.buttons.qr.download')}
          data-cy="download-qr-button"
          onClick={downloadQR}
        />
        {largeScreen && (
          <StyledButton
            label={t('profile.buttons.qr.print')}
            color={theme.BASE_COLOR}
            data-cy="print-qr-button"
            onClick={printQR}
            icon={loading ? faSpinner : null}
            disabled={loading}
          />
        )}
      </SubContainer>
    </Container>
  );

  return (
    <Col align="flex-start" justify="flex-start" zIndex="0" spacingV={0}>
      {!authQRCode ? (
        <WrapperRow justify="flex-start" align="flex-start">
          <LabelSmall margin="auto">{t('profile.labels.qr')}</LabelSmall>
          <StyledButton
            label={t('profile.buttons.qr.generateQr')}
            color={theme.BASE_COLOR}
            margin="25"
            width="70"
            data-cy="generate-qr-button"
            onClick={generateQR}
            icon={loading ? faSpinner : null}
            disabled={loading}
          />
        </WrapperRow>
      ) : (
        <>
          <LabelSmall margin="0">{t('profile.labels.qr')}</LabelSmall>
          <WrapperRow justify="flex-start" align="flex-start">
            {renderQR()}
          </WrapperRow>
        </>
      )}
    </Col>
  );
};

QRCode.propTypes = {
  downloadQR: PropTypes.func.isRequired,
  generateQR: PropTypes.func.isRequired,
  printQR: PropTypes.func.isRequired,
  authQRCode: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  largeScreen: PropTypes.bool.isRequired,
};

QRCode.defaultProps = {
  authQRCode: null,
};
export default QRCode;
