/** @format */

import React, { useState, useRef } from 'react';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import angleRightIcon from '@assets/icons/angle-right.svg';
import angleLeftIcon from '@assets/icons/angle-left.svg';

import { theme } from '@constants';
import { Slider, Row, CallUs } from '@components';
import { ServiceAreaMap as CServiceAreaMap } from '@containers';
import {
  SliderItemCol,
  Image,
  ContentCol,
  Title,
  TextContent,
  ServiceAreaMap,
  ServiceAreaMapIcon,
  ServiceAreaMapText,
  ReadyToRide,
  ReadyToRideText,
  StyledButton,
  SliderArrowButton,
  SliderDot,
} from './styled';
import { aaaRoundTownWelcomeViews } from '../config';

const sliderProps = {
  dots: false,
  swipe: true,
  arrows: false,
  swipeToSlide: true,
  adaptiveHeight: true,
};

const AAARoundTownSlider = ({
  largeScreen,
  onClickCreateAccount,
  setModalHeightWidth,
  setCustomClose,
}) => {
  const { t } = useTranslation();
  const sliderRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [isCallUsView, setIsCallUsView] = useState(false);
  const [isServiceAreaMapView, setIsServiceAreaMapView] = useState(false);

  const spacing = largeScreen ? 25 : 20;
  const isLargeScreen = largeScreen ? 1 : 0;

  sliderProps.beforeChange = (_current, next) => {
    setActiveSlide(next);
  };

  const toggleCallUsView = () => {
    if (isCallUsView) {
      setModalHeightWidth({
        width: false,
        height: false,
      });
    } else {
      setModalHeightWidth({
        width: '430',
        height: null,
      });
    }
    setIsCallUsView(!isCallUsView);
  };
  const toggleServiceAreaMapView = () => {
    if (isServiceAreaMapView) {
      setModalHeightWidth({
        width: false,
        height: false,
      });
    } else {
      setModalHeightWidth({
        width: '580',
        height: null,
      });
    }
    setIsServiceAreaMapView(!isServiceAreaMapView);
  };

  const renderSlickDots = () => {
    if (largeScreen) {
      return null;
    }

    return (
      <Row flex="initial" justify="flex-start" align="center">
        {aaaRoundTownWelcomeViews.map(o => (
          <SliderDot
            key={o.index}
            active={o.index === activeSlide ? 1 : 0}
            onClick={() => sliderRef.current.slickGoTo(o.index)}
          />
        ))}
      </Row>
    );
  };

  const renderSlickArrows = () => {
    if (!largeScreen) {
      return null;
    }

    const disabledPrev = activeSlide === 0;
    const disabledNext = activeSlide === aaaRoundTownWelcomeViews.length - 1;

    return (
      <Row flex="initial" justify="flex-start" align="center" wrap="wrap">
        <SliderArrowButton
          width={12}
          onClick={sliderRef?.current?.slickPrev}
          icon={angleLeftIcon}
          disabled={disabledPrev}
          opacity={disabledPrev ? 0.6 : 1}
        />
        <SliderArrowButton
          width={12}
          onClick={sliderRef?.current?.slickNext}
          icon={angleRightIcon}
          disabled={disabledNext}
          opacity={disabledNext ? 0.6 : 1}
        />
      </Row>
    );
  };

  const renderSliderItem = item => {
    return (
      <SliderItemCol
        justify="flex-start"
        align="flex-start"
        largeScreen={isLargeScreen}
      >
        <Image
          index={item.index}
          largeScreen={isLargeScreen}
          src={isLargeScreen ? item.img : item.imgMobile}
          alt="aaa round town welcome image"
        />
        <ContentCol
          justify="flex-start"
          align="flex-start"
          spacingH={spacing}
          largeScreen={isLargeScreen}
        >
          <Title>{t(item.title)}</Title>
          <TextContent>{t(item.text)}</TextContent>
          {item.showReadyToRide && (
            <ReadyToRide onClick={toggleCallUsView}>
              <ReadyToRideText>{t('welcome-views.aaa-roundtown.others.ready-to-ride')}</ReadyToRideText>
            </ReadyToRide>
          )}
          <ServiceAreaMap onClick={toggleServiceAreaMapView}>
            <ServiceAreaMapIcon icon={faMapMarkerAlt} />
            <ServiceAreaMapText>{t('welcome-views.aaa-roundtown.others.see-map')}</ServiceAreaMapText>
          </ServiceAreaMap>
          {item.secondaryText && (
            <TextContent>{t(item.secondaryText)}</TextContent>
          )}

          {renderSlickDots()}

          {renderSlickArrows()}
        </ContentCol>
        <Row
          flex="initial"
          justify="space-between"
          align="center"
          wrap="wrap"
          spacingV={spacing}
          spacingH={spacing}
        >
          <StyledButton
            id="create-account-button"
            label={t('welcome-views.aaa-roundtown.buttons.create-account')}
            onClick={onClickCreateAccount}
            color={theme.SECONDARY_BASE_COLOR}
            size="lg"
            largeScreen={isLargeScreen}
            spacing={spacing}
          />
          <StyledButton
            label={t('welcome-views.aaa-roundtown.buttons.call-us')}
            onClick={toggleCallUsView}
            color={theme.BASE_COLOR}
            size="lg"
            largeScreen={isLargeScreen}
            spacing={spacing}
          />
        </Row>
      </SliderItemCol>
    );
  };

  const sliderItems = aaaRoundTownWelcomeViews.map(item =>
    renderSliderItem(item),
  );

  return (
    <>
      {isCallUsView && (
        <CallUs
          toggle={toggleCallUsView}
          phone="833-238-2546"
          setCustomClose={setCustomClose}
        />
      )}

      {isServiceAreaMapView && (
        <CServiceAreaMap
          toggle={toggleServiceAreaMapView}
          isLargeScreen={isLargeScreen}
          setCustomClose={setCustomClose}
        />
      )}
      <Slider
        id="aaa-roundtown-slider"
        hide={isCallUsView || isServiceAreaMapView}
        sliderRef={sliderRef}
        views={sliderItems}
        sliderProps={sliderProps}
      />
    </>
  );
};

AAARoundTownSlider.propTypes = {
  largeScreen: PropTypes.bool.isRequired,
  onClickCreateAccount: PropTypes.func.isRequired,
  setModalHeightWidth: PropTypes.func.isRequired,
  setCustomClose: PropTypes.func.isRequired,
};

export default AAARoundTownSlider;
