/** @format */

import styled from 'styled-components';

import { theme, device } from '@constants';
import { Col, Button, Input } from '@components';

export const Container = styled(Col)`
  height: calc(100% - 25px);
`;

export const Heading = styled.h1`
  font-family: Bryant;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
  color: ${theme.COD_GRAY};
  margin: 20px 0 8px 0;
  text-align: center;
  font-size: 26px;
  @media ${device.mediaQuery.SMALL_SCREEN} {
    font-size: 24px;
  }
`;

export const SubHeading = styled.h2`
  font-family: Bryant;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: ${theme.COD_GRAY_LIGHT};
  margin: 0px 0 16px 0;
  padding: 0;
  text-align: center;
  font-size: 22px;
  @media ${device.mediaQuery.SMALL_SCREEN} {
    font-size: 20px;
  }

`;

export const ColItem = styled(Col)`
  position: relative;
`;

export const BottomContainer = styled.div``;

export const StyledButton = styled(Button)`
  width: 100%;
  border-radius: 8px;
  font-family: Bryant;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 18px;
  height: 52px;
  margin-top: 15px;
  @media ${device.mediaQuery.SMALL_SCREEN} {
    margin-top: 10px;
  }
`;

export const StyledIcon = styled.img`
  display: block;
  height: 70px;
  width: 70px;
  margin-top: 2px;
  @media ${device.mediaQuery.SMALL_SCREEN} {
    margin-top: 19px;
  }

`;

export const SInput = styled(Input)`
  color: ${theme.WHITE};

  input {
    border: 1px ${theme.LIGHTEN_GRAY} solid;
    border-radius: 8px;
    font-family: Bryant;
    font-size: 20px;
    height: 60px;
  }

  input::placeholder {
    color: ${theme.COD_GRAY_LIGHT};
    font-family: Bryant;
    opacity: 0.5;
  }

  #error {
    font-size: 16px;
    font-weight: 400;
    font-family: Bryant;
    margin-top: 5px;
    margin-bottom: 5px;
    color: ${theme.SECONDARY_BASE_COLOR};
  }
`;
