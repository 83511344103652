/** @format */

import styled from 'styled-components';

import { theme, device } from '@constants';
import { Button } from '..';

export const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  @media ${device.mediaQuery.SMALLEST_SCREEN} {
    font-size: 14px;
  }
`;

export const Description = styled.span`
  font-size: 12px;
  text-align: center;

  @media ${device.mediaQuery.SMALLEST_SCREEN} {
    font-size: 10px;
  }
`;

export const TotalPrice = styled.span`
  @media ${device.mediaQuery.SMALLEST_SCREEN} {
    font-size: 12px;
  }
`;

export const Total = styled.span`
  font-weight: 600;
  color: ${theme.BRIGHT_SKY_BLUE};
  @media ${device.mediaQuery.SMALLEST_SCREEN} {
    font-size: 12px;
  }
`;

export const InnerWrapper = styled.div`
  background-color: ${theme.WHITE};
  border-radius: 12px;
`;

export const GotItBtn = styled(Button)`
  width: 60%;
  margin-top: 28px;
`;

export const NotFree = styled.div``;
export const Free = styled.div``;
