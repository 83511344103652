/** @format */

import React from 'react';
import PropTypes from 'prop-types';

import Form from './form';
import { TripRequestFormCol } from './styled';

export const TripRequestFormSmall = props => {
  return (
    <TripRequestFormCol
      justify="flex-start"
      align="center"
      spacingV={15}
      spacingH={30}
    >
      <Form {...props} />
    </TripRequestFormCol>
  );
};

TripRequestFormSmall.propTypes = {
  state: PropTypes.shape({
    query: PropTypes.shape({
      orgId: PropTypes.number,
      departDate: PropTypes.string,
      departTime: PropTypes.string,
      pickupAddress: PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.string,
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
        PropTypes.string,
      ]),
      returnDate: PropTypes.string,
      returnTime: PropTypes.string,
      destinationAddress: PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.string,
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
        PropTypes.string,
      ]),
      isReturn: PropTypes.bool,
    }).isRequired,
    pickupAddresses: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.label,
      }),
    ).isRequired,
    destinationAddresses: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.label,
      }),
    ).isRequired,
    loading: PropTypes.string,
  }).isRequired,
  getPickupAddresses: PropTypes.func.isRequired,
  getDestinationAddresses: PropTypes.func.isRequired,
  clearAddresses: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onIsRoundTripChange: PropTypes.func.isRequired,
  onPickupAddressClick: PropTypes.func.isRequired,
  onDestinationAddressClick: PropTypes.func.isRequired,
  openTripDateTimeForm: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  goToHome: PropTypes.func.isRequired,
  getValidTimes: PropTypes.func.isRequired,
};

export default TripRequestFormSmall;
