export const dateConfig = {
    date: {
        format: 'DD',
        caption: 'Day',
        step: 1,
    },
    month: {
        format: 'MM',
        caption: 'Mon',
        step: 1,
    },
    year: {
        format: 'YYYY',
        caption: 'Year',
        step: 1,
    },
};
