/** @format */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { analytics } from '@constants';
import { WorkforceMap as CWorkforceMap } from '@components';
import {
  setCurrentLocation,
  trackAbEvent,
  setSelectedOrganizationPlaceMarker,
  openModal,
} from '@slices/app';
import { getEstimatedShiftPickupTime } from '@slices/tripRequest';
import { LatLng } from 'leaflet';
import { placeHelpers } from '@utils';
import { useLocation, useHistory } from 'react-router';

const mapMarkerZoomConstant = 17;

const WorkforceMap = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const mapRef = useRef(null);
  const [orgPlaces, setOrgPlaces] = useState([]);
  const [workplaceId, setWorkplaceId] = useState(null);
  const [isFitBound, setIsFitBound] = useState(true);

  const {
    currentLocation,
    largeScreen,
    tripMapMarkers,
    organizationPlaces,
    organizations,
    selectedOrganization,
    tripPath,
    resizeMapKey,
    modals,
  } = useSelector(state => state.app);
  const { selectedOrgPlaceMarker } = modals || false;
  const { search } = useSelector(state => state.tripRequest);
  const { pickupHereClicked } = search?.query?.pickupAddress || false;

  useEffect(() => {
    if (
      organizations &&
      Array.isArray(organizations) &&
      (selectedOrganization || selectedOrganization === 0)
    ) {
      const { workplaceId: orgPlaceId } = organizations[selectedOrganization] || {};
      setWorkplaceId(orgPlaceId || null);
    }
  }, [selectedOrganization, organizations]);

  useEffect(() => {
    if (
      organizationPlaces &&
      organizationPlaces.length &&
      (workplaceId || workplaceId === 0) &&
      (location.pathname === '/' ||
      location.pathname.includes('/org') ||
      location.pathname.includes('/trip-request'))
    ) {
      const orgStops = placeHelpers.getAlphabetsForPlaces([...organizationPlaces]);
      const workplaceLoc = organizationPlaces
        .find(orgPlace => orgPlace.type === 'WORKPLACE' && orgPlace.orgPlaceId === workplaceId);

      if (Array.isArray(orgStops) && orgStops.length && workplaceLoc) {
        const places = [...orgStops, workplaceLoc];
        setOrgPlaces(places);
      } else {
        setOrgPlaces([]);
      }
    } else {
      setOrgPlaces([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationPlaces, location, workplaceId]);

  useEffect(() => {
    getCurrentLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const { oldCards, lat, lng } = search?.query?.pickupAddress || {};
    if (oldCards) {
      flyToPosition(lat, lng, mapMarkerZoomConstant);
    }
    if (!search?.query?.pickupAddress) setIsFitBound(true);
  }, [search.query.pickupAddress]);

  useEffect(() => {
    if (!largeScreen) {
      setTimeout(() => {
        if (!selectedOrgPlaceMarker) setIsFitBound(true);
      }, 800);
    } else {
      setIsFitBound(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (!selectedOrgPlaceMarker && !pickupHereClicked) {
      setIsFitBound(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrgPlaceMarker]);

  const getCurrentLocation = (flyToCurrentLocation = false) => {
    navigator.geolocation.getCurrentPosition(pos => {
      const {
        coords: { latitude, longitude },
      } = pos;
      dispatch(setCurrentLocation([latitude, longitude]));
      if (flyToCurrentLocation) {
        flyToPosition(latitude, longitude, mapMarkerZoomConstant);
      }
    });
  };

  const flyToPosition = (lat, lng, zoomConstant = mapMarkerZoomConstant) => {
    if (mapRef?.current) {
      mapRef.current.flyTo(new LatLng(lat, lng), zoomConstant);
    }
  };

  const whenCreatedHandler = mapInstance => {
    mapRef.current = mapInstance;
  };

  const onClickBusStopMarker = (busStop) => {
    if (!busStop || !busStop?.lat || !busStop.lng) return;
    const { lat, lng } = busStop;
    const targetPoint = mapRef.current.project([lat, lng], mapMarkerZoomConstant).add([160, 200]);
    const targetLatLng = mapRef.current.unproject(targetPoint, mapMarkerZoomConstant);

    if (!largeScreen) history.push('/');
    mapRef.current.setView(targetLatLng, mapMarkerZoomConstant);
    dispatch(setSelectedOrganizationPlaceMarker(busStop));
    dispatch(getEstimatedShiftPickupTime(busStop.orgPlaceId));
    setTimeout(() => {
      dispatch(openModal({ modal: 'selectedOrgPlaceMarker', value: true }));
    }, 300);
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.PICKUP_LOCATION_SELECTED_OR_TAPPED,
        subJourneyName: 'Explore Dashboard',
        journeyName: 'Rider Experience',
        details: {
          busStop,
        },
      }),
    );
  };

  const onClickWorkplaceMarker = (workplace) => {
    if (!workplace || !workplace?.lat || !workplace.lng) return;
    const { lat, lng } = workplace;
    const targetPoint = mapRef.current.project([lat, lng], mapMarkerZoomConstant).add([160, 200]);
    const targetLatLng = mapRef.current.unproject(targetPoint, mapMarkerZoomConstant);

    if (!largeScreen) history.push('/');
    mapRef.current.setView(targetLatLng, mapMarkerZoomConstant);
    dispatch(setSelectedOrganizationPlaceMarker(workplace));
    setTimeout(() => {
      dispatch(openModal({ modal: 'selectedOrgPlaceMarker', value: true }));
    }, 300);
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.WORKPLACE_SELECTED_OR_TAPPED,
        subJourneyName: 'Explore Dashboard',
        journeyName: 'Rider Experience',
        details: {
          workplace,
        },
      }),
    );
  };

  return (
    <CWorkforceMap
      places={orgPlaces}
      tripMarkers={tripMapMarkers}
      currentPosition={currentLocation}
      tripPath={tripPath}
      resizeMapKey={resizeMapKey}
      onClickBusStopMarker={onClickBusStopMarker}
      onClickWorkplaceMarker={onClickWorkplaceMarker}
      whenCreatedHandler={whenCreatedHandler}
      largeScreen={largeScreen}
      isFitBound={isFitBound}
      setIsFitBound={setIsFitBound}
      selectedOrgPlaceMarker={selectedOrgPlaceMarker}
      pickupHereClicked={pickupHereClicked}
    />
  );
};

export default WorkforceMap;
