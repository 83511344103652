/** @format */

import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Switch, Route, Redirect } from 'react-router-dom';
import { SheetModal, TripOverviewWorkforce as CTripOverviewWorkforce } from '@components';
import {
  setRepeatData,
  setRepeatEnabled,
  sendTripRequests,
  resetTripRequestStateOnTripOverviewUnmount,
  setSearchSelectionDate,
  setSearchSelectionTime,
  getTripDetails,
  setIsDateTimeChanged,
  setSearchSelectionState,
  setSelectedReturnTripDetail,
  setLoading,
} from '@slices/tripRequestWorkforce';
import {
  setTripMapMarkers,
  trackEvent,
  setTripPath,
  clearTripPath,
} from '@slices/app';
import { analytics, generals as generalConstants } from '@constants';
import {
  useSheetModalMinimize,
  usePayingOrganization,
  useBookingConfirmDialog,
  useMessageDialog,
  useValidTimesWorkforce,
} from '@hooks';

const TripOverviewWorkforce = () => {
  const { t } = useTranslation();
  const [sheetModalMinimized, toggleSheetModalMinimized] =
    useSheetModalMinimize();
  const { BookingConfirmDialog, openBookingConfirmDialog } =
    useBookingConfirmDialog();
  const { MessageDialog, openMessageDialog } = useMessageDialog();
  const [repeatMessageShown, setRepeatMessageShown] = useState(false);
  const [getOrganizationName] = usePayingOrganization();
  const [changedDate, setChangedDate] = useState('');
  const [changedTime, setChangedTime] = useState('');
  const [originalDate, setOriginalDate] = useState('');
  const [originalTime, setOriginalTime] = useState('');
  const [isReturnAvailable, setIsReturnAvailable] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const {
    loading,
    repeat,
    selectedTripDetail,
    selectedReturnTripDetail,
    search,
    isDateTimeChanged,
  } = useSelector(store => store.tripRequestWorkforce);
  const { largeScreen, selectedOrganization, organizations, organizationPlaces, user, userAgent } =
  useSelector(state => state.app);
  
  const [getValidTimes] = useValidTimesWorkforce();
  const [getValidTimesReturn] = useValidTimesWorkforce(true);

  const isAuthenticated = user || userAgent ? true : false;

  useEffect(() => {
    if (selectedTripDetail) {
      dispatch(trackEvent(analytics.TRIP_OVERVIEW_VIEW, selectedTripDetail));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTripDetail, dispatch]);

  useEffect(() => {
    if (changedDate !== '') {
      dispatch(setSearchSelectionDate(moment(changedDate).format('MM/DD/YYYY')));
      if (moment(`${search.selection.returnDate} ${search.selection.returnTime}`).isBefore(moment(`${moment(changedDate).format('MM/DD/YYYY')} ${changedTime.value}`))){
        resetReturnState();
      }

      if (changedTime === '') {
        dispatch(setLoading(true));
      }
    }
    if (changedTime !== '') {
      dispatch(setSearchSelectionTime(moment(changedTime.value, ['h:mm A']).format('HH:mm:ss')));
      dispatch(getTripDetails());
      dispatch(setLoading(null));
      if (moment(`${search.selection.returnDate} ${search.selection.returnTime}`).isBefore(moment(`${changedDate} ${changedTime.value}`))) {
        resetReturnState();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changedDate, changedTime, dispatch]);

  const {
    time: departTime,
    date: departDate,
    returnTime,
    returnDate,
  } = search.selection;

  useEffect(() => {
    if (
      departDate !== '' &&
      departTime !== '' &&
      returnTime === '' &&
      returnDate === ''
    ) {
      const dateTime = moment(`${departDate} ${departTime}`);
      const initialReturn = dateTime.add(8, 'hours');
      setReturnDate(moment(initialReturn).format('MM/DD/YYYY'));

      const times = getValidTimesReturn(moment(initialReturn).format('MM/DD/YYYY'));

      const validTimes = times?.values?.sort();
      if (validTimes && validTimes.length) {  
        const compareWith = initialReturn.format("MM/DD/YYYY hh:mm A");
        for (let i = 0; i < validTimes.length; i++) {
          if (moment(`${departDate} ${validTimes[i].value}`).isAfter(moment(compareWith))) {
            setReturnTime({ value: validTimes[i].value });
            break;
          }
        }
        setIsReturnAvailable(true);
      }
      else {
        if (!isReturnAvailable) {
          const destinationAddressLabel =
            search && search.selection && search.selection.destinationAddress.label;
          let destinationAddress = null; 
          organizationPlaces && organizationPlaces.forEach(orgPlace => {
            if (orgPlace.label === destinationAddressLabel) {
              destinationAddress = orgPlace;
            }
          });
          const availableDays = destinationAddress ? (
            search.selection.requestType === 'Ready By' ? destinationAddress.availableDays : destinationAddress.availableDaysArriveBy
          ) : [];
          setIsReturnAvailable(availableDays.length > 0);
          dispatch(setSearchSelectionState({ key: 'isReturn', value: false }));
        }
        dispatch(setSelectedReturnTripDetail(null));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departDate, departTime, returnTime, returnDate]);

  const isReturnTripValid = moment(
    `${search.selection.returnDate} ${search.selection.returnTime}`,
  ).isAfter(
    moment(`${search.selection.date} ${search.selection.time}`),
  );

  const resetReturnState = () => {
    dispatch(setSearchSelectionState({ key: "returnDate", value: "" }));
    dispatch(setSearchSelectionState({ key: "returnTime", value: "" }));
  };

  const onToggleDateTime = (value) => {
    if (value) {
      setOriginalDate(search.selection.date);
      setOriginalTime(search.selection.time);
      dispatch(setIsDateTimeChanged(true));
    } else {
      dispatch(setSearchSelectionDate(originalDate));
      dispatch(setSearchSelectionTime(originalTime));
      setChangedDate('');
      setChangedTime('');
      dispatch(setLoading(null));
      dispatch(setIsDateTimeChanged(false));
    }
  };

  const onToggleIsReturn = () => {
    if (search.selection.isReturn) {
      // when disabled
      setReturnDate('');
    }

    setReturnTime('');
    dispatch(setSelectedReturnTripDetail(null));
    const payload = {
      key: 'isReturn',
      value: !search.selection.isReturn,
    };
    dispatch(setSearchSelectionState(payload));
  };

  const dateTimeSelected =
    search.selection.returnDate &&
    search.selection.returnTime &&
    search.selection.returnDate !== '' &&
    search.selection.returnTime !== '';

    const setReturnDate = (e) => {
    const payload = {
      key: 'returnDate',
      value: e === '' ? '' : moment(e).format('MM/DD/YYYY'),
    };

    dispatch(setSearchSelectionState(payload));
    if (dateTimeSelected) {
      dispatch(getTripDetails(() => {}, true));
    }
  };

  const setReturnTime = (e) => {
    const payload = {
      key: 'returnTime',
      value: e ? e.value : "",
    };

    dispatch(setSearchSelectionState(payload));
    if (dateTimeSelected) {
      dispatch(getTripDetails(() => {}, true));
    }
  };

  useEffect(() => {
    if (search.selection.time) {
      dispatch(getTripDetails());
    }
  }, [dispatch, search.selection.time]);

  useEffect(() => {
    if (dateTimeSelected) {
      dispatch(getTripDetails(() => {}, true));
    }
  }, [dispatch, dateTimeSelected]);

  const isSelected = selectedTripDetail !== null;

  const tripRequestSuccess = useCallback(
    (created, resetCallback) => {
      openBookingConfirmDialog(true, {
        created,
        resetCallback,
      });
    },
    [openBookingConfirmDialog],
  );

  useEffect(() => {
    if (isSelected) {
      dispatch(
        trackEvent(
          analytics.TRIP_OVERVIEW_VIEW,
          selectedTripDetail,
        ),
      );

      const [pickup, destination] = selectedTripDetail.places;

      dispatch(
        setTripMapMarkers({
          pickup: { location: [pickup.lat, pickup.lng], type: pickup.orgPlaceType},
          destination: { location: [destination.lat, destination.lng], type: destination.orgPlaceType },
        }),
      );

      dispatch(setTripPath({
        share: selectedTripDetail.path,
        transit: null,
      }));

      dispatch(setIsDateTimeChanged(false));
    }

    return () => {
      dispatch(resetTripRequestStateOnTripOverviewUnmount(true));
      dispatch(
        setTripMapMarkers({
          pickup: null,
          destination: null,
        }),
      );
      dispatch(clearTripPath());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isSelected]);

  if (!isSelected) {
    history.push('/');
    return null;
  }

  const payingOrganization = () => getOrganizationName(selectedTripDetail.organizationId);

  const onChangeFrequency = (name, value) => {
    if (name === 'enabled') {
      dispatch(
        setRepeatEnabled({
          enabled: value,
          end: value ? selectedTripDetail.leaveTime : null,
        }),
      );
    } else {
      dispatch(
        setRepeatData({
          [name]: value,
        }),
      );
    }
  };

  const tripRequestDisabledDates =
    Array.isArray(organizations) &&
    selectedOrganization >= 0 &&
    organizations[selectedOrganization] &&
    selectedOrganization < organizations.length
      ? organizations[selectedOrganization].detail?.tripRequestDisabledDates
      : null;

  const isValidDate = (current, isReturn = false) => {
    const yesterday = moment().subtract(1, 'day');
    const pickupAddressLabel =
      search && search.selection
        ? isReturn
          ? search.selection.destinationAddress.label
          : search.selection.pickupAddress.label
        : '';
    let pickupAddress = null; 
    organizationPlaces && organizationPlaces.forEach(orgPlace => {
      if (orgPlace.label === pickupAddressLabel) {
        pickupAddress = orgPlace;
      }
    });
    if (tripRequestDisabledDates?.length > 0 && tripRequestDisabledDates.includes(current.format('MM/DD/YYYY'))) {
      return false;
    }

    const availableDays = pickupAddress ? (
      search.selection.requestType === 'Ready By' ? pickupAddress.availableDays : pickupAddress.availableDaysArriveBy
    ) : [];

    if (availableDays?.length > 0) {
      let isDayAvailable = current.isAfter(yesterday) &&
      availableDays.indexOf(generalConstants.DAYS[current.day()]) !== -1;
      const dayShort = generalConstants.DAYS[current.day()].substring(0,3);
      const type = search.selection.requestType === 'Ready By' ? "readyBy" : "arriveBy";
      const isTimeAvailableForDay = pickupAddress.availableTimes[type][dayShort];
      return isDayAvailable && isTimeAvailableForDay.length > 0;
    }
    return current.isAfter(yesterday) && !isReturn;
  };

  const onEdit = () => {
    dispatch(trackEvent(analytics.TRIP_OVERVIEW_BACK_BUTTON));
    history.push('/');
  };

  const bookRide = () => {
    if (!repeat.enabled && !repeatMessageShown) {
      openMessageDialog(true, {
        bodyText: t('trip-overview.repeat.confirmation'),
        closeButton: {
          title: t('common.no'),
          onPress: () => dispatch(sendTripRequests(tripRequestSuccess)),
        },
        rightButton: {
          title: t('common.yes'),
          onPress: () => onChangeFrequency('enabled', true),
        },
      });
      setRepeatMessageShown(true);
    } else {
      dispatch(sendTripRequests(tripRequestSuccess));
    }
  };

  const tripOverview = (
    <>
      <CTripOverviewWorkforce
        loading={loading}
        selectedTripDetail={selectedTripDetail}
        selectedReturnTripDetail={selectedReturnTripDetail}
        payingOrganization={payingOrganization()}
        onEdit={onEdit}
        onBookRide={bookRide}
        repeat={repeat}
        onToggleDateTime={onToggleDateTime}
        isDateTimeChanged={isDateTimeChanged}
        isValidDate={isValidDate}
        getValidTimes={getValidTimes}
        getValidTimesReturn={getValidTimesReturn}
        setChangedDate={setChangedDate}
        setChangedTime={setChangedTime}
        setReturnDate={setReturnDate}
        setReturnTime={setReturnTime}
        state={search}
        changedDate={changedDate}
        changedTime={changedTime}
        onChangeFrequency={onChangeFrequency}
        isValidEndDate={isValidDate}
        minimized={sheetModalMinimized}
        onToggleIsReturn={onToggleIsReturn}
        largeScreen={largeScreen}
        isReturnAvailable={isReturnAvailable}
        isReturnTripValid={isReturnTripValid}
        isAuthenticated={isAuthenticated}
      />
      <BookingConfirmDialog />
      <MessageDialog />
    </>
  );

  const tripOverviewRoute = largeScreen ? (
    tripOverview
  ) : (
    <SheetModal
      id="trip-detail-sheet-modal"
      minimizeEnabled
      minimized={sheetModalMinimized}
      toggleMinimized={toggleSheetModalMinimized}
    >
      {tripOverview}
    </SheetModal>
  );

  return (
    <Switch>
      <Route path="/trip-overview" render={() => tripOverviewRoute} />
      <Redirect to="/trip-overview" />
    </Switch>
  );
};

export default TripOverviewWorkforce;
