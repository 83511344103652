/** @format */

const size = {
  MOBILE: 576, // < 576px is mobile
  TABLET: 768, // > 576px and < 768px is tablet
  LAPTOP: 1024, // > 768px and < 1024px is laptop, above 1024px is desktop
};

const mediaQuery = {
  SMALLEST_SCREEN: '(max-width: 320px)',
  SMALL_SCREEN: `(max-width: ${size.LAPTOP}px)`,
  SMALLER_THAN_448: '(max-width: 448px)',
  LARGE_SCREEN: `(min-width: ${size.LAPTOP + 1}px)`,
  MOBILE_SCREEN: `(max-width: ${size.MOBILE}px)`,
};

export default {
  size,
  mediaQuery,
};
