/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Col, Row } from '@components';
import { generals, theme } from '@constants';
import { helpers } from '@utils';
import {
  DOBRow,
  Label,
  DropdownWrapper,
  StyledDropdown,
  InputError,
} from './styled';

const DateOfBirthPicker = ({
  label,
  onChangeDay,
  onChangeMonth,
  onChangeYear,
  dob,
  min,
  fontSize,
}) => {
  const { t } = useTranslation();

  const monthsNames = generals.MONTHS_NAMES.map(x => {
    return {
      label: t(`common.month-names.${x.toLowerCase()}`),
      value: x,
    };
  });
  const getDateOptions = () => {
    return helpers.getDaysInMonth(
      helpers.getNumberOfdaysInMonth(
        helpers.getMonthNumberFromName(dob.month),
        dob.year,
      ),
    );
  };
  return (
    <>
      <Col
        data-cy="dob-picker"
        justify="flex-start"
        align="flex-start"
        spacingV={0}
      >
        <Label fontSize={fontSize}>{label}</Label>

        <DOBRow justify="space-between" align="flex-start">
          <DropdownWrapper  data-cy="selects-DOB">
            <StyledDropdown
              id="day"
              value={dob.date ? dob.date.toString() : null}
              onChange={onChangeDay}
              options={getDateOptions()}
              color={theme.BASE_COLOR}
              inverted
              height="50px"
              bordered
              rounded
              placeholder={t('date-of-birth-picker.date')}
              size="sm"
            />
          </DropdownWrapper>
          <DropdownWrapper data-cy="selects-MOB">

            <StyledDropdown
              id="month"
              value={dob.month}
              onChange={onChangeMonth}
              options={monthsNames}
              color={theme.BASE_COLOR}
              inverted
              height="50px"
              bordered
              rounded
              placeholder={t('date-of-birth-picker.month')}
              size="sm"
            />
          </DropdownWrapper>
          <DropdownWrapper data-cy="selects-YOB">

            <StyledDropdown
              id="year"
              value={dob.year ? dob.year.toString() : null}
              onChange={onChangeYear}
              options={helpers.getYearsDOB(min)}
              color={theme.BASE_COLOR}
              inverted
              bordered
              height="50px"
              rounded
              placeholder={t('date-of-birth-picker.year')}
              size="sm"
            />
          </DropdownWrapper>
        </DOBRow>
        {(!dob.year || !dob.month || !dob.date) && (
          <Row justify="flex-start" align="center">
            <InputError>{t('date-of-birth-picker.error')}</InputError>
          </Row>
        )}
      </Col>
    </>
  );
};

DateOfBirthPicker.propTypes = {
  label: PropTypes.string.isRequired,
  onChangeDay: PropTypes.func.isRequired,
  onChangeMonth: PropTypes.func.isRequired,
  onChangeYear: PropTypes.func.isRequired,
  fontSize: PropTypes.number,
  dob: PropTypes.shape({
    date: PropTypes.string,
    month: PropTypes.string,
    year: PropTypes.string,
  }).isRequired,
};

DateOfBirthPicker.defaultProps = {
  fontSize: 16,
};

export default DateOfBirthPicker;
