/** @format */

import moment from 'moment-timezone';
import geoTz from 'geo-tz';

import { generals as generalConstants } from '@constants';
import { getTranslatedStringFromKey } from '@i18Loader';
import { placeHelpers } from '..';

const workforceEnv = process.env.REACT_APP_WORKFORCE_APP === '1';

const sortTimeArray = arr => {
  return arr
    ?.map(x => moment.utc(x, 'hh:mm A'))
    .sort((a, b) => a.unix() - b.unix())
    .map(x => x.format('hh:mm A'));
};

const sortPlacesByTime = places => {
  return places.map(place => {
    const availableTimes = {
      readyBy: {},
      arriveBy: {},
    };

    Object.keys(generalConstants.MINIMAL_TO_FULL_DAY_MAPPING).forEach(key => {
      availableTimes.readyBy[key] = sortTimeArray(
        place?.availableTimes?.readyBy[key],
      );
      availableTimes.arriveBy[key] = sortTimeArray(
        place?.availableTimes?.arriveBy[key],
      );
    });

    return {
      ...place,
      availableTimes,
    };
  });
};

const getMinAndMaxTime = (timings) => {
  let timingStr = '';
  const timingsArr = sortTimeArray(timings);
  if (timingsArr && timingsArr.length > 0) {
    if (timingsArr[0] !== getTranslatedStringFromKey('moment.invalid')) {
      [timingStr] = timingsArr;
    }

    if (timingsArr.length > 1) {
      if (timingsArr[timingsArr.length - 1] !== getTranslatedStringFromKey('moment.invalid')) {
        if (timingStr.length > 0) {
          timingStr += ' - ';
        }
        timingStr += timingsArr[timingsArr.length - 1];
      }
    }
  }

  return timingStr;
};

const checkIfPlaceNameMeansArriveBy = name => {
  if (!name || name.length === 0) {
    return false;
  }

  return (
    name.toLowerCase().includes('arrive by') ||
    name.toLowerCase().includes('airport') ||
    name.toLowerCase().includes('hub') ||
    name.toLowerCase().includes('terminal')
  );
};

/**
 *
 * @param {any[]} places
 * This function accepts an array of objects
 *  of places where a place's available time and days are present
 * It returns a new array of places with new child item `timings`
 * `timing` contains a readable form of schedule
 */

const calculateAvailableTimings = placesParams => {
  const places = placesParams?.map(place => {
    let timings = null;
    const isArriveBy = checkIfPlaceNameMeansArriveBy(place?.name);
    const times = isArriveBy
      ? place?.availableTimes?.arriveBy || []
      : place?.availableTimes?.readyBy || [];
    const flatTimes = Object.keys(times)
      .map(key => times[key])
      .flat();
    const daysToUse = isArriveBy
      ? place?.availableDaysArriveBy || []
      : place?.availableDays || [];

    if (flatTimes?.length > 0 && daysToUse?.length) {
      const timezone = place.timezone || geoTz(place.lat, place.lng).toString();

      const days = daysToUse.map(x => {
        const key = x.slice(0, 3);
        return {
          day: key.toUpperCase(),
          timing: getMinAndMaxTime(
            place.availableTimes[isArriveBy ? 'arriveBy' : 'readyBy'][key],
            timezone,
          ),
        };
      });
      timings = days;
    }

    return {
      ...place,
      timings,
    };
  });
  // In case of workforce it will only return STOP type places with Alplha keys
  return workforceEnv ? placeHelpers.getAlphabetsForPlaces(places) : places;
};

export default {
  calculateAvailableTimings,
  getMinAndMaxTime,
  sortPlacesByTime,
  checkIfPlaceNameMeansArriveBy,
};
