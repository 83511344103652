/** @format */

import styled from 'styled-components';
import { MapContainer, Tooltip as ReactLeafletTooltip } from 'react-leaflet';

import { theme, device } from '@constants';
import { Row } from '..';

export const MapRow = styled(Row)`
  width: 100%;
  position: relative;
  z-index: 0;

  .marker-cluster {
    background-color: ${theme.BASE_COLOR} !important;
    div {
      background-color: ${theme.BASE_COLOR} !important;
      span {
        color: ${theme.WHITE};
      }
    }
  }
`;

export const Map = styled(MapContainer)`
  width: 100%;
  height: 100%;
`;

export const PlaceAddress = styled.span`
  color: ${theme.BASE_COLOR};
  margin: 0;
  font-size: 14px;
`;

export const ButtonLink = styled.button`
  border: none;
  background: none;
  text-decoration: underline;
  cursor: pointer;
  color: ${theme.BRIGHT_SKY_BLUE};
`;

export const AvailableTimes = styled(PlaceAddress)`
  font-weight: bold;
`;

export const Tooltip = styled(ReactLeafletTooltip)`
  padding: 10px;
`;

export const BusStopTitle = styled.h6`
  color: ${theme.BASE_COLOR};
`;

export const BusesText = styled.span`
  margin-bottom: 0;
  color: ${theme.BASE_COLOR};
`;

export const FilterWrapper = styled.div`
  position: absolute;
  right: 0;
  width: 30%;
  z-index: 1;
  margin-right: 10px;
  margin-top: 10px;
  @media ${device.mediaQuery.SMALL_SCREEN} {
    width: 50%;
    margin-top: 70px;
  }
`;

export const GetCurrentLocationPlaceHolder = styled(Row)`
  position: absolute;
  height: 40px;
  width: 40px;
  background: ${theme.WHITE};
  z-index: 1000;
  bottom: 20px;
  right: 20px;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.64); 
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.64);

  .image-wrapper {
    margin-top: 0;
  }

  .image-wrapper:focus {
    outline: 0;
  }
`

