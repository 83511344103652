/** @format */

import React from 'react';
import { PropTypes } from 'prop-types';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import ReactPaginate from 'react-paginate';

import { PaginationRow, PrevNextIcon } from './styled';

const PrevIcon = <PrevNextIcon id="pagination-prev-icon" icon={faAngleLeft} />;
const NextIcon = <PrevNextIcon id="pagination-next-icon" icon={faAngleRight} />;

const Pagination = ({ count, onChange }) => {
  return (
    <PaginationRow justify="center" align="center">
      <ReactPaginate
        previousLabel={PrevIcon}
        nextLabel={NextIcon}
        breakLabel="..."
        breakClassName="break-me"
        pageCount={count}
        marginPagesDisplayed={2}
        pageRangeDisplayed={1}
        onPageChange={onChange}
        containerClassName="pagination"
        activeClassName="active"
      />
    </PaginationRow>
  );
};

Pagination.propTypes = {
  count: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Pagination;
