/** @format */

import styled from 'styled-components';

import { theme } from '@constants';
import { Button } from '@components';
import { hideExtraTextLine } from '@utils/helpers/styled';

export const CardContainer = styled.div`
  width: 100%;
  display: block;
  outline: none !important;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const InfoBlock = styled.div`
  min-height: 132px;
  background: ${theme.WHITE};
  width: 100%;
  border-radius: 10px;
  z-index: 3;
  position: relative;
  padding: 24px;
  color: ${theme.BASE_COLOR};
  h3 {
    font-size: 20px;
    font-weight: 600;
    ${hideExtraTextLine(2)}
  }
  .type {
    font-size: 14px;
    font-weight: 600;
    margin: 10px 0px 10px 0px;
  }
  -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.16);
`;

export const AddressBlock = styled.div`
  display: flex;
  align-items: center;

  p {
    flex: 1;
    margin: 0;
    margin-right: 7px;
    font-weight: 400;
    font-size: 14px;
  }

  button {
    height: 20px;
    width: 80px;
    border-radius: 16px;
    border: 0;
    background-color: ${theme.BASE_COLOR};
    color: ${theme.WHITE};
    font-size: 10px;
    &:focus {
      outline: none;
    }
  }
`;

export const Line = styled.div`
  background-color: ${theme.BASE_COLOR};
  height: 1px;
  width: 100%;
  margin: 5px 0;
`;

export const PlanSummaryBlock = styled.div`
  .heading {
    font-size: 12px;
    font-weight: bold;
    margin: 0 0 4px 0;
  }
`;

export const TimingBlock = styled.div`
  .heading {
    font-size: 12px;
    font-family: 'Inter';
    font-weight: 600;
    font-style: normal;
    margin: 4px 0 4px 0;
  }
`;

export const TimingBlockItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 10px;
  margin: 10px 0px;
  .day {
    font-weight: 400;
    font-size: 14px;
    margin: 0;
  }
  .time {
    font-weight: 600;
    font-size: 14px;
    margin: 0;
    font-style: ${props => props.loading ? 'italic' : 'normal'};
  }
  .no-time {
    width: 75%;
  }
`;

export const TimingBlockHeadings = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 600;
  font-style: normal;
  margin: 4px 0 -14px 0;
  `;

export const Btn = styled(Button)`
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
`;
