/** @format */

import React, { useState, useEffect } from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { useSwipeable } from 'react-swipeable';
import { helpers, placesTimingHelpers } from '@utils'; 
import { styles, generals as generalConstants } from '@constants';
import {
  CardContainer,
  AddressBlock,
  Line,
  PlanSummaryBlock,
  PlanCheckItem,
  TimingBlock,
  TimingBlockItem,
  InfoBlock,
  TimingBlockHeadings,
} from './styled';

const workforceEnv = process.env.REACT_APP_WORKFORCE_APP === '1';

const OrganizationPlaceCardItem = ({
  place,
  selected,
  onAddressClick,
  onCardSelect,
  organizationName,
  onSwipedDown,
  selectedWorkplace,
  loading,
  estPickupTime,
}) => {
  const { t } = useTranslation();
  const [timingBlockVisible, setTimingBlockVisible] = useState(false);
  const isEstShiftTimeLoading = loading === generalConstants.EST_SHIFT_PICKUP_TIME_LOADING;
  useEffect(() => {
    if (selected) {
      setTimeout(() => {
        if (selected) {
          setTimingBlockVisible(true);
        }
      }, styles.ANIM_PLACE_CARD_CONTENT_HIDE_SHOW / 2);
    } else {
      setTimingBlockVisible(false);
    }
  }, [selected]);

  const onClickHandler = () => {
    onCardSelect(place);
  };

  const hasArriveBy = () => {
    if (place && place.name){
      return placesTimingHelpers.checkIfPlaceNameMeansArriveBy(place.name)   
    }
    return false;
  }

  const handlers = useSwipeable({
    onSwipedDown,
  });

  const getAlphaKey = markerKey => markerKey ? `${markerKey}. ` : '';

  const renderShifts = () => {
    return (
      <>
        <TimingBlock id="timing-block">
          <TimingBlockHeadings>
            <p>{t('organization-card.shift-time')}</p>
            <p>{t('organization-card.est-pickup-time')}</p>
          </TimingBlockHeadings>
          {selectedWorkplace?.shifts?.length > 0 ? (
            selectedWorkplace?.shifts?.map(shift => (
              <TimingBlockItem key={shift.id} shift>
                <p className="day">{shift.startTime} - {shift.endTime}</p>
                <p className="time">{isEstShiftTimeLoading ? `${t('organization-card.calculating')}...` : estPickupTime?.get(shift.id)}</p>
              </TimingBlockItem>
            ))
          ) : (
            <TimingBlockItem>
              <p className="no-time">
                {t('organization-card.no-schedule-available')}
              </p>
            </TimingBlockItem>
          )}
        </TimingBlock>
      </>
    );
  };
  return (
    <CardContainer
      onClick={onClickHandler}
      selected={selected}
      id="card-container"
      data-cy="address-card"
      {...handlers}
      preventDefaultTouchmoveEvent
    >
      {/* <PlaceImage selected={selected} src={DEFAULT_IMAGE} /> */}

      <InfoBlock selected={selected}>
        <h3 data-cy="address-name">{`${getAlphaKey(place?.markerKey)}${place?.name}`}</h3>
        <AddressBlock data-cy="complete-address">
          <p className="address">{helpers.getStreetAddress(place?.label)}</p>
          <button
            type="button"
            id="pickup-here"
            onClick={event =>
              onAddressClick(
                event,
                hasArriveBy() && !workforceEnv ? 'destination' : 'pickup',
                { ...place, oldCards: true },
              )
            }
          >
            {hasArriveBy() && !workforceEnv
              ? t('organization-card.go-here')
              : t('organization-card.pickup-here')}
          </button>
        </AddressBlock>
        <Line />
        <PlanSummaryBlock>
          <p className="heading">{t('organization-card.your-plans')}</p>
          {organizationName ? (
            <PlanCheckItem data-cy="address-organization" key={place?.name}>
              <FontAwesomeIcon className="check-icon" icon={faCheck} />
              {organizationName}
            </PlanCheckItem>
          ) : null}
        </PlanSummaryBlock>
        {selected &&
          timingBlockVisible &&
          (workforceEnv ? (
            renderShifts()
          ) : (
            <TimingBlock id="timing-block">
              <p className="heading">
                {t('organization-card.available-times')}
              </p>
              {place?.timings?.length > 0 ? (
                place?.timings?.map(time => (
                  <TimingBlockItem key={time.day}>
                    <p className="day">
                      {t(
                        `common.week-days.week-days-short-labels.${time.day.toLowerCase()}`,
                      )}
                    </p>
                    <p className="time">{time.timing}</p>
                  </TimingBlockItem>
                ))
              ) : (
                <TimingBlockItem>
                  <p className="no-time">
                    {t('organization-card.no-schedule-available')}
                  </p>
                </TimingBlockItem>
              )}
            </TimingBlock>
          ))}
      </InfoBlock>
    </CardContainer>
  );
};

OrganizationPlaceCardItem.propTypes = {
  place: PropTypes.object,
  selected: PropTypes.bool.isRequired,
  onAddressClick: PropTypes.func.isRequired,
  onCardSelect: PropTypes.func.isRequired,
  onSwipedDown: PropTypes.func.isRequired,
  organizationName: PropTypes.string,
  selectedWorkplace: PropTypes.object,
  loading: PropTypes.bool,
  estPickupTime: PropTypes.object,
};

OrganizationPlaceCardItem.defaultProps = {
  organizationName: '',
  place: null,
  selectedWorkplace: null,
  loading: false,
  estPickupTime: null,

};

OrganizationPlaceCardItem.defaultProps = {
  place: null,
};

export default OrganizationPlaceCardItem;
