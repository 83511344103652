/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { Row, IconButton } from '@components';
import { theme } from '@constants';
import { helpers } from '@utils';
import config from './config';
import {
  Title,
  ColItem,
  BottomContainer,
  StyledButton,
  SInput,
} from './styled';

const organizationName = process.env.REACT_APP_ORGANIZATION_NAME;

const ServiceAreaMap = ({
  zipCode,
  onSubmitZipCode,
  onChange,
  errors,
  validate,
  toggle,
  loading,
  valid,
  renderServiceAreaMap,
}) => {
  const { t } = useTranslation();

  const getInputRef = (ref, formField) => {
    if (!ref) {
      return;
    }

    validate(ref, formField.validator);
  };

  return (
    <ColItem justify="flex-start" align="flex-start">
      <Row
        flex="initial"
        justify="flex-end"
        align="center"
        spacingV={20}
        spacingH={20}
      >
        <IconButton onClick={toggle} />
      </Row>
      <Row
        flex="initial"
        justify="space-between"
        align="center"
        wrap="wrap"
        spacingV={0}
        spacingH={30}
      >
        <Title>
          {t(
            'welcome-views.aaa-roundtown.service-area-map.text',
            { organizationName },
          )}
        </Title>
      </Row>
      {renderServiceAreaMap()}
      <BottomContainer>
        <Row
          flex="initial"
          justify="space-between"
          align="flex-end"
          wrap="wrap"
          spacingV={25}
          spacingH={30}
        >
          <Title>
            {t(
              'welcome-views.aaa-roundtown.service-area-map.fields.zip-code.label',
              { organizationName },
            )}
          </Title>
          {config.formFields.map((formField, i) => (
            <SInput
              key={i}
              floating={false}
              type={formField.type}
              name={formField.name}
              onChange={onChange}
              value={zipCode}
              label={t(formField.label)}
              color={theme.WHITE}
              inverted
              inputRef={ref => getInputRef(ref, formField)}
              textColor={theme.COD_GRAY_LIGHT}
              error={t(
                helpers.getObjectProperty(errors, [formField.name, 'message']),
              )}
            />
          ))}
          <StyledButton
            id="submit-btn"
            label={t(
              'welcome-views.aaa-roundtown.service-area-map.buttons.search',
            )}
            onClick={onSubmitZipCode}
            color={theme.BASE_COLOR}
            size="lg"
            icon={loading && !errors.zipCode ? faSpinner : null}
            disabled={!valid}
            iconProps={{ spin: true }}
          />
        </Row>
      </BottomContainer>
    </ColItem>
  );
};

ServiceAreaMap.propTypes = {
  toggle: PropTypes.func.isRequired,
  renderServiceAreaMap: PropTypes.func.isRequired,
  zipCode: PropTypes.string.isRequired,
  onSubmitZipCode: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  validate: PropTypes.func.isRequired,
};

ServiceAreaMap.defaultProps = {
  errors: null,
};

export default ServiceAreaMap;
