/** @format */

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { theme, styles as styleConstants, device } from '@constants';
import { Col, Button, IconButton } from '@components';

export const SliderItemCol = styled(Col)`
  min-height: ${props =>
    !props.largeScreen
      ? `${window.innerHeight}px`
      : `${window.innerHeight - 124 - styleConstants.CLOSE_ICON_ROW_HEIGHT}px`};
  position: relative;
`;

export const Image = styled.img`
  width: 100%;
  ${props =>
    props.largeScreen
      ? `
    position: absolute;
    bottom: 0px;
    width: 75%;
    z-index: -1;
    ${props.index === 1 ? 'left: 0px;' : 'right: 0px;'}
    `
      : 'padding: 25px 0 30px 0;'}
`;

export const ContentCol = styled(Col)`
  ${props => (props.largeScreen ? 'width: 500px;' : '')}
`;

export const Title = styled.h1`
  font-family: Bryant;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 135.5%;
  color: ${theme.COD_GRAY};
  margin: 0 0 15px 0;

  @media ${device.mediaQuery.SMALL_SCREEN} {
    font-size: 28px;
    margin: 0 0 10px 0;
  }
`;

export const TextContent = styled.p`
  font-family: Bryant;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 160%;
  color: ${theme.COD_GRAY_LIGHT};
  margin: 0;

  @media ${device.mediaQuery.SMALL_SCREEN} {
    font-size: 20px;
  }
`;

export const ServiceAreaMap = styled.a`
  text-decoration: none;
  margin: 28px 0;
  padding: 0;

  &:hover {
    text-decoration: none;
  }

  @media ${device.mediaQuery.SMALL_SCREEN} {
    margin: 16px 0;
  }
`;

export const ServiceAreaMapIcon = styled(FontAwesomeIcon)`
  color: ${theme.BASE_COLOR};
  font-size: 24px;
  position: relative;
  top: 2px;

  @media ${device.mediaQuery.SMALL_SCREEN} {
    font-size: 22px;
  }
`;

export const ServiceAreaMapText = styled.span`
  font-family: Bryant;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
  color: ${theme.BASE_COLOR};
  padding-left: 10px;

  @media ${device.mediaQuery.SMALL_SCREEN} {
    font-size: 20px;
    line-height: 20px;
  }
`;

export const ReadyToRide = styled.a`
  text-decoration: none;
  margin: 28px 0 0 0;
  padding: 0;

  &:hover {
    text-decoration: none;
  }

  @media ${device.mediaQuery.SMALL_SCREEN} {
    margin: 16px 0 0 0;
  }
`;

export const ReadyToRideText = styled.span`
  font-family: Bryant;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
  color: ${theme.BASE_COLOR};
  padding: 10px 0;

  @media ${device.mediaQuery.SMALL_SCREEN} {
    font-size: 20px;
    line-height: 20px;
  }
`;

export const StyledButton = styled(Button)`
  width: ${props => (props.largeScreen ? `calc(50% - ${props.spacing / 2}px)` : '100%')};
  border-radius: 8px;
  margin-top: 12px;
  font-family: Bryant;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
`;

export const SliderArrowButton = styled(IconButton)`
  margin: 30px 5px 0 0;
  border: 2px solid ${theme.EMPEROR_GRAY};
  border-radius: 50px;
  width: 50px;
  height: 50px;
  opacity: ${props => props.opacity};
`;

export const SliderDot = styled(Button)`
  width: ${props => (props.active ? 18 : 6)}px;
  height: 6px;
  background-color: ${props => (props.active ? theme.BASE_COLOR : '#DFE3ED')};
  border-radius: ${props => (props.active ? 18 : 6)}px;
  margin: 15px 3px 0 0;
`;
