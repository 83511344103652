/** @format */

import { theme } from '@constants';

const getFormattedTiers = (ridershipTiers, userLifetimeStats) => {
  const formattedTiers = [];
  let commulativeRoundTripBonus = 0;
  let commulativeCompletedRoundTrips = 0;
  let commulativeRoundTripsRequired = 0;

  if (Array.isArray(ridershipTiers) && ridershipTiers.length) {
    // eslint-disable-next-line no-restricted-syntax
    for (const tier of ridershipTiers) {
      const { completedRoundTrips, roundTripsRequired, status, roundTripsBonus } = tier;

      commulativeRoundTripBonus += roundTripsBonus;
      commulativeCompletedRoundTrips += completedRoundTrips.length || 0;
      commulativeRoundTripsRequired += roundTripsRequired;

      const result = {
        ...tier,
        roundTripsBonus: commulativeRoundTripBonus,
        completedPercentage:
          ((completedRoundTrips.length || 0) / roundTripsRequired) * 100,
      };

      if (status === 'Active') {
        result.ridesCovered = `${
          commulativeCompletedRoundTrips || 0
        }/${commulativeRoundTripsRequired}`;
        result.stats = getFormattedUserStats(userLifetimeStats);
      }

      formattedTiers.push(result);
    }
  }

  return formattedTiers;
};

const getFormattedUserStats = userLifetimeStats => {
  let { rides, totalMiles, CO2Saved } = userLifetimeStats;
  rides = parseInt(rides, 10);
  totalMiles = parseInt(totalMiles, 10);
  CO2Saved = parseInt(CO2Saved, 10);

  const stats = {
    rides: `${rides || 0}`,
    totalMiles: `${totalMiles || 0}`,
    CO2Saved: `${CO2Saved || 0}`,
  };

  if (rides && rides >= 10000) {
    stats.rides = `${parseFloat((rides / 1000).toFixed(1))}k`;
  }

  if (totalMiles && totalMiles >= 1000) {
    stats.totalMiles = `${parseFloat((totalMiles / 1000).toFixed(1))}k`;
  }

  if (CO2Saved && CO2Saved >= 1000) {
    stats.CO2Saved = `${parseFloat((CO2Saved / 1000).toFixed(1))}k`;
  }

  return stats;
};

const getLevelBonusColor = ridershipTiers => {
  let levelBonusColor = theme.LIGHT_PINK;
  const activeTierIndex = (ridershipTiers || []).findIndex(
    tier => tier.status === 'Active',
  );

  if (activeTierIndex > 0) {
    const {
      completedRoundTrips = [],
      roundTripsRequired,
      color,
    } = ridershipTiers[activeTierIndex];
    levelBonusColor =
      completedRoundTrips.length === roundTripsRequired
        ? color
        : ridershipTiers[activeTierIndex - 1].color;
  } else if (activeTierIndex === 0) {
    const {
      completedRoundTrips = [],
      roundTripsRequired,
      color,
    } = ridershipTiers[activeTierIndex];
    levelBonusColor =
      completedRoundTrips.length === roundTripsRequired
        ? color
        : levelBonusColor;
  }

  return levelBonusColor;
};

export default {
  getFormattedTiers,
  getFormattedUserStats,
  getLevelBonusColor,
};
