/** @format */
import styled from 'styled-components';
import { theme } from '@constants';
import { Dropdown, Row } from '..';

export const SDropdown = styled(Dropdown)`
  .rdd-control {
    z-index: 15;
  }
  .rdd-menu {
    z-index: 14;
  }
`;

export const DropdownWrapper = styled.div`
  width: 25%;
  margin-left: 10px;
`;

export const LabelSmall = styled.p`
  color: ${theme.BLACK};
  font-family: BryantRegular;
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
`;

export const LabelRow = styled(Row)`
  max-height: 50px;
`;

export const Wrapper = styled.div`
  width: 100%;
  z-index: 0;
`;
