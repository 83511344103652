/** @format */

import styled from 'styled-components';

import { Row, Col, Dropdown } from '@components';
import { theme } from '@constants';

export const WrapperRow = styled(Row)`
  background-color: ${theme.WHITE};
  margin: 0px;
`;

export const DropdownCol = styled(Col)`
  height: 100%;
`;

export const LabelSmall = styled.p`
  color: ${theme.BLACK};
  font-family: Bryant;
  font-size: 18px;
  font-weight: bold;
  margin: 0;
`;

export const WorkplaceSelectDropdown = styled(Dropdown)`
  .rdd-placeholder {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
