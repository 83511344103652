/** @format */

import styled from 'styled-components';
import { theme, device } from '@constants';
import { styles as styleUtils } from '@utils';
import { Button, Input, Row, IconButton } from '..';

export const Wrapper = styled.div`
  ${styleUtils.flex('space-between', 'center', 'column')}
  height: 100%;
  font-family: Bryant;

  button[width='20'] {
    margin-top: 0 !important;
    @media ${device.mediaQuery.SMALL_SCREEN} {
      position: absolute;
      margin: 15px 0;
      font-size: 24px;
      top: 24px !important;
      right: 20px;
    }
  }

  @media ${device.mediaQuery.SMALL_SCREEN} {
    min-height: 100%;
    padding: 20px;
  }

  @media ${device.mediaQuery.LARGE_SCREEN} {
    padding: 20px 24px 20px 24px;
  }

  padding-bottom: 10px;
`;

export const TitleWrapper = styled.div`
  ${styleUtils.flex('center', 'flex-start', 'column')}
`;

export const Title = styled.h6`
  color: ${theme.COD_GRAY_LIGHT};
  font-weight: 600;
  font-size: 26px;
  margin: 0px;
`;

export const SubTitle = styled.p`
  color: ${theme.COD_GRAY_LIGHT};
  font-size: 22px;
  margin-bottom: 10px;

  @media ${device.mediaQuery.SMALL_SCREEN} {
    font-size: 18px;
  }
`;

export const CloseIconRow = styled(Row)`
  margin-left: 8px;
`;

export const FormWrapper = styled.div`
  ${styleUtils.flex('center', 'center', 'column')}
`;

export const Form = styled.div`
  ${props =>
    props.largeScreen
      ? styleUtils.flex('center', 'flex-start', 'row')
      : styleUtils.flex('center', 'flex-start', 'column')};

  & > * {
    margin: 8px 0px;
    flex: 45%;
  }

  & > :nth-child(even) {
    margin-left: ${props => (props.largeScreen ? '20px' : '0px')};
  }
`;

export const LoginBtn = styled(Button)`
  margin-top: 20px;
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  height: 50px;
`;

export const LoginBtnWrapper = styled.div`
  ${styleUtils.flex('flex-start', 'center', 'column')}
`;

export const CloseIconStyled = styled(IconButton)`
  margin-top: 0 !important;
  @media ${device.mediaQuery.SMALL_SCREEN} {
    position: absolute;
    margin: 15px 0;
    font-size: 22px;
    top: 24px;
  }
`;

export const RememberMeTextWrapper = styled(Row)`
  margin-top: 15px;
`;

export const RememberMeText = styled.label`
  color: ${theme.COD_GRAY_LIGHT};
  font-weight: bold;
  cursor: pointer;
  margin-left: 10px;
`;

export const ForgotPasswordText = styled.a`
  align-self: right;
  color: ${props => props.color || theme.COD_GRAY_LIGHT};
  font-weight: bold;
  margin-top: 15px;
  font-size: ${props => (props.largeScreen ? '18px' : '16px')};
  cursor: pointer;
`;

export const SignupText = styled.div`
  font-size: 22px;
  align-self: ${props => (props.largeScreen ? 'center' : 'start')};
  margin-bottom: 15px;

  @media ${device.mediaQuery.SMALL_SCREEN} {
    width: 92%;
    font-size: 16px;
  }
`;

export const SignupLink = styled.a`
  color: ${props => props.color};
  text-decoration: none;
  font-weight: bold;
  font-size: 22px;
  cursor: pointer;
  @media ${device.mediaQuery.SMALL_SCREEN} {
    font-size: 16px;
  }
`;

export const SInput = styled(Input)`
  span {
    color: ${theme.INPUT_LABEL_COLOR};
  }
`;
