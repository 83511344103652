/** @format */

import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { generals } from '@constants';
import { helpers } from '@utils';
import {
  Wrapper,
  WrapperContainer,
  Title,
  Script,
  SInput,
  GiftCardSelectionLabel,
  GiftCardOptionsWrapper,
  Option,
  OptionCircleWrapper,
  OptionCircle,
  OptionCircleActive,
  OptionText,
  ButtonsWrapper,
  CancelButton,
  ConfirmButton,
  LoaderIcon,
  LoaderWrapper,
} from './styled';
import config from './config';

const RedeemBonusModal = ({
  giftCardType,
  handleGiftCardTypeClick,
  emailAddress,
  handleEmailChange,
  confirmEmailAddress,
  handleConfirmEmailChange,
  handleConfirmClick,
  handleCancelClick,
  redeemingRidershipBonusLoading,
  validate,
  errors,
  matchEmail,
}) => {
  const { t } = useTranslation();

  const renderEmailFields = () => {
    return config.formFields.map((formField, i) => {
      const props = {
        'data-cy': formField['data-cy'],
        name: formField.name,
        id: formField.id,
        floating: true,
        key: i,
        type: 'text',
        error: getError(formField),
        inputRef: ref => getInputRef(ref, formField),
        placeholderInsideInput: formField.placeholder,
      };

      if (formField.name === 'email') {
        return (
          <SInput
            {...props}
            value={emailAddress}
            onChange={handleEmailChange}
          />
        );
      }
      return (
        <SInput
          {...props}
          value={confirmEmailAddress}
          onChange={handleConfirmEmailChange}
        />
      );
    });
  };

  const getError = formField => {
    return t(helpers.getObjectProperty(errors, [formField.name, 'message']));
  };

  const getInputRef = (ref, formField) => {
    if (formField.name === 'email') {
      validate(ref, formField.validator);
      return;
    }
    validate(ref, {
      required: formField.validator.required,
      validate: v => matchEmail(v) || t(formField.validator.match.message),
    });
  };

  return (
    <Wrapper
      id="ridership-redeem-bonus-model"
      data-cy="ridership-redeem-bonus-model"
    >
      <WrapperContainer>
        <Title>{t('ridership-bonus.redeem-modal.title')}</Title>
        <Script>{t('ridership-bonus.redeem-modal.script')}</Script>
        {renderEmailFields()}
        <GiftCardSelectionLabel>
          {t('ridership-bonus.redeem-modal.selection-label')}
        </GiftCardSelectionLabel>
        <GiftCardOptionsWrapper>
          {generals.RIDERSHIP_GIFT_CARD_OPTIONS.map((option, index) => {
            return (
              <Option key={index}>
                <OptionCircleWrapper>
                  <OptionCircle
                    id="ridership-redeem-bonus-option"
                    data-cy="ridership-redeem-bonus-option"
                    onClick={() => handleGiftCardTypeClick(option)}
                    index={index}
                  >
                    {giftCardType === option && (
                      <OptionCircleActive> </OptionCircleActive>
                    )}
                  </OptionCircle>
                </OptionCircleWrapper>
                <OptionText>{option}</OptionText>
              </Option>
            );
          })}
        </GiftCardOptionsWrapper>
        <ButtonsWrapper>
          <CancelButton
            id="ridership-redeem-bonus-cancel-button"
            data-cy="ridership-redeem-bonus-cancel-button"
            onClick={handleCancelClick}
          >
            {t('ridership-bonus.redeem-modal.cancel')}
          </CancelButton>
          <ConfirmButton
            onClick={handleConfirmClick}
            id="ridership-redeem-bonus-confirm-button"
            data-cy="ridership-redeem-bonus-confirm-button"
            disabled={
              redeemingRidershipBonusLoading ||
              Object.keys(errors).length ||
              confirmEmailAddress !== emailAddress
            }
          >
            {redeemingRidershipBonusLoading && (
              <LoaderWrapper
                id="ridership-redeem-bonus-confirm-button-loader"
                data-cy="ridership-redeem-bonus-confirm-button-loader"
              >
                <LoaderIcon icon={faSpinner} spin />
              </LoaderWrapper>
            )}
            {t('ridership-bonus.redeem-modal.confirm')}
          </ConfirmButton>
        </ButtonsWrapper>
      </WrapperContainer>
    </Wrapper>
  );
};

RedeemBonusModal.propTypes = {
  giftCardType: PropTypes.string.isRequired,
  handleGiftCardTypeClick: PropTypes.func.isRequired,
  emailAddress: PropTypes.string.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
  confirmEmailAddress: PropTypes.string.isRequired,
  handleConfirmEmailChange: PropTypes.func.isRequired,
  handleConfirmClick: PropTypes.func.isRequired,
  handleCancelClick: PropTypes.func.isRequired,
  redeemingRidershipBonusLoading: PropTypes.bool.isRequired,
  validate: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  matchEmail: PropTypes.func.isRequired,
};

export default RedeemBonusModal;
