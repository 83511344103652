import { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';

const useScrollPagination = ({ loading, hasMoreData, fetchData }) => {
    const [page, setPage] = useState(0);
    const { selectedMarker } = useSelector(store => store.tripRequestWorkforce);
    useEffect(() => {
        fetchData(page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ page ]);

    useEffect(() => {
        setPage(0);
    }, [selectedMarker]);

    const observer = useRef();
    const lastElemRefCb = useCallback(node => {
        if (observer.current) {
            observer.current.disconnect();
        }

        observer.current = new IntersectionObserver(enteries => {
            if (enteries[0].isIntersecting && hasMoreData && !loading) {
                setPage(page + 1);
            }
        });

        if (node) {
            observer.current.observe(node);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, hasMoreData]);

    return {
        lastElemRefCb
    };
}

export default useScrollPagination;